/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { Image } from 'antd';
import env from 'env';
import { $error, $success, Iconfont } from '@components';
import CopeText from './cope_text';
import '@styles/components/fall_item.less';
import useOnScreen from '@/hooks/useOnScreen';
import useDebounce from '@/hooks/useDebounce';

const FallItem = function({
    isDimEnd,
    sum,
    uid,
    url,
    name,
    ratio,
    is_split,
    onClick
}) {
    const width = (ratio / ((isDimEnd && sum < 6) ? 6 : sum)) * 100;
    const viewRef = useRef();
    const [urlSource, setUrlSource] = useState('');
    const isVisible = useOnScreen(viewRef);
    const fn = useDebounce((url) => {
        if (url) {
            setUrlSource(url);
        }
    }, 800, []);

    // const [] = useDebounceState(value, 300)
    useEffect(() => {
        if (isVisible && url && !urlSource) {
            fn(url);
        }
        if (!isVisible && !urlSource) {
            fn('');
        }
    }, [isVisible, url, urlSource]);
    return (
        <div
            ref={viewRef}
            className="amps_fall_item"
            style={{
                flex: `0 0 ${width}%`,
                width: `${width}%`
            }}
        >
            <div className="amps_fall_item_box">
                <div className="amps_fall_item_box_img_box">
                    <Image
                        onClick={onClick}
                        src={urlSource || env.placeImage}
                        fallback={env.placeImage}
                        preview={{
                            visible: false,
                            mask: null
                        }}
                    />
                </div>
                {
                    is_split === 2 && <Iconfont className="amp-split-icon" type="icon-chaifenrenwu-hover" />
                }
                <div className="amps_fall_item_box_text">
                    <CopeText
                        onError={() => $error('复制失败')}
                        onCope={() => $success('复制成功')}
                    >
                        <p>{name}</p>
                    </CopeText>
                    <CopeText
                        onError={() => $error('复制失败')}
                        onCope={() => $success('复制成功')}
                    >
                        <p>{uid}</p>
                    </CopeText>
                </div>
            </div>

        </div>
    );
};
export default FallItem;
