import { BasicImage,Iconfont } from '@components';

const centerStyle = { position: "absolute", top:"50%", transform:"translateY(-50%)" };
const render = (text) => <div style={centerStyle}>{text}</div>;
const renderLabel = ({ label }) => <div style={centerStyle}>{label}</div>;
export const assetColumns = [
  {
    title: "预览图",
    dataIndex: "previews",
    ellipsis: true,
    render: (previews) => <BasicImage height={68} width={68} src={previews[0]} />,
    width: "10%",
  },
  {
    title: "内部ID",
    dataIndex: "uid",
    sorter: true,
    width: "15%",
    ellipsis: true,
    render,
  },
  {
    title: "资产名称",
    dataIndex: "name",
    sorter: true,
    width: "15%",
    ellipsis: true,
    render:(text, record) => {
        return <div style={centerStyle}>{record.is_split === 2 && <Iconfont className={'fz-18'} type="icon-chaifenrenwu-hover"/>} {text}</div>;
    },
  },
  {
    title: "类型",
    dataIndex: "type",
    sorter: true,
    width: "10%",
    ellipsis: true,
    render: renderLabel,
  },
  {
    title: "种类",
    dataIndex: "category",
    sorter: true,
    width: "10%",
    ellipsis: true,
    render: renderLabel,
  },
  {
    title: "项目名称",
    dataIndex: "project_name",
    sorter: true,
    width: "15%",
    ellipsis: true,
    render,
  },
  {
    title: "生成时间",
    dataIndex: "produced_at",
    sorter: true,
    ellipsis: true,
    render,
  },
];
