import React, { useState, useEffect, useMemo } from 'react'
import { Col, Row, Select } from 'antd';
import Column from './template/column';
import Pie from './template/pie';
import DualAxes from './template/dual_axes';
import SearchForm from './template/search_form';
import { getDiscardDateSummary } from '@api'
import { $error } from '@components';
import moment from 'moment'

// 当前时间往前三个月
const discardTime = [
    moment(new Date()).subtract(6, "month"),
    moment(new Date())
]
/**
 * 作废时间维度图表ui
 */
const TimeChart = (props) => {
    const [searchData, setSearchData] = useState({
        discard_time: discardTime
    })
    // 总量周月切换
    const [totalType, setTotalType] = useState(1)
    // 占比周月切换
    const [ratioType, setRatioType] = useState(1)
    // 图表loading
    const [loading, setLoading] = useState(false)
    // 作废数据源
    const [discardData, setDiscardData] = useState({})

    useEffect(() => {
        //发起请求获取作废信息
        setLoading(true)
        const params = {
            ...searchData,
            start_time: searchData.discard_time?.[0].format("YYYY-MM-DD"),
            end_time: searchData.discard_time?.[1].format("YYYY-MM-DD")
        }
        getDiscardDateSummary(params).then((res) => {
            setLoading(false)
            setDiscardData(res)
        }).catch((err) => {
            setLoading(false)
            $error(err.message);
        });
    }, [searchData])

    // 作废量周/月展示数据
    const discarCount = useMemo(() => {
        let data = []
        if (totalType === 1) {
            // 月
            data = discardData?.month_summary?.map(item => {
                return {
                    ...item,
                    xField: `${item.year_index}.${item.month_index}`
                }
            })
        } else {
            // 周
            data = discardData?.week_summary?.map(item => {
                return {
                    ...item,
                    xField: `${item.year_index}-${item.week_index}周`
                }
            })
        }
        return data
    }, [discardData, totalType])

    // 作废占比周/月展示数据
    const [discarProportion, discarProportionLine] = useMemo(() => {
        let dateKey = 'week_index'
        let data = discardData?.week_summary
        if (ratioType === 1) {
            // 月
            dateKey = 'month_index'
            data = discardData?.month_summary
        }
        //作废数
        let discard = []
        // 完成数
        let finish = []
        // 作废比率
        let line = []

        data?.forEach(item => {
            const time = `${item.year_index}.${item[dateKey]}`
            discard.push({
                time,
                value: item.discard_num,
                type: '作废',
            })
            finish.push({
                time,
                value: item.finish_num,
                type: '完成',
            })
            line.push({
                time,
                count: item.discard_percent,
            })
        })

        return [[...discard, ...finish], line]
    }, [discardData, ratioType])

    return (
        <div>

            <div className="amp-flex-row-start-center">
                <div className="amp-destroy-card">
                    <div className="amp-fs-16 amp-mb-16">全平台累计作废</div>
                    <div className="amp-destroy-main-font">{discardData?.summary_num}</div>
                </div>
            </div>

            <SearchForm
                onChange={(val) => setSearchData(val)}
                discardTime={discardTime}
            />

            <Row gutter={24}>
                {/* 作废量 */}
                <Col span={24}>
                    <div className="amp-destroy-tips2">
                        <span>作废量</span>
                        <div>
                            <Select
                                className="amp-destroy-cycle-select"
                                defaultValue={totalType}
                                onChange={setTotalType}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                options={[
                                    {
                                        label: '按月查看',
                                        value: 1
                                    },
                                    {
                                        label: '按周查看',
                                        value: 2
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <Column
                        data={discarCount}
                        type={totalType === 1 ? '月' : '周'}
                        xField="xField"
                        yField="discard_num"
                        loading={loading}
                    />
                </Col>
                {/* 作废类型占比 */}
                <Col span={12} className="amp-pt-16">
                    <div className="amp-destroy-tips2">
                        <span>作废类型占比</span>
                    </div>
                    <Pie
                        data={discardData?.discard_type_summary || []}
                        xField="discard_type_name"
                        yField="discard_percent"
                        loading={loading}
                    />
                </Col>
                {/* 作废占比任务数 */}
                <Col span={12} className="amp-pt-16">
                    <div className="amp-destroy-tips2">
                        <span>作废占比任务数</span>
                        <div>
                            <Select
                                className="amp-destroy-cycle-select"
                                defaultValue={ratioType}
                                onChange={setRatioType}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                options={[
                                    {
                                        label: '按月查看',
                                        value: 1
                                    },
                                    {
                                        label: '按周查看',
                                        value: 2
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <DualAxes
                        data={discarProportion}
                        data2={discarProportionLine}
                        type={ratioType === 1 ? '月' : '周'}
                        loading={loading}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default TimeChart
