import React, { Component } from "react";
import arrayMove from "array-move";
import { SortableTable, AuthIcon, ConfirmDialog } from "@/components";
import { projectSettingsAPI } from "@/api";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

const baseColumns = [
  { title: "真实姓名", dataIndex: "name" },
  { title: "角色", dataIndex: "role" },
  {
    title: "组织",
    dataIndex: "group",
    render: (group) => (group ? group : "-"),
  },
];

@inject("userRelationsStore")
@observer
class RelationList extends Component {
  deleteRef = React.createRef();

  state = {
    list: [],
    total: null,
    isMoved: false,
    loading: false,
  };

  componentDidMount() {
    this.store.fetchRelatedUsers()
  }


  get store() {
    return this.props.userRelationsStore;
  }

  showDelete = (record) => {
    this.deleteRef.current.show(
      record,
      this.store.currentType.title,
      record.name
    );
  };

  fetchRelatedUsers = () => this.props.userRelationsStore.fetchRelatedUsers();

  sortRequest = () => {
    this.store.sortRequest();
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { relatedUsers } = this.store;
    relatedUsers = toJS(relatedUsers);
    if (oldIndex !== newIndex) {
      const newList = arrayMove([...relatedUsers], oldIndex, newIndex).filter(
        (el) => !!el
      );
      this.store.receiveRelatedUsers(newList);
      this.store.sortRequest(newList?.map((item) => item.id));
    }
  };

  renderTable = () => {
    const { relatedUsers, total, loading, currentType } =
      this.props.userRelationsStore;
    const render = (text, record) => (
      <AuthIcon
        type="icon-trash"
        className="icon-20"
        onClick={() => this.showDelete(record)}
        permission="relation_delete"
      />
    );
    const columns = [...baseColumns, { title: "操作", render }];
    return total > 0 || loading ? (
      <SortableTable
        columns={columns}
        dataSource={relatedUsers}
        onSortEnd={this.onSortEnd}
        loading={loading}
        permission="relation_list"
      />
    ) : (
      <div
        style={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        暂时没有{currentType?.title}
      </div>
    );
  };

  render() {
    return (
      <>
        <ConfirmDialog
          ref={this.deleteRef}
          update={this.fetchRelatedUsers}
          confirmRequest={projectSettingsAPI.deleteRelation}
        />
        <div>{this.renderTable()}</div>
      </>
    );
  }
}

export default RelationList;
