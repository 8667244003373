import {
  RECEIVE_ASSETS,
  // DELETE_ASSET,
} from "@actions/action_consts";

const assetsReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    // case RECEIVE_ASSET:
    //   return { ...state, [action.asset.id]: action.asset };
    case RECEIVE_ASSETS:
      let assets = action.assets.list;
      let res = {};
      assets.forEach((asset) => {
        res[asset.id] = asset;
      });
      return { ...state, ...res };
    // case DELETE_ASSET:
    //   const { id } = action;
    //   const newState = { ...state };
    //   delete newState[id];
    //   return newState;
    default:
      return state;
  }
};

export default assetsReducer;
