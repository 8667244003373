import { useState } from 'react'
import { Table, Tooltip } from 'antd'
import { $error, $success } from '@components'
import { getHandoverDetail, getHandoverDetailExport } from '@api'
import { useSwr } from '@hooks';
import { boldExportFile } from '@gUtils/downFile';
import { FilterForm } from './filterForm'
import { _columns } from './conofig'
import './index.less'

/**
 * HandoverDetail
 * @returns {JSX.Element} HandoverDetail
 */
const HandoverDetail = () => {
    const [exportParams, setExportParams] = useState({}); // 导出
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
    })

    /**
     * columns
     */
    const columns = _columns?.map(column => {
        const { render, ellipsis, ...other } = column
        return {
            ...other,
            ellipsis: ellipsis ? {
                showTitle: false
            } : ellipsis,
            render: render || ((text) => {
                if (text) {
                    return ellipsis ? <Tooltip className='amp-line-clamp-3' placement="topLeft" title={text}>
                        {text}
                    </Tooltip> : text
                }
                return '-'
            })
        }
    })

    /**
     * 请求数据
     * @returns {Object} data
     */
    const getTableRequest = (url, searchPage) => {
        let params = {
            ...searchPage,
            page: searchPage.current,
            page_size: searchPage.pageSize,
            shoot_user: '',
            analysis_user: '',
        }
        delete params.analysis_finish
        delete params.current
        delete params.pageSize
        setExportParams(params)
        return getHandoverDetail(params).then(res => {
            return res
        })
    }

    const { data: dataSource, isValidating } = useSwr(['/api/task-statistic/project-handover', pagination], getTableRequest)

    /**
     * 分页
     * @param {*} page
     */
    const onChangeGetList = (page) => {
        setPagination({ ...pagination, pageSize: page.pageSize, current: page.current })
    }

    /**
     * 模糊查询
     * @param {*} value 模糊查询value
     */
    const handleSearch = (value) => {
        setPagination({ ...pagination, ...value, current: 1 })
    }

    /**
     * 重置表单
     * @param {*} value
     */
    const resetForm = (value) => {
        setPagination({
            ...pagination,
            current: 1,
            ...value,
            analysis_finish_start_time: '',
            analysis_finish_end_time: '',
        })
    }

    /**
     * 导出全部数据
     * @param {*} handleCsvRef
     */
    const csvExport = () => {
        getHandoverDetailExport(exportParams).then(res => {
            boldExportFile(res)
            $success("导出成功")
        }).catch(err => {
            $error(err.message)
        })
    }

    return <div className={'amps-handover-page page-container'}>
        <div style={{ height: '100%', overflow: 'auto' }}>
            <FilterForm
                csvExport={csvExport}
                resetForm={resetForm}
                submitForm={handleSearch}
            />
            <Table
                rowKey={(record) => record.asset_uid + Math.random()}
                dataSource={dataSource?.list || []}
                loading={isValidating}
                columns={columns}
                pagination={{
                    position: ['bottomCenter'],
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: dataSource?.total,
                    showTotal: (total) => `共 ${total} 条`,
                    ...pagination
                }}
                onChange={onChangeGetList}
            />
        </div>
    </div>
}

export default HandoverDetail
