import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { taskPlanAPI } from '@api';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Input, Select, Spin } from 'antd';
import useDebounceState from '@/hooks/useDebounceState';
import { $error } from '@components';
import TaskInfoImgItem from './task_info_img_item';

const StatusLabel = {
    1: '未审核',
    2: '通过',
    3: '驳回',
    4: '作废'
};
const TaskInfoV2ImgList = forwardRef(function({
    package_id
}, ref) {
    const nodeRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [statics, setStatics] = useState([]);
    const initialValue = {
        page_size: 40,
        package_id,
        filter: {
            has_related: '',
            type: '',
            title: '',
            status: [1, 2, 3, 4]
        }
    };
    const [params, debounceParams, setParams] = useDebounceState(initialValue);

    useEffect(() => {
        if (package_id) {
            setParams(initialValue);
        }
    }, [package_id]);

    function setValue(key, value) {
        setParams((state) => {
            const copeState = JSON.parse(JSON.stringify(state));
            copeState.filter[key] = value;
            return copeState;

        });
    }


    const {
        list,
        loading,
        total,
        error,
        refresh
    } = useInfiniteScroll({
        params: debounceParams,
        anchorEl,
        scrollThreshold: 0.2,
        onSuccess(res) {
            const statics = res.statics?.map(i => ({
                label: StatusLabel[i.status],
                value: i.count
            }));

            setStatics(statics);
        },
        onError(error, onReload, currentPage, errorCount) {
            if (errorCount < 3) {
                onReload();
            } else {
                $error(error.message || '服务器错误');
            }
        },
        request: (page, params) => taskPlanAPI.getPlanTaskList({ page, ...params })
            .then((res) => {
                return {
                    list: res.data.list,
                    total: res.data.total,
                    statics: res.data.statics
                };
            })
    });
    useEffect(() => {
        setAnchorEl(nodeRef.current);
    }, [package_id]);
    useImperativeHandle(ref, () => ({
        refresh(){
            refresh()
        }
    }))
    return <div className={'tmp-info-images-list'}>
        <div className={'tmp-info-images-list-title'}>
            <div className={'tmp-info-images-list-title-statics'}>
                {statics.map(i => <p key={i.label} className={'tmp-info-images-list-title-statics-desc'}>
                    <span className={'tmp-info-images-list-title-statics-desc-label'}>{i.label}:</span>
                    <span className={'tmp-info-images-list-title-statics-desc-value'}>{i.value}</span>
                </p>)}
            </div>
            <div className={'tmp-info-images-list-title-form'}>
                <Select className={'tmp-info-images-list-title-form-select'} placeholder={'请选择上传类型'} value={params.filter.has_related}
                        onChange={(value) => {
                            setValue('has_related', value);
                        }}>
                    <Select.Option value={''}>全部</Select.Option>
                    <Select.Option value={1}>已上传</Select.Option>
                    <Select.Option value={2}>未上传</Select.Option>
                </Select>
                <Select className={'tmp-info-images-list-title-form-select'} placeholder={'请选择任务'} value={params.filter.type}
                        onChange={(value) => {
                            setValue('type', value);
                        }}>
                    <Select.Option value={''}>全部</Select.Option>
                    <Select.Option value={1}>任务包任务</Select.Option>
                    <Select.Option value={2}>自建任务</Select.Option>
                </Select>
                <Input className={'tmp-info-images-list-title-form-input'} placeholder={'根据任务名模糊匹配'} value={params.filter.title}
                       onChange={(event) => {
                           setValue('title', event.target.value);
                       }} />

            </div>
        </div>
        <div ref={nodeRef} className={'tmp-info-images-list-container'}>
            {
                !error && list?.map(item => <TaskInfoImgItem width={160} height={160} key={item.id} item={item} refresh={refresh} />)
            }
            {
                !!(!error && !list.length && loading) && <div className={'tmp-info-images-list-container-init-loading'}><Spin /></div>
            }
            {
                !!(!error && list.length && loading) && <div className={'tmp-info-images-list-container-button-loading'}><Spin /></div>
            }
            {
                !!(!error && !loading && !list.length && !total) && <div className='amps-no-data'>
                    <img className='amp-no-data-img' src={`${process.env.PUBLIC_URL}/images/other/no_data.png`} alt='请选择任务包' />
                    <span className='associated-content-no-data-span'>暂无任务</span>
                </div>
            }
            {
                error && <Button onClick={() => refresh()}>{error.message || '服务错误'}，点击重新加载</Button>
            }
        </div>

    </div>;
});
export default memo(TaskInfoV2ImgList);
