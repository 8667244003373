import { Menu } from "antd";
import { connect } from "react-redux";
import { checkAuth } from "@gUtils";

const { SubMenu } = Menu;

const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});


const AuthMenuItemComponent = (props) => {
  const { userPermissions, permission, children, dispatch, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? (
    <Menu.Item {...rest}>{children}</Menu.Item>
  ) : (
    ""
  );
};

const AuthSubMenuComponent = (props) => {
  const { userPermissions, permission, children, dispatch, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? (
    <SubMenu {...rest}>{children}</SubMenu>
  ) : (
    ""
  );
};

const AuthMenuComponent = (props) => {
  const { userPermissions, permission, children, dispatch, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? <Menu {...rest}>{children}</Menu> : "";
};


const AuthMenuItem = connect(mapStateToProps)(AuthMenuItemComponent);
const AuthSubMenu = connect(mapStateToProps)(AuthSubMenuComponent);
const AuthMenu = connect(mapStateToProps)(AuthMenuComponent)
export { AuthMenuItem, AuthSubMenu, AuthMenu };
