import React, { Component } from "react";
import { Modal } from "antd";
import {AuthButton}from '@/components'
import ReactCodeInput from "react-verification-code-input";

let timer;
export default class Validation extends Component {
  state = {
    visible: false,
    time: 60,
    disabled: true,
    code: "",
    error: "",
  };

  componentWillUnmount() {
    clearInterval(timer);
  }

  show = () => {
    this.setState({ visible: true });
    this.startCountDown();
    this.setState({ disabled: true });
  };
  hide = () => {
    this.setState({ visible: false });
    this.clear();
  };

  clear = () => {
    this.setState({ code: "", error: "" });
  };

  handleCodeComplete = (code) => {
    this.setState({code})
  };

  handleConfirm = () => {
    const { code } = this.state;
    if (!code) {
      return this.setState({ error: "请输入验证码" });
    }
    this.props.validateCode(
      { code },
      { success: this.success, error: () => {} }
    );
  };

  success = () => {
    this.hide();
    this.clear();
    this.props.showChangePassword();
  };

  error = () => {
    this.setState({ code: "" });
  };

  countDown = () => {
    let { time } = this.state;
    if (time === 0) {
      this.setState({ disabled: false });
      this.clearTime();
    } else {
      time--;
      this.setState({ time });
    }
  };

  startCountDown = () => {
    clearInterval(timer);
    this.clearTime();
    timer = setInterval(this.countDown, 1000);
  };

  clearTime = () => {
    this.setState({ time: 60 });
  };

  restartCountDown = () => {
    this.clearTime();
    this.startCountDown();
  };

  resendCode = () => {
    this.props.sendCode();
    this.setState({ disabled: true });
    this.restartCountDown();
  };

  renderResendButton = () => {
    const { time, disabled } = this.state;
    const finalButton = (
      <u className="fz-14 cursor-pointer" onClick={this.resendCode}>
        重新发送
      </u>
    );
    const countDownButton = (
      <u className="fz-14 cursor-default col-9b" disabled>
        {time}秒后重新发送
      </u>
    );
    return disabled ? countDownButton : finalButton;
  };

  render() {
    const { code, visible } = this.state;

    return (
      <Modal
        visible={visible}
        onOk={this.handleConfirm}
        onCancel={this.hide}
        footer={null}
        className="medium-form-dialog"
        centered
        width={550}
        destroyOnClose
      >
        <div className="fz-18 two-col-form">
          <span> 请输入邮箱收到的验证码 </span>
          {this.renderResendButton()}
        </div>
          <ReactCodeInput
            fields={6}
            onComplete={this.handleCodeComplete}
            type="text"
            className="validation-code"
            fieldWidth={74}
          />
          <div className="dashed-line"></div>
        <div className="error-color">{this.state.error}</div>
        <AuthButton
          type="primary"
          className=" mg-t-30 mg-r-12"
          onClick={this.handleConfirm}
        >
          确认
        </AuthButton>
        <AuthButton className=" mg-t-30" onClick={this.hide}>
          取消
        </AuthButton>
      </Modal>
    );
  }
}
