/**
 * 场景状态
 * @param {number} status status
 * @param {string} username username
 * @returns {*}
 */
export const getStatusOptions = (status: number, username: string) => {
    let initialValues: any = {};
    if (status === 202) {
        initialValues = {
            approved: {
                title: '策划内部审核通过',
                username,
                next: '资产委员会场景审核',
                operation: 'topic_scene_plan_approve'
            },
            reject: {
                title: '策划内部审核驳回',
                username,
                next: '场景编辑',
                operation: 'topic_scene_plan_disapprove'
            },
            cancel: {
                username,
                next: '已作废',
                operation: 'topic_scene_discard'
            }
        };
    }
    if (status === 203) {
        initialValues = {
            approved: {
                title: '资产委员会审核通过',
                username,
                next: '任务包制作',
                operation: 'topic_scene_asset_group_approve'
            },
            reject: {
                title: '资产委员会审核驳回',
                username,
                next: '场景编辑',
                operation: 'topic_scene_asset_group_disapprove'
            },
            cancel: {
                username,
                next: '已作废',
                operation: 'topic_scene_discard'
            }
        };
    }

    return initialValues;
};
