module.exports = {
    ssn: 'amp',
    envName: 'prod', // 生产
    assetsPublicPath: '/',
    logo: '/images/logo/practices.png',
    placeImage: '/images/other/waterfall-placeholder-img.png',
    serveUrls: 'https://amp-api.prod.epverse.net/',
    grpcUrls: "https://api.epverse.net",
    wechatID: 'wx19b996a1687afb54',
    webSocketSrc: "wss://wss.prod.epverse.net/ws",
    rmUser: "ecoplant",
    rmPsd: "U48!68cs3Ud",
    gaodeKey: 'd30ab9c348a8f6ba46112fa0496e8409',
    gaodeCipher: '24ca16459e92f6d526eea2756b029244',
};
