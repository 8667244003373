import React, { useState, createContext, useContext, } from 'react'
import { useHistory } from 'react-router';
import { Modal } from 'antd';

import './index.less'
/**
 * 选题池创建，编辑
 * @param {*} props
 * @returns
 */

const TopicContext = createContext(null);
export const TopicProvider = TopicContext.Provider;
export const useTopicContext = function () {
    return useContext(TopicContext);
};

const CreateTopic = (props) => {

    const {
        width = 708,
        children,
        parentUrl,
    } = props
    // 弹窗显示
    const [visible, setVisible] = useState(true)
    const [title, setTitle] = useState('创建选题')
    const history = useHistory();

    return (
        <TopicProvider
            value={{
                closeModal: () => setVisible(false),
                setTitle: (val) => setTitle(val)
            }}
        >
            <Modal
                title={title}
                className="amp-create-topic-modal"
                visible={visible}
                onCancel={() => setVisible(false)}
                destroyOnClose
                width={width}
                footer={null}
                centered
                maskClosable={false}
                afterClose={() => history.replace(parentUrl)}
            >
                <div className="amp-create-topic-modal-body">
                    {children}
                </div>
            </Modal>
        </TopicProvider>
    )
}

export default CreateTopic
