// 策划审核TabsContext
export const planExamineList = [
    {
        title: '场景',
        name: 'plan-examine-scene'
    }
];

export const assetCommitteeReviewList = [
    {
        title: '场景',
        name: 'asset-committee-review-scene'
    }
];

export const examineType = {
    // 策划审核
    PLAN_EXAMINE: 202,

    // 资产委员会审核
    ASSET_COMMITTEE_REVIEW: 203
};
