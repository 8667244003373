/**
 * @name: QueryFilter.jsx
 * @user: cfj
 * @date: 2022/6/20
 * @description: 筛选form
 */
import React, { useMemo, useState } from 'react'
import { Button, Col, Form, Grid, Input, Row, Space } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

const { useBreakpoint } = Grid

let i = 0;

export const QueryFilter = function (props) {
    const {
        initialValues,
        onFinish,
        children,
        loading,
        hideLabel,
        appendButton,
        appendOthers,
        isOffset,
        minCloSpan,
        ...other
    } = props
    const [collapsed, setCollapsed] = useState(true)
    const [form] = Form.useForm()
    const screens = useBreakpoint()
    let cloSpan
    if (screens.xxl) {
        cloSpan = 4
    } else if (screens.xl) {
        cloSpan = 6
    } else if (screens.lg) {
        cloSpan = 8
    } else if (screens.md) {
        cloSpan = 8
    } else if (screens.sm) {
        cloSpan = 12
    } else if (screens.xs) {
        cloSpan = 24
    } else {
        cloSpan = 6
    }

    if(minCloSpan && cloSpan < minCloSpan){
        cloSpan = minCloSpan
    }

    const items = useMemo(() => React.Children.toArray(children)
        .map(function (item, index) {
            if (React.isValidElement(item)) {
                const hidden = collapsed ? (index > (24 / cloSpan - 2)) : false
                if (hidden) {
                    return React.cloneElement(item, {
                        hidden
                    })
                }
                return <Col span={cloSpan} key={item.props?.name || index}>
                    {item}
                </Col>
            }
            return item
        }), [children, cloSpan, collapsed])

    let offset
    const cloWidth = 24 / cloSpan
    if (items.length < cloWidth - 1) {
        if(isOffset){
            offset = 0
        }else{
            offset = (cloWidth - 1 - items.length) * cloSpan
        }
    } else {
        if (collapsed) {
            offset = 0
        } else {
            offset = 24 - (items.length * cloSpan % 24) - cloSpan
        }
    }
    return <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        {...other}
    >
        <Row gutter={16} >
            {items}
            <Form.Item
                name='_test'
                hidden={true}
            >
                <Input />
            </Form.Item>
            <Col span={cloSpan}
                offset={offset}
                style={{
                    textAlign: 'left'
                }}>
                <Form.Item colon={false}>
                    <Space size='small'>
                        <Button
                            className='amp-button-default'
                            onClick={() => {
                                form.resetFields()
                                form.setFieldsValue({ '_test': ++i })
                                form.submit()
                            }}
                        >重置</Button>
                        <Button
                            type={'primary'}
                            className='amp-button-primary'
                            loading={loading}
                            onClick={() => form.submit()}
                        >查询</Button>
                        {appendButton?.({ loading })}
                        {
                            items.length >= cloWidth && <a style={{ whiteSpace: "nowrap" }} onClick={() => setCollapsed(!collapsed)}>
                                {collapsed ? '展开 ' : '收起 '}
                                {collapsed ? <DownOutlined /> : <UpOutlined />}
                            </a>
                        }
                    </Space>
                </Form.Item>
            </Col>
        </Row>
        {
            appendOthers?.({ loading })
        }
    </Form >
}
