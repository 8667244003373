export const noop = () => {};

export const UNDEFINED = undefined;
export const OBJECT = Object;
export const rAF = (f) =>
    window['requestAnimationFrame'] ? window['requestAnimationFrame'](f) : setTimeout(f, 1)
export const isUndefined = (v) => v === UNDEFINED;
export const isFunction = (v) => typeof v == 'function';
export const mergeObjects = (a, b) => ({ ...a, ...b });

