/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 制作池待选入口
 */
import { useAccess } from '@hooks'
import { Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Distribute from '@views/pool/distribute'
import Receive from '@views/pool/receive'
import Operate from '@views/pool/operate'
import { PoolTitle } from './components/PoolTitle'
import './index.less'

const PoolModel = forwardRef(function (props, ref) {
  const isProduceAllot = useAccess('produce_allot') // 分配权限
  const isProduceTake = useAccess('produce_take') // 领取权限
  const isProduceSetting = useAccess('produce_setting') // 编辑权限
  const isDiscard = useAccess('task_discard') // 作废权限
  const isRollback = useAccess('produce_rollback') // 回退权限
  const isAssignStatus = useAccess('task_change_any_before_status') // 回退指定状态权限
  const [visible, setVisible] = useState(false)
  const [tmp, setTmp] = useState(0)// 1：分配， 2：领取， 3：编辑
  useEffect(() => {
    const t = isProduceAllot ? 1 : isProduceTake ? 2 : isProduceSetting ? 3 : 0
    setTmp(t)
  }, [isProduceAllot, isProduceTake, isProduceSetting])
  useImperativeHandle(ref, () => ({
    open () {
      setVisible(true)
    }
  }))

  function onCancel () {
    setVisible(false)
    const t = isProduceAllot ? 1 : isProduceTake ? 2 : isProduceSetting ? 3 : 0
    setTmp(t)
  }

  return <Modal
    width={1320}
    footer={false}
    destroyOnClose={true}
    centered={true}
    className={'amp-pool'}
    visible={visible}
    onCancel={onCancel}
    title={<PoolTitle tmp={tmp} setTmp={setTmp} />}>
    {/* 分配 */}
    {tmp === 1 && visible && isProduceAllot && <Distribute onCancel={onCancel} tmp={1} />}
    {/* 领取 */}
    {tmp === 2 && visible && isProduceTake && <Receive onCancel={onCancel} tmp={2} />}
    {/* 编辑 */}
    {tmp === 3 && visible && isProduceSetting && (
      <Operate
        onCancel={onCancel}
        tmp={3}
        isRollback={isRollback}
        isDiscard={isDiscard}
        isAssignStatus={isAssignStatus}
      />
    )}
  </Modal>
})
export default PoolModel
