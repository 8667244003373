import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { DatePicker, Form, Input, Select, Checkbox } from 'antd';
import { useSwr } from '@hooks';
import { projectSettingsAPI } from '@api';
import moment from 'moment';
import { QueryFilter, AuthButton } from '@components';
import { topicType, topicStatus } from './config';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

/**
 * 筛选表单
 * @returns {JSX.Element}
 */
const FilterForm = (props) => {
    const history = useHistory();
    const { count = 0, onChange } = props;

    const [info, setInfo] = useState({});

    /**
     * 获取策划人员列表
     */
    const { data: userList = [] } = useSwr('/user-relation/list', () =>
        projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } }).then((res) => {
            if (res.code === 1000) {
                return res.data.list.map((item) => {
                    return {
                        value: item.user_id,
                        label: item.name
                    };
                });
            }
        })
    );

    /**
     * 禁用时间 时间跨度为一年
     */
    const disabledDate = useCallback(
        (currentTime) => {
            const { start, end } = info;
            if (start) {
                const maxTime = moment(start).add(1, 'years');
                return !(currentTime.diff(start) > 0 && maxTime.diff(currentTime) > 0);
            } else if (end) {
                const minTime = moment(end).subtract(1, 'years');
                return !(currentTime.diff(start) < 0 && minTime.diff(currentTime) < 0);
            }
            return false;
        },
        [info]
    );

    /**
     * 打开创建选题Modal
     */
    const openEstablishModal = () => {
        const { location } = history;
        history?.push(`${location?.pathname}/create_topic`);
    };

    return (
        <div className="amp-filter-form">
            <div className="amp-filter-top">
                <QueryFilter
                    size="large"
                    className="amp-query-filter"
                    onFinish={onChange}
                    initialValues={{ type: 0, status: 0 }}
                    appendOthers={() => {
                        return (
                            <div className="amp-filter-sort">
                                <span className="amp-filter-title">大纲类目 ({count})</span>
                                <FormItem className="amp-filter-sort-form" label="" shouldUpdate>
                                    {(form) => {
                                        return (
                                            <>
                                                <FormItem name="sort" hidden>
                                                    <Input />
                                                </FormItem>
                                                <Checkbox.Group
                                                    value={form.getFieldValue('sort')}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        form.setFieldsValue({ sort: value });
                                                        form.submit();
                                                    }}>
                                                    <Checkbox value={0}>按时间</Checkbox>
                                                    <Checkbox value={1}>按大纲</Checkbox>
                                                </Checkbox.Group>
                                            </>
                                        );
                                    }}
                                </FormItem>
                            </div>
                        );
                    }}>
                    <FormItem colon={false} label="搜索" name="topic_id_name">
                        <Input allowClear maxLength={50} placeholder="请输入ID或者名称" />
                    </FormItem>
                    <FormItem colon={false} label="选题性质" name="type">
                        <Select allowClear placeholder="" options={topicType} />
                    </FormItem>
                    <FormItem colon={false} label="策划人员" name="plan_user_id">
                        <Select
                            showSearch
                            allowClear
                            placeholder="请选择策划人员"
                            optionFilterProp="label"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            options={userList}
                        />
                    </FormItem>
                    <FormItem colon={false} label="创建时间" name="create_time">
                        <RangePicker
                            allowClear
                            disabledDate={disabledDate}
                            onCalendarChange={(dates, dateStrings, i) => {
                                if (!Object.keys(info).length) {
                                    if (i.range === 'start') {
                                        setInfo({ start: dates?.[0].toDate().getTime() });
                                    } else {
                                        setInfo({ end: dates?.[1].toDate().getTime() });
                                    }
                                }
                            }}
                            onOpenChange={(open) => {
                                setInfo({});
                            }}
                        />
                    </FormItem>
                    <FormItem
                        colon={false}
                        label='状态'
                        name='status'
                        hidden
                    >
                        <Select
                            allowClear
                            placeholder='请选择状态'
                            options={topicStatus}
                        />
                    </FormItem>
                </QueryFilter>
                <AuthButton permission="topic_create" type="primary" className="amp-button-primary" onClick={openEstablishModal}>
                    创建选题
                </AuthButton>
            </div>
        </div>
    );
};

export default FilterForm;
