/**
 * @name: RenderListLoading.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 列表加载状态
 */
import { Skeleton, Spin } from 'antd';

const RenderListLoading = function({size}){
    if(size === 1){
        return <div style={{
            padding: '16px 32px'
        }}>
            <Skeleton active />
            <Skeleton style={{marginTop: 14}} active />
            <Skeleton style={{marginTop: 14}} active />
        </div>
    } else {
        return <Spin size={'large'} />
    }

}
   export default RenderListLoading
