import { RECEIVE_TASKS, REQUEST_TASKS } from '@actions/action_consts'
import { combineReducers } from 'redux'

const pages = (state = {}, action = {}) => {
  Object.freeze(state);
  const params = action.params ? action.params : {}
  const list_type = params.list_type ? params.list_type : ""
  let items = state[list_type] && params.page !== 1 ? state[list_type].items : []

  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        [action.params.list_type]: {
          items: [...items],
          fetching: true,
        },
      };
    case RECEIVE_TASKS:
      let list = action.tasks.list;
      let newItems = list?.map((task) => task.id);
      return {
        ...state,
        [action.params.list_type]: {
          items: [...items, ...newItems],
          fetching: false,
        },
      };
    default:
      return state;
  }
};

const currentPage = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        [action.params.list_type]: action.params.page,
      };
    case RECEIVE_TASKS:
      return {
        ...state,
        [action.params.list_type]: action.params.page,
      };

    default:
      return state;
  }
};

const total = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TASKS:
      return {
        ...state,
        [action.params.list_type]: action.tasks.total,
      };

    default:
      return state;
  }
};

const status_total = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TASKS:
      return {
        ...state,
        [action.params.list_type]: action.tasks.status_total,
      };

    default:
      return state;
  }
};

const filter = (filter = {}, action = {}) =>
  action.type === REQUEST_TASKS ? action.params.filter || {} : filter;

export const tasksPagination = combineReducers({
  pages,
  currentPage,
  filter,
  total,
  status_total,
});
