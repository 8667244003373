import axios from 'axios';
import { Server } from '@gUtils';

const businessDataAPI = {
    /**
     * kpi制作组 组列表
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    getGroupList(data) {
        return axios.post('/api/group-produce-info/list', data)
    },

    /**
     * kpi制作组 组员列表
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    getGroupUserList(data) {
        return axios.post('/api/user-produce-info/list', data)
    },

    /**
     * kpi 统计明细
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    getStatisticsDetail(data) {
        return axios.post('/api/kpi-asset-info/list', data)
    },

    /**
     * kpi 周月导出
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    exportWeekOrMonthSummary(data) {
        return Server.post('/api/month-or-week-kpi/export', data)
    },

    /**
     * kpi 详情导出
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    exportWeekOrMonthDetail(data) {
        return Server.post('/api/kpi-asset-info/export', data)
    },

    /**
     * 获取日历面板data
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    getListWorkDay(data) {
        return axios.post('/api/workday/list', data)
    },

    /**
     * 更新日历面板
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    saveWorkDay(data) {
        return axios.post('/api/workday/save', data)
    },

    /**
     * 获取月度补分
     * @param {Object} data 请求参数
     * @returns {Object}
     */
    getSupplyScore(data) {
        return axios.post('/api/supply-score/get',data)
    },

    /**
     * 获取月度补分
     * @param {Object} data 请求参数
     * @returns {Object}
     */
     upDataSupplyScore(data) {
        return axios.post('/api/supply-score/update',data)
    }
};

export default businessDataAPI;
