import React, { Component } from 'react';
import AnnotationItem from './render/annotation_item';
import { Iconfont, Collapse, $success, $error } from '@components';
import { getUserAuth } from '@/api/server_api/user';
import AddAnnotation from './render/add_annotation';
import { Spin, Empty } from 'antd';
import { Typography } from '@components';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import './index.less';

/**
 * 批注组件，入参type/id
 * type代表关联对象是选题/场景等，number，2-选题，3-场景，4-任务包，5-立项
 * id代表对应的id值，number
 */
class TopicAnnotation extends Component {
    state = {
        annotation_list: [],
        modalShow: false,
        loadingAnnotation: true,
        isCreateAnnotation: false,
        isDeleteAnnotation: false,
        businessId: this?.props?.id
    };
    componentDidMount = () => {
        this.getAnnotationList();
        this.fetchPermission();
    };

    fetchPermission = () => {
        getUserAuth().then((res) => {
            const createRes = res?.list?.indexOf('topic_annotation_create') > -1;
            const deleteRes = res?.list?.indexOf('topic_annotation_delete') > -1;
            this.setState(() => {
                return {
                    ...this.state,
                    isCreateAnnotation: createRes,
                    isDeleteAnnotation: deleteRes
                };
            });
        });
    }

    componentDidUpdate = (props, state) => {
        if (this.props?.id !== this.state?.businessId) {
            this.setState(() => {
                return {
                    annotation_list: [],
                    modalShow: false,
                    loadingAnnotation: true,
                    isCreateAnnotation: false,
                    isDeleteAnnotation: false,
                    businessId: this?.props?.id
                }
            }, () => {
                this.getAnnotationList();
                this.fetchPermission();
            })
        }
    }

    /**
     * 更新批注列表
     */
    getAnnotationList = () => {
        this.setState(
            () => {
                return {
                    ...this.state,
                    annotation_list: [],
                    loadingAnnotation: true
                };
            },
            () => {
                SelectedTopicApi()
                    .listTopicAnnotation({
                        businessId: this?.state?.businessId, // 关联对象的id值
                        type: this?.props?.type || 2 // 关联对象，默认值选题
                    })
                    .then((res) => {
                        this.setState({
                            ...this.state,
                            annotation_list: res?.listList || [],
                            loadingAnnotation: false
                        });
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            }
        );
    };

    /**
     * 删除批注
     * @param {*} requestObj { id， comment }
     */
    deleteAnnotation = (requestObj) => {
        SelectedTopicApi()
            .deleteAnnotation({
                ...requestObj
            })
            .then(() => {
                $success('删除成功');
                this.getAnnotationList();
            })
            .catch((err) => {
                $error(err?.message || '删除失败');
                throw new Error(err);
            });
    };

    /**
     * 渲染批注信息
     * @param {*} list 批注信息列表
     */
    renderAnnotation = (list, flag) => {
        const temp = [];
        list?.map((item) => {
            temp.push(
                <div key={item?.id} className="amp-b-8">
                    <AnnotationItem {...item} deleteAnnotation={this.deleteAnnotation} isDelete={flag} />
                </div>
            );
        });
        return temp;
    };

    /**
     * 控制弹窗关闭与开启
     * @param {*} status 弹窗状态
     */
    setModalShow = (status) => {
        this.setState(() => {
            return {
                ...this.state,
                modalShow: status
            };
        });
    };

    render() {
        const { annotation_list, modalShow, loadingAnnotation, isCreateAnnotation, isDeleteAnnotation } = this?.state;
        const annotationHeader = (
            <div className="amp-annotation-header">
                <div className="amp-annotation-header-title">
                    <span className="amp-annotation-header-title-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.3206 6.44888C18.4027 6.44888 18.4758 6.5187 18.4758 6.59726V14.6534C18.4758 14.7319 18.4027 14.8017 18.3206 14.8017H13.6201C13.5653 14.8017 13.5106 14.8279 13.4832 14.8803L12.0593 17.4726C12.0319 17.5249 11.9772 17.5511 11.9224 17.5511C11.8677 17.5511 11.8129 17.5249 11.7855 17.4638L10.5077 14.8803C10.4803 14.8279 10.4256 14.793 10.3708 14.793H5.67028C5.58813 14.793 5.51512 14.7232 5.51512 14.6446V6.59726C5.51512 6.5187 5.58813 6.44888 5.67028 6.44888H18.3206M18.3206 5H5.67028C4.74843 5 4 5.71571 4 6.59726V14.6534C4 15.5349 4.74843 16.2506 5.67028 16.2506H9.50371L10.4164 18.0923C10.6902 18.6421 11.2835 19 11.9224 19C12.5431 19 13.109 18.6771 13.401 18.1446L14.4507 16.2419H18.3297C19.2516 16.2419 20 15.5262 20 14.6446V6.59726C19.9909 5.71571 19.2424 5 18.3206 5V5ZM8.4267 9.66958C7.80605 9.66958 7.31318 10.1496 7.31318 10.7344C7.31318 11.3192 7.80605 11.808 8.4267 11.808C9.04735 11.808 9.54022 11.3279 9.54022 10.7431C9.54022 10.1584 9.04735 9.66958 8.4267 9.66958ZM11.9954 9.66958C11.3748 9.66958 10.8819 10.1496 10.8819 10.7344C10.8819 11.3192 11.3748 11.808 11.9954 11.808C12.6161 11.808 13.109 11.3279 13.109 10.7431C13.109 10.1584 12.6161 9.66958 11.9954 9.66958V9.66958ZM15.5642 9.66958C14.9435 9.66958 14.4507 10.1496 14.4507 10.7344C14.4507 11.3192 14.9527 11.7993 15.5642 11.7993C16.1848 11.7993 16.6777 11.3192 16.6777 10.7344C16.6777 10.1496 16.1848 9.66958 15.5642 9.66958Z"
                                fill="#29C1B9"
                            />
                        </svg>
                    </span>
                    <span className="amp-annotation-header-title-content">批注信息</span>
                </div>
            </div>
        );
        const temp = this?.renderAnnotation(annotation_list, isDeleteAnnotation);
        const renderObj = annotation_list?.length ? (
            temp
        ) : (
            <Empty image={`${process.env.PUBLIC_URL}/images/other/image2.png`} description="暂无数据" />
        );

        return (
            <div className="amps-annotation">
                {isCreateAnnotation ? (
                    <div className="amp-annotation-header-operation">
                        <span
                            className="amp-add-annotation"
                            onClick={() => {
                                this.setModalShow(true);
                            }}>
                            添加批注
                        </span>
                    </div>
                ) : null}
                <Collapse header={annotationHeader} opened={true}>
                    <div className="amp-annotation-content">{loadingAnnotation ? <Spin size={'large'} /> : renderObj}</div>
                </Collapse>
                <AddAnnotation
                    width={708}
                    modalShow={modalShow}
                    setModalShow={this.setModalShow}
                    type={this?.props?.type}
                    businessId={this?.props?.id}
                    getAnnotationList={this.getAnnotationList}
                />
            </div>
        );
    }
}

export default TopicAnnotation;
