import { Form, Select } from "antd";
import { rules, projectDIC } from "@vars/views/projects_vars";
import { emptyProject } from "@vars/views/projects_vars";

const { Option } = Select;
const AddEditPhotographer = (props) => {
  const { users, projects, projectId } = props;
  const currentProject = projects[projectId] || emptyProject;
  const {photographer} = currentProject
  const options = users
    ? Object.values(users)?.map((user, idx) => (
        <Option key={idx} value={user.id} label={user.name}>
          {user.name}
        </Option>
      ))
    : [];
  return (
    <Form.Item
      className="form-item"
      name="photographer_id"
      rules={rules.photographer}
      label={projectDIC.photographer.title}
      initialValue={photographer ? photographer.id !== 0 ? photographer.id : null : null}
    >
      <Select
        className="select-sz-m"
        size="large"
        placeholder={projectDIC.photographer.formPlaceholder}
        isPhotographers
        showSearch optionFilterProp="children" showArrow
      >
        {options}
      </Select>
    </Form.Item>
  );
};
export default AddEditPhotographer;
