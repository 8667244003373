/**
 * @name: filterForm.jsx
 * @user: cfj
 * @date: 2022/4/22
 * @description: 表格筛选
 */
import { Col, Form, Row } from 'antd';
import { $error, Button, DatePicker, Input, Select } from '@components';
import { getVoidStatusOptions } from '@gUtils/getVoidStatusOptions';
import { useMemo } from 'react';
import { exportDiscardLog } from '@api';
import { boldExportFile } from '@gUtils/downFile';
import { momentArrDataToTimes } from '@views/4_stats/destroy/index';

const { RangePicker } = DatePicker;
export const FilterForm = function (props) {
    const { value, onChange, isValidating } = props;

    const [form] = Form.useForm();
    const initialValues = useMemo(() => value, []);

    function resetFields() {
        form.resetFields();
        onChange(form.getFieldsValue());
    }

    function exportFile() {
        const { create_time, discard_time, ...other } = form.getFieldsValue();
        exportDiscardLog({
            ...momentArrDataToTimes(create_time, 'create_time'),
            ...momentArrDataToTimes(discard_time, 'discard_time'),
            ...other
        })
            .then((blob) => {
                boldExportFile(blob);
            })
            .catch((err) => {
                $error(err.message);
            });
    }

    return (
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={(values) => {
                onChange(values);
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Row>
                <Col md={24} lg={17}>
                    <Row gutter={30}>
                        <Col xs={12} md={8}>
                            <Form.Item name={'asset_uid_name'} label={'搜索资产'}>
                                <Input maxLength={50} placeholder={'请输入资产ID或者名称'} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name={'discard_operator_name'} label={'作废人'}>
                                <Input maxLength={50} placeholder={'请输入姓名'} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name={'discard_type'} label={'作废类型'}>
                                <Select
                                    placeholder={'请选择作废类型'}
                                    allowClear
                                    options={getVoidStatusOptions().map((i) => ({
                                        label: i.label,
                                        value: i.code
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name={'create_time'} label={'创建时间'}>
                                <RangePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name={'discard_time'} label={'作废时间'}>
                                <RangePicker />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name={'project_name'} label={'项目名称'}>
                                <Input maxLength={50} placeholder={'请输入项目名称'} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col md={24} lg={7} className={'flex-align-end pb-3'}>
                    <div className={'d-flex flex-nowrap '}>
                        <Button style={{ minWidth: 100 }} loading={isValidating} className={'mr-1'} type={'primary'} onClick={form.submit}>
                            查询
                        </Button>
                        <Button style={{ minWidth: 100 }} className={'mr-3'} onClick={resetFields}>
                            重置
                        </Button>
                        <Button style={{ minWidth: 100 }} onClick={exportFile}>
                            导出
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
