/* eslint-disable no-unused-expressions */
import { forwardRef, useImperativeHandle } from 'react';
import { Col, Form, Input, Row } from 'antd';

interface IFormProps {
    initialValues: {
        username?: string,
        next?: string,
        operation?: string
    }
}

/**
 * ApprovedForm 审核通过
 * @param {IFormProps} props props
 * @returns {React.ReactNode}
 */
export const ApprovedForm = forwardRef((props: IFormProps, ref: any) => {
    const { initialValues } = props;
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        submit: form.submit,
        getForm: () => form
    }));

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{ ...initialValues }}
        >
            <Row gutter={40} justify="start">
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="username"
                        label="审核人"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="next"
                        label="下一步"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name="operation"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        colon={false}
                        name="comment"
                        label="备注信息"
                    >
                        <Input.TextArea
                            maxLength={500}
                            autoSize={{ minRows: 3.5, maxRows: 3.5 }}
                            placeholder="我需要说点什么..."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

/**
 * cancelForm 作废
 * @param {IFormProps} props props
 * @returns {React.ReactNode}
 */
export const CancelForm = forwardRef((props: IFormProps, ref: any) => {
    const { initialValues } = props;
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit: form.submit,
        getForm: () => form
    }));
    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{ ...initialValues }}
        >
            <Row gutter={40} justify="start">
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="username"
                        label="审核人"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="next"
                        label="下一步"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name="operation"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        colon={false}
                        name="comment"
                        label="备注信息"
                    >
                        <Input.TextArea
                            maxLength={500}
                            autoSize={{ minRows: 3.5, maxRows: 3.5 }}
                            placeholder="我需要说点什么..."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

/**
 * rejectForm
 * @param {IFormProps} props props
 * @returns {React.ReactNode}
 */
export const RejectForm = forwardRef((props: IFormProps, ref: any) => {
    const { initialValues } = props;
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit: form.submit,
        getForm: () => form
    }));
    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{ ...initialValues }}
        >
            <Row gutter={40} justify="start">
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="username"
                        label="审核人"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        colon={false}
                        name="next"
                        label="下一步"
                    >
                        <Input
                            className="amp-action-input"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name="operation"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        colon={false}
                        name="comment"
                        label="备注信息"
                    >
                        <Input.TextArea
                            maxLength={500}
                            autoSize={{ minRows: 3.5, maxRows: 3.5 }}
                            placeholder="我需要说点什么..."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});
