import { Form, Input, Select, Button, AutoComplete } from "antd";
import React, { Component, useState, useRef } from "react";
import {
  name,
  username,
  userDIC,
  rules,
} from "@vars/views/user_vars";
import { AuthButton } from "@/components";

const { Option } = AutoComplete;

const UserNameInput = (props) => {
  let item = userDIC.username;
  const { data, type } = props;
  let disabled = type === "edit" ? true : false;
  return (
    <Form.Item
      className="form-item"
      label={item.title}
      name={username}
      rules={rules.username}
      initialValue={data.username}
    >
      <Input
        className="normal-input"
        placeholder={item.placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

class EmailInput extends Component {
  state = {
    options: [],
  };

  handleSearch = (value) => {
    let options;
    if (!value || value.indexOf("@") >= 0) {
      options = [];
    } else {
      options = [`${value}@ecoplants.tech`];
    }
    this.setState({ options });
  };

 render() {
    const { data, type } = this.props;
    const { options } = this.state;
    let disabled = type === "edit" ? true : false;
    return (
      <Form.Item
        className="form-item edit-user-select"
        label={userDIC.email.title}
        name="email"
        rules={rules.email}
        initialValue={data && data.email ? data.email : ""}
        required
      >
        <AutoComplete
          placeholder="lisi@ecoplants.tech"
          disabled={disabled}
          onSearch={this.handleSearch}
          allowClear
        >
          {options?.map((email) => (
            <Option value={email} key={email}>
              {email}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
    );
  };
}

const NameInput = (props) => {
  let item = userDIC.name;
  const { data, type } = props;
  let disabled = type === "edit" ? true : false;
  return (
    <Form.Item
      className="form-item"
      label={item.title}
      name={name}
      rules={rules.name}
      initialValue={data.name}
    >
      <Input
        className="normal-input"
        placeholder={item.placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const PasswordInput = (props) => {
  let item = userDIC.password;
  const { data, type, editPsd } = props;
  let disabled = type === "edit" && !editPsd ? true : false;
  if (type === "edit" && !editPsd) {
    item.placeholder = "**********";
  } else if (type === "edit" && editPsd) {
    item.placeholder = "请输入密码";
  } else {
    item.placeholder = "请输入初次登录使用的密码";
  }
  return (
    <Form.Item
      className="form-item"
      label={item.title}
      name="password"
      rules={!disabled ? rules.password : ""}
      initialValue={data.password}
      required
    >
      <Input
        className="normal-input"
        placeholder={item.placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

class RoleSelect extends Component {
  showOption = (data) => {
    return data?.map((e) => (
      <Option value={e.id} label={e.name} key={e.id}>
        {e.name}
      </Option>
    ));
  };

 render() {
    let item = userDIC.role;
    const { data, roleOption } = this.props;
    return (
      <Form.Item
        className="form-item edit-user-select"
        label={item.title}
        name="role_id"
        initialValue={
          data && data.role && data.role.length !== 0 ? data.role.id : ""
        }
        rules={rules.role}
        required
      >
        <Select
          placeholder="请选择角色"
          value="role_id"
          style={{ width: 260 }}
          allowClear
          showSearch optionFilterProp="children" showArrow
        >
          {this.showOption(roleOption)}
        </Select>
      </Form.Item>
    );
  };
}

class GroupSelect extends Component {
  showOption = (data) => {
    return data?.map((e) => (
      <Option value={e.id} label={e.name} key={e.id}>
        {e.name}
      </Option>
    ));
  };
 render() {
    let item = userDIC.group;
    const { data, groupOption } = this.props;
    return (
      <Form.Item
        className="form-item edit-user-select"
        label={item.title}
        name="group_id"
        initialValue={data.group.id ? data.group.id : null}
      >
        <Select
          placeholder="请选择组织"
          value="group_id"
          style={{ width: 260 }}
          allowClear
          showSearch optionFilterProp="children" showArrow
        >
          {this.showOption(groupOption)}
        </Select>
      </Form.Item>
    );
  };
}
class StatusInput extends Component {
  handleSolveSave = () => {
    this.handleUnlock();
  };
  handleUnlock = () => {
    let param = {
      id: this.props.data.id,
    };
    this.props.unlockUser(param, this.props.handleSolve);
  };

  showBtn = () => {};
 render() {
    let item = userDIC.status;
    const { type, data } = this.props;
    let status = type === "add" ? "正常" : !data.is_locked ? "正常" : " 锁定";
    return (
      <div className="user-form">
        <Form.Item
          className="user-item-input "
          label={item.title}
          name={status}
          initialValue={status}
        >
          <Input
            disabled
            className="normal-input cursor-default"
            placeholder={item.placeholder}
          />
        </Form.Item>
        {type === "edit" && status !== "正常" ? (
          <AuthButton
            className="edit-user-text-btn"
            type="text"
            block
            onClick={this.handleSolveSave}
          >
            解锁
          </AuthButton>
        ) : null}
      </div>
    );
  };
}

export {
  UserNameInput,
  EmailInput,
  NameInput,
  PasswordInput,
  RoleSelect,
  GroupSelect,
  StatusInput,
};
