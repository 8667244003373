import {
    combineReducers
} from "redux";
import {
    sessionErrorsReducer
} from "@reducers/0_session/";
import {
    projectsErrorsReducer,
    tasksErrorsReducer,
    usersErrorsReducer
} from '@reducers/1_entities'

const errorsReducer = combineReducers({
    session: sessionErrorsReducer,
    projects: projectsErrorsReducer,
    tasks: tasksErrorsReducer,
    users: usersErrorsReducer
})

export default errorsReducer