/**
 * @name: MissionInfoForm.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 任务信息筛选
 */
import { Col, Form, Row } from 'antd';
import React from 'react';
import { Input, Select } from '@components';

const defaultStatics = [
    {
        label: '未审核',
        value: 0,
        key: 1
    },
    {
        label: '通过',
        value: 0,
        key: 2
    },
    {
        label: '驳回',
        value: 0,
        key: 3
    },
    {
        label: '作废',
        value: 0,
        key: 4
    }
];
const MissionInfoForm = function(props) {
    const {
        statics,
        value,
        onChange
    } = props;
    const [form] = Form.useForm();
    return <Form form={form} initialValues={value} onValuesChange={(values) => onChange(state => Object.assign({}, state, values))}
                 className={'mission-info-form'}>
        <Row gutter={10} align={'center'}>
            <Col span={9} className={'mission-info-form-statics'}>
                {defaultStatics.map(i => <p key={i.key} className={'mission-info-form-desc'}>
                    <span className={'mission-info-form-label'}>{i.label}:</span>
                    <span className={'mission-info-form-value'}>{statics?.find(item => item.status === i.key)?.count || i.value}</span>
                </p>)}
            </Col>
            <Col span={15}>
                <Row gutter={10}>
                    <Col span={8}>
                        <Form.Item name={'has_related'} noStyle initialValue={''}>
                            <Select options={[
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '已上传',
                                    value: 1
                                },
                                {
                                    label: '未上传',
                                    value: 2
                                }
                            ]} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'type'} noStyle initialValue={''}>
                            <Select options={[
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '任务包任务',
                                    value: 1
                                },
                                {
                                    label: '自建任务',
                                    value: 2
                                }
                            ]} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'title'} noStyle>
                            <Input placeholder={'根据任务名模糊匹配'} />
                        </Form.Item>
                    </Col>
                </Row>

            </Col>
        </Row>

    </Form>;
};
export default MissionInfoForm;
