import React, { Component } from "react";
import { connect } from "react-redux";
import { observer, inject } from "mobx-react";
import {
  TitleBasics,
  BundAccount,
  OptionCard,
  CodeDialog,
  UnbindDialog,
} from "./";
import { Form } from "antd";
import { wechatCards } from "@vars/views/account_vars";
import { debounce } from "lodash";
import { wechatSubscription } from "@/utils/config";
import { connectUserSession } from "@gUtils";
import { autorun } from "mobx";
import { $success, $error } from "@components";

let stompClient;
@inject("messagesStore")
@observer
class Messages extends Component {
  formRef = React.createRef();
  get store() {
    return this.props.messagesStore;
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    this.store.clearWechat();
    stompClient.disconnect()
  }

  initialize = () => {
    const { id } = this.props.session;
    this.store.fetchWechatDetails().then(() => {
      this.syncFormWithStore();
    });
    stompClient = wechatSubscription(id, this.updateWechat);
    this.listenToWechatUpdate();
  };

  syncFormWithStore = () => {
    this.formRef.current &&
      this.formRef.current.setFieldsValue(this.store.wechatInfo);
  };

  listenToWechatUpdate = () => {
    autorun(() => {
      if (this.store.wechatInfo) {
        this.syncFormWithStore();
      }
    });
  };

  updateWechat = ({ body }) => {
    const { message, code, ...rest } = JSON.parse(body);
    if (code === 1000) {
      $success(message);
      this.store.updateWechatInfo(rest);
    } else {
      $error(message);
      this.store.clearWechat();
    }
    this.store.hideCode();
  };

  onBindClick = () => {
    if (!this.store.isBund) this.store.showCode();
    else this.store.showUnbind();
  };

  handleFormChange = debounce((_, allValues) => {
    this.store.updateWechatOptions(allValues);
  }, 800);

  renderOptions = () => {
    return (
      <div className="wechat-background">
        <Form ref={this.formRef} onValuesChange={this.handleFormChange}>
          <div className="display-flex" style={{ flexFlow: "row wrap" }}>
            {wechatCards.map(({ name, label, title }) => {
              if (name === "task_count_notification") {
                return this.props.userPermissions.indexOf("task_count_notification") > -1 && <OptionCard name={name} label={label} title={title} key={name} />
              } else {
                return <OptionCard name={name} label={label} title={title} key={name} />
              }
            })}
          </div>
        </Form>
      </div>
    );
  };

  render() {
    return (
      <>
        <CodeDialog />
        <UnbindDialog />
        <div>
          <TitleBasics
            isBund={this.store.isBund}
            onBindClick={this.onBindClick}
          />
          <BundAccount wechatInfo={this.store.wechatInfo} onRefresh={()=> this.store.refreshWechat()} />
          {this.renderOptions()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});
export default connectUserSession(connect(mapStateToProps)(Messages));
