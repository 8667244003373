import { forwardRef, useImperativeHandle } from 'react';
import { Select } from 'antd';
import { clsx } from '@gUtils';
import './index.less';
import { useSwr } from '@hooks';
import useDebounceState from '@hooks/useDebounceState';

/**
 * 下拉选择
 * @param {object} props 参数
 * @param {{value:any,label:any,disabled: boolean}[]} props.options 下拉选项
 * @param {(inputValue: string) => any[]} props.setKey swr 的唯一key
 * @param {(...any[]) => Promise<{value:any,label:any,disabled: boolean}[]>} props.feature swr的feature
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Index = forwardRef((props, ref) => {
    const {
        className,
        setKey,
        feature,
        swrConfig,
        onSearch,
        showSearch,
        options = [],
        children,
        onChange,
        ...other
    } = props;
    const [searchValue, debounceState, setState] = useDebounceState(undefined);
    const key = options?.length ? null : setKey?.(debounceState);
    const {
        data,
        isValidating,
        error,
        mutate
    } = useSwr(key, feature, {
        revalidateIfStale: false,
        fallbackData: options,
        ...swrConfig
    });
    function updateValue(options) {
        if (other.value !== undefined && options?.length > 0) {
            if (Array.isArray(other.value)) {
                const values = other.value;
                onChange(other.value, options.filter((i) => values.includes(i.value)));
            } else {
                onChange(other.value, options.find((i) => i.value === other.value));
            }
        }
    }
    useImperativeHandle(ref, () => ({
        refresh(options) {
            if (options?.length) {
                updateValue(options);
            } else {
                mutate()
                    .then((res) => {
                        updateValue(res);
                        return res;
                    });
            }
        }
    }), [other.value, options]);
    return (
        <>
            <Select
                size="large"
                status={error ? 'error' : undefined}
                showSearch={showSearch}
                searchValue={searchValue}
                onSearch={showSearch ? (value) => {
                    setState(value);
                    onSearch?.(value);
                } : undefined}
                className={clsx('amp-fixed-select', className)}
                loading={data?.length ? false : isValidating}
                onDropdownVisibleChange={(open) => {
                    if (open && error) {
                        mutate();
                    }
                }}
                onChange={onChange}
                {...other}
            >
                {
                    data?.map(({
                        value,
                        disabled,
                        label,
                        ...other
                    }) => (
                        <Select.Option {...other} key={value} disabled={disabled} value={value}>
                            {label}
                        </Select.Option>
                    ))
                }

            </Select>
            {children?.({
                value: other.value,
                data,
                isValidating
            })}
        </>

    );
});
export default Index;
