import React, { useRef, useEffect } from 'react'
import { sceneEventBus } from '@/event_bus'
import SceneButtons from './components/scene_buttons'
import ScrollContainer from '@/components/scroll_container'
import SceneItem from './components/scene_item'
import { AmpIcon } from '@components';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api'
import './index.less'

const TopicScene = (props) => {

    const { topicId } = props

    const listRef = useRef(null)

    useEffect(() => {
        /**
         * 刷新列表
         */
        const reFresh = () => {
            listRef.current?.refresh()
        }

        const off = sceneEventBus.on(reFresh);
        return off
    }, [sceneEventBus]);

    return (
        <div className="amp-topic-relation-scene">
            <div className="amp-topic-scene-header">
                <h4 className="amp-scene-header-title">
                    <AmpIcon className="fz-24" name="relation" />
                    <span className="mg-l-8">关联场景</span>
                </h4>
                <SceneButtons topicId={topicId} />
            </div>
            <ScrollContainer
                ref={listRef}
                pageSize={20}
                col={3}
                height="40vh"
                className={'amp-topic-relation-scene-scroll'}
                params={{topicId}}
                renderItem={(item, key) => <SceneItem data={item} key={key} />}
                request={(page, pageSize, params) => SelectedTopicApi().listTopicSceneAddress({
                    page: page,
                    page_size: pageSize,
                    ...params
                }).then(res => ({
                    list: res.listList,
                    total: res.total
                }))}
            />
        </div>
    )
}

export default TopicScene
