import { useState, useRef, useEffect, useCallback } from "react";
import { Input, Form } from "antd";
// import TripForm from "./components/trip_form";
import { emptyPlan } from "@vars/views/shooting_plan_vars";
import { observer, inject } from "mobx-react";
import SelectPlanUser from "./components/select_plan_user";
import "./index.less";
import { useSelector } from "react-redux";
import TripContent from "./trip_content";

/**
 * 右侧任务包创建
 * @param {*} props  包含了configsStore状态和参数
 * @returns
 */
const RightCreateTasks = (props) => {

    const formContentRef = useRef();
    const { setScrollTop, setFormRef, form, todosStore, taskPackageStore } = props;
    const { isShowEditCreate, currentTasks, getPlanningPersonList } = taskPackageStore
    const trips = currentTasks?.trips
    let projectId = todosStore.projectId || null;
    const [planDetails, setPlanDetails] = useState(emptyPlan);
    const formRef = useRef()
    // 创建者
    const userName = useSelector((state) => state.session.name);

    // 设置高度
    const onScroll = useCallback(
        (e) => {
            setScrollTop(e.target.scrollTop)
        },
        [setScrollTop]
    );

    // 监听滑动条的滑动高度
    useEffect(() => {
        getPlanningPersonList()
        if (formContentRef.current) {
            formContentRef.current.addEventListener("scroll", onScroll);
            setFormRef(formContentRef.current);
        }
        return () => {
            if (formContentRef && formContentRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                formContentRef.current.removeEventListener("scroll", onScroll);
            }
        };
    }, [onScroll, setFormRef]);

    // 监听滑动条的滑动高度
    useEffect(() => {
        if (projectId) {
            form.setFieldsValue({ project_id: parseInt(projectId) });
        }
    }, [projectId, form]);

    useEffect(() => {
        const { isShowEditCreate } = taskPackageStore
        if (isShowEditCreate) {
            const { title, project_id, description, location, no, users, trips, creator } = taskPackageStore.currentTasks
            let tripsList = trips?.map(item => {
                return {
                    ...item,
                    trip_type: (item.trip_type || {}).code || '',
                    transportation: (item.transportation || {}).code || ''
                }
            }) || []
            form.setFieldsValue({
                title: title,
                tasksId: no,
                username: creator.name,
                location: location,
                description: description,
                trips: tripsList
            });
        }


    }, [taskPackageStore, trips]);


    const updatePlanDetails = (val) => {
        form.setFieldsValue({ trips: val });
    };

    const changeHandle = (res) => {
        form.setFieldsValue('trips', res);
    }

    let tripsList = trips?.map(item => {
        return {
            ...item,
            trip_type: (item.trip_type || {}).code || '',
            transportation: (item.transportation || {}).code || ''
        }
    }) || []
    return (
        <div className="amps-right-create-tasks">
            <div className="create-tasks-body" ref={formContentRef}>
                <div className="create-tasks-title">{isShowEditCreate ? "编辑" : "创建"}任务包</div>
                <Form
                    className="create-tasks-form"
                    name="createTasks"
                    form={form}
                    layout="vertical"
                    validateMessages={{ required: "该项为必填项" }}
                    initialValues={{
                        username: userName,
                        description: "",
                        project_id: parseInt(projectId) || null,
                        trips: tripsList
                    }}
                    validateTrigger="onBlur"
                >
                    <div className="flex">
                        <Form.Item name="project_id" style={{ display: "none" }}>
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item
                            name="title"
                            label="任务包名称"
                            rules={[{ required: true }]}
                        >
                            <Input
                                className="task-input"
                                placeholder="请输入名称"
                                width={330}
                            />
                        </Form.Item>
                        <Form.Item name="tasksId" className="task-right" label="任务包编号">
                            <Input
                                className="task-input no-border"
                                disabled
                                placeholder="创建完成后自动生成"
                            />
                        </Form.Item>
                    </div>
                    <div className="flex">
                        <Form.Item name="username" label="创建人">
                            <Input className="task-input no-border" disabled placeholder="请输入名称" />
                        </Form.Item>

                        <Form.Item
                            name="location"
                            className="task-right"
                            label="相关地点"
                            shouldUpdate
                        >
                            <Input className="task-input" placeholder="请输入地点以方便拍摄人员安排出行计划" />
                        </Form.Item>
                    </div>
                    <SelectPlanUser form={form} taskPackageStore={taskPackageStore} />

                    {/* <TripForm
                        planDetails={planDetails}
                        updatePlanDetails={updatePlanDetails}
                        form={form}
                        currentTasks={taskPackageStore?.currentTasks}
                    /> */}
                    {/* <div className="required trip-color">行程</div> */}
                    <Form.Item
                        name="trips"
                        label="行程"
                        // noStyle={true}
                        rules={[{ required: true, message: "请填写行程" }]}
                    >
                        <TripContent
                            form={form}
                            list={tripsList}
                            onChange={(res) => { changeHandle(res) }}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        name="trips"
                        className="task-trips"
                    rules={[{ required: true, message: "请添加行程" }]}
                    >
                        <Input
                            style={{
                                display: "none",
                            }}
                        />
                    </Form.Item> */}
                    <div className="task-details">
                        <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                                let value = getFieldValue("description");
                                return (
                                    <>
                                        <Form.Item label="任务包描述" name="description">
                                            <Input.TextArea
                                                autoSize={{ minRows: 5, maxRows: 5 }}
                                                placeholder="请输入备注信息"
                                                maxLength={200}
                                            ></Input.TextArea>
                                        </Form.Item>
                                        <div className="details-count">
                                            (
                                            {(
                                                <span className={`${value && "count-text"}`}>
                                                    {(value || "").length}
                                                </span>
                                            ) || 0}
                                            /200)
                                        </div>
                                    </>
                                );
                            }}
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default inject("configsStore", "taskPackageStore")(observer(RightCreateTasks));
