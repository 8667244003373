import React, { Component } from "react";
import RelationList from "./relation_list";
import { inject, observer } from "mobx-react";
import { Select } from "antd";
import { AddRelation } from "./";
import { AuthButton } from "@components";
const { Option } = Select;

@inject("userRelationsStore", "addRelationStore")
@observer
class UserRelations extends Component {
  componentDidMount() {
    this.props.userRelationsStore.fetchRelationTypes();
  }

  componentWillUnmount() {
    this.store.changeActive(50);
  }

  get store() {
    return this.props.userRelationsStore;
  }

  handleChange = (value) => {
    this.store.changeActive(value);
  };

  showAdd = () => {
    this.props.addRelationStore.show();
  };

  renderSelect = () => {
    const options = this.store.relationTypes?.map((item) =>
      item.type ? (
        <Option key={item.type} value={item.type}>
          {item.title}
        </Option>
      ) : (
        ""
      )
    );
    return this.store?.relationTypes?.length > 0 ? (
      <Select
        className="select-sz-m"
        size="large"
        value={this.store.activeTypeCode}
        onChange={this.handleChange}
        showSearch optionFilterProp="children" showArrow
      >
        {options}
      </Select>
    ) : (
      ""
    );
  };

  render() {
    return (
      <div>
        <div className="space-between mg-b-20">
          <div>
            <span className="mg-r-10">人员类型</span>
            {this.renderSelect()}
          </div>

          <div>
            <AuthButton
              type="primary"
              onClick={this.showAdd}
              permission="relation_create"
            >
              新增
            </AuthButton>
          </div>
        </div>
        <AddRelation />
        <RelationList />
      </div>
    );
  }
}

export default UserRelations;
