import { flow, makeAutoObservable } from 'mobx';
import { $error, $success } from '@components';
import { projectsAPI, projectSettingsAPI, taskPackageAPI, taskPlanAPI } from '@api';
import { todosStore } from '@stores';

/**
 * 任务包数据
 */
class TaskPackageStore {
    constructor() {
        makeAutoObservable(this);
    }

    isShowEditCreate = false
    // 任务包id
    tasksId = null;
    // 策划数
    planNum = null;
    // 包的列表
    packageList = [];
    // 是否显示创建任务包的面板 true显示，反之显示任务包详情
    isPackagePanelShow = true;
    // 是否显示创建任务包的弹框
    isPackageModal = false;
    // 分页页数多少
    page = 10;
    // 一页多少条
    pageSize = 10;
    //  是否可以请求
    isRequest = true;
    // 当前选中的任务包数据
    currentTasks = {};
    // 任务包分页数据
    planTaskOutListRefresh = []
    // 任务包分页数据总量
    planTaskOutListTotal = 0
    planTaskOutStatics = []
    planTaskOutLoading = false
    // 策划人员列表
    planningPersonList = []
    // 任务包流程数据
    PackageHistoryData = []
    // 是否显示任务包审核弹框
    isPackageReview = false;
    // 状态数据 初始化数据
    statusTotal = [
        { color: "#70D9D9", count: 0, label: "策划中", status: 1 },
        { color: "#F6A3A3", count: 0, label: "策划待审", status: 2 },
        { color: "#BE6969", count: 0, label: "公开待选", status: 3 },
        { color: "#38ADAD", count: 0, label: "已领取", status: 4 },
    ];
    setShowEditCreate = (obj) => {
        this.isShowEditCreate = obj
    }
    // 驳回任务包的评论
    disapproveComment = "";
    // 公开任务包优先级
    publicPriorityTypes = 0;
    // 策划任务当前页
    planningPage = 1;
    // 策划任务筛选条件
    filtrateOptions = [1];
    // 策划任务list
    planningList = [];
    // 策划任务total
    planningListTotal = 0;
    // 策划任务加载中
    Isloading = true;
    // 当前任务包总评分
    currentTaskTotal = 0;
    // 当前人员所在小组剩余分数
    balanceTotal = 0;
    // 策划包审核人员列表
    taskApproveList = [];
    // 行程类别
    tripCategory = [];
    // 交通方式
    trafficMode = [];
    // 轮播图片数组
    slideImage = [];
    // 存任务包id
    setTaskId = (obj) => {
        this.tasksId = obj;
    };
    // 策划任务加载状态
    taskIsLoading = false;
    // 存是否请求的状态
    setIsRequest = (obj) => {
        this.isRequest = obj;
    };
    setPlanTaskOutListRefresh = (obj) => {
        if (obj === "change") {
            this.planTaskOutListRefresh = []
        } else {
            let arr = obj || [];
            let map = {};

            this.planTaskOutListRefresh.forEach((item) => {
                map[item.id] = item;
            })

            for (let i = arr.length-1; i >= 0; i--) {
                const item = arr[i];
                if (map[item.id]) {
                    map[item.id] = Object.assign(map[item.id], item)
                    arr.splice(i, 1);
                }
            }
            this.planTaskOutListRefresh = this.planTaskOutListRefresh.concat(arr);
        }
    }
    // 当前任务包
    setCurrentTasks = (obj) => {
        this.currentTasks = obj;
    };
    // 更新策划任务加载状态
    setTaskIsLoading = (bool) => {
        this.taskIsLoading = bool;
    };
    setPlanTaskOutLoading = bool => {
        this.planTaskOutLoading = bool
    }
    // 存任务包列表数据
    setSlideImage = (list) => {
        this.slideImage = list;
    };
    // 修改轮播图数据
    setIsPackagePanelShow = (obj) => {
        this.isPackagePanelShow = obj;
    };
    // 存任务包列表数据
    setPackageList = (obj) => {
        this.packageList = obj;
    };
    // 存页数
    setPage = (obj) => {
        this.page = obj;
    };
    // 存策划数
    setPlanNum = (obj) => {
        this.planNum = obj;
    };
    // 存状态数据
    setStatusTotal = (obj) => {
        this.statusTotal = obj;
    };
    // 存任务包弹框是否显示
    setIsPackageModal = (obj) => {
        this.isPackageModal = obj;
    };
    // 存任务包审核弹框是否显示
    setIsPackageReview = (obj) => {
        this.isPackageReview = obj;
    };
    // 任务包驳回评论
    setDisapproveComment = (str) => {
        this.disapproveComment = str;
    };
    // 公开任务包优先级
    setPublicPriorityTypes = (num) => {
        this.publicPriorityTypes = num;
    };
    // 修改策划任务当前页
    updatePlanningPage = (num) => {
        this.planningPage = num;
    }
    // 策划任务筛选条件修改
    updateFiltrateOptions = (num) => {
        this.planningList = [];
        this.updatePlanningPage(1);
        const len = this.filtrateOptions.length > 1;
        const flag = this.filtrateOptions.indexOf(num);
        if (flag > -1) {
            if (len) {
                this.filtrateOptions.splice(flag, 1);
            }
        } else {
            this.filtrateOptions.push(num);
        }
        this.getPlanTaskList();
    };
    // 更新策划任务list
    setPlanningList = (arr) => {
        this.planningList = [...this.planningList, ...arr];
    }
    // 更新策划任务total
    updatePlanningListTotal = (num) => {
        this.planningListTotal = num;
    };
    // 策划任务加载中状态更新
    updateIsloading = (bool) => {
        this.Isloading = bool;
    };
    // 实时更新当前任务包总评分
    updateCurrentTaskTotal = (num) => {
        this.currentTaskTotal = num;
    };
    // 更新当前人员所在小组剩余分数
    updateBalanceTotal = (num) => {
        this.balanceTotal = num;
    };
    // 更新策划包审核人员列表
    updateTaskApproveList = (list) => {
        this.taskApproveList = list;
    };
    // 更新行程类别
    updateTripCategory = (list) => {
        this.tripCategory = list;
    };
    // 更新交通方式
    updateTrafficMode = (list) => {
        this.trafficMode = list;
    };
    setPlanningPersonList = (list) => {
        this.planningPersonList = list
    }

    // 页面实时获取taskId
    get getTaskId() {
        return this.tasksId;
    }
    // 页面实时获取taskId
    get getIsPackageReview() {
        return this.isPackageReview;
    }
    // 创建任务包
    createTasksApi = flow(function* (params) {
        try {
            const res = yield taskPackageAPI.createPackage(params);
            if (res.code === 1000) {
                $success(res.msg);
                return res;
            }
        } catch (error) { }
    });
    // 更新任务包
    updatePackage = flow(function* (params) {
        try {
            const res = yield taskPackageAPI.updatePackage(params);
            if (res.code === 1000) {
                $success(res.msg);
                return res;
            } else {
                $error(res.msg)
            }
        } catch (error) { }
    });
    // 获取任务包列表接口
    getPackageList = flow(function* (params) {
        // this.setLoading(true);
        try {
            // isRequest=true可以请求，page页数是1可以请求
            if (this.isRequest || params.page === 1) {
                const res = yield taskPackageAPI.getPackageList(params);
                if (res.code === 1000) {
                    let list = res.data.list || [];
                    // 存储
                    this.setPackageList([...this.packageList, ...list]);
                    this.setPlanNum(res.data.total);
                    this.setStatusTotal(res.data.status_total);

                    if (list && list.length > 0) {
                        this.setPage(params.page + 1);
                    }
                    this.setIsRequest(true);
                    // 如果数据少于10条禁止请求
                    if (list && list.length < 10) {
                        this.setIsRequest(false);
                    }
                    return res;
                }
            }
        } catch (error) { }
    });
    // 更新左侧列表
    updatePackageList = () => {
        let projectId = todosStore.projectId || null;
        this.setPackageList([]);
        this.getPackageList({
            page: 1,
            page_size: this.pageSize,
            project_id: parseInt(projectId),
        });
    }
    // 任务包提交审核
    submitPackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.submitPackage({
                id: this.tasksId,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 任务包公开
    publicPackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.publicPackage({
                id: this.tasksId,
                priority: this.publicPriorityTypes,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 任务包驳回
    disapprovePackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.disapprovePackage({
                id: this.tasksId,
                comment: this.disapproveComment,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 任务包作废
    discardPackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.discardPackage({
                id: this.tasksId,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 领取任务包
    takePackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.takePackage({
                id: this.tasksId,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 获取策划任务
    getPlanTaskList = flow(function* () {
        const params = {
            "page": this.planningPage,
            "page_size": 30,
            "package_id": this.tasksId,
            filter: {
                status: this.filtrateOptions,
            }
        }
        if (this.Isloading) {
            this.updateIsloading(false);
            try {
                const res = yield taskPlanAPI.getPlanTaskList(params);
                if (res.code === 1000) {
                    this.updateIsloading(true);
                    this.setPlanningList(res.data.list)
                    this.updatePlanningListTotal(res.data.total)
                }
            } catch (error) { }
        }

    });
    getPlanTaskOutList = flow(function* () {
        return
        this.setTaskIsLoading(true);
        const params = {
            "page": this.planningPage,
            "page_size": 36,
            "package_id": this.tasksId,
            filter: {
                status: [1, 2, 3, 4],
            }
        }
        try {
            const res = yield taskPlanAPI.getPlanTaskList(params);

            if (res.code === 1000) {
                if (this.planningPage === 1 && res.data.list.length === 0) {
                    this.setPlanTaskOutLoading(true)
                }
                this.planTaskOutListTotal = res.data.total
                this.planTaskOutStatics = res.data.statics
                this.setPlanTaskOutListRefresh(res.data.list)
            }
        } catch (error) { }

    });

    // 获取当前任务包总评分
    getCurrentTaskPackageTotal = flow(function* () {
        try {
            const res = yield projectsAPI.getTaskPackageInfo(this.tasksId);
            if (res.code === 1000) {
                this.updateCurrentTaskTotal(res.data.score);
            }
        } catch (error) { }
    });
    // 获取当前人员所在小组剩余分数
    getPackageScore = flow(function* () {
        try {
            const res = yield taskPackageAPI.getPackageScore();
            if (res.code === 1000) {
                this.updateBalanceTotal(res.data.score);
            }
        } catch (error) { }
    });
    // 放弃任务包
    abandonPackage = flow(function* () {
        try {
            const res = yield taskPackageAPI.abandonPackage({
                id: this.tasksId,
                comment: this.disapproveComment,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) { }
    });
    // 获取策划审核人员
    getPlanningPerson = flow(function* (id) {
        try {
            const res = yield projectSettingsAPI.fetchUserRelations({
                filter: { type: 45 },
            });
            if (res.code === 1000) {
                this.updateTaskApproveList(res.data.list);
            }
        } catch (error) { }
    });
    // 获取策划人员列表
    getPlanningPersonList = flow(function* (id) {
        try {
            const res = yield projectSettingsAPI.fetchUserRelations({
                filter: { type: 17 },
            });
            if (res.code === 1000) {
                this.setPlanningPersonList(res.data.list)
            }
        } catch (error) { }
    });

    // 获取行程类别
    getTripCategory = flow(function* () {
        try {
            const res = yield projectSettingsAPI.fetchPlanConfigs({
                "filter": { type: 1 }
            });
            if (res.code === 1000) {
                this.updateTripCategory(res.data.list)
            }
        } catch (error) { }
    });
    getTrafficMode = flow(function* () {
        try {
            const res = yield projectSettingsAPI.fetchPlanConfigs({
                "filter": { type: 2 }
            });
            if (res.code === 1000) {
                this.updateTrafficMode(res.data.list);
            }
        } catch (error) { }
    })

    // 获取任务包流程
    getPackageHistory = flow(function* () {
        try {
            const res = yield taskPackageAPI.getPackageHistory(this.tasksId)
            if (res.code === 1000) {
                this.PackageHistoryData = res.data
            }
        } catch (error) {
        }
    })
}

export default new TaskPackageStore();
