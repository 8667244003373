import { useCallback, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Modal } from 'antd';
import { AuthButton } from '@components';
import NoTaskInfo from './plan_task_img/no_task_info';
import './index.less';
import TaskInfoImgItem from '@views/2_projects/task_index/task_package/components/taks_details/task_planning/task_info_img_item';

const { TextArea } = Input;

const TaskImg = (props) => {
    const { taskPlanStore, packageStatus, taskPackageStore, planningPage, planTaskOutListTotal,planTaskOutListRefresh } = props;
    const { commentModal, comment } = props.taskPlanStore;
    const [valueLength, setValueLength] = useState(comment?.length || 0);
    const [value, setValue] = useState("")
    const scroll = useRef();
    const hide = () => {
        taskPlanStore.setCommentModal(false);
    };
    const handleValue = (e) => {
        setValue(e.target.value)
        setValueLength(e.target.value.length === 0 ? comment.length : e.target.value.length)
    }

    const handleChange = () => {
        taskPlanStore.setPlanTaskComment(value);
    };


    /*当前data数据显示完且滚动到底部时更新数据*/
    const onScrollHandle = () => {
        const { current } = scroll
        const scrollTop = current.scrollTop;
        const clientHeight = current.clientHeight;
        const scrollHeight = current.scrollHeight;
        const isBottom = scrollTop + clientHeight + 10 > scrollHeight;
        if (isBottom && (planTaskOutListTotal / 36) > planningPage) {
            const changePage = planningPage + 1;
            taskPackageStore.updatePlanningPage(changePage);
            taskPackageStore.getPlanTaskOutList()
        }
    }


    const testReturn = useCallback(() => {
        return (
            planTaskOutListRefresh?.length > 0 ? (
                <div
                    className="tmp-task-info-images"
                    onScrollCapture={() => onScrollHandle()}
                    ref={scroll}
                >
                    {(planTaskOutListRefresh)?.map((item, index) => (
                        <TaskInfoImgItem key={item.id} item={item} width={155} height={155} packageStatus={packageStatus} />
                    ))}
                    <Modal
                        visible={commentModal}
                        className="amp-task-info-modal"
                        width="550px"
                        footer={null}
                        title="填写评论"
                        onCancel={hide}>
                        <div className="center">
                            <div className="content">
                                <TextArea
                                    defaultValue={comment}
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                    placeholder="请填写评论信息"
                                    onChange={(e) => handleValue(e)}
                                    bordered={false}
                                />
                                <div className="details-count">
                                    (
                                    {(
                                        <span className={`${valueLength && "count-text"}`}>
                                            {(valueLength || 0)}
                                        </span>
                                    ) || 0}
                                    /200)
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <AuthButton type="primary" className="btn-sz-m mg-r-20" onClick={handleChange}>
                                保存
                            </AuthButton>
                            <AuthButton className="btn-sz-m" onClick={hide}>
                                取消
                            </AuthButton>
                        </div>
                    </Modal>
                </div>
            ) : (
                <NoTaskInfo />
            )
        )
    }, [planTaskOutListRefresh])


    return testReturn()
};

export default inject('taskPlanStore')(observer(TaskImg));
