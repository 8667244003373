import React from 'react';
import "./index.less"

/**
 * DetailsTitle
 * @param {*} props props
 * @returns {*}
 */
const DetailsTitle = (props) => {
    const { name, number } = props
    return (
        <div className='amp-details-header'>
            <div className='amp-details-title'>{name}</div>
            <div className='amp-details-id'>{number}</div>
        </div>
    )
}
export default DetailsTitle
