import React from 'react'
import { Image } from 'antd'
import env from 'env';

const SceneItem = (props) => {
    const { data } = props

    const jumpSceneDetail = (id) => {
        // 新开页跳转场景详情
        window.open(`/select-topic-management/uphold/scene-manage/scene-details?sceneId=${id}`)
    }

    return (
        <div className="amp-scene-scroll-line">
            {
                data.map(item => (
                    <div className="amp-scene-scroll-line-item" key={item.id}>
                        <div className="amp-scene-item-wrap"
                            onClick={() => jumpSceneDetail(item.id)}
                        >
                            <div className="amp-scene-item-wrap-pic">
                                <Image
                                    fallback={env.placeImage}
                                    preview={false}
                                    className='amp-scene-item-image'
                                    src={item?.imagesList?.[0]?.fileUrl || ''}
                                />
                            </div>
                            <div className="amp-scene-item-content">
                                <h5 className="amp-scene-item-content-name">{item.name}</h5>
                                <div className="display-flex">
                                    <span className="amp-scene-item-content-number">{item.number}</span>
                                    <span className="amp-scene-item-content-tag">{item.statusName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SceneItem
