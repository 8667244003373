import { useRef, cloneElement } from 'react';

const CopeText = function ({ children, onCope, onError }) {
    const domRef = useRef();
    const element = cloneElement(children, {
        ref: domRef,
        onClick() {
            const range = document.createRange();
            window.getSelection().removeAllRanges();
            range.selectNode(domRef.current); // 选中需要复制的节点
            window.getSelection().addRange(range); // 执行选中元素
            const successful = document.execCommand('copy'); // 执行 copy 操作
            if (successful) {
                // 这里使用了antd的message组件
                onCope?.();
            } else {
                onError?.();
            }
            // 移除选中的元素
            window.getSelection().removeAllRanges();
        }
    });
    return element;
};
export default CopeText;
