import { message } from 'antd';

export const $success = (msg) => {
    if (!msg) return
    message.success({
        content: msg,
        style: {
            marginTop: "10vh",
        },
    });
};

// info对应删除成功message
export const $info = (msg) => {
    if (!msg) return
    message.info({
        content: msg,
        style: {
            marginTop: "10vh",
        },
    });
};

// warning对应不可xx
export const $warning = (msg) => {
    if (!msg) return
    message.warning({
        content: msg,
        style: {
            marginTop: "10vh",
        },
    });
};

export const $error = (msg) => {
    if (!msg) return
    message.error({
        content: msg,
        style: {
            marginTop: "10vh",
        },
    });
};

export const forceLogout = (cb) => {
    const content = "您的账户在另一地点登入，您已被迫登出";
    message.error({
        content,
        duration: 5,
        key: "force",
        style: {
            marginTop: "10vh",
        },
    });
};
