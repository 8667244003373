import React, { Component } from 'react'
import { observer, inject } from "mobx-react";
import { Form, Select } from "antd";
import { UserSelect, ProfileGroup } from "@components";
import { rules, projectDIC, emptyProject } from "@vars/views/projects_vars";
import { toJS } from "mobx";
import { CloseCircleFilled } from '@ant-design/icons';
const { Option } = Select;
@inject("configsStore", "projectStore", "userRelationsStore")
@observer
class AddEditConfidentialUsers extends Component {

    constructor(props){
        super(props)
        this.refForm = React.createRef();
    }

    state = {
        final: [],
    }
    get configsStore() {
        return this.props.configsStore;
    }
    get projectStore() {
        return this.props.projectStore;
    }
    get userRelationsStore() {
        return this.props.userRelationsStore;
    }

    changehandle = (e) => {
        this.projectStore.setSelectList(e);
    }
    clickHandle = (e) => {
        this.projectStore.setProfileGroupList(e);
        const final  = this.projectStore.getFinalId();
        this.props.refTag.current.setFieldsValue({
            confidential_user_ids: this.projectStore.unique([...final])
        })
    }
    render() {
        const { projects, projectId } = this.props;
        const currentProject = projects[projectId] || emptyProject;

        const usersList = this.projectStore.getAllUsersToOptions();

        const { final } = this.projectStore

        const checkedOptionList = this.projectStore.getDefaultCheckedOptions(toJS(final))
        
        const listGroup = this.projectStore.uniqueFunc(toJS(final)).map((item, index) => {
            return <div key={index} className="profile-box">
                <ProfileGroup size={48} people={[item]} key={index}/>
                <CloseCircleFilled 
                    className="close-icon" 
                    onClick={() => {this.clickHandle(item)}} 
                    key={item.id} 
                    style={{ color: "#9B9B9B" }} 
                />
            </div>
        })
        
        return (
            <div>
                <Form.Item
                    className="form-item confidential"
                    name="confidential_user_ids"
                    rules={rules.confidential_user_ids}
                    initialValue={
                        currentProject.confidential_user_ids ?
                        (currentProject.confidential_user_ids.length !== 0 ? currentProject.confidential_user_ids : checkedOptionList):
                        checkedOptionList
                    }
                >
                    <UserSelect
                        placeholder={projectDIC.confidential_user_ids.formPlaceholder}
                        className="input-sz-m"
                        mode="multiple"
                        maxTagCount={2}
                        allowClear
                        autoFocus
                        // defaultOpen
                        change
                        onChange={this.changehandle}
                    >{usersList}</UserSelect>
                </Form.Item>
                <div className="confidential-users-list mg-l-10-f">
                    {listGroup}
                </div>
            </div>
        )
    }
}
export default AddEditConfidentialUsers;