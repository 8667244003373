import {
  ProjectDetails,
  ShootingPlanDetails,
  CommentedPanel,
  TitlePanel,
} from "@components/3_feedback/drawer/0_shooting_plan_drawer/history/history_render";

export const planDIC = {
  area: {
    title: "拍摄地区",
    placeholder: "请输入拍摄地区",
    icon: "icon-area",
  },
  fund_total: {
    title: "总费用",
    placeholder: "总费用",
    disabled: true,
    icon: "icon-money",
  },
  executors: {
    title: "参与人员",
  },

  time_frame: {
    title: "拍摄周期",
    placeholder: "拍摄周期",
    disabled: true,
    icon: "icon-calendar",
  },
  comment: {
    title: "备注",
  },
};
export const tripColumns = [
  {
    title: "日期",
    dataIndex: "trip_time",
    width: "24%",
    render: ([start, end]) =>
      start && end
        ? `${start.replace(/-/g, "/")} - ${end.replace(/-/g, "/")}`
        : "",
  },
  {
    title: "行程类型",
    dataIndex: "trip_type",
    render: (val) => val.label,
    width: "17%",
  },
  {
    title: "目的地",
    dataIndex: "trip_destination",
    width: "17%",
  },
  {
    title: "交通方式",
    dataIndex: "trip_transportation",
    render: (val) => val.label,
    width: "17%",
  },
  {
    title: "行程说明",
    dataIndex: "trip_description",
    width: "25%",
  },
];

export const fundColumns = [
  {
    title: "经费类型",
    dataIndex: "fund_type",
    render: (val) => val.label,
    width: "24%",
  },
  {
    title: "经费说明",
    dataIndex: "fund_description",
    width: "51%",
  },
  {
    title: "金额",
    dataIndex: "fund_amount",
    render: (val) => val.toFixed(2),
    width: "25%",
  },
];

export const emptyPlan = {
  time_frame: [],
  fund_total: "",
  project_id: "",
  area: "",
  executors: [],
  trips: [],
  funds: [],
  images: {},
  comment: "",
  shooting_plan_status: {},
  assignee_id: "",
};

export const tripFormColumns = [
  {
    title: "日期",
    dataIndex: "trip_time",
    width: "28.6%",
    render: ([start, end]) =>
      start && end
        ? `${start.replace(/-/g, "/")} - ${end.replace(/-/g, "/")}`
        : "",
  },
  {
    title: "行程类型",
    dataIndex: "trip_type",
    width: "11.6%",
    render: (trip_type) => (trip_type ? trip_type.label : ""),
  },
  {
    title: "目的地",
    dataIndex: "trip_destination",
    width: "11.8%",
  },
  {
    title: "交通方式",
    dataIndex: "trip_transportation",
    width: "11.8%",
    render: (trip_transportation) =>
      trip_transportation ? trip_transportation.label : "",
  },
  {
    title: "行程说明",
    dataIndex: "trip_description",
    width: "24%",
  },
  {
  },
];

export const fundFormColumns = [
  {
    title: "经费类型",
    dataIndex: "fund_type",
    width: "12%",
    render: (fund_type) => (fund_type ? fund_type.label : ""),
  },
  {
    title: "经费说明",
    dataIndex: "fund_description",
    width: "57.6%",
  },
  {
    title: "金额",
    dataIndex: "fund_amount",
    width: "18%",
  },
  {
  },
];

const timelineDIC = {
  project: {
    color: "#925FFE",
    component: (record) => <ProjectDetails {...record} />,
  },
  plan: {
    color: "#65C09A",
    component: (record) => <ShootingPlanDetails {...record} />,
  },
  comment: {
    color: "#65C09A",
    component: (record) => <CommentedPanel {...record} />,
  },
  title: {
    color: "#65C09A",
    component: (record) => <TitlePanel {...record} />,
  },
};

const typeDIC = {
  create_project: "project",
  update_project: "project",
  complete_project: "comment",
  assign_shooting_plan: "title",
  create_shooting_plan: "plan",
  update_shooting_plan: "plan",
  create_submit_shooting_plan: "plan",
  update_submit_shooting_plan: "plan",
  submit_shooting_plan: "plan",
  approve_shooting_plan: "comment",
  disapprove_shooting_plan: "comment",
};

export const timelineTypes = (type) => {
  const display = typeDIC[type];
  return timelineDIC[display];
};

export const shootingPlanStatusIcons = [
  "",
  "icon-plan-planning",
  "icon-plan-pending-approval",
  "icon-plan-disapproved",
  "icon-plan-approved",
];

export const emptyProject = {
  id: null,
  code: null,
  title: "",
  description: "",
  project_status: {
    code: null,
    label: null,
  },
  assignee: {
    id: null,
    username: null,
    name: null,
  },
  creator: {
    id: null,
    username: null,
    name: null,
  },
  photographer: {
    id: null,
    username: null,
    name: null,
  },
  shooting_plan_id: 0,
  output: null,
  progress: null,
  total_tasks: null,
  image_urls: [],
  created_at: null,
  start_at: null,
  estimate_finish_at: null,
  finish_at: null,
};

// 任务包的行程表格title
export const tasksTripFormColumns = [
  {
    title: "日期",
    dataIndex: "trip_time",
    width: "25.9%",
    render: ([start, end]) =>
      start && end
        ? `${start.replace(/-/g, "/")} - ${end.replace(/-/g, "/")}`
        : "",
  },
  {
    title: "行程类型",
    dataIndex: "trip_type",
    width: "13%",
    render: (trip_type) => (trip_type ? trip_type.label : ""),
  },
  {
    title: "目的地",
    dataIndex: "trip_destination",
    width: "13%",
  },
  {
    title: "交通方式",
    dataIndex: "trip_transportation",
    width: "13%",
    render: (trip_transportation) =>
      trip_transportation ? trip_transportation.label : "",
  },
  {
    title: "行程说明",
    dataIndex: "trip_description",
    width: "19.5%",
  },
  {
    title: "",
    dataIndex: "trip_operation",
    width: "10%",
  },
];
