import { connect } from 'react-redux'
import AddEditRole from './add_edit_role'
import { createRole, getPermissions, editRole, fetchRoleDetails } from "@actions/1_entities/role_actions"
import { fetchUserPermissions } from "@actions/0_session/session_actions";

const mapStateToProps = (state) => ({
  allPermissions: state.entities.permissions,
  currentUser: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  createRole: (params, cb) => dispatch(createRole(params, cb)),
  getPermissions: (params, cb) => dispatch(getPermissions(params, cb)),
  editRole: (params, cb) => dispatch(editRole(params, cb)),
  fetchRoleDetails: (params, cb) => dispatch(fetchRoleDetails(params, cb)),
  fetchUserPermissions: () => dispatch(fetchUserPermissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditRole)