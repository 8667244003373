import AnnouncementSettings from "./announcement_settings";
import { connect } from "react-redux";

import { fetchAnnouncements, requestAnnouncements } from "@actions/1_entities/announcements_actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    requestAnnouncements: (params) => dispatch(requestAnnouncements(params)),
    searchAnnouncements: (params, success, error) =>
    dispatch(fetchAnnouncements(params, success, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementSettings);
