import { createFromIconfontCN } from '@ant-design/icons'
import { clsx } from '@gUtils'
import React from 'react'

const Icon = function (props) {
    const {
        color,
        className,
        size,
        style,
        ...other
    } = props
    const Component = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2343817_3u2zkgen0ry.js',
        extraCommonProps: {
            className: clsx('iconfont', className),
            style: {
                fontSize: size || 16,
                color
            }
        }
    });
    return React.createElement(Component, {
        className: clsx('iconfont', className),
        style: {
            fontSize: size || 16,
            color,
            ...style
        },
        ...other
    });
};
export default Icon;
