/**
 * @name: setTask.jsx
 * @user: cfj
 * @date: 2022/3/25
 * @description: 创建或编辑任务
 */
import { Col, Form, InputNumber, Row } from 'antd';
import { AmpUpload, Input, Select } from '@components';
import React, { forwardRef, useImperativeHandle } from 'react';

/* title: 1
 score: 100
 package_id: 96
 images: (binary) */
const SetTaskForm = forwardRef(function(props, ref) {
    const { initialValues } = props;
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit: form.submit,
        getForm: () => form
    }));
    return <Form form={form}
                 layout={'vertical'}
                 initialValues={initialValues}>
        <Row gutter={40}>
            <Form.Item name={'id'} hidden={true}>
                <span />
            </Form.Item>
            <Form.Item name={'package_id'} hidden={true}>
                <span />
            </Form.Item>
            <Col span={12}>
                <Form.Item name={'title'} label={'名称'} required={true} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={'userName'} label={'上传人'} required={true} rules={[{ required: true }]}>
                    <Input disabled />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item name={'score'} label={'自评分'} required={true} rules={[
                    { required: true },
                    {
                        max: 100,
                        min: 0,
                        type: 'number'
                    }]}>
                    <InputNumber size={'large'} max={100} min={0} style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={'material_type'} label={'素材属性'} required={true} rules={[
                    { required: true }]}>
                    <Select options={[
                        {label: '项目素材', value: 1},
                        {label: '泛素材', value: 2}
                    ]} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={40}>
            <Col span={24}>
                <Form.Item name={'images'} dependencies={['image_urls']} label={'任务图片'} required={true} rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value?.length  || getFieldValue('image_urls')?.length) {
                                return Promise.resolve()
                            }
                            return Promise.reject('请选择图片');
                        },
                    })]}>
                    <AmpUpload  maxCount={5} multiple={true} />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
});
export default SetTaskForm;
