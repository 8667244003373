// source: core_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_amp_common_pb = require('./common/amp/common_pb.js');
goog.object.extend(proto, common_amp_common_pb);
goog.exportSymbol('proto.core_api.Address', null, global);
goog.exportSymbol('proto.core_api.AssetExtendInfo', null, global);
goog.exportSymbol('proto.core_api.AssetExtendInfoRequest', null, global);
goog.exportSymbol('proto.core_api.AssetExtendInfoResponse', null, global);
goog.exportSymbol('proto.core_api.AssignmentFilter', null, global);
goog.exportSymbol('proto.core_api.EmptyRequest', null, global);
goog.exportSymbol('proto.core_api.EmptyResponse', null, global);
goog.exportSymbol('proto.core_api.GetAssetExtendInfoRequest', null, global);
goog.exportSymbol('proto.core_api.GetTaskAssignmentRequest', null, global);
goog.exportSymbol('proto.core_api.GetTaskAssignmentResponse', null, global);
goog.exportSymbol('proto.core_api.IdRequest', null, global);
goog.exportSymbol('proto.core_api.ListAssetExtendInfoRequest', null, global);
goog.exportSymbol('proto.core_api.ListAssetExtendInfoResponse', null, global);
goog.exportSymbol('proto.core_api.ListTopicAnnotationRequest', null, global);
goog.exportSymbol('proto.core_api.ListTopicAnnotationResponse', null, global);
goog.exportSymbol('proto.core_api.ListTopicSceneAddressRequest', null, global);
goog.exportSymbol('proto.core_api.ListTopicSceneAddressResponse', null, global);
goog.exportSymbol('proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest', null, global);
goog.exportSymbol('proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse', null, global);
goog.exportSymbol('proto.core_api.ModifyAssetExtendInfo', null, global);
goog.exportSymbol('proto.core_api.ModifyTopicAnnotation', null, global);
goog.exportSymbol('proto.core_api.Outline', null, global);
goog.exportSymbol('proto.core_api.PlanUserList', null, global);
goog.exportSymbol('proto.core_api.SceneAndOutlineRequest', null, global);
goog.exportSymbol('proto.core_api.SceneAndOutlineResponse', null, global);
goog.exportSymbol('proto.core_api.SceneInfoRequest', null, global);
goog.exportSymbol('proto.core_api.SceneTree', null, global);
goog.exportSymbol('proto.core_api.SchedulerOperation', null, global);
goog.exportSymbol('proto.core_api.TaskAssignment', null, global);
goog.exportSymbol('proto.core_api.TaskStatus', null, global);
goog.exportSymbol('proto.core_api.TopicAnnotation', null, global);
goog.exportSymbol('proto.core_api.TopicAnnotationRequest', null, global);
goog.exportSymbol('proto.core_api.TopicAnnotationResponse', null, global);
goog.exportSymbol('proto.core_api.TopicHistoryInfoResponse', null, global);
goog.exportSymbol('proto.core_api.TopicInfoCommonRequest', null, global);
goog.exportSymbol('proto.core_api.TopicInfoRequest', null, global);
goog.exportSymbol('proto.core_api.TopicInfoResponse', null, global);
goog.exportSymbol('proto.core_api.TopicListInfo', null, global);
goog.exportSymbol('proto.core_api.TopicListOutline', null, global);
goog.exportSymbol('proto.core_api.TopicListRequest', null, global);
goog.exportSymbol('proto.core_api.TopicListResponse', null, global);
goog.exportSymbol('proto.core_api.TopicListScene', null, global);
goog.exportSymbol('proto.core_api.TopicResponse', null, global);
goog.exportSymbol('proto.core_api.TopicSceneAddress', null, global);
goog.exportSymbol('proto.core_api.TopicSceneAddressRequest', null, global);
goog.exportSymbol('proto.core_api.TopicSceneAddressResponse', null, global);
goog.exportSymbol('proto.core_api.TopicSceneInfoRequest', null, global);
goog.exportSymbol('proto.core_api.TopicSceneInfoResponse', null, global);
goog.exportSymbol('proto.core_api.TopicSceneSchedulerCommonParam', null, global);
goog.exportSymbol('proto.core_api.TopicSceneSchedulerHandlerRequest', null, global);
goog.exportSymbol('proto.core_api.TopicSceneSchedulerHandlerResponse', null, global);
goog.exportSymbol('proto.core_api.TopicSceneSchedulerModifySceneParam', null, global);
goog.exportSymbol('proto.core_api.TopicSceneUnbindRequest', null, global);
goog.exportSymbol('proto.core_api.UserSimpleInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.EmptyRequest.displayName = 'proto.core_api.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.EmptyResponse.displayName = 'proto.core_api.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.IdRequest.displayName = 'proto.core_api.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TaskStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TaskStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TaskStatus.displayName = 'proto.core_api.TaskStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.UserSimpleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.UserSimpleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.UserSimpleInfo.displayName = 'proto.core_api.UserSimpleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TaskAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TaskAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TaskAssignment.displayName = 'proto.core_api.TaskAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.AssignmentFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.AssignmentFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.AssignmentFilter.displayName = 'proto.core_api.AssignmentFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.GetTaskAssignmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.GetTaskAssignmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.GetTaskAssignmentRequest.displayName = 'proto.core_api.GetTaskAssignmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.GetTaskAssignmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.GetTaskAssignmentResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.GetTaskAssignmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.GetTaskAssignmentResponse.displayName = 'proto.core_api.GetTaskAssignmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.SceneTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.SceneTree.repeatedFields_, null);
};
goog.inherits(proto.core_api.SceneTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.SceneTree.displayName = 'proto.core_api.SceneTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.Outline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.Outline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.Outline.displayName = 'proto.core_api.Outline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.SceneAndOutlineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.SceneAndOutlineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.SceneAndOutlineRequest.displayName = 'proto.core_api.SceneAndOutlineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.SceneAndOutlineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.SceneAndOutlineResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.SceneAndOutlineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.SceneAndOutlineResponse.displayName = 'proto.core_api.SceneAndOutlineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.Address.displayName = 'proto.core_api.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.SceneInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.SceneInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.SceneInfoRequest.displayName = 'proto.core_api.SceneInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicInfoCommonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicInfoCommonRequest.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicInfoCommonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicInfoCommonRequest.displayName = 'proto.core_api.TopicInfoCommonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicResponse.displayName = 'proto.core_api.TopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicListScene = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicListScene, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicListScene.displayName = 'proto.core_api.TopicListScene';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicListOutline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicListOutline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicListOutline.displayName = 'proto.core_api.TopicListOutline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicListInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicListInfo.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicListInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicListInfo.displayName = 'proto.core_api.TopicListInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicListRequest.displayName = 'proto.core_api.TopicListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.PlanUserList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.PlanUserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.PlanUserList.displayName = 'proto.core_api.PlanUserList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicInfoRequest.displayName = 'proto.core_api.TopicInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicAnnotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicAnnotation.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicAnnotation.displayName = 'proto.core_api.TopicAnnotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicHistoryInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicHistoryInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicHistoryInfoResponse.displayName = 'proto.core_api.TopicHistoryInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicInfoResponse.displayName = 'proto.core_api.TopicInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicListResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicListResponse.displayName = 'proto.core_api.TopicListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.displayName = 'proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.displayName = 'proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicSceneAddress.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicSceneAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneAddress.displayName = 'proto.core_api.TopicSceneAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneAddressRequest.displayName = 'proto.core_api.TopicSceneAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneAddressResponse.displayName = 'proto.core_api.TopicSceneAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneUnbindRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneUnbindRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneUnbindRequest.displayName = 'proto.core_api.TopicSceneUnbindRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListTopicSceneAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListTopicSceneAddressRequest.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListTopicSceneAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListTopicSceneAddressRequest.displayName = 'proto.core_api.ListTopicSceneAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListTopicSceneAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListTopicSceneAddressResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListTopicSceneAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListTopicSceneAddressResponse.displayName = 'proto.core_api.ListTopicSceneAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneInfoRequest.displayName = 'proto.core_api.TopicSceneInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicSceneInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicSceneInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneInfoResponse.displayName = 'proto.core_api.TopicSceneInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneSchedulerCommonParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicSceneSchedulerCommonParam.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicSceneSchedulerCommonParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneSchedulerCommonParam.displayName = 'proto.core_api.TopicSceneSchedulerCommonParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneSchedulerModifySceneParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.TopicSceneSchedulerModifySceneParam.repeatedFields_, null);
};
goog.inherits(proto.core_api.TopicSceneSchedulerModifySceneParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneSchedulerModifySceneParam.displayName = 'proto.core_api.TopicSceneSchedulerModifySceneParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneSchedulerHandlerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneSchedulerHandlerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneSchedulerHandlerRequest.displayName = 'proto.core_api.TopicSceneSchedulerHandlerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicSceneSchedulerHandlerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicSceneSchedulerHandlerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicSceneSchedulerHandlerResponse.displayName = 'proto.core_api.TopicSceneSchedulerHandlerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ModifyTopicAnnotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ModifyTopicAnnotation.repeatedFields_, null);
};
goog.inherits(proto.core_api.ModifyTopicAnnotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ModifyTopicAnnotation.displayName = 'proto.core_api.ModifyTopicAnnotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicAnnotationRequest.displayName = 'proto.core_api.TopicAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.TopicAnnotationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.TopicAnnotationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.TopicAnnotationResponse.displayName = 'proto.core_api.TopicAnnotationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListTopicAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.ListTopicAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListTopicAnnotationRequest.displayName = 'proto.core_api.ListTopicAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListTopicAnnotationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListTopicAnnotationResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListTopicAnnotationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListTopicAnnotationResponse.displayName = 'proto.core_api.ListTopicAnnotationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.SchedulerOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.SchedulerOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.SchedulerOperation.displayName = 'proto.core_api.SchedulerOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.AssetExtendInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.AssetExtendInfo.repeatedFields_, null);
};
goog.inherits(proto.core_api.AssetExtendInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.AssetExtendInfo.displayName = 'proto.core_api.AssetExtendInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ModifyAssetExtendInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ModifyAssetExtendInfo.repeatedFields_, null);
};
goog.inherits(proto.core_api.ModifyAssetExtendInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ModifyAssetExtendInfo.displayName = 'proto.core_api.ModifyAssetExtendInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.AssetExtendInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.AssetExtendInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.AssetExtendInfoRequest.displayName = 'proto.core_api.AssetExtendInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.AssetExtendInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.AssetExtendInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.AssetExtendInfoResponse.displayName = 'proto.core_api.AssetExtendInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListAssetExtendInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListAssetExtendInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListAssetExtendInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListAssetExtendInfoResponse.displayName = 'proto.core_api.ListAssetExtendInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.GetAssetExtendInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core_api.GetAssetExtendInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.GetAssetExtendInfoRequest.displayName = 'proto.core_api.GetAssetExtendInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core_api.ListAssetExtendInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core_api.ListAssetExtendInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.core_api.ListAssetExtendInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core_api.ListAssetExtendInfoRequest.displayName = 'proto.core_api.ListAssetExtendInfoRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.EmptyRequest}
 */
proto.core_api.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.EmptyRequest;
  return proto.core_api.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.EmptyRequest}
 */
proto.core_api.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.EmptyResponse}
 */
proto.core_api.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.EmptyResponse;
  return proto.core_api.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.EmptyResponse}
 */
proto.core_api.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.IdRequest}
 */
proto.core_api.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.IdRequest;
  return proto.core_api.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.IdRequest}
 */
proto.core_api.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.IdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.IdRequest} returns this
 */
proto.core_api.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.core_api.IdRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.IdRequest} returns this
 */
proto.core_api.IdRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TaskStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TaskStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TaskStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TaskStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TaskStatus}
 */
proto.core_api.TaskStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TaskStatus;
  return proto.core_api.TaskStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TaskStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TaskStatus}
 */
proto.core_api.TaskStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TaskStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TaskStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TaskStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TaskStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 code = 1;
 * @return {number}
 */
proto.core_api.TaskStatus.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TaskStatus} returns this
 */
proto.core_api.TaskStatus.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.core_api.TaskStatus.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TaskStatus} returns this
 */
proto.core_api.TaskStatus.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.UserSimpleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.UserSimpleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.UserSimpleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.UserSimpleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.UserSimpleInfo}
 */
proto.core_api.UserSimpleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.UserSimpleInfo;
  return proto.core_api.UserSimpleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.UserSimpleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.UserSimpleInfo}
 */
proto.core_api.UserSimpleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.UserSimpleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.UserSimpleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.UserSimpleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.UserSimpleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.UserSimpleInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.UserSimpleInfo} returns this
 */
proto.core_api.UserSimpleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.UserSimpleInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.UserSimpleInfo} returns this
 */
proto.core_api.UserSimpleInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.core_api.UserSimpleInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.UserSimpleInfo} returns this
 */
proto.core_api.UserSimpleInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TaskAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TaskAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TaskAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TaskAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    taskStatus: (f = msg.getTaskStatus()) && proto.core_api.TaskStatus.toObject(includeInstance, f),
    initiator: (f = msg.getInitiator()) && proto.core_api.UserSimpleInfo.toObject(includeInstance, f),
    priority: jspb.Message.getFieldWithDefault(msg, 8, 0),
    waitingTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    taskPreview: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TaskAssignment}
 */
proto.core_api.TaskAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TaskAssignment;
  return proto.core_api.TaskAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TaskAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TaskAssignment}
 */
proto.core_api.TaskAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskNo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 6:
      var value = new proto.core_api.TaskStatus;
      reader.readMessage(value,proto.core_api.TaskStatus.deserializeBinaryFromReader);
      msg.setTaskStatus(value);
      break;
    case 7:
      var value = new proto.core_api.UserSimpleInfo;
      reader.readMessage(value,proto.core_api.UserSimpleInfo.deserializeBinaryFromReader);
      msg.setInitiator(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitingTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskPreview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TaskAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TaskAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TaskAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TaskAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTaskStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.core_api.TaskStatus.serializeBinaryToWriter
    );
  }
  f = message.getInitiator();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.core_api.UserSimpleInfo.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getWaitingTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTaskPreview();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 task_id = 1;
 * @return {number}
 */
proto.core_api.TaskAssignment.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string task_title = 2;
 * @return {string}
 */
proto.core_api.TaskAssignment.prototype.getTaskTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setTaskTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task_no = 3;
 * @return {string}
 */
proto.core_api.TaskAssignment.prototype.getTaskNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setTaskNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 project_id = 4;
 * @return {number}
 */
proto.core_api.TaskAssignment.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string project_name = 5;
 * @return {string}
 */
proto.core_api.TaskAssignment.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TaskStatus task_status = 6;
 * @return {?proto.core_api.TaskStatus}
 */
proto.core_api.TaskAssignment.prototype.getTaskStatus = function() {
  return /** @type{?proto.core_api.TaskStatus} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TaskStatus, 6));
};


/**
 * @param {?proto.core_api.TaskStatus|undefined} value
 * @return {!proto.core_api.TaskAssignment} returns this
*/
proto.core_api.TaskAssignment.prototype.setTaskStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.clearTaskStatus = function() {
  return this.setTaskStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TaskAssignment.prototype.hasTaskStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserSimpleInfo initiator = 7;
 * @return {?proto.core_api.UserSimpleInfo}
 */
proto.core_api.TaskAssignment.prototype.getInitiator = function() {
  return /** @type{?proto.core_api.UserSimpleInfo} */ (
    jspb.Message.getWrapperField(this, proto.core_api.UserSimpleInfo, 7));
};


/**
 * @param {?proto.core_api.UserSimpleInfo|undefined} value
 * @return {!proto.core_api.TaskAssignment} returns this
*/
proto.core_api.TaskAssignment.prototype.setInitiator = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.clearInitiator = function() {
  return this.setInitiator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TaskAssignment.prototype.hasInitiator = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 priority = 8;
 * @return {number}
 */
proto.core_api.TaskAssignment.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 waiting_time = 9;
 * @return {number}
 */
proto.core_api.TaskAssignment.prototype.getWaitingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setWaitingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string task_preview = 10;
 * @return {string}
 */
proto.core_api.TaskAssignment.prototype.getTaskPreview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TaskAssignment} returns this
 */
proto.core_api.TaskAssignment.prototype.setTaskPreview = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.AssignmentFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.AssignmentFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.AssignmentFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssignmentFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initiatorId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taskStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.AssignmentFilter}
 */
proto.core_api.AssignmentFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.AssignmentFilter;
  return proto.core_api.AssignmentFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.AssignmentFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.AssignmentFilter}
 */
proto.core_api.AssignmentFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInitiatorId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.AssignmentFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.AssignmentFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.AssignmentFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssignmentFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitiatorId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTaskStatus();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string project_uid = 1;
 * @return {string}
 */
proto.core_api.AssignmentFilter.prototype.getProjectUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssignmentFilter} returns this
 */
proto.core_api.AssignmentFilter.prototype.setProjectUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 initiator_id = 2;
 * @return {number}
 */
proto.core_api.AssignmentFilter.prototype.getInitiatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssignmentFilter} returns this
 */
proto.core_api.AssignmentFilter.prototype.setInitiatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 task_status = 3;
 * @return {number}
 */
proto.core_api.AssignmentFilter.prototype.getTaskStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssignmentFilter} returns this
 */
proto.core_api.AssignmentFilter.prototype.setTaskStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 priority = 4;
 * @return {number}
 */
proto.core_api.AssignmentFilter.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssignmentFilter} returns this
 */
proto.core_api.AssignmentFilter.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.GetTaskAssignmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.GetTaskAssignmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetTaskAssignmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filter: (f = msg.getFilter()) && proto.core_api.AssignmentFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.GetTaskAssignmentRequest}
 */
proto.core_api.GetTaskAssignmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.GetTaskAssignmentRequest;
  return proto.core_api.GetTaskAssignmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.GetTaskAssignmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.GetTaskAssignmentRequest}
 */
proto.core_api.GetTaskAssignmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = new proto.core_api.AssignmentFilter;
      reader.readMessage(value,proto.core_api.AssignmentFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.GetTaskAssignmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.GetTaskAssignmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetTaskAssignmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core_api.AssignmentFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetTaskAssignmentRequest} returns this
 */
proto.core_api.GetTaskAssignmentRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetTaskAssignmentRequest} returns this
 */
proto.core_api.GetTaskAssignmentRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AssignmentFilter filter = 3;
 * @return {?proto.core_api.AssignmentFilter}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.getFilter = function() {
  return /** @type{?proto.core_api.AssignmentFilter} */ (
    jspb.Message.getWrapperField(this, proto.core_api.AssignmentFilter, 3));
};


/**
 * @param {?proto.core_api.AssignmentFilter|undefined} value
 * @return {!proto.core_api.GetTaskAssignmentRequest} returns this
*/
proto.core_api.GetTaskAssignmentRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.GetTaskAssignmentRequest} returns this
 */
proto.core_api.GetTaskAssignmentRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.GetTaskAssignmentRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.GetTaskAssignmentResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.GetTaskAssignmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.GetTaskAssignmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetTaskAssignmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.TaskAssignment.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.GetTaskAssignmentResponse}
 */
proto.core_api.GetTaskAssignmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.GetTaskAssignmentResponse;
  return proto.core_api.GetTaskAssignmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.GetTaskAssignmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.GetTaskAssignmentResponse}
 */
proto.core_api.GetTaskAssignmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = new proto.core_api.TaskAssignment;
      reader.readMessage(value,proto.core_api.TaskAssignment.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.GetTaskAssignmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.GetTaskAssignmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetTaskAssignmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.core_api.TaskAssignment.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetTaskAssignmentResponse} returns this
 */
proto.core_api.GetTaskAssignmentResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetTaskAssignmentResponse} returns this
 */
proto.core_api.GetTaskAssignmentResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated TaskAssignment list = 3;
 * @return {!Array<!proto.core_api.TaskAssignment>}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.TaskAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TaskAssignment, 3));
};


/**
 * @param {!Array<!proto.core_api.TaskAssignment>} value
 * @return {!proto.core_api.GetTaskAssignmentResponse} returns this
*/
proto.core_api.GetTaskAssignmentResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.core_api.TaskAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TaskAssignment}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.core_api.TaskAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.GetTaskAssignmentResponse} returns this
 */
proto.core_api.GetTaskAssignmentResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 4;
 * @return {number}
 */
proto.core_api.GetTaskAssignmentResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetTaskAssignmentResponse} returns this
 */
proto.core_api.GetTaskAssignmentResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.SceneTree.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.SceneTree.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.SceneTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.SceneTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.core_api.SceneTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.SceneTree}
 */
proto.core_api.SceneTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.SceneTree;
  return proto.core_api.SceneTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.SceneTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.SceneTree}
 */
proto.core_api.SceneTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 4:
      var value = new proto.core_api.SceneTree;
      reader.readMessage(value,proto.core_api.SceneTree.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.SceneTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.SceneTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.SceneTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.core_api.SceneTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.core_api.SceneTree.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SceneTree} returns this
 */
proto.core_api.SceneTree.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.core_api.SceneTree.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SceneTree} returns this
 */
proto.core_api.SceneTree.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 parent_id = 3;
 * @return {number}
 */
proto.core_api.SceneTree.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SceneTree} returns this
 */
proto.core_api.SceneTree.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated SceneTree children = 4;
 * @return {!Array<!proto.core_api.SceneTree>}
 */
proto.core_api.SceneTree.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.core_api.SceneTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.SceneTree, 4));
};


/**
 * @param {!Array<!proto.core_api.SceneTree>} value
 * @return {!proto.core_api.SceneTree} returns this
*/
proto.core_api.SceneTree.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.core_api.SceneTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.SceneTree}
 */
proto.core_api.SceneTree.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.core_api.SceneTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.SceneTree} returns this
 */
proto.core_api.SceneTree.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.Outline.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.Outline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.Outline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.Outline.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.Outline}
 */
proto.core_api.Outline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.Outline;
  return proto.core_api.Outline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.Outline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.Outline}
 */
proto.core_api.Outline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.Outline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.Outline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.Outline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.Outline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.Outline.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.Outline} returns this
 */
proto.core_api.Outline.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.Outline.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Outline} returns this
 */
proto.core_api.Outline.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.SceneAndOutlineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.SceneAndOutlineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.SceneAndOutlineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneAndOutlineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.SceneAndOutlineRequest}
 */
proto.core_api.SceneAndOutlineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.SceneAndOutlineRequest;
  return proto.core_api.SceneAndOutlineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.SceneAndOutlineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.SceneAndOutlineRequest}
 */
proto.core_api.SceneAndOutlineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.SceneAndOutlineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.SceneAndOutlineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.SceneAndOutlineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneAndOutlineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.SceneAndOutlineResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.SceneAndOutlineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.SceneAndOutlineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.SceneAndOutlineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneAndOutlineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sceneListList: jspb.Message.toObjectList(msg.getSceneListList(),
    proto.core_api.SceneTree.toObject, includeInstance),
    outlineListList: jspb.Message.toObjectList(msg.getOutlineListList(),
    proto.core_api.Outline.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.SceneAndOutlineResponse}
 */
proto.core_api.SceneAndOutlineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.SceneAndOutlineResponse;
  return proto.core_api.SceneAndOutlineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.SceneAndOutlineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.SceneAndOutlineResponse}
 */
proto.core_api.SceneAndOutlineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.SceneTree;
      reader.readMessage(value,proto.core_api.SceneTree.deserializeBinaryFromReader);
      msg.addSceneList(value);
      break;
    case 2:
      var value = new proto.core_api.Outline;
      reader.readMessage(value,proto.core_api.Outline.deserializeBinaryFromReader);
      msg.addOutlineList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.SceneAndOutlineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.SceneAndOutlineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.SceneAndOutlineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneAndOutlineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSceneListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.SceneTree.serializeBinaryToWriter
    );
  }
  f = message.getOutlineListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.core_api.Outline.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SceneTree scene_list = 1;
 * @return {!Array<!proto.core_api.SceneTree>}
 */
proto.core_api.SceneAndOutlineResponse.prototype.getSceneListList = function() {
  return /** @type{!Array<!proto.core_api.SceneTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.SceneTree, 1));
};


/**
 * @param {!Array<!proto.core_api.SceneTree>} value
 * @return {!proto.core_api.SceneAndOutlineResponse} returns this
*/
proto.core_api.SceneAndOutlineResponse.prototype.setSceneListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.SceneTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.SceneTree}
 */
proto.core_api.SceneAndOutlineResponse.prototype.addSceneList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.SceneTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.SceneAndOutlineResponse} returns this
 */
proto.core_api.SceneAndOutlineResponse.prototype.clearSceneListList = function() {
  return this.setSceneListList([]);
};


/**
 * repeated Outline outline_list = 2;
 * @return {!Array<!proto.core_api.Outline>}
 */
proto.core_api.SceneAndOutlineResponse.prototype.getOutlineListList = function() {
  return /** @type{!Array<!proto.core_api.Outline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.Outline, 2));
};


/**
 * @param {!Array<!proto.core_api.Outline>} value
 * @return {!proto.core_api.SceneAndOutlineResponse} returns this
*/
proto.core_api.SceneAndOutlineResponse.prototype.setOutlineListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.core_api.Outline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.Outline}
 */
proto.core_api.SceneAndOutlineResponse.prototype.addOutlineList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.core_api.Outline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.SceneAndOutlineResponse} returns this
 */
proto.core_api.SceneAndOutlineResponse.prototype.clearOutlineListList = function() {
  return this.setOutlineListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    typeCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    district: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.Address}
 */
proto.core_api.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.Address;
  return proto.core_api.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.Address}
 */
proto.core_api.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAdCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTypeCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional double lng = 1;
 * @return {number}
 */
proto.core_api.Address.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lat = 2;
 * @return {number}
 */
proto.core_api.Address.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string ad_code = 3;
 * @return {string}
 */
proto.core_api.Address.prototype.getAdCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setAdCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.core_api.Address.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type_code = 5;
 * @return {string}
 */
proto.core_api.Address.prototype.getTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.core_api.Address.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string district = 7;
 * @return {string}
 */
proto.core_api.Address.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.Address} returns this
 */
proto.core_api.Address.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.SceneInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.SceneInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.SceneInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.SceneInfoRequest}
 */
proto.core_api.SceneInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.SceneInfoRequest;
  return proto.core_api.SceneInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.SceneInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.SceneInfoRequest}
 */
proto.core_api.SceneInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.SceneInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.SceneInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.SceneInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SceneInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.SceneInfoRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SceneInfoRequest} returns this
 */
proto.core_api.SceneInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.SceneInfoRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SceneInfoRequest} returns this
 */
proto.core_api.SceneInfoRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicInfoCommonRequest.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicInfoCommonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicInfoCommonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicInfoCommonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoCommonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    planUserId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    outlineId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scenesList: jspb.Message.toObjectList(msg.getScenesList(),
    proto.core_api.SceneInfoRequest.toObject, includeInstance),
    addressList: jspb.Message.toObjectList(msg.getAddressList(),
    proto.core_api.Address.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicInfoCommonRequest}
 */
proto.core_api.TopicInfoCommonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicInfoCommonRequest;
  return proto.core_api.TopicInfoCommonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicInfoCommonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicInfoCommonRequest}
 */
proto.core_api.TopicInfoCommonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutlineId(value);
      break;
    case 7:
      var value = new proto.core_api.SceneInfoRequest;
      reader.readMessage(value,proto.core_api.SceneInfoRequest.deserializeBinaryFromReader);
      msg.addScenes(value);
      break;
    case 8:
      var value = new proto.core_api.Address;
      reader.readMessage(value,proto.core_api.Address.deserializeBinaryFromReader);
      msg.addAddress(value);
      break;
    case 9:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicInfoCommonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicInfoCommonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicInfoCommonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoCommonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlanUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOutlineId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getScenesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core_api.SceneInfoRequest.serializeBinaryToWriter
    );
  }
  f = message.getAddressList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.core_api.Address.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string detail = 3;
 * @return {string}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 plan_user_id = 4;
 * @return {number}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getPlanUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setPlanUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 type = 5;
 * @return {number}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 outline_id = 6;
 * @return {number}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getOutlineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.setOutlineId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated SceneInfoRequest scenes = 7;
 * @return {!Array<!proto.core_api.SceneInfoRequest>}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getScenesList = function() {
  return /** @type{!Array<!proto.core_api.SceneInfoRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.SceneInfoRequest, 7));
};


/**
 * @param {!Array<!proto.core_api.SceneInfoRequest>} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
*/
proto.core_api.TopicInfoCommonRequest.prototype.setScenesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core_api.SceneInfoRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.SceneInfoRequest}
 */
proto.core_api.TopicInfoCommonRequest.prototype.addScenes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core_api.SceneInfoRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.clearScenesList = function() {
  return this.setScenesList([]);
};


/**
 * repeated Address address = 8;
 * @return {!Array<!proto.core_api.Address>}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getAddressList = function() {
  return /** @type{!Array<!proto.core_api.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.Address, 8));
};


/**
 * @param {!Array<!proto.core_api.Address>} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
*/
proto.core_api.TopicInfoCommonRequest.prototype.setAddressList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.core_api.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.Address}
 */
proto.core_api.TopicInfoCommonRequest.prototype.addAddress = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.core_api.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.clearAddressList = function() {
  return this.setAddressList([]);
};


/**
 * repeated common.CommonFileItem images = 9;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicInfoCommonRequest.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 9));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
*/
proto.core_api.TopicInfoCommonRequest.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicInfoCommonRequest.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoCommonRequest} returns this
 */
proto.core_api.TopicInfoCommonRequest.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicResponse}
 */
proto.core_api.TopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicResponse;
  return proto.core_api.TopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicResponse}
 */
proto.core_api.TopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicListScene.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicListScene.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicListScene} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListScene.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicListScene}
 */
proto.core_api.TopicListScene.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicListScene;
  return proto.core_api.TopicListScene.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicListScene} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicListScene}
 */
proto.core_api.TopicListScene.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicListScene.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicListScene.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicListScene} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListScene.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicListScene.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListScene} returns this
 */
proto.core_api.TopicListScene.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicListScene.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListScene} returns this
 */
proto.core_api.TopicListScene.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicListOutline.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicListOutline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicListOutline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListOutline.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicListOutline}
 */
proto.core_api.TopicListOutline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicListOutline;
  return proto.core_api.TopicListOutline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicListOutline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicListOutline}
 */
proto.core_api.TopicListOutline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicListOutline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicListOutline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicListOutline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListOutline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicListOutline.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListOutline} returns this
 */
proto.core_api.TopicListOutline.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicListOutline.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListOutline} returns this
 */
proto.core_api.TopicListOutline.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicListInfo.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicListInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicListInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicListInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    number: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outlineList: (f = msg.getOutlineList()) && proto.core_api.TopicListOutline.toObject(includeInstance, f),
    sceneListList: jspb.Message.toObjectList(msg.getSceneListList(),
    proto.core_api.TopicListScene.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicListInfo}
 */
proto.core_api.TopicListInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicListInfo;
  return proto.core_api.TopicListInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicListInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicListInfo}
 */
proto.core_api.TopicListInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 8:
      var value = new proto.core_api.TopicListOutline;
      reader.readMessage(value,proto.core_api.TopicListOutline.deserializeBinaryFromReader);
      msg.setOutlineList(value);
      break;
    case 6:
      var value = new proto.core_api.TopicListScene;
      reader.readMessage(value,proto.core_api.TopicListScene.deserializeBinaryFromReader);
      msg.addSceneList(value);
      break;
    case 7:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicListInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicListInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicListInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutlineList();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.core_api.TopicListOutline.serializeBinaryToWriter
    );
  }
  f = message.getSceneListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.core_api.TopicListScene.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicListInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicListInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string detail = 3;
 * @return {string}
 */
proto.core_api.TopicListInfo.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 status = 4;
 * @return {number}
 */
proto.core_api.TopicListInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string number = 5;
 * @return {string}
 */
proto.core_api.TopicListInfo.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TopicListOutline outline_list = 8;
 * @return {?proto.core_api.TopicListOutline}
 */
proto.core_api.TopicListInfo.prototype.getOutlineList = function() {
  return /** @type{?proto.core_api.TopicListOutline} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicListOutline, 8));
};


/**
 * @param {?proto.core_api.TopicListOutline|undefined} value
 * @return {!proto.core_api.TopicListInfo} returns this
*/
proto.core_api.TopicListInfo.prototype.setOutlineList = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.clearOutlineList = function() {
  return this.setOutlineList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicListInfo.prototype.hasOutlineList = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated TopicListScene scene_list = 6;
 * @return {!Array<!proto.core_api.TopicListScene>}
 */
proto.core_api.TopicListInfo.prototype.getSceneListList = function() {
  return /** @type{!Array<!proto.core_api.TopicListScene>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicListScene, 6));
};


/**
 * @param {!Array<!proto.core_api.TopicListScene>} value
 * @return {!proto.core_api.TopicListInfo} returns this
*/
proto.core_api.TopicListInfo.prototype.setSceneListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.core_api.TopicListScene=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicListScene}
 */
proto.core_api.TopicListInfo.prototype.addSceneList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.core_api.TopicListScene, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.clearSceneListList = function() {
  return this.setSceneListList([]);
};


/**
 * repeated common.CommonFileItem images = 7;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicListInfo.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 7));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicListInfo} returns this
*/
proto.core_api.TopicListInfo.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicListInfo.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicListInfo} returns this
 */
proto.core_api.TopicListInfo.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicIdName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    planUserId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 8, 0),
    page: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicListRequest}
 */
proto.core_api.TopicListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicListRequest;
  return proto.core_api.TopicListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicListRequest}
 */
proto.core_api.TopicListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopicIdName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicIdName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPlanUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string topic_id_name = 1;
 * @return {string}
 */
proto.core_api.TopicListRequest.prototype.getTopicIdName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setTopicIdName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 type = 2;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 plan_user_id = 4;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getPlanUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setPlanUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 start_time = 5;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 end_time = 6;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 status = 7;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 sort = 8;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 page = 9;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 page_size = 10;
 * @return {number}
 */
proto.core_api.TopicListRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListRequest} returns this
 */
proto.core_api.TopicListRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.PlanUserList.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.PlanUserList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.PlanUserList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.PlanUserList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.PlanUserList}
 */
proto.core_api.PlanUserList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.PlanUserList;
  return proto.core_api.PlanUserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.PlanUserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.PlanUserList}
 */
proto.core_api.PlanUserList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.PlanUserList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.PlanUserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.PlanUserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.PlanUserList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.PlanUserList.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.PlanUserList} returns this
 */
proto.core_api.PlanUserList.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.PlanUserList.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.PlanUserList} returns this
 */
proto.core_api.PlanUserList.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicInfoRequest}
 */
proto.core_api.TopicInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicInfoRequest;
  return proto.core_api.TopicInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicInfoRequest}
 */
proto.core_api.TopicInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicInfoRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoRequest} returns this
 */
proto.core_api.TopicInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicAnnotation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicAnnotation.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicAnnotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicAnnotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    businessId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessStatusName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    businessStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicAnnotation}
 */
proto.core_api.TopicAnnotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicAnnotation;
  return proto.core_api.TopicAnnotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicAnnotation}
 */
proto.core_api.TopicAnnotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessStatusName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicAnnotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicAnnotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBusinessId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessStatusName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBusinessStatus();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.core_api.TopicAnnotation.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.CommonFileItem images = 4;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicAnnotation.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 4));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicAnnotation} returns this
*/
proto.core_api.TopicAnnotation.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicAnnotation.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional common.CoreBusinessType type = 5;
 * @return {!proto.common.CoreBusinessType}
 */
proto.core_api.TopicAnnotation.prototype.getType = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 business_id = 6;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getBusinessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setBusinessId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.core_api.TopicAnnotation.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_status_name = 8;
 * @return {string}
 */
proto.core_api.TopicAnnotation.prototype.getBusinessStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setBusinessStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 business_status = 9;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getBusinessStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setBusinessStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 created_at = 10;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 updated_at = 11;
 * @return {number}
 */
proto.core_api.TopicAnnotation.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicAnnotation} returns this
 */
proto.core_api.TopicAnnotation.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicHistoryInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicHistoryInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicHistoryInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updateAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operatorId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operatorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessPreStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    businessPreStatusName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessNextStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    businessNextStatusName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    operationName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicHistoryInfoResponse}
 */
proto.core_api.TopicHistoryInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicHistoryInfoResponse;
  return proto.core_api.TopicHistoryInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicHistoryInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicHistoryInfoResponse}
 */
proto.core_api.TopicHistoryInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdateAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessPreStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessPreStatusName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessNextStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessNextStatusName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicHistoryInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicHistoryInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicHistoryInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpdateAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOperatorId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOperatorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessPreStatus();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBusinessPreStatusName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessNextStatus();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBusinessNextStatusName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOperationName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 update_at = 2;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getUpdateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setUpdateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 operator_id = 3;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getOperatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string operator_name = 4;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getOperatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setOperatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 business_pre_status = 6;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getBusinessPreStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setBusinessPreStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string business_pre_status_name = 7;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getBusinessPreStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setBusinessPreStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 business_next_status = 8;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getBusinessNextStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setBusinessNextStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string business_next_status_name = 9;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getBusinessNextStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setBusinessNextStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string operation = 10;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 created_at = 11;
 * @return {number}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string operation_name = 13;
 * @return {string}
 */
proto.core_api.TopicHistoryInfoResponse.prototype.getOperationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicHistoryInfoResponse} returns this
 */
proto.core_api.TopicHistoryInfoResponse.prototype.setOperationName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicInfoResponse.repeatedFields_ = [7,8,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    number: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outlineList: (f = msg.getOutlineList()) && proto.core_api.TopicListOutline.toObject(includeInstance, f),
    sceneListList: jspb.Message.toObjectList(msg.getSceneListList(),
    proto.core_api.TopicListScene.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance),
    createdTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    planUserList: (f = msg.getPlanUserList()) && proto.core_api.PlanUserList.toObject(includeInstance, f),
    addressListList: jspb.Message.toObjectList(msg.getAddressListList(),
    proto.core_api.Address.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicInfoResponse}
 */
proto.core_api.TopicInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicInfoResponse;
  return proto.core_api.TopicInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicInfoResponse}
 */
proto.core_api.TopicInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 6:
      var value = new proto.core_api.TopicListOutline;
      reader.readMessage(value,proto.core_api.TopicListOutline.deserializeBinaryFromReader);
      msg.setOutlineList(value);
      break;
    case 7:
      var value = new proto.core_api.TopicListScene;
      reader.readMessage(value,proto.core_api.TopicListScene.deserializeBinaryFromReader);
      msg.addSceneList(value);
      break;
    case 8:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedTime(value);
      break;
    case 10:
      var value = new proto.core_api.PlanUserList;
      reader.readMessage(value,proto.core_api.PlanUserList.deserializeBinaryFromReader);
      msg.setPlanUserList(value);
      break;
    case 11:
      var value = new proto.core_api.Address;
      reader.readMessage(value,proto.core_api.Address.deserializeBinaryFromReader);
      msg.addAddressList(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutlineList();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.core_api.TopicListOutline.serializeBinaryToWriter
    );
  }
  f = message.getSceneListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.core_api.TopicListScene.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPlanUserList();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.core_api.PlanUserList.serializeBinaryToWriter
    );
  }
  f = message.getAddressListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.core_api.Address.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicInfoResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicInfoResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string detail = 3;
 * @return {string}
 */
proto.core_api.TopicInfoResponse.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 status = 4;
 * @return {number}
 */
proto.core_api.TopicInfoResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string number = 5;
 * @return {string}
 */
proto.core_api.TopicInfoResponse.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TopicListOutline outline_list = 6;
 * @return {?proto.core_api.TopicListOutline}
 */
proto.core_api.TopicInfoResponse.prototype.getOutlineList = function() {
  return /** @type{?proto.core_api.TopicListOutline} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicListOutline, 6));
};


/**
 * @param {?proto.core_api.TopicListOutline|undefined} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
*/
proto.core_api.TopicInfoResponse.prototype.setOutlineList = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.clearOutlineList = function() {
  return this.setOutlineList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicInfoResponse.prototype.hasOutlineList = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated TopicListScene scene_list = 7;
 * @return {!Array<!proto.core_api.TopicListScene>}
 */
proto.core_api.TopicInfoResponse.prototype.getSceneListList = function() {
  return /** @type{!Array<!proto.core_api.TopicListScene>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicListScene, 7));
};


/**
 * @param {!Array<!proto.core_api.TopicListScene>} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
*/
proto.core_api.TopicInfoResponse.prototype.setSceneListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.core_api.TopicListScene=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicListScene}
 */
proto.core_api.TopicInfoResponse.prototype.addSceneList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.core_api.TopicListScene, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.clearSceneListList = function() {
  return this.setSceneListList([]);
};


/**
 * repeated common.CommonFileItem images = 8;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicInfoResponse.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 8));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
*/
proto.core_api.TopicInfoResponse.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicInfoResponse.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional int64 created_time = 9;
 * @return {number}
 */
proto.core_api.TopicInfoResponse.prototype.getCreatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional PlanUserList plan_user_list = 10;
 * @return {?proto.core_api.PlanUserList}
 */
proto.core_api.TopicInfoResponse.prototype.getPlanUserList = function() {
  return /** @type{?proto.core_api.PlanUserList} */ (
    jspb.Message.getWrapperField(this, proto.core_api.PlanUserList, 10));
};


/**
 * @param {?proto.core_api.PlanUserList|undefined} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
*/
proto.core_api.TopicInfoResponse.prototype.setPlanUserList = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.clearPlanUserList = function() {
  return this.setPlanUserList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicInfoResponse.prototype.hasPlanUserList = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Address address_list = 11;
 * @return {!Array<!proto.core_api.Address>}
 */
proto.core_api.TopicInfoResponse.prototype.getAddressListList = function() {
  return /** @type{!Array<!proto.core_api.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.Address, 11));
};


/**
 * @param {!Array<!proto.core_api.Address>} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
*/
proto.core_api.TopicInfoResponse.prototype.setAddressListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.core_api.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.Address}
 */
proto.core_api.TopicInfoResponse.prototype.addAddressList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.core_api.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.clearAddressListList = function() {
  return this.setAddressListList([]);
};


/**
 * optional int64 type = 12;
 * @return {number}
 */
proto.core_api.TopicInfoResponse.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicInfoResponse} returns this
 */
proto.core_api.TopicInfoResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.TopicListInfo.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicListResponse}
 */
proto.core_api.TopicListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicListResponse;
  return proto.core_api.TopicListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicListResponse}
 */
proto.core_api.TopicListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicListInfo;
      reader.readMessage(value,proto.core_api.TopicListInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.TopicListInfo.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TopicListInfo list = 1;
 * @return {!Array<!proto.core_api.TopicListInfo>}
 */
proto.core_api.TopicListResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.TopicListInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicListInfo, 1));
};


/**
 * @param {!Array<!proto.core_api.TopicListInfo>} value
 * @return {!proto.core_api.TopicListResponse} returns this
*/
proto.core_api.TopicListResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.TopicListInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicListInfo}
 */
proto.core_api.TopicListResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.TopicListInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicListResponse} returns this
 */
proto.core_api.TopicListResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.core_api.TopicListResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicListResponse} returns this
 */
proto.core_api.TopicListResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assignType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest;
  return proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssignType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 assign_type = 1;
 * @return {number}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.getAssignType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} returns this
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.setAssignType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string operation = 2;
 * @return {string}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} returns this
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 task_status = 3;
 * @return {number}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.getTaskStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} returns this
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest.prototype.setTaskStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.UserSimpleInfo.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse;
  return proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.UserSimpleInfo;
      reader.readMessage(value,proto.core_api.UserSimpleInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.UserSimpleInfo.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated UserSimpleInfo list = 1;
 * @return {!Array<!proto.core_api.UserSimpleInfo>}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.UserSimpleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.UserSimpleInfo, 1));
};


/**
 * @param {!Array<!proto.core_api.UserSimpleInfo>} value
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} returns this
*/
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.UserSimpleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.UserSimpleInfo}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.UserSimpleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} returns this
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse} returns this
 */
proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicSceneAddress.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    topicId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance),
    addressListList: jspb.Message.toObjectList(msg.getAddressListList(),
    proto.core_api.Address.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    operatorId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    operatorName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statusName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    groupStatus: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneAddress}
 */
proto.core_api.TopicSceneAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneAddress;
  return proto.core_api.TopicSceneAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneAddress}
 */
proto.core_api.TopicSceneAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTopicId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 6:
      var value = new proto.core_api.Address;
      reader.readMessage(value,proto.core_api.Address.deserializeBinaryFromReader);
      msg.addAddressList(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTopicId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
  f = message.getAddressListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.core_api.Address.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOperatorId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getOperatorName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStatusName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGroupStatus();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.core_api.TopicSceneAddress.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 topic_id = 3;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getTopicId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setTopicId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.core_api.TopicSceneAddress.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.CommonFileItem images = 5;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicSceneAddress.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 5));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
*/
proto.core_api.TopicSceneAddress.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicSceneAddress.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated Address address_list = 6;
 * @return {!Array<!proto.core_api.Address>}
 */
proto.core_api.TopicSceneAddress.prototype.getAddressListList = function() {
  return /** @type{!Array<!proto.core_api.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.Address, 6));
};


/**
 * @param {!Array<!proto.core_api.Address>} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
*/
proto.core_api.TopicSceneAddress.prototype.setAddressListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.core_api.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.Address}
 */
proto.core_api.TopicSceneAddress.prototype.addAddressList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.core_api.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.clearAddressListList = function() {
  return this.setAddressListList([]);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.core_api.TopicSceneAddress.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 operator_id = 9;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getOperatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string operator_name = 10;
 * @return {string}
 */
proto.core_api.TopicSceneAddress.prototype.getOperatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setOperatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 created_at = 11;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 updated_at = 12;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string status_name = 13;
 * @return {string}
 */
proto.core_api.TopicSceneAddress.prototype.getStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 group_status = 14;
 * @return {number}
 */
proto.core_api.TopicSceneAddress.prototype.getGroupStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneAddress} returns this
 */
proto.core_api.TopicSceneAddress.prototype.setGroupStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.TopicSceneAddress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneAddressRequest}
 */
proto.core_api.TopicSceneAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneAddressRequest;
  return proto.core_api.TopicSceneAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneAddressRequest}
 */
proto.core_api.TopicSceneAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicSceneAddress;
      reader.readMessage(value,proto.core_api.TopicSceneAddress.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.TopicSceneAddress.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicSceneAddress detail = 1;
 * @return {?proto.core_api.TopicSceneAddress}
 */
proto.core_api.TopicSceneAddressRequest.prototype.getDetail = function() {
  return /** @type{?proto.core_api.TopicSceneAddress} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicSceneAddress, 1));
};


/**
 * @param {?proto.core_api.TopicSceneAddress|undefined} value
 * @return {!proto.core_api.TopicSceneAddressRequest} returns this
*/
proto.core_api.TopicSceneAddressRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneAddressRequest} returns this
 */
proto.core_api.TopicSceneAddressRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneAddressRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneAddressResponse}
 */
proto.core_api.TopicSceneAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneAddressResponse;
  return proto.core_api.TopicSceneAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneAddressResponse}
 */
proto.core_api.TopicSceneAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneUnbindRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneUnbindRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneUnbindRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sceneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operatorId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    operatorName: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneUnbindRequest}
 */
proto.core_api.TopicSceneUnbindRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneUnbindRequest;
  return proto.core_api.TopicSceneUnbindRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneUnbindRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneUnbindRequest}
 */
proto.core_api.TopicSceneUnbindRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTopicId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSceneId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneUnbindRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneUnbindRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneUnbindRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSceneId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOperatorId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getOperatorName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 topic_id = 1;
 * @return {number}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.getTopicId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneUnbindRequest} returns this
 */
proto.core_api.TopicSceneUnbindRequest.prototype.setTopicId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 scene_id = 2;
 * @return {number}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.getSceneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneUnbindRequest} returns this
 */
proto.core_api.TopicSceneUnbindRequest.prototype.setSceneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 operator_id = 11;
 * @return {number}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.getOperatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneUnbindRequest} returns this
 */
proto.core_api.TopicSceneUnbindRequest.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string operator_name = 12;
 * @return {string}
 */
proto.core_api.TopicSceneUnbindRequest.prototype.getOperatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneUnbindRequest} returns this
 */
proto.core_api.TopicSceneUnbindRequest.prototype.setOperatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListTopicSceneAddressRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListTopicSceneAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListTopicSceneAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicSceneAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sceneIdName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    planUserId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    page: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    statusArrList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListTopicSceneAddressRequest}
 */
proto.core_api.ListTopicSceneAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListTopicSceneAddressRequest;
  return proto.core_api.ListTopicSceneAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListTopicSceneAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListTopicSceneAddressRequest}
 */
proto.core_api.ListTopicSceneAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTopicId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSceneIdName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusArr(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListTopicSceneAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListTopicSceneAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicSceneAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSceneIdName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlanUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStatusArrList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 topic_id = 1;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getTopicId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setTopicId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string scene_id_name = 2;
 * @return {string}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getSceneIdName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setSceneIdName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 status = 3;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 plan_user_id = 4;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getPlanUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setPlanUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 start_time = 5;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 end_time = 6;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 page = 7;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 page_size = 8;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated int64 status_arr = 9;
 * @return {!Array<number>}
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.getStatusArrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.setStatusArrList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.addStatusArr = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListTopicSceneAddressRequest} returns this
 */
proto.core_api.ListTopicSceneAddressRequest.prototype.clearStatusArrList = function() {
  return this.setStatusArrList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListTopicSceneAddressResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListTopicSceneAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListTopicSceneAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicSceneAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.TopicSceneAddress.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListTopicSceneAddressResponse}
 */
proto.core_api.ListTopicSceneAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListTopicSceneAddressResponse;
  return proto.core_api.ListTopicSceneAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListTopicSceneAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListTopicSceneAddressResponse}
 */
proto.core_api.ListTopicSceneAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicSceneAddress;
      reader.readMessage(value,proto.core_api.TopicSceneAddress.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListTopicSceneAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListTopicSceneAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicSceneAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.TopicSceneAddress.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TopicSceneAddress list = 1;
 * @return {!Array<!proto.core_api.TopicSceneAddress>}
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.TopicSceneAddress>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicSceneAddress, 1));
};


/**
 * @param {!Array<!proto.core_api.TopicSceneAddress>} value
 * @return {!proto.core_api.ListTopicSceneAddressResponse} returns this
*/
proto.core_api.ListTopicSceneAddressResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.TopicSceneAddress=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicSceneAddress}
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.TopicSceneAddress, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListTopicSceneAddressResponse} returns this
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicSceneAddressResponse} returns this
 */
proto.core_api.ListTopicSceneAddressResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sceneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneInfoRequest}
 */
proto.core_api.TopicSceneInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneInfoRequest;
  return proto.core_api.TopicSceneInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneInfoRequest}
 */
proto.core_api.TopicSceneInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSceneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSceneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 scene_id = 1;
 * @return {number}
 */
proto.core_api.TopicSceneInfoRequest.prototype.getSceneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneInfoRequest} returns this
 */
proto.core_api.TopicSceneInfoRequest.prototype.setSceneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicSceneInfoResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.TopicSceneAddress.toObject(includeInstance, f),
    topicDetail: (f = msg.getTopicDetail()) && proto.core_api.TopicInfoResponse.toObject(includeInstance, f),
    historyListList: jspb.Message.toObjectList(msg.getHistoryListList(),
    proto.core_api.TopicHistoryInfoResponse.toObject, includeInstance),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.core_api.SchedulerOperation.toObject, includeInstance),
    planUserList: (f = msg.getPlanUserList()) && proto.core_api.PlanUserList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneInfoResponse}
 */
proto.core_api.TopicSceneInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneInfoResponse;
  return proto.core_api.TopicSceneInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneInfoResponse}
 */
proto.core_api.TopicSceneInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicSceneAddress;
      reader.readMessage(value,proto.core_api.TopicSceneAddress.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    case 2:
      var value = new proto.core_api.TopicInfoResponse;
      reader.readMessage(value,proto.core_api.TopicInfoResponse.deserializeBinaryFromReader);
      msg.setTopicDetail(value);
      break;
    case 3:
      var value = new proto.core_api.TopicHistoryInfoResponse;
      reader.readMessage(value,proto.core_api.TopicHistoryInfoResponse.deserializeBinaryFromReader);
      msg.addHistoryList(value);
      break;
    case 4:
      var value = new proto.core_api.SchedulerOperation;
      reader.readMessage(value,proto.core_api.SchedulerOperation.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 10:
      var value = new proto.core_api.PlanUserList;
      reader.readMessage(value,proto.core_api.PlanUserList.deserializeBinaryFromReader);
      msg.setPlanUserList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.TopicSceneAddress.serializeBinaryToWriter
    );
  }
  f = message.getTopicDetail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core_api.TopicInfoResponse.serializeBinaryToWriter
    );
  }
  f = message.getHistoryListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.core_api.TopicHistoryInfoResponse.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.core_api.SchedulerOperation.serializeBinaryToWriter
    );
  }
  f = message.getPlanUserList();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.core_api.PlanUserList.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicSceneAddress detail = 1;
 * @return {?proto.core_api.TopicSceneAddress}
 */
proto.core_api.TopicSceneInfoResponse.prototype.getDetail = function() {
  return /** @type{?proto.core_api.TopicSceneAddress} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicSceneAddress, 1));
};


/**
 * @param {?proto.core_api.TopicSceneAddress|undefined} value
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
*/
proto.core_api.TopicSceneInfoResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
 */
proto.core_api.TopicSceneInfoResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneInfoResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TopicInfoResponse topic_detail = 2;
 * @return {?proto.core_api.TopicInfoResponse}
 */
proto.core_api.TopicSceneInfoResponse.prototype.getTopicDetail = function() {
  return /** @type{?proto.core_api.TopicInfoResponse} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicInfoResponse, 2));
};


/**
 * @param {?proto.core_api.TopicInfoResponse|undefined} value
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
*/
proto.core_api.TopicSceneInfoResponse.prototype.setTopicDetail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
 */
proto.core_api.TopicSceneInfoResponse.prototype.clearTopicDetail = function() {
  return this.setTopicDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneInfoResponse.prototype.hasTopicDetail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TopicHistoryInfoResponse history_list = 3;
 * @return {!Array<!proto.core_api.TopicHistoryInfoResponse>}
 */
proto.core_api.TopicSceneInfoResponse.prototype.getHistoryListList = function() {
  return /** @type{!Array<!proto.core_api.TopicHistoryInfoResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicHistoryInfoResponse, 3));
};


/**
 * @param {!Array<!proto.core_api.TopicHistoryInfoResponse>} value
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
*/
proto.core_api.TopicSceneInfoResponse.prototype.setHistoryListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.core_api.TopicHistoryInfoResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicHistoryInfoResponse}
 */
proto.core_api.TopicSceneInfoResponse.prototype.addHistoryList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.core_api.TopicHistoryInfoResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
 */
proto.core_api.TopicSceneInfoResponse.prototype.clearHistoryListList = function() {
  return this.setHistoryListList([]);
};


/**
 * repeated SchedulerOperation operations = 4;
 * @return {!Array<!proto.core_api.SchedulerOperation>}
 */
proto.core_api.TopicSceneInfoResponse.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.core_api.SchedulerOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.SchedulerOperation, 4));
};


/**
 * @param {!Array<!proto.core_api.SchedulerOperation>} value
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
*/
proto.core_api.TopicSceneInfoResponse.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.core_api.SchedulerOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.SchedulerOperation}
 */
proto.core_api.TopicSceneInfoResponse.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.core_api.SchedulerOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
 */
proto.core_api.TopicSceneInfoResponse.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * optional PlanUserList plan_user_list = 10;
 * @return {?proto.core_api.PlanUserList}
 */
proto.core_api.TopicSceneInfoResponse.prototype.getPlanUserList = function() {
  return /** @type{?proto.core_api.PlanUserList} */ (
    jspb.Message.getWrapperField(this, proto.core_api.PlanUserList, 10));
};


/**
 * @param {?proto.core_api.PlanUserList|undefined} value
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
*/
proto.core_api.TopicSceneInfoResponse.prototype.setPlanUserList = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneInfoResponse} returns this
 */
proto.core_api.TopicSceneInfoResponse.prototype.clearPlanUserList = function() {
  return this.setPlanUserList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneInfoResponse.prototype.hasPlanUserList = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicSceneSchedulerCommonParam.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneSchedulerCommonParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneSchedulerCommonParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerCommonParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sceneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assignIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    imagesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam}
 */
proto.core_api.TopicSceneSchedulerCommonParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneSchedulerCommonParam;
  return proto.core_api.TopicSceneSchedulerCommonParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneSchedulerCommonParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam}
 */
proto.core_api.TopicSceneSchedulerCommonParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSceneId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAssignIds(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneSchedulerCommonParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneSchedulerCommonParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerCommonParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSceneId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssignIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string operation = 1;
 * @return {string}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 scene_id = 2;
 * @return {number}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.getSceneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.setSceneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 assign_ids = 4;
 * @return {!Array<number>}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.getAssignIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.setAssignIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.addAssignIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.clearAssignIdsList = function() {
  return this.setAssignIdsList([]);
};


/**
 * repeated string images = 5;
 * @return {!Array<string>}
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneSchedulerCommonParam} returns this
 */
proto.core_api.TopicSceneSchedulerCommonParam.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneSchedulerModifySceneParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneSchedulerModifySceneParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance),
    addressList: jspb.Message.toObjectList(msg.getAddressList(),
    proto.core_api.Address.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    planUserId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneSchedulerModifySceneParam;
  return proto.core_api.TopicSceneSchedulerModifySceneParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneSchedulerModifySceneParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTopicId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 4:
      var value = new proto.core_api.Address;
      reader.readMessage(value,proto.core_api.Address.deserializeBinaryFromReader);
      msg.addAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlanUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneSchedulerModifySceneParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneSchedulerModifySceneParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
  f = message.getAddressList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.core_api.Address.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlanUserId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 topic_id = 1;
 * @return {number}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getTopicId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setTopicId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated common.CommonFileItem images = 3;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 3));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
*/
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated Address address = 4;
 * @return {!Array<!proto.core_api.Address>}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getAddressList = function() {
  return /** @type{!Array<!proto.core_api.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.Address, 4));
};


/**
 * @param {!Array<!proto.core_api.Address>} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
*/
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setAddressList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.core_api.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.Address}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.addAddress = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.core_api.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.clearAddressList = function() {
  return this.setAddressList([]);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 plan_user_id = 6;
 * @return {number}
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.getPlanUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneSchedulerModifySceneParam} returns this
 */
proto.core_api.TopicSceneSchedulerModifySceneParam.prototype.setPlanUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneSchedulerHandlerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonParam: (f = msg.getCommonParam()) && proto.core_api.TopicSceneSchedulerCommonParam.toObject(includeInstance, f),
    addSceneParam: (f = msg.getAddSceneParam()) && proto.core_api.TopicSceneSchedulerModifySceneParam.toObject(includeInstance, f),
    updateSceneParam: (f = msg.getUpdateSceneParam()) && proto.core_api.TopicSceneSchedulerModifySceneParam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneSchedulerHandlerRequest;
  return proto.core_api.TopicSceneSchedulerHandlerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicSceneSchedulerCommonParam;
      reader.readMessage(value,proto.core_api.TopicSceneSchedulerCommonParam.deserializeBinaryFromReader);
      msg.setCommonParam(value);
      break;
    case 2:
      var value = new proto.core_api.TopicSceneSchedulerModifySceneParam;
      reader.readMessage(value,proto.core_api.TopicSceneSchedulerModifySceneParam.deserializeBinaryFromReader);
      msg.setAddSceneParam(value);
      break;
    case 3:
      var value = new proto.core_api.TopicSceneSchedulerModifySceneParam;
      reader.readMessage(value,proto.core_api.TopicSceneSchedulerModifySceneParam.deserializeBinaryFromReader);
      msg.setUpdateSceneParam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneSchedulerHandlerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonParam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.TopicSceneSchedulerCommonParam.serializeBinaryToWriter
    );
  }
  f = message.getAddSceneParam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.core_api.TopicSceneSchedulerModifySceneParam.serializeBinaryToWriter
    );
  }
  f = message.getUpdateSceneParam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.core_api.TopicSceneSchedulerModifySceneParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicSceneSchedulerCommonParam common_param = 1;
 * @return {?proto.core_api.TopicSceneSchedulerCommonParam}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.getCommonParam = function() {
  return /** @type{?proto.core_api.TopicSceneSchedulerCommonParam} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicSceneSchedulerCommonParam, 1));
};


/**
 * @param {?proto.core_api.TopicSceneSchedulerCommonParam|undefined} value
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
*/
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.setCommonParam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.clearCommonParam = function() {
  return this.setCommonParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.hasCommonParam = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TopicSceneSchedulerModifySceneParam add_scene_param = 2;
 * @return {?proto.core_api.TopicSceneSchedulerModifySceneParam}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.getAddSceneParam = function() {
  return /** @type{?proto.core_api.TopicSceneSchedulerModifySceneParam} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicSceneSchedulerModifySceneParam, 2));
};


/**
 * @param {?proto.core_api.TopicSceneSchedulerModifySceneParam|undefined} value
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
*/
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.setAddSceneParam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.clearAddSceneParam = function() {
  return this.setAddSceneParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.hasAddSceneParam = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TopicSceneSchedulerModifySceneParam update_scene_param = 3;
 * @return {?proto.core_api.TopicSceneSchedulerModifySceneParam}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.getUpdateSceneParam = function() {
  return /** @type{?proto.core_api.TopicSceneSchedulerModifySceneParam} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicSceneSchedulerModifySceneParam, 3));
};


/**
 * @param {?proto.core_api.TopicSceneSchedulerModifySceneParam|undefined} value
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
*/
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.setUpdateSceneParam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerRequest} returns this
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.clearUpdateSceneParam = function() {
  return this.setUpdateSceneParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicSceneSchedulerHandlerRequest.prototype.hasUpdateSceneParam = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicSceneSchedulerHandlerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicSceneSchedulerHandlerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sceneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerResponse}
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicSceneSchedulerHandlerResponse;
  return proto.core_api.TopicSceneSchedulerHandlerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicSceneSchedulerHandlerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicSceneSchedulerHandlerResponse}
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSceneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicSceneSchedulerHandlerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicSceneSchedulerHandlerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSceneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 scene_id = 1;
 * @return {number}
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.prototype.getSceneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.TopicSceneSchedulerHandlerResponse} returns this
 */
proto.core_api.TopicSceneSchedulerHandlerResponse.prototype.setSceneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ModifyTopicAnnotation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ModifyTopicAnnotation.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ModifyTopicAnnotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ModifyTopicAnnotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ModifyTopicAnnotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    common_amp_common_pb.CommonFileItem.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ModifyTopicAnnotation}
 */
proto.core_api.ModifyTopicAnnotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ModifyTopicAnnotation;
  return proto.core_api.ModifyTopicAnnotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ModifyTopicAnnotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ModifyTopicAnnotation}
 */
proto.core_api.ModifyTopicAnnotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessId(value);
      break;
    case 4:
      var value = new common_amp_common_pb.CommonFileItem;
      reader.readMessage(value,common_amp_common_pb.CommonFileItem.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ModifyTopicAnnotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ModifyTopicAnnotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ModifyTopicAnnotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ModifyTopicAnnotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBusinessId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_amp_common_pb.CommonFileItem.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.ModifyTopicAnnotation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
 */
proto.core_api.ModifyTopicAnnotation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType type = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.core_api.ModifyTopicAnnotation.prototype.getType = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
 */
proto.core_api.ModifyTopicAnnotation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 business_id = 3;
 * @return {number}
 */
proto.core_api.ModifyTopicAnnotation.prototype.getBusinessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
 */
proto.core_api.ModifyTopicAnnotation.prototype.setBusinessId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated common.CommonFileItem images = 4;
 * @return {!Array<!proto.common.CommonFileItem>}
 */
proto.core_api.ModifyTopicAnnotation.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.common.CommonFileItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.CommonFileItem, 4));
};


/**
 * @param {!Array<!proto.common.CommonFileItem>} value
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
*/
proto.core_api.ModifyTopicAnnotation.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.CommonFileItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CommonFileItem}
 */
proto.core_api.ModifyTopicAnnotation.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.CommonFileItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
 */
proto.core_api.ModifyTopicAnnotation.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.core_api.ModifyTopicAnnotation.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyTopicAnnotation} returns this
 */
proto.core_api.ModifyTopicAnnotation.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.ModifyTopicAnnotation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicAnnotationRequest}
 */
proto.core_api.TopicAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicAnnotationRequest;
  return proto.core_api.TopicAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicAnnotationRequest}
 */
proto.core_api.TopicAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.ModifyTopicAnnotation;
      reader.readMessage(value,proto.core_api.ModifyTopicAnnotation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.ModifyTopicAnnotation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyTopicAnnotation detail = 1;
 * @return {?proto.core_api.ModifyTopicAnnotation}
 */
proto.core_api.TopicAnnotationRequest.prototype.getDetail = function() {
  return /** @type{?proto.core_api.ModifyTopicAnnotation} */ (
    jspb.Message.getWrapperField(this, proto.core_api.ModifyTopicAnnotation, 1));
};


/**
 * @param {?proto.core_api.ModifyTopicAnnotation|undefined} value
 * @return {!proto.core_api.TopicAnnotationRequest} returns this
*/
proto.core_api.TopicAnnotationRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicAnnotationRequest} returns this
 */
proto.core_api.TopicAnnotationRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicAnnotationRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.TopicAnnotationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.TopicAnnotationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.TopicAnnotationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.TopicAnnotation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.TopicAnnotationResponse}
 */
proto.core_api.TopicAnnotationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.TopicAnnotationResponse;
  return proto.core_api.TopicAnnotationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.TopicAnnotationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.TopicAnnotationResponse}
 */
proto.core_api.TopicAnnotationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicAnnotation;
      reader.readMessage(value,proto.core_api.TopicAnnotation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.TopicAnnotationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.TopicAnnotationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.TopicAnnotationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.TopicAnnotationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.TopicAnnotation.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicAnnotation detail = 1;
 * @return {?proto.core_api.TopicAnnotation}
 */
proto.core_api.TopicAnnotationResponse.prototype.getDetail = function() {
  return /** @type{?proto.core_api.TopicAnnotation} */ (
    jspb.Message.getWrapperField(this, proto.core_api.TopicAnnotation, 1));
};


/**
 * @param {?proto.core_api.TopicAnnotation|undefined} value
 * @return {!proto.core_api.TopicAnnotationResponse} returns this
*/
proto.core_api.TopicAnnotationResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.TopicAnnotationResponse} returns this
 */
proto.core_api.TopicAnnotationResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.TopicAnnotationResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListTopicAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListTopicAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListTopicAnnotationRequest}
 */
proto.core_api.ListTopicAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListTopicAnnotationRequest;
  return proto.core_api.ListTopicAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListTopicAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListTopicAnnotationRequest}
 */
proto.core_api.ListTopicAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListTopicAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListTopicAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBusinessId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional common.CoreBusinessType type = 1;
 * @return {!proto.common.CoreBusinessType}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.getType = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.core_api.ListTopicAnnotationRequest} returns this
 */
proto.core_api.ListTopicAnnotationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 business_id = 2;
 * @return {number}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.getBusinessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicAnnotationRequest} returns this
 */
proto.core_api.ListTopicAnnotationRequest.prototype.setBusinessId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicAnnotationRequest} returns this
 */
proto.core_api.ListTopicAnnotationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.core_api.ListTopicAnnotationRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicAnnotationRequest} returns this
 */
proto.core_api.ListTopicAnnotationRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListTopicAnnotationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListTopicAnnotationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListTopicAnnotationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListTopicAnnotationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicAnnotationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.TopicAnnotation.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListTopicAnnotationResponse}
 */
proto.core_api.ListTopicAnnotationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListTopicAnnotationResponse;
  return proto.core_api.ListTopicAnnotationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListTopicAnnotationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListTopicAnnotationResponse}
 */
proto.core_api.ListTopicAnnotationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.TopicAnnotation;
      reader.readMessage(value,proto.core_api.TopicAnnotation.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListTopicAnnotationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListTopicAnnotationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListTopicAnnotationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListTopicAnnotationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.TopicAnnotation.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TopicAnnotation list = 1;
 * @return {!Array<!proto.core_api.TopicAnnotation>}
 */
proto.core_api.ListTopicAnnotationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.TopicAnnotation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.TopicAnnotation, 1));
};


/**
 * @param {!Array<!proto.core_api.TopicAnnotation>} value
 * @return {!proto.core_api.ListTopicAnnotationResponse} returns this
*/
proto.core_api.ListTopicAnnotationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.TopicAnnotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.TopicAnnotation}
 */
proto.core_api.ListTopicAnnotationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.TopicAnnotation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListTopicAnnotationResponse} returns this
 */
proto.core_api.ListTopicAnnotationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.core_api.ListTopicAnnotationResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListTopicAnnotationResponse} returns this
 */
proto.core_api.ListTopicAnnotationResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.SchedulerOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.SchedulerOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.SchedulerOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SchedulerOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0),
    taskRole: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nextStepCode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    prevStepType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextStepType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    operationType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    buttonName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    buttonColorType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    color: jspb.Message.getFieldWithDefault(msg, 13, ""),
    nextStepName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.SchedulerOperation}
 */
proto.core_api.SchedulerOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.SchedulerOperation;
  return proto.core_api.SchedulerOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.SchedulerOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.SchedulerOperation}
 */
proto.core_api.SchedulerOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskRole(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextStepCode(value);
      break;
    case 8:
      var value = /** @type {!proto.common.SchedulerOperationPrevStepType} */ (reader.readEnum());
      msg.setPrevStepType(value);
      break;
    case 9:
      var value = /** @type {!proto.common.SchedulerOperationNextStepType} */ (reader.readEnum());
      msg.setNextStepType(value);
      break;
    case 10:
      var value = /** @type {!proto.common.SchedulerOperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonName(value);
      break;
    case 12:
      var value = /** @type {!proto.common.SchedulerButtonColorType} */ (reader.readEnum());
      msg.setButtonColorType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextStepName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.SchedulerOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.SchedulerOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.SchedulerOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.SchedulerOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTaskRole();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getNextStepCode();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPrevStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getNextStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getButtonName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getButtonColorType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNextStepName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.SchedulerOperation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.core_api.SchedulerOperation.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation = 3;
 * @return {string}
 */
proto.core_api.SchedulerOperation.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.core_api.SchedulerOperation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sort = 5;
 * @return {number}
 */
proto.core_api.SchedulerOperation.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 task_role = 6;
 * @return {number}
 */
proto.core_api.SchedulerOperation.prototype.getTaskRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setTaskRole = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 next_step_code = 7;
 * @return {number}
 */
proto.core_api.SchedulerOperation.prototype.getNextStepCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setNextStepCode = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional common.SchedulerOperationPrevStepType prev_step_type = 8;
 * @return {!proto.common.SchedulerOperationPrevStepType}
 */
proto.core_api.SchedulerOperation.prototype.getPrevStepType = function() {
  return /** @type {!proto.common.SchedulerOperationPrevStepType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.SchedulerOperationPrevStepType} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setPrevStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional common.SchedulerOperationNextStepType next_step_type = 9;
 * @return {!proto.common.SchedulerOperationNextStepType}
 */
proto.core_api.SchedulerOperation.prototype.getNextStepType = function() {
  return /** @type {!proto.common.SchedulerOperationNextStepType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.SchedulerOperationNextStepType} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setNextStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional common.SchedulerOperationType operation_type = 10;
 * @return {!proto.common.SchedulerOperationType}
 */
proto.core_api.SchedulerOperation.prototype.getOperationType = function() {
  return /** @type {!proto.common.SchedulerOperationType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.SchedulerOperationType} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string button_name = 11;
 * @return {string}
 */
proto.core_api.SchedulerOperation.prototype.getButtonName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setButtonName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional common.SchedulerButtonColorType button_color_type = 12;
 * @return {!proto.common.SchedulerButtonColorType}
 */
proto.core_api.SchedulerOperation.prototype.getButtonColorType = function() {
  return /** @type {!proto.common.SchedulerButtonColorType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.common.SchedulerButtonColorType} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setButtonColorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string color = 13;
 * @return {string}
 */
proto.core_api.SchedulerOperation.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string next_step_name = 14;
 * @return {string}
 */
proto.core_api.SchedulerOperation.prototype.getNextStepName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.SchedulerOperation} returns this
 */
proto.core_api.SchedulerOperation.prototype.setNextStepName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.AssetExtendInfo.repeatedFields_ = [16,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.AssetExtendInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.AssetExtendInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.AssetExtendInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetUid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assetName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetEnName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assetTypeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    assetPackageUri: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fileType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    expandFileType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    materialType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    closeStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
    suggestPrice: jspb.Message.getFieldWithDefault(msg, 15, 0),
    suggestColorList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    assetSize: (f = msg.getAssetSize()) && common_amp_common_pb.AssetExtendSize.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 18, ""),
    description: jspb.Message.getFieldWithDefault(msg, 19, ""),
    errMessage: jspb.Message.getFieldWithDefault(msg, 20, ""),
    uploadResponse: jspb.Message.getFieldWithDefault(msg, 21, ""),
    reviewImagesList: jspb.Message.toObjectList(msg.getReviewImagesList(),
    common_amp_common_pb.DigitalAssetReviewItem.toObject, includeInstance),
    completedAt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 24, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.AssetExtendInfo}
 */
proto.core_api.AssetExtendInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.AssetExtendInfo;
  return proto.core_api.AssetExtendInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.AssetExtendInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.AssetExtendInfo}
 */
proto.core_api.AssetExtendInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetUid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetEnName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetTypeId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPackageUri(value);
      break;
    case 10:
      var value = /** @type {!proto.common.GodownFileType} */ (reader.readEnum());
      msg.setFileType(value);
      break;
    case 11:
      var value = /** @type {!proto.common.GodownEntryExpandFileType} */ (reader.readEnum());
      msg.setExpandFileType(value);
      break;
    case 12:
      var value = /** @type {!proto.common.MaterialType} */ (reader.readEnum());
      msg.setMaterialType(value);
      break;
    case 13:
      var value = /** @type {!proto.common.GodownEntryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.common.AssetCloseStatus} */ (reader.readEnum());
      msg.setCloseStatus(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuggestPrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestColor(value);
      break;
    case 17:
      var value = new common_amp_common_pb.AssetExtendSize;
      reader.readMessage(value,common_amp_common_pb.AssetExtendSize.deserializeBinaryFromReader);
      msg.setAssetSize(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrMessage(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadResponse(value);
      break;
    case 22:
      var value = new common_amp_common_pb.DigitalAssetReviewItem;
      reader.readMessage(value,common_amp_common_pb.DigitalAssetReviewItem.deserializeBinaryFromReader);
      msg.addReviewImages(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedAt(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.AssetExtendInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.AssetExtendInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.AssetExtendInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssetUid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAssetName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetEnName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetTypeId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAssetPackageUri();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getExpandFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getMaterialType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCloseStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getSuggestPrice();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getSuggestColorList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getAssetSize();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      common_amp_common_pb.AssetExtendSize.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getErrMessage();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUploadResponse();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getReviewImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      common_amp_common_pb.DigitalAssetReviewItem.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 task_id = 2;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string asset_uid = 3;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetUid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 project_id = 4;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string asset_name = 5;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string asset_en_name = 6;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetEnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetEnName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string asset_type = 7;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 asset_type_id = 8;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string asset_package_uri = 9;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetPackageUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setAssetPackageUri = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional common.GodownFileType file_type = 10;
 * @return {!proto.common.GodownFileType}
 */
proto.core_api.AssetExtendInfo.prototype.getFileType = function() {
  return /** @type {!proto.common.GodownFileType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.GodownFileType} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional common.GodownEntryExpandFileType expand_file_type = 11;
 * @return {!proto.common.GodownEntryExpandFileType}
 */
proto.core_api.AssetExtendInfo.prototype.getExpandFileType = function() {
  return /** @type {!proto.common.GodownEntryExpandFileType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.GodownEntryExpandFileType} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setExpandFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional common.MaterialType material_type = 12;
 * @return {!proto.common.MaterialType}
 */
proto.core_api.AssetExtendInfo.prototype.getMaterialType = function() {
  return /** @type {!proto.common.MaterialType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.common.MaterialType} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setMaterialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional common.GodownEntryStatus status = 13;
 * @return {!proto.common.GodownEntryStatus}
 */
proto.core_api.AssetExtendInfo.prototype.getStatus = function() {
  return /** @type {!proto.common.GodownEntryStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.GodownEntryStatus} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.AssetCloseStatus close_status = 14;
 * @return {!proto.common.AssetCloseStatus}
 */
proto.core_api.AssetExtendInfo.prototype.getCloseStatus = function() {
  return /** @type {!proto.common.AssetCloseStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.common.AssetCloseStatus} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setCloseStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int64 suggest_price = 15;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getSuggestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setSuggestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string suggest_color = 16;
 * @return {!Array<string>}
 */
proto.core_api.AssetExtendInfo.prototype.getSuggestColorList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setSuggestColorList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.addSuggestColor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.clearSuggestColorList = function() {
  return this.setSuggestColorList([]);
};


/**
 * optional common.AssetExtendSize asset_size = 17;
 * @return {?proto.common.AssetExtendSize}
 */
proto.core_api.AssetExtendInfo.prototype.getAssetSize = function() {
  return /** @type{?proto.common.AssetExtendSize} */ (
    jspb.Message.getWrapperField(this, common_amp_common_pb.AssetExtendSize, 17));
};


/**
 * @param {?proto.common.AssetExtendSize|undefined} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
*/
proto.core_api.AssetExtendInfo.prototype.setAssetSize = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.clearAssetSize = function() {
  return this.setAssetSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.AssetExtendInfo.prototype.hasAssetSize = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string comment = 18;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string err_message = 20;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getErrMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setErrMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string upload_response = 21;
 * @return {string}
 */
proto.core_api.AssetExtendInfo.prototype.getUploadResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setUploadResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated common.DigitalAssetReviewItem review_images = 22;
 * @return {!Array<!proto.common.DigitalAssetReviewItem>}
 */
proto.core_api.AssetExtendInfo.prototype.getReviewImagesList = function() {
  return /** @type{!Array<!proto.common.DigitalAssetReviewItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.DigitalAssetReviewItem, 22));
};


/**
 * @param {!Array<!proto.common.DigitalAssetReviewItem>} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
*/
proto.core_api.AssetExtendInfo.prototype.setReviewImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.common.DigitalAssetReviewItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.DigitalAssetReviewItem}
 */
proto.core_api.AssetExtendInfo.prototype.addReviewImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.common.DigitalAssetReviewItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.clearReviewImagesList = function() {
  return this.setReviewImagesList([]);
};


/**
 * optional int32 completed_at = 23;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getCompletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setCompletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 created_at = 24;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 updated_at = 25;
 * @return {number}
 */
proto.core_api.AssetExtendInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.AssetExtendInfo} returns this
 */
proto.core_api.AssetExtendInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ModifyAssetExtendInfo.repeatedFields_ = [16,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ModifyAssetExtendInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ModifyAssetExtendInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ModifyAssetExtendInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assetUid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assetName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetEnName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assetTypeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    assetPackageUri: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fileType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    expandFileType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    materialType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    closeStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
    suggestPrice: jspb.Message.getFieldWithDefault(msg, 15, 0),
    suggestColorList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    assetSize: (f = msg.getAssetSize()) && common_amp_common_pb.AssetExtendSize.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 18, ""),
    description: jspb.Message.getFieldWithDefault(msg, 19, ""),
    errMessage: jspb.Message.getFieldWithDefault(msg, 20, ""),
    uploadResponse: jspb.Message.getFieldWithDefault(msg, 21, ""),
    reviewImagesList: jspb.Message.toObjectList(msg.getReviewImagesList(),
    common_amp_common_pb.DigitalAssetReviewItem.toObject, includeInstance),
    completedAt: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ModifyAssetExtendInfo}
 */
proto.core_api.ModifyAssetExtendInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ModifyAssetExtendInfo;
  return proto.core_api.ModifyAssetExtendInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ModifyAssetExtendInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ModifyAssetExtendInfo}
 */
proto.core_api.ModifyAssetExtendInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetUid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetEnName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssetTypeId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPackageUri(value);
      break;
    case 10:
      var value = /** @type {!proto.common.GodownFileType} */ (reader.readEnum());
      msg.setFileType(value);
      break;
    case 11:
      var value = /** @type {!proto.common.GodownEntryExpandFileType} */ (reader.readEnum());
      msg.setExpandFileType(value);
      break;
    case 12:
      var value = /** @type {!proto.common.MaterialType} */ (reader.readEnum());
      msg.setMaterialType(value);
      break;
    case 13:
      var value = /** @type {!proto.common.GodownEntryStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.common.AssetCloseStatus} */ (reader.readEnum());
      msg.setCloseStatus(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuggestPrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestColor(value);
      break;
    case 17:
      var value = new common_amp_common_pb.AssetExtendSize;
      reader.readMessage(value,common_amp_common_pb.AssetExtendSize.deserializeBinaryFromReader);
      msg.setAssetSize(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrMessage(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadResponse(value);
      break;
    case 22:
      var value = new common_amp_common_pb.DigitalAssetReviewItem;
      reader.readMessage(value,common_amp_common_pb.DigitalAssetReviewItem.deserializeBinaryFromReader);
      msg.addReviewImages(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ModifyAssetExtendInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ModifyAssetExtendInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ModifyAssetExtendInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAssetUid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAssetName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetEnName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetTypeId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAssetPackageUri();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getExpandFileType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getMaterialType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCloseStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getSuggestPrice();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getSuggestColorList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getAssetSize();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      common_amp_common_pb.AssetExtendSize.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getErrMessage();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUploadResponse();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getReviewImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      common_amp_common_pb.DigitalAssetReviewItem.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 task_id = 2;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string asset_uid = 3;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetUid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 project_id = 4;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string asset_name = 5;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string asset_en_name = 6;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetEnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetEnName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string asset_type = 7;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 asset_type_id = 8;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string asset_package_uri = 9;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetPackageUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetPackageUri = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional common.GodownFileType file_type = 10;
 * @return {!proto.common.GodownFileType}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getFileType = function() {
  return /** @type {!proto.common.GodownFileType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.GodownFileType} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional common.GodownEntryExpandFileType expand_file_type = 11;
 * @return {!proto.common.GodownEntryExpandFileType}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getExpandFileType = function() {
  return /** @type {!proto.common.GodownEntryExpandFileType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.GodownEntryExpandFileType} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setExpandFileType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional common.MaterialType material_type = 12;
 * @return {!proto.common.MaterialType}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getMaterialType = function() {
  return /** @type {!proto.common.MaterialType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.common.MaterialType} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setMaterialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional common.GodownEntryStatus status = 13;
 * @return {!proto.common.GodownEntryStatus}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getStatus = function() {
  return /** @type {!proto.common.GodownEntryStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.GodownEntryStatus} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.AssetCloseStatus close_status = 14;
 * @return {!proto.common.AssetCloseStatus}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getCloseStatus = function() {
  return /** @type {!proto.common.AssetCloseStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.common.AssetCloseStatus} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setCloseStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int64 suggest_price = 15;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getSuggestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setSuggestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string suggest_color = 16;
 * @return {!Array<string>}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getSuggestColorList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setSuggestColorList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.addSuggestColor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.clearSuggestColorList = function() {
  return this.setSuggestColorList([]);
};


/**
 * optional common.AssetExtendSize asset_size = 17;
 * @return {?proto.common.AssetExtendSize}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getAssetSize = function() {
  return /** @type{?proto.common.AssetExtendSize} */ (
    jspb.Message.getWrapperField(this, common_amp_common_pb.AssetExtendSize, 17));
};


/**
 * @param {?proto.common.AssetExtendSize|undefined} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
*/
proto.core_api.ModifyAssetExtendInfo.prototype.setAssetSize = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.clearAssetSize = function() {
  return this.setAssetSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.hasAssetSize = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string comment = 18;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string err_message = 20;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getErrMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setErrMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string upload_response = 21;
 * @return {string}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getUploadResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setUploadResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated common.DigitalAssetReviewItem review_images = 22;
 * @return {!Array<!proto.common.DigitalAssetReviewItem>}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getReviewImagesList = function() {
  return /** @type{!Array<!proto.common.DigitalAssetReviewItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.DigitalAssetReviewItem, 22));
};


/**
 * @param {!Array<!proto.common.DigitalAssetReviewItem>} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
*/
proto.core_api.ModifyAssetExtendInfo.prototype.setReviewImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.common.DigitalAssetReviewItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.DigitalAssetReviewItem}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.addReviewImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.common.DigitalAssetReviewItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.clearReviewImagesList = function() {
  return this.setReviewImagesList([]);
};


/**
 * optional int32 completed_at = 23;
 * @return {number}
 */
proto.core_api.ModifyAssetExtendInfo.prototype.getCompletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ModifyAssetExtendInfo} returns this
 */
proto.core_api.ModifyAssetExtendInfo.prototype.setCompletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.AssetExtendInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.AssetExtendInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.AssetExtendInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.ModifyAssetExtendInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.AssetExtendInfoRequest}
 */
proto.core_api.AssetExtendInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.AssetExtendInfoRequest;
  return proto.core_api.AssetExtendInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.AssetExtendInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.AssetExtendInfoRequest}
 */
proto.core_api.AssetExtendInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.ModifyAssetExtendInfo;
      reader.readMessage(value,proto.core_api.ModifyAssetExtendInfo.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.AssetExtendInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.AssetExtendInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.AssetExtendInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.ModifyAssetExtendInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyAssetExtendInfo detail = 1;
 * @return {?proto.core_api.ModifyAssetExtendInfo}
 */
proto.core_api.AssetExtendInfoRequest.prototype.getDetail = function() {
  return /** @type{?proto.core_api.ModifyAssetExtendInfo} */ (
    jspb.Message.getWrapperField(this, proto.core_api.ModifyAssetExtendInfo, 1));
};


/**
 * @param {?proto.core_api.ModifyAssetExtendInfo|undefined} value
 * @return {!proto.core_api.AssetExtendInfoRequest} returns this
*/
proto.core_api.AssetExtendInfoRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.AssetExtendInfoRequest} returns this
 */
proto.core_api.AssetExtendInfoRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.AssetExtendInfoRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.AssetExtendInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.AssetExtendInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.AssetExtendInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.core_api.AssetExtendInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.AssetExtendInfoResponse}
 */
proto.core_api.AssetExtendInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.AssetExtendInfoResponse;
  return proto.core_api.AssetExtendInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.AssetExtendInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.AssetExtendInfoResponse}
 */
proto.core_api.AssetExtendInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.AssetExtendInfo;
      reader.readMessage(value,proto.core_api.AssetExtendInfo.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.AssetExtendInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.AssetExtendInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.AssetExtendInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.AssetExtendInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.core_api.AssetExtendInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional AssetExtendInfo detail = 1;
 * @return {?proto.core_api.AssetExtendInfo}
 */
proto.core_api.AssetExtendInfoResponse.prototype.getDetail = function() {
  return /** @type{?proto.core_api.AssetExtendInfo} */ (
    jspb.Message.getWrapperField(this, proto.core_api.AssetExtendInfo, 1));
};


/**
 * @param {?proto.core_api.AssetExtendInfo|undefined} value
 * @return {!proto.core_api.AssetExtendInfoResponse} returns this
*/
proto.core_api.AssetExtendInfoResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core_api.AssetExtendInfoResponse} returns this
 */
proto.core_api.AssetExtendInfoResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core_api.AssetExtendInfoResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListAssetExtendInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListAssetExtendInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListAssetExtendInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListAssetExtendInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.core_api.AssetExtendInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListAssetExtendInfoResponse}
 */
proto.core_api.ListAssetExtendInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListAssetExtendInfoResponse;
  return proto.core_api.ListAssetExtendInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListAssetExtendInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListAssetExtendInfoResponse}
 */
proto.core_api.ListAssetExtendInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core_api.AssetExtendInfo;
      reader.readMessage(value,proto.core_api.AssetExtendInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListAssetExtendInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListAssetExtendInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListAssetExtendInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core_api.AssetExtendInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated AssetExtendInfo list = 1;
 * @return {!Array<!proto.core_api.AssetExtendInfo>}
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.core_api.AssetExtendInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core_api.AssetExtendInfo, 1));
};


/**
 * @param {!Array<!proto.core_api.AssetExtendInfo>} value
 * @return {!proto.core_api.ListAssetExtendInfoResponse} returns this
*/
proto.core_api.ListAssetExtendInfoResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core_api.AssetExtendInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core_api.AssetExtendInfo}
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core_api.AssetExtendInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListAssetExtendInfoResponse} returns this
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListAssetExtendInfoResponse} returns this
 */
proto.core_api.ListAssetExtendInfoResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.GetAssetExtendInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.GetAssetExtendInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.GetAssetExtendInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetAssetExtendInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.GetAssetExtendInfoRequest}
 */
proto.core_api.GetAssetExtendInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.GetAssetExtendInfoRequest;
  return proto.core_api.GetAssetExtendInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.GetAssetExtendInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.GetAssetExtendInfoRequest}
 */
proto.core_api.GetAssetExtendInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.GetAssetExtendInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.GetAssetExtendInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.GetAssetExtendInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.GetAssetExtendInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.core_api.GetAssetExtendInfoRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.GetAssetExtendInfoRequest} returns this
 */
proto.core_api.GetAssetExtendInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core_api.ListAssetExtendInfoRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core_api.ListAssetExtendInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core_api.ListAssetExtendInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListAssetExtendInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core_api.ListAssetExtendInfoRequest}
 */
proto.core_api.ListAssetExtendInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core_api.ListAssetExtendInfoRequest;
  return proto.core_api.ListAssetExtendInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core_api.ListAssetExtendInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core_api.ListAssetExtendInfoRequest}
 */
proto.core_api.ListAssetExtendInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core_api.ListAssetExtendInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core_api.ListAssetExtendInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core_api.ListAssetExtendInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.core_api.ListAssetExtendInfoRequest} returns this
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.core_api.ListAssetExtendInfoRequest} returns this
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core_api.ListAssetExtendInfoRequest} returns this
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListAssetExtendInfoRequest} returns this
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core_api.ListAssetExtendInfoRequest} returns this
 */
proto.core_api.ListAssetExtendInfoRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.core_api);
