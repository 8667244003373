import React, { Component } from "react";
import { Modal } from "antd";
import { RoleForm } from "./form_renders";

export default class AddRole extends Component {
  state = {
    type: "add",
    visible: false,
    loading: false,
    permissionCheckArr: {},
    roleDetails: {},
  };
  componentDidMount() {
    this.props.onRef(this);
    this.props.getPermissions();
  }

  show = async (type, id) => {
    const visible = true;
    let roleDetails = {};
    if (type === "edit") {
      roleDetails = await this.props.fetchRoleDetails(id);
    }
    this.setState({ visible, type, roleDetails });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleSubmit = (val) => {
    const { type } = this.state;
    if (type === "add") {
      this.props.createRole(val, {
        success: this.submitSuccess,
        error: () => {},
      });
    } else if (type === "edit") {
      this.props.editRole(val, this.submitSuccess);
    }
  };

  submitSuccess = () => {
    this.hide();
    const roleName = this.state.roleDetails.name;
    const { currentUser } = this.props;
    if (currentUser.role && currentUser.role === roleName) {
      this.props.fetchUserPermissions();
    }
  };

 render() {
    const {
      type,
      roleDetails,
      permissionCheckArr,
      visible,
      loading,
    } = this.state;
    return (
      <Modal
        className="large-form-dialog editRole"
        width="704px"
        destroyOnClose
        visible={visible}
        confirmLoading={loading}
        footer={null}
        onCancel={this.hide}
      >
        <div className="dialog-title">
          {type === "add" ? "新增角色" : "编辑角色"}{" "}
        </div>

        <RoleForm
          {...this.props}
          type={type}
          roleDetails={roleDetails}
          permissionCheckArr={permissionCheckArr}
          handleSubmit={this.handleSubmit}
          handleCancel={this.hide}
        ></RoleForm>
      </Modal>
    );
  };
}
