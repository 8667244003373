import { connect } from "react-redux";
import { fetchTasks } from "@actions/1_entities/tasks_actions";
import TaskDrawer from './task_drawer'

const mapStateToProps = (state) => ({
  listTypes: state.configs.task_list_type,
  filter: state.pagination.tasks.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTasks: (params, cb) => dispatch(fetchTasks(params, cb)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskDrawer);