// 连接React组件与 react store
// mapStateToProps 将store中的数据作为props绑定到组件上。
// mapDispatchToProps
import { connect } from "react-redux";
import DelUser from './del_user';
import { deleteUser } from "@actions/1_entities/user_actions";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (params, cb) => dispatch(deleteUser(params, cb)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DelUser)