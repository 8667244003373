import { Form, Button, Select, DatePicker, Input } from 'antd';
import { AuthButton } from "@/components"
import moment from "moment";
import { configsAPI, projectSettingsAPI } from '@api'
import { useSwr } from '@hooks';
import { timeStamp } from './../config';

const { RangePicker } = DatePicker;
const { Option } = Select;
const FormITem = Form.Item

/**
 * 表单处理
 * @param {*} props props
 * @returns
 */
const FilterForm = (props) => {
    const {
        resetForm,
        submitForm,
        csvExport,
        btnLoading
    } = props
    const [form] = Form.useForm()

    /**
     * 获取任务状态
     * @returns {Object}
     */
    const taskStatusRequest = () => {
        return configsAPI.fetchTaskConfig().then((res) => {
            if (res.code === 1000) {
                const data = res.data?.task_status
                return Object.values(data)?.map(i => ({
                    id: i.code,
                    name: i.label
                }))
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: taskStatus = [] } = useSwr('/task/config', taskStatusRequest)

    /**
     * 获取小组组员
     * @returns {Object}
     */
    const getUserList = () => {
        return projectSettingsAPI.fetchUserRelations({ filter: { type: 9 } }).then((res) => {
            if (res.code === 1000) {
                return res.data.list
            }
        })
    }

    const { data: userList, userListLoading } = useSwr(['/user-relation/list'], getUserList)

    /**
     * 提交表单
     */
    const handleSubmit = (value) => {
        if (value.created_at) {
            value.start_time = timeStamp(moment(value?.created_at[0]), 1)
            value.end_time = timeStamp(moment(value?.created_at[1]), 2)
        }
        delete value?.created_at
        submitForm(value)
    }

    /**
     * 重置表单
     */
    const submitFormReset = () => {
        form.resetFields();
        resetForm(form.getFieldsValue())
    }

    /**
     * 导出数据
     */
    const submitFormExport = () => {
        csvExport();
    }

    /**
     * 禁用时间
     * @param {*} current
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <div className="amp-business-data-from-page">
            <Form
                className='amp-business-data-form'
                onFinish={handleSubmit}
                form={form}
            >

                <FormITem
                    name="created_at"
                    label='时间'
                >
                    <RangePicker
                        disabledDate={disabledDate}
                        className='amp-range-picker'
                    />
                </FormITem>
                <FormITem
                    label="组员"
                    name="user_id"
                >
                    <Select
                        size="large"
                        placeholder="请选择人员"
                        className="amp-input-sz-l"
                        showSearch={true}
                        loading={userListLoading}
                        optionFilterProp="children"
                    >
                        {
                            userList?.map(item => (
                                <Option
                                    key={item.id}
                                    value={item.user_id}
                                >
                                    {item.name}
                                </Option>
                            ))
                        }
                    </Select>
                </FormITem>
                <FormITem
                    label="资产"
                    name="asset_id_name"
                >
                    <Input
                        maxLength={20}
                        className="amp-input-sz-l"
                        placeholder='请输入资产名称及资产ID'
                    />
                </FormITem>
                <FormITem
                    label="状态"
                    name="task_status"
                >
                    <Select
                        className="amp-input-sz-l"
                        size="large"
                        placeholder="请选择"
                    >
                        {
                            taskStatus.map(item => (
                                <Option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </Option>
                            ))
                        }
                    </Select>
                </FormITem>
                <FormITem
                    className='amp-business-data-form-buttons'
                >
                    <Button
                        className='amp-btn-sz-s amp-blue-border'
                        onClick={submitFormReset}
                    >
                        重置
                    </Button>
                    <Button
                        className='amp-btn-sz-s'
                        type="primary"
                        htmlType='submit'
                    >
                        查询
                    </Button>
                </FormITem>
            </Form>
            <div className="amp-business-data-button">
                <AuthButton
                    loading={btnLoading}
                    permission="asset_info_statistic_export"
                    className='amp-btn-sz-s'
                    onClick={submitFormExport}
                >
                    导出
                </AuthButton>
            </div>
        </div>
    )
}

export default FilterForm

