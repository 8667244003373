import React, { Component } from "react";
import {
  Filter,
  UserTable,
  AddEditUser,
  DeleteUser,
} from "./";
import { AuthButton, AccessControl } from '@/components'
import { userEdit } from "@vars/views/user_vars";
class SettingsUser extends Component {
  getDatas = (v1, v2, data) => {
    this.setState({
      visible: v1,
      visibleDel: v2,
      data: data,
    });
  };

  editOnRef = (ref) => {
    this.child = ref;
  };

  delRef = (ref) => {
    this.child.DelUser = ref;
  };

  addUser = () => {
    this.child.showEdit("add", userEdit);
  };

  delShow = (data) => {
    this.child.DelUser.delShow(data);
  };

  editUser = (type, data) => {
    this.child.showEdit(type, data);
  };

  handleSaveDelete = (data) => {
    this.child.hideEdit();
    this.delShow(data);
  };

  handleSolve = (data) => {
    this.child.hideEdit();
  };

 render() {
    return (
      <div>
        <div className="settings-user-head">
          <AccessControl permission="user_list">
            <Filter />
          </AccessControl>
          <AuthButton
            permission="user_create"
            type="primary"

            onClick={this.addUser}
          >
            新增用户
          </AuthButton>
        </div>
        <div className="settings-user-content">
            <UserTable {...this.props} onRowClick={this.editUser} />
        </div>
        <AddEditUser
          onRef={this.editOnRef}
          {...this.props}
          handleShowDel={this.handleSaveDelete}
          handleSolve={this.handleSolve}
        />
        <DeleteUser
          onRef={this.delRef}
          {...this.props}
          handleShowEdit={this.editUser}
        />
      </div>
    );
  };
}

export default SettingsUser;
