import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, AccessControl, Iconfont } from "@/components";
import { fetchAssets } from "@actions/1_entities/assets_actions";
import {assetColumns} from '@vars/views/asset_vars'
import { centerStyle } from "@vars/views/dashboard_vars";
import { TaskDrawer } from "@components";
import { withRouter } from "react-router";
import { Modal } from "antd";
import AssetUpdate from './asset_update'
import { observer, inject } from "mobx-react";
@inject("filterStore")
@observer
class AssetTable extends React.Component {
  state = {
    show : false,
    updateAsset : {},
  }

  renderAction = (record) => {
    const url = this.getUrl(record, "/assets");
    const onClick = (e) => {
      this.props.filterStore.footerShow = false
      e.stopPropagation();
      this.props.history.push(url);
    };
    const onUpdate = (e) => {
      const { taskId } = this.getUrlParse(url);
      for (const key in this.props.entity) {
        let val = this.props.entity[key];
        if (`${val.task_id}` === taskId) {
          this.setState(() => {
            return {
              ...this.state,
              show : !this.state.show,
              updateAsset: val,
            }
          })
        }
      }
    }
    return (
      <>
        {
          this.props.userPermissions.indexOf("asset_task_history") > -1 && 
          (
            <div className="primary-color table-row" onClick={onClick} style={centerStyle}>
              查看
            </div>
          )
        }
        {
          this.props.userPermissions.indexOf("asset_update") > -1 &&
          (
            <div className={`${this.props.userPermissions.indexOf("asset_task_history") > -1 ? 'mg-l-50': ''} primary-color table-row`} onClick={onUpdate} style={centerStyle}>
                 修改
             </div>
          ) 
        }
      </>
    );
  }

  hide = () => {
    this.setState(() => {
      return {
        ...this.state,
        show : false,
      }
    })
  }

  getUrlParse = (url) => {
    let obj = {};
    let reg = /[?&][^?&]+=[^?g]+/g;
    let arr = url.match(reg);
    if (arr) {
      arr.forEach((item) => {
        let tempArr = item.substring(1).split("=");
        let key = decodeURIComponent(tempArr[0]);
        let val = decodeURIComponent(tempArr[1]);
        obj[key] = val;
      })
    }
    return obj;
  }
  
  getUrl = ({ project_id, task_id, plan_id, typeOperate = "task" }, base) => {
    let url;
    let baseUrl = base || `/projects/${project_id}`;
    switch (typeOperate) {
      case "project":
        url = `/projects/${project_id}`;
        break;
      case "plan":
        url = `${baseUrl}?shootingPlanId=${plan_id}`;
        break;
      case "task":
        url = `${baseUrl}?task_flow?taskId=${task_id}`;
        break;
      default:
        url = "";
        break;
    }
    return url;
  }

  render () {
    const { selectedRowKeys, paramsObj } = this.props.filterStore
    const action = {
      title: "操作",
      key: "operation",
      render: this.renderAction,
    };
    const finalColumns = [...assetColumns, action];
    const rowSelection = {
      selectedRowKeys,
      onChange: (val) => {
        this.props.filterStore.selectedRowKeys = val;
      }
    };
    return (
      <>
        <Table
          columnsConfig={finalColumns}
          defaultPageSize={6}
          rowClassName="asset-table-row"
          rowSelection={rowSelection}
          filter={paramsObj.filter}
          {...this.props}
        />
        <TaskDrawer/>
        <Modal
          width="436px"
          className="editAsset"
          destroyOnClose
          footer={null}
          visible={this.state.show}
          confirmLoading={true}
          onCancel={this.hide}
          style={{ top: "220px", left: "center" }}
        >
          <div className="assets-update-box">
            <div className="assets-update-title mg-b-30">
              {"修改"}
            </div>
            <AssetUpdate {...this.props} hide={this.hide} {...this.state.updateAsset} asset_category={this.props.configs.asset_category}></AssetUpdate>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  entity: state.entities.assets,
  currentPage: state.pagination.assets.currentPage,
  pages: state.pagination.assets.pages,
  total: state.pagination.assets.total,
  filter: state.pagination.assets.filter,
  userPermissions: state.session.permissions,
});
const mapDispatchToProps = (dispatch) => ({
  fetchItems: (params, success, error) =>
    dispatch(fetchAssets(params, success, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetTable));
