/**
 * @name: emptyLayout.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description: 空的布局文件 常用增加节点
 */

import { useOutlet } from '@components/router/routes';

export const EmptyLayout = function () {
    return useOutlet();
};
