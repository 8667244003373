import { Iconfont } from "@/components";
import { useHistory } from "react-router";
import { Empty } from "antd";
import { Spin } from "antd";

const Announcements = (props) => {
  const list =
    props.notifications && props.notifications.list
      ? props.notifications.list.slice(0, 3)
      : [];
  const history = useHistory();
  const viewAll = () => {
    history.push(`account/notifications`);
  };
  const goToDetail = (id) => {
    history.push(`announcements/${id}`);
  };

  const renderRecord = (announcement) => {
    let { title, id, has_read, published_at } = announcement;
    published_at = published_at.split(" ")[0].replace(/-/g, "/");
    const newIcon = !has_read ? (
      <Iconfont type="icon-new" className="icon-new" />
    ) : (
      ""
    );

    return (
      <div
        key={id}
        className="display-flex space-between mg-b-16 board-hover"
        onClick={() => goToDetail(id)}
      >
        <div className="display-flex align-center">
          <span className={`mg-r-10 fz-14 width-254`}>
            {title}
          </span>
          {newIcon}
        </div>
        <div className="tr">{published_at}</div>
      </div>
    );
  };

  const title = (
    <div className="between mg-b-20">
      <div className="board-title">通知公告</div>
      <div className="board-view-all board-hover" onClick={viewAll}>
        查看全部
      </div>
    </div>
  );

  const announcements = list?.map((item) => renderRecord(item));
  const { spinning } = props;

  return (
    <div className="board-border">
        {title}
        {
            spinning ? (
                <Spin spinning={spinning} size="large" />
            ) : (
                list.length > 0 ? announcements : <Empty/>
            )
        }
    </div>
  );
};
export default Announcements;
