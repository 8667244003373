import { useState } from 'react';
import { useOutlet } from '@/components/router/routes';
import TopicWrap from './components/topic_wrap';
import SearchForm from './components/search_form';
import SceneWrap from './components/scene_wrap';
import './index.less';

/**
 * 场景管理列表
 * @param {*} props props
 * @constructor
 */
const SceneManage = () => {
    const [selectTopic, setSelectTopic] = useState<any>(null);
    const [searchData, setSearchData] = useState<any>(null);
    const outlet = useOutlet();

    /**
     *搜索表单提交
     * @param {*} values 搜索数据
     */
    const onSearch = (values: any) => {
        setSearchData(values);
    };
    return (
        <>
            {outlet}
            <div className="amp-topic-scene-manage-page page-container">
                <div className="amp-scene-manage-content">
                    <div className="amp-manage-content-topic-wrap">
                        <div className="amp-manage-content-topic-wrap-border">
                            <TopicWrap
                                // searchData={searchData}
                                setSelectTopic={(item: any) => setSelectTopic(item)}
                            />
                        </div>
                    </div>
                    <div className="amp-manage-content-scene-wrap">
                        <SearchForm
                            onSearch={onSearch}
                        />
                        <div className="amp-scene-wrap-title">
                            {selectTopic?.name ?? '-'}
                        </div>
                        <SceneWrap
                            searchData={searchData}
                            selectTopic={selectTopic}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SceneManage;
