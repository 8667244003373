import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { rules } from "@vars/global_vars";
import { passEncrypt } from "@/utils/global/session_util";
import { Iconfont, AuthButton } from "@/components";

class SessionForm extends Component {
  handleSubmit = (values) => {
    let { username, password } = values;
    password = passEncrypt(password);
    const user = { username, password };
    this.props.submitForm(user);
  };
  valuesChange = () => {
    this.props.resetErr();
  };

  backgroundRender = () => {
    let randomPic = Math.ceil(Math.random() * 2);
    return (
      <img
        src={`${process.env.PUBLIC_URL}/images/background/${randomPic}.jpg`}
        style={{ width: "100%", height: "100%" }}
        alt="background"
      />
    );
  };

  componentDidMount() {
    this.props.resetErr();
  }

  render() {
    return (
      <div>
        <div className="session-bg">{this.backgroundRender()}</div>
        <img
          className="powered-by-ecoplants"
          src={process.env.PUBLIC_URL + "/images/logo/3.png"}
          alt="logo"
        />
        <div className="login-wrapper">
          <div>
            <div className="login-title">
              <img
                src={process.env.PUBLIC_URL + "/images/logo/1.png"}
                alt="logo"
              />
            </div>
            <div className="login-form">
              <Form
                onFinish={this.handleSubmit}
                onValuesChange={this.valuesChange}
                validateTrigger="onBlur"
              >
                <Form.Item
                  className="form-item"
                  name="username"
                  rules={rules.username}
                >
                  <Input
                    className="password-input input-sz-l"
                    placeholder="用户名/邮箱"
                    prefix={
                      <Iconfont
                        type="icon-color-profile"
                        className="icon-20 mg-r-6"
                      />
                    }
                  />
                </Form.Item>

                <Form.Item
                  className="form-item"
                  name="password"
                  rules={rules.password}
                >
                  <Input.Password
                    className="password-input input-sz-l"
                    placeholder="密码"
                    prefix={
                      <Iconfont
                        type="icon-color-lock"
                        className="icon-20 mg-r-6"
                      />
                    }
                  />
                </Form.Item>

                <div className="session-error">{this.props.error}</div>

                <Form.Item className="form-item">
                  <AuthButton
                    className="session-button"
                    type="primary"
                    htmlType="submit"
                  >
                    登录
                  </AuthButton>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SessionForm;
