import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Select, Spin } from "antd";

const { Option } = Select;

@inject("configsStore")
@observer
class UserSelect extends Component {
  get store() {
    return this.props.configsStore;
  }
  get relatedUsers() {
    return this.props.params
      ? this.props.configsStore.relatedUsers.map((item) => ({
          id: item.user_id,
          name: item.name,
        }))
      : this.props.configsStore.allUsers;
  }

  componentWillUnmount() {
    this.store.clearUsers();
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = () => {
    const { params } = this.props;
    const hasValue =
      params &&
      Object.values(params || {})?.filter((item) => item !== "").length > 0;
    if (params) {
      if (hasValue && this.relatedUsers.length < 1)
        this.store.fetchRelatedUsers(params);
    } else {
      if (this.relatedUsers.length < 1) this.store.fetchAllUsers();
    }
  };

  render() {
    const { userFetching, secretList } = this.store;
    let options = [];
    if (this.props.sign === "exist") {
      let temp = [];
      const len = secretList.length;
      const relLen = this.relatedUsers.length;
      if (len > 0) {
        for (let i = 0; i < relLen; i++) {
          for (let j = 0; j < len; j++) {
            if (secretList[j].id === this.relatedUsers[i].id) {
              temp.push(this.relatedUsers[i]);
            }
          }
        }
      } else if (len === 0) {
        temp = this.relatedUsers;
      }
      options = temp.map((user) => (
        <Option key={user.id} value={user.id} label={user.name}>
          {user.name}
        </Option>
      ));
    } else {
      options = this.relatedUsers.map((user) => (
        <Option key={user.id} value={user.id} label={user.name}>
          {user.name}
        </Option>
      ));
    }
    const { configsStore, isDisable, ...rest } = this.props;
    return (
      <Select
        {...rest}
        allowClear
        disabled={isDisable}
        showSearch
        optionFilterProp="children"
        showArrow
        size="large"
        notFoundContent={userFetching ? <Spin size="small" /> : null}
        onClick={this.fetchUsers}
        className={this.props.className}
      >
        {options}
      </Select>
    );
  }
}
export default UserSelect;
