import "./index.less";

/**
 * 暂无数据
 * @returns
 */
export const NoData = () => {
  return (
    <div className="amps-no-data ">
      <div className="no-data-body flex-column  flex-center">
        <img
          alt=""
          className="data-img"
          src={`${process.env.PUBLIC_URL}/images/taskPackage/noData.png`}
        ></img>
        <div className="data-text">暂无策划任务</div>
      </div>
    </div>
  );
};
