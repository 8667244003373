import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Collapse, Iconfont } from '@components';
import { historyFlowSteps, historyFlowStepsWithoutCollectScore, previousHistoryFlowSteps } from '@vars/views/task_flow_vars';
import SingleHistoryItem from './single_history_item';
import { Timeline } from 'antd';
import { withRouter } from 'react-router';
import { AssetsInfoRegion, TaskInfoRegion, TaskPackageRegion } from './../task_details';

@inject("taskStore")
@observer
class TaskHistoryContent extends Component {

    componentDidMount() {
        const { search } = this.props.location;
        const taskId = parseInt(search.replace("?task_flow?taskId=", ""));
        this.store.getProjectDetail(taskId)
        // this.store.getTaskPackageInfo(taskId)
    }
    get store() {
        return this.props.taskStore;
    }
    get currentTask() {
        return this.props.taskStore.currentTask;
    }
    get currentStatus() {
        return this.props.taskStore.taskStatus;
    }

    renderTitle = () => {
        const { processing_time, asset_uid, asset_name, task_status, assignees, type, title } =
            this.currentTask;
        return (
            <div className="drawer-title amp-drawer-title-fixed">
                <div>
                    <h2 style={{ display: "inline-block" }}>{`资产：#${asset_uid} ${type === 0 ? asset_name : title}`}</h2>
                </div>
                <div className="align-center mg-t-12 mg-b-12">
                    <div className="mg-r-16 task-status-display" style={{ background: `${task_status?.color}` }}>{task_status?.label}</div>
                    <div className="align-center mg-r-16">
                        <Iconfont type="icon-clock" className="icon-18 mg-r-6" /> <span> {processing_time} </span>
                    </div>
                    {
                        assignees?.length > 0 && (
                            <div className="align-center">
                                <Iconfont type="icon-a-18-renyuan" className="icon-18 mg-r-6" /> <span> {assignees[0]?.name || []} </span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };

    renderLabelCode = (step, temp) => {
        // let temp = 0;
        // const type = this.props.taskStore.currentTask?.task_package_item?.type;
        // let res = [];
        // if (type === 1) {
        //     res = historyFlowStepsWithoutCollectScore;
        // } else if (type === 2) {
        //     res = historyFlowSteps;
        // } else if (type === 0) {
        //     res = previousHistoryFlowSteps;
        // }
        // console.log('this.currentStatus', this.currentStatus, step)
        // temp = step.label_code
        // res.map((item) => {
        //     if (this.currentStatus === 18) {
        //         temp = 19;
        //         if (type === 1) {
        //             temp = 18
        //         }
        //     } else if (this.currentStatus === 19) {
        //         temp = 19;
        //         if (type === 0) {
        //             temp = 20
        //         } else if (type === 1) {
        //             temp = 18
        //         }
        //     } else if (item.id === this.currentStatus) {
        //         temp = item.label_code
        //     }
        // })
        let stepTime;
        if (temp === step.label_code) {
            stepTime = "current";
        } else if (temp > step.label_code) {
            stepTime = "past";
        } else {
            stepTime = "future";
        }
        return (
            <div className="display-flex">
                <div className={`${stepTime}-history-step mg-r-10`}>
                    {step.label_code}
                </div>
            </div>
        );
    };

    queryString = (getObj, str) => {
        var string = (str !== undefined) ? str : window.location.search;
        var result = string.match(new RegExp("[^\?\&]+=[^\?\&]+", "g"));
        if (result == null) {
            result = '';
        } else if (getObj) {
            var params = {};
            for (var i = 0; i < result.length; i++) {
                var res = result[i].split('=');
                var key = res[0],
                    value = res[1];
                params[key] = value;
            }
            result = params;
        }
        return result;
    }

    queryByIndex(index) {
        var param = this.queryString()[index];
        var value = param ? param.split('=')[1] : ''
        return value;
    }

    renderCollapse = (step) => {
        const { history, currentTask } = this.store;
        const res = this.queryByIndex(1);

        let opened
        if (res) {
            opened =
                step.id === parseInt(res) - 1
        } else {
            opened =
                step.id === currentTask.prev_status || (currentTask.prev_status > 18 && step.id === 18)
        }
        let content;
        if (step.id === 18) {
            content = history.filter((item) => item.status === 18 || item.status === 19);
        } else {
            content = history.filter((item) => item.status === step.id);
        }
        return (
            <Collapse header={step.label} opened={opened} fontWeight="fw-400">
                {content.length > 0
                    ? content?.map((record, idx) => {
                        const actionKey = record.action.split("_")[0]
                        const color = ["rollback", "disapprove", "stop"].includes(actionKey)
                            ? "#EC3B3B"
                            : "#4487DC";
                        return (
                            <Timeline.Item key={idx} style={{ marginLeft: "-28px" }} color={color}>
                                <div>{record?.updated_at.replace(/-/g, "/") || ""}</div>
                                <SingleHistoryItem record={record} />
                            </Timeline.Item>
                        );
                    })
                    : ""}
            </Collapse>
        );
    };

    getTemp = (history) => {
        const status = this.currentStatus === 19 ? 18 : this.currentStatus
        const temp = history.find(item => item.id === status)?.label_code || ''
        return temp
    }

    renderSingleSection = (step, temp) => {
        return (
            <div key={step.id} className="display-flex mg-t-24 mg-b-16">
                {this.renderLabelCode(step, temp)}
                <Timeline>{this.renderCollapse(step)}</Timeline>
            </div>
        );
    };
    renderHistory = () => {
        const type = this.props.taskStore.currentTask?.task_package_item?.type;
        let res = null
        if (type === 1) {
            const temp = this.getTemp(historyFlowStepsWithoutCollectScore)
            res = historyFlowStepsWithoutCollectScore?.map((step) => this.renderSingleSection(step, temp));
        } else if (type === 2) {
            const temp = this.getTemp(historyFlowSteps)
            res = historyFlowSteps?.map((step) => this.renderSingleSection(step, temp));
        } else {
            const temp = this.getTemp(previousHistoryFlowSteps)
            res = previousHistoryFlowSteps?.map((step) => this.renderSingleSection(step, temp));
        }
        return res;
    }


    render() {
        const { ProjectDetail, TaskPackageInfo, isShowTaskInfoRegion } = this.store
        return (
            <div>
                <div>{this.renderTitle()}</div>
                <AssetsInfoRegion {...ProjectDetail} />
                {
                    isShowTaskInfoRegion && (
                        <>
                            <TaskPackageRegion {...TaskPackageInfo} />
                            <TaskInfoRegion {...TaskPackageInfo} />
                        </>

                    )
                }
                <Collapse header="处理流程" fontWeight="fw-500" opened={true}>{this.renderHistory()}</Collapse>
            </div>
        );
    }
}
export default withRouter(TaskHistoryContent);
