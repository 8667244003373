/**
 * @name: useAccess.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 用户权限hook
 */
import { getUserAuth } from '@api';
import useSwr from './swr';
import { accessMatch } from '@gUtils';

/**
 * 快速判断用户权限
 * @param access {undefined, string|string[]}
 * @param options
 * @param options.whereType 匹配模式 AND:access中的权限全部满足，OR: access中的权限又一个满足
 * @returns {undefined| string[], bool}
 */
const useAccess = function (access, options) {
    const { whereType = 'AND', ...other } = options || {};
    const { data } = useSwr('/account/permissions', getUserAuth, {
        revalidateIfStale: false,
        ...other
    });
    if (access && data?.list) {
        return accessMatch(data.list, access, whereType);
    } else {
        return data?.list;
    }
};
export default useAccess;
