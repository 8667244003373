/**
 * @name: Empty.jsx
 * @user: cfj
 * @date: 2022/4/21
 * @description: 空状态
 */

import { clsx } from '@gUtils'
import { Empty as AEmpty } from 'antd'

export const Empty = function (props){
  const {isSearch, className, ...other} = props
  const description = isSearch?'未搜索到数据':'暂无数据'
  return <AEmpty  {...other}  className={clsx('py-4', className)} description={description} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
}
