/* eslint-disable require-jsdoc */
/* eslint-disable no-shadow */
import React, { useRef, useState } from 'react';
import { Button, Drawer as DrawerAntd, Form, Space } from 'antd';
import ReactDOM from 'react-dom';
import './index.less';

const closeFns = [];
let id = 0;

class Drawer extends React.Component {
    static useDrawer(defaultOptions) {
        const drawersRef = useRef(new Map());
        const nodesRef = useRef(new Map());
        const render = useState({})[1];

        function close(key) {
            if (drawersRef.current.has(key)) {
                drawersRef.current.get(key)
                    .onClose();
            }
        }

        function destroy(key) {
            const nodes = nodesRef.current;
            const has = nodes.delete(key);
            if (has) {
                render({});
            }
        }

        function open(options) {
            id += 1;
            const key = id;
            const nodes = nodesRef.current;
            nodes.set(key, <Drawer
                {...defaultOptions}
                {...options}
                key={key}
                afterClose={() => destroy(key)}
                ref={(ref) => { drawersRef.current.set(key, ref); }}
            />);
            render({});
            return close.bind(null, key);
        }

        const children = [];
        nodesRef.current.forEach((value) => {
            if (value) {
                children.push(value);
            }
        });
        return [children, open];
    }

    static open(options) {
        let drawerRef = null;

        function close() {
            drawerRef.onClose();
        }

        const container = document.createDocumentFragment();

        function destroy() {
            ReactDOM.unmountComponentAtNode(container);
            const index = closeFns.indexOf(close);
            if (index > -1) {
                closeFns.splice(index, 1);
            }
        }

        setTimeout(() => {
            ReactDOM.render(<Drawer
                {...options}
                afterClose={() => destroy()}
                ref={(ref) => { drawerRef = ref; }}
            />, container);
            closeFns.push(close);
        });
        return close;
    }

    static closeAll() {
        closeFns.forEach((i) => i?.());
    }

    node = null;

    constructor(props) {
        super(props);
        // 操作成功自动关闭
        this.resolveAutoClose = true;
        // 操作成功自动清空表单
        this.resolveEmptyForm = false;
        this.state = {
            visible: true,
            loading: false,
            error: null
        };
    }

    onClose() {
        const { onClose } = this.props;
        onClose?.();
        this.setState({ visible: false });
    }

    renderFooter() {
        const {
            footer,
            appendButton,
            onOk,
            okText,
            cancelText
        } = this.props;
        const { loading } = this.state;
        return footer === undefined ? (
            <Space size={40}>
                {onOk
                    && (
                        <Button
                            size="large"
                            style={{ width: 120 }}
                            type="primary"
                            onClick={() => {
                                this.resolveAutoClose = true;
                                this.resolveEmptyForm = false;
                                this.node?.submit?.();
                            }}
                            loading={loading}
                        >
                            {okText || '确定'}
                        </Button>
                    )}
                <Button size="large" style={{ width: 120 }} onClick={() => this.onClose()}>{cancelText || '取消'}</Button>
                {appendButton?.({
                    loading,
                    submit: () => this.node?.submit(),
                    disabledAutoClose: () => {
                        this.resolveAutoClose = false;
                    },
                    openEmptyForm: async () => {
                        this.resolveEmptyForm = true;
                    }
                })}
            </Space>
        ) : footer;
    }

    render() {
        const {
            content,
            afterClose,
            onOk,
            onError,
            // eslint-disable-next-line no-unused-vars
            appendButton,
            // eslint-disable-next-line no-unused-vars
            okText,
            // eslint-disable-next-line no-unused-vars
            centerText,
            ...other
        } = this.props;
        const {
            visible,
            error
        } = this.state;
        const cloneContent = React.cloneElement(content, {
            ref: onOk ? (node) => { this.node = node; } : undefined,
            onClose: () => this.onClose(),
            error
        });
        const Component = onOk ? Form.Provider : React.Fragment;
        const componentProps = onOk ? {
            onFormFinish: async (name, info) => {
                this.setState({ loading: true });
                try {
                    await onOk?.(info.values);
                    this.setState({
                        loading: false,
                        visible: !this.resolveAutoClose
                    });
                    if (this.resolveEmptyForm) {
                        this.node?.getForm?.()
                            .resetFields?.();
                    }
                } catch (e) {
                    onError?.(e);
                    this.setState({
                        loading: false,
                        error: e
                    });
                }
            }
        } : undefined;
        return (
            <Component {...componentProps}>
                <DrawerAntd
                    {...other}
                    className="amps-drawer"
                    visible={visible}
                    afterVisibleChange={(visible) => {
                        if (!visible) {
                            afterClose();
                        }
                    }}
                    onClose={() => this.onClose()}
                    footer={this.renderFooter()}
                >
                    {cloneContent}
                </DrawerAntd>
            </Component>

        );
    }
}

export default Drawer;
