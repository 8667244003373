/**
 * @name: sideBarLayout.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description: 左边导航布局
 */

import { matchPath, resolveUrl, useGetAuthRoutes, useOutlet } from '@components/router/routes';
import { Grid, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { AmpIcon, Iconfont } from '@components';
import './index.less';
import { clsx } from '@gUtils';
import { useHistory } from 'react-router';

const { useBreakpoint } = Grid;
const { SubMenu } = Menu;

/**
 * 递归渲染
 * @param {any} items 组件配置
 * @param {number} level 层级
 * @returns {*}
 */
function renderItem(items, level) {
    return items.map((item) => {
        if (item.routes && level < 2) {
            return (
                <SubMenu
                    key={item.name}
                    title={(
                        <span className="d-flex align-items-center">
                            {item.icon && <Iconfont type={item.icon} />}
                            <span className="menu-text">{item.title}</span>
                        </span>
                    )}
                >
                    {renderItem(item.routes, level + 1)}
                </SubMenu>
            );
        }
        return (
            <Menu.Item key={item.name}>
                <span className="menu-text" style={{ fontWeight: 400 }}>
                    {item.icon && <Iconfont type={item.icon} />}
                    <span className="menu-text">{item.title}</span>
                </span>
            </Menu.Item>
        );
    });
}

// eslint-disable-next-line import/prefer-default-export,require-jsdoc
export const SideBarLayout = function () {
    const outlet = useOutlet();
    const [collapsed, setCollapsed] = useState(false);
    const history = useHistory();
    const screens = useBreakpoint();
    useEffect(() => {
        if (screens.lg === false && !collapsed) {
            setCollapsed(true);
        }
    }, [screens.lg]);

    const routes = useGetAuthRoutes();
    const authItems = routes.sort((a, b) => a.menuSore - b.menuSore);
    const selectedKey = [].concat(...authItems.map((item) => {
        if (item.routes) {
            return item.routes.map((i) => i.name);
        }
        return item.name;
    })).find((name) => matchPath(name));
    const defaultOpenKey = authItems.find((item) => {
        if (item.routes) {
            return item.routes.some((i) => i.name === selectedKey);
        }
        return item.name === selectedKey;
    })?.name;
    return (
        <div className={clsx('amps-side-bar-layout', collapsed ? 'amps-side-bar-layout-menu-down' : 'amps-side-bar-layout-menu-up')}>
            <div className="side-bar-layout-menu-box">
                <Menu
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={[defaultOpenKey || 'businessDataOverview']}
                    onSelect={({ key }) => {
                        history.push(resolveUrl(key));
                    }}
                    className="menu"
                    inlineCollapsed={collapsed}
                    multiple={false}
                    mode="inline"
                >
                    {renderItem(authItems, 1)}
                </Menu>
                <AmpIcon
                    title={collapsed ? '展开' : '收起'}
                    onClick={() => setCollapsed(!collapsed)}
                    className="menu-icon-collapsed"
                    name={collapsed ? 'menuDown' : 'menuUp'}
                />
            </div>
            {outlet}
        </div>
    );
};
