import { Input } from "antd";
import { Component } from "react";

class CommentForm extends Component {
  state={
    comment: this.props.planDetails.comment,
  }
 render() {
    const {comment} = this.state
    return (
      <div className="mg-t-30">
        <h3 className="mg-b-16">备注</h3>
        <Input.TextArea
          placeholder="请输入拍摄计划备注内容"
          value={comment}
          autoSize={{ minRows: 4, maxRows: 5 }}
          onChange={(e) => this.setState({comment: e.target.value})}
          maxLength={500}
        />
      </div>
    );
  };
}
export default CommentForm;
