import React from 'react';
import icons from '../../assets/iconfont/index';

/**
 * svg icon组件（svg文件需要放到src/assets/iconfont/icons/目录下）
 * @param {*} props props
 * @param {string} props.name svg文件名
 * @returns {null|React.DetailedReactHTMLElement<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}
 * @constructor
 */
const Index = function (props: any) {
    const { name, ...other } = props;
    if (icons.has(name)) {
        const Icon = icons.get(name);
        return React.createElement(Icon, other);
    }
    return null;
};
export default Index;
