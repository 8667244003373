/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/20
 * @description: 通用表格组件
 */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSwr } from '@hooks';
import { Table as ATable, Tooltip } from 'antd';
import { unstable_serialize } from '@hooks/swr/useSwr';

let id = 0;
/**
 *
 * @param {object} props
 * @param {string} props.url swr 的key
 * @param {(page: number, pageSize: number, filter: any) => Promise<{list: T[], total: number}> } props.request swr 的fetcher
 * @param {(data: {list: T[], total: number}) => ReactNode} props.renderFilterForm 头部渲染筛选项目 可以监听表单提交事件
 * @param {any} props.paginationOptions 分页配置项目
 */
export const Table = forwardRef(function (props, ref) {
    const { url, request, renderFilterForm, paginationOptions, columns: _columns, params: _params, rowKey = 'id', ...other } = props;
    const [params, setParams] = useState({
        page: 1,
        pageSize: 20,
        _params: _params, // props.params
        filter: undefined, // 筛选
        sort: undefined // 排序
    });
    const key = [url, params];
    const { data, isValidating, mutate, error } = useSwr(key, (_, params) => {
        const { page, pageSize, _params, filter, sort } = params;
        return request(page, pageSize, Object.assign({}, _params, sort, filter));
    });
    function onSubmit(values) {
        if (params.page === 1) {
            if (unstable_serialize(values) === unstable_serialize(params.filter)) {
                mutate();
            } else {
                setParams((state) => ({
                    ...state,
                    filter: values
                }));
            }
        } else {
            if (unstable_serialize(values) === unstable_serialize(params.filter)) {
                setParams((state) => ({
                    ...state,
                    page: 1
                }));
            } else {
                setParams((state) => ({
                    ...state,
                    filter: values,
                    page: 1
                }));
            }
        }
    }

    const columns = _columns?.map((column) => {
        const { render, ellipsis, ...other } = column;
        return {
            ...other,
            ellipsis: ellipsis
                ? {
                      showTitle: false
                  }
                : ellipsis,
            render:
                render ||
                ((text) => {
                    if (text) {
                        return ellipsis ? (
                            <Tooltip placement="topLeft" title={text}>
                                {text}
                            </Tooltip>
                        ) : (
                            text
                        );
                    }
                    return '--';
                })
        };
    });
    useImperativeHandle(
        ref,
        () => ({
            refresh() {
                if (params.page === 1) {
                    mutate();
                } else {
                    setParams((state) => ({
                        ...state,
                        page: 1
                    }));
                }
            }
        }),
        [params.page]
    );
    useEffect(() => {
        setParams((state) => ({
            ...state,
            _params: _params,
            page: 1
        }));
    }, [unstable_serialize(_params)]);
    return (
        <>
            {renderFilterForm?.({
                isValidating,
                value: params._params,
                onChange: onSubmit
            })}
            <ATable
                {...other}
                columns={columns}
                dataSource={data?.list || []}
                rowKey={rowKey}
                loading={!data && isValidating}
                pagination={
                    data && {
                        ...paginationOptions,
                        hideOnSinglePage: true,
                        total: data.total,
                        pageSize: params.pageSize,
                        current: params.page,
                        showQuickJumper: true,
                        showTotal: (total) => `共 ${total} 条`,
                        showSizeChanger: false,
                        onChange(page, pageSize) {
                            setParams((state) => ({
                                ...state,
                                page: pageSize !== params.pageSize ? 1 : page,
                                pageSize: pageSize
                            }));
                        }
                    }
                }
            />
        </>
    );
});
