import { useEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';

/**
 * 防抖useState
 * @param value
 * @param delay
 * @returns {[*,*,((value: (((prevState: *) => *) | *)) => void)]}
 */
export default function useDebounceState(value, delay = 200) {
    const [state, setState] = useState(value);
    const [debounceState, setDebounceState] = useState(value);
    const fn = useDebounce((state) => {
        setDebounceState(state);
    }, delay, [state]);

    useEffect(() => {
        fn(state);
    }, [state]);
    return [state, debounceState, setState];
}
