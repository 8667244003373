import React from 'react'
import { Pie as APie } from '@ant-design/plots'
import { generate } from '@ant-design/colors'
import { withEmpty } from '@/HOC'

const Pie = function (props) {
    const { data, xField, yField, loading, legend = {}, tooltip = false } = props
    const config = {
        color: generate('#29C1B9'),
        loading: loading,
        data,
        angleField: yField,
        colorField: xField,
        radius: 0.65,
        tooltip: tooltip,
        appendPadding: [10, 0, 10, 0],
        label: {
            type: 'spider',
            labelHeight: 32,
            content: '{name}\n{percentage}',
        },
        legend: {
            maxHeight: 340,
            ...legend
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    return <APie {...config} />
}
export default withEmpty(Pie)
