import { useEffect, useState } from 'react';
import './index.less';
import { inject, observer } from 'mobx-react';
import { ProfileAvatar } from '@components';
import { Form, Select } from 'antd';

const { Option } = Select

/**
 * 选择策划组件组件
 * @param {*} props  状态
 * @returns
 */
const SelectPlanUser = (props) => {
    const { isShowEditCreate, planningPersonList } = props?.taskPackageStore
    const users = props.taskPackageStore?.currentTasks?.users
    const { form } = props;
    const [selectList, setSelectList] = useState([]);

    useEffect(() => {
        let selectListTempId = [];
        let selectListTemp = []
        if (isShowEditCreate) {
            for (let j = 0; j < users?.length; j++) {
                const temp = {
                    id: users[j].id,
                    name: users[j].name,
                    username: users[j].username,
                    size: 48,
                }
                selectListTemp.push(temp)
                selectListTempId.push(users[j].id);
            }
            setSelectList(selectListTemp);
            form.setFieldsValue({
                user_ids: selectListTempId,
            });
        }
    }, []);

    //  验证策划组员
    const validateExecutors = (_, value) => {
        if (!value?.length) return Promise.reject("请选择策划组员");
        if (value.length > 8) return Promise.reject("策划组员数量不能超过8个");
        return Promise.resolve();
    };

    //  选择人员
    const onSelectUser = (e) => {
        let selectListTemp = [];
        const { allUsers } = props.configsStore;
        const eLen = e.length;
        const allUsersLen = allUsers.length;
        if (eLen) {
            for (let i = 0; i < eLen; i++) {
                for (let j = 0; j < allUsersLen; j++) {
                    if (e[i] === allUsers[j].id) {
                        const temp = {
                            id: allUsers[j].id,
                            name: allUsers[j].name,
                            username: allUsers[j].username,
                            size: 48,
                        };
                        selectListTemp.push(temp);
                    }
                }
            }
        }
        setSelectList(selectListTemp);
    };

    //  展示用户头像
    const renderUserAvatar = () => {
        return (
            <div className="user-avatar flex">
                {selectList.map((item, index) => (
                    <div className="mg-r-12 mg-t-5" key={index}>
                        <ProfileAvatar {...item} />
                    </div>
                ))}

            </div>
        );
    };
    return (
        <div className="amps-select-plan-user ">
            <div className="flex-align-center">
                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        let value = getFieldValue("user_ids");
                        return (
                            <>
                                <Form.Item
                                    name="user_ids"
                                    label="策划组员"
                                    className="users-form"
                                    rules={[{ required: true, validator: validateExecutors }]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        maxTagCount={1}
                                        showSearch
                                        showArrow
                                        onChange={onSelectUser}
                                        size="large"
                                        style={{ width: 160, height: 36 }}
                                        placeholder="添加人员"
                                    >
                                        {
                                            planningPersonList.map((user) => (
                                                <Option key={user.id} value={user.user_id} label={user.name}>
                                                    {user.name}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                {value && value.length > 0 && renderUserAvatar(value)}
                            </>
                        );
                    }}
                </Form.Item>
            </div>
        </div>
    );
};

export default inject("configsStore")(observer(SelectPlanUser));
