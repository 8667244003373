// source: manager_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_amp_common_pb = require('./common/amp/common_pb.js');
goog.object.extend(proto, common_amp_common_pb);
goog.exportSymbol('proto.manager_api.AddTokenWhiteListRequest', null, global);
goog.exportSymbol('proto.manager_api.AddTokenWhiteListResponse', null, global);
goog.exportSymbol('proto.manager_api.CheckTokenInWhiteListRequest', null, global);
goog.exportSymbol('proto.manager_api.CheckTokenInWhiteListResponse', null, global);
goog.exportSymbol('proto.manager_api.CheckUserPermissionRequest', null, global);
goog.exportSymbol('proto.manager_api.CheckUserPermissionResponse', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerOperation', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerOperationRequest', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerOperationResponse', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepGroup', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepGroupResponse', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepMenu', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.CoreSchedulerStepMenuResponse', null, global);
goog.exportSymbol('proto.manager_api.EmptyResponse', null, global);
goog.exportSymbol('proto.manager_api.GetAssetApiRequest', null, global);
goog.exportSymbol('proto.manager_api.GetAssetApiResponse', null, global);
goog.exportSymbol('proto.manager_api.GetAssetProductionApiResponse', null, global);
goog.exportSymbol('proto.manager_api.GetCoreSchedulerOperationRequest', null, global);
goog.exportSymbol('proto.manager_api.GetCoreSchedulerStepGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.GetCoreSchedulerStepMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysApiRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysMenuApiRelationRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysTagRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysUserRequest', null, global);
goog.exportSymbol('proto.manager_api.GetSysUserSchedulerRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.GetTaskUserGroupMemberRequest', null, global);
goog.exportSymbol('proto.manager_api.GetTaskUserGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.GetTaskUserGroupTypeRequest', null, global);
goog.exportSymbol('proto.manager_api.IdRequest', null, global);
goog.exportSymbol('proto.manager_api.IdResponse', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerOperationRequest', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerOperationResponse', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerStepGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerStepGroupResponse', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerStepMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.ListCoreSchedulerStepMenuResponse', null, global);
goog.exportSymbol('proto.manager_api.ListPerformanceGoalsIntegrationResponse', null, global);
goog.exportSymbol('proto.manager_api.ListPerformanceGoalsRequest', null, global);
goog.exportSymbol('proto.manager_api.ListPerformanceGoalsResponse', null, global);
goog.exportSymbol('proto.manager_api.ListRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSchedulerStepRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSchedulerStepResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysApiRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysApiResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysMenuApiRelationRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysMenuApiRelationResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysMenuResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysNotificationFilter', null, global);
goog.exportSymbol('proto.manager_api.ListSysNotificationRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysNotificationResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysRoleResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysTagRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysTagResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysUserRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysUserResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysUserSchedulerRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysUserSchedulerRoleResponse', null, global);
goog.exportSymbol('proto.manager_api.ListSysWorkdayRequest', null, global);
goog.exportSymbol('proto.manager_api.ListSysWorkdayResponse', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupMemberRequest', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupMemberResponse', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupResponse', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupTypeRequest', null, global);
goog.exportSymbol('proto.manager_api.ListTaskUserGroupTypeResponse', null, global);
goog.exportSymbol('proto.manager_api.ListUserPermissionRequest', null, global);
goog.exportSymbol('proto.manager_api.ListUserPermissionResponse', null, global);
goog.exportSymbol('proto.manager_api.ModifyCoreSchedulerOperation', null, global);
goog.exportSymbol('proto.manager_api.ModifyCoreSchedulerStepGroup', null, global);
goog.exportSymbol('proto.manager_api.ModifyCoreSchedulerStepMenu', null, global);
goog.exportSymbol('proto.manager_api.ModifySysApi', null, global);
goog.exportSymbol('proto.manager_api.ModifySysMenu', null, global);
goog.exportSymbol('proto.manager_api.ModifySysMenuApiRelation', null, global);
goog.exportSymbol('proto.manager_api.ModifySysRole', null, global);
goog.exportSymbol('proto.manager_api.ModifySysTag', null, global);
goog.exportSymbol('proto.manager_api.ModifySysUser', null, global);
goog.exportSymbol('proto.manager_api.ModifySysUserSchedulerRole', null, global);
goog.exportSymbol('proto.manager_api.ModifyTaskUserGroup', null, global);
goog.exportSymbol('proto.manager_api.ModifyTaskUserGroupMember', null, global);
goog.exportSymbol('proto.manager_api.ModifyTaskUserGroupType', null, global);
goog.exportSymbol('proto.manager_api.PerformanceGoals', null, global);
goog.exportSymbol('proto.manager_api.PerformanceGoalsIntegration', null, global);
goog.exportSymbol('proto.manager_api.PerformanceGoalsResponse', null, global);
goog.exportSymbol('proto.manager_api.SavePerformanceGoalsRequest', null, global);
goog.exportSymbol('proto.manager_api.SaveSchedulerStepOperationRequest', null, global);
goog.exportSymbol('proto.manager_api.SchedulerStepInfo', null, global);
goog.exportSymbol('proto.manager_api.SchedulerStepRequest', null, global);
goog.exportSymbol('proto.manager_api.SchedulerStepResponse', null, global);
goog.exportSymbol('proto.manager_api.SysApi', null, global);
goog.exportSymbol('proto.manager_api.SysApiRequest', null, global);
goog.exportSymbol('proto.manager_api.SysApiResponse', null, global);
goog.exportSymbol('proto.manager_api.SysMenu', null, global);
goog.exportSymbol('proto.manager_api.SysMenuApiRelation', null, global);
goog.exportSymbol('proto.manager_api.SysMenuApiRelationRequest', null, global);
goog.exportSymbol('proto.manager_api.SysMenuApiRelationResponse', null, global);
goog.exportSymbol('proto.manager_api.SysMenuRequest', null, global);
goog.exportSymbol('proto.manager_api.SysMenuResponse', null, global);
goog.exportSymbol('proto.manager_api.SysNotificationRequest', null, global);
goog.exportSymbol('proto.manager_api.SysNotificationResponse', null, global);
goog.exportSymbol('proto.manager_api.SysRole', null, global);
goog.exportSymbol('proto.manager_api.SysRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.SysRoleResponse', null, global);
goog.exportSymbol('proto.manager_api.SysTag', null, global);
goog.exportSymbol('proto.manager_api.SysTagRequest', null, global);
goog.exportSymbol('proto.manager_api.SysTagResponse', null, global);
goog.exportSymbol('proto.manager_api.SysUser', null, global);
goog.exportSymbol('proto.manager_api.SysUserRequest', null, global);
goog.exportSymbol('proto.manager_api.SysUserResponse', null, global);
goog.exportSymbol('proto.manager_api.SysUserSchedulerRole', null, global);
goog.exportSymbol('proto.manager_api.SysUserSchedulerRoleRequest', null, global);
goog.exportSymbol('proto.manager_api.SysUserSchedulerRoleResponse', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroup', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupMember', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupMemberRequest', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupMemberResponse', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupRequest', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupResponse', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupType', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupTypeRequest', null, global);
goog.exportSymbol('proto.manager_api.TaskUserGroupTypeResponse', null, global);
goog.exportSymbol('proto.manager_api.TimeFilter', null, global);
goog.exportSymbol('proto.manager_api.TreeSchedulerStepOperationInfo', null, global);
goog.exportSymbol('proto.manager_api.TreeSchedulerStepOperationRequest', null, global);
goog.exportSymbol('proto.manager_api.TreeSchedulerStepOperationResponse', null, global);
goog.exportSymbol('proto.manager_api.UpdateSysWorkdayRequest', null, global);
goog.exportSymbol('proto.manager_api.WorkDayType', null, global);
goog.exportSymbol('proto.manager_api.Workday', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListRequest.displayName = 'proto.manager_api.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.EmptyResponse.displayName = 'proto.manager_api.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TimeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TimeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TimeFilter.displayName = 'proto.manager_api.TimeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.IdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.IdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.IdResponse.displayName = 'proto.manager_api.IdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.IdRequest.displayName = 'proto.manager_api.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.PerformanceGoals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.PerformanceGoals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.PerformanceGoals.displayName = 'proto.manager_api.PerformanceGoals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.PerformanceGoalsIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.PerformanceGoalsIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.PerformanceGoalsIntegration.displayName = 'proto.manager_api.PerformanceGoalsIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SavePerformanceGoalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SavePerformanceGoalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SavePerformanceGoalsRequest.displayName = 'proto.manager_api.SavePerformanceGoalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.PerformanceGoalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.PerformanceGoalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.PerformanceGoalsResponse.displayName = 'proto.manager_api.PerformanceGoalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListPerformanceGoalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListPerformanceGoalsResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListPerformanceGoalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListPerformanceGoalsResponse.displayName = 'proto.manager_api.ListPerformanceGoalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListPerformanceGoalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListPerformanceGoalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListPerformanceGoalsRequest.displayName = 'proto.manager_api.ListPerformanceGoalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListPerformanceGoalsIntegrationResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListPerformanceGoalsIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListPerformanceGoalsIntegrationResponse.displayName = 'proto.manager_api.ListPerformanceGoalsIntegrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CheckTokenInWhiteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CheckTokenInWhiteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CheckTokenInWhiteListRequest.displayName = 'proto.manager_api.CheckTokenInWhiteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CheckTokenInWhiteListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CheckTokenInWhiteListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CheckTokenInWhiteListResponse.displayName = 'proto.manager_api.CheckTokenInWhiteListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.AddTokenWhiteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.AddTokenWhiteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.AddTokenWhiteListRequest.displayName = 'proto.manager_api.AddTokenWhiteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.AddTokenWhiteListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.AddTokenWhiteListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.AddTokenWhiteListResponse.displayName = 'proto.manager_api.AddTokenWhiteListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CheckUserPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CheckUserPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CheckUserPermissionRequest.displayName = 'proto.manager_api.CheckUserPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CheckUserPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CheckUserPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CheckUserPermissionResponse.displayName = 'proto.manager_api.CheckUserPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.Workday = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.Workday, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.Workday.displayName = 'proto.manager_api.Workday';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.UpdateSysWorkdayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.UpdateSysWorkdayRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.UpdateSysWorkdayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.UpdateSysWorkdayRequest.displayName = 'proto.manager_api.UpdateSysWorkdayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysWorkdayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListSysWorkdayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysWorkdayRequest.displayName = 'proto.manager_api.ListSysWorkdayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysWorkdayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysWorkdayResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysWorkdayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysWorkdayResponse.displayName = 'proto.manager_api.ListSysWorkdayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListUserPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListUserPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListUserPermissionRequest.displayName = 'proto.manager_api.ListUserPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListUserPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListUserPermissionResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListUserPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListUserPermissionResponse.displayName = 'proto.manager_api.ListUserPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetAssetApiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetAssetApiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetAssetApiRequest.displayName = 'proto.manager_api.GetAssetApiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetAssetApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetAssetApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetAssetApiResponse.displayName = 'proto.manager_api.GetAssetApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetAssetProductionApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetAssetProductionApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetAssetProductionApiResponse.displayName = 'proto.manager_api.GetAssetProductionApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysNotificationResponse.displayName = 'proto.manager_api.SysNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysNotificationRequest.displayName = 'proto.manager_api.SysNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysNotificationFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListSysNotificationFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysNotificationFilter.displayName = 'proto.manager_api.ListSysNotificationFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysNotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListSysNotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysNotificationRequest.displayName = 'proto.manager_api.ListSysNotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysNotificationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysNotificationResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysNotificationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysNotificationResponse.displayName = 'proto.manager_api.ListSysNotificationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepGroup.displayName = 'proto.manager_api.CoreSchedulerStepGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyCoreSchedulerStepGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifyCoreSchedulerStepGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyCoreSchedulerStepGroup.displayName = 'proto.manager_api.ModifyCoreSchedulerStepGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepGroupRequest.displayName = 'proto.manager_api.CoreSchedulerStepGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepGroupResponse.displayName = 'proto.manager_api.CoreSchedulerStepGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerStepGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerStepGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerStepGroupResponse.displayName = 'proto.manager_api.ListCoreSchedulerStepGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetCoreSchedulerStepGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetCoreSchedulerStepGroupRequest.displayName = 'proto.manager_api.GetCoreSchedulerStepGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerStepGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerStepGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerStepGroupRequest.displayName = 'proto.manager_api.ListCoreSchedulerStepGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SchedulerStepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SchedulerStepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SchedulerStepInfo.displayName = 'proto.manager_api.SchedulerStepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SchedulerStepRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SchedulerStepRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SchedulerStepRequest.displayName = 'proto.manager_api.SchedulerStepRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SchedulerStepResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SchedulerStepResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SchedulerStepResponse.displayName = 'proto.manager_api.SchedulerStepResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSchedulerStepRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ListSchedulerStepRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSchedulerStepRequest.displayName = 'proto.manager_api.ListSchedulerStepRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSchedulerStepResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSchedulerStepResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSchedulerStepResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSchedulerStepResponse.displayName = 'proto.manager_api.ListSchedulerStepResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.CoreSchedulerOperation.repeatedFields_, null);
};
goog.inherits(proto.manager_api.CoreSchedulerOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerOperation.displayName = 'proto.manager_api.CoreSchedulerOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyCoreSchedulerOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ModifyCoreSchedulerOperation.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ModifyCoreSchedulerOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyCoreSchedulerOperation.displayName = 'proto.manager_api.ModifyCoreSchedulerOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerOperationRequest.displayName = 'proto.manager_api.CoreSchedulerOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerOperationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerOperationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerOperationResponse.displayName = 'proto.manager_api.CoreSchedulerOperationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerOperationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerOperationResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerOperationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerOperationResponse.displayName = 'proto.manager_api.ListCoreSchedulerOperationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetCoreSchedulerOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetCoreSchedulerOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetCoreSchedulerOperationRequest.displayName = 'proto.manager_api.GetCoreSchedulerOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerOperationRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerOperationRequest.displayName = 'proto.manager_api.ListCoreSchedulerOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SaveSchedulerStepOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.SaveSchedulerStepOperationRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.SaveSchedulerStepOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SaveSchedulerStepOperationRequest.displayName = 'proto.manager_api.SaveSchedulerStepOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TreeSchedulerStepOperationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.TreeSchedulerStepOperationInfo.repeatedFields_, null);
};
goog.inherits(proto.manager_api.TreeSchedulerStepOperationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TreeSchedulerStepOperationInfo.displayName = 'proto.manager_api.TreeSchedulerStepOperationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TreeSchedulerStepOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TreeSchedulerStepOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TreeSchedulerStepOperationRequest.displayName = 'proto.manager_api.TreeSchedulerStepOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TreeSchedulerStepOperationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.TreeSchedulerStepOperationResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.TreeSchedulerStepOperationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TreeSchedulerStepOperationResponse.displayName = 'proto.manager_api.TreeSchedulerStepOperationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepMenu.displayName = 'proto.manager_api.CoreSchedulerStepMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyCoreSchedulerStepMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifyCoreSchedulerStepMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyCoreSchedulerStepMenu.displayName = 'proto.manager_api.ModifyCoreSchedulerStepMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepMenuRequest.displayName = 'proto.manager_api.CoreSchedulerStepMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.CoreSchedulerStepMenuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.CoreSchedulerStepMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.CoreSchedulerStepMenuResponse.displayName = 'proto.manager_api.CoreSchedulerStepMenuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerStepMenuResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerStepMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerStepMenuResponse.displayName = 'proto.manager_api.ListCoreSchedulerStepMenuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetCoreSchedulerStepMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetCoreSchedulerStepMenuRequest.displayName = 'proto.manager_api.GetCoreSchedulerStepMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListCoreSchedulerStepMenuRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListCoreSchedulerStepMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListCoreSchedulerStepMenuRequest.displayName = 'proto.manager_api.ListCoreSchedulerStepMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysRole.displayName = 'proto.manager_api.SysRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysRole.displayName = 'proto.manager_api.ModifySysRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysRoleRequest.displayName = 'proto.manager_api.SysRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysRoleResponse.displayName = 'proto.manager_api.SysRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysRoleResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysRoleResponse.displayName = 'proto.manager_api.ListSysRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysRoleRequest.displayName = 'proto.manager_api.GetSysRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysRoleRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysRoleRequest.displayName = 'proto.manager_api.ListSysRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupType.displayName = 'proto.manager_api.TaskUserGroupType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyTaskUserGroupType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifyTaskUserGroupType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyTaskUserGroupType.displayName = 'proto.manager_api.ModifyTaskUserGroupType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupTypeRequest.displayName = 'proto.manager_api.TaskUserGroupTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupTypeResponse.displayName = 'proto.manager_api.TaskUserGroupTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupTypeResponse.displayName = 'proto.manager_api.ListTaskUserGroupTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetTaskUserGroupTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetTaskUserGroupTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetTaskUserGroupTypeRequest.displayName = 'proto.manager_api.GetTaskUserGroupTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupTypeRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupTypeRequest.displayName = 'proto.manager_api.ListTaskUserGroupTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroup.displayName = 'proto.manager_api.TaskUserGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyTaskUserGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifyTaskUserGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyTaskUserGroup.displayName = 'proto.manager_api.ModifyTaskUserGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupRequest.displayName = 'proto.manager_api.TaskUserGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupResponse.displayName = 'proto.manager_api.TaskUserGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupResponse.displayName = 'proto.manager_api.ListTaskUserGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetTaskUserGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetTaskUserGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetTaskUserGroupRequest.displayName = 'proto.manager_api.GetTaskUserGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupRequest.displayName = 'proto.manager_api.ListTaskUserGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupMember.displayName = 'proto.manager_api.TaskUserGroupMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifyTaskUserGroupMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifyTaskUserGroupMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifyTaskUserGroupMember.displayName = 'proto.manager_api.ModifyTaskUserGroupMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupMemberRequest.displayName = 'proto.manager_api.TaskUserGroupMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.TaskUserGroupMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.TaskUserGroupMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.TaskUserGroupMemberResponse.displayName = 'proto.manager_api.TaskUserGroupMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupMemberResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupMemberResponse.displayName = 'proto.manager_api.ListTaskUserGroupMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetTaskUserGroupMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetTaskUserGroupMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetTaskUserGroupMemberRequest.displayName = 'proto.manager_api.GetTaskUserGroupMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListTaskUserGroupMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListTaskUserGroupMemberRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListTaskUserGroupMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListTaskUserGroupMemberRequest.displayName = 'proto.manager_api.ListTaskUserGroupMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysApi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysApi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysApi.displayName = 'proto.manager_api.SysApi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysApi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysApi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysApi.displayName = 'proto.manager_api.ModifySysApi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysApiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysApiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysApiRequest.displayName = 'proto.manager_api.SysApiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysApiResponse.displayName = 'proto.manager_api.SysApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysApiResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysApiResponse.displayName = 'proto.manager_api.ListSysApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysApiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysApiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysApiRequest.displayName = 'proto.manager_api.GetSysApiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysApiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysApiRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysApiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysApiRequest.displayName = 'proto.manager_api.ListSysApiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenu.displayName = 'proto.manager_api.SysMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysMenu.displayName = 'proto.manager_api.ModifySysMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenuRequest.displayName = 'proto.manager_api.SysMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenuResponse.displayName = 'proto.manager_api.SysMenuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysMenuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysMenuResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysMenuResponse.displayName = 'proto.manager_api.ListSysMenuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysMenuRequest.displayName = 'proto.manager_api.GetSysMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysMenuRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysMenuRequest.displayName = 'proto.manager_api.ListSysMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenuApiRelation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenuApiRelation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenuApiRelation.displayName = 'proto.manager_api.SysMenuApiRelation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysMenuApiRelation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysMenuApiRelation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysMenuApiRelation.displayName = 'proto.manager_api.ModifySysMenuApiRelation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenuApiRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenuApiRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenuApiRelationRequest.displayName = 'proto.manager_api.SysMenuApiRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysMenuApiRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysMenuApiRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysMenuApiRelationResponse.displayName = 'proto.manager_api.SysMenuApiRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysMenuApiRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysMenuApiRelationResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysMenuApiRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysMenuApiRelationResponse.displayName = 'proto.manager_api.ListSysMenuApiRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysMenuApiRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysMenuApiRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysMenuApiRelationRequest.displayName = 'proto.manager_api.GetSysMenuApiRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysMenuApiRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysMenuApiRelationRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysMenuApiRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysMenuApiRelationRequest.displayName = 'proto.manager_api.ListSysMenuApiRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUserSchedulerRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUserSchedulerRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUserSchedulerRole.displayName = 'proto.manager_api.SysUserSchedulerRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysUserSchedulerRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysUserSchedulerRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysUserSchedulerRole.displayName = 'proto.manager_api.ModifySysUserSchedulerRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUserSchedulerRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUserSchedulerRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUserSchedulerRoleRequest.displayName = 'proto.manager_api.SysUserSchedulerRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUserSchedulerRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUserSchedulerRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUserSchedulerRoleResponse.displayName = 'proto.manager_api.SysUserSchedulerRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysUserSchedulerRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysUserSchedulerRoleResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysUserSchedulerRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysUserSchedulerRoleResponse.displayName = 'proto.manager_api.ListSysUserSchedulerRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysUserSchedulerRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysUserSchedulerRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysUserSchedulerRoleRequest.displayName = 'proto.manager_api.GetSysUserSchedulerRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysUserSchedulerRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysUserSchedulerRoleRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysUserSchedulerRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysUserSchedulerRoleRequest.displayName = 'proto.manager_api.ListSysUserSchedulerRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysTag.displayName = 'proto.manager_api.SysTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysTag.displayName = 'proto.manager_api.ModifySysTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysTagRequest.displayName = 'proto.manager_api.SysTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysTagResponse.displayName = 'proto.manager_api.SysTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysTagResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysTagResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysTagResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysTagResponse.displayName = 'proto.manager_api.ListSysTagResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysTagRequest.displayName = 'proto.manager_api.GetSysTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysTagRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysTagRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysTagRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysTagRequest.displayName = 'proto.manager_api.ListSysTagRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUser.displayName = 'proto.manager_api.SysUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ModifySysUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.ModifySysUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ModifySysUser.displayName = 'proto.manager_api.ModifySysUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUserRequest.displayName = 'proto.manager_api.SysUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.SysUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.SysUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.SysUserResponse.displayName = 'proto.manager_api.SysUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysUserResponse.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysUserResponse.displayName = 'proto.manager_api.ListSysUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.GetSysUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.manager_api.GetSysUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.GetSysUserRequest.displayName = 'proto.manager_api.GetSysUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.manager_api.ListSysUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.manager_api.ListSysUserRequest.repeatedFields_, null);
};
goog.inherits(proto.manager_api.ListSysUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.manager_api.ListSysUserRequest.displayName = 'proto.manager_api.ListSysUserRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListRequest}
 */
proto.manager_api.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListRequest;
  return proto.manager_api.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListRequest}
 */
proto.manager_api.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.manager_api.ListRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListRequest} returns this
 */
proto.manager_api.ListRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.manager_api.ListRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListRequest} returns this
 */
proto.manager_api.ListRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.EmptyResponse}
 */
proto.manager_api.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.EmptyResponse;
  return proto.manager_api.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.EmptyResponse}
 */
proto.manager_api.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TimeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TimeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TimeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TimeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TimeFilter}
 */
proto.manager_api.TimeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TimeFilter;
  return proto.manager_api.TimeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TimeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TimeFilter}
 */
proto.manager_api.TimeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TimeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TimeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TimeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TimeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.manager_api.TimeFilter.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.TimeFilter} returns this
 */
proto.manager_api.TimeFilter.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.manager_api.TimeFilter.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.TimeFilter} returns this
 */
proto.manager_api.TimeFilter.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.IdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.IdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.IdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.IdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.IdResponse}
 */
proto.manager_api.IdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.IdResponse;
  return proto.manager_api.IdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.IdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.IdResponse}
 */
proto.manager_api.IdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.IdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.IdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.IdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.IdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.IdResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.IdResponse} returns this
 */
proto.manager_api.IdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.IdRequest}
 */
proto.manager_api.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.IdRequest;
  return proto.manager_api.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.IdRequest}
 */
proto.manager_api.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.IdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.IdRequest} returns this
 */
proto.manager_api.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.PerformanceGoals.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.PerformanceGoals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.PerformanceGoals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoals.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goal: jspb.Message.getFieldWithDefault(msg, 5, 0),
    goalDateType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    goalType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    goalDateYear: jspb.Message.getFieldWithDefault(msg, 8, 0),
    goalDateMonth: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.PerformanceGoals}
 */
proto.manager_api.PerformanceGoals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.PerformanceGoals;
  return proto.manager_api.PerformanceGoals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.PerformanceGoals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.PerformanceGoals}
 */
proto.manager_api.PerformanceGoals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalDateType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalDateYear(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoalDateMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.PerformanceGoals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.PerformanceGoals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.PerformanceGoals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGoal();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGoalDateType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGoalType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getGoalDateYear();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGoalDateMonth();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 group_type = 2;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 group_id = 3;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 user_id = 4;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 goal = 5;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGoal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGoal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 goal_date_type = 6;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGoalDateType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGoalDateType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 goal_type = 7;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGoalType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGoalType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 goal_date_year = 8;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGoalDateYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGoalDateYear = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 goal_date_month = 9;
 * @return {number}
 */
proto.manager_api.PerformanceGoals.prototype.getGoalDateMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoals} returns this
 */
proto.manager_api.PerformanceGoals.prototype.setGoalDateMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.PerformanceGoalsIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.PerformanceGoalsIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoalsIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {
    jan: jspb.Message.getFieldWithDefault(msg, 1, 0),
    feb: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mar: jspb.Message.getFieldWithDefault(msg, 3, 0),
    apr: jspb.Message.getFieldWithDefault(msg, 4, 0),
    may: jspb.Message.getFieldWithDefault(msg, 5, 0),
    june: jspb.Message.getFieldWithDefault(msg, 6, 0),
    july: jspb.Message.getFieldWithDefault(msg, 7, 0),
    aug: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sept: jspb.Message.getFieldWithDefault(msg, 9, 0),
    oct: jspb.Message.getFieldWithDefault(msg, 10, 0),
    nov: jspb.Message.getFieldWithDefault(msg, 11, 0),
    dec: jspb.Message.getFieldWithDefault(msg, 12, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    name: jspb.Message.getFieldWithDefault(msg, 15, ""),
    year: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.PerformanceGoalsIntegration}
 */
proto.manager_api.PerformanceGoalsIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.PerformanceGoalsIntegration;
  return proto.manager_api.PerformanceGoalsIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.PerformanceGoalsIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.PerformanceGoalsIntegration}
 */
proto.manager_api.PerformanceGoalsIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJan(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFeb(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMar(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApr(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMay(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJune(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJuly(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAug(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSept(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOct(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNov(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDec(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.PerformanceGoalsIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.PerformanceGoalsIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoalsIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJan();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFeb();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMar();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getApr();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMay();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getJune();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getJuly();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getAug();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSept();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOct();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getNov();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDec();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional int32 jan = 1;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getJan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setJan = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 feb = 2;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getFeb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setFeb = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 mar = 3;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getMar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setMar = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 apr = 4;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getApr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setApr = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 may = 5;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getMay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setMay = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 june = 6;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getJune = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setJune = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 july = 7;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getJuly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setJuly = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 aug = 8;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getAug = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setAug = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 sept = 9;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getSept = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setSept = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 oct = 10;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getOct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setOct = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 nov = 11;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getNov = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setNov = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 dec = 12;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getDec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setDec = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 user_id = 13;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 group_id = 14;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string name = 15;
 * @return {string}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 year = 16;
 * @return {number}
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.PerformanceGoalsIntegration} returns this
 */
proto.manager_api.PerformanceGoalsIntegration.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SavePerformanceGoalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SavePerformanceGoalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SavePerformanceGoalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    goal: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    month: jspb.Message.getFieldWithDefault(msg, 5, 0),
    groupType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SavePerformanceGoalsRequest}
 */
proto.manager_api.SavePerformanceGoalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SavePerformanceGoalsRequest;
  return proto.manager_api.SavePerformanceGoalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SavePerformanceGoalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SavePerformanceGoalsRequest}
 */
proto.manager_api.SavePerformanceGoalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGoal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SavePerformanceGoalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SavePerformanceGoalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SavePerformanceGoalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 goal = 1;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getGoal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setGoal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 group_id = 3;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 user_id = 4;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 month = 5;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 group_type = 6;
 * @return {number}
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SavePerformanceGoalsRequest} returns this
 */
proto.manager_api.SavePerformanceGoalsRequest.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.PerformanceGoalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.PerformanceGoalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.PerformanceGoalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.PerformanceGoals.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.PerformanceGoalsResponse}
 */
proto.manager_api.PerformanceGoalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.PerformanceGoalsResponse;
  return proto.manager_api.PerformanceGoalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.PerformanceGoalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.PerformanceGoalsResponse}
 */
proto.manager_api.PerformanceGoalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.PerformanceGoals;
      reader.readMessage(value,proto.manager_api.PerformanceGoals.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.PerformanceGoalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.PerformanceGoalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.PerformanceGoalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.PerformanceGoalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.PerformanceGoals.serializeBinaryToWriter
    );
  }
};


/**
 * optional PerformanceGoals detail = 1;
 * @return {?proto.manager_api.PerformanceGoals}
 */
proto.manager_api.PerformanceGoalsResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.PerformanceGoals} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.PerformanceGoals, 1));
};


/**
 * @param {?proto.manager_api.PerformanceGoals|undefined} value
 * @return {!proto.manager_api.PerformanceGoalsResponse} returns this
*/
proto.manager_api.PerformanceGoalsResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.PerformanceGoalsResponse} returns this
 */
proto.manager_api.PerformanceGoalsResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.PerformanceGoalsResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListPerformanceGoalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListPerformanceGoalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListPerformanceGoalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.PerformanceGoals.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListPerformanceGoalsResponse}
 */
proto.manager_api.ListPerformanceGoalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListPerformanceGoalsResponse;
  return proto.manager_api.ListPerformanceGoalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListPerformanceGoalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListPerformanceGoalsResponse}
 */
proto.manager_api.ListPerformanceGoalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.PerformanceGoals;
      reader.readMessage(value,proto.manager_api.PerformanceGoals.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListPerformanceGoalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListPerformanceGoalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.PerformanceGoals.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PerformanceGoals list = 1;
 * @return {!Array<!proto.manager_api.PerformanceGoals>}
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.PerformanceGoals>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.PerformanceGoals, 1));
};


/**
 * @param {!Array<!proto.manager_api.PerformanceGoals>} value
 * @return {!proto.manager_api.ListPerformanceGoalsResponse} returns this
*/
proto.manager_api.ListPerformanceGoalsResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.PerformanceGoals=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.PerformanceGoals}
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.PerformanceGoals, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListPerformanceGoalsResponse} returns this
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListPerformanceGoalsResponse} returns this
 */
proto.manager_api.ListPerformanceGoalsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListPerformanceGoalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListPerformanceGoalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListPerformanceGoalsRequest}
 */
proto.manager_api.ListPerformanceGoalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListPerformanceGoalsRequest;
  return proto.manager_api.ListPerformanceGoalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListPerformanceGoalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListPerformanceGoalsRequest}
 */
proto.manager_api.ListPerformanceGoalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListPerformanceGoalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListPerformanceGoalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListPerformanceGoalsRequest} returns this
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 group_type = 2;
 * @return {number}
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListPerformanceGoalsRequest} returns this
 */
proto.manager_api.ListPerformanceGoalsRequest.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListPerformanceGoalsIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.PerformanceGoalsIntegration.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListPerformanceGoalsIntegrationResponse}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListPerformanceGoalsIntegrationResponse;
  return proto.manager_api.ListPerformanceGoalsIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListPerformanceGoalsIntegrationResponse}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.PerformanceGoalsIntegration;
      reader.readMessage(value,proto.manager_api.PerformanceGoalsIntegration.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListPerformanceGoalsIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.PerformanceGoalsIntegration.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PerformanceGoalsIntegration list = 1;
 * @return {!Array<!proto.manager_api.PerformanceGoalsIntegration>}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.PerformanceGoalsIntegration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.PerformanceGoalsIntegration, 1));
};


/**
 * @param {!Array<!proto.manager_api.PerformanceGoalsIntegration>} value
 * @return {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} returns this
*/
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.PerformanceGoalsIntegration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.PerformanceGoalsIntegration}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.PerformanceGoalsIntegration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} returns this
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListPerformanceGoalsIntegrationResponse} returns this
 */
proto.manager_api.ListPerformanceGoalsIntegrationResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CheckTokenInWhiteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CheckTokenInWhiteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CheckTokenInWhiteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckTokenInWhiteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CheckTokenInWhiteListRequest}
 */
proto.manager_api.CheckTokenInWhiteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CheckTokenInWhiteListRequest;
  return proto.manager_api.CheckTokenInWhiteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CheckTokenInWhiteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CheckTokenInWhiteListRequest}
 */
proto.manager_api.CheckTokenInWhiteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CheckTokenInWhiteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CheckTokenInWhiteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CheckTokenInWhiteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckTokenInWhiteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.manager_api.CheckTokenInWhiteListRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CheckTokenInWhiteListRequest} returns this
 */
proto.manager_api.CheckTokenInWhiteListRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CheckTokenInWhiteListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CheckTokenInWhiteListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CheckTokenInWhiteListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckTokenInWhiteListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    canLogin: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CheckTokenInWhiteListResponse}
 */
proto.manager_api.CheckTokenInWhiteListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CheckTokenInWhiteListResponse;
  return proto.manager_api.CheckTokenInWhiteListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CheckTokenInWhiteListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CheckTokenInWhiteListResponse}
 */
proto.manager_api.CheckTokenInWhiteListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanLogin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CheckTokenInWhiteListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CheckTokenInWhiteListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CheckTokenInWhiteListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckTokenInWhiteListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanLogin();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool can_login = 1;
 * @return {boolean}
 */
proto.manager_api.CheckTokenInWhiteListResponse.prototype.getCanLogin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.manager_api.CheckTokenInWhiteListResponse} returns this
 */
proto.manager_api.CheckTokenInWhiteListResponse.prototype.setCanLogin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.AddTokenWhiteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.AddTokenWhiteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.AddTokenWhiteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.AddTokenWhiteListRequest}
 */
proto.manager_api.AddTokenWhiteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.AddTokenWhiteListRequest;
  return proto.manager_api.AddTokenWhiteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.AddTokenWhiteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.AddTokenWhiteListRequest}
 */
proto.manager_api.AddTokenWhiteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.AddTokenWhiteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.AddTokenWhiteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.AddTokenWhiteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.AddTokenWhiteListRequest} returns this
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.AddTokenWhiteListRequest} returns this
 */
proto.manager_api.AddTokenWhiteListRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.AddTokenWhiteListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.AddTokenWhiteListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.AddTokenWhiteListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.AddTokenWhiteListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.AddTokenWhiteListResponse}
 */
proto.manager_api.AddTokenWhiteListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.AddTokenWhiteListResponse;
  return proto.manager_api.AddTokenWhiteListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.AddTokenWhiteListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.AddTokenWhiteListResponse}
 */
proto.manager_api.AddTokenWhiteListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.AddTokenWhiteListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.AddTokenWhiteListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.AddTokenWhiteListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.AddTokenWhiteListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CheckUserPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CheckUserPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CheckUserPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckUserPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apiMethod: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CheckUserPermissionRequest}
 */
proto.manager_api.CheckUserPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CheckUserPermissionRequest;
  return proto.manager_api.CheckUserPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CheckUserPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CheckUserPermissionRequest}
 */
proto.manager_api.CheckUserPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiMethod(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CheckUserPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CheckUserPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CheckUserPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckUserPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getApiMethod();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.manager_api.CheckUserPermissionRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CheckUserPermissionRequest} returns this
 */
proto.manager_api.CheckUserPermissionRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string api_method = 2;
 * @return {string}
 */
proto.manager_api.CheckUserPermissionRequest.prototype.getApiMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CheckUserPermissionRequest} returns this
 */
proto.manager_api.CheckUserPermissionRequest.prototype.setApiMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string api_url = 3;
 * @return {string}
 */
proto.manager_api.CheckUserPermissionRequest.prototype.getApiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CheckUserPermissionRequest} returns this
 */
proto.manager_api.CheckUserPermissionRequest.prototype.setApiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CheckUserPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CheckUserPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CheckUserPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckUserPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hadPermission: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CheckUserPermissionResponse}
 */
proto.manager_api.CheckUserPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CheckUserPermissionResponse;
  return proto.manager_api.CheckUserPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CheckUserPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CheckUserPermissionResponse}
 */
proto.manager_api.CheckUserPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHadPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CheckUserPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CheckUserPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CheckUserPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CheckUserPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHadPermission();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool had_permission = 1;
 * @return {boolean}
 */
proto.manager_api.CheckUserPermissionResponse.prototype.getHadPermission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.manager_api.CheckUserPermissionResponse} returns this
 */
proto.manager_api.CheckUserPermissionResponse.prototype.setHadPermission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.Workday.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.Workday.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.Workday} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.Workday.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workdayType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.Workday}
 */
proto.manager_api.Workday.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.Workday;
  return proto.manager_api.Workday.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.Workday} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.Workday}
 */
proto.manager_api.Workday.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = /** @type {!proto.manager_api.WorkDayType} */ (reader.readEnum());
      msg.setWorkdayType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.Workday.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.Workday.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.Workday} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.Workday.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWorkdayType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.manager_api.Workday.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.Workday} returns this
 */
proto.manager_api.Workday.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.manager_api.Workday.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.Workday} returns this
 */
proto.manager_api.Workday.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.manager_api.Workday.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.Workday} returns this
 */
proto.manager_api.Workday.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional WorkDayType workday_type = 4;
 * @return {!proto.manager_api.WorkDayType}
 */
proto.manager_api.Workday.prototype.getWorkdayType = function() {
  return /** @type {!proto.manager_api.WorkDayType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.manager_api.WorkDayType} value
 * @return {!proto.manager_api.Workday} returns this
 */
proto.manager_api.Workday.prototype.setWorkdayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 id = 5;
 * @return {number}
 */
proto.manager_api.Workday.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.Workday} returns this
 */
proto.manager_api.Workday.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.UpdateSysWorkdayRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.UpdateSysWorkdayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.UpdateSysWorkdayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.UpdateSysWorkdayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.UpdateSysWorkdayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.Workday.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.UpdateSysWorkdayRequest}
 */
proto.manager_api.UpdateSysWorkdayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.UpdateSysWorkdayRequest;
  return proto.manager_api.UpdateSysWorkdayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.UpdateSysWorkdayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.UpdateSysWorkdayRequest}
 */
proto.manager_api.UpdateSysWorkdayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.Workday;
      reader.readMessage(value,proto.manager_api.Workday.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.UpdateSysWorkdayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.UpdateSysWorkdayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.UpdateSysWorkdayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.UpdateSysWorkdayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.Workday.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Workday list = 1;
 * @return {!Array<!proto.manager_api.Workday>}
 */
proto.manager_api.UpdateSysWorkdayRequest.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.Workday>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.Workday, 1));
};


/**
 * @param {!Array<!proto.manager_api.Workday>} value
 * @return {!proto.manager_api.UpdateSysWorkdayRequest} returns this
*/
proto.manager_api.UpdateSysWorkdayRequest.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.Workday=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.Workday}
 */
proto.manager_api.UpdateSysWorkdayRequest.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.Workday, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.UpdateSysWorkdayRequest} returns this
 */
proto.manager_api.UpdateSysWorkdayRequest.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysWorkdayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysWorkdayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysWorkdayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysWorkdayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysWorkdayRequest}
 */
proto.manager_api.ListSysWorkdayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysWorkdayRequest;
  return proto.manager_api.ListSysWorkdayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysWorkdayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysWorkdayRequest}
 */
proto.manager_api.ListSysWorkdayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysWorkdayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysWorkdayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysWorkdayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysWorkdayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.manager_api.ListSysWorkdayRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysWorkdayRequest} returns this
 */
proto.manager_api.ListSysWorkdayRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.manager_api.ListSysWorkdayRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysWorkdayRequest} returns this
 */
proto.manager_api.ListSysWorkdayRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysWorkdayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysWorkdayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysWorkdayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysWorkdayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysWorkdayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.Workday.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysWorkdayResponse}
 */
proto.manager_api.ListSysWorkdayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysWorkdayResponse;
  return proto.manager_api.ListSysWorkdayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysWorkdayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysWorkdayResponse}
 */
proto.manager_api.ListSysWorkdayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.Workday;
      reader.readMessage(value,proto.manager_api.Workday.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysWorkdayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysWorkdayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysWorkdayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysWorkdayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.Workday.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Workday list = 1;
 * @return {!Array<!proto.manager_api.Workday>}
 */
proto.manager_api.ListSysWorkdayResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.Workday>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.Workday, 1));
};


/**
 * @param {!Array<!proto.manager_api.Workday>} value
 * @return {!proto.manager_api.ListSysWorkdayResponse} returns this
*/
proto.manager_api.ListSysWorkdayResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.Workday=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.Workday}
 */
proto.manager_api.ListSysWorkdayResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.Workday, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysWorkdayResponse} returns this
 */
proto.manager_api.ListSysWorkdayResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.manager_api.ListSysWorkdayResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysWorkdayResponse} returns this
 */
proto.manager_api.ListSysWorkdayResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListUserPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListUserPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListUserPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListUserPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operatorId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListUserPermissionRequest}
 */
proto.manager_api.ListUserPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListUserPermissionRequest;
  return proto.manager_api.ListUserPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListUserPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListUserPermissionRequest}
 */
proto.manager_api.ListUserPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListUserPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListUserPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListUserPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListUserPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperatorId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 operator_id = 1;
 * @return {number}
 */
proto.manager_api.ListUserPermissionRequest.prototype.getOperatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListUserPermissionRequest} returns this
 */
proto.manager_api.ListUserPermissionRequest.prototype.setOperatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListUserPermissionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListUserPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListUserPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListUserPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListUserPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListUserPermissionResponse}
 */
proto.manager_api.ListUserPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListUserPermissionResponse;
  return proto.manager_api.ListUserPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListUserPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListUserPermissionResponse}
 */
proto.manager_api.ListUserPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListUserPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListUserPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListUserPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListUserPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string list = 1;
 * @return {!Array<string>}
 */
proto.manager_api.ListUserPermissionResponse.prototype.getListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.manager_api.ListUserPermissionResponse} returns this
 */
proto.manager_api.ListUserPermissionResponse.prototype.setListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListUserPermissionResponse} returns this
 */
proto.manager_api.ListUserPermissionResponse.prototype.addList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListUserPermissionResponse} returns this
 */
proto.manager_api.ListUserPermissionResponse.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetAssetApiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetAssetApiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetAssetApiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetApiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetAssetApiRequest}
 */
proto.manager_api.GetAssetApiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetAssetApiRequest;
  return proto.manager_api.GetAssetApiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetAssetApiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetAssetApiRequest}
 */
proto.manager_api.GetAssetApiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetAssetApiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetAssetApiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetAssetApiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetApiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string third_party_id = 1;
 * @return {string}
 */
proto.manager_api.GetAssetApiRequest.prototype.getThirdPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetApiRequest} returns this
 */
proto.manager_api.GetAssetApiRequest.prototype.setThirdPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetAssetApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetAssetApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetAssetApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categories: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetAssetApiResponse}
 */
proto.manager_api.GetAssetApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetAssetApiResponse;
  return proto.manager_api.GetAssetApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetAssetApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetAssetApiResponse}
 */
proto.manager_api.GetAssetApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetAssetApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetAssetApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetAssetApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategories();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.manager_api.GetAssetApiResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetApiResponse} returns this
 */
proto.manager_api.GetAssetApiResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.GetAssetApiResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetApiResponse} returns this
 */
proto.manager_api.GetAssetApiResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location = 3;
 * @return {string}
 */
proto.manager_api.GetAssetApiResponse.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetApiResponse} returns this
 */
proto.manager_api.GetAssetApiResponse.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string categories = 4;
 * @return {string}
 */
proto.manager_api.GetAssetApiResponse.prototype.getCategories = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetApiResponse} returns this
 */
proto.manager_api.GetAssetApiResponse.prototype.setCategories = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetAssetProductionApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetAssetProductionApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetProductionApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shootPerson: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shootInfo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shootScore: jspb.Message.getFieldWithDefault(msg, 3, 0),
    parsPerson: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parsInfo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parsScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
    makePerson: jspb.Message.getFieldWithDefault(msg, 7, ""),
    makeInfo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    makeScore: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetAssetProductionApiResponse}
 */
proto.manager_api.GetAssetProductionApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetAssetProductionApiResponse;
  return proto.manager_api.GetAssetProductionApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetAssetProductionApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetAssetProductionApiResponse}
 */
proto.manager_api.GetAssetProductionApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShootPerson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShootInfo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShootScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setParsPerson(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParsInfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParsScore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMakePerson(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMakeInfo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMakeScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetAssetProductionApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetAssetProductionApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetAssetProductionApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShootPerson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShootInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShootScore();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getParsPerson();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParsInfo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParsScore();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMakePerson();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMakeInfo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMakeScore();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string shoot_person = 1;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getShootPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setShootPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shoot_info = 2;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getShootInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setShootInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 shoot_score = 3;
 * @return {number}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getShootScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setShootScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string pars_person = 4;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getParsPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setParsPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pars_info = 5;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getParsInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setParsInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 pars_score = 6;
 * @return {number}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getParsScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setParsScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string make_person = 7;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getMakePerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setMakePerson = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string make_info = 8;
 * @return {string}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getMakeInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setMakeInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 make_score = 9;
 * @return {number}
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.getMakeScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetAssetProductionApiResponse} returns this
 */
proto.manager_api.GetAssetProductionApiResponse.prototype.setMakeScore = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    author: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    publishedAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    content: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysNotificationResponse}
 */
proto.manager_api.SysNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysNotificationResponse;
  return proto.manager_api.SysNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysNotificationResponse}
 */
proto.manager_api.SysNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPublishedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysNotificationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.manager_api.SysNotificationResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string author = 3;
 * @return {string}
 */
proto.manager_api.SysNotificationResponse.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 status = 4;
 * @return {number}
 */
proto.manager_api.SysNotificationResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string published_at = 5;
 * @return {string}
 */
proto.manager_api.SysNotificationResponse.prototype.getPublishedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setPublishedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.manager_api.SysNotificationResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationResponse} returns this
 */
proto.manager_api.SysNotificationResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysNotificationRequest}
 */
proto.manager_api.SysNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysNotificationRequest;
  return proto.manager_api.SysNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysNotificationRequest}
 */
proto.manager_api.SysNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysNotificationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysNotificationRequest} returns this
 */
proto.manager_api.SysNotificationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.manager_api.SysNotificationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationRequest} returns this
 */
proto.manager_api.SysNotificationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.manager_api.SysNotificationRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysNotificationRequest} returns this
 */
proto.manager_api.SysNotificationRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysNotificationFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysNotificationFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysNotificationFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    titleAuthor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    publishedAt: (f = msg.getPublishedAt()) && proto.manager_api.TimeFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysNotificationFilter}
 */
proto.manager_api.ListSysNotificationFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysNotificationFilter;
  return proto.manager_api.ListSysNotificationFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysNotificationFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysNotificationFilter}
 */
proto.manager_api.ListSysNotificationFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleAuthor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.manager_api.TimeFilter;
      reader.readMessage(value,proto.manager_api.TimeFilter.deserializeBinaryFromReader);
      msg.setPublishedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysNotificationFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysNotificationFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysNotificationFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitleAuthor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPublishedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.manager_api.TimeFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title_author = 1;
 * @return {string}
 */
proto.manager_api.ListSysNotificationFilter.prototype.getTitleAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ListSysNotificationFilter} returns this
 */
proto.manager_api.ListSysNotificationFilter.prototype.setTitleAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 status = 2;
 * @return {number}
 */
proto.manager_api.ListSysNotificationFilter.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationFilter} returns this
 */
proto.manager_api.ListSysNotificationFilter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TimeFilter published_at = 3;
 * @return {?proto.manager_api.TimeFilter}
 */
proto.manager_api.ListSysNotificationFilter.prototype.getPublishedAt = function() {
  return /** @type{?proto.manager_api.TimeFilter} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.TimeFilter, 3));
};


/**
 * @param {?proto.manager_api.TimeFilter|undefined} value
 * @return {!proto.manager_api.ListSysNotificationFilter} returns this
*/
proto.manager_api.ListSysNotificationFilter.prototype.setPublishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.ListSysNotificationFilter} returns this
 */
proto.manager_api.ListSysNotificationFilter.prototype.clearPublishedAt = function() {
  return this.setPublishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.ListSysNotificationFilter.prototype.hasPublishedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysNotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysNotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysNotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filter: (f = msg.getFilter()) && proto.manager_api.ListSysNotificationFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysNotificationRequest}
 */
proto.manager_api.ListSysNotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysNotificationRequest;
  return proto.manager_api.ListSysNotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysNotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysNotificationRequest}
 */
proto.manager_api.ListSysNotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = new proto.manager_api.ListSysNotificationFilter;
      reader.readMessage(value,proto.manager_api.ListSysNotificationFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysNotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysNotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysNotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.manager_api.ListSysNotificationFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.manager_api.ListSysNotificationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationRequest} returns this
 */
proto.manager_api.ListSysNotificationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page_size = 2;
 * @return {number}
 */
proto.manager_api.ListSysNotificationRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationRequest} returns this
 */
proto.manager_api.ListSysNotificationRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ListSysNotificationFilter filter = 3;
 * @return {?proto.manager_api.ListSysNotificationFilter}
 */
proto.manager_api.ListSysNotificationRequest.prototype.getFilter = function() {
  return /** @type{?proto.manager_api.ListSysNotificationFilter} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ListSysNotificationFilter, 3));
};


/**
 * @param {?proto.manager_api.ListSysNotificationFilter|undefined} value
 * @return {!proto.manager_api.ListSysNotificationRequest} returns this
*/
proto.manager_api.ListSysNotificationRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.ListSysNotificationRequest} returns this
 */
proto.manager_api.ListSysNotificationRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.ListSysNotificationRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysNotificationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysNotificationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysNotificationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysNotificationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysNotificationResponse.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysNotificationResponse}
 */
proto.manager_api.ListSysNotificationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysNotificationResponse;
  return proto.manager_api.ListSysNotificationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysNotificationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysNotificationResponse}
 */
proto.manager_api.ListSysNotificationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysNotificationResponse;
      reader.readMessage(value,proto.manager_api.SysNotificationResponse.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysNotificationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysNotificationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysNotificationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysNotificationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysNotificationResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated SysNotificationResponse list = 1;
 * @return {!Array<!proto.manager_api.SysNotificationResponse>}
 */
proto.manager_api.ListSysNotificationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysNotificationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysNotificationResponse, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysNotificationResponse>} value
 * @return {!proto.manager_api.ListSysNotificationResponse} returns this
*/
proto.manager_api.ListSysNotificationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysNotificationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysNotificationResponse}
 */
proto.manager_api.ListSysNotificationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysNotificationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysNotificationResponse} returns this
 */
proto.manager_api.ListSysNotificationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysNotificationResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationResponse} returns this
 */
proto.manager_api.ListSysNotificationResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page = 3;
 * @return {number}
 */
proto.manager_api.ListSysNotificationResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationResponse} returns this
 */
proto.manager_api.ListSysNotificationResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 page_size = 4;
 * @return {number}
 */
proto.manager_api.ListSysNotificationResponse.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysNotificationResponse} returns this
 */
proto.manager_api.ListSysNotificationResponse.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stepMenuId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepGroup}
 */
proto.manager_api.CoreSchedulerStepGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepGroup;
  return proto.manager_api.CoreSchedulerStepGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepGroup}
 */
proto.manager_api.CoreSchedulerStepGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepMenuId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStepMenuId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 step_menu_id = 7;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getStepMenuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setStepMenuId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 created_at = 8;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 updated_at = 9;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepGroup} returns this
 */
proto.manager_api.CoreSchedulerStepGroup.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyCoreSchedulerStepGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyCoreSchedulerStepGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stepMenuId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyCoreSchedulerStepGroup;
  return proto.manager_api.ModifyCoreSchedulerStepGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyCoreSchedulerStepGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepMenuId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyCoreSchedulerStepGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyCoreSchedulerStepGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStepMenuId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 step_menu_id = 7;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getStepMenuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setStepMenuId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 created_at = 8;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 updated_at = 9;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepGroup} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepGroup.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyCoreSchedulerStepGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepGroupRequest}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepGroupRequest;
  return proto.manager_api.CoreSchedulerStepGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepGroupRequest}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyCoreSchedulerStepGroup;
      reader.readMessage(value,proto.manager_api.ModifyCoreSchedulerStepGroup.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyCoreSchedulerStepGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyCoreSchedulerStepGroup detail = 1;
 * @return {?proto.manager_api.ModifyCoreSchedulerStepGroup}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyCoreSchedulerStepGroup} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyCoreSchedulerStepGroup, 1));
};


/**
 * @param {?proto.manager_api.ModifyCoreSchedulerStepGroup|undefined} value
 * @return {!proto.manager_api.CoreSchedulerStepGroupRequest} returns this
*/
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerStepGroupRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.CoreSchedulerStepGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepGroupResponse}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepGroupResponse;
  return proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepGroupResponse}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerStepGroup;
      reader.readMessage(value,proto.manager_api.CoreSchedulerStepGroup.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerStepGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoreSchedulerStepGroup detail = 1;
 * @return {?proto.manager_api.CoreSchedulerStepGroup}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.CoreSchedulerStepGroup} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.CoreSchedulerStepGroup, 1));
};


/**
 * @param {?proto.manager_api.CoreSchedulerStepGroup|undefined} value
 * @return {!proto.manager_api.CoreSchedulerStepGroupResponse} returns this
*/
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerStepGroupResponse} returns this
 */
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerStepGroupResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerStepGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.CoreSchedulerStepGroup.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupResponse}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerStepGroupResponse;
  return proto.manager_api.ListCoreSchedulerStepGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupResponse}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerStepGroup;
      reader.readMessage(value,proto.manager_api.CoreSchedulerStepGroup.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerStepGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerStepGroup.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated CoreSchedulerStepGroup list = 1;
 * @return {!Array<!proto.manager_api.CoreSchedulerStepGroup>}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.CoreSchedulerStepGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.CoreSchedulerStepGroup, 1));
};


/**
 * @param {!Array<!proto.manager_api.CoreSchedulerStepGroup>} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupResponse} returns this
*/
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerStepGroup}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.CoreSchedulerStepGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupResponse} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupResponse} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetCoreSchedulerStepGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetCoreSchedulerStepGroupRequest}
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetCoreSchedulerStepGroupRequest;
  return proto.manager_api.GetCoreSchedulerStepGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetCoreSchedulerStepGroupRequest}
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetCoreSchedulerStepGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.GetCoreSchedulerStepGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerStepGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    typesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerStepGroupRequest;
  return proto.manager_api.ListCoreSchedulerStepGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.common.CoreBusinessType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerStepGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated common.CoreBusinessType types = 4;
 * @return {!Array<!proto.common.CoreBusinessType>}
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.common.CoreBusinessType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.common.CoreBusinessType>} value
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerStepGroupRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepGroupRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SchedulerStepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SchedulerStepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SchedulerStepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    belongProcedure: jspb.Message.getFieldWithDefault(msg, 3, 0),
    code: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isEnabled: jspb.Message.getFieldWithDefault(msg, 9, 0),
    stepGroupId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.SchedulerStepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SchedulerStepInfo;
  return proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SchedulerStepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.SchedulerBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBelongProcedure(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 9:
      var value = /** @type {!proto.common.EnabledStatus} */ (reader.readEnum());
      msg.setIsEnabled(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepGroupId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SchedulerStepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SchedulerStepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBelongProcedure();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIsEnabled();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStepGroupId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.SchedulerBusinessType belong_business = 2;
 * @return {!proto.common.SchedulerBusinessType}
 */
proto.manager_api.SchedulerStepInfo.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.SchedulerBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.SchedulerBusinessType} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 belong_procedure = 3;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getBelongProcedure = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setBelongProcedure = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 code = 4;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.manager_api.SchedulerStepInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.manager_api.SchedulerStepInfo.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.manager_api.SchedulerStepInfo.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 sort = 8;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional common.EnabledStatus is_enabled = 9;
 * @return {!proto.common.EnabledStatus}
 */
proto.manager_api.SchedulerStepInfo.prototype.getIsEnabled = function() {
  return /** @type {!proto.common.EnabledStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.EnabledStatus} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 step_group_id = 10;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getStepGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setStepGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 created_at = 11;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 updated_at = 12;
 * @return {number}
 */
proto.manager_api.SchedulerStepInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SchedulerStepInfo} returns this
 */
proto.manager_api.SchedulerStepInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SchedulerStepRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SchedulerStepRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SchedulerStepRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SchedulerStepInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SchedulerStepRequest}
 */
proto.manager_api.SchedulerStepRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SchedulerStepRequest;
  return proto.manager_api.SchedulerStepRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SchedulerStepRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SchedulerStepRequest}
 */
proto.manager_api.SchedulerStepRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SchedulerStepInfo;
      reader.readMessage(value,proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SchedulerStepRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SchedulerStepRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SchedulerStepRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SchedulerStepInfo detail = 1;
 * @return {?proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.SchedulerStepRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SchedulerStepInfo} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SchedulerStepInfo, 1));
};


/**
 * @param {?proto.manager_api.SchedulerStepInfo|undefined} value
 * @return {!proto.manager_api.SchedulerStepRequest} returns this
*/
proto.manager_api.SchedulerStepRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SchedulerStepRequest} returns this
 */
proto.manager_api.SchedulerStepRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SchedulerStepRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SchedulerStepResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SchedulerStepResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SchedulerStepResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SchedulerStepInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SchedulerStepResponse}
 */
proto.manager_api.SchedulerStepResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SchedulerStepResponse;
  return proto.manager_api.SchedulerStepResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SchedulerStepResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SchedulerStepResponse}
 */
proto.manager_api.SchedulerStepResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SchedulerStepInfo;
      reader.readMessage(value,proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SchedulerStepResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SchedulerStepResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SchedulerStepResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SchedulerStepResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SchedulerStepInfo detail = 1;
 * @return {?proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.SchedulerStepResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SchedulerStepInfo} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SchedulerStepInfo, 1));
};


/**
 * @param {?proto.manager_api.SchedulerStepInfo|undefined} value
 * @return {!proto.manager_api.SchedulerStepResponse} returns this
*/
proto.manager_api.SchedulerStepResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SchedulerStepResponse} returns this
 */
proto.manager_api.SchedulerStepResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SchedulerStepResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSchedulerStepRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSchedulerStepRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSchedulerStepRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSchedulerStepRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSchedulerStepRequest}
 */
proto.manager_api.ListSchedulerStepRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSchedulerStepRequest;
  return proto.manager_api.ListSchedulerStepRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSchedulerStepRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSchedulerStepRequest}
 */
proto.manager_api.ListSchedulerStepRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.SchedulerBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSchedulerStepRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSchedulerStepRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSchedulerStepRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSchedulerStepRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional common.SchedulerBusinessType belong_business = 1;
 * @return {!proto.common.SchedulerBusinessType}
 */
proto.manager_api.ListSchedulerStepRequest.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.SchedulerBusinessType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.SchedulerBusinessType} value
 * @return {!proto.manager_api.ListSchedulerStepRequest} returns this
 */
proto.manager_api.ListSchedulerStepRequest.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSchedulerStepResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSchedulerStepResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSchedulerStepResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSchedulerStepResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSchedulerStepResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SchedulerStepInfo.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSchedulerStepResponse}
 */
proto.manager_api.ListSchedulerStepResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSchedulerStepResponse;
  return proto.manager_api.ListSchedulerStepResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSchedulerStepResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSchedulerStepResponse}
 */
proto.manager_api.ListSchedulerStepResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SchedulerStepInfo;
      reader.readMessage(value,proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSchedulerStepResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSchedulerStepResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSchedulerStepResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSchedulerStepResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SchedulerStepInfo list = 1;
 * @return {!Array<!proto.manager_api.SchedulerStepInfo>}
 */
proto.manager_api.ListSchedulerStepResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SchedulerStepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SchedulerStepInfo, 1));
};


/**
 * @param {!Array<!proto.manager_api.SchedulerStepInfo>} value
 * @return {!proto.manager_api.ListSchedulerStepResponse} returns this
*/
proto.manager_api.ListSchedulerStepResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SchedulerStepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.ListSchedulerStepResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SchedulerStepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSchedulerStepResponse} returns this
 */
proto.manager_api.ListSchedulerStepResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.manager_api.ListSchedulerStepResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSchedulerStepResponse} returns this
 */
proto.manager_api.ListSchedulerStepResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.CoreSchedulerOperation.repeatedFields_ = [6,8,10,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    preRequisite: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bindFunctionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sort: jspb.Message.getFieldWithDefault(msg, 7, 0),
    bindRolesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    taskRole: jspb.Message.getFieldWithDefault(msg, 9, 0),
    notifyMessageTemplateList: jspb.Message.toObjectList(msg.getNotifyMessageTemplateList(),
    common_amp_common_pb.NotifyMessageTemplate.toObject, includeInstance),
    isEnabled: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nextStepCode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    prevStepType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    nextStepType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    operationType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hitCaseList: jspb.Message.toObjectList(msg.getHitCaseList(),
    common_amp_common_pb.SchedulerHitCase.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 18, 0),
    buttonName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    buttonColorType: jspb.Message.getFieldWithDefault(msg, 20, 0),
    color: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerOperation}
 */
proto.manager_api.CoreSchedulerOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerOperation;
  return proto.manager_api.CoreSchedulerOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerOperation}
 */
proto.manager_api.CoreSchedulerOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {!proto.common.PreRequisiteType} */ (reader.readEnum());
      msg.setPreRequisite(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addBindFunctions(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBindRoles(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskRole(value);
      break;
    case 10:
      var value = new common_amp_common_pb.NotifyMessageTemplate;
      reader.readMessage(value,common_amp_common_pb.NotifyMessageTemplate.deserializeBinaryFromReader);
      msg.addNotifyMessageTemplate(value);
      break;
    case 11:
      var value = /** @type {!proto.common.EnabledStatus} */ (reader.readEnum());
      msg.setIsEnabled(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextStepCode(value);
      break;
    case 13:
      var value = /** @type {!proto.common.SchedulerOperationPrevStepType} */ (reader.readEnum());
      msg.setPrevStepType(value);
      break;
    case 14:
      var value = /** @type {!proto.common.SchedulerOperationNextStepType} */ (reader.readEnum());
      msg.setNextStepType(value);
      break;
    case 15:
      var value = /** @type {!proto.common.SchedulerOperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 16:
      var value = new common_amp_common_pb.SchedulerHitCase;
      reader.readMessage(value,common_amp_common_pb.SchedulerHitCase.deserializeBinaryFromReader);
      msg.addHitCase(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonName(value);
      break;
    case 20:
      var value = /** @type {!proto.common.SchedulerButtonColorType} */ (reader.readEnum());
      msg.setButtonColorType(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPreRequisite();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBindFunctionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBindRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getTaskRole();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNotifyMessageTemplateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_amp_common_pb.NotifyMessageTemplate.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getNextStepCode();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPrevStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getNextStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getHitCaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      common_amp_common_pb.SchedulerHitCase.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getButtonName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getButtonColorType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation = 3;
 * @return {string}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.PreRequisiteType pre_requisite = 5;
 * @return {!proto.common.PreRequisiteType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getPreRequisite = function() {
  return /** @type {!proto.common.PreRequisiteType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.PreRequisiteType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setPreRequisite = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string bind_functions = 6;
 * @return {!Array<string>}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getBindFunctionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setBindFunctionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.addBindFunctions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.clearBindFunctionsList = function() {
  return this.setBindFunctionsList([]);
};


/**
 * optional int32 sort = 7;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int64 bind_roles = 8;
 * @return {!Array<number>}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getBindRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setBindRolesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.addBindRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.clearBindRolesList = function() {
  return this.setBindRolesList([]);
};


/**
 * optional int64 task_role = 9;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getTaskRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setTaskRole = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated common.NotifyMessageTemplate notify_message_template = 10;
 * @return {!Array<!proto.common.NotifyMessageTemplate>}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getNotifyMessageTemplateList = function() {
  return /** @type{!Array<!proto.common.NotifyMessageTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.NotifyMessageTemplate, 10));
};


/**
 * @param {!Array<!proto.common.NotifyMessageTemplate>} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
*/
proto.manager_api.CoreSchedulerOperation.prototype.setNotifyMessageTemplateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.NotifyMessageTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.NotifyMessageTemplate}
 */
proto.manager_api.CoreSchedulerOperation.prototype.addNotifyMessageTemplate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.NotifyMessageTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.clearNotifyMessageTemplateList = function() {
  return this.setNotifyMessageTemplateList([]);
};


/**
 * optional common.EnabledStatus is_enabled = 11;
 * @return {!proto.common.EnabledStatus}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getIsEnabled = function() {
  return /** @type {!proto.common.EnabledStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.EnabledStatus} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int64 next_step_code = 12;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getNextStepCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setNextStepCode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional common.SchedulerOperationPrevStepType prev_step_type = 13;
 * @return {!proto.common.SchedulerOperationPrevStepType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getPrevStepType = function() {
  return /** @type {!proto.common.SchedulerOperationPrevStepType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.SchedulerOperationPrevStepType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setPrevStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.SchedulerOperationNextStepType next_step_type = 14;
 * @return {!proto.common.SchedulerOperationNextStepType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getNextStepType = function() {
  return /** @type {!proto.common.SchedulerOperationNextStepType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.common.SchedulerOperationNextStepType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setNextStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional common.SchedulerOperationType operation_type = 15;
 * @return {!proto.common.SchedulerOperationType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getOperationType = function() {
  return /** @type {!proto.common.SchedulerOperationType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.common.SchedulerOperationType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated common.SchedulerHitCase hit_case = 16;
 * @return {!Array<!proto.common.SchedulerHitCase>}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getHitCaseList = function() {
  return /** @type{!Array<!proto.common.SchedulerHitCase>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.SchedulerHitCase, 16));
};


/**
 * @param {!Array<!proto.common.SchedulerHitCase>} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
*/
proto.manager_api.CoreSchedulerOperation.prototype.setHitCaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.SchedulerHitCase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SchedulerHitCase}
 */
proto.manager_api.CoreSchedulerOperation.prototype.addHitCase = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.SchedulerHitCase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.clearHitCaseList = function() {
  return this.setHitCaseList([]);
};


/**
 * optional int64 created_at = 17;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 updated_at = 18;
 * @return {number}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string button_name = 19;
 * @return {string}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getButtonName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setButtonName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional common.SchedulerButtonColorType button_color_type = 20;
 * @return {!proto.common.SchedulerButtonColorType}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getButtonColorType = function() {
  return /** @type {!proto.common.SchedulerButtonColorType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.common.SchedulerButtonColorType} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setButtonColorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string color = 21;
 * @return {string}
 */
proto.manager_api.CoreSchedulerOperation.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerOperation} returns this
 */
proto.manager_api.CoreSchedulerOperation.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ModifyCoreSchedulerOperation.repeatedFields_ = [6,8,10,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyCoreSchedulerOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyCoreSchedulerOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    preRequisite: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bindFunctionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sort: jspb.Message.getFieldWithDefault(msg, 7, 0),
    bindRolesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    taskRole: jspb.Message.getFieldWithDefault(msg, 9, 0),
    notifyMessageTemplateList: jspb.Message.toObjectList(msg.getNotifyMessageTemplateList(),
    common_amp_common_pb.NotifyMessageTemplate.toObject, includeInstance),
    isEnabled: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nextStepCode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    prevStepType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    nextStepType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    operationType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    hitCaseList: jspb.Message.toObjectList(msg.getHitCaseList(),
    common_amp_common_pb.SchedulerHitCase.toObject, includeInstance),
    buttonName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    buttonColorType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    color: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation}
 */
proto.manager_api.ModifyCoreSchedulerOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyCoreSchedulerOperation;
  return proto.manager_api.ModifyCoreSchedulerOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyCoreSchedulerOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation}
 */
proto.manager_api.ModifyCoreSchedulerOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {!proto.common.PreRequisiteType} */ (reader.readEnum());
      msg.setPreRequisite(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addBindFunctions(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBindRoles(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskRole(value);
      break;
    case 10:
      var value = new common_amp_common_pb.NotifyMessageTemplate;
      reader.readMessage(value,common_amp_common_pb.NotifyMessageTemplate.deserializeBinaryFromReader);
      msg.addNotifyMessageTemplate(value);
      break;
    case 11:
      var value = /** @type {!proto.common.EnabledStatus} */ (reader.readEnum());
      msg.setIsEnabled(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextStepCode(value);
      break;
    case 13:
      var value = /** @type {!proto.common.SchedulerOperationPrevStepType} */ (reader.readEnum());
      msg.setPrevStepType(value);
      break;
    case 14:
      var value = /** @type {!proto.common.SchedulerOperationNextStepType} */ (reader.readEnum());
      msg.setNextStepType(value);
      break;
    case 15:
      var value = /** @type {!proto.common.SchedulerOperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 16:
      var value = new common_amp_common_pb.SchedulerHitCase;
      reader.readMessage(value,common_amp_common_pb.SchedulerHitCase.deserializeBinaryFromReader);
      msg.addHitCase(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonName(value);
      break;
    case 18:
      var value = /** @type {!proto.common.SchedulerButtonColorType} */ (reader.readEnum());
      msg.setButtonColorType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyCoreSchedulerOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyCoreSchedulerOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPreRequisite();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBindFunctionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBindRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getTaskRole();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNotifyMessageTemplateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      common_amp_common_pb.NotifyMessageTemplate.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getNextStepCode();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPrevStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getNextStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getHitCaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      common_amp_common_pb.SchedulerHitCase.serializeBinaryToWriter
    );
  }
  f = message.getButtonName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getButtonColorType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string operation = 3;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.PreRequisiteType pre_requisite = 5;
 * @return {!proto.common.PreRequisiteType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getPreRequisite = function() {
  return /** @type {!proto.common.PreRequisiteType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.PreRequisiteType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setPreRequisite = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string bind_functions = 6;
 * @return {!Array<string>}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getBindFunctionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setBindFunctionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.addBindFunctions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.clearBindFunctionsList = function() {
  return this.setBindFunctionsList([]);
};


/**
 * optional int32 sort = 7;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int64 bind_roles = 8;
 * @return {!Array<number>}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getBindRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setBindRolesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.addBindRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.clearBindRolesList = function() {
  return this.setBindRolesList([]);
};


/**
 * optional int64 task_role = 9;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getTaskRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setTaskRole = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated common.NotifyMessageTemplate notify_message_template = 10;
 * @return {!Array<!proto.common.NotifyMessageTemplate>}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getNotifyMessageTemplateList = function() {
  return /** @type{!Array<!proto.common.NotifyMessageTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.NotifyMessageTemplate, 10));
};


/**
 * @param {!Array<!proto.common.NotifyMessageTemplate>} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
*/
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setNotifyMessageTemplateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.NotifyMessageTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.NotifyMessageTemplate}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.addNotifyMessageTemplate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.NotifyMessageTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.clearNotifyMessageTemplateList = function() {
  return this.setNotifyMessageTemplateList([]);
};


/**
 * optional common.EnabledStatus is_enabled = 11;
 * @return {!proto.common.EnabledStatus}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getIsEnabled = function() {
  return /** @type {!proto.common.EnabledStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.EnabledStatus} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int64 next_step_code = 12;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getNextStepCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setNextStepCode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional common.SchedulerOperationPrevStepType prev_step_type = 13;
 * @return {!proto.common.SchedulerOperationPrevStepType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getPrevStepType = function() {
  return /** @type {!proto.common.SchedulerOperationPrevStepType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.SchedulerOperationPrevStepType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setPrevStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.SchedulerOperationNextStepType next_step_type = 14;
 * @return {!proto.common.SchedulerOperationNextStepType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getNextStepType = function() {
  return /** @type {!proto.common.SchedulerOperationNextStepType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.common.SchedulerOperationNextStepType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setNextStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional common.SchedulerOperationType operation_type = 15;
 * @return {!proto.common.SchedulerOperationType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getOperationType = function() {
  return /** @type {!proto.common.SchedulerOperationType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.common.SchedulerOperationType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated common.SchedulerHitCase hit_case = 16;
 * @return {!Array<!proto.common.SchedulerHitCase>}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getHitCaseList = function() {
  return /** @type{!Array<!proto.common.SchedulerHitCase>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_amp_common_pb.SchedulerHitCase, 16));
};


/**
 * @param {!Array<!proto.common.SchedulerHitCase>} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
*/
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setHitCaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.SchedulerHitCase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SchedulerHitCase}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.addHitCase = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.SchedulerHitCase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.clearHitCaseList = function() {
  return this.setHitCaseList([]);
};


/**
 * optional string button_name = 17;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getButtonName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setButtonName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional common.SchedulerButtonColorType button_color_type = 18;
 * @return {!proto.common.SchedulerButtonColorType}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getButtonColorType = function() {
  return /** @type {!proto.common.SchedulerButtonColorType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.common.SchedulerButtonColorType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setButtonColorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string color = 19;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerOperation} returns this
 */
proto.manager_api.ModifyCoreSchedulerOperation.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyCoreSchedulerOperation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerOperationRequest}
 */
proto.manager_api.CoreSchedulerOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerOperationRequest;
  return proto.manager_api.CoreSchedulerOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerOperationRequest}
 */
proto.manager_api.CoreSchedulerOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyCoreSchedulerOperation;
      reader.readMessage(value,proto.manager_api.ModifyCoreSchedulerOperation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyCoreSchedulerOperation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyCoreSchedulerOperation detail = 1;
 * @return {?proto.manager_api.ModifyCoreSchedulerOperation}
 */
proto.manager_api.CoreSchedulerOperationRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyCoreSchedulerOperation} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyCoreSchedulerOperation, 1));
};


/**
 * @param {?proto.manager_api.ModifyCoreSchedulerOperation|undefined} value
 * @return {!proto.manager_api.CoreSchedulerOperationRequest} returns this
*/
proto.manager_api.CoreSchedulerOperationRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerOperationRequest} returns this
 */
proto.manager_api.CoreSchedulerOperationRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerOperationRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerOperationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerOperationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerOperationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.CoreSchedulerOperation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerOperationResponse}
 */
proto.manager_api.CoreSchedulerOperationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerOperationResponse;
  return proto.manager_api.CoreSchedulerOperationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerOperationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerOperationResponse}
 */
proto.manager_api.CoreSchedulerOperationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerOperation;
      reader.readMessage(value,proto.manager_api.CoreSchedulerOperation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerOperationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerOperationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerOperationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerOperationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerOperation.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoreSchedulerOperation detail = 1;
 * @return {?proto.manager_api.CoreSchedulerOperation}
 */
proto.manager_api.CoreSchedulerOperationResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.CoreSchedulerOperation} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.CoreSchedulerOperation, 1));
};


/**
 * @param {?proto.manager_api.CoreSchedulerOperation|undefined} value
 * @return {!proto.manager_api.CoreSchedulerOperationResponse} returns this
*/
proto.manager_api.CoreSchedulerOperationResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerOperationResponse} returns this
 */
proto.manager_api.CoreSchedulerOperationResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerOperationResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerOperationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerOperationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerOperationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerOperationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.CoreSchedulerOperation.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerOperationResponse}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerOperationResponse;
  return proto.manager_api.ListCoreSchedulerOperationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerOperationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerOperationResponse}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerOperation;
      reader.readMessage(value,proto.manager_api.CoreSchedulerOperation.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerOperationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerOperationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerOperationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerOperation.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated CoreSchedulerOperation list = 1;
 * @return {!Array<!proto.manager_api.CoreSchedulerOperation>}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.CoreSchedulerOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.CoreSchedulerOperation, 1));
};


/**
 * @param {!Array<!proto.manager_api.CoreSchedulerOperation>} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationResponse} returns this
*/
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.CoreSchedulerOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerOperation}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.CoreSchedulerOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerOperationResponse} returns this
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationResponse} returns this
 */
proto.manager_api.ListCoreSchedulerOperationResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetCoreSchedulerOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetCoreSchedulerOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetCoreSchedulerOperationRequest}
 */
proto.manager_api.GetCoreSchedulerOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetCoreSchedulerOperationRequest;
  return proto.manager_api.GetCoreSchedulerOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetCoreSchedulerOperationRequest}
 */
proto.manager_api.GetCoreSchedulerOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetCoreSchedulerOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetCoreSchedulerOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetCoreSchedulerOperationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.GetCoreSchedulerOperationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerOperationRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerOperationRequest;
  return proto.manager_api.ListCoreSchedulerOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {!proto.common.SchedulerBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional common.SchedulerBusinessType belong_business = 4;
 * @return {!proto.common.SchedulerBusinessType}
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.SchedulerBusinessType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.SchedulerBusinessType} value
 * @return {!proto.manager_api.ListCoreSchedulerOperationRequest} returns this
 */
proto.manager_api.ListCoreSchedulerOperationRequest.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.SaveSchedulerStepOperationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SaveSchedulerStepOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SaveSchedulerStepOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SaveSchedulerStepOperationRequest;
  return proto.manager_api.SaveSchedulerStepOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOperationIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SaveSchedulerStepOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SaveSchedulerStepOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOperationIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 step_id = 1;
 * @return {number}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.getStepId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest} returns this
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.setStepId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 operation_ids = 2;
 * @return {!Array<number>}
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.getOperationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest} returns this
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.setOperationIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest} returns this
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.addOperationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.SaveSchedulerStepOperationRequest} returns this
 */
proto.manager_api.SaveSchedulerStepOperationRequest.prototype.clearOperationIdsList = function() {
  return this.setOperationIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.TreeSchedulerStepOperationInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TreeSchedulerStepOperationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TreeSchedulerStepOperationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: (f = msg.getStep()) && proto.manager_api.SchedulerStepInfo.toObject(includeInstance, f),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.manager_api.CoreSchedulerOperation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TreeSchedulerStepOperationInfo;
  return proto.manager_api.TreeSchedulerStepOperationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TreeSchedulerStepOperationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SchedulerStepInfo;
      reader.readMessage(value,proto.manager_api.SchedulerStepInfo.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    case 2:
      var value = new proto.manager_api.CoreSchedulerOperation;
      reader.readMessage(value,proto.manager_api.CoreSchedulerOperation.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TreeSchedulerStepOperationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TreeSchedulerStepOperationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SchedulerStepInfo.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.manager_api.CoreSchedulerOperation.serializeBinaryToWriter
    );
  }
};


/**
 * optional SchedulerStepInfo step = 1;
 * @return {?proto.manager_api.SchedulerStepInfo}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.getStep = function() {
  return /** @type{?proto.manager_api.SchedulerStepInfo} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SchedulerStepInfo, 1));
};


/**
 * @param {?proto.manager_api.SchedulerStepInfo|undefined} value
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo} returns this
*/
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo} returns this
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.hasStep = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CoreSchedulerOperation children = 2;
 * @return {!Array<!proto.manager_api.CoreSchedulerOperation>}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.manager_api.CoreSchedulerOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.CoreSchedulerOperation, 2));
};


/**
 * @param {!Array<!proto.manager_api.CoreSchedulerOperation>} value
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo} returns this
*/
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.manager_api.CoreSchedulerOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerOperation}
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.manager_api.CoreSchedulerOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo} returns this
 */
proto.manager_api.TreeSchedulerStepOperationInfo.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TreeSchedulerStepOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TreeSchedulerStepOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TreeSchedulerStepOperationRequest}
 */
proto.manager_api.TreeSchedulerStepOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TreeSchedulerStepOperationRequest;
  return proto.manager_api.TreeSchedulerStepOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TreeSchedulerStepOperationRequest}
 */
proto.manager_api.TreeSchedulerStepOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.SchedulerBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TreeSchedulerStepOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TreeSchedulerStepOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional common.SchedulerBusinessType belong_business = 1;
 * @return {!proto.common.SchedulerBusinessType}
 */
proto.manager_api.TreeSchedulerStepOperationRequest.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.SchedulerBusinessType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.SchedulerBusinessType} value
 * @return {!proto.manager_api.TreeSchedulerStepOperationRequest} returns this
 */
proto.manager_api.TreeSchedulerStepOperationRequest.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.TreeSchedulerStepOperationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TreeSchedulerStepOperationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TreeSchedulerStepOperationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.TreeSchedulerStepOperationInfo.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TreeSchedulerStepOperationResponse}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TreeSchedulerStepOperationResponse;
  return proto.manager_api.TreeSchedulerStepOperationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TreeSchedulerStepOperationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TreeSchedulerStepOperationResponse}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TreeSchedulerStepOperationInfo;
      reader.readMessage(value,proto.manager_api.TreeSchedulerStepOperationInfo.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TreeSchedulerStepOperationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TreeSchedulerStepOperationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TreeSchedulerStepOperationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.TreeSchedulerStepOperationInfo.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated TreeSchedulerStepOperationInfo list = 1;
 * @return {!Array<!proto.manager_api.TreeSchedulerStepOperationInfo>}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.TreeSchedulerStepOperationInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.TreeSchedulerStepOperationInfo, 1));
};


/**
 * @param {!Array<!proto.manager_api.TreeSchedulerStepOperationInfo>} value
 * @return {!proto.manager_api.TreeSchedulerStepOperationResponse} returns this
*/
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.TreeSchedulerStepOperationInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.TreeSchedulerStepOperationInfo}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.TreeSchedulerStepOperationInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.TreeSchedulerStepOperationResponse} returns this
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TreeSchedulerStepOperationResponse} returns this
 */
proto.manager_api.TreeSchedulerStepOperationResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepMenu}
 */
proto.manager_api.CoreSchedulerStepMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepMenu;
  return proto.manager_api.CoreSchedulerStepMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepMenu}
 */
proto.manager_api.CoreSchedulerStepMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.CoreSchedulerStepMenu} returns this
 */
proto.manager_api.CoreSchedulerStepMenu.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyCoreSchedulerStepMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyCoreSchedulerStepMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    belongBusiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyCoreSchedulerStepMenu;
  return proto.manager_api.ModifyCoreSchedulerStepMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyCoreSchedulerStepMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CoreBusinessType} */ (reader.readEnum());
      msg.setBelongBusiness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyCoreSchedulerStepMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyCoreSchedulerStepMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBelongBusiness();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CoreBusinessType belong_business = 2;
 * @return {!proto.common.CoreBusinessType}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.getBelongBusiness = function() {
  return /** @type {!proto.common.CoreBusinessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CoreBusinessType} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.setBelongBusiness = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyCoreSchedulerStepMenu} returns this
 */
proto.manager_api.ModifyCoreSchedulerStepMenu.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyCoreSchedulerStepMenu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepMenuRequest}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepMenuRequest;
  return proto.manager_api.CoreSchedulerStepMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepMenuRequest}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyCoreSchedulerStepMenu;
      reader.readMessage(value,proto.manager_api.ModifyCoreSchedulerStepMenu.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyCoreSchedulerStepMenu.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyCoreSchedulerStepMenu detail = 1;
 * @return {?proto.manager_api.ModifyCoreSchedulerStepMenu}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyCoreSchedulerStepMenu} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyCoreSchedulerStepMenu, 1));
};


/**
 * @param {?proto.manager_api.ModifyCoreSchedulerStepMenu|undefined} value
 * @return {!proto.manager_api.CoreSchedulerStepMenuRequest} returns this
*/
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerStepMenuRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.CoreSchedulerStepMenuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.CoreSchedulerStepMenuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.CoreSchedulerStepMenu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.CoreSchedulerStepMenuResponse}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.CoreSchedulerStepMenuResponse;
  return proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.CoreSchedulerStepMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.CoreSchedulerStepMenuResponse}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerStepMenu;
      reader.readMessage(value,proto.manager_api.CoreSchedulerStepMenu.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.CoreSchedulerStepMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.CoreSchedulerStepMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.CoreSchedulerStepMenuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerStepMenu.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoreSchedulerStepMenu detail = 1;
 * @return {?proto.manager_api.CoreSchedulerStepMenu}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.CoreSchedulerStepMenu} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.CoreSchedulerStepMenu, 1));
};


/**
 * @param {?proto.manager_api.CoreSchedulerStepMenu|undefined} value
 * @return {!proto.manager_api.CoreSchedulerStepMenuResponse} returns this
*/
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.CoreSchedulerStepMenuResponse} returns this
 */
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.CoreSchedulerStepMenuResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerStepMenuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.CoreSchedulerStepMenu.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuResponse}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerStepMenuResponse;
  return proto.manager_api.ListCoreSchedulerStepMenuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuResponse}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.CoreSchedulerStepMenu;
      reader.readMessage(value,proto.manager_api.CoreSchedulerStepMenu.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerStepMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.CoreSchedulerStepMenu.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated CoreSchedulerStepMenu list = 1;
 * @return {!Array<!proto.manager_api.CoreSchedulerStepMenu>}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.CoreSchedulerStepMenu>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.CoreSchedulerStepMenu, 1));
};


/**
 * @param {!Array<!proto.manager_api.CoreSchedulerStepMenu>} value
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuResponse} returns this
*/
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepMenu=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.CoreSchedulerStepMenu}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.CoreSchedulerStepMenu, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuResponse} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuResponse} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetCoreSchedulerStepMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetCoreSchedulerStepMenuRequest}
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetCoreSchedulerStepMenuRequest;
  return proto.manager_api.GetCoreSchedulerStepMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetCoreSchedulerStepMenuRequest}
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetCoreSchedulerStepMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.GetCoreSchedulerStepMenuRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListCoreSchedulerStepMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListCoreSchedulerStepMenuRequest;
  return proto.manager_api.ListCoreSchedulerStepMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListCoreSchedulerStepMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListCoreSchedulerStepMenuRequest} returns this
 */
proto.manager_api.ListCoreSchedulerStepMenuRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysRole.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roleName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fullPermission: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysRole}
 */
proto.manager_api.SysRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysRole;
  return proto.manager_api.SysRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysRole}
 */
proto.manager_api.SysRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 7:
      var value = /** @type {!proto.common.SysRolePermissionType} */ (reader.readEnum());
      msg.setFullPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFullPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysRole.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.manager_api.SysRole.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.manager_api.SysRole.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string role_name = 4;
 * @return {string}
 */
proto.manager_api.SysRole.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 parent_id = 5;
 * @return {number}
 */
proto.manager_api.SysRole.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 sort = 6;
 * @return {number}
 */
proto.manager_api.SysRole.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional common.SysRolePermissionType full_permission = 7;
 * @return {!proto.common.SysRolePermissionType}
 */
proto.manager_api.SysRole.prototype.getFullPermission = function() {
  return /** @type {!proto.common.SysRolePermissionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.SysRolePermissionType} value
 * @return {!proto.manager_api.SysRole} returns this
 */
proto.manager_api.SysRole.prototype.setFullPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysRole.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    roleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fullPermission: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysRole}
 */
proto.manager_api.ModifySysRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysRole;
  return proto.manager_api.ModifySysRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysRole}
 */
proto.manager_api.ModifySysRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {!proto.common.SysRolePermissionType} */ (reader.readEnum());
      msg.setFullPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFullPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysRole.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysRole} returns this
 */
proto.manager_api.ModifySysRole.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string role_name = 2;
 * @return {string}
 */
proto.manager_api.ModifySysRole.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysRole} returns this
 */
proto.manager_api.ModifySysRole.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 parent_id = 3;
 * @return {number}
 */
proto.manager_api.ModifySysRole.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysRole} returns this
 */
proto.manager_api.ModifySysRole.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 sort = 4;
 * @return {number}
 */
proto.manager_api.ModifySysRole.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysRole} returns this
 */
proto.manager_api.ModifySysRole.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional common.SysRolePermissionType full_permission = 5;
 * @return {!proto.common.SysRolePermissionType}
 */
proto.manager_api.ModifySysRole.prototype.getFullPermission = function() {
  return /** @type {!proto.common.SysRolePermissionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.SysRolePermissionType} value
 * @return {!proto.manager_api.ModifySysRole} returns this
 */
proto.manager_api.ModifySysRole.prototype.setFullPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysRoleRequest}
 */
proto.manager_api.SysRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysRoleRequest;
  return proto.manager_api.SysRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysRoleRequest}
 */
proto.manager_api.SysRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysRole;
      reader.readMessage(value,proto.manager_api.ModifySysRole.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysRole detail = 1;
 * @return {?proto.manager_api.ModifySysRole}
 */
proto.manager_api.SysRoleRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysRole} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysRole, 1));
};


/**
 * @param {?proto.manager_api.ModifySysRole|undefined} value
 * @return {!proto.manager_api.SysRoleRequest} returns this
*/
proto.manager_api.SysRoleRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysRoleRequest} returns this
 */
proto.manager_api.SysRoleRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysRoleRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysRoleResponse}
 */
proto.manager_api.SysRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysRoleResponse;
  return proto.manager_api.SysRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysRoleResponse}
 */
proto.manager_api.SysRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysRole;
      reader.readMessage(value,proto.manager_api.SysRole.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysRole detail = 1;
 * @return {?proto.manager_api.SysRole}
 */
proto.manager_api.SysRoleResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysRole} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysRole, 1));
};


/**
 * @param {?proto.manager_api.SysRole|undefined} value
 * @return {!proto.manager_api.SysRoleResponse} returns this
*/
proto.manager_api.SysRoleResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysRoleResponse} returns this
 */
proto.manager_api.SysRoleResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysRoleResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysRoleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysRole.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysRoleResponse}
 */
proto.manager_api.ListSysRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysRoleResponse;
  return proto.manager_api.ListSysRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysRoleResponse}
 */
proto.manager_api.ListSysRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysRole;
      reader.readMessage(value,proto.manager_api.SysRole.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysRole.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysRole list = 1;
 * @return {!Array<!proto.manager_api.SysRole>}
 */
proto.manager_api.ListSysRoleResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysRole, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysRole>} value
 * @return {!proto.manager_api.ListSysRoleResponse} returns this
*/
proto.manager_api.ListSysRoleResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysRole}
 */
proto.manager_api.ListSysRoleResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysRoleResponse} returns this
 */
proto.manager_api.ListSysRoleResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysRoleResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysRoleResponse} returns this
 */
proto.manager_api.ListSysRoleResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysRoleRequest}
 */
proto.manager_api.GetSysRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysRoleRequest;
  return proto.manager_api.GetSysRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysRoleRequest}
 */
proto.manager_api.GetSysRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysRoleRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysRoleRequest} returns this
 */
proto.manager_api.GetSysRoleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysRoleRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysRoleRequest}
 */
proto.manager_api.ListSysRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysRoleRequest;
  return proto.manager_api.ListSysRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysRoleRequest}
 */
proto.manager_api.ListSysRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysRoleRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysRoleRequest} returns this
 */
proto.manager_api.ListSysRoleRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysRoleRequest} returns this
 */
proto.manager_api.ListSysRoleRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysRoleRequest} returns this
 */
proto.manager_api.ListSysRoleRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysRoleRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysRoleRequest} returns this
 */
proto.manager_api.ListSysRoleRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysRoleRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysRoleRequest} returns this
 */
proto.manager_api.ListSysRoleRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupType.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupType}
 */
proto.manager_api.TaskUserGroupType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupType;
  return proto.manager_api.TaskUserGroupType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupType}
 */
proto.manager_api.TaskUserGroupType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.TaskUserGroupType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupType} returns this
 */
proto.manager_api.TaskUserGroupType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.TaskUserGroupType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.TaskUserGroupType} returns this
 */
proto.manager_api.TaskUserGroupType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 creator = 3;
 * @return {number}
 */
proto.manager_api.TaskUserGroupType.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupType} returns this
 */
proto.manager_api.TaskUserGroupType.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.manager_api.TaskUserGroupType.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupType} returns this
 */
proto.manager_api.TaskUserGroupType.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.manager_api.TaskUserGroupType.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupType} returns this
 */
proto.manager_api.TaskUserGroupType.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyTaskUserGroupType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyTaskUserGroupType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroupType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyTaskUserGroupType}
 */
proto.manager_api.ModifyTaskUserGroupType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyTaskUserGroupType;
  return proto.manager_api.ModifyTaskUserGroupType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyTaskUserGroupType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyTaskUserGroupType}
 */
proto.manager_api.ModifyTaskUserGroupType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyTaskUserGroupType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyTaskUserGroupType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroupType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupType} returns this
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyTaskUserGroupType} returns this
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 creator = 3;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupType} returns this
 */
proto.manager_api.ModifyTaskUserGroupType.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyTaskUserGroupType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupTypeRequest}
 */
proto.manager_api.TaskUserGroupTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupTypeRequest;
  return proto.manager_api.TaskUserGroupTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupTypeRequest}
 */
proto.manager_api.TaskUserGroupTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyTaskUserGroupType;
      reader.readMessage(value,proto.manager_api.ModifyTaskUserGroupType.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyTaskUserGroupType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyTaskUserGroupType detail = 1;
 * @return {?proto.manager_api.ModifyTaskUserGroupType}
 */
proto.manager_api.TaskUserGroupTypeRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyTaskUserGroupType} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyTaskUserGroupType, 1));
};


/**
 * @param {?proto.manager_api.ModifyTaskUserGroupType|undefined} value
 * @return {!proto.manager_api.TaskUserGroupTypeRequest} returns this
*/
proto.manager_api.TaskUserGroupTypeRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupTypeRequest} returns this
 */
proto.manager_api.TaskUserGroupTypeRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupTypeRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.TaskUserGroupType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupTypeResponse}
 */
proto.manager_api.TaskUserGroupTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupTypeResponse;
  return proto.manager_api.TaskUserGroupTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupTypeResponse}
 */
proto.manager_api.TaskUserGroupTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroupType;
      reader.readMessage(value,proto.manager_api.TaskUserGroupType.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.TaskUserGroupType.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskUserGroupType detail = 1;
 * @return {?proto.manager_api.TaskUserGroupType}
 */
proto.manager_api.TaskUserGroupTypeResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.TaskUserGroupType} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.TaskUserGroupType, 1));
};


/**
 * @param {?proto.manager_api.TaskUserGroupType|undefined} value
 * @return {!proto.manager_api.TaskUserGroupTypeResponse} returns this
*/
proto.manager_api.TaskUserGroupTypeResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupTypeResponse} returns this
 */
proto.manager_api.TaskUserGroupTypeResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupTypeResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.TaskUserGroupType.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupTypeResponse}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupTypeResponse;
  return proto.manager_api.ListTaskUserGroupTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupTypeResponse}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroupType;
      reader.readMessage(value,proto.manager_api.TaskUserGroupType.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.TaskUserGroupType.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TaskUserGroupType list = 1;
 * @return {!Array<!proto.manager_api.TaskUserGroupType>}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.TaskUserGroupType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.TaskUserGroupType, 1));
};


/**
 * @param {!Array<!proto.manager_api.TaskUserGroupType>} value
 * @return {!proto.manager_api.ListTaskUserGroupTypeResponse} returns this
*/
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.TaskUserGroupType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.TaskUserGroupType}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.TaskUserGroupType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupTypeResponse} returns this
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupTypeResponse} returns this
 */
proto.manager_api.ListTaskUserGroupTypeResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetTaskUserGroupTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetTaskUserGroupTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetTaskUserGroupTypeRequest}
 */
proto.manager_api.GetTaskUserGroupTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetTaskUserGroupTypeRequest;
  return proto.manager_api.GetTaskUserGroupTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetTaskUserGroupTypeRequest}
 */
proto.manager_api.GetTaskUserGroupTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetTaskUserGroupTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetTaskUserGroupTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetTaskUserGroupTypeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.GetTaskUserGroupTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupTypeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupTypeRequest;
  return proto.manager_api.ListTaskUserGroupTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupTypeRequest} returns this
 */
proto.manager_api.ListTaskUserGroupTypeRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroup}
 */
proto.manager_api.TaskUserGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroup;
  return proto.manager_api.TaskUserGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroup}
 */
proto.manager_api.TaskUserGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.TaskUserGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 creator = 3;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 group_type = 4;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sort = 5;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.manager_api.TaskUserGroup.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroup} returns this
 */
proto.manager_api.TaskUserGroup.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyTaskUserGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyTaskUserGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyTaskUserGroup}
 */
proto.manager_api.ModifyTaskUserGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyTaskUserGroup;
  return proto.manager_api.ModifyTaskUserGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyTaskUserGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyTaskUserGroup}
 */
proto.manager_api.ModifyTaskUserGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyTaskUserGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyTaskUserGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroup} returns this
 */
proto.manager_api.ModifyTaskUserGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifyTaskUserGroup} returns this
 */
proto.manager_api.ModifyTaskUserGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 creator = 3;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroup} returns this
 */
proto.manager_api.ModifyTaskUserGroup.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 group_type = 4;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroup} returns this
 */
proto.manager_api.ModifyTaskUserGroup.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sort = 5;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroup.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroup} returns this
 */
proto.manager_api.ModifyTaskUserGroup.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyTaskUserGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupRequest}
 */
proto.manager_api.TaskUserGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupRequest;
  return proto.manager_api.TaskUserGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupRequest}
 */
proto.manager_api.TaskUserGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyTaskUserGroup;
      reader.readMessage(value,proto.manager_api.ModifyTaskUserGroup.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyTaskUserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyTaskUserGroup detail = 1;
 * @return {?proto.manager_api.ModifyTaskUserGroup}
 */
proto.manager_api.TaskUserGroupRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyTaskUserGroup} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyTaskUserGroup, 1));
};


/**
 * @param {?proto.manager_api.ModifyTaskUserGroup|undefined} value
 * @return {!proto.manager_api.TaskUserGroupRequest} returns this
*/
proto.manager_api.TaskUserGroupRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupRequest} returns this
 */
proto.manager_api.TaskUserGroupRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.TaskUserGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupResponse}
 */
proto.manager_api.TaskUserGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupResponse;
  return proto.manager_api.TaskUserGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupResponse}
 */
proto.manager_api.TaskUserGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroup;
      reader.readMessage(value,proto.manager_api.TaskUserGroup.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.TaskUserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskUserGroup detail = 1;
 * @return {?proto.manager_api.TaskUserGroup}
 */
proto.manager_api.TaskUserGroupResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.TaskUserGroup} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.TaskUserGroup, 1));
};


/**
 * @param {?proto.manager_api.TaskUserGroup|undefined} value
 * @return {!proto.manager_api.TaskUserGroupResponse} returns this
*/
proto.manager_api.TaskUserGroupResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupResponse} returns this
 */
proto.manager_api.TaskUserGroupResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.TaskUserGroup.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupResponse}
 */
proto.manager_api.ListTaskUserGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupResponse;
  return proto.manager_api.ListTaskUserGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupResponse}
 */
proto.manager_api.ListTaskUserGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroup;
      reader.readMessage(value,proto.manager_api.TaskUserGroup.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.TaskUserGroup.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TaskUserGroup list = 1;
 * @return {!Array<!proto.manager_api.TaskUserGroup>}
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.TaskUserGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.TaskUserGroup, 1));
};


/**
 * @param {!Array<!proto.manager_api.TaskUserGroup>} value
 * @return {!proto.manager_api.ListTaskUserGroupResponse} returns this
*/
proto.manager_api.ListTaskUserGroupResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.TaskUserGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.TaskUserGroup}
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.TaskUserGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupResponse} returns this
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupResponse} returns this
 */
proto.manager_api.ListTaskUserGroupResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetTaskUserGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetTaskUserGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetTaskUserGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetTaskUserGroupRequest}
 */
proto.manager_api.GetTaskUserGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetTaskUserGroupRequest;
  return proto.manager_api.GetTaskUserGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetTaskUserGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetTaskUserGroupRequest}
 */
proto.manager_api.GetTaskUserGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetTaskUserGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetTaskUserGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetTaskUserGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetTaskUserGroupRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetTaskUserGroupRequest} returns this
 */
proto.manager_api.GetTaskUserGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupRequest}
 */
proto.manager_api.ListTaskUserGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupRequest;
  return proto.manager_api.ListTaskUserGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupRequest}
 */
proto.manager_api.ListTaskUserGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListTaskUserGroupRequest} returns this
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListTaskUserGroupRequest} returns this
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupRequest} returns this
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupRequest} returns this
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupRequest} returns this
 */
proto.manager_api.ListTaskUserGroupRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupMember.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isLeader: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupMember}
 */
proto.manager_api.TaskUserGroupMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupMember;
  return proto.manager_api.TaskUserGroupMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupMember}
 */
proto.manager_api.TaskUserGroupMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {!proto.common.GroupUserType} */ (reader.readEnum());
      msg.setIsLeader(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getIsLeader();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 group_id = 4;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 user_id = 5;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional common.GroupUserType is_leader = 6;
 * @return {!proto.common.GroupUserType}
 */
proto.manager_api.TaskUserGroupMember.prototype.getIsLeader = function() {
  return /** @type {!proto.common.GroupUserType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.GroupUserType} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setIsLeader = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 sort = 7;
 * @return {number}
 */
proto.manager_api.TaskUserGroupMember.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.TaskUserGroupMember} returns this
 */
proto.manager_api.TaskUserGroupMember.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifyTaskUserGroupMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifyTaskUserGroupMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroupMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isLeader: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifyTaskUserGroupMember}
 */
proto.manager_api.ModifyTaskUserGroupMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifyTaskUserGroupMember;
  return proto.manager_api.ModifyTaskUserGroupMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifyTaskUserGroupMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifyTaskUserGroupMember}
 */
proto.manager_api.ModifyTaskUserGroupMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.common.GroupUserType} */ (reader.readEnum());
      msg.setIsLeader(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifyTaskUserGroupMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifyTaskUserGroupMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifyTaskUserGroupMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsLeader();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupMember} returns this
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 group_id = 2;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupMember} returns this
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 user_id = 3;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupMember} returns this
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional common.GroupUserType is_leader = 4;
 * @return {!proto.common.GroupUserType}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.getIsLeader = function() {
  return /** @type {!proto.common.GroupUserType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.GroupUserType} value
 * @return {!proto.manager_api.ModifyTaskUserGroupMember} returns this
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.setIsLeader = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 sort = 5;
 * @return {number}
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifyTaskUserGroupMember} returns this
 */
proto.manager_api.ModifyTaskUserGroupMember.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifyTaskUserGroupMember.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupMemberRequest}
 */
proto.manager_api.TaskUserGroupMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupMemberRequest;
  return proto.manager_api.TaskUserGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupMemberRequest}
 */
proto.manager_api.TaskUserGroupMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifyTaskUserGroupMember;
      reader.readMessage(value,proto.manager_api.ModifyTaskUserGroupMember.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifyTaskUserGroupMember.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifyTaskUserGroupMember detail = 1;
 * @return {?proto.manager_api.ModifyTaskUserGroupMember}
 */
proto.manager_api.TaskUserGroupMemberRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifyTaskUserGroupMember} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifyTaskUserGroupMember, 1));
};


/**
 * @param {?proto.manager_api.ModifyTaskUserGroupMember|undefined} value
 * @return {!proto.manager_api.TaskUserGroupMemberRequest} returns this
*/
proto.manager_api.TaskUserGroupMemberRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupMemberRequest} returns this
 */
proto.manager_api.TaskUserGroupMemberRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupMemberRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.TaskUserGroupMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.TaskUserGroupMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.TaskUserGroupMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.TaskUserGroupMember.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.TaskUserGroupMemberResponse}
 */
proto.manager_api.TaskUserGroupMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.TaskUserGroupMemberResponse;
  return proto.manager_api.TaskUserGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.TaskUserGroupMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.TaskUserGroupMemberResponse}
 */
proto.manager_api.TaskUserGroupMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroupMember;
      reader.readMessage(value,proto.manager_api.TaskUserGroupMember.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.TaskUserGroupMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.TaskUserGroupMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.TaskUserGroupMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.TaskUserGroupMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.TaskUserGroupMember.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskUserGroupMember detail = 1;
 * @return {?proto.manager_api.TaskUserGroupMember}
 */
proto.manager_api.TaskUserGroupMemberResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.TaskUserGroupMember} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.TaskUserGroupMember, 1));
};


/**
 * @param {?proto.manager_api.TaskUserGroupMember|undefined} value
 * @return {!proto.manager_api.TaskUserGroupMemberResponse} returns this
*/
proto.manager_api.TaskUserGroupMemberResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.TaskUserGroupMemberResponse} returns this
 */
proto.manager_api.TaskUserGroupMemberResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.TaskUserGroupMemberResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupMemberResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.TaskUserGroupMember.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupMemberResponse}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupMemberResponse;
  return proto.manager_api.ListTaskUserGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupMemberResponse}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.TaskUserGroupMember;
      reader.readMessage(value,proto.manager_api.TaskUserGroupMember.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.TaskUserGroupMember.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated TaskUserGroupMember list = 1;
 * @return {!Array<!proto.manager_api.TaskUserGroupMember>}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.TaskUserGroupMember>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.TaskUserGroupMember, 1));
};


/**
 * @param {!Array<!proto.manager_api.TaskUserGroupMember>} value
 * @return {!proto.manager_api.ListTaskUserGroupMemberResponse} returns this
*/
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.TaskUserGroupMember=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.TaskUserGroupMember}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.TaskUserGroupMember, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupMemberResponse} returns this
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupMemberResponse} returns this
 */
proto.manager_api.ListTaskUserGroupMemberResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetTaskUserGroupMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetTaskUserGroupMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetTaskUserGroupMemberRequest}
 */
proto.manager_api.GetTaskUserGroupMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetTaskUserGroupMemberRequest;
  return proto.manager_api.GetTaskUserGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetTaskUserGroupMemberRequest}
 */
proto.manager_api.GetTaskUserGroupMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetTaskUserGroupMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetTaskUserGroupMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetTaskUserGroupMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetTaskUserGroupMemberRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.GetTaskUserGroupMemberRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListTaskUserGroupMemberRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListTaskUserGroupMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListTaskUserGroupMemberRequest;
  return proto.manager_api.ListTaskUserGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListTaskUserGroupMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListTaskUserGroupMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListTaskUserGroupMemberRequest} returns this
 */
proto.manager_api.ListTaskUserGroupMemberRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysApi.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysApi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysApi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApi.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apiName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiMethod: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    apiDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysApi}
 */
proto.manager_api.SysApi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysApi;
  return proto.manager_api.SysApi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysApi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysApi}
 */
proto.manager_api.SysApi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiMethod(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysApi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysApi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysApi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getApiName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiMethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApiDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysApi.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string api_name = 2;
 * @return {string}
 */
proto.manager_api.SysApi.prototype.getApiName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setApiName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string api_method = 3;
 * @return {string}
 */
proto.manager_api.SysApi.prototype.getApiMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setApiMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_path = 4;
 * @return {string}
 */
proto.manager_api.SysApi.prototype.getApiPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setApiPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string api_description = 5;
 * @return {string}
 */
proto.manager_api.SysApi.prototype.getApiDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setApiDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 creator = 6;
 * @return {number}
 */
proto.manager_api.SysApi.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.manager_api.SysApi.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 updated_at = 8;
 * @return {number}
 */
proto.manager_api.SysApi.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysApi} returns this
 */
proto.manager_api.SysApi.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysApi.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysApi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysApi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysApi.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apiName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apiMethod: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    apiDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysApi}
 */
proto.manager_api.ModifySysApi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysApi;
  return proto.manager_api.ModifySysApi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysApi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysApi}
 */
proto.manager_api.ModifySysApi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiMethod(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysApi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysApi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysApi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysApi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getApiName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApiMethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApiDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysApi.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string api_name = 2;
 * @return {string}
 */
proto.manager_api.ModifySysApi.prototype.getApiName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setApiName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string api_method = 3;
 * @return {string}
 */
proto.manager_api.ModifySysApi.prototype.getApiMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setApiMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_path = 4;
 * @return {string}
 */
proto.manager_api.ModifySysApi.prototype.getApiPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setApiPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string api_description = 5;
 * @return {string}
 */
proto.manager_api.ModifySysApi.prototype.getApiDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setApiDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 creator = 6;
 * @return {number}
 */
proto.manager_api.ModifySysApi.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysApi} returns this
 */
proto.manager_api.ModifySysApi.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysApiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysApiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysApiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysApi.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysApiRequest}
 */
proto.manager_api.SysApiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysApiRequest;
  return proto.manager_api.SysApiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysApiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysApiRequest}
 */
proto.manager_api.SysApiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysApi;
      reader.readMessage(value,proto.manager_api.ModifySysApi.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysApiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysApiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysApiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysApi.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysApi detail = 1;
 * @return {?proto.manager_api.ModifySysApi}
 */
proto.manager_api.SysApiRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysApi} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysApi, 1));
};


/**
 * @param {?proto.manager_api.ModifySysApi|undefined} value
 * @return {!proto.manager_api.SysApiRequest} returns this
*/
proto.manager_api.SysApiRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysApiRequest} returns this
 */
proto.manager_api.SysApiRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysApiRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysApi.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysApiResponse}
 */
proto.manager_api.SysApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysApiResponse;
  return proto.manager_api.SysApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysApiResponse}
 */
proto.manager_api.SysApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysApi;
      reader.readMessage(value,proto.manager_api.SysApi.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysApi.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysApi detail = 1;
 * @return {?proto.manager_api.SysApi}
 */
proto.manager_api.SysApiResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysApi} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysApi, 1));
};


/**
 * @param {?proto.manager_api.SysApi|undefined} value
 * @return {!proto.manager_api.SysApiResponse} returns this
*/
proto.manager_api.SysApiResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysApiResponse} returns this
 */
proto.manager_api.SysApiResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysApiResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysApiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysApi.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysApiResponse}
 */
proto.manager_api.ListSysApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysApiResponse;
  return proto.manager_api.ListSysApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysApiResponse}
 */
proto.manager_api.ListSysApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysApi;
      reader.readMessage(value,proto.manager_api.SysApi.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysApi.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysApi list = 1;
 * @return {!Array<!proto.manager_api.SysApi>}
 */
proto.manager_api.ListSysApiResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysApi>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysApi, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysApi>} value
 * @return {!proto.manager_api.ListSysApiResponse} returns this
*/
proto.manager_api.ListSysApiResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysApi=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysApi}
 */
proto.manager_api.ListSysApiResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysApi, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysApiResponse} returns this
 */
proto.manager_api.ListSysApiResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysApiResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysApiResponse} returns this
 */
proto.manager_api.ListSysApiResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysApiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysApiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysApiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysApiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysApiRequest}
 */
proto.manager_api.GetSysApiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysApiRequest;
  return proto.manager_api.GetSysApiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysApiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysApiRequest}
 */
proto.manager_api.GetSysApiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysApiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysApiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysApiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysApiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysApiRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysApiRequest} returns this
 */
proto.manager_api.GetSysApiRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysApiRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysApiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysApiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysApiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysApiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysApiRequest}
 */
proto.manager_api.ListSysApiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysApiRequest;
  return proto.manager_api.ListSysApiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysApiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysApiRequest}
 */
proto.manager_api.ListSysApiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysApiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysApiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysApiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysApiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysApiRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysApiRequest} returns this
 */
proto.manager_api.ListSysApiRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysApiRequest} returns this
 */
proto.manager_api.ListSysApiRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysApiRequest} returns this
 */
proto.manager_api.ListSysApiRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysApiRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysApiRequest} returns this
 */
proto.manager_api.ListSysApiRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysApiRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysApiRequest} returns this
 */
proto.manager_api.ListSysApiRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    menuCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    menuDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenu}
 */
proto.manager_api.SysMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenu;
  return proto.manager_api.SysMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenu}
 */
proto.manager_api.SysMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenuCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenuDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMenuCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMenuDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysMenu.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.SysMenu.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string menu_code = 3;
 * @return {string}
 */
proto.manager_api.SysMenu.prototype.getMenuCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setMenuCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string menu_description = 4;
 * @return {string}
 */
proto.manager_api.SysMenu.prototype.getMenuDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setMenuDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 parent_id = 5;
 * @return {number}
 */
proto.manager_api.SysMenu.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 creator = 6;
 * @return {number}
 */
proto.manager_api.SysMenu.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.manager_api.SysMenu.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 updated_at = 8;
 * @return {number}
 */
proto.manager_api.SysMenu.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenu} returns this
 */
proto.manager_api.SysMenu.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    menuCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    menuDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysMenu}
 */
proto.manager_api.ModifySysMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysMenu;
  return proto.manager_api.ModifySysMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysMenu}
 */
proto.manager_api.ModifySysMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenuCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenuDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMenuCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMenuDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysMenu.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.manager_api.ModifySysMenu.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string menu_code = 3;
 * @return {string}
 */
proto.manager_api.ModifySysMenu.prototype.getMenuCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setMenuCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string menu_description = 4;
 * @return {string}
 */
proto.manager_api.ModifySysMenu.prototype.getMenuDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setMenuDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 parent_id = 5;
 * @return {number}
 */
proto.manager_api.ModifySysMenu.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 creator = 6;
 * @return {number}
 */
proto.manager_api.ModifySysMenu.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenu} returns this
 */
proto.manager_api.ModifySysMenu.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysMenu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenuRequest}
 */
proto.manager_api.SysMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenuRequest;
  return proto.manager_api.SysMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenuRequest}
 */
proto.manager_api.SysMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysMenu;
      reader.readMessage(value,proto.manager_api.ModifySysMenu.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysMenu.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysMenu detail = 1;
 * @return {?proto.manager_api.ModifySysMenu}
 */
proto.manager_api.SysMenuRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysMenu} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysMenu, 1));
};


/**
 * @param {?proto.manager_api.ModifySysMenu|undefined} value
 * @return {!proto.manager_api.SysMenuRequest} returns this
*/
proto.manager_api.SysMenuRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysMenuRequest} returns this
 */
proto.manager_api.SysMenuRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysMenuRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysMenu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenuResponse}
 */
proto.manager_api.SysMenuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenuResponse;
  return proto.manager_api.SysMenuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenuResponse}
 */
proto.manager_api.SysMenuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysMenu;
      reader.readMessage(value,proto.manager_api.SysMenu.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysMenu.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysMenu detail = 1;
 * @return {?proto.manager_api.SysMenu}
 */
proto.manager_api.SysMenuResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysMenu} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysMenu, 1));
};


/**
 * @param {?proto.manager_api.SysMenu|undefined} value
 * @return {!proto.manager_api.SysMenuResponse} returns this
*/
proto.manager_api.SysMenuResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysMenuResponse} returns this
 */
proto.manager_api.SysMenuResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysMenuResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysMenuResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysMenuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysMenuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysMenuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysMenu.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysMenuResponse}
 */
proto.manager_api.ListSysMenuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysMenuResponse;
  return proto.manager_api.ListSysMenuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysMenuResponse}
 */
proto.manager_api.ListSysMenuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysMenu;
      reader.readMessage(value,proto.manager_api.SysMenu.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysMenuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysMenu.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysMenu list = 1;
 * @return {!Array<!proto.manager_api.SysMenu>}
 */
proto.manager_api.ListSysMenuResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysMenu>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysMenu, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysMenu>} value
 * @return {!proto.manager_api.ListSysMenuResponse} returns this
*/
proto.manager_api.ListSysMenuResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysMenu=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysMenu}
 */
proto.manager_api.ListSysMenuResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysMenu, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysMenuResponse} returns this
 */
proto.manager_api.ListSysMenuResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysMenuResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuResponse} returns this
 */
proto.manager_api.ListSysMenuResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysMenuRequest}
 */
proto.manager_api.GetSysMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysMenuRequest;
  return proto.manager_api.GetSysMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysMenuRequest}
 */
proto.manager_api.GetSysMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysMenuRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysMenuRequest} returns this
 */
proto.manager_api.GetSysMenuRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysMenuRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysMenuRequest}
 */
proto.manager_api.ListSysMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysMenuRequest;
  return proto.manager_api.ListSysMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysMenuRequest}
 */
proto.manager_api.ListSysMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysMenuRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysMenuRequest} returns this
 */
proto.manager_api.ListSysMenuRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysMenuRequest} returns this
 */
proto.manager_api.ListSysMenuRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysMenuRequest} returns this
 */
proto.manager_api.ListSysMenuRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysMenuRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuRequest} returns this
 */
proto.manager_api.ListSysMenuRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysMenuRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuRequest} returns this
 */
proto.manager_api.ListSysMenuRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenuApiRelation.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenuApiRelation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenuApiRelation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    menuId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    apiId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenuApiRelation}
 */
proto.manager_api.SysMenuApiRelation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenuApiRelation;
  return proto.manager_api.SysMenuApiRelation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenuApiRelation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenuApiRelation}
 */
proto.manager_api.SysMenuApiRelation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMenuId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApiId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenuApiRelation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenuApiRelation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenuApiRelation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMenuId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getApiId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysMenuApiRelation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenuApiRelation} returns this
 */
proto.manager_api.SysMenuApiRelation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 menu_id = 2;
 * @return {number}
 */
proto.manager_api.SysMenuApiRelation.prototype.getMenuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenuApiRelation} returns this
 */
proto.manager_api.SysMenuApiRelation.prototype.setMenuId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 api_id = 3;
 * @return {number}
 */
proto.manager_api.SysMenuApiRelation.prototype.getApiId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenuApiRelation} returns this
 */
proto.manager_api.SysMenuApiRelation.prototype.setApiId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 creator = 4;
 * @return {number}
 */
proto.manager_api.SysMenuApiRelation.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysMenuApiRelation} returns this
 */
proto.manager_api.SysMenuApiRelation.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysMenuApiRelation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysMenuApiRelation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysMenuApiRelation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    menuId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    apiId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysMenuApiRelation}
 */
proto.manager_api.ModifySysMenuApiRelation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysMenuApiRelation;
  return proto.manager_api.ModifySysMenuApiRelation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysMenuApiRelation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysMenuApiRelation}
 */
proto.manager_api.ModifySysMenuApiRelation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMenuId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApiId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysMenuApiRelation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysMenuApiRelation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysMenuApiRelation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMenuId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getApiId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreator();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenuApiRelation} returns this
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 menu_id = 2;
 * @return {number}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.getMenuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenuApiRelation} returns this
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.setMenuId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 api_id = 3;
 * @return {number}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.getApiId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenuApiRelation} returns this
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.setApiId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 creator = 4;
 * @return {number}
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.getCreator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysMenuApiRelation} returns this
 */
proto.manager_api.ModifySysMenuApiRelation.prototype.setCreator = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenuApiRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenuApiRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenuApiRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysMenuApiRelation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenuApiRelationRequest}
 */
proto.manager_api.SysMenuApiRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenuApiRelationRequest;
  return proto.manager_api.SysMenuApiRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenuApiRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenuApiRelationRequest}
 */
proto.manager_api.SysMenuApiRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysMenuApiRelation;
      reader.readMessage(value,proto.manager_api.ModifySysMenuApiRelation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenuApiRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenuApiRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenuApiRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysMenuApiRelation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysMenuApiRelation detail = 1;
 * @return {?proto.manager_api.ModifySysMenuApiRelation}
 */
proto.manager_api.SysMenuApiRelationRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysMenuApiRelation} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysMenuApiRelation, 1));
};


/**
 * @param {?proto.manager_api.ModifySysMenuApiRelation|undefined} value
 * @return {!proto.manager_api.SysMenuApiRelationRequest} returns this
*/
proto.manager_api.SysMenuApiRelationRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysMenuApiRelationRequest} returns this
 */
proto.manager_api.SysMenuApiRelationRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysMenuApiRelationRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysMenuApiRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysMenuApiRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysMenuApiRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysMenuApiRelation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysMenuApiRelationResponse}
 */
proto.manager_api.SysMenuApiRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysMenuApiRelationResponse;
  return proto.manager_api.SysMenuApiRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysMenuApiRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysMenuApiRelationResponse}
 */
proto.manager_api.SysMenuApiRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysMenuApiRelation;
      reader.readMessage(value,proto.manager_api.SysMenuApiRelation.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysMenuApiRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysMenuApiRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysMenuApiRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysMenuApiRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysMenuApiRelation.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysMenuApiRelation detail = 1;
 * @return {?proto.manager_api.SysMenuApiRelation}
 */
proto.manager_api.SysMenuApiRelationResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysMenuApiRelation} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysMenuApiRelation, 1));
};


/**
 * @param {?proto.manager_api.SysMenuApiRelation|undefined} value
 * @return {!proto.manager_api.SysMenuApiRelationResponse} returns this
*/
proto.manager_api.SysMenuApiRelationResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysMenuApiRelationResponse} returns this
 */
proto.manager_api.SysMenuApiRelationResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysMenuApiRelationResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysMenuApiRelationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysMenuApiRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysMenuApiRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuApiRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysMenuApiRelation.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysMenuApiRelationResponse}
 */
proto.manager_api.ListSysMenuApiRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysMenuApiRelationResponse;
  return proto.manager_api.ListSysMenuApiRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysMenuApiRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysMenuApiRelationResponse}
 */
proto.manager_api.ListSysMenuApiRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysMenuApiRelation;
      reader.readMessage(value,proto.manager_api.SysMenuApiRelation.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysMenuApiRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysMenuApiRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuApiRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysMenuApiRelation.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysMenuApiRelation list = 1;
 * @return {!Array<!proto.manager_api.SysMenuApiRelation>}
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysMenuApiRelation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysMenuApiRelation, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysMenuApiRelation>} value
 * @return {!proto.manager_api.ListSysMenuApiRelationResponse} returns this
*/
proto.manager_api.ListSysMenuApiRelationResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysMenuApiRelation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysMenuApiRelation}
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysMenuApiRelation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysMenuApiRelationResponse} returns this
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuApiRelationResponse} returns this
 */
proto.manager_api.ListSysMenuApiRelationResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysMenuApiRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysMenuApiRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysMenuApiRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysMenuApiRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysMenuApiRelationRequest}
 */
proto.manager_api.GetSysMenuApiRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysMenuApiRelationRequest;
  return proto.manager_api.GetSysMenuApiRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysMenuApiRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysMenuApiRelationRequest}
 */
proto.manager_api.GetSysMenuApiRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysMenuApiRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysMenuApiRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysMenuApiRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysMenuApiRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysMenuApiRelationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysMenuApiRelationRequest} returns this
 */
proto.manager_api.GetSysMenuApiRelationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysMenuApiRelationRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysMenuApiRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysMenuApiRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuApiRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest}
 */
proto.manager_api.ListSysMenuApiRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysMenuApiRelationRequest;
  return proto.manager_api.ListSysMenuApiRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysMenuApiRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest}
 */
proto.manager_api.ListSysMenuApiRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysMenuApiRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysMenuApiRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysMenuApiRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest} returns this
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest} returns this
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest} returns this
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest} returns this
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysMenuApiRelationRequest} returns this
 */
proto.manager_api.ListSysMenuApiRelationRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUserSchedulerRole.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUserSchedulerRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUserSchedulerRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUserSchedulerRole}
 */
proto.manager_api.SysUserSchedulerRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUserSchedulerRole;
  return proto.manager_api.SysUserSchedulerRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUserSchedulerRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUserSchedulerRole}
 */
proto.manager_api.SysUserSchedulerRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUserSchedulerRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUserSchedulerRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUserSchedulerRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 code = 2;
 * @return {number}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sort = 4;
 * @return {number}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.manager_api.SysUserSchedulerRole.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUserSchedulerRole} returns this
 */
proto.manager_api.SysUserSchedulerRole.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysUserSchedulerRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysUserSchedulerRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysUserSchedulerRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysUserSchedulerRole}
 */
proto.manager_api.ModifySysUserSchedulerRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysUserSchedulerRole;
  return proto.manager_api.ModifySysUserSchedulerRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysUserSchedulerRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysUserSchedulerRole}
 */
proto.manager_api.ModifySysUserSchedulerRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysUserSchedulerRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysUserSchedulerRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysUserSchedulerRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysUserSchedulerRole} returns this
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 code = 2;
 * @return {number}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysUserSchedulerRole} returns this
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUserSchedulerRole} returns this
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sort = 4;
 * @return {number}
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysUserSchedulerRole} returns this
 */
proto.manager_api.ModifySysUserSchedulerRole.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUserSchedulerRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUserSchedulerRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysUserSchedulerRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUserSchedulerRoleRequest}
 */
proto.manager_api.SysUserSchedulerRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUserSchedulerRoleRequest;
  return proto.manager_api.SysUserSchedulerRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUserSchedulerRoleRequest}
 */
proto.manager_api.SysUserSchedulerRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysUserSchedulerRole;
      reader.readMessage(value,proto.manager_api.ModifySysUserSchedulerRole.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUserSchedulerRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUserSchedulerRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysUserSchedulerRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysUserSchedulerRole detail = 1;
 * @return {?proto.manager_api.ModifySysUserSchedulerRole}
 */
proto.manager_api.SysUserSchedulerRoleRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysUserSchedulerRole} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysUserSchedulerRole, 1));
};


/**
 * @param {?proto.manager_api.ModifySysUserSchedulerRole|undefined} value
 * @return {!proto.manager_api.SysUserSchedulerRoleRequest} returns this
*/
proto.manager_api.SysUserSchedulerRoleRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.SysUserSchedulerRoleRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysUserSchedulerRoleRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUserSchedulerRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUserSchedulerRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUserSchedulerRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysUserSchedulerRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUserSchedulerRoleResponse}
 */
proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUserSchedulerRoleResponse;
  return proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUserSchedulerRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUserSchedulerRoleResponse}
 */
proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysUserSchedulerRole;
      reader.readMessage(value,proto.manager_api.SysUserSchedulerRole.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUserSchedulerRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUserSchedulerRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUserSchedulerRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserSchedulerRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysUserSchedulerRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysUserSchedulerRole detail = 1;
 * @return {?proto.manager_api.SysUserSchedulerRole}
 */
proto.manager_api.SysUserSchedulerRoleResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysUserSchedulerRole} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysUserSchedulerRole, 1));
};


/**
 * @param {?proto.manager_api.SysUserSchedulerRole|undefined} value
 * @return {!proto.manager_api.SysUserSchedulerRoleResponse} returns this
*/
proto.manager_api.SysUserSchedulerRoleResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysUserSchedulerRoleResponse} returns this
 */
proto.manager_api.SysUserSchedulerRoleResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysUserSchedulerRoleResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysUserSchedulerRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysUserSchedulerRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysUserSchedulerRole.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleResponse}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysUserSchedulerRoleResponse;
  return proto.manager_api.ListSysUserSchedulerRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysUserSchedulerRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleResponse}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysUserSchedulerRole;
      reader.readMessage(value,proto.manager_api.SysUserSchedulerRole.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysUserSchedulerRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysUserSchedulerRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysUserSchedulerRole.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysUserSchedulerRole list = 1;
 * @return {!Array<!proto.manager_api.SysUserSchedulerRole>}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysUserSchedulerRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysUserSchedulerRole, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysUserSchedulerRole>} value
 * @return {!proto.manager_api.ListSysUserSchedulerRoleResponse} returns this
*/
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysUserSchedulerRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysUserSchedulerRole}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysUserSchedulerRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleResponse} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserSchedulerRoleResponse} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysUserSchedulerRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysUserSchedulerRoleRequest}
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysUserSchedulerRoleRequest;
  return proto.manager_api.GetSysUserSchedulerRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysUserSchedulerRoleRequest}
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysUserSchedulerRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.GetSysUserSchedulerRoleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysUserSchedulerRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysUserSchedulerRoleRequest;
  return proto.manager_api.ListSysUserSchedulerRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysUserSchedulerRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserSchedulerRoleRequest} returns this
 */
proto.manager_api.ListSysUserSchedulerRoleRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysTag.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scene: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creatorId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysTag}
 */
proto.manager_api.SysTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysTag;
  return proto.manager_api.SysTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysTag}
 */
proto.manager_api.SysTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScene(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatorId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScene();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatorId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.manager_api.SysTag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scene = 3;
 * @return {string}
 */
proto.manager_api.SysTag.prototype.getScene = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setScene = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 creator_id = 4;
 * @return {number}
 */
proto.manager_api.SysTag.prototype.getCreatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 sort = 5;
 * @return {number}
 */
proto.manager_api.SysTag.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.manager_api.SysTag.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.manager_api.SysTag.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysTag} returns this
 */
proto.manager_api.SysTag.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysTag.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scene: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creatorId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysTag}
 */
proto.manager_api.ModifySysTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysTag;
  return proto.manager_api.ModifySysTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysTag}
 */
proto.manager_api.ModifySysTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScene(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatorId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScene();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatorId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysTag} returns this
 */
proto.manager_api.ModifySysTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.manager_api.ModifySysTag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysTag} returns this
 */
proto.manager_api.ModifySysTag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scene = 3;
 * @return {string}
 */
proto.manager_api.ModifySysTag.prototype.getScene = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysTag} returns this
 */
proto.manager_api.ModifySysTag.prototype.setScene = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 creator_id = 4;
 * @return {number}
 */
proto.manager_api.ModifySysTag.prototype.getCreatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysTag} returns this
 */
proto.manager_api.ModifySysTag.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 sort = 5;
 * @return {number}
 */
proto.manager_api.ModifySysTag.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysTag} returns this
 */
proto.manager_api.ModifySysTag.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysTag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysTagRequest}
 */
proto.manager_api.SysTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysTagRequest;
  return proto.manager_api.SysTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysTagRequest}
 */
proto.manager_api.SysTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysTag;
      reader.readMessage(value,proto.manager_api.ModifySysTag.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysTag.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysTag detail = 1;
 * @return {?proto.manager_api.ModifySysTag}
 */
proto.manager_api.SysTagRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysTag} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysTag, 1));
};


/**
 * @param {?proto.manager_api.ModifySysTag|undefined} value
 * @return {!proto.manager_api.SysTagRequest} returns this
*/
proto.manager_api.SysTagRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysTagRequest} returns this
 */
proto.manager_api.SysTagRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysTagRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysTag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysTagResponse}
 */
proto.manager_api.SysTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysTagResponse;
  return proto.manager_api.SysTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysTagResponse}
 */
proto.manager_api.SysTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysTag;
      reader.readMessage(value,proto.manager_api.SysTag.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysTag.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysTag detail = 1;
 * @return {?proto.manager_api.SysTag}
 */
proto.manager_api.SysTagResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysTag} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysTag, 1));
};


/**
 * @param {?proto.manager_api.SysTag|undefined} value
 * @return {!proto.manager_api.SysTagResponse} returns this
*/
proto.manager_api.SysTagResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysTagResponse} returns this
 */
proto.manager_api.SysTagResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysTagResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysTagResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysTagResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysTagResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysTagResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysTagResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysTag.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysTagResponse}
 */
proto.manager_api.ListSysTagResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysTagResponse;
  return proto.manager_api.ListSysTagResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysTagResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysTagResponse}
 */
proto.manager_api.ListSysTagResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysTag;
      reader.readMessage(value,proto.manager_api.SysTag.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysTagResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysTagResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysTagResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysTagResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysTag.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysTag list = 1;
 * @return {!Array<!proto.manager_api.SysTag>}
 */
proto.manager_api.ListSysTagResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysTag, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysTag>} value
 * @return {!proto.manager_api.ListSysTagResponse} returns this
*/
proto.manager_api.ListSysTagResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysTag}
 */
proto.manager_api.ListSysTagResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysTagResponse} returns this
 */
proto.manager_api.ListSysTagResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysTagResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysTagResponse} returns this
 */
proto.manager_api.ListSysTagResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysTagRequest}
 */
proto.manager_api.GetSysTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysTagRequest;
  return proto.manager_api.GetSysTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysTagRequest}
 */
proto.manager_api.GetSysTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysTagRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysTagRequest} returns this
 */
proto.manager_api.GetSysTagRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysTagRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysTagRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysTagRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysTagRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysTagRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysTagRequest}
 */
proto.manager_api.ListSysTagRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysTagRequest;
  return proto.manager_api.ListSysTagRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysTagRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysTagRequest}
 */
proto.manager_api.ListSysTagRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysTagRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysTagRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysTagRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysTagRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysTagRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysTagRequest} returns this
 */
proto.manager_api.ListSysTagRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysTagRequest} returns this
 */
proto.manager_api.ListSysTagRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysTagRequest} returns this
 */
proto.manager_api.ListSysTagRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysTagRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysTagRequest} returns this
 */
proto.manager_api.ListSysTagRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysTagRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysTagRequest} returns this
 */
proto.manager_api.ListSysTagRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUser.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    username: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    passwordHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    initialized: jspb.Message.getFieldWithDefault(msg, 11, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUser}
 */
proto.manager_api.SysUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUser;
  return proto.manager_api.SysUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUser}
 */
proto.manager_api.SysUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordHash(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 10:
      var value = /** @type {!proto.common.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {!proto.common.UserPasswordSecurityStatus} */ (reader.readEnum());
      msg.setInitialized(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPasswordHash();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getInitialized();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.SysUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.manager_api.SysUser.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.manager_api.SysUser.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string password_hash = 6;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getPasswordHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setPasswordHash = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string avatar = 9;
 * @return {string}
 */
proto.manager_api.SysUser.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional common.UserStatus status = 10;
 * @return {!proto.common.UserStatus}
 */
proto.manager_api.SysUser.prototype.getStatus = function() {
  return /** @type {!proto.common.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.common.UserStatus} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional common.UserPasswordSecurityStatus initialized = 11;
 * @return {!proto.common.UserPasswordSecurityStatus}
 */
proto.manager_api.SysUser.prototype.getInitialized = function() {
  return /** @type {!proto.common.UserPasswordSecurityStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.common.UserPasswordSecurityStatus} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setInitialized = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int64 group_id = 12;
 * @return {number}
 */
proto.manager_api.SysUser.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.SysUser} returns this
 */
proto.manager_api.SysUser.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ModifySysUser.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ModifySysUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ModifySysUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passwordHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    initialized: jspb.Message.getFieldWithDefault(msg, 9, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ModifySysUser}
 */
proto.manager_api.ModifySysUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ModifySysUser;
  return proto.manager_api.ModifySysUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ModifySysUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ModifySysUser}
 */
proto.manager_api.ModifySysUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordHash(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 8:
      var value = /** @type {!proto.common.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.common.UserPasswordSecurityStatus} */ (reader.readEnum());
      msg.setInitialized(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ModifySysUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ModifySysUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ModifySysUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ModifySysUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPasswordHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getInitialized();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.ModifySysUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password_hash = 4;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getPasswordHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setPasswordHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string avatar = 7;
 * @return {string}
 */
proto.manager_api.ModifySysUser.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.UserStatus status = 8;
 * @return {!proto.common.UserStatus}
 */
proto.manager_api.ModifySysUser.prototype.getStatus = function() {
  return /** @type {!proto.common.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.UserStatus} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional common.UserPasswordSecurityStatus initialized = 9;
 * @return {!proto.common.UserPasswordSecurityStatus}
 */
proto.manager_api.ModifySysUser.prototype.getInitialized = function() {
  return /** @type {!proto.common.UserPasswordSecurityStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.UserPasswordSecurityStatus} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setInitialized = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 group_id = 10;
 * @return {number}
 */
proto.manager_api.ModifySysUser.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ModifySysUser} returns this
 */
proto.manager_api.ModifySysUser.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.ModifySysUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUserRequest}
 */
proto.manager_api.SysUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUserRequest;
  return proto.manager_api.SysUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUserRequest}
 */
proto.manager_api.SysUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.ModifySysUser;
      reader.readMessage(value,proto.manager_api.ModifySysUser.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.ModifySysUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModifySysUser detail = 1;
 * @return {?proto.manager_api.ModifySysUser}
 */
proto.manager_api.SysUserRequest.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.ModifySysUser} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.ModifySysUser, 1));
};


/**
 * @param {?proto.manager_api.ModifySysUser|undefined} value
 * @return {!proto.manager_api.SysUserRequest} returns this
*/
proto.manager_api.SysUserRequest.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysUserRequest} returns this
 */
proto.manager_api.SysUserRequest.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysUserRequest.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.SysUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.SysUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.SysUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detail: (f = msg.getDetail()) && proto.manager_api.SysUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.SysUserResponse}
 */
proto.manager_api.SysUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.SysUserResponse;
  return proto.manager_api.SysUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.SysUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.SysUserResponse}
 */
proto.manager_api.SysUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysUser;
      reader.readMessage(value,proto.manager_api.SysUser.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.SysUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.SysUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.SysUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.SysUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.manager_api.SysUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional SysUser detail = 1;
 * @return {?proto.manager_api.SysUser}
 */
proto.manager_api.SysUserResponse.prototype.getDetail = function() {
  return /** @type{?proto.manager_api.SysUser} */ (
    jspb.Message.getWrapperField(this, proto.manager_api.SysUser, 1));
};


/**
 * @param {?proto.manager_api.SysUser|undefined} value
 * @return {!proto.manager_api.SysUserResponse} returns this
*/
proto.manager_api.SysUserResponse.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.manager_api.SysUserResponse} returns this
 */
proto.manager_api.SysUserResponse.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.manager_api.SysUserResponse.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.manager_api.SysUser.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysUserResponse}
 */
proto.manager_api.ListSysUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysUserResponse;
  return proto.manager_api.ListSysUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysUserResponse}
 */
proto.manager_api.ListSysUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.manager_api.SysUser;
      reader.readMessage(value,proto.manager_api.SysUser.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.manager_api.SysUser.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated SysUser list = 1;
 * @return {!Array<!proto.manager_api.SysUser>}
 */
proto.manager_api.ListSysUserResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.manager_api.SysUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.manager_api.SysUser, 1));
};


/**
 * @param {!Array<!proto.manager_api.SysUser>} value
 * @return {!proto.manager_api.ListSysUserResponse} returns this
*/
proto.manager_api.ListSysUserResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.manager_api.SysUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.manager_api.SysUser}
 */
proto.manager_api.ListSysUserResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.manager_api.SysUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysUserResponse} returns this
 */
proto.manager_api.ListSysUserResponse.prototype.clearListList = function() {
  return this.setListList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.manager_api.ListSysUserResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserResponse} returns this
 */
proto.manager_api.ListSysUserResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.GetSysUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.GetSysUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.GetSysUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.GetSysUserRequest}
 */
proto.manager_api.GetSysUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.GetSysUserRequest;
  return proto.manager_api.GetSysUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.GetSysUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.GetSysUserRequest}
 */
proto.manager_api.GetSysUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.GetSysUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.GetSysUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.GetSysUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.GetSysUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.manager_api.GetSysUserRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.GetSysUserRequest} returns this
 */
proto.manager_api.GetSysUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.manager_api.ListSysUserRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.manager_api.ListSysUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.manager_api.ListSysUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.manager_api.ListSysUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.manager_api.ListSysUserRequest}
 */
proto.manager_api.ListSysUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.manager_api.ListSysUserRequest;
  return proto.manager_api.ListSysUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.manager_api.ListSysUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.manager_api.ListSysUserRequest}
 */
proto.manager_api.ListSysUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.manager_api.ListSysUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.manager_api.ListSysUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.manager_api.ListSysUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.manager_api.ListSysUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int64 ids = 1;
 * @return {!Array<number>}
 */
proto.manager_api.ListSysUserRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.manager_api.ListSysUserRequest} returns this
 */
proto.manager_api.ListSysUserRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.manager_api.ListSysUserRequest} returns this
 */
proto.manager_api.ListSysUserRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.manager_api.ListSysUserRequest} returns this
 */
proto.manager_api.ListSysUserRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.manager_api.ListSysUserRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserRequest} returns this
 */
proto.manager_api.ListSysUserRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_size = 3;
 * @return {number}
 */
proto.manager_api.ListSysUserRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.manager_api.ListSysUserRequest} returns this
 */
proto.manager_api.ListSysUserRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.manager_api.WorkDayType = {
  WORK_DAY_TYPE_UNKNOWN: 0,
  WORK_DAY_TYPE_WORK: 1,
  WORK_DAY_TYPE_REST: 2,
  WORK_DAY_TYPE_LEGAL_REST: 3
};

goog.object.extend(exports, proto.manager_api);
