import React from 'react';
import { Timeline } from 'antd';
import { getTime } from '@/utils/global/getTime';
import './index.less';

interface IProps {
    historyList: any[]
}

/**
 * HistoryRecords
 * @param {IProps} props props
 * @returns {React.ReactNode}
 */
const HistoryRecords: React.FC<IProps> = (props: IProps) => {
    const { historyList } = props;

    let allDates: any = [];
    historyList.forEach((item) => allDates.push(new Date(item.updateAt * 1000).toDateString()));
    allDates = Array.from(new Set(allDates));
    allDates = allDates?.map((date: any) => {
        const filteredDates = historyList.filter((item) => {
            if (new Date(item.updateAt * 1000).toDateString() === date) {
                const timeString = getTime(item.updateAt);
                item.date = `${timeString?.h}:${timeString?.f}:${timeString?.s}`;
                return item;
            }
            return null;
        });
        const dateString = getTime(filteredDates?.[0]?.updateAt);
        date = `${dateString?.y}-${dateString.m}-${dateString.d}`;
        return { list: filteredDates, date };
    });

    return (
        <div className="amps-history-container">
            {
                allDates?.map((item: any, index: number) => (
                    <div className="amps-history-list" key={index}>
                        <div className="amp-history-title">{`${item.date}`}</div>
                        <Timeline mode="left">
                            {item.list?.map((item1: any) => (
                                <Timeline.Item label={item1.date} color={item1.color || '#DBDFE5'} key={item1.id}>
                                    <div className="">{item1?.operationName}</div>
                                    <div className="amp-history-label">
                                        操作人：
                                        {item1?.operatorName}
                                    </div>
                                    {
                                        item1.comment && (
                                            <div className="amp-history-label">
                                                备注：
                                                {item1?.comment}
                                            </div>
                                        )
                                    }
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </div>
                ))
            }
        </div>
    );
};

export default HistoryRecords;
