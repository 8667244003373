import React, { useRef, useEffect } from 'react';
import TopicProgress from '../../../components/topic_progress';
import SceneDetailsDescription from './render/scene_details_description';
import SceneDetailsInfo from './render/scene_details_info';
import { Drawer } from '@components';
import './index.less';

/**
 * 场景详情
 * @param {*} props props
 * @returns {*}
 */
const SceneDetailsContent = (props) => {
    const divRef = useRef();
    const {
        imagesList, // 参考图 []
        comment, // 场景描述 string
        createdAt, // 创建时间 number
        planUserList, // 策划人员 {}
        addressListList, // 地址 []
        groupStatus // 当前节点
    } = props?.details;
    const {
        relatedInfo, // 关联信息 {}
        sceneId, // 选题id
        historyList, // 历史记录
        progressList // 节点数据
    } = props;
    const [nodes, open] = Drawer.useDrawer({
        placement: 'bottom',
        maskClosable: false,
        mask: false,
        height: '367px',
        destroyOnClose: true,
        style: { position: 'absolute' },
        getContainer: () => divRef?.current
    });
    useEffect(() => {
        props?.getChildrenOpen(open);
    }, []);
    return (
        <div className="amps-scene-details-content" ref={divRef}>
            <TopicProgress progressList={progressList} groupStatus={groupStatus} />
            <div className="amp-scene-details-content-main">
                <SceneDetailsDescription
                    imagesList={imagesList}
                    comment={comment}
                    createdAt={createdAt}
                    planUserList={planUserList}
                    addressListList={addressListList}
                />
                <SceneDetailsInfo relatedInfo={relatedInfo} sceneId={sceneId} historyList={historyList} />
                {nodes}
            </div>
        </div>
    );
};

export default SceneDetailsContent;
