import { Avatar, Tooltip } from 'antd';

const currentDate = new Date();
const dayNumber = currentDate.getDay();
const colors = [
  "#6ECCC8",
  "#62A8F8",
  "#F4C659",
  "#F3E774",
  "#885AD7",
  "#FFB7B7",
];

export const ProfileAvatar = (props) => {
  let { name, id, size } = props;
  name = name ? name : "未知"
  const displayName = name.slice(-2).toUpperCase();
  const colorIdx = (id + dayNumber) % colors.length;
  const backgroundColor = colors[colorIdx];
  const fontSize = size ? size < 42 ? (size/2.8) : (size /2.5) : 14
  const avatar = (
    <Avatar
      style={{ backgroundColor, fontSize }}
      size={size ? size : size}
      className="profile-avatar"
    >
    {displayName}
    </Avatar>
  );
  return size < 42 ? (
    <Tooltip title={name} placement="top" key={id}>
      {avatar}
    </Tooltip>
  ) : (
    avatar
  );
};

export const ProfileGroup = (props) => {
  const { people, size } = props;
  const avatars = people?.map((person) => (
    <ProfileAvatar {...person} size={size ? size : 34} key={person.id}/>
  ));
  const count = people.length > 3 ? 2 : 3
  return (
    <Avatar.Group
      maxCount={count}
      maxStyle={{
        color: "#2B364F",
        backgroundColor: "#EAECEE",
        fontSize: "14px",
      }}
      size={size ? size : 34}
      maxPopoverPlacement="bottom"
    >
      {avatars}
    </Avatar.Group>
  );
};
