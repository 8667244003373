import { Skeleton, Tooltip } from 'antd';
import { Button, Typography } from '@components';
import { useHistory, useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { clsx } from '@gUtils';

/**
 * @name: RenderListHeader.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 列表的Header
 */
const RenderListHeader = function(props) {
    const statusTotal = props.res?.statusTotal
    const history = useHistory()
    const {pathname} = useLocation()
    const {url} = useRouteMatch()
    const gotoAddUrl = `${url}/add`
    function gotoAdd(){
        history.push(gotoAddUrl)
    }
    if (statusTotal) {
        return <div className={'list-content-header'}>
            <div className={'list-content-header-text'}>
                <Typography className={'list-content-header-title'}>策划 {statusTotal.reduce((acc, i) => acc + i.count, 0)}</Typography>
                <div className={'list-content-header-tooltip-list'}>
                    {statusTotal?.map((item) => <Tooltip title={`${item.label} ${item.count}`} key={item.status}>
                        <Typography className={'list-content-header-tooltip-item'}><span style={{ backgroundColor: item.color }} /> {item.count}
                        </Typography>
                    </Tooltip>)}
                </div>
            </div>
            <Button onClick={gotoAdd} className={clsx('list-content-header-btn', pathname === gotoAddUrl&& 'list-content-header-btn-action')}><Typography color={'primary'} align={'center'}>+ 任务包</Typography></Button>
        </div>;
    } else {
        return <div className={'list-content-header'}>
            <Skeleton.Input active />
            <Skeleton.Input style={{marginTop: 10}}  active />
        </div> ;
    }

};
export default RenderListHeader;
