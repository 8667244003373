/**
 * @name: PlanFormItem.jsx
 * @user: cfj
 * @date: 2022/3/23
 * @description: 策划组员选择
 */
import { Select } from '@components';
import projectSettingsAPI from '../../../api/5_settings/project_settings_api';
import { Avatar, Col, Grid, Row } from 'antd';

const { useBreakpoint } = Grid;


export const Avatars = function({users}){
    const colors = [
        "#6ECCC8",
        "#62A8F8",
        "#F4C659",
        "#F3E774",
        "#885AD7",
        "#FFB7B7",
    ];
    return  <Avatar.Group maxPopoverPlacement="bottom" maxCount={8} size={48}>
        {users.map(item => {
                const bgColor = colors[Number(item.value)%colors.length]
                return <Avatar style={{backgroundColor: bgColor}} key={item.value}>{item.label.slice(-2).toUpperCase()}</Avatar>
            })}
    </Avatar.Group>
}

const PlanFormItem = function(props) {
    const screens = useBreakpoint();
    const feature = () => projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } })
        .then(res => {
            if (res.code === 1000) {
                return res.data?.list.map(user => ({
                    value: user.user_id,
                    label: user.name
                }));
            }
            throw new Error('数据错误');
        });
    const className = screens.sm?'col-sm':'col-xs'
    return <Row gutter={40}>
        <Select
            {...props}
            className={className}
            mode={'multiple'}
            placeholder='添加人员'
            showSearch
            showArrow
            maxTagCount={4}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            setKey={() => ['/user-relation/list']}
            feature={feature}
        >
            {({
                data,
                value
            }) => {
                if (data?.length && value) {
                    const users = value.map(id => data.find(i => i.value === id))
                    return <Col sm={12} xs={24}>
                        <Avatars users={users} />
                    </Col>
                }
                return null
            }}
        </Select>
    </Row> ;
};
export default PlanFormItem;
