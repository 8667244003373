import { Form, Input } from "antd";
import {RangePicker} from '@/components'

export const TripDate = () => (
  <Form.Item
    className="table-form-item"
    name="trip_time"
    rules={[{ required: true }]}
  >
    <RangePicker
      placeholder={["选择日期"]}
      format="YYYY/MM/DD"
    />
  </Form.Item>
);

export const TripDestination = () => {
  return (
    <Form.Item
      className="table-form-item"
      name="trip_destination"
      rules={[{ required: true }]}
    >
      <Input placeholder="请输入" className="input-sz-xs" />
    </Form.Item>
  );
};

