import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SideBar, Profile, Permissions, Notifications, Activities, Messages } from ".";
import { BlankPage } from "@/components";
import { connect } from "react-redux";
import { fetchProfile } from "@actions/1_entities/account_actions";

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
    fetchProfile: (cb) => dispatch(fetchProfile(cb)),
});

class Account extends Component {
    componentDidMount() {
        this.props.fetchProfile();
    }
    render() {
        return (
            <div className="full-content display-flex">
                <SideBar />
                <div className="side-content">
                    <Switch>
                        <Redirect exact path="/account" to="/account/profile" />
                        <Route exact path="/account/profile" component={Profile} />
                        <Route exact path="/account/permissions" component={Permissions} />
                        <Route
                            exact
                            path="/account/notifications"
                            component={Notifications}
                        />
                        <Route exact path="/account/activities" component={Activities} />
                        <Route exact path="/account/messages" component={Messages} />
                        <Route component={BlankPage} />
                    </Switch>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
