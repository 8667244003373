import React, { memo } from 'react'
import { useSwr } from '@hooks';
import { productionStatsAPI } from '@api'

const taskList = [
    {
        label: '策划任务',
        key: 'task_package_item_count',
        id: 1
    },
    {
        label: '采集任务',
        key: 'task_shooting_collection_count',
        id: 2
    },
    {
        label: '解析任务',
        key: 'task_analysing_count',
        id: 3
    },
    {
        label: '制作任务',
        key: 'task_maked_count',
        id: 4
    },
    {
        label: '工程文件',
        key: 'task_file_count',
        id: 5
    },
    {
        label: '待入库',
        key: 'task_wait_for_storage',
        id: 6
    }
]
/**
 * 工作实时进度统计-总览
 * @returns {JSX.Element}
 */
function Pandect(props) {

    // 获取实时进度总览数据
    const getOverview = () => {
        return productionStatsAPI.getOverviewRealTime().then(res => {
            if (res.code === 1000) {
                return res.data
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: taskInfo } = useSwr('/api/real-time/overview', getOverview)

    return (
        <div className="amp-mb-32">
            <div className="amp-work-progress-title">
                <div className="amp-work-progress-title-icon"></div>
                <span>总览</span>
            </div>
            <div className="amp-flex-row-start-center">
                <div className="amp-work-card">
                    <div className="amp-fs-16 amp-mb-16">制作中的资产</div>
                    <div className="amp-work-main-font">{taskInfo?.task_making_count}</div>
                </div>
                <div className="amp-work-card">
                    <div className="amp-fs-16 amp-mb-16">进行中的项目</div>
                    <div className="amp-work-main-font">{taskInfo?.project_underway}</div>
                </div>
            </div>
            <div className="amp-flex-row-start-center">
                {
                    taskList.map(item => (
                        <div className="amp-task-num-card" key={item.id}>
                            <div className="amp-fs-16 amp-mb-16 amp-c-e25">{item.label}</div>
                            <div className="amp-minh-24 amp-fs-24">{taskInfo?.[item.key] || ''}</div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default memo(Pandect)
