/**
 * @name: routes.js
 * @user: cfj
 * @date: 2022/4/18
 * @description:
 */
import { generatePath, matchPath as matchPath1, Redirect, useLocation, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { resolve } from 'path';
import { createContext, createElement, Suspense, useContext, useEffect } from 'react';
import { useAccess } from '@hooks';
import { Error, Error403, Error404, Loading } from '@components';
import { accessMatch } from '@gUtils';
import createErrorBoundaries from '@/HOC/createErrorBoundaries';

const ErrorBoundaries = createErrorBoundaries();

const OutletContext = createContext(null);
const ParentRouteContext = createContext(null);

const OutletProvider = OutletContext.Provider;
const ParentRouteProvider = ParentRouteContext.Provider;
const rootRouteMap = new Map();
// eslint-disable-next-line require-jsdoc
export const useOutlet = function () {
    return useContext(OutletContext);
};
// eslint-disable-next-line require-jsdoc
export const useParentRoute = function () {
    return useContext(ParentRouteContext);
};

/**
 * 由于是分模块升级所以每次替换一个模块时都要注册
 * @param {route[]} routes 路由配置项目
 * @param {string} parentName 父级节点的name
 * @param {string} parentPath 父级节点的路径
 */
export const registerRouterConfig = (routes, parentName, parentPath) => {
    const _path = rootRouteMap.get(parentName)?.path || parentPath;

    if (rootRouteMap.has(parentName) && !rootRouteMap.get(parentName)?.routes) {
        rootRouteMap.get(parentName).routes = routes;
    }
    routes.forEach((route) => {
        route.path = resolve(_path, route.index ? './' : route.path);
        // TODO 待优化下一级路由无法匹配404
        route.exact = route.routes?.length ? false : route.index || route.exact;
        // route.exact = !route.routes?.length;
        route._parentName = parentName;
        if (route.name && !rootRouteMap.has(route.name)) {
            rootRouteMap.set(route.name, route);
        }
        if (route.name && rootRouteMap.has(route.name) && route.routes?.length) {
            registerRouterConfig(route.routes, route.name);
        }
    });
};

/**
 * 拼接路由跳转的url
 * @param {string} name route.name
 * @param {object} params 动态路由的参数数
 * @returns {string}
 */
export const resolveUrl = (name, params) => {
    if (rootRouteMap.has(name)) {
        const { path } = rootRouteMap.get(name);
        return generatePath(path, params);
    }
    throw new Error('路由不存在');
};
/**
 * 路由匹配方法
 * @param {string} name route.name
 * @returns {boolean}
 */
export const matchPath = (name) => {
    if (rootRouteMap.has(name)) {
        const { path, exact } = rootRouteMap.get(name);
        return !!matchPath1(window.location.pathname, {
            path,
            exact
        });
    }
    return false;
};
/**
 * 获取路由配置项目
 * @param {string} name route.name
 * @returns {null|unknown}
 */
export const getRoute = (name) => {
    if (rootRouteMap.has(name)) {
        return rootRouteMap.get(name);
    }
    return null;
};

// eslint-disable-next-line require-jsdoc
export function getRouteAuth(route, auths) {
    if (route?.access) {
        return accessMatch(auths, route.access, route.whereType);
    }
    if (route?.routes) {
        return route.routes.some((ri) => {
            if (ri.redirect) {
                return false;
            }
            return getRouteAuth(ri, auths);
        });
    }
    return true;
}

// eslint-disable-next-line require-jsdoc
function filterRoutes(routes, auths) {
    if (routes?.length > 0) {
        return routes.map((route) => {
            const childrenRoutes = filterRoutes(route.routes, auths);
            return {
                ...route,
                isAuto: getRouteAuth(route, auths),
                routes: childrenRoutes.length ? childrenRoutes : undefined
            };
        }).filter((route) => {
            if (route.redirect || route?.hideMenu) {
                return false;
            }
            return route.isAuto;
        });
    }

    return [];
}
// 获取有权限路由 用在渲染导航
// eslint-disable-next-line require-jsdoc
export const useGetAuthRoutes = () => {
    const auths = useAccess(null, { suspense: true });
    const route = useParentRoute();
    return filterRoutes(route.routes, auths);
};

// eslint-disable-next-line require-jsdoc
function sortRoute(routes) {
    return routes
        .map((route) => {
            let sort = 0;
            if (route.index || route.path === '/') {
                sort = 0;
            } else if (route.path === '*') {
                sort = -1;
            } else if (route.path) {
                const pathSplit = route.path.split('/');
                if (!/\*/.test(route.path) && !/(^:.+)|(\/:.+)/.test(route.path)) {
                    sort = pathSplit.length ** 3;
                }
                if (/(^:.+)|(\/:.+)/.test(route.path)) {
                    sort = pathSplit.findIndex((str) => str.includes(':')) ** 2;
                }
                if (/\*/.test(route.path)) {
                    sort = pathSplit.findIndex((str) => str.includes('*')) ** 1;
                }
            } else {
                sort = -2;
            }
            route.sort = sort;
            return route;
        })
        .sort((a, b) => b.sort - a.sort);
}
// TODO 1. route.exact 匹配问题由于可能存在模块嵌套现在存在无法匹配到下一级的404路由
/**
 * 系统路由
 * @param  {route[]} routes 路由项数组
 * @param {object} options 路由配置项目
 * @param {Location} options.location 浏览器Location对象
 * @param {ReactNode} [options.loadingFallback = null] Suspense 组件的fallback
 * @param {ReactNode} [options.error403Fallback = null] 没有权限渲染组件
 * @param {ReactNode} [options.error404Fallback = null] 没有404渲染组件
 * @param {ReactNode} [options.errorFallback = null] 组件加载错误渲染
 * @param {boolean} [options.disabledDefault404 = false] 关闭同一级路由404路由配置
 * @param {boolean} [options.disabledLinkTitle = false] 关闭自动设置浏览器title 功能
 * @returns {JSX.Element}
 */
export function useRoutes(routes, options) {
    const { path: _path } = useRouteMatch();
    const isFillAll = routes.find((i) => i.path === '*');
    const _parent = useParentRoute();
    if (_parent && !_parent.routes) {
        _parent.routes = routes;
    }
    const sortRoutes = sortRoute(routes).map((route) => {
        route.path = resolve(_path, route.index ? './' : route.path);
        route.exact = route.routes?.length ? false : route.index || route.exact;
        route._parentName = _parent?.name;
        // route.exact = !route.routes?.length;
        return route;
    });
    const disabledDefault404 = (options?.disabledDefault404 || _parent?.disabledDefault404);
    return (
        <Switch>
            {sortRoutes.map((route) => (
                <RenderRoute key={route.path} exact={route.exact} path={route.path} options={options} route={route} />
            ))}
            {!isFillAll && !disabledDefault404 && <Route path="*">{options?.error404Fallback || <Error404 />}</Route>}
        </Switch>
    );
}

// eslint-disable-next-line require-jsdoc
function RenderRoutes(props) {
    const { routes, options } = props;
    const location = useLocation();
    return useRoutes(routes, {
        ...options,
        location
    });
}

// eslint-disable-next-line require-jsdoc
function RenderAccess(props) {
    const { access, whereType, children, error403Fallback } = props;
    const isAccess = useAccess(access, {
        whereType,
        suspense: true
    });
    if (isAccess) {
        return children;
    }
    return error403Fallback;
}
// 重定向组件的拦截
// eslint-disable-next-line require-jsdoc
function createRedirectWrapperTree(wrappers, element) {
    if (wrappers?.length) {
        return wrappers.reduceRight((el, item) => createElement(item, {}, el), element);
    }
    return element;
}
// eslint-disable-next-line require-jsdoc
function createWrapperTree(wrappers, element) {
    if (wrappers?.length) {
        return wrappers.reduceRight((e, item) => createElement(item, {}, e), element);
    }
    return element;
}

// eslint-disable-next-line require-jsdoc
const RenderComponent = function (props) {
    const { route, options } = props;
    const { redirect, component, wrappers, access } = route;
    const { loadingFallback = <Loading />, errorFallback = <Error />, error403Fallback = <Error403 /> } = options || {};
    const { path: _path } = useRouteMatch();

    if (redirect) {
        const path = rootRouteMap.get(route.redirect)?.path;
        const resolvePath = path || resolve(_path, redirect);
        return createRedirectWrapperTree(wrappers, <Redirect to={resolvePath} />);
    }

    const Outlet = route.routes?.length ? <RenderRoutes routes={route.routes} options={options} /> : null;
    const fallback = component?.fallback || loadingFallback;
    const renderFallback = component?.errorFallback || errorFallback;
    return (
        <OutletProvider value={Outlet}>
            <ErrorBoundaries errorFallback={renderFallback}>
                <Suspense fallback={fallback}>
                    {createWrapperTree(wrappers, access ? (
                        <RenderAccess access={access} whereType={route.whereType} error403Fallback={error403Fallback}>
                            {createElement(component)}
                        </RenderAccess>
                    ) : (
                        createElement(component)
                    ))}
                </Suspense>
            </ErrorBoundaries>
        </OutletProvider>
    );
};

// eslint-disable-next-line require-jsdoc
const RenderRoute = function (props) {
    const { path, exact, options, route } = props;
    useEffect(() => {
        if (route.title && !options.disabledLinkTitle) {
            document.title = route.title;
        }
    }, [route.title]);
    return (
        <Route path={path} exact={exact}>
            <ParentRouteProvider value={route}>
                <RenderComponent path={path} exact={exact} options={options} route={route} />
            </ParentRouteProvider>
        </Route>
    );
};
window.rootRouteMap = rootRouteMap;
