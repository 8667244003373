import React, { Component } from 'react';
import { AddEditProject, RemoveProject } from '../../../dialogs';
import { ConfirmDialog, Iconfont } from '@/components';
import FilterSection from './filter_section';
import { emptyProject } from '@vars/views/projects_vars';
import { withRouter } from 'react-router';
import { RightPart, StatusIcon } from './renders';
import { inject, observer } from 'mobx-react';

@withRouter
@inject("taskStore", "projectStore", "taskPackageStore")
@observer
class TitleSection extends Component {
    editRef = React.createRef();
    removeRef = React.createRef();
    finishRef = React.createRef();
    assetDrawerRef = React.createRef();
    get store() {
        return this.props.taskStore;
    }
    get id() {
        return this.props.match.params.id;
    }
    get currentProject() {
        return this.props.projects[this.id] || emptyProject;
    }

    componentDidMount() {
        this.store.updateRefresh(false);
        const temp = this.renderByPermission(this.props.userPermissions);
        let type = null;
        switch (temp) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
                type = 2;
                break;
            case 6:
                type = 1;
                break;
            default:
                break;
        }
        if (type !== null) {
            this.props.projectStore.getProduceListTotal(type);
        }
    }

    componentWillUnmount () {
        this.props.projectStore.setShowTotal(0);
    }

    handleMenuClick = ({ key }) => {
        switch (key) {
            case "edit":
                this.editRef.current.show("edit");
                break;
            case "finish":
                this.finishRef.current.show(
                    this.currentProject,
                    "项目",
                    this.currentProject.title,
                    "结项"
                );
                break;
            case "remove":
                this.removeRef.current.show();
                break;
            default:
                break;
        }
    };

    showShootingDrawer = () => {
        this.props.history.push(
            `/projects/${this.id}?shootingPlanId=${this.currentProject.shooting_plan_id}`
        );
    };

    renderByPermission = (list) => {
        const proSetting = list.indexOf("produce_setting") > -1;
        const proAllot = list.indexOf("produce_allot") > -1;
        const proTake = list.indexOf("produce_take") > -1;
        let res;
        if (proAllot) {
            if (proTake && proSetting) {
                res = 1;
            } else if (proTake) {
                res = 2;
            } else if (proSetting) {
                res = 3;
            } else {
                res = 5;
            }
        } else {
            if (proTake && proSetting) {
                res = 4;
            } else if (proTake) {
                res = 6;
            } else if (proSetting) {
                res = 7;
            }
        }
        // this.props.projectStore.setRenderPage(res);
        return res;
    }


    showAssetDrawer = () => {
        this.store.setAssetCharacter(0);
        this.store.loading = false;
        this.props.history.push(`/projects/${this.id}?task_add?taskId=0`);
        this.store.associatedTaskID = "";
    };

    showGroupDrawer = () => {
        this.props.history.push(`/projects/${this.id}?task-groups`);
    };


    refreshPage = () => {
        window.location.reload();
    };

    renderLeft = () => {
        const currentProject = this.currentProject;
        const statusCode = currentProject?.project_status?.code;

        return (
            <div className="project-title-left">
                <div className="mg-r-10 mg-t-3">
                    <Iconfont
                        className="icon-20 "
                        type="icon-back"
                        onClick={() => this.props.history.push("/projects")}
                    />
                </div>
                <h3 className="mg-r-18">{this.props.currentProject?.title}</h3>
                <div className="display-flex align-center">
                    <div className="mg-r-5 mg-t-4">
                        <StatusIcon statusCode={statusCode} />
                    </div>
                    <span>{this.props.currentProject?.project_status?.label}</span>
                    <div>
                        {this.store.shouldRefresh ? (
                            <div className="align-center">
                                <Iconfont
                                    className="icon-20 mg-l-20 mg-r-5"
                                    type="icon-refresh"
                                    onClick={this.refreshPage}
                                />
                                <span>看板内容已更新 请刷新页面</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    };
    render() {
        const currentProject = this.currentProject;
        const { viewType } = this.props
        const statusCode = currentProject?.project_status?.code;
        const { produceListTotal, produceListOpenTotal, showTotal, renderPage: temp } = this.props.projectStore;
        const props = { currentProject, statusCode, ...this.props, ...this, produceListTotal, produceListOpenTotal, showTotal };
        return (
            <div>
                <AddEditProject ref={this.editRef} {...props} />
                <ConfirmDialog
                    ref={this.finishRef}
                    storeRequest={props.completeProject}
                />
                <RemoveProject ref={this.removeRef} {...props} />
                <div className="project-title">
                    {this.renderLeft()}
                    <RightPart {...props} />
                </div>
                {
                    viewType === 'card' && <div className="project-sub-title">
                        <FilterSection {...props} />
                    </div>
                }
            </div>
        );
    }
}

export default TitleSection;
