import { Button, Form, Popconfirm, Space } from 'antd';
import { createContext, useContext, useState } from 'react';
import { AmpIcon, DatePicker, Input, Select } from '@components';
import { useSwr } from '@hooks';
import moment from 'moment';

/**
 * @name: tableComponents.jsx
 * @user: cfj
 * @date: 2022/3/23
 * @description: 编辑表格的子组件
 */
export function HeaderRow(props) {
    const {
        value,
        onChange,
        index,
        ...other
    } = props;
    const [form] = Form.useForm();
    return <Form
        layout={'horizontal'}
        form={form}
        onFinish={(values) => {
            if (value?.[value.length - 1]) {
                const id = value[value.length - 1].id + 1;
                onChange([].concat(value || [], {
                    ...values,
                    id
                }));
            } else {
                onChange([
                    {
                        ...values,
                        id: 0
                    }]);
            }
            form.resetFields();
        }}
        component={false}
    >
        <tr {...other} />
    </Form>;

}

export function HeaderTableCell(props) {
    const {
        dataIndex,
        valueType,
        fieldProps,
        key,
        formItemProps,
        setKey,
        request,
        renderHeader,
        options,
        ...other
    } = props;
    const {
        data,
        isValidating
    } = useSwr((setKey && request) ? setKey : null, request, {
        revalidateIfStale: false
    });
    const mergeFieldProps = Object.assign({}, valueType === 'select' && {
        options: options || data || [],
        loading: data?.length?false:isValidating
    }, fieldProps);
    if (valueType) {
        if (renderHeader) {
            return <th {...other}>{renderHeader()}</th>;
        } else {
            return <th {...other}>
                <RenderFormItem
                    name={key}
                    isEdit={true}
                    formItemProps={formItemProps}
                    dataIndex={dataIndex}
                    valueType={valueType}
                    fieldProps={mergeFieldProps} />
            </th>;
        }

    }
    return <th {...other} />;
}

export const EditRowFormContext = createContext(null);

export function BodyRow(props) {
    const {
        initialValues,
        onChange,
        index,
        children,
        edit,
        del,
        ...other
    } = props;
    const [isEdit, setEdit] = useState(false);
    const { Provider } = EditRowFormContext;
    const [form] = Form.useForm();
    return <Form
        layout={'horizontal'}
        form={form}
        initialValues={initialValues}
        component={false}
        onFinish={(value) => {
            edit({
                ...value,
                id: initialValues?.id
            });
            setEdit(false);
        }}

    >
        <Provider value={{
            isEdit,
            edit() {
                setEdit(true);
            },
            del() {
                del(initialValues.id);
                setEdit(false);
            },
            cancel() {
                setEdit(false);
            },
            save() {
                form.submit();
            }
        }}>
            <tr {...other}>
                {children}
            </tr>
        </Provider>
    </Form>;
}

export function BodyCell(props) {
    const {
        record,
        index,
        dataIndex,
        valueType,
        fieldProps,
        key,
        formItemProps,
        setKey,
        request,
        renderHeader,
        renderText,
        ...other
    } = props;
    const {
        data,
        isValidating
    } = useSwr((setKey && request) ? setKey : null, request, {
        revalidateIfStale: false
    });
    const mergeFieldProps = Object.assign({}, valueType === 'select' && {
        options: data,
        loading: data?.length?false:isValidating
    }, fieldProps);
    const {
        isEdit,
        save,
        cancel,
        edit,
        del
    } = useContext(EditRowFormContext);
    if (valueType) {
        if (isEdit) {
            if (valueType === 'option') {
                return <td {...other}>
                    <Space>
                        <Button type={'link'} onClick={save}>保存</Button>
                        <Button type={'link'} onClick={cancel}>取消</Button>
                    </Space>
                </td>;
            }
        } else {
            if (valueType === 'option') {
                return <td {...other}>
                    <Space>
                        <AmpIcon onClick={edit} name={'edit'} />
                        <Popconfirm
                            title='确认删除吗?'
                            onConfirm={del}
                            okText='确定'
                            cancelText='取消'
                        >
                            <AmpIcon name={'del'} />
                        </Popconfirm>
                    </Space>
                </td>;
            }
        }
        return <td {...other}><RenderFormItem
            value={record?.[dataIndex]}
            renderText={renderText}
            name={key}
            isEdit={isEdit}
            formItemProps={formItemProps}
            dataIndex={dataIndex}
            valueType={valueType}
            fieldProps={mergeFieldProps} />
        </td>;
    } else {
        return <td {...other} />;
    }
}

export function RenderFormItem(props) {
    const {
        valueType = 'text',
        dataIndex,
        name,
        fieldProps,
        formItemProps,
        renderText,
        isEdit,
        value
    } = props;
    if (valueType === 'select') {
        if (isEdit) {
            return <Form.Item name={name || dataIndex} {...formItemProps}>
                <Select {...fieldProps} />
            </Form.Item>;
        } else {
            return renderText?.({
                value,
                valueType,
                options
            }) || fieldProps.options?.find(item => item.value === value)?.label || null;
        }

    } else if (valueType === 'rangePicker') {
        if (isEdit) {
            return <Form.Item name={name || dataIndex} {...formItemProps}>
                <DatePicker.RangePicker {...fieldProps} />
            </Form.Item>;
        } else {
            return renderText?.({
                value,
                valueType
            }) || value?.map(data => moment(data)
                .format('YYYY-MM-DD'))
                .join(' - ') || null;
        }

    } else {
        if (isEdit) {
            return <Form.Item name={name || dataIndex} {...formItemProps}>
                <Input {...fieldProps} />
            </Form.Item>;
        } else {
            return renderText?.({
                value,
                valueType
            }) || value || null;
        }
    }
}
