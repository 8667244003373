const announcementStatuses = {
    0: '草稿',
    1: '已发布'
};

export const announcementColumns = [
    {
        title: '公告ID',
        dataIndex: 'id',
        width: '10%'
    },
    {
        title: '公告名称',
        dataIndex: 'title',
        width: '35%',
        ellipsis: true
    },
    {
        title: '发布时间',
        dataIndex: 'published_at',
        render: (time) => (time ? time : '-'),
        width: '18%'
    },
    {
        title: '作者',
        dataIndex: 'author',
        width: '10%'
    },
    {
        title: '状态',
        dataIndex: 'status',
        render: (code) => announcementStatuses[code],
        width: '10%'
    }
];

export const monthEnum = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    june: 6,
    july: 7,
    aug: 8,
    sept: 9,
    oct: 10,
    nov: 11,
    dec: 12
};
