import { InputNumber as IN } from 'antd';
import { clsx } from '@gUtils';
import './index.less';

const InputNumber = function(props) {
    const {
        className,
        ...other
    } = props;
    return <IN size={'large'}
               className={clsx('amp-fixed-input', className)} {...other} />;
};

export default InputNumber;
