import React, { useState, useMemo, useEffect } from 'react'
import { Form, Button } from 'antd';
import AddTopicFormItem from './template/add_topic_form_item';
import { useTopicContext } from './index'
import { useSend, useSwr } from '@hooks';
import { $error, $success } from '@components';
import { useParams } from 'react-router-dom';
import SelectedTopicApi from "@/api/grpcApi/seleceed_topic/selected_topic_api"
import { projectSettingsAPI } from '@api'
import { useHistory } from 'react-router';
import { topicEventBus } from '@/event_bus'
import { useAccess } from '@hooks'

const AddTopic = (props) => {
    const { parentUrl } = props
    const history = useHistory();
    const { topicId } = useParams()
    const [form] = Form.useForm()
    const { closeModal, setTitle } = useTopicContext()
    const isTopicCreate = useAccess('topic_create') // 创建选题权限
    const isTopicUpdate = useAccess('topic_update') // 编辑选题权限

    const disabled = useMemo(() => {
        if (topicId) {
            return !isTopicUpdate
        } else {
            return !isTopicCreate
        }
    }, [isTopicCreate, isTopicUpdate, topicId])

    useEffect(() => {
        if (topicId) {
            setTitle("编辑选题")
        } else {
            setTitle("创建选题")
        }
    }, [topicId])

    // 获取配置信息 选题-大纲和场景
    const { data: configInfo = {} } = useSwr('getTopicSceneAndOutline', () =>
        SelectedTopicApi().getTopicSceneAndOutline().then((res) => {
            return res
        }).catch((err) => {
            throw new Error(err.message);
        })
    )

    // 选题-大纲数据格式化
    const { outlineOptions, themeOptions } = useMemo(() => {
        const outlineList = configInfo?.outlineListList?.map(item => ({
            value: item.id,
            label: item.name
        }))
        const themeList = configInfo?.sceneListList
        return {
            outlineOptions: outlineList,
            themeOptions: themeList
        }
    }, [configInfo])

    // 获取策划人员
    const { data: userList = [] } = useSwr('/user-relation/list', () =>
        projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } }).then((res) => {
            if (res.code === 1000) {
                return res.data.list.map(item => {
                    return {
                        value: item.user_id,
                        label: item.name
                    }
                })
            }
        })
    )

    // 获取选题编辑信息
    const { data: topicData, mutate: updateTopic } = useSwr(topicId ? ['getTopicInfo', topicId] : null, (url, id) =>
        SelectedTopicApi().getTopicInfo({ id }).then((res) => {
            const obj = {
                name: res.name,
                type: res.type,
                imagesList: res?.imagesList?.map((item, index) => ({
                    ...item,
                    file_path: item.filePath,
                    file_url: item.fileUrl,
                    review_image_type: item.useType === 1 ? 'main' : '',
                    uid: `${item.fileUid}-${index}`,
                    status: 'done'
                })),
                detail: res.detail,
                planUserId: res?.planUserList.id,
                outlineId: res?.outlineList.id,
                scenesList: res.sceneListList,
                addressList: res?.addressListList?.map(item => ({
                    ...item,
                    adcode: item.adCode, //区域编码
                    typecode: item.typeCode
                }))
            }
            return obj
        })
        , {
            suspense: true,
        })

    // 提交表单，创建，编辑
    const [loading, submission] = useSend((data) => {
        const params = {
            ...data,
            imagesList: data?.imagesList.map(item => {
                return {
                    filePath: item.file_path,
                    fileUrl: item.file_url,
                    fileUid: item.uid,
                    useType: item.review_image_type === 'main' ? 1 : 0
                }
            }),
            addressList: data?.addressList.map(item => {
                return {
                    ...item,
                    ad_code: item.adcode, //区域编码
                    type_code: item.typecode,// POI类型编码
                }
            })
        }
        // 编辑选题
        if (topicId) {
            params.id = topicId
            return SelectedTopicApi().updateTopic(params)
        } else {
            return SelectedTopicApi().createTopic(params)
        }
    }, {
        onSuccess(res) {
            if (topicId) {
                $success('编辑成功')
            } else {
                $success('创建成功')
            }
            updateTopic()
            history.replace(parentUrl);
            topicEventBus.emit('topicEventBus')
        },
        onError(error) {
            $error(error.message);
        }
    })

    return (
        <>
            <div className="amp-create-topic-add">
                <Form
                    form={form}
                    layout='vertical'
                    size="large"
                    className="amp-create-topic-form"
                    onFinish={submission}
                    preserve={false}
                    initialValues={topicData ? { ...topicData } : {}}
                >
                    <AddTopicFormItem
                        disabled={disabled}
                        outlineOptions={outlineOptions}
                        themeOptions={themeOptions}
                        userList={userList}
                    />
                </Form>
            </div>
            <div className="amp-create-topic-footer">
                <Button
                    className="mg-r-16"
                    type="primary"
                    loading={loading}
                    disabled={disabled}
                    onClick={form.submit}
                >
                    确认
                </Button>
                <Button
                    type=""
                    onClick={closeModal}
                >
                    取消
                </Button>
            </div>
        </>
    )
}

export default AddTopic
