import axios from 'axios'
import { Server } from '@gUtils';

const analyticalStatsAPI = {
    /**
     * 解析统计周月总览
     * @param {*} params
     * @returns {Object} data
     */
    getAnalyticalSummary(params) {
        return axios.post("/api/task-performance/summary", params);
    },

    /**
     * 解析统计详情
     * @param {*} params
     * @returns {Object} data
     */
    getAnalyticalDetails(params) {
        return axios.post("/api/task-performance/list", params);
    },

    /**
     * 解析统计 详情表格导出
     * @param {*} params
     * @returns {Object} data
     */
    getAnalyticalDetailExport(params) {
        return Server.post("/api/task-performance-detail/export", params);
    },

    /**
     * 解析统计 周月总览表格导出
     * @param {*} params
     * @returns {Object} data
     */
    getAnalyticalSummaryExport(params) {
        return Server.post("/api/task-performance-summary/export", params);
    },
};

export default analyticalStatsAPI
