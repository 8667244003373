export const tripColumns = [
    {
      title: "日期",
      dataIndex: "start_time",
      width: "24%",
      render: (t,r) =>  `${r.start_time.replace(/-/g, "/")} - ${r.end_time.replace(/-/g, "/")} `
    },
    {
      title: "行程类型",
      dataIndex: "trip_type",
      render: (val) => val.label,
      width: "17%",
    },
    {
      title: "目的地",
      dataIndex: "destination",
      width: "17%",
    },
    {
      title: "交通方式",
      dataIndex: "transportation",
      render: (val) => val.label,
      width: "17%",
    },
    {
      title: "行程说明",
      dataIndex: "comment",
      width: "25%",
    },
  ];
