import {dashboardAPI} from "@api";
import { $error } from "@/components";

export const fetchStatistics = (success = () => {}, error = () => {}) => (
  dispatch
) =>
  dashboardAPI.fetchStatistics().then((res) => {
    if (res.code === 1000) {
      success();
      return res.data;
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
    }
  });
