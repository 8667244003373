import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { sessionReducer } from "./0_session";
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import {errorsReducer, paginationReducer, entitiesReducer, configsReducer} from '.'
import { LOGOUT_CURRENT_USER } from '@actions/action_consts';
// 解决嵌套的数据持久化黑名单问题
const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["session"],
  stateReconciler: hardSet,
};

const sessionPersistConfig = {
  key: "session",
  storage: storage,
  blacklist: ["initial_session"],
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  errors: errorsReducer,
  configs: configsReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer),
  pagination: paginationReducer,
  entities: entitiesReducer,
});
// 重置嵌套的数据持久化，只保留state中的key‘_persist’
const resetState = (state) => {
    if(Object.prototype.toString.call(state) !== "[object Object]") return state
    const newState = {}
    for(let key in state){
        if(key === '_persist'){
            newState[key] = state[key]
        }else if(state[key].hasOwnProperty('_persist')){
            newState[key] = {
                _persist: state[key]['_persist']
            }
        } else {
            newState[key] = undefined
        }
    }
    return newState
}

const resetReducer = (state, action) => {
    // 退出登录清楚redux中的数据缓存,不能设置state为undefined，因为嵌套数据持久化会有_persist属性
    // 清除state属性中的_persist会导致持久化响应失效，localStorage.clear()会导致刷新时持久化数据丢失
    // 多层嵌套属性中需要分别保留_persist，或者修改blacklist:["session.initial_session"]
    if (action.type === LOGOUT_CURRENT_USER) {
        state = resetState(state)
    }
    return rootReducer(state, action)
}

const persistedRootReducer = persistReducer(rootPersistConfig, resetReducer);

export default persistedRootReducer;
