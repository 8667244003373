import { Form, Button, Tooltip } from "antd";
import {
  FormDescription,
  FormSelect,
  FormAmount,
} from "./common_renders";
import { useState } from "react";
import { Iconfont, AuthButton } from "@/components";

const SingleRecord = (props) => {
  const [editing, setEditing] = useState(false);
  const {
    fund_amount,
    fund_type,
    fund_description,
    index,
    saveItem,
    deleteItem,
  } = props;
  const initialValues = {
    fund_amount: fund_amount,
    fund_description,
    fund_type: fund_type.code,
  };

  const beginEdit = () => {
    setEditing(true);
  };
  const stopEdit = () => setEditing(false);

  const onFinish = (val) => {
    saveItem(index, val);
    stopEdit();
  };

  const editingButton = (
    <Form.Item className="plan-form-edit">
      <AuthButton type="link" htmlType="submit">
        确认
      </AuthButton>
      <AuthButton type="link" onClick={stopEdit}>
        取消
      </AuthButton>
    </Form.Item>
  );

  const normalButton = (
    <>
      <AuthButton
        type="text"
        onClick={beginEdit}
        icon={<Iconfont type="icon-edit" className="icon-23" />}
        className="plan-form-add"
      />
      <AuthButton
        type="text"
        onClick={() => deleteItem(index)}
        icon={<Iconfont type="icon-minus" className="icon-23" />}
        className="plan-form-add"
      />
    </>
  );

  const normalContent = (
    <>
      <div
        style={{ width: "4.35vw", marginLeft: "16px" }}
        className="mg-r-16 record-cell"
      >
        {fund_type.label}
      </div>
      <div
        className="description mg-r-16  record-cell"
        style={{ width: "25.2vw" }}
      >
        <Tooltip
          title={
            fund_description
              ? fund_description.length > 50
                ? fund_description
                : ""
              : ""
          }
        >
          {fund_description}
        </Tooltip>
      </div>
      <div style={{ width: "6.65vw" }} className="mg-r-16  record-cell">
        {fund_amount}
      </div>
      <div style={{ width: "4.5vw" }} className="mg-r-16  record-cell">
        {normalButton}
      </div>
    </>
  );

  const editingContent = (
    <div>
      <Form
        layout="inline"
        onFinish={onFinish}
        validateMessages={{ required: "该项为必填项" }}
        initialValues={initialValues}
        className="plan-table-form bg-trans"
        validateTrigger="onBlur"
      >
        <FormSelect {...props} type={"fund_type"} />
        <FormDescription type={"fund_description"} size="long" />
        <FormAmount type={"fund_amount"} />
        {editingButton}
      </Form>
    </div>
  );

  return (
    <div className="single-record">
      {!editing ? normalContent : editingContent}
    </div>
  );
};

const FundTable = (props) => {
  const { fundData } = props;
  return fundData?.map((fund, index) => (
    <SingleRecord key={index} index={index} {...fund} {...props} />
  ));
};

export default FundTable;
