import { Form, Button, Tooltip } from "antd";
import { TripDate, TripDestination } from "./trip_form_renders";
import { FormDescription, FormSelect } from "./common_renders";
import { useState } from "react";
import { Iconfont, AuthButton } from "@/components";
import moment from "moment";

const SingleRecord = (props) => {
  const {
    trip_time,
    trip_type,
    trip_destination,
    trip_transportation,
    trip_description,
    index,
    saveItem,
    deleteItem,
  } = props;

  let initialValues = { trip_destination, trip_description };
  initialValues.trip_type = trip_type.code;
  initialValues.trip_transportation = trip_transportation.code;
  initialValues.trip_time = trip_time?.map((item) => moment(item, "YYYY/MM/DD"));

  const [start, end] = trip_time;
  const [editing, setEditing] = useState(false);
  const beginEdit = () => {
    setEditing(true);
  };
  const stopEdit = () => setEditing(false);

  const onFinish = (val) => {
    let { trip_time } = val;
    trip_time = trip_time?.map((item) => item.format("YYYY/MM/DD"));
    saveItem(index, { ...val, trip_time });
    stopEdit();
  };

  const editingButton = (
    <Form.Item className="plan-form-edit">
      <AuthButton type="link" htmlType="submit">
        确认
      </AuthButton>
      <AuthButton type="link" onClick={stopEdit}>
        取消
      </AuthButton>
    </Form.Item>
  );

  const normalButton = (
    <>
      <AuthButton
        type="text"
        onClick={beginEdit}
        icon={<Iconfont type="icon-edit" className="icon-23" />}
        className="plan-form-add"
      />
      <AuthButton
        type="text"
        onClick={() => deleteItem(index)}
        icon={<Iconfont type="icon-minus" className="icon-23" />}
        className="plan-form-add"
      />
    </>
  );

  const normalContent = (
    <>
      <div style={{ width: "12vw", marginLeft: "16px" }} className="mg-r-16">
        {start && end
          ? `${start.replace(/-/g, "/")} - ${end.replace(/-/g, "/")}`
          : ""}
      </div>
      <div style={{ width: "4.5vw" }} className="mg-r-16">
        {trip_type.label}
      </div>
      <div style={{ width: "4.5vw" }} className="mg-r-16">
        {trip_destination}
      </div>
      <div style={{ width: "4.5vw" }} className="mg-r-16">
        {trip_transportation.label}
      </div>
      <div className="description mg-r-16" style={{ width: "9.5vw" }} >
        <Tooltip
          title={
            trip_description && trip_description.length > 20
              ? trip_description
              : ""
          }
        >
          {trip_description}
        </Tooltip>
      </div>
      <div style={{ width: "4.5vw" }}>{normalButton}</div>
    </>
  );

  const editingContent = (
    <div>
      <Form
        layout="inline"
        onFinish={onFinish}
        validateMessages={{ required: "该项为必填项" }}
        initialValues={initialValues}
        className="plan-table-form bg-trans"
        validateTrigger="onBlur"
      >
        <TripDate />
        <FormSelect {...props} type={"trip_type"} />
        <TripDestination />
        <FormSelect {...props} type={"trip_transportation"} />
        <FormDescription type={"trip_description"} />
        {editingButton}
      </Form>
    </div>
  );

  return (
    <div className="single-record">
      {!editing ? normalContent : editingContent}
    </div>
  );
};

const TripTable = (props) => {
  const { tripData } = props;
  return tripData?.map((trip, index) => (
    <SingleRecord key={index} index={index} {...trip} {...props} />
  ));
};

export default TripTable;
