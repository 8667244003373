import { memo } from 'react'
import { registerRouterConfig, useRoutes } from '@components/router/routes'
import { SideBarLayout, EmptyLayout } from '@layouts'
import { AuthRedirect } from '@/wrappers'
import TopicPool from './selected_topic_pool'
import CreateTopic from './create_topic/creat_route'
import TopicDetails from "./topic_details"
import { SceneManage } from './topic_uphold'
import PlanExamine from './topic_examine/plan_examine'
import AssetCommitteeReview from './topic_examine/asset_committee_review'
import PlanExamineScene from './topic_examine/plan_examine/scene'
import AssetCommitteeReviewScene from './topic_examine/asset_committee_review/scene'
import TaskPackage from './topic_examine/plan_examine/task_package.tsx'
import SceneDetails from './topic_uphold/scene_details';

const empty = () => <div>敬请期待</div>
// 选题管理路由
export const routes = [
    {
        index: true,
        name: 'select-topic-management',
        component: SideBarLayout,
        routes: [
            {
                name: 'selectTopicPool',
                title: '选题池',
                // index: true,
                wrappers: [AuthRedirect],
                path: 'select-topic-pool',
                access: 'topic_list',
                icon: 'icon-a-30-shujutongjijindubiao',
                component: TopicPool,
                disabledDefault404: true,
                routes: [
                    {
                        name: 'creatTopic',
                        path: '',
                        hideMenu: true,
                        component: CreateTopic,
                    },
                    {
                        name: "topicDetails",
                        path: `topic_details`,
                        hideMenu: true,
                        component: TopicDetails,
                    }
                ]
            },
            {
                name: 'topicUphold',
                title: '选题维护',
                path: 'uphold',
                access: 'topic_maintain',
                icon: 'icon-a-30-shujutongjijindubiao',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'topicSceneManage',
                        title: '场景管理',
                        path: 'scene-manage',
                        access: 'topic_scene_address',
                        icon: 'icon-a-30-shujutongjijindubiao',
                        component: SceneManage,
                        disabledDefault404: true,
                        routes: [
                            {
                                name: "sceneDetails",
                                path: `scene-details`,
                                access: '',
                                hideMenu: true,
                                component: SceneDetails,
                            },
                        ]
                    },
                    // {
                    //     name: 'topicTaskPackage',
                    //     title: '任务包',
                    //     path: 'task-package',
                    //     access: '',
                    //     icon: 'icon-a-30-shujutongjijindubiao',
                    //     component: empty,
                    // },
                    // {
                    //     name: 'topicDefineProject',
                    //     title: '选题立项',
                    //     path: 'define-project',
                    //     access: 'topic_list',
                    //     icon: 'icon-a-30-shujutongjijindubiao',
                    //     component: empty,
                    // },
                    {
                        name: 'topicSceneManageRedirect',
                        index: true,
                        wrappers: [AuthRedirect],
                        redirect: 'topicSceneManage'
                    }
                ]
            },
            {
                name: 'topicExamine',
                title: '选题审核',
                path: 'examine',
                access: 'topic_check',
                icon: 'icon-a-30-shujutongjijindubiao',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'topicPlanExamine',
                        title: '策划审核',
                        path: 'plan-examine',
                        access: 'topic_planner_check',
                        icon: 'icon-a-30-shujutongjijindubiao',
                        component: PlanExamine,
                        routes: [
                            {
                                name: 'planExamineScene',
                                title: '',
                                path: 'plan-examine-scene',
                                hideMenu: true,
                                component: PlanExamineScene,
                            },
                            {
                                name: 'planExamineSceneRedirect',
                                index: true,
                                wrappers: [AuthRedirect],
                                redirect: 'planExamineScene'
                            }
                        ]
                    },
                    {
                        name: 'topicCommitteeExamine',
                        title: '资产委员会审核',
                        path: 'committee-examine',
                        access: 'topic_assets_check',
                        icon: 'icon-a-30-shujutongjijindubiao',
                        component: AssetCommitteeReview,
                        routes: [
                            {
                                name: 'assetCommitteeReviewScene',
                                title: '',
                                path: 'asset-committee-review-scene',
                                hideMenu: true,
                                component: AssetCommitteeReviewScene,
                            },
                            {
                                name: 'assetCommitteeReviewSceneRedirect',
                                index: true,
                                wrappers: [AuthRedirect],
                                redirect: 'assetCommitteeReviewScene'
                            }
                        ]
                    },
                    {
                        name: 'tipicExamineRedirect',
                        index: true,
                        wrappers: [AuthRedirect],
                        redirect: 'topicPlanExamine'
                    }
                ]
            },
            {
                name: 'selectTopicPoolRedirect',
                index: true,
                wrappers: [AuthRedirect],
                redirect: 'selectTopicPool'
            }
        ]
    }
];
// registerRouterConfig(routeConfig) 注册配置
registerRouterConfig(routes, undefined, '/select-topic-management');

/**
 * 导出选题管理路由
 */
export const TopicRoute = memo(() => useRoutes(routes, { disabledLinkTitle: true }));
