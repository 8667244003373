import { Form, Button } from "antd";
import { AuthButton } from "@/components";
const FormFooter = (props) => {
  const { type, roleDetails } = props;
  const handleSave = () => {
    handleSubmission();
  };
  const handleSubmission = () => {
    props.form.current.validateFields().then((values) => {
        props.confirmFinish(values)
    });
  };

  const handleDel = () => {
    props.handleShowDel(roleDetails);
  };

  return (
    <div>
      <Form.Item>
        <AuthButton
          permission={type === "edit" ? "role_update" : "role_create"}
          type="primary"
          
          onClick={handleSave}
        >
          完成
        </AuthButton>
        {type === "edit" ? (
          <AuthButton
            permission="role_delete"
            htmlType="button"
            className=" mg-l-20"
            onClick={handleDel}
          >
            删除角色
          </AuthButton>
        ) : ""}
        <AuthButton
          className=" mg-l-20"
          htmlType="button"
          onClick={props.handleCancel}
        >
          取消
        </AuthButton>
      </Form.Item>
    </div>
  );
};
export default FormFooter;
