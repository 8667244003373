import {
  RECEIVE_ANNOUNCEMENTS,
  RECEIVE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
} from "@actions/action_consts";

const announcementsReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ANNOUNCEMENT:
      return { ...state, [action.announcement.id]: action.announcement };
    case RECEIVE_ANNOUNCEMENTS:
      let announcements = action.announcements.list;
      let res = {};
      announcements.forEach((announcement) => {
        res[announcement.id] = announcement;
      });
      
      return { ...state, ...res };
    case DELETE_ANNOUNCEMENT:
      const { id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    default:
      return state;
  }
};

export default announcementsReducer;
