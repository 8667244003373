import {
  observable,
  action,
  computed,
  flow,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import { statsStore } from "@stores";
import { artStatsAPI, productionStatsAPI } from "@api";
import { debounce } from "lodash";
const page_size = 11;

class SummaryStore {
  constructor() {
    makeAutoObservable(this);
  }
  @observable filter = {};
  @observable tab = "all";
  @observable table;
  @observable page = 1;
  @observable date;
  @observable loading = true;
  @action startLoad() {
    this.loading = true;
  }
  @action finishLoad() {
    this.loading = false;
  }
  @computed get statType() {
    return statsStore.statType;
  }
  @computed get dateType() {
    return statsStore.dateType;
  }
  @computed get relatedUsers() {
    return statsStore.relatedUsers;
  }
  @computed get params() {
    let [from, to] = this.date;
    const isWeek = this.statType === "production" && this.dateType === 2;
    from = isWeek ? from.format("YYYY-WW") : from.format("YYYY-MM");
    to = isWeek ? to.format("YYYY-WW") : to.format("YYYY-MM");
    const filter = { ...this.filter, date: { from, to } };
    const date_type = this.statType === "art" ? 1 : this.dateType;
    const params = {
      filter,
      date_type,
      page_size,
      page: this.page,
    };
    return params;
  }
  @action updateDateType(type) {
    statsStore.updateDateType(type);
  }
  @action updateStatType(type) {
    statsStore.updateStatType(type);
  }
  @action updateTab(val) {
    this.tab = val;
  }
  @action updateFilter(val) {
    this.filter = val;
  }
  @action updatePage(val) {
    this.page = val;
  }
  @action updateDate(date) {
    this.date = date;
  }
  @action clear() {
    runInAction(() => {
      this.page = 1;
      this.table = [];
      this.filter = {};
    });
  }

  @action handleRequest(res) {
    if (res.code === 1000) {
      this.finishLoad();
      this.table = res.data.list;
      this.total = res.data.total;
    }
  }

  fetchArtSummary = flow(function* () {
    this.startLoad();
    try {
      const res = yield artStatsAPI.fetchArtSummary(this.params);
      this.handleRequest(res);
    } catch (e) {}
  });
  fetchArtUserSummary = flow(function* () {
    this.startLoad();
    try {
      const res = yield artStatsAPI.fetchArtUserSummary(this.params);
      this.handleRequest(res);
    } catch (e) {}
  });
  fetchProductionSummary = flow(function* () {
    this.startLoad();
    try {
      const res = yield productionStatsAPI.fetchProductionSummary(this.params);
      this.handleRequest(res);
    } catch (e) {}
  });
  fetchProductionUserSummary = flow(function* () {
    this.startLoad();
    try {
      const res = yield productionStatsAPI.fetchProductionUserSummary(
        this.params
      );
      this.handleRequest(res);
    } catch (e) {}
  });
}

const summaryStore = new SummaryStore();

const handleDifferentRequest = () => {
  const isAll = summaryStore.tab === "all";
  const isUser = summaryStore.tab === "user";
  const isArt = summaryStore.statType === "art";
  const isProduction = summaryStore.statType === "production";
  if (isArt && isAll) summaryStore.fetchArtSummary();
  else if (isArt && isUser) summaryStore.fetchArtUserSummary();
  else if (isProduction && isAll) summaryStore.fetchProductionSummary();
  else if (isProduction && isUser) summaryStore.fetchProductionUserSummary();
};

const debouncedRequest = debounce(handleDifferentRequest, 800);
reaction(
  () => summaryStore.date,
  (val, prevVal) => {
    if (val !== prevVal) {
      summaryStore.updatePage(1);
      debouncedRequest();
    }
  }
);
reaction(
  () => summaryStore.filter,
  (val, prevVal) => {
    if (val !== prevVal) {
      summaryStore.updatePage(1);
      debouncedRequest();
    }
  }
);
reaction(
  () => summaryStore.page,
  (val, prevVal) => {
    if (val !== prevVal) debouncedRequest();
  }
);

export default summaryStore;
