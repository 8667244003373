/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { Drawer, AmpIcon } from '@components';
import { Col, Row, Empty, Avatar, Tooltip } from 'antd';
import ImageSwiper from '@/components/image_swiper';
import GdMap from '@/components/gaode_map';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import ActionBtn from './action_btn';
import TopicAnnotation from '../../components/topic_annotation/index';
import HistoryRecords from '../../components/history_records';

interface IProps {
    selectTopic: any,
    status: number
}

/**
 * ItemDetails
 * @param {IProps} props props
 * @returns {React.ReactNode}
 */
const ItemDetails: React.FC<IProps> = (props: IProps) => {
    const { selectTopic, status } = props;
    const detailRef = useRef<HTMLDivElement>(null);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);

    const [drawerNodes, DrawerOpen]: any = Drawer.useDrawer({
        placement: 'bottom',
        maskClosable: false,
        destroyOnClose: true,
        style: { position: 'absolute' },
        getContainer: () => detailRef.current
    });

    // 获取选题编辑信息
    useEffect(() => {
        if (selectTopic) {
            SelectedTopicApi().getTopicSceneInfo({ sceneId: selectTopic.id }).then((res: any) => {
                setData(res);
                setIsShow(true);
            });
        } else {
            setIsShow(false);
        }
    }, [selectTopic]);

    /**
     * 关联选题新开页
     * @param {number} id topicId
     */
    const relationTopic = (id: number) => {
        window.open(`/select-topic-management/select-topic-pool/topic_details?topicId=${id}`);
    };

    return (
        <div className={isShow ? 'amp-item-detail' : 'amp-item-detail-empty'} ref={detailRef}>
            {
                isShow && (
                    <>
                        <Row gutter={16} justify="space-between">
                            <Col span={12} className="amp-item-detail-left">
                                <div className="amp-title">
                                    <span className="amp-label amp-line-clamp-1 amp-fs-24">
                                        <Tooltip placement="bottomLeft" title={data?.detail?.name}>{data?.detail?.name}</Tooltip>
                                    </span>
                                    <span className="amp-content amp-fs-16">
                                        {data?.detail?.number}
                                    </span>
                                    <div className="amp-tip amp-ml-8">{data?.detail?.statusName}</div>
                                </div>
                                <div className="amp-swiper">
                                    <ImageSwiper imageList={data?.detail?.imagesList} slidesPerView={1} />
                                </div>
                                <div className="amp-title amp-title-flex">
                                    <span className="amp-label">描述</span>
                                    <span className="amp-content amp-gray">{data?.detail?.comment || '无'}</span>
                                </div>
                                <div className="amp-title">
                                    <span className="amp-label">负责人</span>
                                    <Avatar.Group maxPopoverPlacement="bottom" maxCount={8} size={30}>
                                        <Avatar style={{ backgroundColor: '#6ECCC8' }}>{data?.detail?.operatorName.slice(-2).toUpperCase()}</Avatar>
                                    </Avatar.Group>
                                    <span className="amp-content amp-ml-8">{data?.detail?.operatorName}</span>
                                </div>
                                <div className="amp-map">
                                    <div className="amp-title amp-title-flex">
                                        <span className="amp-label">地址</span>
                                        <div className="amp-content amp-content-flex">
                                            <AmpIcon className="fz-24 amp-address-icon" name="address" />
                                            <div>
                                                {`${data?.detail?.addressListList?.[0]?.district}${data?.detail?.addressListList?.[0]?.address}`}
                                            </div>
                                        </div>
                                    </div>
                                    <GdMap marker={data?.detail?.addressListList} />
                                </div>
                            </Col>
                            <Col span={12} className="amp-item-detail-right">
                                <div className="amp-relation-info amp-mb-24">
                                    <div className="amp-relation-info-top">
                                        <AmpIcon className="fz-24" name="relation-info" />
                                        <div className="amp-title amp-fw-500 amp-ml-8">关联信息</div>
                                    </div>
                                    <div className="amp-relation-info-list">
                                        <span className="amp-relation-info-title">关联选题</span>
                                        <div className="amp-relation-info-item" onClick={() => relationTopic(data?.detail?.topicId)}>
                                            <span className="amp-gray">{data?.topicDetail?.number}</span>
                                            &nbsp;
                                            {data?.topicDetail?.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="amp-comments-info amp-mb-24">
                                    <TopicAnnotation id={data?.detail?.id} type={3} />
                                </div>
                                <div className="amp-history-info">
                                    <div className="amp-history-info-top">
                                        <AmpIcon className="fz-24" name="history-record" />
                                        <div className="amp-title amp-fw-500 amp-ml-8 amp-mb-8">历史记录</div>
                                    </div>
                                    <div className="amp-history-info-list">
                                        <HistoryRecords historyList={data?.historyListList} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <ActionBtn status={status} id={data?.detail?.id} operationsList={data?.operationsList} DrawerOpen={DrawerOpen} />
                        {drawerNodes}
                    </>
                )
            }
            {
                !isShow && <Empty image={`${process.env.PUBLIC_URL}/images/other/image2.png`} description="暂无数据" />
            }
        </div>
    );
};

export default ItemDetails;
