import React, { useState } from 'react';
import { Collapse } from '@components';
import TopicAnnotation from '../../../../../components/topic_annotation';
import HistoryRecords from '../../../../../components/history_records';
import RelateInfo from '../../../../../components/relate_info';
import './index.less';

/**
 * 渲染批注信息
 * @param {*} list 批注信息列表
 */
const renderAnnotation = (list) => {
    const temp = [];
    list?.map((item) => {
        temp.push(<AnnotationItem key={item?.id} {...item} />);
    });
    return temp;
};

/**
 * 场景详情右侧展示组件
 * @param {*} props props
 * @returns {*}
 */
const SceneDetailsInfo = (props) => {
    const { relatedInfo, sceneId, historyList } = props;
    const temp = renderAnnotation(props?.annotation_list);
    const historyHeader = (
        <div className="amp-scene-history-title">
            <span className="amp-scene-history-title-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.9999 12.4143C20.0233 16.5643 16.7156 19.9411 12.6078 19.9992C8.4251 20.0589 5.01579 16.6931 5.00017 12.485C4.9986 12.2572 5.00798 12.0309 5.02829 11.8078C5.09392 11.0378 5.7314 10.4454 6.49858 10.4454C6.66576 10.4454 6.78451 10.6073 6.73763 10.7691C6.57982 11.3144 6.50014 11.8801 6.50014 12.4583C6.50014 13.2739 6.65795 14.0642 6.97044 14.8059C7.272 15.524 7.7048 16.1698 8.25635 16.7245C8.80791 17.2792 9.45008 17.7145 10.1641 18.0177C10.9032 18.332 11.6891 18.4907 12.4985 18.4907C13.3078 18.4907 14.0953 18.332 14.8328 18.0177C15.5468 17.7145 16.189 17.2792 16.7406 16.7245C17.2921 16.1698 17.7249 15.524 18.0265 14.8059C18.339 14.0627 18.4968 13.2723 18.4968 12.4583C18.4968 11.6444 18.339 10.8524 18.0265 10.1107C17.7249 9.39263 17.2921 8.74681 16.7406 8.19212C16.189 7.63744 15.5468 7.20218 14.8328 6.89891C14.0937 6.58464 13.3078 6.42593 12.4985 6.42593C11.6891 6.42593 10.9016 6.58464 10.1641 6.89891C9.88445 7.01833 9.61727 7.15661 9.36102 7.31531C9.3079 7.34831 9.33133 7.43159 9.39383 7.43159H10.3813C10.4547 7.43159 10.5266 7.46459 10.5735 7.52273L11.6625 8.83795C11.6969 8.8788 11.6672 8.94166 11.6141 8.94166H8.5001C7.39543 8.94166 6.50014 8.04128 6.50014 6.93033V4.06263C6.50014 4.00606 6.56732 3.97935 6.60639 4.01863L7.92667 5.34642C7.97355 5.39356 8.00011 5.45798 8.00011 5.52398V6.30022C8.00011 6.35208 8.05792 6.38193 8.09854 6.35051C9.33758 5.44698 10.861 4.9143 12.5078 4.91587C16.639 4.91901 19.9764 8.25969 19.9999 12.4143Z"
                        fill="#29C1B9"
                    />
                    <path
                        d="M14.5 9.5042V12.9612C14.5 13.794 13.8281 14.4697 13 14.4697H9.31258C9.27821 14.4697 9.25009 14.4414 9.25009 14.4068V13.024C9.25009 12.9894 9.27821 12.9612 9.31258 12.9612H12.5C12.7766 12.9612 13 12.7365 13 12.4583V9.5042C13 9.46963 13.0281 9.44134 13.0625 9.44134H14.4375C14.4719 9.44134 14.5 9.46963 14.5 9.5042Z"
                        fill="#29C1B9"
                    />
                </svg>
            </span>
            <span className="amp-scene-history-title-content">历史记录</span>
        </div>
    );

    const relateHeader = (
        <div className="amp-scene-relate-title">
            <span className="amp-scene-relate-title-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.9764 14.4965C11.4338 14.9506 11.2442 14.6776 11.5493 14.3748L14.3643 11.5615C14.6693 11.2583 15.5291 10.8837 15.0714 10.4296C14.6133 9.97523 13.9961 9.85282 13.6907 10.155L10.5432 13.2866C10.2381 13.5901 10.5193 14.0418 10.9764 14.4965V14.4965ZM8.67759 18.2666L6.6875 16.2776L10.9231 12.0451C9.85889 11.7305 8.66215 11.9753 7.82181 12.81L5.61258 15.0036C4.39268 16.2152 5.17362 17.4037 6.39418 18.6154C7.61374 19.8277 8.81149 20.6034 10.0321 19.3911L12.241 17.1978C13.1185 16.3259 13.3551 15.0676 12.9702 13.9774L8.67759 18.2666V18.2666ZM18.5918 6.39863C17.3508 5.16619 16.1191 4.38983 14.8777 5.62328L12.6296 7.85442C11.7476 8.73105 11.5057 9.99099 11.8775 11.0903L16.2977 6.67362L18.2884 8.66263L13.879 13.0699C14.9828 13.4334 16.2466 13.1916 17.1256 12.318L19.3727 10.0862C20.6141 8.85412 19.8328 7.63141 18.5918 6.39863V6.39863Z"
                        fill="#29C1B9"
                    />
                </svg>
            </span>
            <span className="amp-scene-relate-title-content">关联信息</span>
        </div>
    );

    return (
        <div className="amps-scene-info">
            <div className="amp-scene-annotation">
                <Collapse header={relateHeader} opened={true}>
                    <RelateInfo relatedInfo={relatedInfo} />
                </Collapse>
            </div>
            <div className="amp-scene-annotation">
                <TopicAnnotation type={3} id={sceneId} />
            </div>
            <div className="amp-scene-history">
                <Collapse header={historyHeader} opened={true}>
                    <HistoryRecords historyList={historyList} />
                </Collapse>
            </div>
        </div>
    );
};

export default SceneDetailsInfo;
