import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import ScrollContainer from '@/components/scroll_container';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import TopicItem from './topic_item';
import './index.less'
// interface ITopicWrapProps {
//     searchData?: any
//     selectKey: (item: any) => void
// }
const allTopic = {id: 0, name: '全部场景', num:'-'}
/**
 * 场景管理列表-选题
 * @param {ITopicWrapProps} props props
 * @constructor
 */
const TopicWrap = (props) => {
    const { setSelectTopic } = props;
    const topicRef = useRef(null);
    const [checkKeys, setCheckKeys] = useState(0);
    // const [allData, setAllData] = useState([]);

    // useEffect(() => {
    //     if (Array.isArray(allData) && allData.length > 0) {
    //         const currentKey = allData.find(item => item.id === checkKeys)
    //         if (currentKey) {
    //             setSelectTopic(currentKey)
    //         } else {
    //             setSelectTopic(allData[0])
    //             setCheckKeys(allData[0].id)
    //         }
    //     } else {
    //         setSelectTopic(null)
    //     }
    // }, [allData])

    useEffect(() => {
        setSelectTopic(allTopic)
    }, [])


    const checkKeysChange = useCallback((item) => {
        setCheckKeys(item.id)
        setSelectTopic(item)
    },[])

    /**
     * 获取所有选题数据
     * @param {Array} val val
     * @constructor
     */
    // const renderAllData = useCallback(
    //     (val) => {
    //         setAllData(val)
    //     }, [])

    return (
        <ScrollContainer
            ref={topicRef}
            pageSize={20}
            col={1}
            height="100%"
            className="amp-topic-scene-wrap-scroll"
            params={{}}
            renderItem={(item, key) => (
                <TopicItem
                    data={item}
                    key={key}
                    checkKeys={checkKeys}
                    checkKeysChange={checkKeysChange}
                />
            )}
            renderTopDom={() => (
                <TopicItem
                    data={allTopic}
                    checkKeys={checkKeys}
                    key="0"
                    checkKeysChange={checkKeysChange}
                />
            )}
            // renderAllData={renderAllData}
            request={(page, pageSize, params) => SelectedTopicApi().getTopicList({
                page,
                page_size: pageSize,
                ...params
            }).then((res) => {
                return {
                    list: res.listList,
                    total: res.count
                }
            })}
        />
    );
};

export default memo(TopicWrap);
