import { RangePicker } from '@/components';
import { Input, Select } from 'antd';
const { Option } = Select;

const splitOptions = [
    { key: 1, label: '未拆分' },
    { key: 2, label: '已拆分' }
]
const placeholder = {
    asset_type: '全部类型',
    asset_category: '全部种类',
    uid_name: '检索内部ID\\资产名称',
    project_name: '检索项目名称',
};

export const DateRangesFilter = (props) => {
    const { state, setStateFunc } = props;

    return (
        <div className="mg-r-20">
            <RangePicker placeholder={['生成时间']} onChange={(date) => setStateFunc('produced_at', date)} value={state.produced_at} />
        </div>
    );
};

export const InputFilter = (props) => {
    const { state, setStateFunc } = props;

    return ['uid_name', 'project_name']?.map((key) => (
        <Input
            allowClear
            onChange={(e) => setStateFunc(key, e.target.value)}
            placeholder={placeholder[key]}
            value={state[key]}
            size="large"
            className="filter-input mg-r-20"
            key={key}
        />
    ));
};

export const SelectFilter = (props) => {
    const { state, setStateFunc, configs } = props;

    return ['asset_type', 'asset_category']?.map((key) => (
        <Select
            className="filter-input mg-r-20"
            allowClear
            showArrow
            showSearch
            optionFilterProp="children"
            size="large"
            placeholder={placeholder[key]}
            onChange={(value) => setStateFunc(key, value)}
            value={state[key]}
            key={key}>
            {optionsRender(configs, key)}
        </Select>
    ));
};

const optionsRender = (configs, key) => {
    if (!configs || !configs[key]) return '';
    const options = Object.values(configs[key]);
    return options?.map((ele) => (
        <Option key={ele.code} label={ele.label} value={ele.code}>
            {ele.label}
        </Option>
    ));
};

export const SplitSelect = (props) => {
    const { state, setStateFunc, configs } = props;
    return (
        <Select
            className="filter-input mg-r-20"
            allowClear
            showArrow
            optionFilterProp="children"
            size="large"
            placeholder={"拆分任务"}
            onChange={(value) => setStateFunc('is_split',value)}
            value={state['is_split']}
        >
            {
                splitOptions.map(item => (
                    <Option
                        key={item.key}
                        value={item.key}
                    >
                        {item.label}
                    </Option>
                ))
            }
        </Select>
    )
}
