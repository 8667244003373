import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { DraggableAreasGroup } from "react-draggable-tags";
import { SingleGroupItem, AssetImage } from "..";
const draggableGroup = new DraggableAreasGroup();

@inject("taskGroupsStore")
@observer
class DragDropArea extends Component {
    get store() {
        return this.props.taskGroupsStore;
    }

    get activeType() {
        return this.props.activeType ?? '1'
    }

    renderGrouped = () => {
        const { groups } = this.store;
        const display = groups?.map((item, idx) => (
            <SingleGroupItem
                groupItem={item}
                index={idx}
                key={idx}
                draggableGroup={draggableGroup}
                activeType={this.activeType}
            />
        ));

        return display;
    };

    renderUngrouped = () => {
        const { ungroupedDisplay } = this.store;
        const UngroupedArea = draggableGroup.addArea("ungrouped");
        return (
            <div className="ungrouped-droppable">
                <UngroupedArea
                    tags={ungroupedDisplay}
                    render={({ tag }) => <AssetImage tag={tag} />}
                    style={{
                        minHeight: '200px',
                        width: '100%'
                    }}
                />
            </div>
        );
    };

    render() {
        return (
            <div className="drag-drop-area">
                <div style={{ marginTop: "-20px" }}>{this.renderGrouped()}</div>
                <div className="mg-b-68">{this.renderUngrouped()}</div>
            </div>
        );
    }
}
export default DragDropArea;
