import { createElement, forwardRef, Suspense } from 'react';
import { Loading } from '@components';
import createErrorBoundaries from '@/HOC/createErrorBoundaries';

/**
 * Swr的Suspense 模式拦截
 * @param {React.ComponentType} component - React 组件
 * @param {React.ComponentType.fallback} React.ComponentType.fallback: (data: {error?: any}) => React.ReactNode - 渲染额外ui
 * @returns {*} 返回的组件
 */
function withSuspense(component) {
    return forwardRef((props, ref) => {
        const ErrorBoundaries = createErrorBoundaries();
        const fallback = component.fallback || <Loading size="large" />;
        const errorFallback = component.errorFallback;
        return (
            <ErrorBoundaries errorFallback={errorFallback}>
                <Suspense fallback={fallback}>
                    {createElement(component, { ...props, ref })}
                </Suspense>
            </ErrorBoundaries>
        );
    });
}

export default withSuspense;
