import { Form } from "antd";
import { addEditDic } from "@vars/views/projects_vars";
import { formatImageFiles } from "@gUtils/functional_util";
import { useState } from "react";
import { AuthButton } from "@/components";
import { emptyProject } from "@vars/views/projects_vars";
import { inject, observer } from 'mobx-react';

const AddEditFooter = (props) => {
  const { type } = props;
  const { form } = props;
  const [loading, setLoading] = useState(false)
  const finishProject = () => {
    form.current
      .validateFields()
      .then((values) => {
        setLoading(true);
        let formData = new FormData();
        let fileList = props.getFiles();
        formData = formatInput(values, formData);
        formData = formatImageFiles(fileList, formData);
        props.handleSave(formData, () => setLoading(false));
      })
  };

  const formatInput = (values, formData) => {
    let {
      start_at,
      estimate_finish_at,
      title,
      photographer_id,
      description,
      is_confidential,
      confidential_user_ids,
      is_public,
    } = values;
    const { projects, projectId } = props;
    const currentProject = projects[projectId] || emptyProject

    start_at = start_at.format("YYYY-MM-DD") + " 00:00:00";
    estimate_finish_at = estimate_finish_at ? estimate_finish_at.format("YYYY-MM-DD") + " 23:59:59" : ""
    description = description ? description : ""

    if (currentProject.id) {
      formData.set("id", currentProject.id);
    }
    formData.set("start_at", start_at);
    formData.set("estimate_finish_at", estimate_finish_at);
    formData.set("title", title);
    formData.set("photographer_id", photographer_id);
    formData.set("description", description);
    formData.set("is_public", is_public);
    formData.set("is_confidential", is_confidential);
    if (is_confidential === 0) {
      formData.set("confidential_user_ids", null);
      props.configsStore.setSecretList(props.configsStore.allUsers);
    } else {
      const temp = [];
      const { allUsers } = props.configsStore
      confidential_user_ids && props.projectStore.unique(confidential_user_ids).map((item) => {
        formData.append("confidential_user_ids", item);
        for (let i = 0; i < allUsers.length; i++) {
          if (item === allUsers[i].id) {
            temp.push(allUsers[i]);
          }
        }
      })
      props.configsStore.setSecretList(temp);
    }


    return formData;
  };

  return (
    <div className="dialog-footer dialog-footer-shadow">
      <Form.Item>
        <AuthButton
          type="primary"
          htmlType="submit"

          onClick={finishProject}
          loading={loading}
        >
          {addEditDic[type].okText}
        </AuthButton>
        <AuthButton
          className=" mg-l-20"
          htmlType="button"
          onClick={props.handleCancel}
        >
          取消
        </AuthButton>
      </Form.Item>
    </div>
  );
};

export default inject('configsStore', "projectStore")(observer(AddEditFooter))
