import { checkPassword } from "@/utils/validations/validator_util";

export const publicKey =
  "-----BEGIN PUBLIC KEY-----\n" +
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDRjd1auDrQGXVbpbNU9fcmkrxf\n" +
  "79EmqomdXp+feFRbBBbx0rg4UjEvsgt2m5Kmo4ny2arwgoAATH3Y37/9rsdrIlQ6\n" +
  "lXe4YyfxIwq6yLAVXQnp4UuR0USH3ANQL9o1F4aOxDj7sNENp3iNahBsuZ8FrrLE\n" +
  "zIMj+xZ5D4HNElhV+QIDAQAB\n" +
  "-----END PUBLIC KEY-----";

const confirmValidation = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject("两次输入的密码需保持一致");
  },
});

export const rules = {
  username: [{ required: true, message: "账户名不能为空" }],
  password: [{ required: true, validator: checkPassword }],
  defaultPassword: [{ required: true, message: "初始密码不能为空" }],
  confirmPassword: [
    { required: true, message: "请再次输入新密码!" },
    confirmValidation,
  ],
};

export const tabs = [
  {
    title: "工作中心",
    path: "/dashboard",
    key: "dashboard",
  },
  {
    title: "选题管理",
    path: "/select-topic-management",
    key: "select-topic-management",
  },
  {
    title: "项目管理",
    path: "/projects",
    key: "projects",
  },
  {
    title: "资产仓库",
    path: "/assets",
    key: "assets",
  },
  {
    title: "数据统计",
    path: "/stats",
    key: "stats",
  },
  {
    title: "系统设置",
    path: "/settings",
    key: "settings",
  },
];

export const countries = [
  { code: "AF", label: "阿富汗 " },
  { code: "AX", label: "奥兰群岛(芬兰属)" },
  { code: "AL", label: "阿尔巴尼亚" },
  { code: "DZ", label: "阿尔及利亚" },
  { code: "AS", label: "美属萨摩亚" },
  { code: "AD", label: "安道尔" },
  { code: "AO", label: "安哥拉" },
  { code: "AI", label: "安圭拉岛" },
  { code: "AQ", label: "南极洲" },
  { code: "AG", label: "安提瓜和巴布达" },
  { code: "AR", label: "阿根廷" },
  { code: "AM", label: "亚美尼亚" },
  { code: "AW", label: "阿鲁巴" },
  { code: "AU", label: "澳大利亚" },
  { code: "AT", label: "奥地利" },
  { code: "AZ", label: "阿塞拜疆" },
  { code: "BS", label: "巴哈马" },
  { code: "BH", label: "巴林" },
  { code: "BD", label: "孟加拉国" },
  { code: "BB", label: "巴巴多斯" },
  { code: "BY", label: "白俄罗斯" },
  { code: "BE", label: "比利时" },
  { code: "BZ", label: "伯利兹" },
  { code: "BJ", label: "贝宁" },
  { code: "BM", label: "百慕大群岛" },
  { code: "BT", label: "不丹" },
  { code: "BO", label: "玻利维亚" },
  { code: "BA", label: "波斯尼亚和黑塞哥维那" },
  { code: "BW", label: "博茨瓦纳" },
  { code: "BV", label: "布韦岛" },
  { code: "BR", label: "巴西" },
  { code: "IO", label: "英属印度洋领地" },
  { code: "BN", label: "文莱" },
  { code: "BG", label: "保加利亚" },
  { code: "BF", label: "布基纳法索" },
  { code: "BI", label: "布隆迪" },
  { code: "KH", label: "柬埔寨" },
  { code: "CM", label: "喀麦隆" },
  { code: "CA", label: "加拿大" },
  { code: "CV", label: "佛得角" },
  { code: "KY", label: "开曼群岛" },
  { code: "CF", label: "中非共和国" },
  { code: "TD", label: "乍得" },
  { code: "CL", label: "智利" },
  { code: "CN", label: "中国" },
  { code: "CX", label: "圣诞岛" },
  { code: "CC", label: "科科斯群岛（基灵群岛）" },
  { code: "CO", label: "哥伦比亚" },
  { code: "KM", label: "科摩罗" },
  { code: "CG", label: "刚果" },
  { code: "CD", label: "刚果民主共和国" },
  { code: "CK", label: "库克群岛" },
  { code: "CR", label: "哥斯达黎加" },
  { code: "CI", label: "科特迪瓦" },
  { code: "HR", label: "克罗地亚" },
  { code: "CU", label: "古巴" },
  { code: "CY", label: "塞浦路斯" },
  { code: "CZ", label: "捷克共和国" },
  { code: "DK", label: "丹麦" },
  { code: "DJ", label: "吉布提" },
  { code: "DM", label: "多米尼加" },
  { code: "DO", label: "多米尼加共和国" },
  { code: "EC", label: "厄瓜多尔" },
  { code: "EG", label: "埃及" },
  { code: "SV", label: "萨尔瓦多" },
  { code: "GQ", label: "赤道几内亚" },
  { code: "ER", label: "厄立特里亚" },
  { code: "EE", label: "爱沙尼亚" },
  { code: "ET", label: "埃塞俄比亚" },
  { code: "FK", label: "福克兰群岛(马尔维纳斯群岛)" },
  { code: "FO", label: "法罗群岛" },
  { code: "FJ", label: "斐济群岛" },
  { code: "FI", label: "芬兰" },
  { code: "FR", label: "法国" },
  { code: "GF", label: "法属圭亚那" },
  { code: "PF", label: "法属波利尼西亚" },
  { code: "TF", label: "法属南极地区" },
  { code: "GA", label: "加蓬" },
  { code: "GM", label: "冈比亚" },
  { code: "GE", label: "乔治亚" },
  { code: "DE", label: "德国" },
  { code: "GH", label: "加纳" },
  { code: "GI", label: "直布罗陀" },
  { code: "GR", label: "希腊" },
  { code: "GL", label: "格陵兰" },
  { code: "GD", label: "格林纳达" },
  { code: "GP", label: "瓜德罗普岛" },
  { code: "GU", label: "关岛" },
  { code: "GT", label: "危地马拉" },
  { code: "GG", label: "格恩西" },
  { code: "GN", label: "几内亚" },
  { code: "GW", label: "几内亚比绍" },
  { code: "GY", label: "圭亚那" },
  { code: "HT", label: "海地" },
  { code: "HM", label: "赫德和麦克唐纳群岛" },
  { code: "VA", label: "梵蒂冈城" },
  { code: "HN", label: "洪都拉斯" },
  { code: "HU", label: "匈牙利" },
  { code: "IS", label: "冰岛" },
  { code: "IN", label: "印度" },
  { code: "ID", label: "印度尼西亚" },
  { code: "IR", label: "伊朗" },
  { code: "IQ", label: "伊拉克" },
  { code: "IE", label: "爱尔兰" },
  { code: "IM", label: "马恩岛" },
  { code: "IL", label: "以色列" },
  { code: "IT", label: "意大利" },
  { code: "JM", label: "牙买加" },
  { code: "JP", label: "日本" },
  { code: "JE", label: "泽西" },
  { code: "JO", label: "约旦" },
  { code: "KZ", label: "哈萨克斯坦" },
  { code: "KE", label: "肯尼亚" },
  { code: "KI", label: "基里巴斯" },
  { code: "KP", label: "朝鲜" },
  { code: "KR", label: "韩国" },
  { code: "KW", label: "科威特" },
  { code: "KG", label: "吉尔吉斯斯坦" },
  { code: "LA", label: "老挝" },
  { code: "LV", label: "拉脱维亚" },
  { code: "LB", label: "黎巴嫩" },
  { code: "LS", label: "莱索托" },
  { code: "LR", label: "利比里亚" },
  { code: "LY", label: "利比亚" },
  { code: "LI", label: "列支敦士登" },
  { code: "LT", label: "立陶宛" },
  { code: "LU", label: "卢森堡" },
  { code: "MK", label: "马其顿,前南斯拉夫共和国" },
  { code: "MG", label: "马达加斯加" },
  { code: "MW", label: "马拉维" },
  { code: "MY", label: "马来西亚" },
  { code: "MV", label: "马尔代夫" },
  { code: "ML", label: "马里" },
  { code: "MT", label: "马耳他" },
  { code: "MH", label: "马绍尔群岛" },
  { code: "MQ", label: "马提尼克岛" },
  { code: "MR", label: "毛里塔尼亚" },
  { code: "MU", label: "毛里求斯" },
  { code: "YT", label: "马约特岛" },
  { code: "MX", label: "墨西哥" },
  { code: "FM", label: "密克罗尼西亚" },
  { code: "MD", label: "摩尔多瓦" },
  { code: "MC", label: "摩纳哥" },
  { code: "MN", label: "蒙古" },
  { code: "ME", label: "门的内哥罗(黑山)" },
  { code: "MS", label: "蒙特塞拉特" },
  { code: "MA", label: "摩洛哥" },
  { code: "MZ", label: "莫桑比克" },
  { code: "MM", label: "缅甸" },
  { code: "NA", label: "纳米比亚" },
  { code: "NR", label: "瑙鲁" },
  { code: "NP", label: "尼泊尔" },
  { code: "NL", label: "荷兰" },
  { code: "AN", label: "荷属安的列斯群岛" },
  { code: "NC", label: "新喀里多尼亚" },
  { code: "NZ", label: "新西兰" },
  { code: "NI", label: "尼加拉瓜" },
  { code: "NE", label: "尼日尔" },
  { code: "NG", label: "尼日利亚" },
  { code: "NU", label: "纽埃" },
  { code: "NF", label: "诺福克岛" },
  { code: "MP", label: "北马里亚纳群岛" },
  { code: "NO", label: "挪威" },
  { code: "OM", label: "阿曼" },
  { code: "PK", label: "巴基斯坦" },
  { code: "PW", label: "帕劳群岛" },
  { code: "PS", label: "巴勒斯坦当局" },
  { code: "PA", label: "巴拿马" },
  { code: "PG", label: "巴布亚新几内亚" },
  { code: "PY", label: "巴拉圭" },
  { code: "PE", label: "秘鲁" },
  { code: "PH", label: "菲律宾" },
  { code: "PN", label: "皮特克恩群岛" },
  { code: "PL", label: "波兰" },
  { code: "PT", label: "葡萄牙" },
  { code: "PR", label: "波多黎各" },
  { code: "QA", label: "卡塔尔" },
  { code: "RE", label: "留尼汪岛" },
  { code: "RO", label: "罗马尼亚" },
  { code: "RU", label: "俄罗斯" },
  { code: "RW", label: "卢旺达" },
  { code: "BL", label: "圣巴泰勒米" },
  { code: "SH", label: "圣赫勒拿岛" },
  { code: "KN", label: "圣基茨和尼维斯" },
  { code: "LC", label: "圣卢西亚" },
  { code: "MF", label: "圣马丁" },
  { code: "PM", label: "圣皮埃尔岛和密克隆岛" },
  { code: "VC", label: "圣文森特和格林纳丁斯" },
  { code: "WS", label: "萨摩亚" },
  { code: "SM", label: "圣马力诺" },
  { code: "ST", label: "圣多美和普林西比" },
  { code: "SA", label: "沙特阿拉伯" },
  { code: "SN", label: "塞内加尔" },
  { code: "RS", label: "塞尔维亚" },
  { code: "SC", label: "塞舌尔" },
  { code: "SL", label: "塞拉利昂" },
  { code: "SG", label: "新加坡" },
  { code: "SK", label: "斯洛伐克" },
  { code: "SI", label: "斯洛文尼亚" },
  { code: "SB", label: "所罗门群岛" },
  { code: "SO", label: "索马里" },
  { code: "ZA", label: "南非" },
  { code: "GS", label: "南乔治亚和南桑德威奇群岛" },
  { code: "ES", label: "西班牙" },
  { code: "LK", label: "斯里兰卡" },
  { code: "SD", label: "苏丹" },
  { code: "SR", label: "苏里南" },
  { code: "SJ", label: "斯瓦尔巴群岛和扬马延" },
  { code: "SZ", label: "斯威士兰" },
  { code: "SE", label: "瑞典" },
  { code: "CH", label: "瑞士" },
  { code: "SY", label: "叙利亚" },
  { code: "TJ", label: "塔吉克斯坦" },
  { code: "TZ", label: "坦桑尼亚" },
  { code: "TH", label: "泰国" },
  { code: "TL", label: "东帝汶" },
  { code: "TG", label: "多哥" },
  { code: "TK", label: "托克劳" },
  { code: "TO", label: "汤加" },
  { code: "TT", label: "特立尼达和多巴哥" },
  { code: "TN", label: "突尼斯" },
  { code: "TR", label: "土耳其" },
  { code: "TM", label: "土库曼斯坦" },
  { code: "TC", label: "特克斯群岛和凯科斯群岛" },
  { code: "TV", label: "图瓦卢" },
  { code: "UG", label: "乌干达" },
  { code: "UA", label: "乌克兰" },
  { code: "AE", label: "阿拉伯联合酋长国" },
  { code: "GB", label: "英国" },
  { code: "US", label: "美国" },
  { code: "UM", label: "美属小奥特兰群岛" },
  { code: "UY", label: "乌拉圭" },
  { code: "UZ", label: "乌兹别克斯坦" },
  { code: "VU", label: "瓦努阿图" },
  { code: "VE", label: "委内瑞拉" },
  { code: "VN", label: "越南" },
  { code: "VG", label: "维尔京群岛（英属）" },
  { code: "VI", label: "维尔京群岛" },
  { code: "WF", label: "瓦利斯群岛和富图纳群岛" },
  { code: "EH", label: "西撒哈拉" },
  { code: "YE", label: "也门" },
  { code: "ZM", label: "赞比亚" },
  { code: "ZW", label: "津巴布韦" },
];
