import { AuthButton, Iconfont } from "@components";
import { Divider, Avatar } from "antd";
import { ReloadOutlined, SyncOutlined } from "@ant-design/icons";
export const TitleBasics = (props) => {
  const { isBund, onBindClick } = props;
  const bindIcon = (
    <div className={isBund ? "is-bund" : "not-bund"}>
      {isBund ? "已绑定" : "未绑定"}
    </div>
  );
  const bindButton = (
    <AuthButton
      type="primary"
      ghost={isBund}
      icon={<Iconfont type="icon-link" className="icon-20" />}
      onClick={onBindClick}
    >
      {isBund ? "解除绑定" : "开始绑定"}
    </AuthButton>
  );
  return (
    <div>
      <div className="align-center mg-tb-24">
        <h3 className="mg-r-20">微信管理</h3>
        {bindIcon}
      </div>
      {bindButton}
    </div>
  );
};

const RefreshButton = (props) => {
  const onClick = () => props.onRefresh();
  return (
    <AuthButton
      type="link"
      icon={<SyncOutlined />}
      onClick={onClick}
      style={{ color: "#3497F2" }}
    >
      刷新
    </AuthButton>
  );
};

export const BundAccount = (props) => {
  const { head_image, nickname } = props?.wechatInfo;
  return head_image && nickname ? (
    <div className="align-center mg-t-30">
      <div className="align-center mg-r-12">
        <div style={{ color: "#767676" }}>绑定账号：</div>
        <Avatar src={head_image} className="mg-r-12" />
        <div className="bold">{nickname}</div>
      </div>
      <Divider type="vertical" />
      <RefreshButton onRefresh={props.onRefresh} />
    </div>
  ) : (
    ""
  );
};
