import React, { Component } from 'react'
import "./index.less"
class NoTaskInfo extends Component {
    render() {
        return (
            <div className="amps-no-data">
                <img className='amp-no-data-img' src={`${process.env.PUBLIC_URL}/images/other/no_data.png`} alt='请选择任务包' />
                <span className='associated-content-no-data-span'>暂无任务</span>
            </div>
        )
    }
}

export default NoTaskInfo
