
export const creator = "creator";
export const location = "location";
export const description = "description";
export const projectDetailTitle = {
    [location]: {
        title: "相关地点",
    },
    [creator]: {
        title: "创建人",
    },
    [description]: {
        title: "任务包描述",
    },
};

export const drawerTitle = {
    0: {
        formTitle: "提交审核",
    },
    1: {
        formTitle: "创建策划任务",
    },
    2: {
        formTitle: "批量创建策划任务",
    },
    3: {
        formTitle: "修改策划任务",
    },
    4: {
        formTitle: "审核任务包",
    },
    5: {
        formTitle: "公开任务包",
        formContent: [
            {
                name: "next",
                action_name: "public",
                label: "下一步",
                value: "任务包待选择",
                disabled: true,
            },
        ],
        buttons: [
            {
                title: "确认通过",
                action: "public",
            }
        ]
    },
    6: {
        formTitle: "驳回任务包",
        formContent: [
            {
                name: "next",
                action_name: "disapprove",
                label: "下一步",
                value: "策划中",
            },
            {
                name: "operator",
                action_name: "disapprove-operator",
                label: "处理人"
            },
        ],
        buttons: [
            {
                title: "确认提交",
                action: "disapprove",
            }
        ]
    },
    7:
    {
        formTitle: "作废任务包",
        formContent: [
            {
                name: "next",
                action_name: "discard",
                label: "下一步",
                value: "作废",
            },
        ],
        buttons: [
            {
                title: "确认提交",
                action: "discard",
            }
        ]
    },
    8: {
        formTitle: "承接任务包",
        formContent:[
            {
                name: "next",
                action_name: "take",
                label: "下一步",
                value: "任务包已领取",
            },
            {
                name: "total",
                action_name: "total",
                label: "此任务包总评分",
            },
            {
                name: "totals",
                action_name: "totals",
                label: "当前小组剩余可承接分",
            }
        ],
        buttons: [
            {
                title: "确认通过",
                action: "take",
            }
        ]
    },
    9: {
        formTitle: "放弃任务包",
        formContent:[
            {
                name:"next",
                action_name: "abandon",
                label:"下一步",
                value: "任务包待选"
            },
        ],
        buttons: [
            {
                title: "确认",
                action: "abandon",
            }
        ]
    }
}
