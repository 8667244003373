import React, { Component } from "react";
import {
  SummaryForm,
  TripForm,
  FundForm,
  Upload,
  CommentForm,
} from "./form_render";
import { SubmitDrawer } from "../bottom_drawer";
import { shootingPlanAPI } from "@api";
import { configsAPI } from "@/api";
import {$error} from '@components'
import {
  formatImageFiles,
  requestMessage,
  bulkSetFormData,
  formatRecordWithCode,
} from "@gUtils";

class PlanForm extends Component {
  summaryRef = React.createRef();
  tripRef = React.createRef();
  fundRef = React.createRef();
  uploadRef = React.createRef();
  commentRef = React.createRef();
  formRef = React.createRef();

  state = {
    visible: false,
    paramId: {},
    assignee_id: "",
    list: [],
  };

  componentDidMount() {
    this.props.onRef(this);
    this.props.fetchShootingPlanConfig();
    this.fetchRelations();
  }
  // getters
  get componentStates() {
    return {
      ...this.uploadRef.current.state,
      ...this.commentRef.current.state,
      ...this.fundRef.current.state,
      ...this.tripRef.current.state,
      ...this.summaryRef.current.state
    };
  }

  onClose = () => this.setState({ visible: false });
  showDrawer = () => this.setState({ visible: true });
  setAssignee = (val) => this.setState({ assignee_id: val });

  fetchRelations = () => {
    let params = { filter: { type: 1 } };
    configsAPI.fetchRelatedUsers(params).then((res) => {
      if (res.code === 1000) {
        const { list } = res.data;
        this.setState({ list });
      }
    });
  };

  validatePlan = () => {
   const summary = this.summaryRef.current.validateForm();
   const trip =  this.tripRef.current.validateForm();
    const fund = this.fundRef.current.validateForm();
    return Promise.all([summary, trip, fund]);
  };

  submitPlan = (paramID) => {
    this.setState({ paramID });
    this.showDrawer();
  };

  savePlan = (paramID, params) => {
    this.setState({ params });
    this.planRequest({ ...paramID, shooting_plan_status: 1 });
  };
  handlePlan = (type, paramID) => {
    const cb =
      type === "submit"
        ? this.submitPlan
        : type === "save"
        ? this.savePlan
        : () => {};
    this.validatePlan().then((res) => cb(paramID));
  };

  confirmSubmit = () => {
    const { paramID, assignee_id } = this.state;
    if (!assignee_id) return $error('请选择待处理人')
    const params = { ...paramID, shooting_plan_status: 2, assignee_id };
    this.planRequest(params);
  };

  planRequest = (params) => {
    let formData = new FormData(),
      { shooting_plan_id, project_id } = params;
    formData = formatImageFiles(this.componentStates.fileList, formData);
    formData = bulkSetFormData(params, formData);
    formData = this.handleParams(formData);
    if (shooting_plan_id > 0) {
      return this.handleRequest("edit", formData);
    } else if (project_id) {
      return this.handleRequest("add", formData);
    }
  };

  handleParams = (formData) => {
    let { comment, trips, funds, area, executors } = this.componentStates;
    const { planDetails } = this.props;
    const { fund_total, time_frame } = planDetails;
    trips = trips?.map((item) => formatRecordWithCode(item));
    funds = funds?.map((item) => formatRecordWithCode(item));
    const fundTotal = Number.isFinite(fund_total) ? fund_total.toFixed(2) : 0;
    comment = comment || "";
    const params = {
      area,
      executors,
      trips,
      funds,
      comment,
      fund_total: fundTotal,
      time_frame,
    };
    return bulkSetFormData(params, formData);
  };

  handleRequest = (type, params) => {
    if (type === "edit") {
      this.handleResult(shootingPlanAPI.updatePlan(params));
    } else if (type === "add") {
      this.handleResult(shootingPlanAPI.createPlan(params));
    }
  };

  handleResult = (promise) =>
    promise
      .then((res) => {
        requestMessage(res, this.props.getUpdate(res.data.id));
      })
      .then(() => this.props.showHistory());

  render() {
    const { props } = this;
    return (
      <div>
        <SummaryForm {...props} ref={this.summaryRef} />
        <TripForm {...props} ref={this.tripRef} />
        <FundForm {...props} ref={this.fundRef} />
        <div className="mg-t-30">
          <Upload
            {...props}
            ref={this.uploadRef}
            titleClassName="fz-18 bold"
            entity={this.props.planDetails}
            showIndicator={true}
          />
        </div>
        <CommentForm {...props} ref={this.commentRef} />
        <SubmitDrawer {...props} {...this} />
      </div>
    );
  }
}

export default PlanForm;
