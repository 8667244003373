export const titleBaseStyle = {
  background: "#F5F5F5",
  height: "48px",
  paddingLeft: "18px",
  lineHeight: "48px",
  borderRight: "1px solid white",
};
export const columnStyle = {
  overflowY: "auto",
  borderRight: "1px solid #F0F0F0",
  height: "30vh",
};
