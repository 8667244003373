import { connect } from 'react-redux'
import RoleFilter from './role_filter'
import { fetchRoles, requestRoles } from "@actions/1_entities/role_actions";

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  searchRoles: (params) => dispatch(fetchRoles(params)),
  resetRoles: (params) => dispatch(requestRoles(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleFilter)
