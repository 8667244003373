import { action, flow, makeAutoObservable, observable } from 'mobx';
import { $error, $success } from '@components';
import { taskPlanAPI } from '@api';
import autoSave from '@stores/autosave';
import { taskPackageStore } from '@stores';

class TaskPlanStore {
    constructor() {
        makeAutoObservable(this);
        autoSave(this, "taskPlanStore");
    }
    @observable visible = false;
    @observable title = 1;
    @observable actionState = "";
    @observable updateTask = {};
    @observable volumnCreateStatus = true;
    @observable volumnCreateErrorPath = null;
    @observable operatorId = null;
    @observable planTaskComment = "";
    @observable commentModal = false;
    @observable comment = "";
    // 策划任务显示状态
    @action toggleVisible(bool) {
        this.visible = bool;
    }
    // 策划任务的标题
    @action setTitle(num) {
        this.title = num;
        this.toggleVisible(true);
    }
    // 单个创建策划任务校验
    @action beforeUpload(file) {
        const lessThan50M = file.size / 1024 / 1024 < 50 ;
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          $error("仅支持jpg或png格式图片，请重新选择～");
          file.status = "error";
        }
        if (!lessThan50M) {
          $error("图片大小不能超过50M, 请重新选择～");
          file.status = "error";
        }
        return false;
    };
    // 批量创建策划任务前校验
    @action beforeVolumnUploadExcel(file) {
        const index = file.name.lastIndexOf(".");
        const res = file.name.substr(index + 1);
        if (res !== "xlsx" && res !== "csv") {
          $error("请上传excel或CSV格式的文件");
          file.status = "error";
        }
        return false;
    };
    // 压缩包格式校验
    @action beforeVolumnUploadZip(file) {
        const index = file.name.lastIndexOf(".");
        const res = file.name.substr(index + 1);
        if (res !== "zip") {
          $error("请上传zip格式的文件");
          file.status = "error";
        }
        return false;
    };
    // 更新当前操作的策划任务
    @action setUpdateTask(obj) {
        this.updateTask = obj;
    };
    // 批量创建策划任务状态是否成功
    @action updateVolumnCreateStatus(bool) {
        this.volumnCreateStatus = bool;
    }
    // 批量创建策划任务失败下载路径
    @action setVolumnCreateErrorPath(str) {
        this.volumnCreateErrorPath = str;
    }
    // 评论策划任务弹框
    @action setCommentModal(bool) {
        this.commentModal = bool;
    }
    // 当前操作的策划任务的Id
    @action setOperatorId(num, type, comment) {
        this.operatorId = num;
        switch (type) {
            case "approve":
               return  this.approvePlanTask();
            case "disapprove":
                return  this.disapprovePlanTask();
            case "discard":
               return  this.discardPlanTask();
            case "comment":
                this.setCommentModal(true);
                break;
            default:
                break;
        }
    }
    // 评论策划任务
    @action setPlanTaskComment(text) {
        this.planTaskComment = text;
        this.planTaskComment !== "" && this.commentPlanTask();
    }
    // 创建策划任务
    createPlanTask = flow(function* (params) {
        try {
            const res = yield taskPlanAPI.createPlanTask(params);
            if (res.code === 1000) {
                $success(res.msg);
                taskPackageStore.getPlanTaskOutList();
                taskPackageStore.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    //修改更新策划任务
    updatePlanTask = flow(function* (params) {
        try {
            const res = yield taskPlanAPI.updatePlanTask(params);
            if (res.code === 1000) {
                $success(res.msg);
                taskPackageStore.updatePlanningPage(1)
                taskPackageStore.getPlanTaskOutList()
                taskPackageStore.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    // 批量创建策划任务
    volumnCreatePlanTask = flow(function* (params) {
        try {
            const res = yield taskPlanAPI.volumnCreatePlanTask(params);
            if (res.code === 1000) {
                if (res.data.failed_count) {
                    this.setVolumnCreateErrorPath(res.data.error_url);
                    this.updateVolumnCreateStatus(false);
                } else {
                    $success(res.msg);
                    this.updateVolumnCreateStatus(true);
                }
                taskPackageStore.getPlanTaskOutList()
                taskPackageStore.updatePackageList();
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    // 通过策划任务
    approvePlanTask = flow(function* () {
        try {
            const res = yield taskPlanAPI.approvePlanTask({
                id: this.operatorId
            });
            if (res.code === 1000) {
                taskPackageStore.getPlanTaskOutList()
                $success(res.msg)
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    // 驳回策划任务
    disapprovePlanTask = flow(function* (id) {
        try {
            const res = yield taskPlanAPI.disapprovePlanTask({
                id: this.operatorId
            });
            if (res.code === 1000) {
                $success(res.msg);
                taskPackageStore.updatePlanningPage(1)
                taskPackageStore.getPlanTaskOutList()
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    // 作废策划任务
    discardPlanTask = flow(function* (id) {
        try {
            const res = yield taskPlanAPI.discardPlanTask({
                id: this.operatorId
            });
            if (res.code === 1000) {
                $success(res.msg);
                taskPackageStore.updatePlanningPage(1)
                taskPackageStore.getPlanTaskOutList()
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    // 评论策划任务
    commentPlanTask = flow(function* (params) {
        try {
            const res = yield taskPlanAPI.commentPlanTask({
                id: this.operatorId,
                comment: this.planTaskComment,
            });
            if (res.code === 1000) {
                $success(res.msg);
                this.setCommentModal(false);
                taskPackageStore.getPlanTaskOutList()
            } else {
                $error(res.msg)
            }
        } catch (error) {}
    })
    // 获取策划任务列表 //TODO: taskPackageStore里的方法拿过来做分页
    // getPlanTaskList = flow(function* (params) {
    //     let data = {
    //         "page":1,
    //         "page_size": 5,
    //         "package_id":36,
    //         "filter":{
    //             "status": [1]
    //         }
    //     }
    //     try {
    //         const res = yield taskPlanAPI.getPlanTaskList(data);
    //         if (res.code === 1000) {
    //             $success(res.msg);
    //             this.setCommentModal(false);
    //         } else {
    //             $error(res.msg)
    //         }
    //     } catch (error) {}
    // })

}

const taskPlanStore = new TaskPlanStore();
export default taskPlanStore;
