/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/20
 * @description: 资产数量
 */
import { getNumberAsset, getTypeProportion } from '@api'
import { useSwr } from '@hooks'
import './index.less'
import { Select, Typography } from '@components'
import { useState } from 'react'
import { Col, Row } from 'antd'
import Column from './column'
import Pie from './pie'
import Line from './line'

const NumberAssets = function () {
    const [type, setType] = useState(1)
    const { data } = useSwr(['/api/asset/list-new-asset', type], (_, type) => getNumberAsset({ asset_type: type }), {
        suspense: true
    })

    /**
     * 类型占比
     */
    const { data: typeProportionData } = useSwr(['/api/asset-count-pie/list', type], (_, type) => getTypeProportion({ asset_type: type }), {
        suspense: true
    })
    return <div className={'page-container amps-number-assets px-5'}>
        <div>
            <Typography variant={'h3'} color={'black'} className={'d-flex align-items-center'}>
                <span className={'tips card mr-2'} />
                总览
            </Typography>
            <div className={'card mt-2 px-3 py-2 d-inline-block'}>
                <Typography variant={'body1'} color={'black'}>当前资产仓库中的总量</Typography>
                <Typography style={{ fontSize: 28 }} className={'mt-2'} variant={'body1'} weight={500} color={'primary'}>{data.count}</Typography>
            </div>
        </div>
        <div className={'mt-5'}>
            <Row gutter={24}>
                <Col span={24} className={'mb-1'}>
                    <div className={'d-flex align-items-center justify-content-between'}>
                        <Typography variant={'h3'} color={'black'} className={'d-flex align-items-center'}>
                            <span className={'tips card mr-2'} />
                            可视化
                        </Typography>
                        <div className={'d-flex align-items-center'}>
                            <Typography color={'black'} className={'mr-1'}>数据范围</Typography>
                            <div className={'flex-1'}>
                                <Select
                                    defaultValue={type}
                                    onChange={setType}
                                    options={[
                                        {
                                            label: '按月查看',
                                            value: 1
                                        },
                                        {
                                            label: '按周查看',
                                            value: 2
                                        }
                                    ]} />
                            </div>

                        </div>
                    </div>
                </Col>
                <Col sm={24} md={12}>
                    <div className={'tips2 d-flex'}>
                        <Typography color={'black'} variant={'caption'} className={'label'}>资产数量</Typography>
                        <Typography variant={'caption'} className={'value ml-1'}>所有状态为已入库的资产数量总和</Typography>
                    </div>
                    <Column data={data.new_asset_count} type={type === 1 ? '月' : '周'} />
                </Col>
                <Col sm={24} md={12}>
                    <div className={'tips2 d-flex'}>
                        <Typography color={'black'} variant={'caption'} className={'label'}>类型占比</Typography>
                        <Typography variant={'caption'} className={'value ml-1'}>已入库的资产类型与总数量占比</Typography>
                    </div>
                    <Pie data={typeProportionData.list} />
                </Col>
                <Col span={24} className={'py-3'}>
                    <div className={'tips2 d-flex'}>
                        <Typography color={'black'} variant={'caption'} className={'label'}>增长速度</Typography>
                        <Typography variant={'caption'} className={'value ml-1'}>资产总量环比上一个时间节点的增长速度</Typography>
                    </div>
                    <Line data={data.new_speed_asset} type={type === 1 ? '月' : '周'} />
                </Col>
            </Row>
        </div>
    </div>
}
export default NumberAssets
