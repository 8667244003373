import { connect } from "react-redux";
import { fetchAssets, requestAssets } from "@actions/1_entities/assets_actions";
import { fetchTaskConfig } from '@actions/2_configs/configs_actions'

import Assets from "./assets";

const mapStateToProps = (state) => ({
  assets: state.entities.assets,
  configs: state.configs
});

const mapDispatchToProps = (dispatch) => ({
    fetchAssets: (params, success, error) =>
        dispatch(fetchAssets(params, success, error)),
    requestAssets: (params) => dispatch(requestAssets(params)),
    fetchConfigs: () => dispatch(fetchTaskConfig())
});

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
