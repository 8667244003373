import axios from "axios";

const groupAPI = {
  fetchGroupTree() {
    return axios.get("/group/tree");
  },
  fetchGroups(params) {
    return axios.post("/group/list", params);
  },
  createGroup(params) {
    return axios.post("/group/create", params);
  },
  updateGroup(params) {
    return axios.put("/group/update", params);
  },
  deleteGroup(id) {
    return axios.delete("/group/delete", { data: { id } });
  },
  fetchGroupUser(id) {
    return axios.get(`/group/info?id=${id}`)
  }
};

export default groupAPI