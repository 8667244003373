import React, { Component } from "react";
import arrayMove from "array-move";
import {
  AuthButton,
  SortableTable,
  AuthIcon,
  $success,
  $error,
  ConfirmDialog,
  AddEditConfigDialog,
} from "@/components";
import { projectSettingsAPI } from "@/api";

class ConfigList extends Component {
  deleteRef = React.createRef();
  addEditRef = React.createRef();

  state = {
    list: [],
    total: null,
    isMoved: false,
    loading: false,
    editingKey: null,
  };

  columns = [
    {
      title: () => this.props.tab.title,
      dataIndex: "value",
      width: "60%",
      editable: true,
    },
    {
      title: "操作",
      render: (_, record) => this.renderOperations(record),
    },
  ];

  componentDidMount() {
    this.fetchConfigs();
  }

  componentDidUpdate(prevProps, prevState) {
    const { tab } = this.props;
    const { isMoved } = this.state;
    if (prevProps.tab !== tab) {
      this.fetchConfigs();
    }
    if (isMoved && !prevState.isMoved) {
      this.sortRequest();
    }
  }

  fetchConfigs = () => {
    const { key } = this.props.tab;
    let params = { filter: { type: key } };
    this.setState({ loading: true });
    projectSettingsAPI.fetchPlanConfigs(params).then((res) => {
      if (res.code === 1000) {
        const { list, total } = res.data;
        this.setState({ list, total, loading: false });
      }
    });
  };

  sortRequest = () => {
    const { list } = this.state;
    const { key } = this.props.tab;
    const sort = list?.map((item) => item.id);
    const params = { type: key, sort };
    this.setState({ loading: true });
    projectSettingsAPI.sortPlanConfigs(params).then((res) => {
      if (res.code === 1000) {
        this.setState({ isMoved: false });
        $success(res.msg);
      } else {
        if (res.code !== 1002) $error(res.msg);
      }
      this.setState({ loading: false });
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { list } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...list], oldIndex, newIndex).filter(
        (el) => !!el
      );
      this.setState({ list: newData, isMoved: true });
    }
  };

  showDelete = (record) => {
    this.deleteRef.current.show(record, this.props.tab.title, record.value);
  };
  showAdd = () => {
    this.addEditRef.current.show(this.props.tab);
  };

  showEdit = (record) => {
    this.addEditRef.current.show(this.props.tab, record);
  };

  renderOperations = (record) => (
    <>
      <AuthIcon
        type="icon-edit-item"
        className="icon-20 mg-r-32"
        onClick={() => this.showEdit(record)}
        permission="plan_config_update"
      />
      <AuthIcon
        type="icon-trash"
        className="icon-20"
        onClick={() => this.showDelete(record)}
        permission="plan_config_delete"
      />
    </>
  );

  renderTable = () => {
    const { list, total, loading } = this.state;
    const { tab } = this.props;

    return total > 0 || loading ? (
      <SortableTable
        columns={this.columns}
        dataSource={list}
        onSortEnd={this.onSortEnd}
        loading={loading}
        permission="plan_config_list"
      />
    ) : (
      <div
        style={{
          height: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        暂时没有{tab.title}
      </div>
    );
  };

  render() {
    return (
      <>
        <ConfirmDialog
          ref={this.deleteRef}
          update={this.fetchConfigs}
          confirmRequest={projectSettingsAPI.deletePlanConfig}
        />
        <AddEditConfigDialog
          ref={this.addEditRef}
          updateList={this.fetchConfigs}
          createRequest={projectSettingsAPI.createPlanConfig}
          updateRequest={projectSettingsAPI.updatePlanConfig}
        />
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <AuthButton type="primary" onClick={this.showAdd} permission="plan_config_create" >
              新增
            </AuthButton>
          </div>
          {this.renderTable()}
        </div>
      </>
    );
  }
}

export default ConfigList;
