import { forwardRef, useImperativeHandle } from 'react'
import { Col, Form, Input, Row, Space } from 'antd'
import OnlySelect from '@components/only_select'
import { Image } from '@components'
import MackIcon from '@components/image/mack_icon'
import PreviewImage from '@components/image/preview_image'
import { sessionAPI } from '@api'
import '@styles/views/VoidForm.less'
import { getVoidStatusOptions } from '@gUtils/getVoidStatusOptions'

const VoidForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit() {
            form.submit();
        }
    }));
    const item = props.item;
    const images = [item?.display_images, item?.analyse_images, item?.produce_images].filter(i => i?.length);
    // 预览功能
    const onPreview = (index) => {
        const current = ([].concat(...images.slice(0, index))).length;

        PreviewImage.open({
            images: [].concat(...images),
            current: current,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };
    return <div className={'amps-voidForm'}>
        <Row gutter={40}>
            <Col span={12}>
                <div className={'desc-field'}>
                    <p className={'label'}>资产编号</p>
                    <p className={'value'}>{item?.asset_uid || '--'}</p>
                </div>
            </Col>
            <Col span={12}>
                <div className={'desc-field'}>
                    <p className={'label'}>资产名称</p>
                    <p className={'value'}>{item?.asset_name || '--'}</p>
                </div>
            </Col>
            <Col span={24}>
                <div className={'desc-field-flex '}>
                    <p className={'label'}>相关图片</p>
                    <Space className={'images'} size={2}>
                        {images.map((i, k) => <Image key={k} src={i[0]} width={160} height={160}
                                                     mask={[<MackIcon onClick={() => onPreview(k)} type={'icon-a-16-chakan'} />]} />)}
                    </Space>
                </div>
            </Col>
        </Row>
        <Form form={form} layout={'vertical'} initialValues={props.initialValues}>
            <Row gutter={40}>
                <Form.Item hidden name={'id'}><input /></Form.Item>
                <Col span={12}>
                    <Form.Item label={'下一步'}>
                        <Input style={{ height: 40 }} value={'作废类型'} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'discard_type'} label={'作废类型'} rules={[
                        {
                            required: true,
                            message: '请先选择作废类型'
                        }]}>
                        <OnlySelect
                            size='large'
                            options={getVoidStatusOptions(item.task_status?.code)}
                            placeholder={'请选择作废类型'}
                            showSearch
                            optionFilterProp='children'
                            showArrow
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={'处理意见'} name={'comment'} rules={[{max: 200, type: 'string'}]}>
                        <Input.TextArea
                            maxLength={200}
                            autoSize={{
                                minRows: 5,
                                maxRows: 5
                            }}
                            placeholder={'请输入处理意见'} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>;
});
export default VoidForm;
