import Icon from '@components/icon';
import '@styles/components/mack-icon.less';

const MackIcon = function({ type, onClick, title, ...other }) {
    return (
        <div onClick={onClick} title={title} className="amps-mack-icon">
            <Icon
                type={type}
                {...other}
            />
        </div>
    );
};
export default MackIcon;
