/**
 * @name: user.js
 * @user: cfj
 * @date: 2022/4/29
 * @description:
 */

import { Server } from '@gUtils';

/**
 * 获取用户权限
 * @returns {Promise<Awaited<unknown>>}
 */
export const getUserAuth = () => Server.get('/api/account/permissions');
