import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { Modal } from "antd";
import { WechatCode } from "@components";


@inject("messagesStore")
@observer
class CodeDialog extends Component {
  get store() {
    return this.props.messagesStore;
  }
  render() {
    return (
      <Modal
        footer={false}
        visible={this.store.codeVisible}
        width="17.8vw"
        onCancel={() => this.store.hideCode()}
        centered
      >
        <div style={{ paddingBottom: "92px" }}>
          <div
            className="justify-center"
            style={{ padding: "70px 50px 20px 50px" }}
          >
            <WechatCode />
          </div>
          <div className="justify-center">请使用个人微信号扫描绑定AMP账号</div>
        </div>
      </Modal>
    );
  }
}
export default CodeDialog;