import axios from "axios";

const artStatsAPI = {
  getArtReport(date) {
    return axios.get("/shoot-stats/statistics?date=" + date);
  },
  fetchArtSummary(params) {
    return axios.post("/shoot-stats/summary", params);
  },
  fetchArtUserSummary(params) {
    return axios.post("/shoot-stats/user-summary", params);
  },
  fetchArtDetails(params) {
    return axios.post("/shoot-stats/detail", params);
  },
};

export default artStatsAPI;
