import { tableTimeFormat } from "@gUtils/functional_util";
import { Progress } from "antd";

export const start_at = "start_at";
export const estimate_finish_at = "estimate_finish_at";
export const finish_at = "finish_at";
export const project_status = "project_status";
export const code = "code";
export const total_tasks = "total_tasks";
export const progress = "progress";
export const output = "output";
export const title = "title";
export const photographer = "photographer";
export const is_public = "is_public";
export const is_confidential = "is_confidential";
export const confidential_user_ids = "confidential_user_ids";
export const description = "description";
export const creator = "creator";
export const projectDIC = {
  [start_at]: {
    title: "开始时间",
    dataIndex: start_at,
    sorter: true,
    formPlaceholder: "请选择开始时间",
  },
  [estimate_finish_at]: {
    title: "预计结项",
    dataIndex: estimate_finish_at,
    sorter: true,
    formPlaceholder: "请选择预计结项时间",
  },
  [finish_at]: {
    title: "实际结项",
    dataIndex: finish_at,
    sorter: true,
  },
  [project_status]: {
    title: "项目状态",
    dataIndex: project_status,
  },
  [total_tasks]: {
    title: "任务量",
    dataIndex: total_tasks,
    sorter: true,
  },
  [progress]: {
    title: "任务完成进度条",
    dataIndex: progress,
    sorter: true,
  },
  [output]: {
    title: "产出",
    dataIndex: output,
    sorter: true,
  },
  [code]: {
    title: "项目编号",
    dataIndex: code,
    sorter: true,
  },
  [title]: {
    title: "项目名称",
    dataIndex: title,
    formPlaceholder: "请输入不超过50个字符长度的项目名称",
  },
  [photographer]: {
    title: "拍摄计划负责人",
    formPlaceholder: "请选择拍摄计划负责人",
  },
  [is_public]: {
    title: "是否公开制作池素材待选",
    formPlaceholder: "请选择是否公开制作池素材待选",
  },
  [is_confidential]: {
    title: "是否保密",
    formPlaceholder: "请选择是否保密",
  },
  [confidential_user_ids]: {
    title: "开放人员",
    formPlaceholder: "开放人员",
  },
  [description]: {
    title: "项目描述",
    formPlaceholder: "请输入项目描述",
  },
  [creator]: {
    title: "创建人员",
  },
};

export const dateRanges = [start_at, estimate_finish_at, finish_at]?.map(
  (key) => ({
    key,
    placeholder: projectDIC[key].title,
  })
);

export const initialState = {
  code_title: "",
  participant: null,
  start_at: [],
  estimate_finish_at: [],
  finish_at: [],
  project_status: [],
  name_uid:"",
  discard_at: {},
  dates: [],
};

export const projectColumns = [
  {
    ...projectDIC.code,
    render: (code) => `#${code}`,
    width: "9%",
  },
  {
    ...projectDIC.title,
    width: "20%",
    ellipsis: true,
  },
  {
    ...projectDIC.start_at,
    render: (start_at) => tableTimeFormat(start_at),
    width: "8%",
  },
  {
    ...projectDIC.estimate_finish_at,
    render: (estimate_finish_at) => tableTimeFormat(estimate_finish_at),
    width: "8%",
  },
  {
    ...projectDIC.finish_at,
    render: (finish_at) => tableTimeFormat(finish_at),
    width: "8%",
  },
  {
    ...projectDIC.total_tasks,
    width: "8%",
  },
  {
    ...projectDIC.progress,
    width: "22%",
    render: (progress) => (
      <Progress percent={progress} className="progress-bar" />
    ),
  },
  {
    ...projectDIC.output,
    width: "8%",
  },
  {
    ...projectDIC.project_status,
    width: "9%",
    render: (project_status) => (project_status ? project_status.label : ""),
  },
];

export const addEditDic = {
  add: {
    title: "新建项目",
    okText: "创建",
  },
  edit: {
    title: "编辑项目",
    okText: "完成",
    finishText: "结项",
  },
};

export const rules = {
  title: [
    {
      required: true,
      message: "项目名称不能为空且不超过50个字符",
      max: 50,
      type: "string",
    },
  ],
  start_at: [{ required: true, message: "请选择开始时间" }],
  estimate_finish_at: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const valid = getFieldValue("start_at").isBefore(value) || !value;
        if (valid) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("预计结项时间不能早于开始时间"));
      },
    }),
  ],
  photographer: [{ required: true, message: "请选择拍摄负责人" }],
  is_public: [{ required: true, message: "请选择是否公开制作池素材待选" }],
  is_confidential: [{ required: true, message: "请选择是否保密" }],
  confidential_user_ids: [{ required: true, message: "请选择开放人员" }],
};

export const formDateRanges = [start_at, estimate_finish_at]?.map((key) => ({
  key,
  placeholder: projectDIC[key].formPlaceholder,
}));

export const nullProject = {
  title: null,
  start_at: null,
  estimate_finish_at: null,
  photographer: null,
  is_public: null,
  is_confidential: null,
  confidential_user_ids: null,
  description: null,
  images: null,
};

export const emptyProject = {
  id: null,
  code: null,
  title: "",
  description: "",
  project_status: {
    code: null,
    label: null,
  },
  assignee: {
    id: null,
    username: null,
    name: null,
  },
  creator: {
    id: null,
    username: null,
    name: null,
  },
  photographer: {
    id: null,
    username: null,
    name: null,
  },
  confidential_user_ids: [],
  shooting_plan_id: 0,
  output: null,
  progress: null,
  total_tasks: null,
  image_urls: [],
  created_at: null,
  start_at: null,
  estimate_finish_at: null,
  finish_at: null,
};

export const projectStatusIcons = [
  "",
  "icon-project-planning",
  "icon-project-ongoing",
  "icon-project-complete",
];

export const projectMenuPermissions = [
  "project_update",
  "project_complete",
  "project_delete",
];
