import { connect } from "react-redux";
import {
  fetchUserPermissions,
  login,
  finishInitialization,
  fetchTodos
} from "@actions/0_session/session_actions";

// const mapCurrentUserState = (state) => ({
//   currentUser: state.session,
// });

const mapSessionState = (state) => ({
  session: state.session,
});

const mapPermissionState = (state) => ({
  userPermissions: state.session.permissions,
});

const mapSessionDispatch = (dispatch) => ({
  login: (params) => dispatch(login(params)),
  finishInitialization: () => dispatch(finishInitialization()),
  fetchUserPermissions: () => dispatch(fetchUserPermissions()),
  fetchTodos:()=> dispatch(fetchTodos())
});

const mapPermissionDispatch = (dispatch) => ({
  fetchUserPermissions: () => dispatch(fetchUserPermissions()),
});


// export const connectCurrentUser = (component) =>
//   connect(mapCurrentUserState)(component);


export const connectUserSession = (component) =>
  connect(mapSessionState, mapSessionDispatch)(component);

export const connectPermission = (component) =>
  connect(mapPermissionState, mapPermissionDispatch)(component);