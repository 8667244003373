import { Drawer } from "antd";
import { Component } from "react";
import { DrawerCloseButton } from "@/components";
import { withRouter } from "react-router";
import { observer, inject } from "mobx-react";
@inject("filterStore")
@observer
class BaseDrawer extends Component {
  onClose = () => {
    this.props.filterStore.footerShow = true
    const { onClose } = this.props;
    const { pathname } = this.props.location;
    if (onClose){ return onClose() } else {
      this.props.history.push(pathname);
    }
  };
  render() {
    const { visible, children, className, width} = this.props;
    const icon = <DrawerCloseButton onClick={this.onClose} visible={visible} width={width||960} />;
    return (
      <div>
        {icon}
        <Drawer
          className={`drawer ${className}`}
          onClose={this.onClose}
          width={width ? `${width/19.2}vw` : "50vw"}
          visible={visible}
          mask={false}
          closable={false}
          destroyOnClose
          style={{ transform: "translateX(0)" }}
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(BaseDrawer);
