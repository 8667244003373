import { observer, inject } from "mobx-react";
import { AuthButton } from "@/components";
import "./index.less";
import { TaskButton } from "./components/task_button";
import { TasksButton } from "./components/tasks_button";
import { ReviewButton } from "./components/review_button";
import { UndertakeButton } from "./components/undertake_button";
import { AbandonButton } from "./components/abandon_button";
import { toJS } from "mobx";
/**
 * 任务包底部按钮
 * @param {*} props  参数
 * @returns
 */
const BottomButton = (props) => {
    const {
        taskPackageStore: { currentTasks, taskApproveList },
    } = props;
    const { loginUser } = props.projectStore;
    // 渲染底部按钮数据
    const renderButton = (item) => {
        let code
        if(props.taskPackageStore.isShowEditCreate) {
            code = 6
        }else {
            code = item?.status?.code || null;
        }
        switch (code) {
            // 策划中
            case 1:
                // 是否有策划任务
                let isHaveTask = true;
                // 创建任务相关按钮
                return <TaskButton isHaveTask={isHaveTask} {...props} loginUser={loginUser} />;
            // 策划待审
            case 2:
                // 任务包审核相关按钮
                return <ReviewButton {...props} loginUser={loginUser} taskApproveList={taskApproveList} />;
            // 公开待选
            case 3:
                // 承接任务包相关按钮
                return <UndertakeButton {...props} />;
            //已领取
            case 4:
                // 放弃任务包
                return <AbandonButton {...props} />;
            //已作废
            case 5:
                // 作废任务包
                return (
                    <AuthButton
                        onClick={() => {
                            props.handleCancel()
                        }}
                        className="mg-l-20"
                    >
                        取消
                    </AuthButton>
                );
            default:
                // 创建任务包相关按钮
                return <TasksButton {...props} />;
        }
    };

    return (
        <div className="amps-bottom-button flex-align-center">
            {renderButton(currentTasks)}
        </div>
    );
};

export default inject("projectStore")(observer(BottomButton));
