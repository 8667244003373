import { useHistory } from "react-router";
import { Empty, Row, Col } from "antd";
import { getActivityUrl } from "@gUtils";
import { Spin } from "antd";

const Activities = (props) => {
  const { projectActivities } = props;
  const list =
    projectActivities && projectActivities.list ? projectActivities.list : [];
  const history = useHistory();
  const viewAll = () => {
    history.push(`account/activities`);
  };

  const recordClick = (record) => {
    const finalUrl = getActivityUrl(record);
    history.push(finalUrl);
  };

  const renderSingleRecord = (record) => {
    const { description, date, time, object_name, type, history_id } = record;
    const person = description.split(" ")[0];
    const activity = description.split(" ").slice(1).join(" ");
    return (
      <div key={history_id} onClick={() => recordClick(record)}>
        <div className="board-hover mg-b-16">
          <Row gutter={8} justify="space-between">
            <Col span={4}>{person}</Col>
            <Col span={14}>{`${activity} ${
              type === 3 ? "#" : ""
            }${object_name}`}</Col>
            <Col span={6} className="ta-r">{`${date} ${time}`}</Col>
          </Row>
        </div>
      </div>
    );
  };

  const title = (
    <div className="between mg-b-20">
      <div className="board-title">项目变动</div>
      <div className="board-view-all board-hover" onClick={viewAll}>
        查看全部
      </div>
    </div>
  );
  let activities = list?.map((item) => renderSingleRecord(item));
  activities = activities.splice(0, 3)
  const { spinning } = props;
  return (
    <div className="board-border">
        {title}
        {
            spinning ? (
                <Spin spinning={spinning} size="large" />
            ) : (
                list.length > 0 ? activities : <Empty />
            )
        }
    </div>
  );
};
export default Activities;
