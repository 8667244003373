import { tasksAPI } from '@api'
import { RECEIVE_TASKS, RECEIVE_TASKS_ERROR, REQUEST_TASKS } from '@actions/action_consts'

export const RECEIVE_TASK = 'RECEIVE_TASK'
export const RECEIVE_TASK_ERROR = 'RECEIVE_TASK_ERROR'

// action creators
export const requestTasks = (params) => ({
    type: REQUEST_TASKS,
    params
})

export const receiveTasks = (params, tasks) => ({
    type: RECEIVE_TASKS,
    params,
    tasks
})

export const receiveTasksErr = (error) => ({
    type: RECEIVE_TASKS_ERROR,
    error
})

// back end requests
export const fetchTasks = (
    params,
    success = () => {}, error = () => {}
) => (dispatch) => {
    dispatch(requestTasks(params))
    tasksAPI.fetchTasks(params)
        .then((res) => {
            if (res.code === 1000) {
                const successDispatch = dispatch(receiveTasks(params, res.data))
                success(res)
                localStorage.setItem(`project_${params.project_id}`, res.time)
                return successDispatch
            } else {
                const errDispatch = dispatch(receiveTasksErr(res.msg))
                error()
                return errDispatch
            }
        })
}

