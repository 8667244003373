import React, { Component } from 'react'
import { Input } from 'antd'
import { initState } from '@vars/views/settings_role_vars'
import { debounce } from '@gUtils';
class RoleFilter extends Component {
    // 初始化渲染
    state = initState;

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.searchRequest();
        }
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentWillUnmount() {
        // this.props.fetchAssets({});
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        // 清除redux表格记录的筛选数据
        this.props.resetRoles({})
    }

    // 赋值
    handleStateFun = (key, val) => this.setState({ [key]: val });

    handleName = (val) => {
        this.handleStateFun("name", val);
    };

    // 清除数据
    clear = () => {
        this.setState(initState);
    };

    searchRequest = () => {
        let { name } = this.state;
        let params = {
            page: 1,
            page_size: 12,
            filter: {
                name,
            },
        };
        this.debounceSearchRoles(params);
    };

    debounceSearchRoles = debounce(this.props.searchRoles, 300);

    render() {
        return (
            <div className="role-filter">
                <div>
                    <Input
                        placeholder="检索角色名称"
                        className="filter-input"
                        size="large"
                        value={this.state.name}
                        onChange={(val) => this.handleName(val.target.value)}
                    ></Input>
                </div>
                <div className="reset-filter" onClick={this.clear}>
                    <span>重置</span>
                </div>
            </div>
        )
    }
}
export default RoleFilter
