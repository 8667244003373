import React, { Component } from "react";
import { Form, Input, Select } from "antd";
import { dateRanges, initialState } from "@vars/views/projects_vars";
import { formatTime } from "@/utils/global/functional_util";
import { RangePicker, UserSelect } from "@/components";
import { observer, inject } from "mobx-react";
import { debounce } from '@gUtils';
const { Option } = Select;

@inject("configsStore")
@observer
class ProjectsFilter extends Component {
    state = {
        ...initialState
    }
    get configsStore() {
        return this.props.configsStore
    }
    // lifecycle methods
    componentDidMount() {
        this.props.fetchProjectConfig();
        this.configsStore.setUidName("");
        this.configsStore.setParticipant(null);
        this.configsStore.setDiscardAt({}, []);
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.debounceSearchRequest();
        }
    }

    componentWillUnmount(){
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        // 清除表格记录的筛选数据
        this.props.requestProjects({})
    }

    // functions
    clear = () => {
        this.setState(initialState);
        this.configsStore.setUidName("");
        this.configsStore.setParticipant(null);
        this.configsStore.setDiscardAt({}, []);
    };

    setStateFunc = (key, val) => {
        this.setState(() => {
            return { [key]: val }
        }, () => {
            this.configsStore.setUidName(this.state.name_uid);
            this.configsStore.setParticipant(this.state.participant);
        });
    };
    setRangeFunc = (date, key) => {
        this.setStateFunc(key, date);
    };

    handleStatusChange = (value) => {
        this.setStateFunc("project_status", value);
    };

    searchRequest = () => {
        let newState = { ...this.state };
        ["start_at", "estimate_finish_at", "finish_at"].forEach(
            (key) => (newState[key] = formatTime(this.state[key]))
        );
        let params = {
            page: 1,
            filter: newState,
            page_size: 14,
        };
        this.props.searchProjects(params);
    };

    debounceSearchRequest = debounce(this.searchRequest, 300);

    handleParticipantsChange = (val) => {
        this.setStateFunc("participant", val);
    };
    // components render functions
    dateRangesRender = () =>
        dateRanges?.map((ele, idx) => (
            <div className="mg-r-20" key={idx}>
                <RangePicker
                    placeholder={[ele.placeholder]}
                    onChange={(date) => this.setRangeFunc(date, ele.key)}
                    value={this.state[ele.key]}
                />
            </div>
        ));

    selectOptionsRender = () => {
        let { projectStatuses } = this.props;
        if (projectStatuses) {
            return Object.values(projectStatuses)?.map((ele) => (
                <Option key={ele.code} label={ele.label} value={ele.code}>
                    {ele.label}
                </Option>
            ));
        }
    };
    selectedTagsRender = (props) => {
        let { label, value } = props;
        const selectedStatus = this.state.project_status;
        const idx = selectedStatus.indexOf(value);
        const style = idx === 0 ? { marginLeft: "7px" } : {};
        label = selectedStatus.length > 1 && idx > 0 ? `/${label}` : label;
        return (
            <span key={label} style={style}>
                {label}
            </span>
        );
    };
    /**
     * 作废时间
     * @param {*} val
     */
    handlesRangePickerValue = (val) => {
        if (val === null) {
            this.setState({
                dates: [],
                discard_at: {}
            }, () => {
                this.configsStore.setDiscardAt({}, []);
            })
        } else {
            this.setState({
                dates: val,
                discard_at: {
                    from: val[0].format('YYYY-MM-DD'),
                    to: val[1].format('YYYY-MM-DD')
                }
            }, () => {
                this.configsStore.setDiscardAt(this.state.discard_at, this.state.dates);
            })
        }
    }

    /**
     * 不可选日期
     * @param {*} current
     * @returns tooEarly || tooLate
     */
    disabledDate = current => {
        const { dates } = this.state
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'months') > 2;
        const tooEarly = dates[1] && dates[1].diff(current, 'months') > 2;
        return tooEarly || tooLate;
    };

    render() {
        return <div className="project-filters">
            <Input
                allowClear
                onChange={(e) => this.setStateFunc("code_title", e.target.value)}
                placeholder="检索项目编号\名称"
                value={this.state.code_title}
                size="large"
                className="filter-input mg-r-20  mg-b-20"
            />
            <Input
                allowClear
                onChange={(e) => this.setStateFunc("name_uid", e.target.value)}
                placeholder="检索资产名称\内部ID"
                value={this.state.name_uid}
                size="large"
                className="filter-input mg-r-20 mg-b-20"
            />
            {this.dateRangesRender()}
            <UserSelect
                placeholder="参与人员"
                className="filter-input mg-r-20 mg-b-20"
                value={this.state.participant}
                onChange={this.handleParticipantsChange}
            />
            <Select
                mode="multiple"
                placeholder="全部状态"
                className="filter-input mg-b-20"
                onChange={this.handleStatusChange}
                value={this.state.project_status}
                tagRender={this.selectedTagsRender}
                allowClear
                showArrow
                maxTagCount={3}
                showSearch
                optionFilterProp="children"
            >
                {this.selectOptionsRender()}
            </Select>
            <RangePicker
                value={this.state.dates}
                className="mg-l-20"
                size='size'
                placeholder={['作废时间']}
                disabledDate={this.disabledDate}
                onCalendarChange={(val) => {
                    this.setState({
                        dates: val
                    })
                }}
                onChange={val => this.handlesRangePickerValue(val)}
            />
            <div className="reset-filter" onClick={this.clear}>
                <span>重置</span>
            </div>
        </div>
    };
}

export default ProjectsFilter;
