import { RECEIVE_TASK, RECEIVE_TASKS, RECEIVE_TASK_ERROR, RECEIVE_TASKS_ERROR } from "@actions/action_consts";

const tasksErrorsReducer = (state = null, action = {} ) => {
    switch (action.type) {
        case RECEIVE_TASKS_ERROR:
            return action.error || null
        case RECEIVE_TASKS:
            return null
        case RECEIVE_TASK_ERROR:
            return  action.error || null
        case RECEIVE_TASK:
            return null
        default:
            return state
    }
}

export default tasksErrorsReducer