import "./index.less";
import { priorityIcon } from "@vars/views/tasks_vars";
import { Iconfont } from "@/components";
import { Image } from "antd"
import env from "env";

/**
 * 任务包列表的单个任务卡片
 * @param {*} props  参数
 * @returns
 */
export const TaskItem = (props) => {
  const {
    item,
    item: { items },
    onClickItem,
    selectId,
  } = props;

  // 获取图片列表
  const getImgList = () => {
    if (items && items.length > 0) {
      if (items.length > 10) {
        return items.slice(0, 10);
      } else {
        return items;
      }
    } else {
      return [];
    }
  };
  /**
   * 绘画圆
   * @returns
   */
  const renderCircle = () => (
    <div className="ellipsis flex-center">
      {[1, 2, 3].map((index) => (
        <div key={index} className="ellipsis-circle"></div>
      ))}
    </div>
  );

  return (
    <div className={`amps-task-item`} onClick={() => onClickItem(item)}>
      <div
        className={`amps-task-item-body ${
          selectId === item.id && "item-select"
        }`}
      >
        <div className="item-state flex-space-between">
          <div className="state-icon flex">
            <div className="assets flex-center">
              <img
                alt=""
                className="assets-img"
                src={`${process.env.PUBLIC_URL}/images/taskPackage/taskImg.png`}
              ></img>
              <div className="assets-text">{item.item_count || 0}个</div>
            </div>
            <div className="score flex-center">
              <img
                alt=""
                className="score-img"
                src={`${process.env.PUBLIC_URL}/images/taskPackage/scoreImg.png`}
              ></img>
              <div className="score-text">{item.score || 0}分</div>
            </div>
          </div>
          <div
            className="state-text flex-center"
          >
              <span
                style={{
                    background: item.status.color,
                    padding: "1px 5px",
                }}
              >
                {item.status.label}
              </span>
              <div>
                {priorityIcon[item.priority] ? (
                    <Iconfont
                        type={priorityIcon[item.priority]}
                        className="task-priority-icon mg-l-4 mg-t-4"
                    />
                ) : (
                    ""
                )}
              </div>
          </div>
        </div>

        <div className="item-title flex">
          <div className="title-id">{item.no}</div>
          <div className="title-text">{item.title}</div>
        </div>
        {items && items.length > 0 && (
          <div className="item-images flex-item-wrap">
            {getImgList().map((itemObj, index) => (
              <div key={index} className="images-item flex-center">
                <Image
                  alt=""
                  fallback={env.placeImage}
                  src={
                    itemObj.images &&
                    itemObj.images.length > 0 &&
                    itemObj.images[0]
                  }
                  preview={{
                      visible: false,
                      mask: null,
                  }}
                ></Image>
                {index === 9 && renderCircle()}
              </div>
            ))}
          </div>
        )}

        {item.description && (
          <div className="item-details">
            {item.description}
            {/* 我也不知道想备注什么，反正也是非必填的反正也是非必填的反正也是非必填的... */}
          </div>
        )}
      </div>
    </div>
  );
};
