/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/3/24
 * @description: 图片
 */
import { useEffect, useRef, useState } from 'react';
import { Image } from 'antd';
import useOnScreen from '../../../hooks/useOnScreen';
import useDebounce from '../../../hooks/useDebounce';
import { clsx } from '@gUtils';
import env from 'env';

/**
 * 图片的懒加载
 * @param {object} props
 * @param {boolean} props.disabledLazyLoading 禁用懒加载功能
 * @param {number} props.delay [props.delay=500] 防抖间隔时间
 * @returns {JSX.Element}
 * @constructor
 */
const LazyLoadingImage = function (props) {
    const {
        src,
        delay = 500,
        disabledLazyLoading,
        ...other
    } = props;
    const imgRef = useRef();
    const [urlSource, setUrlSource] = useState('');
    const isVisible = useOnScreen(imgRef);
    const fn = useDebounce(setUrlSource, delay, []);
    useEffect(() => {
        if (disabledLazyLoading) {
            return;
        }
        if (isVisible) {
            fn(src);
        }
        if (!isVisible && !urlSource) {
            fn('');
        }
    }, [isVisible, src, urlSource, disabledLazyLoading]);
    return (
        <div
            ref={imgRef}
            style={{ height: other.height, width: other.width, ...other.style }}
            className={clsx(other.className, 'ant-image')}
        >
            <Image fallback={env.placeImage} src={disabledLazyLoading ? src : urlSource} {...other} />
        </div>
    )
};
export default LazyLoadingImage
