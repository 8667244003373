import { HistoryHeader } from "@/components";

const TitlePanel = (props) => {
  const { history_detail, history_id } = props;
  return (
    <HistoryHeader {...props} />
  );
};

export default TitlePanel;
