import React, { Component } from 'react';
import { ProjectsFilterContainer, ProjectsTableContainer } from './';
import { AddEditProject } from '../dialogs';
import { nullProject } from '@vars/views/projects_vars';
import { AuthButton } from '@/components';
import { inject, observer } from 'mobx-react';
import PoolModel from '@views/pool';

@inject("projectStore")
@observer
class ProjectIndex extends Component {
    addEditRef = React.createRef();
    poolRef = null

    componentDidMount() {
        this.store.fetchUserPermissions();
    }

    componentWillUnmount () {
        this.store.setShowTotal(0);
    }

    get store () {
        return this.props.projectStore;
    }

    addNewPop = () => {
        this.addEditRef.current.show("add");
    };

    render() {
        const { userPermissions, showTotal } = this.store;
        return (
            <div style={{ paddingTop: "12px", boxSizing: "border-box" }}>
                <div className="projects-head">
                    <ProjectsFilterContainer {...this.props} />
                    <AuthButton permission="project_create" type="primary" onClick={this.addNewPop}>
                        新建项目
                    </AuthButton>
                    <AuthButton className="mg-l-10 blue-btn" onClick={() => {
                        this.poolRef.open()
                    }}>
                        制作池待选
                        <span className="mg-l-4">
                            {showTotal}
                        </span>
                    </AuthButton>
                </div>
                <div className="table-list-content">
                    <ProjectsTableContainer />
                </div>
                <div className="dialog">
                    <AddEditProject
                        ref={this.addEditRef}
                        currentProject={nullProject}
                        {...this.props}
                    />
                </div>
                <PoolModel ref={(ref) => this.poolRef = ref} />
            </div>
        );
    };
}

export default ProjectIndex;
