import React, { Component } from "react";
import { $success, $error, AuthButton } from "@/components";
import { Modal, Form, Input } from "antd";
const initialState = {
  visible: false,
  loading: false,
  record: {},
  tab: {},
  value: null,
};

class AddEditConfigDialog extends Component {
  formRef = React.createRef();
  state = initialState;

  show = (tab, record = {}) => {
    this.setState({ visible: true, tab, record, value: record.value });
  };
  hide = () => {
    this.setState(initialState);
  };

  handleSubmit = () =>
    this.formRef.current.validateFields().then((value) => {
      this.startLoad();
      let params = { ...value };
      this.saveRequest(params);
    });

  startLoad = () => {
    this.setState({ loading: true });
  };

  finishLoad = () => {
    this.setState({ loading: false });
  };

  saveRequest = (params) => {
    const { createRequest, updateRequest } = this.props;
    const { record } = this.state;

    let request = record.id ? updateRequest : createRequest;
    let reqParams = record.id ? { ...params, id: record.id } : params;

    request(reqParams).then((res) => {
      if (res.code === 1000) {
        $success(res.msg);
        this.success();
      } else {
        if (res.code !== 1002) $error(res.msg);
      }
      this.finishLoad();
    });
  };

  success = () => {
    this.props.updateList();
    this.hide();
  };

  onInputChange = (e) => {
    this.setState({ value: e.target.value });
  };

  validateCode = (_, value) => {
    const regEnglish = /^[A-Za-z]+$/;
    if (!value) return Promise.reject("资产简称不能为空");
    let flag = regEnglish.test(value);
    if (!flag) return Promise.reject("简称须为英文");
    if (value.length !== 2) return Promise.reject("简称须为两位英文字符");
    return Promise.resolve();
  };

  render() {
    const { visible, tab, loading, record } = this.state;
    const title = record.id ? `编辑${tab.title}` : `新增${tab.title}`;
    return (
      <Modal
        centered
        visible={visible}
        onCancel={this.hide}
        destroyOnClose
        footer={null}
        width="550px"
      >
        <div className="dialog-pd">
          <div className="fz-32 bold mg-b-30">{title}</div>
          <Form
            layout="vertical"
            ref={this.formRef}
            validateTrigger="onBlur"
            preserve={false}
          >
            <div className="dialog-content">
              <Form.Item
                className="form-item"
                name="value"
                rules={[{ required: true, message: `请填写${tab.title}` }]}
                label={tab.title}
              >
                <Input className="normal-input"></Input>
              </Form.Item>
              {tab.key === 4 ? (
                <Form.Item
                  className="form-item"
                  name="code"
                  rules={[{ required: true, validator: this.validateCode }]}
                  label="简称"
                >
                  <Input className="normal-input"></Input>
                </Form.Item>
              ) : (
                ""
              )}
            </div>
            <div className="dialog-footer">
              <Form.Item className="mg-t-42">
                <AuthButton
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={this.handleSubmit}
                  htmlType="submit"
                >
                  确认
                </AuthButton>
                <AuthButton key="back" onClick={this.hide} className="mg-l-20">
                  取消
                </AuthButton>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default AddEditConfigDialog;
