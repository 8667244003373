import React, { Component } from "react";
import { LabeledInput, Editor, AuthButton, $error } from "@/components";
import { withRouter } from "react-router";
const emptyArticle = { title: "", content: "" };
class AddEdit extends Component {
  editorRef = React.createRef();
  state = { ...this.currentArticle };

  get articleId() {
    const { params } = this.props.match;
    const { id } = params;
    return id;
  }
  get currentArticle() {
    const { announcements } = this.props;
    return announcements[this.articleId] || emptyArticle;
  }

  componentDidMount() {
    if (this.articleId !== "new") {
      this.getArticle();
    }
  }

  getArticle = () => {
    this.props.fetchAnnouncement(this.articleId, this.checkAnnouncement);
  };

  checkAnnouncement = () => {
    if (this.currentArticle.status === 1)
      this.props.history.push("/settings/announcements");
  };

  handleTitleChange = (e) => {
    const title = e.target.value;
    this.setState({ title });
  };

  handleContentChange = (content) => {
    this.setState({ content });
  };

  handleSave = () => {
    const { title } = this.state;
    if (!title) return $error("公告名称不能为空～");
    if (this.articleId === "new") {
      this.props.createAnnouncement(this.state, this.goBack);
    } else {
      this.props.updateAnnouncement(this.state, this.goBack);
    }
  };

  goBack = () => {
    this.props.history.push("/settings/announcements");
  };

  topSectionRender = () => (
    <div className="display-flex align-center space-between mg-b-16">
      <LabeledInput
        label="公告名称"
        required
        value={this.state.title}
        onChange={this.handleTitleChange}
        maxLength={50}
      />
      <div>
        <AuthButton
          type="primary"
          className=" mg-r-20"
          onClick={this.handleSave}
        >
          保存
        </AuthButton>
        <AuthButton onClick={this.goBack}>取消</AuthButton>
      </div>
    </div>
  );

  render() {
    return (
      <div>
        {this.topSectionRender()}
        <Editor
          handleContentChange={this.handleContentChange}
          setContents={this.currentArticle.content}
        />
      </div>
    );
  }
}

export default withRouter(AddEdit);
