import React, { Component } from "react";
import { Iconfont } from "@/components";
import { withRouter } from "react-router";
import { Input, Pagination } from "antd";
class Notifications extends Component {
  state = {
    notifications: [],
    title: "",
    total: null,
    page: 1,
    page_size: 13,
  };
  componentDidMount() {
    this.requestNotifications();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.title !== this.state.title) {
      this.requestNotifications("search");
    } else if (prevState.page !== this.state.page) {
      this.requestNotifications();
    }
  }

  requestNotifications = async (type) => {
    let { page, page_size, title, notifications } = this.state;
    const onSearch = type === "search";
    page = onSearch ? 1 : page;
    let params = { page, page_size, filter: { title } };
    const res = await this.props.fetchNotifications(params);
    const { list, total } = res;
    page = res.page;
    notifications = list;
    this.setState({ notifications, total, page });
  };

  onAnnouncementClick = (id) => {
    window.open(`/announcements/${id}`, "_blank");
  };
  handleTitleChange = (e) => {
    const title = e.target.value;
    this.setState({ title });
  };

  handlePageChange = (page, page_size) => {
    this.setState({ page, page_size });
  };

  recordRender = (announcement) => {
    let { title, id, has_read, published_at } = announcement;
    published_at = published_at.split(" ")[0].replace(/-/g, "/");
    const newIcon = !has_read ? (
      <Iconfont type="icon-new" className="icon-new" />
    ) : (
      ""
    );
    return (
      <div
        key={id}
        className="record-list"
        onClick={() => this.onAnnouncementClick(id)}
      >
        <div className="display-flex align-center">
          <span className={`mg-r-10 fz-14`}>
            {title}
          </span>
          {newIcon}
        </div>
        <div className="mg-r-32">{published_at}</div>
      </div>
    );
  };

  render() {
    const { notifications, title, total, page } = this.state;
    const list = notifications?.map((item) => this.recordRender(item));
    const prefix = (
      <Iconfont
        type="icon-search"
        className="icon-20 mg-r-16 cursor-pointer"
      />
    );
    return (
      <div>
        <h3 className="mg-tb-24">通知公告</h3>
        <div className="mg-b-16">
          <Input
            size="large"
            value={title}
            onChange={this.handleTitleChange}
            allowClear
            prefix={prefix}
          />
        </div>
        <div style={{ minHeight: "69.4vh" }}>{list}</div>
        <Pagination
          defaultPageSize={13}
          total={total}
          showQuickJumper
          onChange={this.handlePageChange}
          current={page}
          showTotal={(total) => `共 ${total} 条`}
          showSizeChanger={false}
        />
      </div>
    );
  }
}

export default withRouter(Notifications);
