import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Skeleton } from 'antd';
import { sceneEventBus } from '@/event_bus'
import DetailsTitle from '../../topic_details/details_title';
import SceneDetailsContent from '../components/scene_details_content';
import SceneDetailsFooter from '../components/scene_details_footer';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import DispatchApi from '@/api/grpcApi/dispatch/dispatch_api';
import './index.less';

class SceneDetails extends Component {
    state = {
        sceneId: 0,
        details: {},
        annotationList: [],
        loadingAnnotation: true,
        relatedInfo: {
            topicList: [],
            projectList: [],
            taskPackageList: []
        },
        historyList: [],
        footerList: [],
        planUserList: {},
        progressList: [],
        childrenFn: null
    };

    componentDidMount = async () => {
        await this.fetchProgressList();
        await this.fetchSceneDetails();
    };

    // 获取节点数据
    fetchProgressList = () => {
        DispatchApi()
            .listCoreSchedulerStepGroup({
                types: 3 // 场景
            })
            .then((res) => {
                this.setState(() => {
                    return {
                        ...this.state,
                        progressList: res?.listList
                    };
                });
            });
    };

    // 获取子元素Drawer的open方法
    getChildrenOpen = (fn) => {
        this.setState(() => {
            return {
                ...this.state,
                childrenFn: fn
            };
        });
    };

    fetchSceneDetails = () => {
        this.setState(
            () => {
                return {
                    ...this.state,
                    sceneId: 0,
                    details: {},
                };
            },
            () => {
                const { history } = this?.props;
                const { id } = this.state;
                const params = new URLSearchParams(history?.location?.search);
                const requestFlag = id !== Number(params.get('sceneId'));
                requestFlag &&
                    SelectedTopicApi()
                        .getTopicSceneInfo({
                            scene_id: Number(params.get('sceneId'))
                        })
                        .then((res) => {
                            const temp = {};
                            temp.id = res?.topicDetail?.id;
                            temp.name = res?.topicDetail?.name;
                            temp.number = res?.topicDetail?.number;
                            this.setState({
                                ...this.state,
                                details: res?.detail,
                                relatedInfo: {
                                    topicList: [temp]
                                },
                                planUserList: res?.planUserList,
                                historyList: res?.historyListList,
                                footerList: res?.operationsList,
                                sceneId: Number(params.get('sceneId'))
                            });
                        })
                        .catch((err) => {
                            throw new Error(err);
                        });
            }
        );
    };

    /**
     * 关闭弹框
     */
    cancelHandle = () => {
        this.setState(
            () => {
                return {
                    sceneId: 0,
                    details: {},
                    annotationList: [],
                    loadingAnnotation: true,
                    relatedInfo: {
                        topicList: [],
                        projectList: [],
                        taskPackageList: []
                    },
                    historyList: [],
                    footerList: [],
                    planUserList: {},
                    progressList: [],
                    childrenFn: null
                };
            },
            () => {
                const { history } = this?.props;
                history?.push('/select-topic-management/uphold/scene-manage');
                sceneEventBus.emit('sceneEventBus')
            }
        );
    };

    render() {
        const {
            details,
            annotationList,
            loadingAnnotation,
            relatedInfo,
            sceneId,
            historyList,
            footerList,
            planUserList,
            progressList,
            childrenFn
        } = this.state;
        const finalDetails = {
            ...details,
            planUserList: planUserList
        };
        const flag = JSON.stringify(details) === '{}';
        return (
            <Modal
                visible={true}
                width="auto"
                className="amps-scene-details-modal"
                title={<DetailsTitle name={details?.name} number={details?.number} />}
                onCancel={() => {
                    this.cancelHandle();
                }}
                footer={
                    <SceneDetailsFooter
                        topicId={details?.topicId}
                        list={footerList}
                        id={sceneId}
                        open={childrenFn}
                        fetchSceneDetails={this.fetchSceneDetails}
                    />
                }>
                {flag ? (
                    <Skeleton active />
                ) : (
                    <SceneDetailsContent
                        details={finalDetails} // 场景详情
                        relatedInfo={relatedInfo} // 关联信息(选题/项目/任务包)
                        sceneId={sceneId}
                        historyList={historyList}
                        progressList={progressList}
                        getChildrenOpen={this.getChildrenOpen}
                    />
                )}
            </Modal>
        );
    }
}

export default withRouter(SceneDetails);
