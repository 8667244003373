import * as React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Table, Pagination } from "antd";
import { detailColumns } from "@vars/views/stats_vars";


@withRouter
@inject("detailStore")
@observer
class DetailTable extends React.Component {
  get store() {
    return this.props.detailStore;
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  componentDidMount() {
    this.handlePageChange(1)
  }
  handlePageChange = (page) => {
    this.store.updatePage(page);
  };

  render() {
    const { table, page, total, loading } = this.store;
    return (
      <div>
        <Table
          dataSource={table}
          columns={detailColumns[this.statType]}
          pagination={false}
          rowKey={(record) => record.id}
          loading={loading}
          className="detailsTable"
        />

        <Pagination
          defaultPageSize={7}
          total={total}
          showQuickJumper
          onChange={this.handlePageChange}
          current={page}
          showTotal={(total) => `共 ${total} 条`}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
export default DetailTable;
