import { accountAPI } from "@api";
import { $success, $error } from "@/components";
import {
  RECEIVE_USER,
  RECEIVE_USERS_ERR,
  RECEIVE_VALIDATION_TOKEN,
} from "@actions/action_consts";

const receiveUser = (data) => ({
  type: RECEIVE_USER,
  data,
});

const receiveUsersErr = (err) => ({
  type: RECEIVE_USERS_ERR,
  err,
});

const receiveValidationToken = (validationToken) => ({
  type: RECEIVE_VALIDATION_TOKEN,
  validationToken,
});

export const accountUpdate = (params, success = () => {}, error = () => {}) => (
  dispatch
) =>
  accountAPI.accountUpdate(params).then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
      return dispatch(receiveUser(res.data));
    } else {
      error();
            if (res.code !== 1002) $error(res.msg);

      return dispatch(receiveUsersErr(res.msg));
    }
  });

export const fetchProfile = (
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) => {
  accountAPI.fetchProfile().then((res) => {
    if (res.code === 1000) {
      success();
      return dispatch(receiveUser(res.data));
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUsersErr(res.msg));
    }
  });
};

export const sendCode = (
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) => {
  accountAPI.sendCode().then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
      return;
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUsersErr(res.msg));
    }
  });
};

export const validateCode = (
  params,
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) => {
  accountAPI.validateCode(params).then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
      return dispatch(receiveValidationToken(res.data.token));
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUsersErr(res.msg));
    }
  });
};

export const changePassword = (
  params,
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) => {
  accountAPI.changePassword(params).then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUsersErr(res.msg));
    }
  });
};

export const fetchPermissionDetails = (
  success = () => {},
  error = () => {}
) => (dispatch) =>
  accountAPI.fetchPermissionDetails().then((res) => {
    if (res.code === 1000) {
      success();
      return res.data;
    } else {
      error();
    }
  });

export const fetchNotifications = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  accountAPI.fetchNotifications(params).then((res) => {
    if (res.code === 1000) {
      success();
      return res.data;
    } else {
      error();
    }
  });

export const fetchProjectActivities = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  accountAPI.fetchProjectActivities(params).then((res) => {
    if (res.code === 1000) {
      success();
      return res.data;
    } else {
      error();
    }
  });
