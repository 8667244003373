import { AuthButton } from "@/components";
export const ApprovalButtons = (props) => {
  const { showApprovalDrawer } = props;
  return (
    <>
      <AuthButton
        type="primary"
        className=" mg-lr-8"
        onClick={() => showApprovalDrawer("approve")}
        permission="shooting_plan_approve"
      >
        通过计划
      </AuthButton>

      <AuthButton
        className=" mg-lr-8"
        onClick={() => showApprovalDrawer("disapprove")}
        permission="shooting_plan_disapprove"
      >
        驳回计划
      </AuthButton>
    </>
  );
};

export const HistoryButtons = (props) => {
  const { onEditPlan, planStatus } = props;
  return (
    <AuthButton
      permission="shooting_plan_update"
      type="primary"
      className=" mg-lr-8"
      onClick={onEditPlan}
    >
      {planStatus > 1 ? "修改计划" : "编辑计划"}
    </AuthButton>
  );
};

export const FormButtons = (props) => {
  const { onCancel, onHandlePlan, planStatus } = props;
  return (
    <>
      <AuthButton
        type="primary"
        className=" mg-lr-8"
        onClick={() => onHandlePlan("submit")}
      >
        提交计划
      </AuthButton>
      {planStatus < 3 ? (
        <AuthButton
          type="primary"
          className=" mg-lr-8"
          onClick={() => onHandlePlan("save")}
        >
          保存计划
        </AuthButton>
      ) : (
        ""
      )}

      <AuthButton className=" mg-lr-8" onClick={onCancel}>
        取消
      </AuthButton>
    </>
  );
};
