import React, { memo, useEffect, useRef, useState, useMemo } from 'react';
import { sceneEventBus } from '@/event_bus'
import ScrollContainer from '@/components/scroll_container';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import SceneItem from './scene_item';
import './index.less'

// interface ISceneWrapProps {
//     topicId: string | number;
// }
/**
 * 场景管理列表-场景
 * @param {ISceneWrapProps} props props
 * @constructor
 */
const SceneWrap = (props) => {
    const { searchData, selectTopic } = props;
    const topicRef = useRef(null);
    const [allData, setAllData] = useState([]);
    const searchParams = useMemo(() => {
        let params = searchData ? { ...searchData } : {}
        if (selectTopic && Object.hasOwn(selectTopic, 'id')) {
            params.topicId = selectTopic.id
        }
        return params
    }, [searchData, selectTopic])

    useEffect(() => {
        /**
         * 刷新列表
         */
        const reFresh = () => {
            topicRef.current?.refresh()
        }

        const off = sceneEventBus.on(reFresh);
        return off
    }, [sceneEventBus]);

    return (
        <ScrollContainer
            ref={topicRef}
            pageSize={20}
            col={4}
            height="100%"
            className="amp-topic-scene-scroll"
            params={searchParams}
            renderItem={(item, key) => (
                <SceneItem
                    data={item}
                    key={key}
                />
            )}
            renderAllData={(val) => setAllData(val)}
            request={(page, pageSize, params) => SelectedTopicApi().listTopicSceneAddress({
                page,
                page_size: pageSize,
                ...params
            }).then((res) => {
                return {
                    list: res.listList,
                    total: res.total
                }
            })}
        />
    );
};

export default memo(SceneWrap);
