import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { persistedRootReducer } from '@reducers';

// persistStore解决数据持久化存储问题，细化配置在persistedRootReducer文件内
// 使用thunk作为middleware处理异步数据
const applyMiddle = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk)

export const store =  createStore(persistedRootReducer, {}, applyMiddle)
export const persist = persistStore(store)
export const configureStore = (preloadedState = {}) =>
  createStore(persistedRootReducer, preloadedState, applyMiddle);

export const configurePersistor = (store) => persistStore(store);
