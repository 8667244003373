import React, { useState, useEffect, } from 'react'
import { Col, Row, Select, Form } from 'antd';
import ColumnSlider from './template/column_slider';
import Pie from './template/pie';
import { getVoidStatusOptions } from '@gUtils/getVoidStatusOptions';
import { getDiscardProjectSummary } from '@api'
import { $error } from '@components';

/**
 * 作废项目维度图表ui
 */
const ProjectChart = (props) => {
    const [searchData, setSearchData] = useState({})
    const [loading, setLoading] = useState(false)
    const [discardData, setDiscardData] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        //发起请求获取作废信息
        setLoading(true)
        getDiscardProjectSummary(searchData).then((res) => {
            setLoading(false)
            setDiscardData(res?.discard_summary || [])
        }).catch((err) => {
            setLoading(false)
            $error(err.message);
        });
    }, [searchData])

    return (
        <div>
            <Form
                className='amp-destroy-chart-from'
                form={form}
                initialValues={{}}
                onValuesChange={(changedValues, allValues) => setSearchData(allValues)}
            >
                <div
                    className="amp-destroy-chart-from-title"
                >
                    数据范围
                </div>
                <Form.Item
                    name="discard_type"
                >
                    <Select
                        size="large"
                        placeholder="请选择作废类型"
                        className="select-sz-m amp-mr-10"
                        allowClear
                        getPopupContainer={triggerNode => triggerNode.parentElement}
                        showSearch
                        optionFilterProp="children"
                        options={getVoidStatusOptions().map((i) => ({
                            label: i.label,
                            value: i.code
                        }))}
                    >
                    </Select>
                </Form.Item>
            </Form>

            <Row gutter={24}>

                {/* 作废总数 */}
                <Col span={12} className="amp-pt-16">
                    <div className="amp-destroy-tips2">
                        <span>作废总数</span>
                    </div>
                    <ColumnSlider
                        data={discardData}
                        type='项目'
                        loading={loading}
                        xField="project_name"
                        yField="discard_num"
                    />
                </Col>
                {/* 作废数占比 */}
                <Col span={12} className="amp-pt-16">
                    <div className="amp-destroy-tips2">
                        <span>作废数占比</span>
                    </div>
                    <Pie
                        data={discardData}
                        xField="project_name"
                        yField="discard_num"
                        loading={loading}
                        legend={{
                            reversed: true
                        }}
                        tooltip={{}}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ProjectChart
