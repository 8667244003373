import React, { useState } from "react";
import { Iconfont } from "@components";

export const AssetImage = ({ tag, allowRemove, onRemove = () => {} }) => {
  let [hovered, setHovered] = useState(false);
  const onHover = () => setHovered(true);
  const finishHover = () => setHovered(false);
  return (
    <div
      className="draggable-img-wrapper"
      onMouseEnter={onHover}
      onMouseLeave={finishHover}
    >
      <img src={tag.task_image} alt={tag.task_name} />
      {hovered ? (
        <div className="position-absolute">#{tag?.asset_uid}</div>
      ) : (
        ""
      )}
      {hovered && allowRemove ? (
        <div className="position-absolute cursor-pointer" onClick={onRemove} style={{top:"0", right:"12px"}}>
          <Iconfont type="icon-list_deleteX1" className="icon-8" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
