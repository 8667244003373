/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Button } from 'antd';
import { $success, $error } from '@components';
import { accountAPI } from '@api';
import { useSwr } from '@hooks';
import { sceneEventBus } from '@/event_bus';
import { ApprovedForm, RejectForm, CancelForm } from './action_form';
import { getStatusOptions } from './util';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';

interface IProps {
    DrawerOpen: any,
    status: number,
    id: number,
    operationsList: any,
}
/**
 * ActionBtn
 * @param {IProps} props props
 * @returns {React.ReactNode}
 */
const ActionBtn: React.FC<IProps> = (props: IProps) => {
    const { DrawerOpen, status, id, operationsList } = props;

    const operationsBtnArr: any[] = [];
    operationsList.forEach((item: any) => operationsBtnArr.push(item.operation));

    /**
     * 获取制作组成员列表
     * @returns {object}
     */
    const getUserInfo = () => accountAPI.fetchProfile().then((res: any) => {
        if (res.code === 1000) {
            return res.data;
        }
        return null;
    });

    const { data: info } = useSwr(['/account/info'], getUserInfo);

    /**
     * 策划内部审核通过抽屉
     */
    const approved = () => {
        DrawerOpen({
            title: getStatusOptions(status, info?.name).approved.title,
            height: 440,
            content: <ApprovedForm
                initialValues={getStatusOptions(status, info?.name).approved}
            />,

            /**
             * 提交失败
             * @param {any} error error
             */
            onError(error: any) {
                $error(error.message);
            },

            /**
             * 提交成功
             * @param {any} values values
             */
            async onOk(values: any) {
                const data = {
                    commonParam: {
                        sceneId: id,
                        ...values
                    }
                };
                await SelectedTopicApi().topicSceneSchedulerHandler(data).then(() => {
                    $success('操作成功');
                    sceneEventBus.emit('sceneEventBus');
                }).catch(() => {
                    $error('操作失败');
                });
            }
        });
    };

    /**
     * 驳回抽屉
     */
    const reject = () => {
        DrawerOpen({
            title: getStatusOptions(status, info?.name).reject.title,
            height: 440,
            content: <RejectForm
                initialValues={getStatusOptions(status, info?.name).reject}
            />,
            /**
             * 提交成功
             * @param {any} values values
             */
            async onOk(values: any) {
                const data = {
                    commonParam: {
                        sceneId: id,
                        ...values
                    }
                };
                await SelectedTopicApi().topicSceneSchedulerHandler(data).then(() => {
                    $success('操作成功');
                    sceneEventBus.emit('sceneEventBus');
                }).catch(() => {
                    $error('操作失败');
                });
            }
        });
    };

    /**
     * 作废抽屉
     */
    const cancel = () => {
        DrawerOpen({
            title: '作废',
            height: 440,
            content: <CancelForm initialValues={getStatusOptions(status, info?.name).cancel} />,
            /**
             * 提交成功
             * @param {any} values values
             */
            async onOk(values: any) {
                const data = {
                    commonParam: {
                        sceneId: id,
                        ...values
                    }
                };
                await SelectedTopicApi().topicSceneSchedulerHandler(data).then(() => {
                    $success('操作成功');
                    sceneEventBus.emit('sceneEventBus');
                }).catch(() => {
                    $error('操作失败');
                });
            }
        });
    };

    return (
        <div className="amp-item-detail-bottom">
            {operationsBtnArr.includes(getStatusOptions(status, info?.name).approved.operation)
                && <Button className="amp-button" type="primary" onClick={approved}>审核通过</Button>}
            {operationsBtnArr.includes(getStatusOptions(status, info?.name).reject.operation)
                && <Button className="amp-button amp-mg-16" onClick={reject}>驳回</Button>}
            {operationsBtnArr.includes(getStatusOptions(status, info?.name).cancel.operation)
                && <Button className="amp-button" onClick={cancel}>作废</Button>}
        </div>
    );
};

export default ActionBtn;
