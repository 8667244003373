import React, { memo } from 'react';
import env from 'env';
import { Iconfont } from '@components';
import { Image } from 'antd';

interface ITopicListItemProps {
    data: any;
    checkKeys: number | string;
    checkKeysChange: (item: any) => void;
}
/**
 * 选题列表单行item
 * @param {ITopicListItemProps} props props
 * @returns {React.ReactNode}
 */
const TopicListItem: React.FC<ITopicListItemProps> = (props) => {
    const { data, checkKeys, checkKeysChange } = props;
    /**
     * 获取显示类名
     * @param {number} id id
     * @returns {string} 类名
     */
    const checkClassName = (id: number) => {
        if (checkKeys === id) {
            return 'amp-scene-item-checked';
        }
        return '';
    };
    /**
     * 显示icon
     * @param {number} id id
     * @returns {React.ReactDOM}
     */
    const checkedIcon = (id: number) => {
        if (checkKeys === id) {
            return <Iconfont type="icon-a-24-yixuan" className="fz-24 icon-hover-main" />;
        }
        return <Iconfont type="icon-a-24-weixuan" className="fz-24 icon-hover-main" />;
    };
    return (
        <div className="amp-related-topic-scroll-line">
            {Array.isArray(data)
                && data.map((item: any) => (
                    <div className="amp-related-topic-scroll-line-item" onClick={() => checkKeysChange(item)} key={item.id}>
                        <div className={`amp-scene-modal-item-wrap ${checkClassName(item.id)}`}>
                            <div className="amp-scene-modal-item-wrap-pic">
                                <div className="amp-scene-item-check">{checkedIcon(item.id)}</div>
                                <Image
                                    fallback={env.placeImage}
                                    preview={false}
                                    className="amp-scene-item-image"
                                    src={item?.imagesList?.[0]?.fileUrl || ''}
                                />
                            </div>
                            <div className="amp-scene-item-content">
                                <h5 className="amp-scene-item-content-name">{item.name}</h5>
                                <div className="amp-scene-item-content-tag">{item.number}</div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default memo(TopicListItem);
