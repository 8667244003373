import React, { Component } from "react";
import { Modal } from "antd";

import { addEditDic } from "@vars/views/projects_vars";
import AddEditForm from "./add_edit_form";

export default class AddEditProject extends Component {
  state = {
    type: "add",
    visible: false,
    confirmLoading: false,
  };

  // event handlers
  show = (type) => {
    this.setState({ type });
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  getProjectDetail = () => {};
  handleSave = (formData, cb) => {
    const { type } = this.state;
    switch (type) {
      case "add":
        this.props.createProject(formData, { success: ()=>this.success(cb), error: ()=>{} });
        break;
      case "edit":
        this.props.updateProject(formData, { success: () => this.success(cb), error:()=>{} });
        break;

      default:
        break;
    }
  };

  success = (cb) => {
    cb()
    this.hide()
  }

  render() {
    const type = this.state.type;
    return (
      <Modal
        centered
        visible={this.state.visible}
        confirmLoading={this.state.confirmLoading}
        onCancel={this.hide}
        className="large-form-dialog projectModel"
        footer={null}
        width="704px"
        destroyOnClose
      >
        <div className="dialog-title dialog-title-shadow mg-review">{addEditDic[type].title}</div>
        <div className="dialog-content">
          <AddEditForm
            type={type}
            handleCancel={this.hide}
            handleSave={this.handleSave}
            {...this.props}
          />
        </div>
      </Modal>
    );
  }
}
