/**
 * @name: CategoryFormItem.jsx
 * @user: cfj
 * @date: 2022/4/12
 * @description: 类别筛选
 */
import { getCategory } from '@api';
import { useParams } from 'react-router-dom';
import { useSwr } from '@hooks';
import { Typography } from '@components';
import { clsx } from '@gUtils';

const Category = function(props) {
    const {
        select,
        onSelect,
        className,
        ...other
    } = props;
    return <Typography className={clsx('category', select && 'category-select', className)} {...other} onClick={onSelect} />;
};

export const CategoryFormField = function(props) {
    const {
        value,
        onChange,
        className,
        ...other
    } = props;
    const params = useParams();
    const key = ['/produce/category', params.id !== undefined ? Number(params.id) : undefined]
    const { data } = useSwr(key, (a, id) => getCategory(id), {
        fallbackData: []
    });

    function onSelect(id) {
        const ids = [...value||[]];
        const index = ids.indexOf(id);
        if (index > -1) {
            if(ids.length>1){
                ids.splice(index, 1);
            } else {
                onChange([id])
            }
        } else {
            ids.push(id);
        }
        onChange(ids);
    }

    return <div className={clsx('d-flex flex-wrap', className)} {...other} >
        <Category className={'mr-2 mb-2 user-select-none'} select={value?.length === 0 || !value} onSelect={() => {
            onChange([]);
        }
        }>全部</Category>
        {data.sort((a, b) => a.sort - b.sort)
            .map(i => <Category
                key={i.id}
                className={'mr-2 mb-2 user-select-none'}
                onSelect={() => onSelect(i.id)}
                select={value?.includes(i.id)}>
                {i.value}
            </Category>)}
    </div>;
};
