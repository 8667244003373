/**
 * @name: SetTaskForm.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 创建或编辑任务
 */
import { useParams } from 'react-router-dom';
import { $error, Button, Input, Typography } from '@components';
import { Col, Form, Row, Space } from 'antd';
import PlanFormItem from '@views/taskPackage/components/PlanFormItem';
import JourneyFormItem from '@views/taskPackage/components/JourneyFormItem';
import { useSend, useSwr } from '@hooks';
import taskPackageAPI from '../../api/2_projects/task_package_api';
import { useTaskContext } from '@views/taskPackage/index';
import moment from 'moment';
import { useHistory } from 'react-router';
import { withSuspense } from '@/HOC';

const classPrefix = 'detail-body'

const SetTaskForm = function({ parentUrl }) {
    const {
        taskId,
        id: projectId
    } = useParams();
    const {
        data,
        mutate
    } = useSwr(taskId ? ['/package/info', taskId] : null, (url, id) => taskPackageAPI.getPackageInfo(id), {
        suspense: true
    });

    const {
        session,
        refreshTaskList
    } = useTaskContext();
    const history = useHistory();
    const [loading, sen] = useSend((data) => (taskId?taskPackageAPI.updatePackage(data):taskPackageAPI.createPackage(data))
        .then(res => {
            if (res.code === 1000) {
                return res;
            } else {
                throw new Error(res.msg || '操作失败');
            }
        }), {
        onSuccess(res) {
            const taskId = res.data.id;
            const gotoUrl = `${parentUrl}/detail/${taskId}`;
            refreshTaskList();
            mutate();
            history.push(gotoUrl);
        },
        onError(error) {
            $error(error.message);
        }
    });
    let initialValues = {
        project_id: Number(projectId),
        userName: session.name
    };
    if (taskId) {
        const {no, title, location, users, trips, description} = data.data || {};
        initialValues = {
            project_id: Number(projectId),
            id: Number(taskId),
            no,
            userName: session.name,
            title,
            location,
            user_ids: users?.map(user => user.id),
            trips: trips?.map(trip => ({
                id: trip.trip_id,
                time: [trip.start_time, trip.end_time],
                trip_type: trip.trip_type.code,
                transportation: trip.transportation.code,
                destination: trip.destination,
                comment: trip.comment
            })),
            description
        };
    }

    const [form] = Form.useForm();
    return <>
        <div className={`${classPrefix}-main`}>
            <Form form={form}
                  layout={'vertical'}
                  className={`${classPrefix}-main-content ${classPrefix}-form`}
                  initialValues={initialValues}
                  onFinish={(values) => {
                      // TODO 待优化
                      values.trips.forEach(item => {
                          item.start_time = moment(item.time[0])
                              .format('YYYY-MM-DD');
                          item.end_time = moment(item.time[1])
                              .format('YYYY-MM-DD');
                      });
                      sen(values);
                  }}>
                <Typography variant={'h3'} style={{marginBottom: 26}}>创建任务包</Typography>
                <Form.Item name={'project_id'} hidden={true}>
                    <span />
                </Form.Item>
                <Form.Item name={'id'} hidden={true}>
                    <span />
                </Form.Item>
                <Row gutter={40}>
                    <Col sm={12} xs={24}>
                        <Form.Item
                            name='title'
                            label='任务包名称'
                            required={true}
                            rules={[{ required: true }]}
                        >
                            <Input
                                className='task-input'
                                placeholder='请输入名称'
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item
                            name='no'
                            label='任务包编号'
                        >
                            <Input
                                disabled={true}
                                placeholder='创建完成后自动生成'
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item
                            name='userName'
                            label='创建人'
                        >
                            <Input
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                        <Form.Item
                            name='location'
                            label='相关地点'
                        >
                            <Input placeholder='请输入地点以方便拍摄人员安排出行计划' />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name='user_ids'
                            label='策划组员'
                            required={true}
                            rules={[
                                { required: true },
                                {
                                    max: 8,
                                    message: '最多选8个',
                                    type: 'array'
                                }]}
                        >
                            <PlanFormItem />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name='trips'
                            label='行程'
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: '请添加行程'
                                }]}
                        >
                            <JourneyFormItem />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label='任务包描述'
                            name='description'
                        >
                            <Input.TextArea
                                autoSize={{
                                    minRows: 5,
                                    maxRows: 5
                                }}
                                placeholder='请输入备注信息'
                                maxLength={200}
                                showCount={({
                                    count,
                                    maxLength
                                }) => `${count}/${maxLength}`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
        <div className={`${classPrefix}-footer`}>
            <Space size={40}>
                <Button type={'primary'} loading={loading} onClick={form.submit}>{taskId?'编辑':'创建'}</Button>
                <Button onClick={history.goBack}>取消</Button>
            </Space>
        </div>
    </>;
};

export default withSuspense(SetTaskForm);
