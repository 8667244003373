import { Button } from 'antd';

export const AddFooter = (props) => {
    const { onOk, onBegin, onCancel, loading } = props
    return <>
        <Button
            type="primary"
            loading={loading}
            onClick={onOk}
        >
            保存
        </Button>
        <Button
            type="primary"
            loading={loading}
            onClick={onBegin}
        >
            继续创建
        </Button>
        <Button
            type=""
            onClick={onCancel}
        >
            关闭
        </Button>
    </>
}

export const BasicFooter = (props) => {
    const { onOk, onCancel, loading, forbid } = props
    return <>
        <Button
            type="primary"
            loading={loading}
            disabled={forbid}
            onClick={onOk}
        >
            提交
        </Button>
        <Button
            type=""
            onClick={onCancel}
        >
            取消
        </Button>
    </>
}
