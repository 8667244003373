/**
 * @name: loading.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description: 加载状态
 */

import { Spin } from 'antd'
import { useEffect, useState } from 'react'

/**
 * 等待加载loading
 * @param props
 * @param {number} props.delay 等待多久显示loading
 * @returns {JSX.Element|null}
 * @constructor
 */
export const Loading = function (props){
  const {delay = 1000, ...other} = props
  const [show, setShow] = useState(!delay)
  useEffect(() => {
    if(delay>0){
      const timer = setTimeout(() => {
        setShow(true)
      }, delay)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [])
  if(show){
    return <Spin {...other} />
  }
  return null

}
