/**
 * @name: DetailComponents.jsx
 * @user: cfj
 * @date: 2022/3/24
 * @description: 详情的组件
 */
import { Typography } from '@components';
import { Avatars } from './PlanFormItem';
import { Table, Timeline } from 'antd';
import TaskInfoV2ImgList from '@views/2_projects/task_index/task_package/components/taks_details/task_planning/task_info_img_list';
import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import Collapse from './Collapse';

const { Panel } = Collapse;
const { Item } = Timeline;
export const BasicInfo = function({ data }) {
    const columns = [
        {
            title: '日期',
            width: 200,
            dataIndex: 'time',
            render(text, record) {
                return `${record.start_time} - ${record.end_time}`;
            }
        },
        {
            title: '行程类型',
            width: 120,
            dataIndex: 'trip_type',
            render(text, record) {
                return record.trip_type.label;
            }
        },
        {
            title: '目的地',
            width: 200,
            dataIndex: 'destination'
        },
        {
            title: '交通方式',
            width: 120,
            dataIndex: 'transportation',
            render(text, record) {
                return record.transportation.label;
            }
        }, {
            title: '行程说明',
            dataIndex: 'comment'
        }
    ];

    return <div>
        <div className={'desc-row'}>
            <div className={'desc-col'}>
                <Typography className={'desc-label'}>创建人</Typography>
                <Typography className={'desc-value'}>{data.creator.name}</Typography>
            </div>
            <div className={'desc-col'}>
                <Typography className={'desc-label'}>相关地点</Typography>
                <Typography className={'desc-value'}>{data.location}</Typography>
            </div>
        </div>
        <div className={'desc-row'}>
            <div className={'desc-col'}>
                <Typography className={'desc-label'}>策划组员</Typography>
                <Avatars users={data.users.map(user => ({
                    value: user.id,
                    label: user.name
                }))} />
            </div>
        </div>
        <div className={'desc-row'}>
            <div className={'desc-col desc-col-column'}>
                <Typography className={'desc-label'}>行程 <i className={'label-required'}>*</i></Typography>
                <Table bordered={true} className={'desc-table'} columns={columns} rowKey={'trip_id'} dataSource={data.trips}
                       pagination={false} />
            </div>
        </div>
        <div className={'desc-row'}>
            <div className={'desc-col'}>
                <Typography className={'desc-label'}>任务包描述</Typography>
                <Typography className={'desc-value'}>{data.description}</Typography>
            </div>
        </div>
    </div>;
};

export const MissionInfo = forwardRef(function(props, ref) {
    const {
        taskId
    } = useParams();
    return <TaskInfoV2ImgList ref={ref} package_id={Number(taskId)} />;
});

export const HistoryData = function(props) {
    const actionKey = {
        create_package: '创建任务包',
        update_package: '更新任务包'
    };
    const { data } = props;
    return <Timeline>
        {
            data.map(item => {
                const { action,
                    history_data: {
                        operation,
                        creator,
                        operator
                    },
                    id
                } = item;
                const title = actionKey[action] || operation;
                return <Item key={id}>
                    <Panel title={title}>
                        <Typography>{item.updated_at}</Typography>
                        <div className={'desc-row'}>
                            <div className={'desc-col'}>
                                <Typography className={'desc-label'}>操作人：</Typography>
                                <Typography className={'desc-value'}>{actionKey[action] ? creator.name : operator}</Typography>
                            </div>
                            <div className={'desc-col'}>
                                <Typography className={'desc-label'}>操作类型：</Typography>
                                <Typography className={'desc-value'}>{title}</Typography>
                            </div>
                        </div>
                    </Panel>
                </Item>;
            })
        }
    </Timeline>;
};
