import { observable, action, flow, makeAutoObservable, computed } from 'mobx';
import { taskFlowAPI, configsAPI } from '@api';
import { taskStore, todosStore } from '@stores';
import { $success, $error } from '@components';
class TaskProcessStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable action = '';
    @observable assignee_ids = [];
    @observable comment = '';
    @observable score = null;
    @observable reason;
    @observable images;
    @observable next_status;
    @observable relatedUsers = [];
    @observable discardReasons = {};
    @observable actionState = '';
    @observable produceScore = {};
    @observable collectScore = {};
    @observable actionDispose = {};

    @computed get visible() {
        return this.action && (this.next_status || this.next_status === 0);
    }
    @computed get baseParams() {
        return { id: taskStore.taskID, action: this.action };
    }

    @action updateActionStatus(action, next_status, action_dispose = {}) {
        this.action = action;
        this.next_status = next_status;
        this.actionDispose = action_dispose
    }
    @action hide() {
        if (document.location.pathname.includes('dashboard')) {
            // todosStore.fetchTaskTodos()
            // todosStore.againFilterType()
        }
        this.updateActionStatus('', null);
        this.actionState = '';
    }

    @action setProduceScore(obj) {
        this.produceScore = obj;
    }

    @action setCollectScore(obj) {
        this.collectScore = obj;
    }

    @action handleTaskUpdates(res) {
        if (res.code === 1000) {
            $success(res.msg);
            this.actionState = 'done';
            taskStore.receiveTask(res.data);
        } else {
            if (res.code !== 1002) $error(res.msg);
            this.actionState = 'error';
        }
    }

    fetchDiscardReasons = flow(function* () {
        try {
            const res = yield configsAPI.fetchTaskConfig();
            if (res.code === 1000) {
                this.discardReasons = res.data.discard_reason;
            }
        } catch (error) { }
    });

    fetchRelatedUsers = flow(function* () {
        const filter = { action: this.action };
        this.relatedUsers = [];
        try {
            const res = yield configsAPI.fetchRelatedUsers({ filter });
            if (res.code === 1000) {
                this.relatedUsers = res.data.list || [];
            }
        } catch (error) { }
    });

    // formData for submit
    submitTask = flow(function* (formData) {
        formData.append('id', taskStore.taskID);
        formData.append('action', this.action);
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.submit(formData);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    reviewTask = flow(function* (formData) {
        formData.append('id', taskStore.taskID);
        formData.append('action', this.action);
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.review(formData);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    manipulateTask = flow(function* (params) {
        params = { ...this.baseParams, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.manipulate(params);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    startTask = flow(function* (params) {
        params = { ...this.baseParams, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.start(params);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    discardTask = flow(function* (params) {
        params = { ...this.baseParams, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.discard(params);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    stopTask = flow(function* (params) {
        params = { id: taskStore.taskID, action: this.action, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.stop(params);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    remakeTask = flow(function* (params) {
        params = { id: taskStore.taskID, action: this.action, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.remake(params);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    getProduceScore = flow(function* (id) {
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.getProduceScore(id);
            this.setProduceScore(res.data);
            this.actionState = '';
        } catch (error) { }
    });

    productionScore = flow(function* (params) {
        params["id"] = taskStore.taskID
        params["action"] = this.action
        params["comment"] = params?.comment ? params?.comment : ''
        try {
            const res = yield taskFlowAPI.productionScore(params);
            if (res.code === 1000) {
                //   $success('操作成功')
                this.handleTaskUpdates(res);
            }
            this.hide();
        } catch (error) { }
    });

    // 修改资产ID
    updateKindTask = flow(function* (params) {
        params["id"] = taskStore.taskID
        params["action"] = this.action
        try {
            const res = yield taskFlowAPI.updateKindTask(params);
            if (res.code === 1000) {
                this.handleTaskUpdates(res);
            } else {
                $error(res?.msg);
            }
            this.hide();
        } catch (error) { }
    });

    // 通过原序列待审、解析待审、待制作转派任务时单独请求
    specialReview = flow(function* (params) {
        const filter = {
            id: taskStore.taskID,
            action: this.action,
            data: { ...params }
        }
        try {
            const res = yield taskFlowAPI.specialReview(filter);
            if (res.code === 1000) {
                // $success("操作成功");
                this.handleTaskUpdates(res);
            } else {
                $error(res?.msg);
            }
            this.hide();
        } catch (error) {
            throw new Error(error);
        }
    });

    // 新增任务请求
    splitInProduceFinal = flow(function* (params) {
        this.actionState = 'pending';
        const filter = {
            id: taskStore.taskID,
            action: this.action,
            data: [
                ...params?.data
            ],
            comment: params.comment || "", // 后端字段限制必须传
        };
        try {
            const res = yield taskFlowAPI.splitInProduceFinal(filter);
            if (res.code === 1000) {
                this.handleTaskUpdates(res);
            } else {
                $error(res?.msg);
            }
            this.hide();
        } catch (error) {
            throw new Error(error);
        }
    })

    // 资产回退单独请求处理
    taskRollBack = flow(function* (params) {
        const filter = {
            id: taskStore.taskID,
            action: 'rollback_task',
            data: { ...params }
        }
        try {
            const res = yield taskFlowAPI.specialReview(filter);
            if (res.code === 1000) {
                // $success("操作成功");
                this.handleTaskUpdates(res);
            } else {
                $error(res?.msg);
                return Promise.reject()
            }
            this.hide();
        } catch (error) {
            throw new Error(error)
        }
    })

    // 制作终审待审修改评分
    updateProduceScore = flow(function* (params) {
        this.actionState = 'pending';
        const filter = {
            id: taskStore.taskID,
            action: "update_produce_score",
            data: {
                ...params,
            }
        }
        try {
            const res = yield taskFlowAPI.updateProduceScore(filter);
            if (res.code === 1000) {
                this.handleTaskUpdates(res);
            } else {
                $error(res?.msg);
                return Promise.reject()
            }
            this.hide();
        } catch (error) {
            throw new Error(error);
        }
    })

    getCollectScore = flow(function* (id) {
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.getCollectScore(id);
            if (res.code === 1000) {
                this.setCollectScore(res.data);
                this.actionState = '';
            }
        } catch (error) { }
    });

    updateCollectCount = flow(function* (formData) {
        formData.append('id', taskStore.taskID);
        formData.append('action', this.action);
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.updateCollectCount(formData);
            this.handleTaskUpdates(res);
            this.hide();
        } catch (error) { }
    });

    createGodownEntry = flow(function* (params) {
        params = { task_id: taskStore.taskID, action: this.action, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.createGodownEntry(params);
            if (res.code === 1000) {
                $success(res.msg || '入库开始，任务完成需要一段时间，请等待');
                this.actionState = 'done';
                taskStore.receiveTask(res.data);
                this.hide();
                todosStore.setUpdateStorages(true)
            } else {
                $error(res.msg);
                this.actionState = 'error';
                return Promise.reject()
            }
        } catch (error) {
            throw new Error(error)
        }
    });

    godownEntryReset = flow(function* (params) {
        params = { task_id: taskStore.taskID, action: this.action, ...params };
        this.actionState = 'pending';
        try {
            const res = yield taskFlowAPI.godownEntryReset(params);
            if (res.code === 1000) {
                $success(res.msg || '入库重置成功！');
                this.actionState = 'done';
                this.hide();
            } else {
                $error(res.msg);
                this.actionState = 'error';
                return Promise.reject()
            }
        } catch (error) {
            throw new Error(error)
        }
    });
}

export default new TaskProcessStore();
