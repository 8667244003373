import { Drawer, Input } from "antd";
import { AuthButton } from "@/components";

const ReviewContent = (props) => {
  const { setComment, state } = props;
  const { review_comment } = state;
  return (
    <>
      <div className="bold mg-b-8">处理意见</div>
      <Input.TextArea
        onChange={setComment}
        value={review_comment}
        autoSize={{ minRows: 3.5, maxRows: 3.5 }}
      />
    </>
  );
};

const ReviewButtons = (props) => {
  const { confirmApprove, confirmDisapprove, hideDrawer } = props;
  const { drawerType } = props.state;
  const handleClick =
    drawerType === "approve"
      ? confirmApprove
      : drawerType === "disapprove"
      ? confirmDisapprove
      : hideDrawer;
  return (
    <div style={{ marginLeft: "-8px" }} className="mg-t-30">
      <AuthButton type="primary" className=" mg-lr-8" onClick={handleClick}>
        确认
      </AuthButton>
      <AuthButton className=" mg-lr-8" onClick={hideDrawer}>
        取消
      </AuthButton>
    </div>
  );
};

const ReviewDrawer = (props) => {
  const { hideDrawer, state } = props;
  const { visible } = state;

  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={hideDrawer}
      visible={visible}
      getContainer={false}
      mask={false}
      height={240}
      destroyOnClose
    >
      <ReviewContent {...props} />
      <ReviewButtons {...props} />
    </Drawer>
  );
};

export default ReviewDrawer
