import {
    observable,
    action,
    computed,
    flow,
    makeAutoObservable,
    reaction,
    runInAction,
} from "mobx";
import { statsStore } from "@stores";
import { configsAPI, productionStatsAPI } from "@api";
import { debounce } from "lodash";
import moment from "moment";
const page_size = 9;

class ReviewStore {
    constructor() {
        makeAutoObservable(this);
    }
    @observable filter = {};
    @observable tab = "all";
    @observable table;
    @observable page = 1;
    @observable loading = true;
    @observable user_id;
    @observable artsReviewUsers = [];
    @observable resReviewUsers = [];
    @observable date = null;
    @action clearDate() {
        this.date = moment().subtract(1, "months");
    }
    @action updateDate(val) {
        this.date = val;
    }
    @computed get statType() {
        return statsStore?.statType;
    }
    @computed get dateType() {
        return statsStore?.dateType;
    }
    @computed get relatedUsers() {
        return statsStore.relatedUsers;
    }
    @computed get params() {
        let [from, to] = this.date;
        from = from.format("YYYY-MM-DD");
        to = to.format("YYYY-MM-DD");
        const filter = { ...this.filter, created_at: { from, to } };
        const params = {
          filter,
          page_size,
          page: this.page,
        };
        return params;
    }
    @action updateDateType(val) {
        statsStore.updateDateType(val);
    }
    @action updateStatType(type) {
        statsStore.updateStatType(type);
    }
    @action updateUserId(id) {
        this.user_id = id
    }
    @action startLoad() {
        this.loading = true;
    }
    @action finishLoad() {
        this.loading = false;
    }
    @action changeFilter(val) {
        this.filter = val;
    }
    @action updateFilter(val) {
        this.filter = { ...this.filter, ...val };
    }
    @action updatePage(val) {
        this.page = val;
    }
    @action clear() {
        runInAction(() => {
          this.page = 1;
          this.table = [];
          this.filter = {};
        });
    }
    @action uniqueFunc(list) {
        let obj = {}
        let tempArr = []
        for(var i = 0; i<list.length; i++){
            if(!obj[list[i]["user_id"]]){
                tempArr.push(list[i])
                obj[list[i]["user_id"]] = true
            }
        }
        return tempArr
      }
    @action handleRequest(res) {
        if (res.code === 1000) {
          this.finishLoad();
          this.table = res.data.list;
          this.total = res.data.total;
        }
    }
    fetchProductionReviews = flow(function* () {
        this.startLoad();
        try {
            const res = yield productionStatsAPI.fetchProductionReviews(this.params);
            this.handleRequest(res);
        } catch (e) {}
    });
    fetchArtsReviewUsers = flow(function* (params) {
        try {
          const res = yield configsAPI.fetchRelatedUsers(params);
          if (res.code === 1000) {
            this.artsReviewUsers = res.data.list;
          }
        } catch (error) {}
    });
    fetchResReviewUsers = flow(function* (params) {
        try {
          const res = yield configsAPI.fetchRelatedUsers(params);
          if (res.code === 1000) {
            this.resReviewUsers = res.data.list;
          }
        } catch (error) {}
    });
}

const reviewStore = new ReviewStore();
const handleDifferentRequest = () => {
    reviewStore.fetchProductionReviews();
};
  
const debouncedRequest = debounce(handleDifferentRequest, 1000);
  
reaction(
    () => reviewStore.date,
    (val, prevVal) => {
        if (val !== prevVal) {
        reviewStore.updatePage(1);
        debouncedRequest();
        }
    }
);
reaction(
    () => reviewStore.dateType,
    (val, prevVal) => {
        if (val !== prevVal) {
        reviewStore.updatePage(1);
        debouncedRequest();
        }
    }
);

reaction(
    () => reviewStore.filter,
    (val, prevVal) => {
        if (val !== prevVal) {
        reviewStore.updatePage(1)
        debouncedRequest()
        };
    }
);
reaction(
    () => reviewStore.page,
    (val, prevVal) => {
        if (val !== prevVal) debouncedRequest();
    }
);

export default reviewStore;