import "./index.less";
import { Button } from "antd";
import { AuthButton } from "@/components";
import { toJS } from "mobx";

/**
 * 创建***任务包***保存相关按钮
 * @returns
 */
export const TasksButton = (props) => {
    const {
        handleCancel,
        form,
        todosStore,

        taskPackageStore: { pageSize, isShowEditCreate ,currentTasks,setShowEditCreate},
    } = props;
    let projectId = todosStore.projectId || null;
    const editTask = isShowEditCreate ? "edit" : ""

    // 创建任务包接口功能
    const createTasks = () => {


        // 校验表单
        form.validateFields().then((values) => {
            if (editTask === "edit") {
                values["id"] = currentTasks?.id
                props.taskPackageStore.updatePackage(values).then(() => {
                    form.resetFields();
                    setShowEditCreate(false);
                    // 刷新下策划任务包列表
                    props.taskPackageStore.setPackageList([]);
                    props.taskPackageStore.getPackageList({
                        page: 1,
                        page_size: pageSize,
                        project_id: parseInt(projectId),
                    });
                });
            } else {
                props.taskPackageStore.createTasksApi(values).then(() => {
                    // 请求成功后清除form表单
                    form.resetFields();
                    // // 清除之前的列表数据
                    props.taskPackageStore.setPackageList([]);
                    // 刷新下策划任务包列表
                    props.taskPackageStore.getPackageList({
                        page: 1,
                        page_size: pageSize,
                        project_id: parseInt(projectId),
                    });
                });
            }

        });
    };

    return (
        <div className="amps-create-tasks-button">
            <div className="bottom-button  ">
                <AuthButton
                    type="primary"
                    className="tasks-create tasks-btn mg-r-20"
                    onClick={createTasks}
                >
                    {editTask === "edit" ? "保存" : "创建"}
                </AuthButton>
                <Button className="tasks-cancel tasks-btn" onClick={handleCancel}>
                    取消
                </Button>
            </div>
        </div>
    );
};
