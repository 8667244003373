import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Title } from "./TaskPlanningRender";
import { Form } from "antd";
import { AuthButton, $error, $success } from "@/components";
import {
    TaskName,
    TaskUploader,
    TaskScore,
    UploadImage,
    VolumeUploadExcel,
    VolumeUploadImage,
    TaskPlanningComment,
    ForbidEditInput,
} from "./TaskPlanningRender";
import { formatImageFiles, formatExcelFiles, formatZipFiles, downloadFile } from "@gUtils";
import "./index.less";
import axios from "axios";
import env from "env";

@inject("taskPlanStore", "projectStore", "taskPackageStore")
@observer
class TaskPlanning extends Component {
    state = {
        loading: false,
    };
    formRef = React.createRef();
    uploadRef = React.createRef();
    uploadExcelRef = React.createRef();
    uploadZipRef = React.createRef();
    componentDidMount() {
        const { title } = this.store;
        if (title === 1) {
            this.store.setUpdateTask({});
            const { loginUser } = this.projectStore;
            this.formRef.current.setFieldsValue({
                task_name: "",
                task_score: "",
                task_uploader: loginUser?.name,
                task_planning_comment: "",
            });
        }
    }
    /**
     *获取taskPlanStore
     */
    get store() {
        return this.props.taskPlanStore;
    }
    /**
     * 获取projectStore
     */
    get projectStore() {
        return this.props.projectStore;
    }
    /**
     * 获取projectStore
     */
    get taskPackageStore() {
        return this.props.taskPackageStore;
    }
    /**
     *抽屉动画
     */
    HandleAnimate = (type) => {
        if (type === "open") {

        }
    }
    /**
     *切换隐藏弹框
     */
    hide = () => {
        this.store.toggleVisible(false);
        this.store.setUpdateTask({});
        this.store.updateVolumnCreateStatus(true);
    }
    /**
     *校验
     */
    handleConfirm = (type, title) => {

        if (title === 1 || title === 3) {
            let { fileList } = this.uploadRef.current.state;
            this.formRef.current.validateFields().then((res) => {
                if (fileList.length < 1) {
                    return $error("请上传任务图片");
                }
                this.handleCreateBound(res, fileList, type, title)
            })
        } else if (title === 2) {
            let { fileList: excelList } = this.uploadExcelRef.current.state;
            let { fileList: zipList } = this.uploadZipRef.current.state;
            this.formRef.current.validateFields().then((res) => {
                if (excelList.length < 1) {
                    return $error("请上传电子表格");
                }
                if (zipList.length < 1) {
                    return $error("请上传任务图片包");
                }
                this.handleVolumnBound(res, excelList, zipList)
            })
        }
    }
    /**
     *创建/更新绑定参数
     */
    handleCreateBound = (params, fileList, type, title) => {
        let formData = new FormData();
        formData.append("title", params.task_name);
        formData.append("score", params.task_score);
        if (title === 1) {
            formData.append("package_id", this.taskPackageStore.tasksId);
        } else if (title === 3) {
            const { id } = this.store.updateTask
            formData.append("id", id);
        }
        formData = formatImageFiles(fileList, formData);
        this.setState({ loading: true })
        this.handleCreate(type, formData, fileList, title).finally(() => {
            this.setState({ loading: false });
        });
    }
    /**
     *保存/继续创建/更新策划任务
     */
    handleCreate = async (type, formData, fileList, title) => {
        if (title === 1) {
            await this.store.createPlanTask(formData);
        } else {
            await this.store.updatePlanTask(formData);
        }
        this.formRef.current.setFieldsValue({
            task_name: "",
            task_score: "",
            task_planning_comment: "",
        });
        const len = fileList.length;
        for (let i = 0; i < len; i++) {
            this.uploadRef.current.onRemove();
        }
        if (type === "save") {
            this.store.toggleVisible(false);
        }
    }
    /**
     * 批量创建绑定参数
     */
    handleVolumnBound = (params, excelList, zipList) => {
        let formData = new FormData();
        formData.append("package_id", this.taskPackageStore.tasksId);
        formData = formatExcelFiles(excelList, formData);
        formData = formatZipFiles(zipList, formData);
        this.setState({ loading: true });

        this.handleVolumnCreate(formData).finally(() => {
            this.setState({ loading: false });
        });
    }
    /**
     *批量创建策划任务
     */
    handleVolumnCreate = async (formData, excelList, zipList) => {
        await this.store.volumnCreatePlanTask(formData);
        for (let i = 0; i < 1; i++) {
            this.uploadExcelRef.current.onRemove();
            this.uploadZipRef.current.onRemove();
        }
        const { volumnCreateStatus } = this.store;
        volumnCreateStatus && this.store.toggleVisible(false);
    }
    /**
     *下载详表
     */
    handleDownload = () => {
        downloadFile(env.serveUrls + "static/resource/package/上传策划任务样表.xlsx");
        $success("下载成功！");
    }
    /**
     *提交审核
     */
    handleSubmit = () => {
        this.setState({loading: true});
        this.taskPackageStore.submitPackage().then(() => {
            this.store.toggleVisible(false);
        }).finally(() => {
            this.setState({loading: false});

        });
    }
    /**
     *底部按钮渲染函数
     */
    renderFooter = (title) => {
        const { loading } = this.state
        return (
            <div className="mg-t-22">
                {
                    title === 0 ? (
                        <AuthButton
                            type="primary"
                            className="mg-r-20"
                            onClick={() => {
                                this.handleSubmit();
                            }}
                            loading={loading}
                        >确认提交</AuthButton>
                    ) : (
                        <>
                            <AuthButton
                                type="primary"
                                className="mg-r-20"
                                onClick={() => {
                                    this.handleConfirm("save", title);
                                }}
                                loading={loading}
                            >保存</AuthButton>
                            {
                                title === 1 ? (
                                    <AuthButton
                                        type="primary"
                                        className="mg-r-20"
                                        onClick={() => {
                                            this.handleConfirm("continue", title)
                                        }}
                                        loading={loading}
                                    >继续创建</AuthButton>
                                ) : (
                                    title === 2 && (
                                        <AuthButton
                                            type="primary"
                                            className="mg-r-20"
                                            onClick={() => {
                                                this.handleDownload();
                                            }}
                                        >下载详表</AuthButton>
                                    )
                                )
                            }
                        </>
                    )
                }
                <AuthButton onClick={() => { this.hide() }}>取消</AuthButton>
            </div>
        );
    }
    /**
     *内容区渲染函数
    */
    renderContent = (title) => {
        let res = null;
        const {
            beforeUpload,
            beforeVolumnUploadExcel,
            beforeVolumnUploadZip,
            updateTask,
            volumnCreateStatus,
            volumnCreateErrorPath
        } = this.store;
        const { loginUser } = this.projectStore;
        const props = { ...this.props, updateTask }
        if (title === 1 || title === 3) {
            res = (
                <>
                    <TaskName {...props} />
                    <TaskUploader loginUser={loginUser} {...props} />
                    <TaskScore {...props} />
                    <UploadImage
                        uploadRef={this.uploadRef}
                        beforeUpload={beforeUpload}
                        {...props}
                    />
                    {
                        title === 3 && updateTask.comment && (<TaskPlanningComment {...props} />)
                    }
                </>
            );
        } else if (title === 2) {
            res = (
                <>
                    <VolumeUploadExcel
                        uploadExcelRef={this.uploadExcelRef}
                        beforeUpload={beforeVolumnUploadExcel}
                    />
                    <VolumeUploadImage
                        uploadZipRef={this.uploadZipRef}
                        beforeUpload={beforeVolumnUploadZip}
                    />
                    {
                        !volumnCreateStatus && (
                            <div
                                className="task-form-col-1 error-reason"
                                onClick={() => {
                                    downloadFile(volumnCreateErrorPath);
                                }}

                            >
                                查看错误原因
                            </div>
                        )
                    }
                </>
            );
        } else if (title === 0) {
            const content = "策划待审"
            res = (
                <>
                    <ForbidEditInput
                        value={content}
                    />
                </>
            )
        }
        return res;
    }
    render() {
        const {
            title,
            visible,
        } = this.store;
        const res = this.renderContent(title);
        return (
            <div
                className="task-plan-model"
                style={{
                    display: visible ? "block" : "none",
                }}
            >
                <Form
                    ref={this.formRef}
                >
                    <div className={
                        (title === 1 || title === 3 || title === 0) ? "bold task-plan-title mg-b-20" : "bold task-plan-title mg-b-4"
                    }>
                        <Title title={title} />
                    </div>
                    <div className="task-plan-content">
                        <div className="task-form">
                            {res}
                        </div>
                    </div>
                    <div className="task-plan-footer">
                        {this.renderFooter(title)}
                    </div>
                </Form>
            </div>
        )
    }
}

export default TaskPlanning;
