import React from 'react';
import { Image } from 'antd';
import env from 'env';

interface IProps {
    data: any,
    checkKeysChange: (item: any) => void;
}

/**
 * ListItem
 * @param {IProps} props props
 * @returns {React.ReactNode}
 */
const ListItem: React.FC<IProps> = (props: IProps) => {
    const { data, checkKeysChange } = props;

    return (
        <div className="amp-list-item">
            {
                data?.map((item: any) => (
                    <div
                        className="amp-item"
                        key={item.id}
                        onClick={() => checkKeysChange(item)}
                    >
                        <div className="amp-item-img">
                            <Image
                                fallback={env.placeImage}
                                preview={false}
                                className="amp-ant-image"
                                src={item?.imagesList?.[0]?.fileUrl || ''}
                            />
                        </div>
                        <div className="amp-item-content">
                            <div className="amp-item-title amp-line-clamp-1">{item.name}</div>
                            <div className="amp-item-tips">
                                {item.number}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default ListItem;
