import axios from 'axios'

const messagesAPI = {
  fetchWechatDetails() {
    return axios.get("/account/wechat-info");
  },
  updateWechatOptions(params) {
    return axios.post("/account/wechat-notification", params);
  },
  unbindWechat() {
    return axios.get("/account/wechat-unbind");
  },
  refreshWechat() {
    return axios.get("/account/wechat-refresh");
  }
};

export default messagesAPI