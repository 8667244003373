/**
 * @name: barExtraContentLayout.jsx
 * @user: zy
 * @date: 2022/5/11
 * @description: tab bar 上带有额外的元素的导航布局
 */
import { createContext, useState, useContext } from 'react';
import { matchPath, resolveUrl, useGetAuthRoutes, useOutlet } from '@components/router/routes';
import { Tabs, Modal, Button } from 'antd';
import { useHistory } from 'react-router';
import { Iconfont } from '@components';
import CalendarModal from '@views/4_stats/businessDataOverview/new_kpi/calendar';

const { TabPane } = Tabs;

const TabsContext = createContext(null);
const TabsProvider = TabsContext.Provider;
/**
 * useTaskContext
 * @returns {any} TaskContext
 */
export const useTabsContext = function() {
    return useContext(TabsContext);
};

/**
 * tab bar 上带有额外的元素的导航布局
 * @returns {JSX.Element}
 */
export const barExtraContentLayout = function () {
    const outlet = useOutlet();
    const history = useHistory();
    const routes = useGetAuthRoutes();
    const [hover, setHover] = useState(false);
    const [visible, setVisible] = useState(false);
    const authItems = routes.sort((a, b) => a.menuSore - b.menuSore);
    const selectedKey = authItems.find((item) => matchPath(item.name))?.name || -1;
    const operations = (
        <Iconfont
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            type={hover ? 'icon-gongzuorili2' : 'icon-gongzuorili'}
            className="fz-18"
            onClick={() => {
                setVisible(true);
            }}
        />
    );

    /**
     * 保存更改刷新table
     */
    const handleSave = () => {
        setVisible(false);
    };

    /**
    * 关闭日历弹框
    */
    const handleCloseCalendarModal = () => {
        setVisible(false);
    };
    return (
        <div className="nav-layout">
            <TabsProvider
                value={{
                    calendarVisible: visible
                }}
            >
                <Tabs
                    tabBarGutter={40}
                    animated={false}
                    activeKey={selectedKey}
                    tabBarExtraContent={operations}
                    defaultActiveKey={selectedKey}
                    onChange={(key) => {
                        const url = resolveUrl(key);
                        history.push(url);
                    }}
                >
                    {
                        authItems.map((item) => (
                            <TabPane tab={item.title} key={item.name} />
                        ))
                    }

                </Tabs>
                <div className="nav-layout-container">
                    {outlet}
                </div>
                <Modal
                    className="amp-calendar-modal"
                    visible={visible}
                    current
                    destroyOnClose
                    title="工作日历"
                    width="42%"
                    onCancel={handleCloseCalendarModal}
                    footer={null}
                    closeIcon={<Iconfont type="icon-close-01" className="fz-24" />}
                >
                    <CalendarModal />
                    <div className="amp-calendar-modal-button">
                        <Button className="amp-btn-sz-s mg-r-20" type="primary" onClick={handleSave}>保存</Button>
                        <Button className="amp-btn-sz-s" onClick={handleCloseCalendarModal}>取消</Button>
                    </div>
                </Modal>
            </TabsProvider>
        </div>
    );
};
