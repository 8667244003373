import React, { forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';
import './index.less';
const { Item } = Form;

const DrawerChildren = forwardRef((props, ref) => {
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        submit: form.submit,
        getForm: () => form
    }));
    const session = useSelector((state) => state.session);
    const { item } = props;
    return (
        <div className="amps-drawer-children">
            <Form form={form}>
                <div className="amp-drawer-children-operator">
                    <div className="amp-drawer-title">处理人</div>
                    <Input disabled value={session?.name} size={'large'} />
                </div>
                <div className="amp-drawer-children-next">
                    <div className="amp-drawer-title">下一步</div>
                    <Input disabled value={item?.nextStepName} size={'large'} />
                </div>
                <div className="amp-drawer-children-comment">
                    <div className="amp-drawer-title">备注</div>
                    <Item name="comment">
                        <Input.TextArea placeholder="请添加备注信息" className="amp-h-100" />
                    </Item>
                </div>
            </Form>
        </div>
    );
});

export default DrawerChildren;
