import { Form, DatePicker, Input } from "antd";
import moment from "moment";
import { emptyProject } from "@vars/views/projects_vars";
import {
  rules,
  projectDIC,
  formDateRanges,
  title,
  description,
} from "@vars/views/projects_vars";

const datePickersRender = (props) => {
  const { projects, projectId, type } = props;
  const currentProject = projects[projectId] || emptyProject;
  return formDateRanges?.map((item, idx) => (
    <Form.Item
      className="form-item"
      name={item.key}
      rules={rules[item.key]}
      label={projectDIC[item.key].title}
      dependencies={item.key !== "start_at" ? ["start_at"] :[]}
      key={idx}
      initialValue={
        currentProject[item.key]
          ? moment(currentProject[item.key], "YYYY-MM-DD")
          : item.key === "start_at"
          ? moment()
          : ""
      }
    >
      <DatePicker
        placeholder={item.placeholder}
        className="input-sz-m"
        format="YYYY/MM/DD"
        disabled={type === "edit" && item.key === "start_at"}
        // suffixIcon={}
      />
    </Form.Item>
  ));
};

export const AddEditDate = (props) => datePickersRender(props);

export const AddEditTitle = (props) => {
  const { projects, projectId } = props;
  const currentProject = projects[projectId] || emptyProject;
  return (
    <Form.Item
      className="form-item"
      name={title}
      rules={rules.title}
      label={projectDIC.title.title}
      initialValue={currentProject.title}
    >
      <Input
        className="normal-input"
        placeholder={projectDIC.title.formPlaceholder}
      ></Input>
    </Form.Item>
  );
};

export const AddEditDescription = (props) => {
  const { projects, projectId } = props;
  const currentProject = projects[projectId] || emptyProject;
  return (
    <Form.Item
      className="form-item add-edit-description"
      name={description}
      label={projectDIC.description.title}
      initialValue={currentProject.description}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 3 }}
        placeholder={projectDIC.description.formPlaceholder}
        maxLength={500}
      ></Input.TextArea>
    </Form.Item>
  );
};
