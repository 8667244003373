/**
 * @name: Audit.jsx
 * @user: cfj
 * @date: 2022/3/25
 * @description: 提交审核表单
 */
import { Col, Form, Row } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';
import { Input } from '@components';

const AuditForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    const { initialValues } = props;
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }));
    return <Form
        form={form}
        initialValues={initialValues}
        layout={'vertical'}
    >
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item label={'下一步'} name={'statusLabel'} required={true}>
                    <Input disabled />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
});
export default AuditForm;
