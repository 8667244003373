import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { clsx } from '@gUtils';
import './index.less';
import { useVirtual } from '@hooks';
import { defaultRenderError, defaultRenderLoading, defaultRenderNoData } from './Tsusukes';

/**
 * 下拉滚动组件
 * @param {object} props 组件的props
 * @param {{refresh: ()=> Promise<any>}} ref
 * @param {number} props.col [props.col=1] 组件的props
 * @param {string} props.className 滚动容器的className
 * @param {number} props.height  滚动容器的可视高度
 * @param {(data:T) => T} props.dataFormat  renderItem前的数据处理，一个纯函数
 * @param {({data: T, size: number}) => ReactNode} props.renderHeader  渲染滚动容器的Header
 * @param {({list: T[], param: any}) => ReactNode} props.renderNoData  渲染滚动容器的空数据 list.length是否存在数据或者无跟多数据，判断param判断无搜索数据
 * @param {({data: T, size: number, isValidating: boolean}) => ReactNode} props.renderLoading 渲染滚动容器的加载状态 可以根据size判断是否是第一页加载
 * @param {({list: T[],error: Error, mutate: () => Promise<T>}) => ReactNode} props.renderError 渲染加载错误，根据list.length判断是否是第一页加载错误，mutate刷新方法
 * @param {(clos: T|T[]) => ReactNode} renderItem 渲染列表项 clos更具props.col>1?T[]:T
 * @param {(clos: T|T[]) => ReactNode} renderItem 渲染列表项 clos更具props.col>1?T[]:T
 * 余下的是useVirtual 的参数
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const ScrollContainer = forwardRef(function(props, ref) {
    const container = useRef();
    const {
        // 列数
        col = 1,
        className,
        style,
        height,
        // 数据过滤
        dataFormat,
        // 默认渲染辅助ui
        renderHeader,
        // 往滚动体头部加入渲染dom
        renderTopDom,
        renderLoading = defaultRenderLoading,
        renderNoData = defaultRenderNoData,
        renderError = defaultRenderError,
        // 渲染行
        renderItem,
        renderAllData,

        ...other
    } = props;
    const {
        data,
        error,
        isValidating,
        mutate,
        size,
        totalSize
    } = useVirtual(container, {
        revalidateFirstPage: true,
        ...other
    });

    useEffect(() => {
        renderAllData?.(data?.length ? [].concat(...data.map(data => data.list)) : [])
    }, [data])

    useImperativeHandle(ref, () => ({
        refresh: mutate
    }));
    let multipleColumnsList = data?.length ? [].concat(...data.map(data => data.list)) : [];

    if (col > 1 && data?.length) {
        multipleColumnsList = [].concat(...data.map(data => data.list)).reduce((accArr, item) => {
            if (!accArr.length) {
                return [[item]];
            } else {
                if (accArr[accArr.length - 1].length < col) {
                    accArr[accArr.length - 1].push(item);
                    return accArr;
                } else {
                    accArr.push([item]);
                    return accArr;
                }
            }

        }, []);
    }
    const renderList = dataFormat?.(multipleColumnsList) || multipleColumnsList;
    return <>
        {renderHeader?.({
            data,
            size
        })}
        <div ref={container} style={{ height, ...style }} className={clsx('maps-scroll-container', className)}>
            {renderTopDom?.()}
            {renderList?.map((i, key) => renderItem(i, key))}
            {isValidating && renderLoading({
                data,
                size,
                isValidating
            })}
            {!error && !isValidating && multipleColumnsList.length === 0  && renderNoData({ list: multipleColumnsList, params:other.params })}
            {!error && !isValidating && multipleColumnsList.length > 0 && size === totalSize && renderNoData({ list: multipleColumnsList, params:other.params })}
            {!isValidating && error && renderError({
                list: multipleColumnsList,
                error,
                mutate
            })}
        </div>
    </>;
});
export default ScrollContainer;
