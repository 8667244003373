import { Button, Typography } from '@components'

/**
 * @name: Error.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description: 系统错误
 */

export function Error(props){
  const {children, ...other} = props
  return <Typography>
    {other.error?.message||"网络错误请"},<Button className="d-inline" type="link" onClick={other.reLoad}>重试</Button>
    {children}
  </Typography>
}
