/**
 *
 * @param file 文件流
 * @param fileName 文件名
 * @param ext 扩展名
 * @param options 文件选项
 */
export function downloadFile(file, fileName, ext, options) {
    let content = file;
    fileName = ((fileName || '') + '').replace(/.+=/g, '');
    let nowTime = (new Date()).getTime();
    // 增加需求下载文件名按照后端的命名来
    let arr = [(decodeURI(decodeURIComponent(fileName)) || nowTime)];
    ext && arr.push(ext);
    let _fileName = arr.join('.');
    // options = Object.assign(options);
    if (typeof file === 'string') {
        download(file, _fileName);
    }
    else {
        let upFile;
        if (content instanceof File) {
            upFile = content;
        }
        else {
            upFile = new File([content], _fileName, options || {});
        }
        let reader = new FileReader();
        reader.readAsDataURL(upFile); // 转换为base64，可以直接放入a标签href
        reader.onload = function () {
            download(upFile, _fileName);
        };
    }
}
/**
 * 下载文件
 * @param filePath 文件地址
 * @param fileName 文件名称
 */
export function download(filePath, fileName) {
    let aEl = document.createElement('a');
    if ('download' in aEl) {
        // 非IE下载
        aEl.download = fileName;
        aEl.style.display = 'none';
        if (!(typeof filePath === 'string')) {
            filePath = URL.createObjectURL(filePath);
        }
        aEl.href = filePath;
        aEl.target = "_blank";
        // document.body.appendChild(elink);
        aEl.click();
        URL.revokeObjectURL(aEl.href); // 释放URL 对象
        // document.body.removeChild(elink);
    }
    else {
        // IE10+下载
        navigator.msSaveBlob(filePath, fileName);
    }
}

/**
 * 文件导出
 * @param {Blob} blob
 * @param {string|undefined} fileName 文件名
 */
export const boldExportFile = function (blob, fileName){
  let down = document.createElement('a');
  down.href = window.URL.createObjectURL(blob);
  down.download = blob.name || fileName;
  down.style.display = 'none';
  document.body.appendChild(down);
  down.click();
  document.body.removeChild(down);
  window.URL.revokeObjectURL(down.href);
}
