import React, { Component } from "react";
import { Form, Tree } from "antd";
import { roleDIC } from "@vars/views/settings_role_vars";

class TreeSelect extends Component {
  state = {
    checkedKeys: this.props.roleDetails.permissions,
  };

  get permissionTree() {
    const { allPermissions } = this.props;
    // 后端新的接口里边，数据包了一层list
    return this.permList(allPermissions?.list ? allPermissions?.list : []);
  }

  permList = (rolesArr, parent) => {
    let arr = [];
    if (!rolesArr) return;
    Object.values(rolesArr).forEach((item, index) => {
      arr.push({
        ...item,
        parent,
        title: item.name,
        key: item.code,
        isLeaf: item.children && item.children.length === 0,
        children:
          item.children && item.children.length
            ? this.permList(item.children, item)
            : [],
      });
    });
    return arr;
  };

  onCheck = (checkedKeys, { checked, node }) => {
    let permissionTree = [{ code: "", children: this.permissionTree }];
    let resArr = [...checkedKeys.checked];
    if (!checked) {
      resArr = this.removeChildren(resArr, node);
    } else {
      if (node.children) {
        resArr = this.getChildren(resArr, node);
      }
      if (node.parent) {
        resArr = this.getParent(resArr, node, permissionTree);
      }
    }
    resArr = Array.from(new Set(resArr)).filter((item) => !!item !== false);
    this.setState({ checkedKeys: resArr });
  };

  getParent = (resArr, node, tree) => {
    let seq = node.pos.split("-")?.map((idx) => parseInt(idx));
    let newTree = [...tree];
    while (seq.length > 0) {
      let first = seq.shift();
      const item = newTree[first];
      resArr.push(item.code);
      newTree = item.children;
    }
    return resArr;
  };

  getChildren = (resArr, node) => {
    let queue = [...node.children];
    while (queue.length > 0) {
      const first = queue.shift();
      resArr.push(first.code);
      queue = [...queue, ...first.children];
    }
    return resArr;
  };

  removeChildren = (resArr, node) => {
    let { children } = node;
    let queue = [...children];
    while (queue.length > 0) {
      let first = queue.shift();
      resArr = resArr.filter((item) => item !== first.code);
      queue = [...queue, ...first.children];
    }
    return resArr;
  };

 render() {
    let item = roleDIC.filter((e) => e.title === "角色权限")[0];
    const { checkedKeys } = this.state;

    return (
      <div className="role-permissions">
        <Form.Item className="form-item" label={item.title} required={true}>
          <Tree
            treeData={this.permissionTree}
            checkable
            multiple
            onCheck={this.onCheck}
            checkedKeys={checkedKeys}
            checkStrictly={true}
          ></Tree>
        </Form.Item>
      </div>
    );
  };
}
export default TreeSelect;
