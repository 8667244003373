import { Col, Row, Space } from 'antd';
import { $error, $success, Button, Drawer, ScrollContainer, Typography } from '@components';
import { OperateForm } from '../components/OperateForm';
import { useDebounceState, useSend } from '@hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PoolItem } from '../components/PoolItem';
import { useParams } from 'react-router-dom';
import { getProduceAllList, getProduceList } from '@views/pool/receive';
import { discardTask, produceCategoryToggle, produceToggle, taskFlowAPI } from '@api';
import VoidForm from '../components/VoidForm';
import RollbackForm from '../components/RollbackForm';

const Operate = function(props) {
    const {
        onCancel,
        tmp,
        isDiscard,
        isRollback,
        isAssignStatus,
    } = props;
    const [nodes, open] = Drawer.useDrawer();

    const { id: projectId } = useParams();
    // 选择模式 多选
    const [isMultiple, setMultiple] = useState(false);
    const operateFromRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [toggles, setToggles] = useState([]);
    const [destroyItem, setDestroyItem] = useState(null);
    const [params, debounceParams, setParams] = useDebounceState({
        asset_category: [],
        is_public: 2,
        parent_id: undefined
    });
    const parentIdParams = useMemo(() => ({
        ...debounceParams,
        project_id: projectId !== undefined ? Number(projectId) : undefined
    }), [projectId, debounceParams]);
    const [loading, send] = useSend(produceToggle, {
        onError(error) {
            $error(error.message);
        },
        onSuccess() {
            setParams((state) => ({
                ...state,
                setParams: 2
            }));
            setToggles([]);
            scrollContainerRef.current?.refresh();
            $success('设置成功');
        }
    });

    const [loading2, send2] = useSend(produceCategoryToggle, {
        onError(error) {
            $error(error.message);
        },
        onSuccess() {
            setParams((state) => ({
                ...state,
                setParams: 2
            }));
            setToggles([]);
            scrollContainerRef.current?.refresh();
            $success('设置成功');
        }
    });

    function onChange(values) {
        setParams((state) => ({
            ...state,
            ...values
        }));
    }

    function onOpen({
        id,
        is_public
    }) {
        // is_public: 0
        // task_id: 4524
        const tos = [...toggles];
        const index = tos.findIndex(task => task.task_id === id);
        if (index > -1) {
            tos.splice(index, 1);
        } else {
            tos.push({
                task_id: id,
                is_public: is_public === 0 ? 1 : 0
            });
        }
        setToggles(tos);
    }

    function onSplit(item) {
        operateFromRef.current?.setParentId(item.parent_id);
    }

    function onDestroy(item) {
        setDestroyItem((state) => state ? null : item);
    }

    function bulkHandler() {
        setMultiple(true);
        setDestroyItem(null);
    }

    function destroyHandler() {
        open({
                getContainer: false,
                placement: 'bottom',
                style: {
                    position: 'absolute',
                    height: 740
                },
                title: '作废',
                okText: "确认处理",
                cancelText: "取消处理",
                mask: false,
                content: <VoidForm item={destroyItem} initialValues={{ id: destroyItem.id }} />,
                onError(error) {
                    $error(error.message);
                },
                async onOk(values) {
                    const res = await discardTask(values);
                    if(res.code === 1000){
                        scrollContainerRef.current?.refresh();
                        setDestroyItem(null);
                        setToggles([]);
                        $success('操作成功');
                    }else{
                        throw new Error(res?.msg)
                    }
                }
            }
        );
    }

    const rollbackHandler = () => {
        open({
                getContainer: false,
                placement: 'bottom',
                style: {
                    position: 'absolute',
                    height: 420
                },
                title: '回退',
                mask: false,
                content: <RollbackForm
                            item={destroyItem}
                            initialValues={{
                                id: destroyItem.id
                            }}
                            isAssignStatus={isAssignStatus}
                        />,
                onError(error) {
                    $error(error.message);
                },
                okText: "确认处理",
                cancelText: "取消处理",
                async onOk(values) {
                    //task_status 默认上一步回退到 21
                    const value = {
                        id: values.id,
                        action: "rollback_task",
                        data: {
                            task_status: values.is_appoint_status === 1 ? 21: values.appoint_status,
                            comment: values?.comment
                        }
                    }
                    const res = await taskFlowAPI.specialReview(value);
                    if(res.code === 1000){
                        scrollContainerRef.current?.refresh();
                        setDestroyItem(null);
                        setToggles([]);
                        $success('操作成功');
                    }else{
                        throw new Error(res?.msg)
                    }
                }
            }
        );
    }

    useEffect(() => {
        if (debounceParams.is_public !== 2) {
            scrollContainerRef.current?.refresh();
        }
    }, [debounceParams.is_public]);
    return <>
        <div className={'receive-content'}>
            <div className={'receive-header px-4 pt-2'}>
                {isMultiple ? <Typography className="title-form-field">如需要批量变更任务状态，请先筛选出需要变更的任务，再点击底部的批量操作按钮</Typography>
                    : <Typography className="title-form-field">请勾选需要公开的类型，如果需要单独隐藏某个任务，请悬浮到对应任务上并点击隐藏按钮</Typography>}
                <OperateForm ref={operateFromRef} value={params} onChange={onChange} />
            </div>
            <div className={'receive-body pl-3'}>
                <ScrollContainer
                    col={4}
                    ref={scrollContainerRef}
                    pageSize={16}
                    className={'receive-body-scroll'}
                    url={'/produce/all'}
                    params={parentIdParams}
                    renderItem={(col, key) => <Row key={key} gutter={16} style={{ marginBottom: 16 }}>
                        {col.map(item => {
                            const toggle = toggles.find(toggle => toggle.task_id === item.id);
                            return <Col key={item.id} span={6}>
                                <PoolItem
                                    tmp={tmp}
                                    item={item}
                                    destroy={destroyItem?.id === item.id}
                                    onDestroy={onDestroy}
                                    disableSelect={isMultiple}
                                    open={toggle ? toggle.is_public : -1}
                                    onOpen={onOpen}
                                    split={debounceParams.parent_id === item.parent_id}
                                    onSplit={onSplit} />
                            </Col>;
                        })}
                    </Row>}
                    request={projectId !== undefined ? getProduceList : getProduceAllList}
                />
            </div>
            {
                isMultiple ? <Space size={16} className={'receive-footer px-4 pt-2 pb-3'}>
                    <Button type={'primary'} loading={loading2} onClick={() => {
                        send2({
                            category_id: params.asset_category,
                            is_public: 1
                        });
                    }}>公开所选</Button>
                    <Button type={'primary'} loading={loading2} onClick={() => {
                        send2({
                            category_id: params.asset_category,
                            is_public: 0
                        });
                    }}>隐藏所选</Button>
                    <Button onClick={() => setMultiple(false)}>返回</Button>
                </Space> : <Space size={16} className={'receive-footer px-4 pt-2 pb-3'}>
                    <Button type={'primary'} loading={loading} onClick={() => {
                        send({ tasks: toggles });
                    }}>保存</Button>
                    <Button type={'primary'} onClick={bulkHandler}>批量操作</Button>
                    {isRollback && <Button disabled={!destroyItem} onClick={rollbackHandler}>回退</Button>}
                    {isDiscard && <Button disabled={!destroyItem} onClick={destroyHandler}>作废</Button>}
                    <Button onClick={onCancel}>取消</Button>
                </Space>
            }
        </div>
        {nodes}
    </>;
};
export default Operate;
