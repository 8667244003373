/**
 * @name: headerNavLayout.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description: 顶部导航默认布局
 */
import { matchPath, resolveUrl, useGetAuthRoutes, useOutlet } from '@components/router/routes';
import { Tabs } from 'antd';
import { useHistory } from 'react-router';

const { TabPane } = Tabs;
// eslint-disable-next-line import/prefer-default-export,require-jsdoc
export const HeaderNavLayout = function () {
    const outlet = useOutlet();
    const history = useHistory();
    const routes = useGetAuthRoutes();
    const authItems = routes.sort((a, b) => a.menuSore - b.menuSore);
    const selectedKey = authItems.find((item) => matchPath(item.name))?.name || -1;
    return (
        <div className="nav-layout">
            <Tabs
                tabBarGutter={40}
                animated={false}
                activeKey={selectedKey}
                defaultActiveKey={selectedKey}
                onChange={(key) => {
                    const url = resolveUrl(key);
                    history.push(url);
                }}
            >
                {
                    authItems.map((item) => (
                        <TabPane tab={item.title} key={item.name} />
                    ))
                }

            </Tabs>
            <div className="nav-layout-container">
                {outlet}
            </div>
        </div>
    );
};
