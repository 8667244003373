/**
 * @name: ItemRenderImage.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 列表渲染图片
 */
import { clsx } from '@gUtils';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';
import React from 'react';
import { LazyLoadingImage } from '@components';

const ItemRenderImage = function(props) {
    const {
        images = [],
        ...other
    } = props;
    const onPreview = (index) => {
        const filterImage = images.filter(i => i.length > 0);
        const current = ([].concat(...filterImage.slice(0, index))).length;
        PreviewImage.open({
            images: [].concat(...filterImage) ,
            current: current,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };
    if(images.length === 0) return null
    return <div {...other}>
        {images.slice(0, 10)
            .map((urls, i) => <div key={i} className={'task-list-item-images-col'}>
                <div className={'task-list-item-images-col-box'}>
                    <LazyLoadingImage
                        onClick={(event) => {
                            event.stopPropagation();
                            onPreview(i)
                        }}
                        alt={i}
                        src={urls[0]}
                        preview={{
                            visible: false
                        }}
                        className={clsx('task-list-item-images-col-box-image')}
                    />
                    {i === 9 && <span className={'task-list-item-images-col-box-end'}>
                        <i />
                        <i />
                        <i />
                    </span>}
                </div>

            </div> )}
    </div>;
};
export default ItemRenderImage;
