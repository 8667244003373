/**
 * @name: Comment.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 任务包评论功能
 */

import { Form } from 'antd';
import { Input } from '@components';
import { forwardRef, useImperativeHandle } from 'react';

const CommentForm = forwardRef(function(props, ref) {
    const {initialValues} = props
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }));
    return <Form form={form} initialValues={initialValues} layout={'vertical'}>
        <Form.Item label={'评论'}
                   name={'comment'}
                   required={true} rules={[
            {
                required: true,
                message: '请填写评论'
            }]}>
            <Input.TextArea
                autoSize={{
                    minRows: 5,
                    maxRows: 5
                }}
                placeholder='请填写评论'
                maxLength={200}
                showCount={({
                    count,
                    maxLength
                }) => `${count}/${maxLength}`}
            />
        </Form.Item>
    </Form>;
});
export default CommentForm
