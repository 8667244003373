import React, { Component } from 'react'
import { Col, Row } from 'antd'
import { Activities, Announcements, Stats , Todos} from './'
// import { TodoTabs } from '@views/1_dashboard/TodoTabs'

export default class Dashboard extends Component {
    state = {
        statisticalData: {},
        spinning: false,
        projectActivities: [],
        notifications: [],
    };
    componentDidMount() {
        this.setState({ spinning: true });
        this.initialize();
    }

    initialize = async () => {
        let projectActivities = (await this.props.fetchProjectActivities({ page: 1, page_size: 5 })) || [];
        let statisticalData = await this.props.fetchStatistics();
        let notifications = (await this.props.fetchNotifications()) || [];
        this.setState({
            statisticalData,
            projectActivities,
            notifications,
            spinning: false,
        });
    };

    render() {
        return (
            <div>
                <div>
                    <Row justify="space-between" gutter={[16, 8]}>
                        <Col xl={8} lg={8} sm={24} xs={24}>
                            <Stats {...this.props} {...this.state} />
                        </Col>
                        <Col xl={8} lg={8} sm={24} xs={24}>
                            <Announcements {...this.props} {...this.state} />
                        </Col>
                        <Col xl={8} lg={8} sm={24} xs={24}>
                            <Activities {...this.props} {...this.state} />
                        </Col>
                    </Row>
                    {/* <TodoTabs /> */}
                    <Todos {...this.props} {...this.state} />
                </div>
            </div>
        )
    }
}
