import React from 'react'
import TargetRecord from './target_record'

const TargetConfig = (props) => {
    const { tab =  '1'} = props
    const template = {
        '1': <TargetRecord />
    }
    return (
        <div>
            {
                template[tab] ?? <TargetRecord />
            }
        </div>
    )
}

export default TargetConfig
