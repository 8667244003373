import { Form, Button, Select, DatePicker } from 'antd';
import { useParentRoute } from '@components/router/routes.js'
import { AuthButton } from "@/components"
import { useSwr } from '@hooks'
import { projectSettingsAPI } from "@api"
import moment from "moment";
import { timeStamp } from './../config'

const { Option } = Select;
const FormITem = Form.Item

/**
 * 表单处理
 * @param {*} props props
 * @returns {JSX.Element}
 */
const BusinessDataKpiForm = (props) => {
    const route = useParentRoute()
    const {
        submitForm,
        resetForm,
        csvExport,
        btnLoading
    } = props
    const [form] = Form.useForm()

    /**
     * 获取制作组成员列表
     * @returns {object}
     */
    const getUserList = () => {
        return projectSettingsAPI.fetchUserRelations({ filter: { type: 9 } }).then((res) => {
            if (res.code === 1000) {
                return res.data.list
            }
        })
    }

    const { data: userList, userListLoading } = useSwr(['/user-relation/list'], getUserList)

    /**
    * 表单
    * @param {*} value
    */
    const handleSubmit = (value) => {
        if (route.key === "week" && value.created_at) {
            value.start_time = timeStamp(moment(value.created_at).day(1).subtract(2, 'day'), 1)
            value.end_time = timeStamp(moment(value.created_at).day(8).subtract(2, 'day'), 2)
        } else if (route.key === "month" && value.created_at) {
            value.start_time = timeStamp(moment(value.created_at).startOf('month'), 1)
            value.end_time = timeStamp(moment(value.created_at).endOf('month'), 2)
        }
        delete value?.created_at
        submitForm(value)
    }

    /**
     * 禁用时间
     * @param {*} current
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    /**
     * 重置表单
     */
    const submitFormReset = () => {
        form.resetFields();
        resetForm(form.getFieldsValue())
    }

    /**
     * 导出数据
     */
    const submitFormExport = () => {
        csvExport();
    }

    return (
        <div className="amp-business-data-from-page">
            <Form
                className='amp-business-data-form'
                onFinish={handleSubmit}
                form={form}
                initialValues={{ created_at: route.key === 'week' ? moment().week('week') : moment().month('month') }}
            >
                <FormITem
                    name="created_at"
                    label="统计周期"
                >
                    <DatePicker
                        picker={route.key}
                        className='amp-input-sz-l'
                        disabledDate={disabledDate}
                    />
                </FormITem>
                <FormITem
                    label="搜索成员"
                    name="user_id"
                >
                    <Select
                        size="large"
                        placeholder="请选择人员"
                        className="amp-input-sz-l"
                        showSearch={true}
                        loading={userListLoading}
                        optionFilterProp="children"
                    >
                        {
                            userList?.map((item) => {
                                return (
                                    <Option key={item.id} value={item.user_id}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>
                </FormITem>
                <FormITem
                    className='amp-business-data-form-buttons mg-l-6'
                >
                    <Button
                        className='amp-btn-sz-s amp-blue-border'
                        onClick={submitFormReset}
                    >
                        重置
                    </Button>
                    <Button
                        className='amp-btn-sz-s'
                        type="primary"
                        htmlType='submit'
                    >
                        查询
                    </Button>
                </FormITem>
            </Form>
            <div className="amp-business-data-button">
                <AuthButton
                    loading={btnLoading}
                    permission="user_kpi_statistic_export"
                    className='amp-btn-sz-s'
                    onClick={submitFormExport}
                >
                    导出
                </AuthButton>
            </div>
        </div>
    )
}

export default BusinessDataKpiForm

