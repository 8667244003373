/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 制作池领取
 */
import { useDebounceState, useSend, useAccess } from '@hooks';
import { receiveTake, taskFlowAPI } from '@api';
import { $error, $success, Button, ScrollContainer } from '@components';
import { Col, Row, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { PoolItem } from '@views/pool/components/PoolItem';
import { useParams } from 'react-router-dom';
import { FilterForm } from '@views/pool/components/FilterForm';

export const getProduceAllList = (page, pageSize, params) => {
    return taskFlowAPI.getAllProduce({
        page: page,
        page_size: pageSize,
        filter: {
            ...params,
        }
    })
        .then(res => {
            if (res.code === 1000) {
                return {
                    list: res.data.list,
                    statusTotal: res.data.status_total,
                    total: res.data.total
                };
            } else {
                throw new Error(res.msg);
            }
        });
};

export const getProduceList = (page, pageSize, params) => {
    const {
        project_id,
        ...other
    } = params;
    return taskFlowAPI.getProduceList({
        page: page,
        page_size: pageSize,
        project_id,
        filter: {
            ...other,
        }
    })
        .then(res => {
            if (res.code === 1000) {
                return {
                    list: res.data.list,
                    statusTotal: res.data.status_total,
                    total: res.data.total
                };
            } else {
                throw new Error(res.msg);
            }
        });
};

const Receive = function(props) {
    const {
        onCancel,
        tmp
    } = props;
    const { id: projectId } = useParams();
    const receiveFormRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [selectIds, setIds] = useState([]);

    const [loading, send] = useSend((ids) => receiveTake({ task_id: ids }), {
        onError(error) {
            $error(error.message);
        },
        onSuccess() {
            setIds([]);
            receiveFormRef.current?.refresh()
            scrollContainerRef.current?.refresh();
            $success('领取成功');
        }
    });
    const [params, debounceParams, setParams] = useDebounceState({
        is_split: 0,
        parent_id: undefined,
        uid_name: undefined,
        produceInfo: null
    });
    const remainingSelectNumber = params.produceInfo?(params.produceInfo.can_take_count - params.produceInfo.produce_take_count - selectIds.length):0;
    function onSelect(item) {
        // 拿到拆分任务id数组
        // 后端返回的sibling包含所有状态的兄弟拆分任务，没有区分是否处在制作待选状态，8对应后端定义的制作待选状态
        const temp = []
        item?.sibling.map(({id, task_status}) => {
            if (task_status.code === 8) {
                temp.push(id);
            }
        });
        temp.push(item.id);
        // 获取已选id索引
        const index = selectIds.indexOf(item.id);
        let ids = [...selectIds];
        if (index > -1) {
            // 移除
            ids = ids.filter((item) => {
                return temp.indexOf(item) === -1;
            })
        } else {
            const selectTotal = temp.length + selectIds.length; // 当前选中总数量
            const limit = params.produceInfo.can_take_count - params.produceInfo.produce_take_count; // 领取上限
            if (selectTotal > limit) {
                $error("剩余数量不足，无法勾选领取");
                return ;
            } else {
                // 添加
                ids.push(...temp);
            }
        }
        setIds(ids);
    }

    function onChange(values) {
        setIds([]);
        setParams(state => ({
            ...state,
            ...values
        }));
    }

    function onSplit(item) {
        receiveFormRef.current?.setParentId(item.parent_id);
    }
    const isProduceSetting = useAccess('produce_setting') // 编辑权限

    const parentIdParams = useMemo(() => ({
        is_split: debounceParams.is_split,
        parent_id: debounceParams.parent_id,
        uid_name: debounceParams.uid_name,
        project_id: projectId !== undefined ? Number(projectId) : undefined,
        is_public: isProduceSetting ? 2 : 1,
    }), [
        projectId,
        debounceParams.is_split,
        debounceParams.parent_id,
        isProduceSetting,
        debounceParams.uid_name]);
    return <div className={'receive-content'}>
        <FilterForm
            tmp={tmp}
            ref={receiveFormRef}
            value={params}
            onChange={onChange}
            className={'receive-header px-4 py-2'}
        />
        <div className={'receive-body pl-3'}>
            <ScrollContainer
                col={4}
                pageSize={16}
                ref={scrollContainerRef}
                className={'receive-body-scroll'}
                url={'/produce/all'}
                params={parentIdParams}
                renderItem={(col, key) => <Row key={key} gutter={16} style={{ marginBottom: 16 }}>
                    {col.map(item => <Col key={item.id} span={6}>
                        <PoolItem
                            item={item}
                            tmp={tmp}
                            split={debounceParams.parent_id === item.parent_id}
                            onSplit={onSplit}
                            disabled={remainingSelectNumber <= 0 && !selectIds.includes(item.id)}
                            select={selectIds.includes(item.id)}
                            onSelect={onSelect}
                        />
                    </Col>)}
                </Row>}
                request={projectId !== undefined ? getProduceList : getProduceAllList}
            />
        </div>

        <Space size={16} className={'receive-footer px-4 pt-2 pb-3'}>
            <Button type={'primary'} loading={loading} onClick={() => send(selectIds)} disabled={!selectIds.length}>领取({selectIds.length})</Button>
            <Button onClick={onCancel}>取消</Button>
        </Space>
    </div>;
};
export default Receive;
