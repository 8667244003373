import { RECEIVE_TASK, RECEIVE_TASKS } from "@actions/action_consts";

const tasksReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TASK:
      return { ...state, [action.task.id]: action.task };
    case RECEIVE_TASKS:
      let tasks = action.tasks.list;
      let res = {};
      tasks.forEach((task) => {
        res[task.id] = task;
      });
      if (!action.params.filter) return res;
      return { ...state, ...res };
    default:
      return state;
  }
};

export default tasksReducer;
