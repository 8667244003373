import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Badge, Popover } from "antd";
import { tabs } from "@vars/global_vars";
import { connectUserSession, getActivityUrl } from "@gUtils";
import {
    $success,
    $push,
    ResetPassword,
    ProfileAvatar,
    Iconfont,
} from "@/components";
import { todosSubscription } from "@/utils/config";
import { inject, observer } from "mobx-react";
import env from "env";
import { taskFlowAPI } from '@api';
import { autorun } from 'mobx';

let stompClient;
@inject("todosStore")
@observer
class HeaderBar extends Component {
    resetRef = React.createRef();
    state = {
        inStorages: [],
        storagesVisible: false
    }
    componentDidMount() {
        this.initialize();
        this.timeCycle = setInterval(() => {
            this.getAssetProgress()
        }, 30000)
        this.listenToActionChanges()
    }
    // 监听todosStore.storagesType为true 刷新入库队列接口
    listenToActionChanges = () => {
        autorun(() => {
            if (this.props.todosStore.storagesType) {
                this.getAssetProgress()
            }
        });
    }
    componentWillUnmount() {
        stompClient.disconnect()
        clearInterval(this.timeCycle)
    }
    initialize = () => {
        const { session, fetchUserPermissions } = this.props;
        const { id, is_first_login, initial_session } = session;
        if (session && is_first_login) {
            this.resetPop();
        } else if (initial_session) {
            $success("🎉🎉🎉 欢迎来到AMP，艺术制造实践之旅～");
        }
        stompClient = todosSubscription(id, this.printMessage);
        fetchUserPermissions();
        this.props.todosStore.getTodosCount();
    };
    // 轮训获取入库上传列表状态
    getAssetProgress = async () => {
        this.props.todosStore.setUpdateStorages(false)
        const res = await taskFlowAPI.getListGodownEntry()
        if (res.code === 1000) {
            this.setState({
                inStorages: res.data?.list || []
            })
        }
    }

    get activeKey() {
        const { pathname } = this.props.location;
        const activeKey = pathname.split("/")[1];
        return activeKey;
    }

    printMessage = ({ body }) => {
        const res = JSON.parse(body);
        const { message } = res;
        $push(message, () => this.messageClick(res), this.goHomePage);
    };

    goHomePage = () => {
        const { pathname } = this.props.location;
        if (pathname.includes("dashboard")) {
            window.location.reload()
        }else{
            this.goToPage("/dashboard")
        }
    };

    messageClick = (item) => {
        const finalUrl = getActivityUrl(item);
        this.goToPage(finalUrl);
    };

    goToPage = (page) => this.props.history.push(page);
    goToAccount = () => this.goToPage("/account");
    onRef = (ref) => (this.child = ref);

    resetPop = () => {
        if (this.resetRef.current) this.resetRef.current.show();
    };
    renderSingleTab = (tab) => {
        const isActive = this.activeKey === tab.key;
        const className = isActive ? "header-tab-active" : "header-tab";
        return (
            <Link to={tab.path} className={className} key={tab.key}>
                {tab.title}
            </Link>
        );
    };
    handleVisibleChange = storagesVisible => {
        this.setState({ storagesVisible });
    };
    // 入库队列状态显示
    renderCloudIcon = () => {
        const storagesLength = this.state.inStorages.length || 0
        if (storagesLength === 0) return
        const content = () => {
            return (
                <div className="amp-header-storages-popover-content">
                    {
                        this.state.inStorages.map(item => (
                            <Link
                                key={item.task_id}
                                className="amp-header-storages-popover-line"
                                to={`/projects/${item.project_id}?task_flow?taskId=${item.task_id}`}
                                onClick={() => this.setState({ storagesVisible: false })}
                            >
                                <span className="amp-header-storages-popover-taskid">#{item.asset_uid}</span>
                                <span className="amp-header-storages-popover-taskname">{item.asset_name}</span>
                            </Link>
                        ))
                    }

                </div>
            )
        }
        const Title = () => (
            <div className="amp-header-storages-popover-title">
                <div className="flex-center">
                    <span className="amp-header-storages-popover-maintext">{storagesLength}</span>
                    <span>个资产文件正在校验文件完整性</span>
                </div>
                <div
                    className="amp-header-storages-popover-close"
                    onClick={() => this.setState({ storagesVisible: false })}
                >
                    <Iconfont type="icon-a-16-guanbi" className="fz-16" />
                </div>
            </div>
        )
        return (
            <div className="mg-r-22">
                <Popover
                    overlayClassName="amp-header-storages-popover"
                    content={content}
                    title={Title}
                    trigger="click"
                    visible={this.state.storagesVisible}
                    onVisibleChange={this.handleVisibleChange}
                >
                    <Badge count={storagesLength} overflowCount={99}>
                        <Iconfont type="icon-rukuduilie" className="fz-24 icon-hover-main" />
                    </Badge>
                </Popover>

            </div>
        )
    }

    renderTodoIcon = () => {
        const { todosCount } = this.props.todosStore;
        return todosCount > 0 ? (
            <span className="mg-r-22" onClick={() => this.goToPage("/dashboard")}>
                <div className="relative">
                    <Badge count={todosCount} overflowCount={99}>
                        <Iconfont type="icon-list_daiban-01" className="fz-24" />
                    </Badge>
                </div>
            </span>
        ) : (
            ""
        );
    };

    renderAccountBar = () => {
        const { session, location } = this.props;
        const { pathname } = location;
        const onAccountPage = pathname.split("/")[1] === "account";
        if (!session || onAccountPage) return <div style={{ width: "184px" }}></div>;
        return (
            <div className="header-user">
                {this.renderCloudIcon()}
                {this.renderTodoIcon()}
                <div className="display-flex align-center" onClick={this.goToAccount}>
                    <ProfileAvatar {...session} />
                    <span className="mg-l-16">
                        {session?.role?.name || session?.role}
                    </span>
                </div>
            </div>
        );
    };

    render() {
        const logoSrc = process.env.PUBLIC_URL + `${`${env.logo}`}`;
        return (
            <>
                <div className="header-bar">
                    <div className="header-logo" onClick={() => this.goToPage("/")}>
                        <img src={logoSrc} alt="logo" />
                    </div>
                    <div className="header-tabs">
                        {tabs?.map((tab) => this.renderSingleTab(tab))}
                    </div>

                    {this.renderAccountBar()}
                </div>
                <ResetPassword ref={this.resetRef} {...this.props} />
            </>
        );
    }
}

export default withRouter(connectUserSession(HeaderBar));
