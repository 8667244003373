/**
 * 入库 建议颜色，包含色值 rgb，打勾颜色
 */
const checkSign = {
    white: '#FFF',
    black: '#000'
}
export const standardColor = [
    {
        colorCode: 'rgb(184, 52, 43)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(252, 107, 51)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(242, 206, 88)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(81, 150, 98)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(30, 55, 131)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(89, 53, 98)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(36, 36, 36)',
        checkSign: checkSign.white
    },
    {
        colorCode: 'rgb(255, 255, 255)',
        checkSign: checkSign.black
    },
    {
        colorCode: 'rgb(171, 174, 170)',
        checkSign: checkSign.white
    },
]
