/**
 * 获取表格的可视化高度
 * @param {*} ref react ref 节点属性
 * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为50)
 * @returns {number} 表格滚动高度 表格剩余空间，在整屏不滚动时距离底部最大高度
 */
export const getTableHeight = (ref, extraHeight = 50) => {
    let showHeight = 0;
    if (ref && ref.current) {
        showHeight = window.innerHeight - ref.current.offsetTop - extraHeight;
    }
    return showHeight > 600 ? showHeight : 600;
};

export default { getTableHeight };
