import * as React from "react";
import { observer, inject } from "mobx-react";
import {titleBaseStyle, columnStyle}from './vars'
import { Checkbox } from "antd";


@inject("addRelationStore")
@observer
class UserPart extends React.Component {
  get store() {
    return this.props.addRelationStore;
  }
  render() {
    const { usersPaiSheDisplay, selectedIds, shootingMemberIds } = this.store;
    return (
      <div>
        <div style={{ ...titleBaseStyle }}>用户</div>
        <Checkbox.Group
          value={[...selectedIds, ...shootingMemberIds]}
          onChange={(val) => this.store.updateSelectedIds(val)}
          style={{ width: "100%", height: "30vh", overflow: "auto" }}
        >
          {usersPaiSheDisplay?.map((item) => {
            const { name, id, disabled } = item;
            return (
              <div className="user-checkbox-item" key={id}>
                <Checkbox value={id} disabled={disabled}>
                  {name}
                </Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
      </div>
    );
  }
}
export default UserPart;
