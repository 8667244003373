import { Form, Input, Select, Button, InputNumber } from "antd";
import { Iconfont, AuthButton } from "@/components";

const { Option } = Select;

export const FormDescription = ({ type, size }) => (
  <Form.Item className="plan-form-item" name={type}>
    <Input
      placeholder="选填"
      className={size ? `input-sz-${size}` : "input-sz-sm"}
      maxLength={500}
    />
  </Form.Item>
);

export const FormAdd = () => {
  return (
    <Form.Item>
      <AuthButton
        type="text"
        htmlType="submit"
        icon={<Iconfont type="icon-plus" className="icon-23" />}
        className="plan-form-add"
      />
    </Form.Item>
  );
};

export const FormSelect = (props) => {
  const { type } = props;
  const chosen = optionsChosen(props) ? optionsChosen(props) : {}
  const typeOptions = Object.values(chosen).sort((a,b)=>(a.sort > b.sort) ? 1 : -1);
  const options = typeOptions?.map((ele) => (
    <Option key={ele.code} label={ele.label} value={ele.code}>
      {ele.label}
    </Option>
  ));
  return (
    <Form.Item
      className="table-form-item select-sz-xs"
      name={type}
      rules={[{ required: true }]}
    >
      <Select placeholder="请选择" size="large" showSearch optionFilterProp="children" showArrow>
        {options}
      </Select>
    </Form.Item>
  );
};


const optionsChosen = (props) => {
  const { tripType, tripTransportation, fundType, type } = props;
  let res;
  switch (type) {
    case "trip_type":
      res = tripType;
      break;
    case "fund_type":
      res = fundType;
      break;
    case "trip_transportation":
      res = tripTransportation;
      break;
    default:
      res = {};
      break;
  }
  return res;
};


export const FormAmount = ({ type }) => (
  <Form.Item
    className="plan-form-item"
    name={type}
    rules={[{ required: true }]}
  >
    <InputNumber size="large" className="input-sz-s" placeholder="请输入金额"  max="999999" min="0" step="0.01"/>
  </Form.Item>
);