import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal } from "antd";
import { AuthButton } from "@components";

@inject("messagesStore")
@observer
class UnbindDialog extends Component {
  state = { loading: false };
  get store() {
    return this.props.messagesStore;
  }

  startLoad = () => this.setState({ loading: true });
  finishLoad = () => this.setState({ loading: false });

  handleConfirm = () => {
    this.startLoad();
    this.store.unbindWechat().then(() => {
        this.finishLoad()
        this.store.hideUnbind()
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Modal
        visible={this.store.unbindVisible}
        footer={false}
        width="28.6vw"
        onCancel={() => this.store.hideUnbind()}
        centered
        destroyOnClose
      >
        <div className="dialog-pd">
          <div className="fz-24 bold mg-b-30">是否解除绑定？</div>
          <div className="fz-18 mg-b-68">
            解除绑定后将无法在微信中收到消息提醒。
          </div>
          <div className="dialog-footer">
            <AuthButton
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleConfirm}
            >
              确认
            </AuthButton>
            <AuthButton
              key="back"
              onClick={() => this.store.hideUnbind()}
              className="mg-l-20"
            >
              取消
            </AuthButton>
          </div>
        </div>
      </Modal>
    );
  }
}
export default UnbindDialog;
