import React, { Component, createContext } from 'react';
import { TaskList, TitleSection } from './components';
import { withRouter } from 'react-router';
import { boardSubscription } from '@/utils/config';
import { inject, observer } from 'mobx-react';
import FallView from './fall_view';
// 切换瀑布流的Context
export const ViewTypeContext = createContext(null)
let stompClient;
@withRouter
@inject("taskStore")
@observer
class TaskBoard extends Component {
    state = {
        changed: false,
        viewType: 'card'
    };
    get projectId() {
        return JSON.parse(this.props.match.params.id);
    }

    componentDidMount() {
        this.props.taskStore.updateRefresh(false);
        this.initialize();
    }
    componentWillUnmount() {
        localStorage.removeItem(`project_${this.projectId}`);
        // stompClient.disconnect()
    }

    initialize = () => {
        stompClient = boardSubscription(this.projectId, this.listenToChange);
    };

    listenToChange = () => {
        const time = JSON.parse(localStorage.getItem(`project_${this.projectId}`));
        const params = { project_id: this.projectId, time };
        this.props.taskStore.checkRefresh(params);
    }

    TaskItemClick = (id) => {
        const { projectId } = this.props;
        this.props.history.push(`/projects/${projectId}?task_flow?taskId=${id}`);
    };

    listRender = () => {
        const { listTypes } = this.props;
        return Object.values(listTypes || {}).sort((a, b) => a.sort - b.sort).map(({code}) => {
            return <TaskList
                listCode={code}
                key={code}
                {...this.props}
                TaskItemClick={this.TaskItemClick}
            />
        })
    };

    render() {
        const { viewType } = this.state
        return (
            <ViewTypeContext.Provider value={{
                viewType: viewType,
                switchValueType: () => {
                    this.setState({
                        viewType: this.state.viewType === 'card' ? 'fall' : 'card'
                    })
                }
            }}>
                <TitleSection {...this.props} viewType={viewType} />
                {
                    viewType === 'card' ? <div className="task-board">{this.listRender()}</div> : <FallView project_id={this.projectId} />
                }
            </ViewTypeContext.Provider>

        );
    };
}

export default TaskBoard;
