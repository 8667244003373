import {
  combineReducers
} from "redux";

import {
  RECEIVE_ROLES,
  REQUEST_ROLE_LIST
} from '@actions/action_consts'

const pages = (pages = {}, action = {}) => {
  Object.freeze(pages)
  switch (action.type) {
    case REQUEST_ROLE_LIST:
      return {
        ...pages,
        [action.params.page]: {
          items: [],
          fetching: true,
        },
      };
      case RECEIVE_ROLES:
        let list = action.roles.list
        return {
          ...pages,
          [action.params.page]: {
            items: list ? list?.map((item) => item.id) : [],
            fetching: false,
          },
        };
        default:
          return pages
  }
}

const filter = (filter = {}, action = {}) =>
  action.type === REQUEST_ROLE_LIST ? (action.params.filter || {}) : filter;
const currentPage = (currentPage = 1, action = {}) =>
  action.type === REQUEST_ROLE_LIST ? (action.params.page || 1) : currentPage;
const total = (total = 0, action = {}) =>
  action.type === RECEIVE_ROLES ? action.roles.total : total;

export const rolePagination = combineReducers({
  pages,
  currentPage,
  total,
  filter
});