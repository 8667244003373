import { connect } from "react-redux";
import Profile from "./profile";
import {
  accountUpdate,
  sendCode,
  validateCode,
  changePassword,
} from "@actions/1_entities/account_actions";
import { logOut } from "@actions/0_session/session_actions";

const mapStateToProps = (state) => ({
  users: state.entities.users,
  currentUser: state.entities.users[state.session.id] || {},
  validationToken: state.session.validationToken,
});
const mapDispatchToProps = (dispatch) => ({
  sendCode: (cb) => dispatch(sendCode(cb)),
  accountUpdate: (params, cb) => dispatch(accountUpdate(params, cb)),
  validateCode: (params, cb) => dispatch(validateCode(params, cb)),
  changePassword: (params, cb) => dispatch(changePassword(params, cb)),
  logout: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
