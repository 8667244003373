import { Modal, Upload } from 'antd';
import React, { Component } from 'react';
import { $error, Iconfont } from '@/components';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export default class BaseUpload extends Component {
    state = {
        previewVisible: false,
        previewImage: "",
        fileList: [],
    };

    componentDidMount() {
        this.initiateFiles();
    }

    initiateFiles = () => {
        const { entity } = this.props;
        let image_urls = null;
        if (entity instanceof Array) {
            image_urls = entity.length > 0 && entity;
        } else {
            image_urls = entity?.image_urls || [];
        }
        const fileList = image_urls?.map((url, idx) => ({
            uid: idx,
            url: url,
            status: "done",
        }));
        this.setState({ fileList });
        if (!this.props.value) {
            this.props.onChange?.(fileList)
        }
    };

    handleChange = ({ fileList }) => {
        const filtered = fileList.filter((file) => file.status !== "error");
        this.setState({ fileList: filtered });
        this.props.onChange?.(filtered)
    };
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        PreviewImage.open({
            images: [file.url || file.preview],
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };

    onRemove = (file) => {
        this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    };

    beforeUpload = (file) => {
        const lessThan20M = file.size / 1024 / 1024 < 20;
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (this.props.noRestriction) return false
        if (!isJpgOrPng) {
            $error("仅支持jpg或png格式图片，请重新选择～");
            file.status = "error";
        }
        if (!lessThan20M) {
            $error("图片大小不能超过20M, 请重新选择～");
            file.status = "error";
        }
        return false;
    };

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const { titleClassName, extra, showIndicator, maxCount, length, beforeUpload, iconType, iconTitle } = this.props;
        const uploadButton = (
            <div>
                <Iconfont type={iconType ? iconType : "icon-a-14-zengjia"} />
                <div style={{ marginTop: 8, color: "#BFC5D1" }}>
                    {
                        iconTitle ? iconTitle : "上传图片"
                    }
                </div>
            </div>
        );
        return (
            <div>
                <div>
                    <div className={titleClassName}>
                        {showIndicator ? `参考图 (${fileList.length}/10)` : ""}
                        {!extra ? "" : extra}
                    </div>
                </div>
                <div
                    className={
                        this.props.code === 18 || this.props.code === 23
                            ? "mg-t-6 removeMg"
                            : "mg-t-16 removeMg"
                    }
                >
                    <Upload
                        fileList={fileList}
                        listType="picture-card"
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        beforeUpload={beforeUpload ? beforeUpload : this.beforeUpload}
                        onRemove={this.onRemove}
                        maxCount={maxCount}
                    >
                        {fileList.length >= (length ? length : 10) ? null : uploadButton}
                    </Upload>
                </div>

                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                    className="image-preview-modal"
                >
                    <img alt="preview" src={previewImage} className="preview-img" />
                </Modal>
            </div>
        );
    }
}
