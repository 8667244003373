import axios from 'axios';

const configsAPI = {
    fetchProjectConfig() {
        return axios.get('/project/config');
    },
    fetchShootingPlanConfig() {
        return axios.get('/shooting-plan/config');
    },
    fetchTaskConfig() {
        return axios.get('/task/config');
    },
    fetchUserConfig() {
        return axios.get('/user/config');
    },
    fetchRelatedUsers(params) {
        return axios.post('/related-user/config', params);
    },
    fetchUserRelationTypes() {
        return axios.get('/user-relation-types/config');
    },
    fetchParticipator() {
        return axios.post('/task/assignments');
    },
    uploadImages(params) {
        return axios.post('/api/upload', params);
    }
};

export default configsAPI;
