import React, { Component } from "react";
import { Select } from "antd";
import {ShrinkImage, AssetIdAndName} from './asset_components'
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { toJS } from "mobx"

@inject("filterStore")
@observer
class AssetUpdate extends Component {
    get store() {
        return this.props.filterStore;
    }

    componentDidMount() {
        this.store.changeAssetCategoryId(this.props.category.code)
    }

    renderOptions = (category) => {
        const tempArr = [];
        for (const key in category) {
            const { Option } = Select;
            tempArr.push(<Option key={key} value={key}>{category[key].label}</Option>)
        }
        return tempArr;
    }

    handleClick = async () => {
        const { currentPage } = this.props;
        const targetId = this.store.assetCategoryId + "";
        const final = {
            id : this.props.id,
            category : "",
        }
        for (const key in this.props.asset_category) {
            if (targetId === key) {
                final.category = this.props.asset_category[key].code;
                await this.store.updateAssets(final);
                this.props.hide();
                this.props.fetchAssets({
                    page: 1,
                    page_size: 6,
                });
                this.store.setClearFlag();
            }
        }
    }
   render() {
        const options = this.renderOptions(this.props.asset_category)
        return (
            <div>
                <ShrinkImage {...this.props}/>
                <AssetIdAndName {...this.props}/>
                <div>
                    <span className="asset-update-span">资产种类</span>
                    <div className="asset-update-category">
                        <Select
                            style={{width:"260px"}}
                            defaultValue={this.props.category.label}
                            onChange={(val) => {
                                this.store.changeAssetCategoryId(val)
                            }}
                            size="large"
                            showSearch
                        >{options}</Select>
                    </div>
                </div>
                <div className="asset-update-footer">
                    <button
                        onClick={() => {
                            this.handleClick();
                        }}
                    >确 认</button>
                    <button
                        onClick={() => {
                            this.props.hide();
                        }}
                    >取 消</button>
                </div>
            </div>
        );
    }
}


export default withRouter(AssetUpdate);
