import { Form, Input, Tooltip } from "antd";
import React from "react";
import { roleDIC, name } from "@vars/views/settings_role_vars";

const NameInput = (props) => {
  let item = roleDIC.filter((e) => {
    return e.title === "角色名称";
  })[0];
  const { roleDetails } = props;
  return (
    <Form.Item
      className="form-item"
      label={item.title}
      name={name}
      rules={item.rules.name}
      initialValue={roleDetails.name}
      required={true}
    >
      <Input className="normal-input" placeholder={item.placeholder}></Input>
    </Form.Item>
  );
};

const ConnectedAccounts = (props) => {
  let item = roleDIC.filter((e) => {
    return e.title === "绑定人员";
  })[0];

  const { type, roleDetails } = props;
  let usersContent = "";
  if (roleDetails.users) {
    roleDetails.users?.map((item, idx) => {
      if (idx > 0) {
        usersContent += "/" + item.name;
      } else {
        usersContent += item.name;
      }
      return usersContent;
    });
  }

  return (
    <div className="role-user">
      <Tooltip placement="bottomLeft" title={usersContent}>
        <Form.Item
          className="form-item"
          name="users"
          label={item.title}
          initialValue={usersContent}
        >
          <Input
            className="normal-input"
            placeholder={type === "add" ? "未关联" : ""}
            disabled={true}
          ></Input>
        </Form.Item>
      </Tooltip>
    </div>
  );
};

export { NameInput, ConnectedAccounts };
