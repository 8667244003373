/**
 * @name: Btns.jsx
 * @user: cfj
 * @date: 2022/3/25
 * @description: 详情操作按钮组
 */

import { Space } from 'antd';
import { $error, $success, Button, Drawer, Typography } from '@components';
import { useTaskContext } from '@views/taskPackage';
import { useParams } from 'react-router-dom';
import {
    AbandonTaskForm,
    AcceptTaskForm,
    AuditForm,
    AuditTaskPackage,
    BatchTaskForm,
    PublicTaskPackageForm,
    SetTaskForm
} from './ActionForms';
import { useHistory } from 'react-router';
import { downloadFile, jsonToFormData } from '@gUtils';
import env from 'env';
import { projectSettingsAPI, taskPackageAPI, taskPlanAPI } from '@api';
import TaskPackageRejectedForm from './ActionForms/TaskPackageRejectedForm';
import React from 'react';
import JsonToFormData from '@gUtils/jsonToFormData';
import { useSwr, useAccess } from '@hooks';
import AmpIcon from '@components/ampIcon';

export const ActionBtns = function(props) {
    const {infoData} = props
    const history = useHistory()
    const {
        taskId: id
    } = useParams();
    const taskId = Number(id)
    // 获取任务信息
    const {data,mutate } = useSwr(['/package-item/list', taskId], (url, taskId) => taskPlanAPI.getPlanTaskList({
        page: 1,
        page_size: 1,
        package_id: taskId,
        filter: {
            status: [1,2,3,4]
        }
    }))
    // 获取审核权限人员
    const {data: relationData} = useSwr(infoData.status.code === 2?'/user-relation/list':null, () => projectSettingsAPI.fetchUserRelations({
        filter: { type: 45 },
    }))
    const packageItemDataStatics =data?.data.statics || []
    const {
        closeModal,
        session, // 当前登录用户信息
        parentUrl, // 上一级url
        isPlanUser,// 当前登录用户是否在策划组中
        getContainer, // 渲染抽屉的容器
        refreshTaskList, // 刷新任务包列表
        refreshTaskDetails, // 刷新任务包详情
        refreshTaskInfoList, // 刷新任务包详情下的任务信息
        refreshTaskHistoryData // 刷新任务包详情下的任务操作历史
    } = useTaskContext();
    const [nodes, open] = Drawer.useDrawer({
        placement: 'bottom',
        maskClosable: false,
        destroyOnClose: true,
        style: { position: 'absolute' },
        getContainer: getContainer(),
    });
    function openAudit() {
        open({
            title: '提交审核',
            content: <AuditForm initialValues={{statusLabel: '策划待审'}} />,
            onError(error){
                $error(error.message);
            },
            async onOk(){
                const res =   await taskPackageAPI.submitPackage({id: taskId})
                if (res.code === 1000) {
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()

                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        });
    }
    function openTask(){
        open({
            title: '创建任务',
            height: 550,
            appendButton({loading, submit, disabledAutoClose, openEmptyForm}){
                return <Button
                    size="large"
                    style={{ width: 120 }}
                    type="primary"
                    onClick={  () => {
                        disabledAutoClose()
                        openEmptyForm()
                        submit()
                    }}
                    loading={loading}
                >
                    继续创建
                </Button>
            },
            content: <SetTaskForm initialValues={{
                package_id:taskId,
                userName: session.name,
                material_type: 2
            }}  />,
            onError(error) {
                $error(error.message);
            },
            async onOk(values){
              const res =  await taskPlanAPI.createPlanTask(JsonToFormData(values))
                if (res.code === 1000) {
                    $success('添加成功');
                    refreshTaskInfoList()
                    refreshTaskList()
                    mutate()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        });
    }
    function openBatchTask(){
        function handleDownload(){
            downloadFile(env.serveUrls + "static/resource/package/上传策划任务样表.xlsx");
        }
        open({
            title: <div className={'batch-title'}><Typography variant={'h3'}>批量上传</Typography> <a style={{fontSize: 12}} onClick={handleDownload}><AmpIcon name={'download'} /> 下载详表</a></div>,
            height: 350,
            content: <BatchTaskForm initialValues={{package_id: taskId}} />,
            onError(error){
                if(error instanceof Error){
                    $error(error.message);
                }
            },
            async onOk(values){
              const res =  await taskPlanAPI.volumnCreatePlanTask(jsonToFormData(values))
                if (res.code === 1000){
                    if(res.data.failed_count){
                        throw res
                    } else {
                        refreshTaskInfoList()
                        refreshTaskList()
                        mutate()
                        $success('提交成功');
                    }
                } else {
                    throw new Error(res.msg || '添加失败')
                }
            }
        })
    }
    function openAuditTask(){
        open({
            title: '审核任务包',
            height: 550,
            content: <AuditTaskPackage refreshTaskInfoList={() => {
                refreshTaskInfoList()
                mutate()
            }
            } taskId={taskId} />
        })
    }
    function openPublicTaskPackage(){
        open({
            title: '公开任务包',
            content: <PublicTaskPackageForm initialValues={{statusLabel: '公开待选'}} />,
            onError(error){
                $error(error.message);
            },
            async onOk(values){
              const res =  await  taskPackageAPI.publicPackage({id: taskId, priority: values.priority})
                if(res.code === 1000){
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        })
    }

    function openTaskPackageReject(){
        open({
            title: '驳回任务包',
            content: <TaskPackageRejectedForm initialValues={{statusLabel: '策划中', userName: session.name}}/>,
            onError(error){
                $error(error.message);
            },
            async onOk(){
                const res =  await  taskPackageAPI.disapprovePackage({id: taskId})
                if(res.code === 1000){
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        })
    }

    function openTaskPackageDiscard(){
        open({
            title: '作废任务包',
            content: <AuditForm initialValues={{statusLabel: '作废'}} />,
            onError(error){
                $error(error.message);
            },
            async onOk(){
                const res =  await  taskPackageAPI.discardPackage({id: taskId})
                if(res.code === 1000){
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        })
    }
    function onAcceptTask (){
        open({
            title: '承接任务包',
            height: 400,
            content: <AcceptTaskForm initialValues={{scoreTotal: infoData.score, statusLabel: '任务包已领取'}}/>,
            onError(error){
                $error(error.message);
            },
            async onOk(){
                const res = await taskPackageAPI.takePackage({id: taskId})
                if(res.code === 1000){
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        })
    }
    function onAbandonTask(){
        open({
            title: '放弃任务包',
            height: 450,
            content: <AbandonTaskForm initialValues={{statusLabel: '任务包待选'}}/>,
            onError(error){
                $error(error.message);
            },
            async onOk(values){
                const res = await taskPackageAPI.abandonPackage({id: taskId, comment: values.comment})
                if(res.code === 1000){
                    $success('提交成功');
                    refreshTaskDetails()
                    refreshTaskList()
                    refreshTaskHistoryData()
                } else {
                    throw new Error(res.msg || '操作失败')
                }
            }
        })
    }
    // 是否是创建人
    const isCreateTaskUser = infoData.creator.id === session.id
    // 当前登录人是否有审核权限
    const isAuthUser = !!relationData?.data?.list?.find(user => user.user_id === session.id)
    // 任务包作废权限
    const isDiscard = useAccess('task_package_discard')


    const statusToBtns = {
        1: {
            label: '策划中',
            btns: [
                isCreateTaskUser && <Button type={'primary'} key={1} onClick={() => history.push(`${parentUrl}/edit/${taskId}`)}>编辑任务包</Button>,
                isCreateTaskUser && <Button type={'primary'} disabled={!packageItemDataStatics.reduce((acc, i) => acc + i.count, 0)} key={2} onClick={openAudit}>提交审核</Button>,
                (isPlanUser || isCreateTaskUser) && <Button key={3} onClick={openTask}>创建任务</Button>,
                (isPlanUser || isCreateTaskUser) && <Button key={4} onClick={openBatchTask}>批量创建任务</Button>,
                <Button key={5} onClick={closeModal}>关闭</Button>
            ]
        },
        2: {
            label: '策划待审',
            btns: [
                isAuthUser && <Button key={1} type={'primary'} onClick={openAuditTask}>任务包审核</Button>,
                // 存在未审核，驳回的任务时禁止公开
                isAuthUser && <Button type={'primary'} disabled={packageItemDataStatics.some(i => (i.status === 1 && i.count) || (i.status === 3 && i.count))} key={2} onClick={openPublicTaskPackage}>公开任务包</Button>,
                <Button key={3} onClick={openTaskPackageReject}>任务包驳回</Button>,
                isDiscard && <Button key={4} onClick={openTaskPackageDiscard}>任务包作废</Button>,
                <Button key={5} onClick={closeModal}>关闭</Button>
            ]
        },
        3: {
            label: '公开待选',
            btns: [
                <Button key={1} type={'primary'} onClick={onAcceptTask}>承接任务包</Button>,
                isDiscard && <Button key={4} onClick={openTaskPackageDiscard}>任务包作废</Button>,
                <Button key={5} onClick={closeModal}>关闭</Button>
            ]
        },
        4: {
            label: '已领取',
            btns: [
                <Button key={1} onClick={onAbandonTask}>放弃任务包</Button>,
                isDiscard && <Button key={4} onClick={openTaskPackageDiscard}>任务包作废</Button>,
                <Button key={5} onClick={closeModal}>关闭</Button>
            ]
        },
        5: {
            label: '已作废',
            btns: [
                <Button key={5} onClick={closeModal}>关闭</Button>
            ]
        }
    };

    const btns = statusToBtns[infoData.status.code]?.btns.filter(i => i)
    if(btns.length){
        return <>
            <Space size={40} className={'detail-body-footer'}>
                {
                    btns.map((i) => i)
                }
            </Space>
            {nodes}
        </>
    } else {
        return  nodes
    }
};
