import React, { memo, useState } from 'react'
import { AuthButton } from '@components'
import SceneModal from './scene_modal'

const SceneButtons = (props) => {
    const {topicId} = props
    const [modalType, setModalType] = useState('')

    return (
        <>
            <div className="amp-topic-scene-header-btns">
                <AuthButton
                    permission="topic_scene_address_create"
                    type="primary"
                    className="amp-scene-header-btns-btn"
                    onClick={() => setModalType('add')}
                >
                    新增场景
                </AuthButton>
                <AuthButton
                    permission="topic_scene_address_quick_commit"
                    className="amp-scene-header-btns-btn"
                    onClick={() => setModalType('fastReview')}
                >
                    快速审核
                </AuthButton>
                <AuthButton
                    permission="topic_scene_address_commit"
                    className="amp-scene-header-btns-btn"
                    onClick={() => setModalType('review')}
                >
                    提交策划审核
                </AuthButton>
                <AuthButton
                    permission="topic_scene_address_delete"
                    className="amp-scene-header-btns-btn"
                    onClick={() => setModalType('abandon')}
                >
                    作废
                </AuthButton>
            </div>

            <SceneModal
                onClose={(type) => setModalType(type)}
                modalType={modalType}
                topicId={topicId}
            />
        </>
    )
}

export default memo(SceneButtons)
