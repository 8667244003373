import PreviewImage from '@components/image/preview_image'
import { LazyLoadingImage, } from '@components'
import { sessionAPI } from '@api'

// _columns
export const _columns = [
    {
        title: '资产ID',
        dataIndex: 'asset_uid'
    },
    {
        title: '资产名称',
        dataIndex: 'asset_name',
        ellipsis: {
            showTitle: true
        },
    },
    {
        title: '缩略图',
        dataIndex: 'review_images',
        render(text, record) {
            const src = record.review_images
            const onPreview = function (src) {
                PreviewImage.open({
                    images: [src],
                    request: (url) => sessionAPI.fetchOriginalImage(url)
                        .then(res => res.data?.origin_urls?.[0] || '')
                })
            }
            return <LazyLoadingImage
                preview={{
                    visible: false
                }}
                onClick={(event) => {
                    event.stopPropagation()
                    onPreview(src)
                }}
                width='60px'
                height='60px'
                src={src} />
        }
    },
    {
        title: '拍摄人',
        dataIndex: 'shoot_user'
    },
    {
        title: '资产类型',
        dataIndex: 'asset_type'
    },
    {
        title: '原序列图片数量',
        dataIndex: 'collect_images_count'
    },
    {
        title: '分数',
        dataIndex: 'collection_score'
    },
    {
        title: '拍摄备注',
        ellipsis: {
            showTitle: true
        },
        dataIndex: 'shoot_remark'
    },
    {
        title: '解析人',
        dataIndex: 'analysis_user',
        ellipsis: true
    },
    {
        title: '解析完成时间',
        dataIndex: 'analysis_finish_time'
    },
    {
        title: '状态',
        dataIndex: 'handover_status',
        render: (_, record) => {
            return (
                <div>
                    {record.handover_status === 1 && <span className='amp-handover-status amp-no-analytical'>{handoverStatus[1].label}</span>}
                    {record.handover_status === 2 && <span className='amp-handover-status amp-analytical-ing'>{handoverStatus[2].label}</span>}
                    {record.handover_status === 3 && <span className='amp-handover-status amp-finish'>{handoverStatus[3].label}</span>}
                    {record.handover_status === 4 && <span className='amp-handover-status amp-fail'>{handoverStatus[4].label}</span>}
                </div>

            )
        }
    },
    {
        title: '解析备注',
        dataIndex: 'analysis_remark',
        ellipsis: {
            showTitle: true
        }
    },
]

// 交接状态
export const handoverStatus = [
    {
        id: 0,
        label: "所有状态"
    },
    {
        id: 1,
        label: "未到解析"
    },
    {
        id: 2,
        label: "解析中"
    },
    {
        id: 3,
        label: "已提交"
    },
    {
        id: 4,
        label: "已作废"
    },
]
