import React, { Component } from "react";
import { observer, inject } from "mobx-react";

@inject("todosStore")
@observer
class TodosTab extends Component {
  get store() {
    return this.props.todosStore;
  }

  handleTodoRequest = (type) => {
    if (type === "task") return this.store.fetchTaskTodos();
    else return this.store.fetchProjectTodos();
  };

  renderTab = (type) => {
    const { currentTab } = this.store;
    const isTask = type === "task";
    let title = isTask ? "我的任务" : "我的项目";
    let total = isTask ? this.store.taskTotal : this.store.projectTotal;
    const titleClass = `board-title ${
      currentTab === type ? "primary-color" : ""
    }`;
    const handleTabClick = () => {
      this.store.updateTab(type);
      this.handleTodoRequest(type);
      this.store.againFilterType()
    };
    return (
      <div
        className={`${titleClass} ${!isTask && "mg-l-40"}`}
        key={type}
        onClick={handleTabClick}
      >
        <span>{title}</span>
        <span className="mg-l-8">{total}</span>
      </div>
    );
  };

  render() {
    return (
      <div className="mg-t-30 mg-b-30 align-center">
        {["task", "project"]?.map((item) => this.renderTab(item))}
      </div>
    );
  }
}
export default TodosTab;
