/**
 * @name: column.jsx
 * @user: cfj
 * @date: 2022/4/21
 * @description: 资产数量柱状图
 */
import { Column as AColumn } from '@ant-design/plots'
import { withEmpty } from '@/HOC'

const Column = function (props) {
    const { data, type = '月', } = props
    const config = {
        color: '#29C1B9',
        data: data,
        xField: 'date',
        yField: 'count',
        appendPadding: [20, 0, 0, 0],
        tooltip: false,
        label: {
            position: 'top'
        },
        yAxis: {
            title: {
                text: '(个)',
                autoRotate: false,
                position: 'end'
            },
        },
        xAxis: {
            title: {
                text: `(${type})`,
                autoRotate: false,
                position: 'end'
            },
        },
        meta: {
            date: {
                alias: '时间'
            },
            count: {
                alias: '总数'
            }
        }
    }

    return <AColumn {...config} />
}
export default withEmpty(Column)
