import axios from 'axios';

const sessionAPI = {
    login(params) {
        return axios.post('/user/login', params);
    },
    fetchOriginalImage(src) {
        return axios.get(`/image/origin?images=${src}`);
    }
};

export default sessionAPI;
