import { AmpIcon, Iconfont, Typography } from '@components';
import ItemRenderImage from './ItemRenderImage';
import { useHistory, useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { clsx } from '@gUtils';

/**
 * @name: TaskListItem.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 列表项
 */
const classPrefix = 'task-list-item'

const priorityIcon = {
    1: "icon-top",
    2: "icon-list-high",
    3: "icon-low",
};
const TaskListItem = function(props){
    const history = useHistory()
    const location = useLocation()
    const {url} = useRouteMatch()
    const toUrl = `${url}/detail/${props.id}`
    const isActive = location.pathname === toUrl
    function gotoDetail(){
        history.push(toUrl)
    }
    const {item_count, score, status, color, no, priority,title, items, description} = props
    const images = items?.map((item) => item.images).filter((i) => i && i?.length)
    return <div className={clsx(classPrefix, isActive && 'task-list-item-active')} onClick={gotoDetail}>
        <div className={`${classPrefix}-head`}>
            <Typography className={'flex-align-center'}>
                <AmpIcon name={'analysis'} className={'mr-7'}/>
                {item_count||0}个
                <AmpIcon name={'score'} className={'mr-7 ml-10'}/>
                {score || 0}分
            </Typography>
            <div className={'task-tags'}>
                <Typography className={'task-tag'} style={{background: status.color}}>
                    {status.label}
                </Typography>
                {Reflect.has(priorityIcon, priority) &&<Iconfont
                    className={`${classPrefix}-icon`}
                    type={priorityIcon[priority]}
                />}
            </div>
        </div>
        <div className={`${classPrefix}-title`}>
            <Typography weight={400} color={'primaryText'} className={'mr-10'}>{no}</Typography>
            <Typography weight={400} color={'primaryText'} className={`${classPrefix}-title1`}>{title}</Typography>
        </div>
        <ItemRenderImage className={`${classPrefix}-images-row`} images={images} />
        {!!(description) && <Typography color={'primaryText'} className={`${classPrefix}-desc`}>
            {description}
        </Typography>}
    </div>
}
export default TaskListItem
