import React, { useState, useEffect, useRef, memo } from 'react'
import { Table, Button } from 'antd';
import TableItem from './table_item'
import { getTableHeight } from '@/utils/get_element_size'
import { monthEnum } from '@vars/views/settings_vars'
import useDebounce from '@/hooks/useDebounce'

const TargetTable = (props) => {
    const { year, itemRevise, tableLoading, dataSource, editable = false } = props
    const tableRef = useRef(null)
    const [tableY, setTableY] = useState()

    const columns = [
        {
            title: () => (
                <Button type="primary"
                    size="large"
                    style={{ width: "100%" }}
                >
                    {year}
                </Button>),
            dataIndex: 'name',
            width: 110,
            align: 'right',
        },
    ]
    /**
     * @method 表头添加1-12月
    */
    for (const key in monthEnum) {
        columns.push({
            title: () => (
                <div
                    className="amp-target-table-thead"
                >
                    {`${monthEnum[key]}月`}
                </div>
            ),
            dataIndex: key,
            align: 'center',
            width: 80,
            render: (text, record) => {
                return (
                    <TableItem
                        text={text}
                        record={record}
                        dataIndex={key}
                        revise={itemRevise}
                        editable={editable}
                    />
                )
            }
        })
    }
    const tableHeight = () => setTableY(getTableHeight(tableRef, 100))
    const windowResize = useDebounce(tableHeight, 500)

    useEffect(() => {
        tableHeight()
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize);
        }
    }, [])

    return (
        <div
            ref={tableRef}
            className="mg-b-20"
        >
            <Table
                className="amp-target-table"
                loading={tableLoading}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: tableY }}
            />
        </div>
    )
}

export default memo(TargetTable)
