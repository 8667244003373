/**
 * 类组建 防抖函数
 * @param {*} fn 需要防抖处理的函数
 * @param {*} wait 间隔时间
 */
export const debounce = (fn, wait = 200) => {
    let timeout = null

    return function (...args) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            fn.call(this, ...args)
        }, wait)
    }
};

/**
 *  获取jsonp文件
 * @param {String} url jsonp url
 * @param {Number} overtime 超时时间
 * @returns {Promise}
 */
export const getJSONP = (url, overtime = 10000) => {
    const promise = new Promise( (resolve, reject) =>  {
        //注册回调函数
        const callbackName = 'jsonp' + Math.ceil(Math.random() * 1000000);
        window[callbackName] =  (json) =>  {
            document.head.removeChild(script);    //移除scipt标签
            clearTimeout(script.timer);  //清除超时计时器
            window[callbackName] = null;
            resolve(json || {});   //成功处理
        }
        //生成scipt标签
        let script = document.createElement('script');
        script.src = url + callbackName;
        document.head.appendChild(script);
        //错误处理
        script.onerror =  () =>  {
            if (window[callbackName] == null) {
                reject('timeout');   //超时处理
            } else {
                reject('faild');   //失败处理
                window[callbackName] = null;
                document.head.removeChild(script);
            }
        }
        //超时判断
        script.timer = setTimeout( () => {
            window[callbackName] = null;
            document.head.removeChild(script);
        }, overtime);
    });
    return promise;
};
