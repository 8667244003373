import { observable, action, flow, makeAutoObservable, computed } from 'mobx';
import { configsAPI, usersAPI } from '@api';
import { taskGroupsStore } from '@stores';
class ConfigsStore {
    constructor() {
        makeAutoObservable(this);
    }
    @observable projectConfig = {};
    @observable planConfig = {};
    @observable taskConfig = {};
    @observable userConfig = {};
    @observable relatedUsers = [];
    @observable allUsers = [];
    @observable userRelationTypeConfig = {};
    @observable userFetching = false;
    @observable initiator = [];
    @observable secretList = [];

    @observable paramsObj = {
        uid_name: '',
        participants: [],
        asset_group: [],
        asset_type: [],
        assignee_id: null,
        discard_type: null,
        task_status: [],
        discard_at: {},
        discard_dates: []
    };

    @action setSecretList(arr) {
        this.secretList = arr;
    }
    @action clearUsers() {
        this.relatedUsers = [];
    }
    @action setUidName(name) {
        this.paramsObj.uid_name = name;
    }
    @action setParticipant(id) {
        this.paramsObj.participants =  id || id === 0 ? [id] : [];
    }
    @action filterParticipator(arr) {
        const res = new Map();
        return arr.filter((item) => !res.has(item.name) && res.set(item.name, 1));
    }
    @action setDiscardAt(discard_at, discard_dates) {
        this.paramsObj.discard_at = discard_at;
        this.paramsObj.discard_dates = discard_dates;
    }
    //flow requests
    fetchProjectConfig = flow(function* () {
        try {
            const res = yield configsAPI.fetchProjectConfig();
            if (res.code === 1000) {
                this.projectConfig = res.data;
            } else {
            }
        } catch (error) {}
    });
    fetchShootingPlanConfig = flow(function* () {
        try {
            const res = yield configsAPI.fetchShootingPlanConfig();
            if (res.code === 1000) {
                this.planConfig = res.data;
            } else {
            }
        } catch (error) {}
    });
    fetchTaskConfig = flow(function* () {
        taskGroupsStore.flag = true;
        try {
            const res = yield configsAPI.fetchTaskConfig();
            if (res.code === 1000) {
                this.taskConfig = res.data;
                taskGroupsStore.flag = false;
            } else {
            }
        } catch (error) {}
    });
    fetchUserConfig = flow(function* () {
        try {
            const res = yield configsAPI.fetchUserConfig();
            if (res.code === 1000) {
                this.userConfig = res.data;
            } else {
            }
        } catch (error) {}
    });
    fetchRelatedUsers = flow(function* (filter) {
        this.userFetching = true;
        try {
            const res = yield configsAPI.fetchRelatedUsers({ filter });
            if (res.code === 1000) {
                this.relatedUsers = res.data.list;
                this.userFetching = false;
            } else {
            }
        } catch (error) {}
    });

    fetchAllUsers = flow(function* () {
        this.userFetching = true;
        try {
            const res = yield usersAPI.fetchUsers();
            if (res.code === 1000) {
                this.allUsers = res.data.list;
                this.userFetching = false;
            }
        } catch (error) {}
    });

    fetchUserRelationTypes = flow(function* () {
        try {
            const res = yield configsAPI.fetchRelatedUsers();
            if (res.code === 1000) {
                this.userRelationTypeConfig = res.data;
            } else {
            }
        } catch (error) {}
    });

    fetchParticipator = flow(function* () {
        try {
            const res = yield configsAPI.fetchParticipator();
            if (res.code === 1000) {
                res.data.map((item) => {
                    this.initiator.push(item.initiator);
                });
            }
        } catch (error) {}
    });
}

export default new ConfigsStore();
