/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 创建或编辑任务
 */

import { Modal } from 'antd';
import { useHistory } from 'react-router';
import { createContext, useCallback, useContext, useRef, useState } from 'react';
import TaskList from './TaskList';
import './index.less';
import { useSelector } from 'react-redux';

const TaskContext = createContext(null);
export const TaskProvider  = TaskContext.Provider;
export const useTaskContext = function() {
    return useContext(TaskContext);
};
const TaskPackage = function(props) {
    const {
        parentUrl,
        children
    } = props;
    const containerRef = useRef()
    const session = useSelector((state) => state.session);
    const taskListRef = useRef();
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const refreshTaskList = useCallback(() => {
        taskListRef.current?.refresh();
    }, []);
    return (
        <TaskProvider value={{
            closeModal: () => setVisible(false),
            parentUrl, // 上一级url
            session, // 登陆用户信息
            getContainer: () => containerRef.current, // 抽屉弹款的渲染容器
            refreshTaskList, // 刷新任务包列表
            refreshTaskDetails: () => {}, // 刷新任务包详情
            refreshTaskInfoList: () => {}, // 刷新任务包详情下的任务信息
            refreshTaskHistoryData: () => {} // 刷新任务包详情下的任务操作历史
        }}
        >
            <Modal
                title='任务包'
                centered={true}
                visible={visible}
                wrapClassName={'amps-task-package'}
                width={1335}
                onCancel={() => setVisible(false)}
                footer={null}
                afterClose={() => history.replace(parentUrl)}
            >
                <div className={'amps-task-package-content'}>
                    <TaskList className={'list-content'} ref={taskListRef} />
                    <div className={'detail-body'} ref={containerRef}>
                        {children}
                    </div>
                </div>
            </Modal>

        </TaskProvider>

    );
};

export default TaskPackage;
