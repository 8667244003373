import React, { Component } from 'react';
import { Drawer } from "antd";

export default class TaskDrawer extends Component {
    render() {
        const { visible, children, containerRef } = this.props;
        return (
            <div
                className='amps-task-drawer'
                style={{

                }}
            >
                <Drawer
                    destroyOnClose={true}
                    placement="bottom"
                    getContainer={containerRef}
                    closable={false}
                    height="auto"
                    maskClosable={true}
                    visible={visible}
                    mask={true}
                    maskStyle={{
                        background: "rgba(0, 0, 0, 0.2)"
                    }}
                    style={{
                        position: "absolute",
                    }}
                >
                    {children}
                </Drawer>
            </div>
        )
    }
}
