import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { checkAuth, connectUserSession, parseTaskID } from '@gUtils';
import { $success, AuthButton, BaseDrawer, DrawerFooter, WaterfallPreview } from '@components';
import { withRouter } from 'react-router';
import { ActionButtons, TaskFormContent, TaskHistoryContent, TaskProcessDrawer } from '.';
import { Spin } from 'antd';

@withRouter
@inject("taskStore", "taskProcessStore", "collapseStore", "todosStore", "filterStore", "userRelationsStore")
@observer
class TaskDrawer extends Component {
    get store() {
        return this.props.taskStore;
    }

    get userRelationsStore() {
        return this.props.userRelationsStore;
    }
    get contentType() {
        const { search } = this.props.location;
        return search.split("?")[1];
    }

    get searchID() {
        const { search } = this.props.location;
        if (search.includes("task_flow")) {
            return parseInt(search.replace("?task_flow?taskId=", ""));
        } else if (search.includes("task_edit")) {
            return parseInt(search.replace("?task_edit?taskId=", ""));
        } else if (search.includes("task_add")) {
            return parseInt(search.replace("?task_add?taskId=", ""));
        } else {
            return NaN;
        }
    }
    get taskID() {
        return this.props.taskStore.taskID;
    }
    get projectId() {
        return (
            parseInt(this.props.match.params.id) || this.props.todosStore.projectId
        );
    }
    get currentTask() {
        return this.props.taskStore.currentTask;
    }
    get drawerContent() {
        return this.store.drawerContent;
    }

    componentDidMount() {
        this.setUpDrawer();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setUpDrawer();
        }
    }

    componentWillUnmount() {
        this.props.taskProcessStore.hide();
        this.props.filterStore.footerShow = true;
        this.store.setAssetCharacter(0);
    }

    setUpDrawer = () => {
        const projectId = this.props?.match?.params?.id;
        if (projectId) {
            this.props.todosStore.updateProjectId(projectId);
        }
        if (this.searchID >= 0) {
            this.showDrawer();
            if (this.searchID > 0) {
                this.requestTaskDetails();
            }
        } else {
            this.onClose();
        }
    };

    updateIssues = (type) => {
        const { pathname } = this.props.location;
        if (pathname.includes("projects")) {
            if (type === "plan") {
                this.updateShootingList();
            } else if (type === 'producer') {
                this.updateProducerList()
            } else if (type === "task") {
                this.updateAllLists();
            }
        } else if (pathname.includes("dashboard")) {
            this.props.todosStore.finishTaskTodo(this.searchID);
            this.props.history.push("/dashboard");
        }
    };

    // 更新监制的task
    updateProducerList = () => {
        let filter = this.props.filter || {};
        const params = {
            list_type: 1,
            page: 1,
            page_size: 5,
            project_id: this.projectId,
            filter,
        };
        this.props.fetchTasks(params);
    };

    updateShootingList = () => {
        let filter = this.props.filter || {};
        const params = {
            list_type: 2,
            page: 1,
            page_size: 5,
            project_id: this.projectId,
            filter,
        };
        this.props.fetchTasks(params);
    };

    updateAllLists = () => {
        Object.values(this.props.listTypes || {}).forEach(({code}) => {
            let filter = this.props.filter || {};
            const params = {
                list_type: JSON.parse(code),
                page: 1,
                page_size: 5,
                project_id: this.projectId,
                filter,
            };
            this.props.fetchTasks(params);
        })
    };

    showDrawer = () => this.store.show();
    onClose = () => this.store.hide();

    handleDownload = () => {
        window.location.href = this.currentTask.download_url;
        $success("下载成功！");
    };

    handleEdit = () => {
        const { pathname } = this.props.location;
        const search = `?task_edit?taskId=${this.taskID}`;
        if (pathname !== "/dashboard") {
            this.props.history.push(`/projects/${this.projectId}${search}`);
        } else {
            this.props.history.push(search);
        }
    };

    updateActionStatus = (action, status, dispose) => {
        this.props.taskProcessStore.updateActionStatus(action, status, dispose);
    };

    requestTaskDetails = () => {
        const id = this.searchID;
        this.store.fetchTask(id).then(() => {
            if (this.contentType === "task_flow") {
                this.store.fetchHistory(id);
            }
        });
    };
    showWaterfall = async () => {
        const tempNum = parseTaskID(this.props.location);
        this.store.showWaterfall();
        await this.store.getWaterfallSource(tempNum);
    }
    renderFooter = (isAssignee, hasDiscard) => {
        const { taskStatus, currentTask } = this.store;
        const { actions } = currentTask;
        const permissions = this.props.session?.permissions;
        const IsUpdateKind = permissions?.indexOf("update_kind_task");
        // 新增任务按钮显示的节点
        const splitBtn = taskStatus === 9 ||  // 待制作
                        taskStatus === 10 ||  // 制作中
                        taskStatus === 11 ||  // 制作初审待审
                        taskStatus === 12 ||  // 制作终审待审
                        taskStatus === 13 ||  // 工程文件待分配
                        taskStatus === 14 ||  // 工程文件待制作
                        taskStatus === 15 ||  // 工程文件制作中
                        taskStatus === 30 ||  // 工程文件初审
                        taskStatus === 16 ||  // 工程文件终审
                        taskStatus === 23;    // 待入库
        const splitPermissions = permissions?.indexOf("task_duplicate") > -1;
        // 修改评分权限
        const updateScore = permissions?.indexOf("update_produce_score") > -1;
        return (
            <div className="align-center">
                {isAssignee && taskStatus === 1 ? (
                    <div className="align-center">
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={this.handleEdit}
                        >
                            编辑
                        </AuthButton>
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={this.handleDownload}
                            permission="task_download"
                        >
                            下载文件夹
                        </AuthButton>
                    </div>
                ) : (
                    ""
                )}
                <ActionButtons
                    actions={actions}
                    updateActionStatus={this.updateActionStatus}
                    isAssignee={isAssignee}
                    permissions={permissions}
                    taskStatus={taskStatus}
                />
                {
                    IsUpdateKind > -1 && taskStatus !== 18 ? (
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={() => {
                                this.updateActionStatus("update_kind_task", taskStatus);
                            }}
                        >
                            修改类型
                        </AuthButton>
                    ) : null
                }
                {
                    splitPermissions && splitBtn && <AuthButton
                        type="primary"
                        className="mg-r-12"
                        onClick={() => {
                            this.updateActionStatus("duplicate_task", taskStatus);
                        }}
                    >
                        新增任务
                    </AuthButton>
                }
                {
                    updateScore && taskStatus === 12 ? (
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={() => {
                                this.updateActionStatus("update_produce_score", 12);
                            }}
                        >
                            修改评分
                        </AuthButton>
                    ) : null
                }
                {isAssignee && (taskStatus === 11 || taskStatus === 12) ? (
                    <div>
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={() => {
                                this.showWaterfall();
                            }}
                        >
                            瀑布流预览
                        </AuthButton>
                    </div>
                ) : (
                    ""
                )}
                {
                    hasDiscard && taskStatus && taskStatus !== 18 && taskStatus !== 19 ? (
                        <AuthButton
                            className="mg-r-12"
                            onClick={() => {
                                this.updateActionStatus("discard_task", 19);
                            }}
                        >
                            作废
                        </AuthButton>
                    ) : null
                }
            </div>
        );
    };

    render() {
        const { visible, currentTask, loading, taskStatus } = this.store;

        const { actions, project_status } = currentTask;
        const display = loading ? (
            <div
                style={{ height: "67vh" }}
                className="display-flex justify-center align-center"
            >
                <Spin size="large" />
            </div>
        ) : this.contentType === "task_flow" ? (
            <TaskHistoryContent />
        ) : this.contentType === "task_edit" || this.contentType === "task_add" ? (
            <TaskFormContent updatePlanRelated={(type = 'plan') => this.updateIssues(type)} />
        ) : (
            ""
        );
        const isAssignee = currentTask?.assignees?.some(
            (item) => item.id === this.props.session.id
        );

        const hasDiscard = checkAuth(this.props.session.permissions, "task_discard");
        const showDrawer =
            (isAssignee || hasDiscard) &&
            this.contentType === "task_flow" &&
            project_status?.code !== 3;
        const { footerShow } = this.props.filterStore
        return (
            <BaseDrawer visible={visible}>
                <WaterfallPreview {...this.props} />
                <div>{display}</div>
                {showDrawer && footerShow && isAssignee && taskStatus !== 18 ? (
                    <DrawerFooter>{this.renderFooter(isAssignee, hasDiscard, taskStatus)}</DrawerFooter>
                ) : (
                    <>
                        {
                            this.contentType === "task_edit" || this.contentType === "task_add" ? (
                                null
                            ) : (
                                taskStatus &&  (
                                    <DrawerFooter>{this.renderFooter(isAssignee, hasDiscard, taskStatus)}</DrawerFooter>
                                )
                            )
                        }
                    </>
                )}
                {
                    this.props.taskProcessStore?.visible &&
                    <TaskProcessDrawer
                        updateTaskRelated={() => this.updateIssues("task")}
                        updateTask={this.requestTaskDetails}
                        showWaterfall={this.showWaterfall}
                    />
                }

            </BaseDrawer>
        );
    }
}
export default connectUserSession(TaskDrawer);
