import React from 'react'
import { DualAxes as ADualAxes } from '@ant-design/plots'
import { generate } from '@ant-design/colors'
import { withEmpty } from '@/HOC'
import { each, groupBy } from '@antv/util'

const DualAxes = function (props) {
    const { data, data2, type } = props

    // 也可以在项目中直接使用 lodash
    const annotations = [];
    each(groupBy(data, 'time'), (values, k) => {
        const value = values.reduce((a, b) => a + b.value, 0);
        annotations.push({
            type: 'text',
            position: [k, value],
            content: `${value}`,
            style: {
                textAlign: 'center',
                fontSize: 14,
                fill: 'rgba(0,0,0,0.85)',
            },
            offsetY: -10,
        });
    });

    const config = {
        data: [data, data2],
        xField: 'time',
        yField: ['value', 'count'],
        meta: {
            count: {
                alias: '作废占比'
            }
        },
        legend: {
            position: 'top',
            offsetY: 10,
        },
        appendPadding: [20, 0, 0, 0],
        geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                seriesField: 'type',
                maxColumnWidth: 65,
                color: ['#D9D9D9', '#29C1B9'],
                label: {
                    position: 'middle',
                    style: {
                        fill: '#333'
                    },
                    layout: [
                        // 柱形图数据标签位置自动调整
                        {
                            type: 'interval-adjust-position',
                        }, // 数据标签防遮挡
                        {
                            type: 'interval-hide-overlap',
                        }, // 数据标签文颜色自动调整
                        {
                            type: 'adjust-color',
                        },
                    ],
                },
                annotations
            },
            {
                geometry: 'line',
                color: '#595959',
                lineStyle: {
                    opacity: 0.6,
                },
                point: {
                    size: 3,
                    style: {
                        fill: 'white',
                        stroke: '#595959',
                        lineWidth: 2,
                    },
                }
            },
        ],
        yAxis: {
            value: {
                title: {
                    text: '(个)',
                    autoRotate: false,
                    position: 'end'
                },
            },
            count: {
                title: {
                    text: '(%)',
                    autoRotate: false,
                    position: 'end'
                },
            }
        },
        xAxis: {
            title: {
                text: `(${type})`,
                autoRotate: false,
                position: 'end',
                offset: 26
            },
        },
        tooltip: {
            formatter: (datum) => {
                if (datum.type === '作废') {
                    return { name: `当${type}作废任务数`, value: datum.value }
                } else if (datum.type === '完成') {
                    return { name: `当${type}完成任务数`, value: datum.value }
                } else if (datum.hasOwnProperty('count')) {
                    return { name: `当${type}作废占比`, value: datum.count + '%' }
                }
            },
        }
    };

    return <ADualAxes {...config} />
}
export default withEmpty(DualAxes)
