import React, { Component } from 'react';
import { getScenarioKeys, historyEntryLabels, priorityTypes, tastSize } from '@vars/views/task_flow_vars';
import { AssigneesEntry, ImageEntry, SplitTask, CustomMade } from '.';
import RecommendedColor from './../template/recommended_color/index'

class SingleHistoryItem extends Component {
    get action() {
        return this.props.record.action;
    }
    get record() {
        return this.props.record;
    }
    get status() {
        return this.props.record.status;
    }

    itemContentDisplay = (key) => {
        const { history_data } = this.record;
        let res;
         switch (key) {
            case "asset_uid":
                res = `#${history_data?.[key]}`;
                break;
            case "asset_type":
            case "asset_category":
                res = history_data[key]?.label;
                break;
            case "priority":
                res = priorityTypes[history_data?.[key]];
                break;
            case "difficulty":
                res = history_data?.score ? parseInt(history_data?.score) : 0;
                break;
            case "reason":
                res = history_data?.reason;
                break;
            case "comment":
                res = history_data?.comment;
                break;
            case "discard_type":
                res = history_data?.discard_type;
                break;
            case "photographers":
                res = history_data[key]?.map((item, index) => (
                    <span className="mg-r-10" key={index}>{item}</span>
                ));
                break;
            case "collect_images_count":
                res = history_data?.collect_images_count;
                break;
            default:
                res = history_data?.[key] && history_data?.[key];
                break;
        }
        return res;
    };

    singleEntry = (key, idx) => {
        const otherScenarios = ["image_urls", "assignees"];
        const mainClass =
            key === "creator" || key === "comment"
                ? "history-item-col-1"
                : "history-item-col-2";
        if (otherScenarios.includes(key)) {
            return this.otherEntry(key, idx);
        } else if(key === "asset_size") {
            //资产尺寸
            const sizeList = tastSize.map(item => {
                const value = this.itemContentDisplay(item.sizeName)
                const unit = this.itemContentDisplay(item.unit) || 'M'
                if(value){
                    return `${item.label}（${value}${unit}）`
                }
                return null
            })
            const valueList = sizeList.filter(item => item)
            if(valueList.length === 0) return null
            return (
                <CustomMade
                    key={idx}
                    label={historyEntryLabels[key]}
                    value={valueList.join(' ')}
                />
            )
        } else if(key === "color_code") {
            //建议颜色
            const valueList = this.itemContentDisplay(key)
            if(!Array.isArray(valueList)) return null
            return (
                <CustomMade
                    key={idx}
                    label={historyEntryLabels[key]}
                    labelClass="display-flex align-center mg-b-4"
                    value={
                        <RecommendedColor value={valueList} preview />
                    }
                />
            )
        } else {
            if (key === "discard_type" || key === "reason") {
                const temp = this.record?.history_data?.["discard_type"];
                let flag = "discard_type";
                if (!temp) {
                    flag = "reason";
                }
                return (
                    <div className={mainClass} key={idx}>
                        <div className="history-item">
                            <div className="history-item-title">
                                {historyEntryLabels[flag]}
                            </div>
                            <div className="history-item-content">
                                {this.itemContentDisplay(flag)}
                            </div>
                        </div>
                    </div>
                );
            } else if (key === "split_task" && this.props.record.history_data?.children) {
                const props = {
                    action: this.props.record.action,
                    record: this.props.record,
                };
                return (
                    <SplitTask {...props} key={idx} />
                )
            } else {
                if ( !this.itemContentDisplay(key) ) return; // 过滤空数据
                const isShowDifficulty = this.itemContentDisplay(key);
                return this.record?.history_data?.[key] !== (null || "" || undefined) ? (
                    isShowDifficulty === 0 && (key === "score" || key === "difficulty") ? null : (
                        <div className={mainClass} key={idx}>
                            <div className="history-item">
                                <div className="history-item-title">
                                    {
                                        this.itemContentDisplay(key) === "最终评分" ? "最终评分" : historyEntryLabels[key]
                                    }
                                </div>
                                <div className="history-item-content">
                                    {
                                        key === "collScore"
                                        ? this.itemContentDisplay("score")
                                        : this.itemContentDisplay(key) === "最终评分"
                                        ? this.itemContentDisplay("score")
                                        : this.itemContentDisplay(key)
                                    }
                                </div>
                            </div>
                        </div>
                    )
                ) : null;
            }
        }
    };

    otherEntry = (key, idx) => {
        const props = {
            action: this.props.record.action,
            record: this.props.record,
        };
        const assigneesLen = this.props?.record?.history_data?.assignees?.length;
        switch (key) {
            case "image_urls":
                return <ImageEntry {...props} key={idx} />;
            case "assignees":
                return assigneesLen ? <AssigneesEntry {...props} key={idx} /> : "";
            default:
                return " ";
        }
    };

    render() {
        const keys = getScenarioKeys(this.action, this.status, this.record);
        return (
            <div className="history-record-area">
                {keys?.map((key, idx) => this.singleEntry(key, idx))}
            </div>
        );
    }
}
export default SingleHistoryItem;
