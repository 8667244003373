import axios from "axios";

const tasksAPI = {
  fetchTasks(params) {
    return axios.post("/task/list", params);
  },
  createTask(params) {
    return axios.post("/task/add", params);
  },
  updateTask(params) {
    return axios.post("/task/update", params);
  },
  fetchTask(id) {
    return axios.get("/task/info?id=" + id);
  },
  downloadTask(params) {
    return axios.post("/task/download", params);
  },
  fetchHistory(id) {
    return axios.get("/api/task/history?id=" + id);
  },
  checkRefresh(params) {
    return axios.post('/task/panel', params)
  },
  getWaterfallSource(id) {
    return axios.get(`/task/waterfall?id=${id}`);
  },
  getReleatedAsset(id) {
    return axios.get(`/asset/unrelated?project_id=${id}`);
  },
};

export default tasksAPI;
