import React from "react";
import { Image } from "antd";
import { BasicImage } from '@components';
import { toJS } from "mobx";

export const ImageEntry = (props) => {
    const title = props.action === "create_task" ? "预览图" : "参考图";
    const content = props.record?.history_data?.image_urls?.map((url) => {
        return (
            <div key={url} className="mg-r-10">
                {/* <Image src={url} width={120} height={120} /> */}
                <BasicImage src={url} width={120} height={120} />
            </div>
        );
    });
    if (!props.record?.history_data?.image_urls) return "";
    return (
        <div className="history-item-col-1">
            <div className="history-item">
                <div className="history-item-title">{title}</div>
                <div className="history-item-image">{content}</div>
            </div>
        </div>
    );
};

const SingleAssignee = ({ person, extra = "" }) => (
    <div className="history-item-col-2">
        <div className="history-item">
            <div className="history-item-title">待处理人</div>
            <div className="history-item-content">{person}</div>
        </div>
    </div>
);

export const AssigneesEntry = (props) => {
    const { assignees } = props.record.history_data;
    if (!assignees) return "";
    else {
        return <SingleAssignee person={assignees[0]} />;
    }
};

export const SplitTask = (props) => {
    const { children, operator, operation } = props.record.history_data
    return (
        <div>
            <div className="amp-history-item-col-1">
                <div className="history-item">
                    <div className="history-item-title">任务处理人</div>
                    <div className="history-item-content">{operator}</div>
                </div>
                <div className="history-item">
                    <div className="history-item-title">操作类型</div>
                    <div className="history-item-content">{operation || "制作评分"}</div> {/* 旧数据拆分任务时没有返回operation字段，前端直接写死“制作评分” */}
                </div>
            </div>
            {
                children.map((item, index) => {
                    return (
                        <div className="amp-history-item-col-1" key={index}>
                            <div className="history-item">
                                <div className="history-item-title">任务名称</div>
                                <div className="history-item-content">{item.no} {item.title}</div>
                            </div>
                            <div className="history-item">
                                <div className="history-item-title">任务评分</div>
                                <div className="history-item-content">{item.produce_score || item.score}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const CustomMade = ({
    label,
    value,
    className = 'history-item-col-2',
    labelClass
}) => (
    <div className={className}>
        <div className="history-item">
            <div className={`history-item-title ${labelClass}`}>{label}</div>
            <div className="history-item-content">{value}</div>
        </div>
    </div>
);
