/**
 * @name: 403.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 未授权
 */
import { useHistory } from 'react-router'
import React from 'react'

export const Error403 = function(props){
    const history = useHistory()
    return <div>
      <div className="blank-content">
        <div className="empty-img-text">
          <img
            src={process.env.PUBLIC_URL + "/images/other/pending.png"}
            alt="function-pending"
          />
          <h2>您还没有该功能的权限 <a onClick={history.goBack}>返回</a></h2>
        </div>
      </div>
    </div>
}
