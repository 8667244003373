import React, { Component } from "react";
import { withRouter } from "react-router";
import { BaseDrawer, Iconfont } from "@/components";
import { PlanFooter, PlanForm, PlanHistory } from ".";
import { shootingPlanAPI } from "@api";
import {
  emptyPlan,
  shootingPlanStatusIcons,
} from "@vars/views/shooting_plan_vars";
import {inject, observer} from 'mobx-react'

const initialState = {
    visible: false,
    drawerContent: "history",
    planDetails: emptyPlan,
  };

@withRouter
@inject("todosStore", "projectStore")
@observer
class ShootingPlanDrawer extends Component {
  planFormRef = (ref) => (this.planForm = ref);
  planHistoryRef = (ref) => (this.planHistory = ref);
  state = initialState;
  // getters
  get projectId() {
    return (
      parseInt(this.props.match?.params?.id) || this.props.todosStore.projectId
    );
  }
  get currentProject() {
    return this.props.projectStore.currentProject;
  }
  get shootingPlanId() {
    const { search } = this.props.location;
    return parseInt(search.replace("?shootingPlanId=", ""));
  }
  get hasPlan() {
    return this.shootingPlanId !== 0;
  }
  get paramID() {
    const shooting_plan_id = this.shootingPlanId,
      project_id = this.projectId;
    return this.hasPlan ? { shooting_plan_id } : { project_id };
  }

  // lifecycle methods
  componentDidMount() {
    this.setUpDrawer();
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { drawerContent } = this.state;
    if (prevProps.location !== location) {
      this.setUpDrawer();
    }
    if (prevState.drawerContent !== drawerContent && drawerContent === "form") {
      this.setUpPlanDetails();
    }
  }

  componentWillUnmount() {
    this.props.projectStore.clear();
    this.onClose()
  }

  // initializer
  setUpDrawer = () => {
    const projectId = this.props?.match?.params?.id;
    const id = this.shootingPlanId;
    if (projectId) {
      this.props.projectStore.updateProjectId(projectId);
    }
    if (id >= 0) {
      this.showDrawer();
      this.setUpPlanDetails();
    } else {
      this.onClose();
    }
  };

  setUpPlanDetails = () => {
    const id = this.shootingPlanId;
    if (id > 0) this.requestPlanDetails(id);
    else this.setState({ planDetails: emptyPlan });
  };

  // state setters
  showForm = () => this.setState({ drawerContent: "form" });
  showHistory = () => this.setState({ drawerContent: "history" });
  showDrawer = () => this.setState({ visible: true });
  onClose = () => this.setState({ visible: false, drawerContent: "history" });

  // event handlers
  onCancel = () => this.showHistory();
  onEditPlan = () => this.showForm();
  onHandlePlan = (type) => this.planForm.handlePlan(type, this.paramID);
  showApprovalDrawer = (type) => this.planHistory.showDrawer(type);

  getUpdate = (id) => {
    const { pathname } = this.props.location;
    if (!pathname.includes("dashboard")) {
      this.props.history.push(
        `/projects/${this.projectId}?shootingPlanId=${id}`
      );
      this.props.updateProject();
    } else {
      this.props.todosStore.finishProjectTodo(id, this.projectId);
      this.props.history.push("/dashboard");
    }
  };

  updatePlanDetails = (val) => {
    let { planDetails } = this.state;
    planDetails = { ...planDetails, ...val };
    this.setState({ planDetails });
  };

  // requests
  requestPlanDetails = (id) => {
    const planId = id || this.shootingPlanId;
    shootingPlanAPI.fetchPlanDetails(planId).then((res) => {
      const planDetails = res.data;
      const { project } = planDetails;
      if (res.code === 1000 && project.id === this.projectId) {
        this.setState({ planDetails });
      } else {
        this.onClose();
      }
    });
  };

  // component renders
  footerRender = () => {
    const { planDetails, drawerContent } = this.state;
    const { shooting_plan_status } = planDetails;
    const statusCode = this.currentProject.project_status.code;
    const planStatus = shooting_plan_status?.code || 1;
    const props = { ...this, planStatus, drawerContent };
    return statusCode !== 3 ? <PlanFooter {...props} /> : "";
  };

  drawerContentRender = () => {
    const { drawerContent, planDetails } = this.state;
    switch (drawerContent) {
      case "history":
        return (
          <PlanHistory
            onRef={this.planHistoryRef}
            projectId={this.projectId}
            planId={this.shootingPlanId}
            getUpdate={this.getUpdate}
            onClose={this.onClose}
          />
        );
      case "form":
        return (
          <PlanForm
            planDetails={planDetails}
            onRef={this.planFormRef}
            showHistory={this.showHistory}
            updatePlanDetails={this.updatePlanDetails}
            projectId={this.projectId}
            getUpdate={this.getUpdate}
          />
        );

      default:
        return "";
    }
  };

  statusIconRender = () => {
    const { shooting_plan_status } = this.state.planDetails;
    const planStatus = shooting_plan_status ? shooting_plan_status.code : "";
    let icon = shootingPlanStatusIcons[planStatus] || "icon-plan-planning";
    return <Iconfont type={icon} className="icon-rec" />;
  };

  // final render
  render() {
    const { photographer } = this.currentProject;
    const { assignee, shooting_plan_status } = this.state.planDetails;
    const showButton =
      shooting_plan_status?.code === 2
        ? assignee?.id === this.props.session.id
        : photographer?.id === this.props.session.id;
    return (
      <div>
        <BaseDrawer visible={this.state.visible}>
          <div className="drawer-title">
            <div className="display-flex align-center">
              <h2 className="mg-r-14">
                {`拍摄计划：${this.props.projectStore.currentProject.title}`}
              </h2>
              {this.statusIconRender()}
            </div>
          </div>
          {this.drawerContentRender()}
          {showButton ? this.footerRender() : ""}
        </BaseDrawer>
      </div>
    );
  }
}

export default ShootingPlanDrawer;
