import React, { memo, useMemo, useState } from 'react'
import { Input, Form, Radio, Select } from 'antd';
import {
    historyFlowSteps,
    historyFlowStepsWithoutCollectScore,
    previousHistoryFlowSteps,
} from '@vars/views/task_flow_vars';
import { useSelector } from "react-redux";
import { checkAuth } from '@gUtils'
const { Option } = Select;

/**
 * 资产回退表单
 * @param {*} props
 * @returns
 */
const TaskSpecifyFallback = (props) => {
    const { store, taskStore } = props
    const [radioVal, setRadioVal] = useState(1)
    const radioChange = (e) => {
        setRadioVal(e.target.value)
    }
    // 判断用户是否有权限回退指定节点
    const userPermissions = useSelector(state => state.session.permissions)
    const labelDisabled = checkAuth(userPermissions, 'task_change_any_before_status');

    const appoints = useMemo(() => {
        const status = taskStore?.ProjectDetail?.appoint_status
        let list = []
        if (status) {
            if (Array.isArray(status)) {
                list = status
            } else {
                for (const item in status) {
                    list.push(status[item])
                }
            }
        }
        return list
    }, [taskStore])

    const nextLabel = useMemo(() => {
        const next_status = store?.next_status;
        const type = taskStore.currentTask?.task_package_item?.type;
        let label = '';
        if (type === 1) {
            historyFlowStepsWithoutCollectScore.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        } else if (type === 2) {
            historyFlowSteps.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        } else {
            previousHistoryFlowSteps.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        }
        if (next_status === 18) {
            label = '入库'
        }
        if (next_status === 19) {
            label = '作废'
        }
        return label
    }, [])
    return (
        <React.Fragment>
            <div className="process-form-col-2">
                <div className="mg-b-8 bold">回退类型</div>
                <Form.Item
                    name="is_appoint_status"
                    initialValue={1}
                >
                    <Radio.Group onChange={radioChange} >
                        <Radio value={1}>上一步</Radio>
                        {
                            labelDisabled && <Radio value={2}>指定状态</Radio>
                        }
                    </Radio.Group>
                </Form.Item>
            </div>
            <div className="process-form-col-2">
                <div className="mg-b-8 required bold">目标状态</div>
                {
                    radioVal === 1 &&
                    <Input disabled value={nextLabel} className='input-sz-m mg-b-16' />
                }
                {
                    radioVal === 2 &&
                    <Form.Item
                        name="appoint_status"
                        rules={[{ required: true, message: '请选择目标状态' }]}
                    >
                        <Select
                            className='input-sz-m'
                            showSearch
                            placeholder="请选择任务状态"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            size="large"
                        >
                            {
                                appoints.map(item =>
                                    <Option value={item.code} key={item.code}>{item.label}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                }
            </div>
        </React.Fragment>
    )
}

export default memo(TaskSpecifyFallback)
