import PlanForm from "./plan_form";
import { connect } from "react-redux";
import { fetchShootingPlanConfig } from "@actions/2_configs/configs_actions";
import { fetchProject } from "@actions/1_entities/project_actions";
const mapStateToProps = (state) => ({
  tripType: state.configs.trip_type,
  tripTransportation: state.configs.transportation_type,
  fundType: state.configs.fund_type,
  users: state.entities.users,
});

const mapDispatchToProps = (dispatch) => ({
  fetchShootingPlanConfig: (cb) => dispatch(fetchShootingPlanConfig(cb)),
  fetchProject: (params, cb) => dispatch(fetchProject(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm);
