 import axios from 'axios';

 const usersAPI = {
   fetchUsers(params) {
     return axios.post("/user/list", params);
   },
   getUser(id) {
     return axios.get("/user/info?id=" + id);
   },
   createUser(params) {
     return axios.post("/user/create", params);
   },
   updateUser(params) {
     return axios.put("/user/update", params);
   },
   delUser(params) {
     return axios.delete("/user/delete", { data: params });
   },
   unlockUser(params) {
     return axios.put("/user/unlock", params);
   },
 };


 export default usersAPI;