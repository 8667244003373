import { useCallback, useEffect, useRef } from 'react';

/**
 * 函数节流
 * @param fn 节流触发函数
 * @param delay 间隔时间
 * @param dep 节流函数中的数据依赖项
 * @returns {(function(...[*]): void)|*} 返回节流函数
 */
function useThrottle(fn, delay, dep = []) {
    const { current } = useRef({ fn, timer: null, previous: null });
    useEffect(() => {
        current.fn = fn;
    }, [fn]);

    return useCallback(function f(...args) {
        current.now = +new Date();

        if (current.now - current.previous > delay) {
            current.fn.call(this, ...args);
            current.previous = current.now;
        } else {
            clearTimeout(current.timer);
            current.timer = setTimeout(() => {
                current.fn.call(this, ...args);
            }, delay);
        }
    }, dep);
}

export default useThrottle;
