import { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Table } from 'antd';
import { useSwr } from '@hooks';
import { $error, $success } from '@components';
import { collectionStatsAPI } from '@api';
import { boldExportFile } from '@gUtils/downFile';
import { useParentRoute } from '@components/router/routes';
import { weekColumns, collectionSummaryFormList, emptyColumns } from '../config';
import { AnalyticalFrom } from '../../0_components';
import '../index.less';

// 1: 采集 2: 解析 3:制作
const type = 1;

// 默认周月
const defaultStartWeek = moment().week(moment().week() - 1).startOf('week').format('YYYY-MM-DD');
const defaultEndWeek = moment().week(moment().week() - 1).endOf('week').format('YYYY-MM-DD');
const defaultStartMonth = moment().month(moment().month() - 1).startOf('month').format('YYYY-MM-DD');
const defaultEndMonth = moment().month(moment().month() - 1).endOf('month').format('YYYY-MM-DD');

/**
 * 采集统计周/月汇总
 * @param {*} props
 * @returns {JSX.Element} CollectionSummary
 */
const CollectionSummary = (props) => {
    const history = useHistory();
    const route = useParentRoute(); // 获取当前路由节点的配置项
    const [pagination, setPagination] = useState({}); // 分页
    const [exportParams, setExportParams] = useState(); // 导出数据

    /**
     * 获取数据
     * @param {*} url 请求地址
     * @param {*} searchPage 参数
     * @param {*} type 组类型
     * @returns {Object}
     */
    const getTableRequest = (url, searchPage) => {
        const { user_group_id, user_id, created_at } = searchPage;

        let params = {
            type, // 绩效类型
            user_group_id: user_group_id || 0, // 用户小组id
            user_id: user_id || 0, // 用户id
            created_at: created_at || {
                from: route.key === 'week' ? defaultStartWeek : defaultStartMonth,
                to: route.key === 'week' ? defaultEndWeek : defaultEndMonth
            }
        };
        setExportParams(params);
        return collectionStatsAPI.getCollectionSummary(params).then((res) => {
            if (res.code === 1000) {
                res.data.list.map((item, index) => {
                    const children = item.users;
                    delete item.users;
                    item.children = children;
                    item?.children.map((item, index) => {
                        const user_group_name = item.user_name;
                        delete item.user_name;
                        item.user_group_name = user_group_name;
                    });
                });
                res.data.summary.user_group_name = '合计';
                return res;
            }
            throw new Error(res.msg || '');
        });
    };

    const { data: list, isValidating } = useSwr(['/api/task-performance/summary', pagination, route.name], getTableRequest);

    /**
     * 模糊查询
     * @param {*} value 模糊查询value
     */
    const handleSearch = (value) => {
        setPagination({ ...value });
    };

    /**
     * 重置表单
     * @param {*} value
     */
    const resetForm = (value) => {
        setPagination({});
    };

    /**
     * 导出全部数据
     * @param {*} handleCsvRef
     */
    const csvExport = () => {
        collectionStatsAPI.getCollectionSummaryExport(exportParams).then((blob) => {
            boldExportFile(blob);
            $success('导出成功');
        }).catch((err) => {
            $error(err.message);
        });
    };

    /**
    * Table action
    */
    const actionColumns = {
        title: '操作',
        width: '12%',
        render: (record) => (
            <span
                className="amp-blue-btn cursor-pointer"
                onClick={() => handleDetail(record)}
            >
                查看详情
            </span>
        )
    };
    /**
     * 跳转数据详情页面
     * @param {*} record 行数据
     */
    const handleDetail = (record) => {
        if (record.user_group_id >= 0) {
            history.push({
                pathname: '/stats/collection-statistics/details',
                user_group_id: record.user_group_id,
                exportParams: exportParams
            });
        } else if (record.user_id >= 0) {
            history.push({
                pathname: '/stats/collection-statistics/details',
                user_id: record.user_id,
                exportParams: exportParams
            });
        }
    };

    return (
        <div className="amps-collection-data">
            <AnalyticalFrom
                initialValues={{ created_at: moment().subtract(1, route.key), user_group_id: 0 }}
                resetForm={resetForm}
                weekOrMonth
                submitForm={handleSearch}
                csvExport={csvExport}
                formList={collectionSummaryFormList}
                GroupType={type}
            />
            <Table
                loading={isValidating}
                columns={[...weekColumns, ...actionColumns]}
                pagination={false}
                rowKey={(record) => (record.user_group_id ? record.user_group_id : record.user_id)}
                dataSource={list?.data?.list || []}
            />
            <div className="amp-table-summary-no-thead">
                <Table
                    showHeader={false}
                    loading={isValidating}
                    columns={[...weekColumns, ...emptyColumns]}
                    pagination={false}
                    rowKey="common_material_score"
                    dataSource={list?.data ? [list.data?.summary] : []}
                />
            </div>
        </div>
    );
};

export default CollectionSummary;
