import { connect } from "react-redux";
import AddEditAnnouncement from './add_edit'
import {
  fetchAnnouncement,
  createAnnouncement,
  updateAnnouncement,
} from "@actions/1_entities/announcement_actions";

const mapStateToProps = (state) => ({
  announcements: state.entities.announcements,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAnnouncement: (params, success, error) =>
    dispatch(fetchAnnouncement(params, success, error)),
  createAnnouncement: (params, success, error) =>
    dispatch(createAnnouncement(params, success, error)),
  updateAnnouncement: (params, success, error) =>
    dispatch(updateAnnouncement(params, success, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAnnouncement);