import React, { Component } from 'react';
import AssetFilter from './filter_table/asset_filter';
import AssetTable from './filter_table/asset_table';

import { inject, observer } from 'mobx-react';
import AssetWaterFallV2 from './filter_table/asset_water_fall_v2';

@inject("filterStore")
@observer
class Assets extends Component {
  get store() {
    return this.props.filterStore;
  }

  componentDidMount() {
    this.getConfigurations();
    window.addEventListener('beforeunload', this.componentCleanup);
    // this.props.fetchAssets({
    //   page: 1,
    //   page_size: 6,
    // });
  }

  componentWillUnmount() {
    // this.props.fetchAssets({});
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    // 清除redux表格记录的筛选数据
    this.props.requestAssets({})
    this.store.showWaterFall = true;
    this.store.selectedRowKeys = [];
  }

  getConfigurations = () => {
    const { configs } = this.props;
    const { asset_type, asset_category } = configs;
    if (!asset_type || !asset_category) {
      this.props.fetchConfigs();
    }
  };

  render () {
    let { showWaterFall, filter } = this.store
    return (
      <div>
        <div className="display-flex space-between align-center mg-b-20">
          <AssetFilter {...this.props} />
        </div>
        {showWaterFall ? <AssetTable {...this.props} /> : <AssetWaterFallV2 filter={filter} />}
      </div>
    )
  };
}

export default Assets
