import React, { memo } from 'react'
import Pandect from './template/pandect'
import ProjectSituation from './template/project_situation'
import { BasicImage } from '@components'
import './index.less'

const projectColumns = [
    {
        title: '部门名称',
        key: 'department_name',
        dataIndex: 'department_name',
    },
    {
        title: '小组名称',
        key: 'group_name',
        dataIndex: 'group_name',
    },
    {
        title: '成员名称',
        key: 'user_name',
        dataIndex: 'user_name',
    },
    {
        title: '参与的项目名称',
        key: 'project_name',
        dataIndex: 'project_name',
    },
    {
        title: '项目状态',
        key: 'project_status',
        dataIndex: 'project_status',
        render: (text) => {
            return <div
                className="amp-work-progress-project-type"
            >
                {text}
            </div>
        }
    },
    {
        title: '预计结项时间',
        key: 'project_end_time',
        dataIndex: 'project_end_time',
    }
]

const taskColumns = [
    {
        title: '部门名称',
        key: 'department_name',
        dataIndex: 'department_name',
    },
    {
        title: '小组名称',
        key: 'group_name',
        dataIndex: 'group_name',
    },
    {
        title: '成员名称',
        key: 'user_name',
        dataIndex: 'user_name',
    },
    {
        title: '预览图',
        key: 'preview_image',
        dataIndex: 'preview_image',
        render: (text) => <BasicImage height={60} width={60} src={text} />
    },
    {
        title: '资产ID',
        key: 'asset_id',
        dataIndex: 'asset_id',
    },
    {
        title: '资产名称',
        key: 'asset_name',
        dataIndex: 'asset_name',
        width: '12%'
    },
    {
        title: '任务状态',
        key: 'task_status',
        dataIndex: 'task_status',
        align: 'center',
        render: (text, record) => {
            return <div
                className="amp-work-progress-task-type"
                style={{ backgroundColor: record.task_status_color }}
            >
                {text}
            </div>
        }
    },
    {
        title: '已处理时间',
        key: 'handle_time',
        dataIndex: 'handle_time',
    },
]

/**
 * 工作实时进度统计
 * @returns {JSX.Element}
 */
function RealTimeWorkProgress(props) {

    return (
        <div className="amp-work-progress page-container px-5">
            {/* 总览 */}
            <Pandect />
            {/* 项目情况 */}
            <ProjectSituation
                columns={projectColumns}
                type="project"
                theme="项目"
            />
            {/* 任务情况 */}
            <ProjectSituation
                columns={taskColumns}
                type="task"
                theme="任务"
                pageSize={6}
            />
        </div>
    )
}

export default memo(RealTimeWorkProgress)
