import { Image } from 'antd'
import { useHistory } from 'react-router'
import { CopeText, $error, $success } from '@components'
import env from 'env';

/**
 * 选题池卡片
 * @param {*} props
 * @returns {JSX.Element}
 */
const ListItem = (props) => {

    const { data } = props
    const history = useHistory();

    /**
     * 详情弹框
     * @param {*} item item
     */
    const handleDetail = (item) => {
        const { location } = history;
        history?.push(`${location?.pathname}/topic_details?topicId=${item?.id}`)
    }

    /**
     * 禁止冒泡
     * @param {*} e event
     */
    const handelEvent = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="amp-list-item">
            {
                data.map(item => {
                    return (
                        <div className='amp-item' key={item.id} onClick={() => handleDetail(item)}>
                            <div className='amp-item-img'>
                                <Image
                                    fallback={env.placeImage}
                                    preview={false}
                                    className='amp-ant-image'
                                    src={item?.imagesList?.[0]?.fileUrl || ''}
                                />
                            </div>
                            <div className='amp-item-content' onClick={(e) => handelEvent(e)}>
                                <div className='amp-item-content-top d-flex'>
                                    <CopeText
                                        onError={() => $error('复制失败')}
                                        onCope={() => $success('复制成功')}
                                    >
                                        <div className='amp-item-title amp-line-clamp-1 cursor-pointer'>{item.name}</div>
                                    </CopeText>
                                    <div className='amp-item-tips'>
                                        <span className='amp-item-span-border amp-scene-bg-bd'>{item.outlineList?.name}</span>
                                        <span className='amp-item-span-border amp-theme-bg-bd amp-mg-l-8'>{item.sceneListList[0]?.name}</span>
                                    </div>
                                </div>
                                <div className='amp-item-content-middle d-flex cursor-pointer'>
                                    <CopeText
                                        onError={() => $error('复制失败')}
                                        onCope={() => $success('复制成功')}
                                    >
                                        <span className='amp-mg-r-8'>{item.number}</span>
                                    </CopeText>
                                    <CopeText
                                        onError={() => $error('复制失败')}
                                        onCope={() => $success('复制成功')}
                                    >
                                        <span className='amp-item-span-border cursor-pointer'>创建/编辑选题</span>
                                    </CopeText>
                                </div>
                                <CopeText
                                    onError={() => $error('复制失败')}
                                    onCope={() => $success('复制成功')}
                                >
                                    <div className='amp-item-content-bottom d-flex amp-line-clamp-2 cursor-pointer'>
                                        {item?.detail || '无'}
                                    </div>
                                </CopeText>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ListItem
