/**
 * @name: Server.js
 * @user: cfj
 * @date: 2022/4/12
 * @description:
 */
import env from 'env'
import { jsonToFormData } from '@/utils/global'
import { forceLogout } from '@components'
import { store } from '@/redux/store'

class Fetch {

  constructor (options) {
    const {
      timeout = 100000,
      headers
    } = options || {}
    this.timeout = timeout
    this.baseURL = env.serveUrls
    this.headers = {
      'Content-Type': 'application/json',
      ...headers
    }
  }

  timeoutPromise () {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('请求超时')), this.timeout)
    })
  }

  fetcher (url, init) {
    return fetch(url, init)
  }

  init (url, init) {
    const {
      headers,
      ...output
    } = init
    const _headers = Object.assign({}, this.headers, headers)
    const timeout = this.timeoutPromise()
    const urlObj = new URL(url, this.baseURL)
    const fetcher = this.fetcher(urlObj.href, {
      ...output,
      headers: _headers
    })
    return Promise.race([timeout, fetcher])
  }
}

export class Server {
  static get (url, params, options) {
    const server = new Server()
    const init = {
      ...options,
      method: 'GET'
    }
    if (params && typeof params === 'object') {
      let searchParams = new URLSearchParams()
      for (const key in params) {
        const value = params[key]
        if (value && Array.isArray(value)) {
          value.forEach(v => {
            if (v !== undefined && v !== null) {
              searchParams.append(key, v)
            }
          })
        } else {
          if (value !== undefined) {
            searchParams.append(key, value)
          }
        }
      }
      return server.request(url + '?' + searchParams.toString(), init)
    }
    return server.request(url, init)
  }

  static post (url, data, options = {}) {
    const {
      dataType,
      ...output
    } = options
    const server = new Server()
    const init = {
      ...output,
      method: 'POST'
    }
    if (data && typeof data === 'object' && dataType === 'formData') {
      init.body = jsonToFormData(data)
      return server.request(url, init)
    } else if (data && typeof data === 'object' && dataType !== 'formData') {
      init.body = JSON.stringify(data)
      return server.request(url, init)
    } else {
      return server.request(url, init)
    }

  }

  static put (url, data, options) {
    const server = new Server()
    const init = {
      ...options,
      method: 'PUT'
    }
    if (data && typeof data === 'object') {
      init.body = JSON.stringify(data)
      return server.request(url, init)
    } else {
      return server.request(url, init)
    }
  }

  requestInterceptors (init) {
    const token = localStorage.getItem('token')
    if (init.headers) {
      init.headers['x-token'] = token
    } else {
      init.headers = {
        'x-token': token
      }
    }
    return init
  }

  responseInterceptors (response) {
    const contentType = response.headers.get('content-type') || ''
    const status = response.status
    if (response.status >= 400) {
      let err
      if (response.status === 401) {
        store.dispatch({ type: 'LOGOUT_CURRENT_USER' })
        localStorage.removeItem('token')
        forceLogout()
      }
      if (contentType.includes('application/json')) {
        return response.json()
          .then(res => {
            err = new Error(res.msg || res.data?.msg)
            err.status = status
            throw err
          })
      } else if (contentType.includes('text/plain')) {
        return response.text()
          .then(text => {
            const code = 'desc = '
            const index = text.lastIndexOf(code)
            err = new Error(index > -1 ? text.slice(index + code.length) : text)
            err.status = status
            throw err
          })
      } else {
        err = new Error('请求错误')
        err.status = status
        throw err
      }
    } else {
      if (contentType.includes('application/json')) {
        return response.json()
          .then(res => {
            if (res.code === 1000) {
              return res.data
            } else {
              const err = new Error(res.msg || '请求错误')
              err.status = status
              throw err
            }
          })
      } else if (contentType.includes('text/plain')) {
        return response.text()
      } else if (contentType.includes('application/octet-stream')) {
        return response.blob().then(blob => {
          blob.name = response.headers.get('Content-Disposition')?.replace(/.*filename=(.*)$/ig,"$1")
          return blob
        })
      } else {
        return response

      }
    }
  }

  request (input, init) {
    const fetch = new Fetch(init)
    return fetch.init(input, this.requestInterceptors(init))
      .then(this.responseInterceptors)
  }
}

