import {usersAPI} from "@api";

import {
  REQUEST_USERS,
  RECEIVE_USERS,
  RECEIVE_USERS_ERR,
} from "@actions/action_consts";

const requestUsers = (params) => ({
  type: REQUEST_USERS,
  params,
});

const receiveUsers = (params, users) => ({
  type: RECEIVE_USERS,
  params,
  users,
});

const receiveUsersErr = (err) => ({
  type: RECEIVE_USERS_ERR,
  err,
});

const fetchUsers = (params, callback = () => {}) => (dispatch) => {
  dispatch(requestUsers(params));
  usersAPI.fetchUsers(params).then((res) => {
    if (res.code === 1000) {
      callback();
      return dispatch(receiveUsers(params, res.data));
    } else {
      callback();
      return dispatch(receiveUsersErr(res.msg));
    }
  });
};

export { requestUsers, receiveUsers, fetchUsers };
