/**
 * @fileoverview gRPC-Web generated client stub for data_depend_api
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.data_depend_api = require('./data_depend_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.data_depend_api.DataDependApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.data_depend_api.DataDependApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.data_depend_api.EmptyRequest,
 *   !proto.data_depend_api.StatusResponse>}
 */
const methodDescriptor_DataDependApiService_Status = new grpc.web.MethodDescriptor(
  '/data_depend_api.DataDependApiService/Status',
  grpc.web.MethodType.UNARY,
  proto.data_depend_api.EmptyRequest,
  proto.data_depend_api.StatusResponse,
  /**
   * @param {!proto.data_depend_api.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.data_depend_api.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.data_depend_api.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.data_depend_api.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.data_depend_api.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.data_depend_api.DataDependApiServiceClient.prototype.status =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/data_depend_api.DataDependApiService/Status',
      request,
      metadata || {},
      methodDescriptor_DataDependApiService_Status,
      callback);
};


/**
 * @param {!proto.data_depend_api.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.data_depend_api.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.data_depend_api.DataDependApiServicePromiseClient.prototype.status =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/data_depend_api.DataDependApiService/Status',
      request,
      metadata || {},
      methodDescriptor_DataDependApiService_Status);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.data_depend_api.EmptyRequest,
 *   !proto.data_depend_api.ListAssetTypesResponse>}
 */
const methodDescriptor_DataDependApiService_ListAssetType = new grpc.web.MethodDescriptor(
  '/data_depend_api.DataDependApiService/ListAssetType',
  grpc.web.MethodType.UNARY,
  proto.data_depend_api.EmptyRequest,
  proto.data_depend_api.ListAssetTypesResponse,
  /**
   * @param {!proto.data_depend_api.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.data_depend_api.ListAssetTypesResponse.deserializeBinary
);


/**
 * @param {!proto.data_depend_api.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.data_depend_api.ListAssetTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.data_depend_api.ListAssetTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.data_depend_api.DataDependApiServiceClient.prototype.listAssetType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/data_depend_api.DataDependApiService/ListAssetType',
      request,
      metadata || {},
      methodDescriptor_DataDependApiService_ListAssetType,
      callback);
};


/**
 * @param {!proto.data_depend_api.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.data_depend_api.ListAssetTypesResponse>}
 *     Promise that resolves to the response
 */
proto.data_depend_api.DataDependApiServicePromiseClient.prototype.listAssetType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/data_depend_api.DataDependApiService/ListAssetType',
      request,
      metadata || {},
      methodDescriptor_DataDependApiService_ListAssetType);
};


module.exports = proto.data_depend_api;

