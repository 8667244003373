import axios from 'axios'

const projectsAPI = {
    fetchProjects(params) {
        return axios.post('/project/list', params)
    },
    // ===============================================
    createProject(params) {
        return axios.post('/project/add', params)
    },

    updateProject(params) {
        return axios.post('/project/update', params)
    },
    removeProject(params) {
        return axios.post('/project/delete', params)
    },
    completeProject(params) {
        return axios.post('/project/complete', params)
    },

    fetchProjectInfo(id) {
        return axios.get(`/project/info?id=${id}`)
    },
    fetchProjectHistory(id) {
        return axios.get(`/project/history?id=${id}`)
    },
    getProjectDetail(id){
        return axios.get(`/task/info?id=${id}`)
    },
    getTaskPackageInfo(id){
        return axios.get(`/package/info?id=${id}`)
    },
    planAssociatedTask(id){
        return axios.get(`/project/usable-packages?id=${id}`)
    },
    associatedTask(params) {
        return axios.post("package-item/create",params)
   }
}

export default projectsAPI
