import React, { useEffect, useState, memo } from 'react'
import { CheckOutlined } from '@ant-design/icons';
import { standardColor } from "@vars/configs/standard_color";
import './index.less'

/**
 * 建议颜色组件，可选择颜色，回显颜色
 * @param {String | Array} value 值
 * @param {Function} onChange 修改颜色
 * @param {Boolean} disabled 是否禁用
 * @param {Boolean} preview 查看状态只显示已选择颜色
 * @param {Number} max 最大选择数
 * @param {String | Array} value 值
 * @returns ReactNode
 */
const RecommendedColor = (props) => {
    const {
        value,
        onChange,
        disabled,
        preview,
        max = 3
    } = props

    const [values, setvalues] = useState([])

    /**
     * 色块点击事件，通过最大选中数量控制反选
     * @param {object} params
     * @returns
     */
    const clickColor = (params) => {
        // 查看和禁用不执行
        if(preview || disabled) return
        if(values.includes(params.colorCode)){
            removeColor(params.colorCode)
        } else {
            addColor(params.colorCode)
        }
    }
    // 删除已选择颜色
    const removeColor = (code) => {
        let newColors = ''
        if(max === 1){
            newColors = ''
        }else{
            newColors = values.filter(item => item !== code)
        }
        onChange?.(newColors)
    }

    // 添加已选择颜色
    const addColor = (code) => {
        let newColors = [...values]
        if(max === 1){
            newColors = code
        }else if(max > values.length){
            newColors.push(code)
        }else if(max <= values.length){
            return
        }
        onChange?.(newColors)
    }

    useEffect(() => {
        const valuetype = Object.prototype.toString.call(value)
        if (valuetype === '[object Array]') {
            setvalues([...value])
        } else if (valuetype === '[object String]' && value != '') {
            setvalues([value])
        } else {
            setvalues([])
        }
    }, [value])

    // 只显示选中色块
    const previewColor = () => {
        const checkColors = standardColor.filter(item => values.includes(item.colorCode))
        return checkColors.map(item => {
            return (
                <div
                    key={item.colorCode}
                    className="amp-recommended-color-block"
                    style={{ backgroundColor: item.colorCode }}
                >
                </div>
            )
        })
    }

    // 所有色块列表
    const editColor = () => {
        const colors = standardColor.map(item => {
            const checked = values.includes(item.colorCode)
            const cursor = disabled ? 'amp-recommended-color-disabled' : 'amp-recommended-color-pointer'
            return (
                <div
                    key={item.colorCode}
                    className={`amp-recommended-color-block ${cursor}`}
                    style={{ backgroundColor: item.colorCode }}
                    onClick={() => clickColor(item)}
                >
                    {
                        checked && <CheckOutlined style={{color: item.checkSign}} />
                    }
                </div>
            )
        })
        return colors
    }

    return (
        <div className="amp-recommended-color">
            {
               preview ? previewColor() : editColor()
            }
        </div>
    )
}

export default memo(RecommendedColor)
