import { TaskItem } from "./components/task_item";
import { Spin } from "antd";
import "./index.less";
import "@/stylesheets/components/flex_basics.less";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NoData } from "./components/no_data";
import { observer } from "mobx-react";
import { Tooltip } from 'antd';

// 滑动区域高度
const ContainerHeight = 718;
/**
 * 任务包列表
 * @returns
 */
export const LeftTaskList = observer((props) => {
    const {
        todosStore,
        taskPackageStore,
        form,
        taskPackageStore: {
            packageList: packages,
            planNum,
            statusTotal: statusList,
            page,
            pageSize,
            setTaskId,
            taskId,
            isPackageModal,
            setCurrentTasks,
            setShowEditCreate,
            updatePlanningPage,
            setPlanTaskOutListRefresh,
            setPlanTaskOutLoading
        },
    } = props;
    let projectId = todosStore.projectId || null;
    const listRef = useRef();
    const [scrollTop, setScrollTop] = useState(0);
    const [loading, setLoading] = useState(true)

    // 请求列表接口
    const getTaskList = useCallback(
        (projectId, pageNum) => {
            if (pageNum === 1) {
                setLoading(true)
                // 清除之前的数据
                taskPackageStore.setPackageList([]);
            }
            taskPackageStore.getPackageList({
                page: pageNum,
                page_size: pageSize,
                project_id: parseInt(projectId),
            }).then((res) => {
                if(res?.code === 1000) {
                    setLoading(false)
                }
            });
        },
        [taskPackageStore, pageSize]
    );

    // 获取列表数据
    useEffect(() => {
        if (projectId && isPackageModal) {
            getTaskList(projectId, 1);
        }
    }, [projectId, isPackageModal, getTaskList]);

    // 渲染包
    const renderPackages = (values) => {
        return values.map((item) => (
            <div key={item.id}>
                <TaskItem
                    item={item}
                    onClickItem={onClickItem}
                    selectId={taskId}
                ></TaskItem>
            </div>
        ));
    };

    // 点击方法
    const onClickItem = (item) => {
        props.onSelectChange()
        setPlanTaskOutLoading(false)
        setPlanTaskOutListRefresh("change")
        updatePlanningPage(1)
        setTaskId(item.id);
        setCurrentTasks(item);
        setShowEditCreate(false);
        props.taskPlanStore.toggleVisible(false);
        props.taskPackageStore.getPlanTaskOutList()
        props.taskPackageStore.getPackageHistory()
        // form.resetFields();
    };

    // 滑动方法
    const onScroll = useCallback(
        (e) => {
            setScrollTop(e.target.scrollTop);
            if (e.target.scrollHeight - e.target.scrollTop === ContainerHeight) {
                getTaskList(projectId, page);
            }
        },
        [page, projectId, getTaskList]
    );

    // 监听高度
    useEffect(() => {
        if (listRef && listRef.current) {
            listRef.current.addEventListener("scroll", onScroll);
        }
        return () => {
            if (listRef && listRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                listRef.current.removeEventListener("scroll", onScroll);
            }
        };
    }, [onScroll]);

    // 显示创建任务面板
    const showCreateTask = () => {
        props.onSelectChange()
        setShowEditCreate(false)
        setTaskId(null);
        setCurrentTasks(null);

    };

    return (
        <div className="amps-task-list flex-justify-center">
            <div className="list-body">
                <div
                    className={`list-state  ${scrollTop > 0 && "state-shadow"
                        } flex-align-center flex-space-between `}
                >
                    <div className="state-plan">策划 {planNum}</div>
                    <div className="state-number flex">
                        {statusList &&
                            statusList.map((item, index) => (
                                <Tooltip title={`${item.label} ${item.count}`} key={index}>
                                    <div className="number-body flex-center">
                                        <div
                                            className="body-circle"
                                            style={{ background: item.color }}
                                        ></div>
                                        <div className="body-number">{item.count}</div>
                                    </div>
                                </Tooltip>
                            ))}
                    </div>
                </div>
                <div className="list-bottom flex-justify-center">
                    <div className="list-bottom-body" ref={listRef}>
                        {
                            packages.length> 0 && (
                                <div className="list-create flex-center" onClick={showCreateTask}>
                                    + 任务包
                                </div>
                            )
                        }
                        {
                            loading ? (
                                <div
                                    style={{ height: "500px" }}
                                    className="display-flex justify-center align-center"
                                >
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div className="list-tasks">
                                    {packages && packages.length > 0 ? (
                                        renderPackages(packages)
                                    ) : (
                                        <NoData />
                                    )}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});
