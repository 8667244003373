import React, { useState } from 'react'
import { Form, Input, Select, Button } from 'antd';
import { getVoidStatusOptions } from '@gUtils/getVoidStatusOptions';
import { $error, RangePicker } from '@components';
import moment from 'moment'

/**
 * 作废时间维度 表单查询
 */
const SearchForm = (props) => {
    const { onChange, discardTime } = props
    const [form] = Form.useForm()
    const [dates, setDates] = useState([])

    const resetFields = () => {
        form.resetFields();
        onChange?.(form.getFieldsValue());
    }

    // 作废查询不可选日期 可选范围6个月 最大可选当天
    const disabledDate = (current) => {
        if(current > moment(new Date())){
            return true
        }
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'months') > 5;
        const tooEarly = dates[1] && dates[1].diff(current, 'months') > 5;
        return tooEarly || tooLate;
    }

    return (

        <Form
            className='amp-destroy-chart-from'
            form={form}
            initialValues={{
                discard_time: discardTime
            }}
            onFinish={(values) => onChange?.(values)}
        >
            <div
                className="amp-destroy-chart-from-title"
            >
                数据范围
            </div>
            <Form.Item
                name="discard_type"
            >
                <Select
                    size="large"
                    placeholder="请选择作废类型"
                    className="select-sz-m amp-mr-10"
                    allowClear
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    showSearch
                    optionFilterProp="children"
                    options={getVoidStatusOptions().map((i) => ({
                        label: i.label,
                        value: i.code
                    }))}
                >
                </Select>
            </Form.Item>

            <Form.Item
                name={'discard_time'}
            >
                <RangePicker
                    className="select-sz-l amp-mr-30"
                    disabledDate={disabledDate}
                    onCalendarChange={val => setDates(val)}
                    allowClear={false}
                />
            </Form.Item>

            <div
                className="amp-destroy-chart-from-title"
            >
                项目名称
            </div>

            <Form.Item
                name={'project_name'}
            >
                <Input
                    maxLength={50}
                    placeholder={'请输入项目名称'}
                    className="select-sz-m amp-mr-30"
                />
            </Form.Item>

            <div className="mb-3">
                <Button
                    loading={false}
                    className="btn-sz-s amp-mr-10"
                    type="primary"
                    onClick={form.submit}>
                    查询
                </Button>
                <Button
                    className="btn-sz-s amp-mr-10"
                    onClick={resetFields}
                >
                    重置
                </Button>
            </div>

        </Form>
    )
}

export default SearchForm
