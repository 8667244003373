import axios from 'axios'

const accountAPI = {
  resetDefaultPassword(params) {
    return axios.put("/account/change-default-password", params);
  },
  changePassword(params) {
    return axios.put("/account/change-password", params);
  },
  fetchProfile() {
    return axios.get("/account/info");
  },
  accountUpdate(params) {
    return axios.put("/account/update", params);
  },
  sendCode() {
    return axios.post("/account/send-email-code");
  },
  validateCode(params) {
    return axios.put("/account/validate-code", params);
  },
  fetchPermissionDetails() {
    return axios.get("/account/permission-infos");
  },
  fetchNotifications(params) {
    return axios.post("/account/announcements", params);
  },
  fetchProjectActivities(params) {
    return axios.post("/account/project-histories", params);
  },
};

export default accountAPI