import React from 'react';
import { clsx } from '@gUtils';
import PreviewImage from '@components/image/preview_image';
import { LazyLoadingImage } from '@components';
import { sessionAPI } from "@api";

export default function TaskImages(props) {
    const { shoot_images, analyse_images, produce_images } = props;
    const images = [shoot_images, analyse_images, produce_images]
    const onPreview = (index) => {
        const filterImage = images.filter(i => i.length > 0);
        const current = ([].concat(...filterImage.slice(0, index))).length;
        PreviewImage.open({
            images: [].concat(...filterImage),
            current: current,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };

    return (
        <div className="task-card-img">
            {images.map((urls, i) => <div key={i} className={'task-list-item-images-col'}>
                <div className={'task-list-item-images-col-box'} style={{marginRight:"6px"}}>
                    {
                        urls.length > 0 && (
                            <LazyLoadingImage
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onPreview(i)
                                }}
                                width={88}
                                height={88}
                                alt={i}
                                src={urls[0]}
                                preview={{
                                    visible: false
                                }}
                                className={clsx('task-list-item-images-col-box-image')}
                            />
                        )
                    }
                </div>
            </div>)}
        </div>
    );
}

