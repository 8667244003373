import React, { useState, useEffect } from 'react';
import './index.less';
import { LazyLoadingImage } from '@components';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';
import { getTime } from '@/utils/global/getTime';
import { render } from 'react-dom';
import { Tooltip } from 'antd';
import env from 'env';
import RemoveAnnotation from '../remove_annotation';

/**
 * 渲染图片组件
 * @param {*} list 图片的数组
 * @returns {*}
 */
const renderImage = (list) => {
    const renderList = [];
    list?.map((item) => {
        renderList.push(item?.fileUrl);
    });
    const temp = [];
    const onPreview = (index) => {
        const filterImage = renderList.filter((i) => i.length > 0);
        const current = [].concat(...filterImage.slice(0, index)).length;
        PreviewImage.open({
            images: [].concat(...filterImage),
            current: current,
            request: (url) => sessionAPI.fetchOriginalImage(url).then((res) => res.data?.origin_urls?.[0] || '')
        });
    };
    renderList?.map((item, idx) => {
        temp.push(
            <div className="amp-annotation-item-image-render" key={idx}>
                <LazyLoadingImage
                    onClick={(event) => {
                        event.stopPropagation();
                        onPreview(idx);
                    }}
                    // height={100}
                    src={item || env.placeImage}
                    fallback={env.placeImage}
                    preview={{
                        visible: false
                    }}
                />
            </div>
        );
    });
    return temp;
};

/**
 * 单个批注详情
 * @param {*} props props
 * @returns {*}
 */
const AnnotationItem = (props) => {
    const { userName, businessStatusName, comment, imagesList, createdAt, deleteAnnotation, id, isDelete } = props;
    const [removeModal, setRemoveModal] = useState(false);
    const imageList = renderImage(imagesList);
    // const [imageList, setImageList] = useState(renderImage(imagesList));
    const timeObj = getTime(createdAt);
    // useEffect(() => {
    //     setImageList(renderImage(imagesList))
    // }, [sceneId]);
    return (
        <div className="amps-annotation-item">
            <div className="amp-annotation-item-header">
                <div className="amp-annotation-item-header-name">{userName}</div>
                <div className="amp-annotation-item-header-time">{`${timeObj?.y}.${timeObj?.m}.${timeObj.d} ${timeObj?.h}:${timeObj?.f}:${timeObj.s}`}</div>
                <div className="amp-annotation-item-header-operation">
                    <span className="amp-annotation-item-title">{businessStatusName}</span>
                    {isDelete ? (
                        <div className="amp-annotation-item-operate">
                            <span className="amp-annotation-item-operate-icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.4375 9.98047C3.4375 10.1241 3.46579 10.2663 3.52076 10.399C3.57572 10.5317 3.65629 10.6523 3.75785 10.7539C3.85942 10.8554 3.97999 10.936 4.11269 10.991C4.24539 11.0459 4.38762 11.0742 4.53125 11.0742C4.67488 11.0742 4.81711 11.0459 4.94981 10.991C5.08251 10.936 5.20308 10.8554 5.30465 10.7539C5.40621 10.6523 5.48678 10.5317 5.54174 10.399C5.59671 10.2663 5.625 10.1241 5.625 9.98047C5.625 9.83684 5.59671 9.69461 5.54174 9.56191C5.48678 9.42921 5.40621 9.30863 5.30465 9.20707C5.20308 9.10551 5.08251 9.02494 4.94981 8.96998C4.81711 8.91501 4.67488 8.88672 4.53125 8.88672C4.38762 8.88672 4.24539 8.91501 4.11269 8.96998C3.97999 9.02494 3.85942 9.10551 3.75785 9.20707C3.65629 9.30863 3.57572 9.42921 3.52076 9.56191C3.46579 9.69461 3.4375 9.83684 3.4375 9.98047V9.98047ZM8.90625 9.98047C8.90625 10.2705 9.02148 10.5487 9.2266 10.7539C9.43172 10.959 9.70992 11.0742 10 11.0742C10.2901 11.0742 10.5683 10.959 10.7734 10.7539C10.9785 10.5487 11.0938 10.2705 11.0938 9.98047C11.0938 9.69039 10.9785 9.41219 10.7734 9.20707C10.5683 9.00195 10.2901 8.88672 10 8.88672C9.70992 8.88672 9.43172 9.00195 9.2266 9.20707C9.02148 9.41219 8.90625 9.69039 8.90625 9.98047V9.98047ZM14.375 9.98047C14.375 10.2705 14.4902 10.5487 14.6954 10.7539C14.9005 10.959 15.1787 11.0742 15.4688 11.0742C15.7588 11.0742 16.037 10.959 16.2421 10.7539C16.4473 10.5487 16.5625 10.2705 16.5625 9.98047C16.5625 9.69039 16.4473 9.41219 16.2421 9.20707C16.037 9.00195 15.7588 8.88672 15.4688 8.88672C15.1787 8.88672 14.9005 9.00195 14.6954 9.20707C14.4902 9.41219 14.375 9.69039 14.375 9.98047V9.98047Z"
                                        fill="black"
                                    />
                                </svg>
                            </span>
                            <div className="amp-annotation-item-operate-list">
                                <div
                                    className="amp-annotation-delete"
                                    onClick={() => {
                                        setRemoveModal(true);
                                    }}>
                                    删除
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="amp-annotation-item-comment">{comment}</div>
            { imageList?.length ? <div className="amp-annotation-item-image">{imageList}</div> : null}
            <RemoveAnnotation removeModal={removeModal} setRemoveModal={setRemoveModal} id={id} deleteAnnotation={deleteAnnotation} />
        </div>
    );
};

export default AnnotationItem;
