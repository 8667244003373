import { connect } from "react-redux";
import { Table } from "@/components";
import { fetchRoles, fetchRoleDetails } from "@actions/1_entities/role_actions";
import { roleColumns } from "@vars/views/settings_role_vars";
import { Space } from "antd";
import { Iconfont, AccessControl } from "@/components";

const RoleTable = (props) => {
  const rowClick = (record, index) => {
    // history.pushState('') 表格里跳转
  };
  const delShow = (id) => {
    props.delShow(id);
  };

  const editShow = (id) => {
    props.editShow(id);
  };
  const action = {
    title: "操作",
    render: (text, record) => (
      <>
        <AccessControl permission="role_update">
          <span onClick={() => editShow(record.id)}>
            <Iconfont type="icon-edit-item" className="icon-20 cursor-pointer mg-r-32" />
          </span>
        </AccessControl>
        <AccessControl permission="role_delete">
          <span onClick={() => delShow(record)} className="cursor">
            <Iconfont
              type="icon-trash"
              className="icon-20"
            />
          </span>
        </AccessControl>
        </>
    ),
  };
  const columnsConfig = [...roleColumns, action];
  return (
    <Table
      rowClick={rowClick}
      columnsConfig={columnsConfig}
      permission="role_list"
      rowClassName="table-cursor-default"
      rowClickPermission={["role_update", "role_delete"]}
      {...props}
    ></Table>
  );
};

const mapStateToProps = (state) => ({
  entity: state.entities.roles,
  currentPage: state.pagination.roles.currentPage,
  total: state.pagination.roles.total,
  pages: state.pagination.roles.pages,
  filter: state.pagination.roles.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (params) => dispatch(fetchRoles(params)),
  fetchRoleDetails: (params, cb) => dispatch(fetchRoleDetails(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleTable);
