import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { TaskBoard } from './task_board';
import { emptyProject } from '@vars/views/projects_vars';
import { ShootingPlanDrawer, TaskDrawer, TaskGroupsDrawer } from '@components';
import { inject, observer } from 'mobx-react';
import TaskPackage from './task_package';
import TaskRoute from '@views/taskPackage/TaskRoute';

@inject("projectStore")
@observer
class TaskIndex extends Component {
    /**
     * 获取projectStore
     */
    get store() {
        return this.props.projectStore;
    }
    state = {
        currentProject: emptyProject,
    };
    get projectId() {
        return JSON.parse(this.props.match.params.id);
    }

    componentDidMount() {
        // 获取并重置redux任务配置
        this.props.fetchTaskConfig();
        // 获取当前项目详情
        this.getProject();
        // 获取用户信息
        this.store.fetchProfile();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getProject();
        }
    }

    getProject = () => {
        this.props.fetchProject(this.projectId).then((res) => {
            if (res && res.id) {
                const currentProject = res;
                this.setState({ currentProject });
            } else {
                this.props.history.push("/projects");
            }
        });
    };

    render() {
        const { currentProject } = this.state;
        return this.projectId ? (
            <>
                <ShootingPlanDrawer updateProject={this.getProject} />
                <TaskGroupsDrawer />
                <TaskDrawer />
                <TaskBoard
                    projectId={this.projectId}
                    {...this.props}
                    currentProject={currentProject}
                />
                <TaskPackage {...this.props} />
                <TaskRoute />
            </>
        ) : (
            <Redirect to="/projects" />
        );
    }
}

export default withRouter(TaskIndex);
