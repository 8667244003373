import React, { useState, useEffect } from 'react';
import { ProfileAvatar } from '@/components';
import { getTime } from '@/utils/global/getTime';
import './index.less';
import { constants } from 'zlib';

/**
 * renderAvatar，渲染创建人
 * @param {*} props props
 */
const renderAvatar = (props) => {
    const temp = [];
    [props]?.map((item, idx) => {
        temp.push(
            <div key={idx} className="amp-info-avatar">
                <span className="amp-info-avatar-details">
                    <ProfileAvatar {...item} />
                </span>
                <span className="amp-info-avatar-details">{item?.name}</span>
            </div>
        );
    });
    return temp;
};

/**
 * renderScene，渲染地址
 * @param {*} props props
 */
const renderAddress = (props) => {
    const temp = [];
    props?.map((item, idx) => {
        temp.push(
            <div key={idx + 1} className="amp-address-item">
                <span className="amp-address-item-icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11 2.75C7.581 2.75 4.8125 5.51736 4.8125 8.92808C4.8125 9.63608 4.93274 10.3164 5.15163 10.9505V10.9597L5.16087 10.9782C5.2657 11.2737 5.3921 11.56 5.537 11.834L5.54008 11.8401L5.54316 11.8463C5.60174 11.954 5.6634 12.0618 5.72814 12.1664L5.7343 12.1757L5.73739 12.1818L9.83464 19.268C10.3526 20.1607 11.6443 20.1607 12.1592 19.268L16.2749 12.1603L16.2934 12.1326L16.426 11.9017C16.4322 11.8925 16.4383 11.8832 16.4414 11.874L16.4445 11.8647L16.4476 11.8586C16.5987 11.5754 16.7312 11.2799 16.8391 10.9751L16.8484 10.9567V10.9505C17.0673 10.3164 17.1875 9.63608 17.1875 8.92808C17.1875 5.51736 14.4159 2.75 11 2.75ZM11 11.6739C9.37528 11.6739 8.06194 10.3595 8.06194 8.74031C8.06194 7.12114 9.37836 5.80672 11 5.80672C12.6216 5.80672 13.9381 7.12114 13.9381 8.74031C13.9381 10.3595 12.6216 11.6739 11 11.6739Z"
                            fill="#595959"
                        />
                    </svg>
                </span>
                <span className="amp-address-item-info">
                    <span className="amp-bolder amp-mg-r-4">{item?.name}</span>
                    <span>{item?.district + item?.address}</span>
                </span>
            </div>
        );
    });
    return temp;
};

/**
 * 场景详情信息包含：描述/创建时间/创建人/地址
 * @param {*} props props
 * @returns
 */
const RenderItem = (props) => {
    const { comment, createdAt, planUserList, addressListList } = props;
    const [users, setUsers] = useState(renderAvatar(planUserList)); // 创建人
    const [address, setAddress] = useState(renderAddress(addressListList)); // 地址
    const [timeObj, setTimerObj] = useState(getTime(Number(createdAt))); // 创建时间
    useEffect(() => {
        setUsers(renderAvatar(planUserList));
        setAddress(renderAddress(addressListList));
        setTimerObj(getTime(Number(createdAt)));
    }, [planUserList, addressListList, createdAt]);
    return (
        <div className="amp-scene-description-details-render">
            <div className="amp-descript">
                <div className="amp-label">场景描述</div>
                <div className="amp-content">{comment || '无'}</div>
            </div>
            <div className="amp-createInfo">
                <div className="amp-createTime">
                    <div className="amp-label">创建时间</div>
                    <div className="amp-content">
                        <span className="amp-mg-r-8">{`${timeObj?.y}-${timeObj?.m}-${timeObj?.d}`}</span>
                        <span>{`${timeObj?.h}:${timeObj?.f}:${timeObj?.s}`}</span>
                    </div>
                </div>
                <div className="amp-createUser">
                    <div className="amp-label">策划人员</div>
                    <div className="amp-content">{users}</div>
                </div>
            </div>
            <div className="amp-sceneAddress">
                <div className="amp-label">地址</div>
                <div className="amp-content">
                    <div className="amp-address">{address}</div>
                </div>
            </div>
        </div>
    );
};

export default RenderItem;
