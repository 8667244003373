import { connect } from "react-redux";
import {
  createProject,
  updateProject,
  removeProject,
  completeProject,
  fetchProject
} from "@actions/1_entities/project_actions";
import TaskIndex from "./task_index";

import { fetchTaskConfig } from "@actions/2_configs/configs_actions";
import {
  createTask,
  updateTask,
  fetchTaskInfo,
  fetchTaskHistory,
  taskDiscard,
  remake,
  rollback,
} from "@actions/1_entities/task_actions";

const mapStateToProps = (state) => ({
  projects: state.entities.projects,
  configs: state.configs,
  users: state.entities.users,
  listTypes: state.configs.task_list_type,
});
const mapDispatchToProps = (dispatch) => ({
  fetchProject: (params, cb) => dispatch(fetchProject(params, cb)),
  createProject: (params, cb) => dispatch(createProject(params, cb)),
  updateProject: (params, cb) => dispatch(updateProject(params, cb)),
  removeProject: (params, cb) => dispatch(removeProject(params, cb)),
  completeProject: (id, cb) => dispatch(completeProject({id}, cb)),
  fetchTaskConfig: (cb) => dispatch(fetchTaskConfig(cb)),
  createTask: (params, cb) => dispatch(createTask(params, cb)),
  updateTask: (params, cb) => dispatch(updateTask(params, cb)),
  fetchTaskInfo: (id, cb) => dispatch(fetchTaskInfo(id, cb)),
  fetchTaskHistory: (id, cb) => dispatch(fetchTaskHistory(id, cb)),
  // 作废
  taskDiscard: (params, cb) => dispatch(taskDiscard(params, cb)),
  // 重制
  remake: (params, cb) => dispatch(remake(params, cb)),
  // 回退
  rollback: (params, cb) => dispatch(rollback(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskIndex);
