/**
 * @name: 404.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 404页面
 */
import { Typography } from '@/components/atoms'
import React from 'react'

export const Error404 = function(props){
    return <div className="empty-img-text" {...props}>
        <img
            src={process.env.PUBLIC_URL + "/images/other/pending.png"}
            alt="function-pending"
        />
        <Typography visible={'h3'} align={'center'}>此功能暂未开放，敬请期待!</Typography>
    </div>
}

