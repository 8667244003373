import React, { memo, useCallback } from 'react';
import env from 'env';
import { Image } from 'antd';
import { CopeText, $error, $success } from '@components';
import { useHistory } from 'react-router-dom';

interface ISceneItemProps {
    data: any;
}
/**
 * 选题列表单行item
 * @param {ISceneItemProps} props props
 * @returns {React.ReactNode}
 */
const SceneItem: React.FC<ISceneItemProps> = (props) => {
    const { data } = props;
    const history = useHistory();

    const jumpSceneDetail = useCallback((id) => {
        const { location } = history;
        history?.push(`${location?.pathname}/scene-details?sceneId=${id}`);
    }, [history]);

    return (
        <div className="amp-scene-wrap-line">
            {Array.isArray(data) && data.map((item: any) => (
                <div
                    className="amp-scene-wrap-line-item"
                    key={item.id}
                >
                    <div className="amp-scene-wrap-line-item-wrap">
                        <div className="amp-scene-wrap-line-item-wrap-pic" onClick={() => jumpSceneDetail(item.id)}>
                            <Image
                                fallback={env.placeImage}
                                preview={false}
                                className="amp-scene-item-image"
                                src={item?.imagesList?.[0]?.fileUrl || ''}
                            />
                        </div>
                        <div className="amp-scene-wrap-line-item-content">
                            <CopeText
                                onError={() => $error('复制失败')}
                                onCope={() => $success('复制成功')}
                            >
                                <h5 className="amp-scene-item-content-name">{item.name}</h5>
                            </CopeText>
                            <div className="display-flex">
                                <CopeText
                                    onError={() => $error('复制失败')}
                                    onCope={() => $success('复制成功')}
                                >
                                    <span className="amp-scene-item-content-number">{item.number}</span>
                                </CopeText>

                                <CopeText
                                    onError={() => $error('复制失败')}
                                    onCope={() => $success('复制成功')}
                                >
                                    <span className="amp-scene-item-content-tag">{item.statusName}</span>
                                </CopeText>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default memo(SceneItem);
