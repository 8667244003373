import { observable, action, flow, makeAutoObservable, computed } from 'mobx';
import { taskGroupsAPI } from '@api';
import { $success, $error } from '@components';

class TaskGroupsStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable project_id;
    @observable visible = false;
    @observable allAssets = [];
    @observable ungroupedAssets = [];
    @observable searchWord = '';
    @observable groups = [];
    @observable actionState = '';
    @observable flag = true;

    @computed get groupedIds() {
        let allTasks = this.groups?.map((item) => item.tasks).flat();
        let allIds = allTasks?.map((item) => item.id).filter((item) => !!item);
        return Array.from(new Set(allIds));
    }

    @computed get ungroupedDisplay() {
        let displayAssets = this.allAssets.filter((item) => !this.groupedIds.includes(item.id));
        if (this.searchWord) {
            return displayAssets.filter((item) => item.task_search.includes(this.searchWord));
        } else {
            return displayAssets;
        }
    }

    @action initializeAllAssets() {
        const assets = this.groups?.map((item) => item.tasks).flat();
        this.allAssets = [...assets, ...this.ungroupedAssets];
    }

    @action clear() {
        this.groups = [];
        this.actionState = '';
    }
    @action show() {
        this.visible = true;
    }
    @action hide() {
        this.visible = false;
    }
    @action updateProjectId(project_id) {
        this.project_id = project_id;
    }
    @action updateGroups(groups) {
        this.groups = groups;
    }
    @action updateGroupName(idx, group_name) {
        this.groups[idx].group_name = group_name;
    }

    @action updateGroupTasks(idx, tasks) {
        if (tasks[0]?.id === 0) {
            this.groups[idx].tasks = tasks;
        } else {
            this.groups[idx].tasks = [{ id: 0 }, ...tasks];
        }
    }

    @action removeGroup(idx) {
        this.groups.splice(idx, 1);
    }
    @action updateSearchWord(val) {
        this.searchWord = val;
    }
    @action addNewGroup() {
        this.groups.unshift({
            group_name: '',
            tasks: [{ id: 0 }],
            id: this.groups.length
        });
    }

    tasksDataFilter = (data) => {
        if (Object.prototype.toString.call(data) !== '[object Array]') return data;
        return data.map((task) => {
            return {
                ...task,
                id: task.id ? task.id : task.item_id,
                asset_uid: task.asset_uid ? task.asset_uid : task.item_search,
                task_image: task.task_image ? task.task_image : task.item_image,
                task_search: task.task_search ? task.task_search : task.item_search
            };
        });
    };

    fetchTaskGroups = flow(function* (type = '1') {
        this.flag = true;
        const params = { project_id: this.project_id, type: Number(type) };
        try {
            const res = yield taskGroupsAPI.fetchTaskGroups(params);
            if (res.code === 1000) {
                let data = res.data ?? [];
                if (type == '2') {
                    data = data.map((item) => {
                        item.tasks = this.tasksDataFilter(item.tasks);
                        return item;
                    });
                }
                this.groups = data;
                this.initializeAllAssets();
                this.flag = false;
            } else {
                this.groups = [];
            }
        } catch (error) {}
    });

    fetchUngroupedAssets = flow(function* (type = '1') {
        const params = { project_id: this.project_id, type: Number(type) };
        try {
            const res = yield taskGroupsAPI.fetchUngroupedAssets(params);
            if (res.code === 1000) {
                let data = res.data ?? [];
                if (type == '2') {
                    data = this.tasksDataFilter(data);
                }
                this.ungroupedAssets = data;
                this.initializeAllAssets();
            } else {
                this.ungroupedAssets = [];
            }
        } catch (error) {}
    });

    updateGroupsRequest = flow(function* (type = '1') {
        let groups = this.groups?.map((item) => {
            let { group_name, tasks } = item;
            if (type == '2') {
                //组合任务
                const item_ids = tasks?.map((item) => item.item_id).filter((item) => !!item);
                return { group_name, item_ids };
            }
            //组合资产
            const task_ids = tasks?.map((item) => item.task_id).filter((item) => !!item);
            return { group_name, task_ids };
        });
        const params = { project_id: this.project_id, groups, type: Number(type) };
        this.actionState = 'pending';
        try {
            const res = yield taskGroupsAPI.updateTaskGroups(params);
            if (res.code === 1000) {
                $success(res.msg);
                this.updateGroups(res.data);
                this.hide();
                this.actionState = 'done';
            } else {
                if (res.code !== 1002) $error(res.msg);
                this.actionState = 'error';
            }
        } catch (error) {}
    });
}

export default new TaskGroupsStore();
