import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Col, DatePicker, Row, Select } from 'antd'
import { Chart, RankTable, StatsSummary } from '../0_components/index'
import { withRouter } from 'react-router'
import moment from 'moment'

const { Option } = Select;

@withRouter
@inject("reportStore")
@observer
class ArtReport extends React.Component {
    get store() {
        return this.props.reportStore;
    }
    get statType() {
        const { pathname } = this.props.location;
        return pathname.split("/")[2];
    }
    componentDidMount() {
        this.checkReportType();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.checkReportType();
        }
    }
    updateDate = () => [this.store.updateDate(moment)];
    checkReportType = () => {
        const { pathname } = this.props.location;
        const type = pathname.split("/")[2];
        this.store.updateStatType(type);
        if (type === "production") {
            this.store.updateDateType(2);
            this.store.updateDate(moment().subtract(1, "week"));
        } else {
            this.store.updateDateType(1);
            this.store.updateDate(moment().subtract(1, "months"));
        }
    };

    changePickerType = (val) => {
        this.store.updateDateType(val);
        if (val === 1) {
            this.store.updateDate(moment().subtract(1, "months"));
        } else {
            this.store.updateDate(moment().subtract(1, "week"));
        }
    };

    disabledDate = (current) => {
        return (
            (this.store.dateType === 1
                ? current >= moment().startOf("month")
                : current >= moment().subtract(1, "weeks").endOf('week')) ||
            current < moment("2021-07-19")
        );
    };

    renderPickerType = () => {
        return (
            <Select
                size="large"
                className="filter-input mg-r-10"
                value={this.store.dateType}
                onChange={this.changePickerType}
            >
                <Option value={2}>周</Option>
                <Option value={1}>月</Option>
            </Select>
        );
    };

    renderFilter = () => {
        const isProduction = this.statType === "production";
        return (
            <div className="mg-b-40">
                <span className="bold mg-r-10">统计时间</span>
                {isProduction ? this.renderPickerType() : ""}
                <DatePicker
                    picker={isProduction && this.store.dateType === 2 ? "week" : "month"}
                    className="filter-input"
                    value={this.store.date}
                    onChange={(date) => this.store.updateDate(date)}
                    disabledDate={this.disabledDate}
                />
                <div className="reset-filter" onClick={() => this.store.clearDate()}>
                    <span>重置</span>
                </div>
            </div>
        );
    };
    render() {
        const { report, date, dateType } = this.store;
        const { stats, user_stats } = report;
        const isArt = this.statType === "art";
        const isProduction = this.statType === "production";
        const labels = isArt
            ? ["拍摄任务总数", "拍摄美术总分"]
            : isProduction && ["制作总数", "难度总数"];
        const filter = isArt
            ? { date: [date, date] }
            : isProduction && { date: [date, date], dateType };
        const url = (type) =>
            `/stats/${this.statType}/${this.statType
            }-summary/${type}?filter=${JSON.stringify(filter)}`;

        return (
            <div className="mg-t-20 page-container">
                {this.renderFilter()}
                <div className="mg-b-40">
                    <StatsSummary type={this.statType} stats={stats} url={url("all")} />
                </div>
                <Row justify="space-between" gutter={[16, 24]}>
                    <Col span={12}>
                        <RankTable type={this.statType} stats={user_stats} />
                    </Col>
                    <Col span={12}>
                        <Chart
                            user_stats={user_stats}
                            labels={labels}
                            title={isArt ? "个人拍摄情况" : isProduction && "个人制作情况"}
                            url={url("user")}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default ArtReport;
