import React, { Component } from 'react';
import { Image } from 'antd';
import env from 'env';
import { sessionAPI } from '@api';
import { inject, observer } from 'mobx-react';

@inject('taskPackageStore')
@observer
class BasicImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            previewSrc: '',
            imgList: [],
            visible: false
        };
    }

    get store() {
        return this.props.taskPackageStore;
    }

    visibleChangeHandle = () => {
        const {
            src,
            multipleImage,
            source
        } = this.props;
        let res = '';
        const len = source?.length;
        source?.map((item, index) => {
            if (len === 1) {
                res = item;
            } else {
                if (index < 1) {
                    res = item;
                } else {
                    res = res + '&images=' + item;
                }
            }
        });
        multipleImage ? (
            sessionAPI.fetchOriginalImage(res)
                .then((res) => {
                    const { origin_urls } = res.data;
                    this.setState({ imgList: res.data.origin_urls });
                    this.store.setSlideImage(origin_urls);
                })
        ) : (
            sessionAPI.fetchOriginalImage(src)
                .then((res) => {
                    const { origin_urls } = res.data;

                    this.setState({ imgList: res.data });
                    this.setState(() => {
                        return {
                            previewSrc: origin_urls[0]
                        };
                    });
                })
        );
    };

    render() {
        const {
            width,
            height,
            src,
            onClick,
            preview,
            zhizuotu
        } = this.props;
        const {
            previewSrc,
            visible,
            imgList
        } = this.state;

        return (
            <>
                <Image
                    width={width}
                    height={height}
                    src={src}
                    onClick={onClick}
                    fallback={env.placeImage}
                    preview={{
                        ...preview,
                        src: previewSrc,
                        visible: this.props.visible,
                        onVisibleChange: (visible) => {
                            this.setState({ visible: visible });
                            if (visible && !previewSrc) {
                                this.visibleChangeHandle();
                            }
                        }
                    }}
                />

                {
                    zhizuotu && (
                        <div style={{ display: 'none' }}>
                            <Image.PreviewGroup
                                preview={{
                                    visible,
                                    onVisibleChange: (visible) => {
                                        this.setState({
                                            visible: visible
                                        });
                                    }
                                }}
                            >
                                {imgList?.map((imgUrl, index) => {
                                    return <Image key={`${index}-images`} src={imgUrl} />;
                                })}
                            </Image.PreviewGroup>
                        </div>
                    )
                }

            </>
        );
    }
}

export default BasicImage;
