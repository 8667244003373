import { RECEIVE_CONFIGS } from "@actions/action_consts";

const configsReducer = (state = {}, action = {}) => {
    Object.freeze(state)
    switch (action.type) {
        case RECEIVE_CONFIGS:
            const configs = action.configs
            const res = {}
            for (const key in configs) {
               res[key] = configs[key]
            }
            return {...state, ...res}
        default:
            return state
    }
}

export default configsReducer;
