import * as React from "react";
import { Image } from "antd";
import env from "env"

export const SingleWaterFall = (props) => {
  const tempIndex = props.aspect_ratio;
  const {width} = tempIndex && getDivWidth(tempIndex);
  const res = props.tag === "pre" ? props.thumbUrl : (props.status === "placeholder" ?  props.task_image_bak : props.task_image)
  return (
    <>
      <div
        className="render-item "
        style={{
          width,
        }}
      >
        <Image src={res} preview={{ visible: false, mask: null }} fallback={env.placeImage} />
      </div>
    </>
  )
}

function getDivWidth(tempIndex) {
  if (tempIndex > 0 && tempIndex < 1.5) {
    return {
      width: "15.625vw",
    }
  } else if (tempIndex >= 1.5 && tempIndex < 2.5) {
    return {
      width: "31.667vw",
    }
  } else if (tempIndex >= 2.5 && tempIndex < 3.5) {
    return {
      width: "47.708vw",
    }
  } else if (tempIndex >= 3.5) {
    return {
      width: "63.75vw",
    };
  }
}
