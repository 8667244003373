import React, { Component } from "react";
export default class BlankPage extends Component {
  render () {
    const {text} = this.props
    return (
      <div>
        <div className="blank-content">
          <div className="empty-img-text">
            <img
              src={process.env.PUBLIC_URL + "/images/other/pending.png"}
              alt="function-pending"
            />
            <h2>{text ? text : "此功能暂未开放，敬请期待!"}</h2>
          </div>
        </div>
      </div>
    );}
}
