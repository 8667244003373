import { useCallback, useEffect, useRef, useState } from 'react';
import { isFunction, isUndefined } from './helper';
import { serialize } from './serialize';
import useSwr from './useSwr';
import { withArgs } from './resolve-args';

const INFINITE_PREFIX = '$inf$'

const getFirstPageKey = (getKey) => {
  return serialize(getKey ? getKey(0, null) : null)[0]
}
export const unstable_serialize = (getKey) => {
  return INFINITE_PREFIX + getFirstPageKey(getKey)
}
 const infinite = (useSwrNext) => (getKey, fn, config) => {
  const rerender = useState({})[1]
  const didMountRef = useRef(false)
  const dataRef = useRef()

  const {
    cache,
    initialSize = 1,
    revalidateAll = false,
    persistSize = false,
    revalidateFirstPage = false,
    revalidateOnMount = false
  } = config

  let firstPageKey = null
  let contextCacheKey = null
  let pageSizeCacheKey = null
  try {
    firstPageKey = getFirstPageKey(getKey)
  } catch (err) {
  }
  if (firstPageKey) {
    contextCacheKey = '$ctx$' + firstPageKey
    pageSizeCacheKey = '$len$' + firstPageKey // 存储当前页key
  }

  // 获取当前页
  const resolvePageSize = useCallback(() => {
    const cachedPageSize = cache.get(pageSizeCacheKey)
    return isUndefined(cachedPageSize) ? initialSize : cachedPageSize
  }, [pageSizeCacheKey, initialSize])

  const lastPageSizeRef = useRef(resolvePageSize())
  // 是否重新验证
  const shouldRevalidateOnMount = revalidateOnMount && !didMountRef.current

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }
    if (firstPageKey) {
      cache.set(
        pageSizeCacheKey,
        persistSize ? lastPageSizeRef.current : initialSize
      )
    }
  }, [firstPageKey])

  const swr = useSwrNext(firstPageKey ? INFINITE_PREFIX + firstPageKey : null,
    async () => {
      const [forceRevalidateAll, originalData] = cache.get(contextCacheKey) ||
      []
      const data = []
      const pageSize = resolvePageSize()

      let previousPageData = null
      for (let i = 0; i < pageSize; ++i) {
        // 获取当前页的存储数据的key
        const [pageKey, pageArgs] = serialize(getKey(i, previousPageData))
        if (!pageKey) {
          break
        }
        let pageData = cache.get(pageKey)
        const shouldFetchPage =
          revalidateAll ||
          forceRevalidateAll ||
          isUndefined(pageData) ||
          (revalidateFirstPage && !i && !isUndefined(dataRef.current)) ||
          shouldRevalidateOnMount ||
          (originalData &&
            !isUndefined(originalData[i]) &&
            !config.compare(originalData[i], pageData))
        if (fn && shouldFetchPage) {
          pageData = await fn(...pageArgs)
          cache.set(pageKey, pageData)
        }

        data.push(pageData)
        previousPageData = pageData
      }
      cache.delete(contextCacheKey)
      return data
    }, config)
  useEffect(() => {
    dataRef.current = swr.data
  }, [swr.data])

  const mutate = useCallback((...args) => {
    const data = args[0]
    const shouldRevalidate = args[1] !== false
    if (!contextCacheKey) return
    if(shouldRevalidate){
      if(!isUndefined(data)){
        const originalData = dataRef.current
        cache.set(contextCacheKey, [false, originalData])
      } else {
        cache.set(contextCacheKey, [true])
      }
      return args.length ? swr.mutate(data, shouldRevalidate) : swr.mutate()
    }
  }, [contextCacheKey])
  // 获取当前页面前面的页面的数据
  const resolvePagesFromCache = (pageSize)=> {
    const data = []
    let previousPageData = null
    for (let i = 0; i < pageSize; ++i) {
      const [pageKey] = serialize(getKey(i, previousPageData))

      const pageData = pageKey ? cache.get(pageKey) : undefined

      if (isUndefined(pageData)) return dataRef.current

      data.push(pageData)
      previousPageData = pageData
    }
    return data
  }

  const setSize = useCallback((arg) => {
      if (!pageSizeCacheKey) return
      let size
      if (isFunction(arg)) {
        size = arg(resolvePageSize())
      } else if (typeof arg == 'number') {
        size = arg
      }
      if (typeof size != 'number') return

      cache.set(pageSizeCacheKey, size)
      lastPageSizeRef.current = size
      rerender({})
      return mutate(resolvePagesFromCache(size))
    },
    [pageSizeCacheKey, resolvePageSize, mutate]
  )

  return {
    size: resolvePageSize(),
    setSize,
    mutate,
    get error() {
      return swr.error
    },
    get data() {
      return swr.data
    },
    get isValidating() {
      return swr.isValidating
    }
  }
}
export default withArgs(infinite(useSwr))
