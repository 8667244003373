/**
 * @name: OpenFormItem.jsx
 * @user: cfj
 * @date: 2022/4/12
 * @description: 公开or不公开
 */
import { Typography } from '@components'
import React from 'react'
import Icon from '@components/icon'
import { clsx } from '@gUtils'

export const OpenFormField = function(props) {
    const {
        value,
        onChange,
        className,
        ...other
    } = props;
    const onSelect = value === 1 || value === 2;
    const offSelect = value === 0 || value === 2;
    // 1：公开， 0：不公开，2:全选
    function onClick(inputValue) {
        let outValue;
        // 什么情况下单选公开。 全选时点击公开 或者 选中公开点击公开
        if ((value === 2 && inputValue === 1) || (inputValue === 1 && value === 1)) {
            outValue = 0;
          // 什么情况下单选不公开。 全选时点击不公开 或者 选中不公开点击不公开
        } else if ((value === 2 && inputValue === 0) || (inputValue === 0 && value === 0)) {
            outValue = 1;
          // 什么情况下全选。 公开时点击不公开 或者 不公开点击公开
        } else if (value === 0 && inputValue === 1 || value === 1 && inputValue === 0) {
            outValue = 2;
        } else {
            outValue = value;
        }
        onChange(outValue);
    }

    return <div className={clsx(className, 'd-flex align-items-center')} {...other}>
        <Typography onClick={() => onClick(1)} className={'cursor-pointer toggle'}>
            <Icon type={onSelect ? 'icon-a-24-xuanzhong' : 'icon-a-24-weixuanzhong'} />
            公开
        </Typography>
        <Typography onClick={() => onClick(0)} className={'cursor-pointer toggle'}>
            <Icon type={offSelect ? 'icon-a-24-xuanzhong' : 'icon-a-24-weixuanzhong'} className={'ml-2'} />
            不公开
        </Typography>
    </div>;
};
