import { LeftTaskList } from './components/left_task_list';
import RightCreateTasks from './components/right_create_tasks';
import { TaskPackageModal } from './components/task_package_modal';
import '@/stylesheets/components/flex_basics.less';
import './index.less';
import BottomButton from './components/bottom_button';
import { useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'antd';
import TaskDetails from './components/taks_details';
import { TaskPackageReview } from './components/task_planning/task_package_review';

/**
 * 任务包弹框功能
 */
const TaskPackage = (props) => {
    let {
        taskPackageStore: { isPackageModal, taskId, getTaskId,isShowEditCreate,setCurrentTasks,tasksId }
    } = props;
    // 创建任务包滑动的高度
    const [scrollTop, setScrollTop] = useState(0);
    //  任务包form的高度
    const [formRef, setFormRef] = useState(null);
    // form对象
    const [form] = Form.useForm();
    const taskPlanRef = useRef();

    const dom = document.getElementById('task-planning-content');
    // props.taskPlanStore.setModelDom(dom.);

    // 关闭弹框
    const handleCancel = () => {
        props.taskPackageStore.setIsPackageModal(false);
        props.taskPlanStore.setCommentModal(false)
        props.taskPackageStore.setTaskId(null);
        props.taskPlanStore.currentTasks = {}
        props.taskPlanStore.toggleVisible(false);
        props.taskPlanStore.updateVolumnCreateStatus(true);
        props.taskPackageStore.setShowEditCreate(false)
        props.taskPlanStore.setUpdateTask({});
         // 请求成功后清除form表单
         form.resetFields();
    };
    const afterClose = () => {
        setCurrentTasks(null);
    }

    const onSelectChange = () => {
        form.resetFields();
        form.setFieldsValue({
            trips: []
        })
    }

    return (
        <TaskPackageModal isVisible={isPackageModal} afterClose={afterClose} handleCancel={handleCancel} className="amp-tasks-package">
            <div className="flex">
                {/* 左侧的单元列表 */}
                <div className='tmp-task-package-left'>
                    <LeftTaskList {...props } onSelectChange={onSelectChange} form={form}/>
                </div>
                {/* 右侧的创建任务表单 */}
                <div className="right-create-tasks" ref={taskPlanRef}>
                    {/* 顶部区域 */}
                    {/* <div className={`tasks-top-blank ${scrollTop > 0 && 'blank-shadow'}`}></div> */}
                    {/* 任务包详情 */}
                    {tasksId ? (
                        isShowEditCreate ?  <RightCreateTasks setScrollTop={setScrollTop} setFormRef={setFormRef} form={form} {...props} /> :
                        <TaskDetails {...props} taskPlanRef={taskPlanRef} />
                    ) : (
                        <RightCreateTasks setScrollTop={setScrollTop} setFormRef={setFormRef} form={form} {...props} />
                    )}

                    {/* 底部按钮组件 */}
                    <div className={`tasks-bottom-button ${formRef && formRef.scrollHeight > 687 && 'bottom-shadow'}`}>
                        <BottomButton handleCancel={handleCancel} form={form} {...props} />
                    </div>
                </div>
            </div>
        </TaskPackageModal>
    );
};

export default inject('taskPlanStore', 'taskPackageStore', 'todosStore')(observer(TaskPackage));
