import { CaretUpOutlined } from "@ant-design/icons";
import { Component } from "react";

class Collapse extends Component {
  state = {
    collapsed: !this.props.opened,
  };

  get collapsed() {
    return this.state.collapsed;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this.setState({ collapsed: !this.props.opened });
    }
  }

  toggleCollapse = () => this.setState({ collapsed: !this.collapsed });
  renderChildren = () =>
    !this.collapsed && this.props.children ? (
      <div className="mg-t-16 mg-b-16"> {this.props.children} </div>
    ) : (
      ""
    );
  close = () => this.setState({ collapsed: false });
  open = () => this.setState({ collapsed: true });

 render() {
    const { header,fontWeight} = this.props;
    const fw = fontWeight ? fontWeight : "";
    const hasChildren = !!this.props.children;
    const cursorClass = hasChildren ? "cursor-pointer" : "cursor-default";
    return (
      <div>
        <div
          onClick={this.toggleCollapse}
          className={`display-flex collapse-header ${cursorClass}`}
        >
          <div className={`mg-r-10 ${fw}`}>{header}</div>

          {hasChildren ? (
            <div>
              <CaretUpOutlined
                className="collapse-arrow"
                rotate={!this.collapsed ? 0 : 180}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="collapse-content">{this.renderChildren()}</div>
      </div>
    );
  };
}

export default Collapse;
