import React, { memo, useState } from 'react'
import { Form, Select } from 'antd';
import { useSwr } from '@hooks';
import { shootingTeamAPI, usersAPI, configsAPI } from '@api'
const { Option } = Select

function SearchFrom(props) {
    const { searchChange, type } = props
    const [groupId, setGroupId] = useState(0)
    const [form] = Form.useForm()
    const onValuesChange = (changedValues, allValues) => {
        const keyName = Object.keys(changedValues)[0];
        let newValues = {}
        // 修改选择小组 清除组员
        if (keyName === 'group_id') {
            newValues.user_id = undefined
            setGroupId(changedValues.group_id)
        }
        form.setFieldsValue(newValues)
        searchChange?.({ ...allValues, ...newValues })
    }
    const groupRequst = () => {
        return shootingTeamAPI.shootingTeamList().then((res) => {
            if (res.code === 1000) {
                return res.data.list
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: groupList, isValidating: groupLoading } = useSwr('/task/user-group/list', groupRequst)

    const usersRequst = (url, filterGroupId) => {
        if (filterGroupId) {
            const filter = {
                group_id: filterGroupId
            }
            return shootingTeamAPI.shootingTeamMember({ filter }).then((res) => {
                if (res.code === 1000) {
                    return res.data.list.map(item => ({
                        name: item.name,
                        id: item.user_id
                    }))
                }
                throw new Error(res.msg || '');
            })

        }
        return usersAPI.fetchUsers().then((res) => {
            if (res.code === 1000) {
                return res.data.list
            }
            throw new Error(res.msg || '');
        })
    }
    const { data: usersList, isValidating: usersLoading } = useSwr(['/user/list', groupId], usersRequst)

    const taskStatusRequst = () => {
        return configsAPI.fetchTaskConfig().then((res) => {
            if (res.code === 1000) {
                const data = res.data?.task_status
                return Object.values(data)?.map(i => ({
                    id: i.code,
                    name: i.label
                }))
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: taskStatus = [] } = useSwr(type === 'task' ? '/task/config' : null, taskStatusRequst)

    const previewOption = (options = []) => {
        return options.map(item => (
            <Option
                key={item.id}
                value={item.id}
            >
                {item.name}
            </Option>
        ))
    }

    return (
        <div>
            <Form
                className='amp-work-progress-from'
                form={form}
                initialValues={{ department_id: 0, group_id: 0 }}
                onValuesChange={onValuesChange}
            >
                <div
                    className="amp-work-progress-from-title"
                >
                    数据范围
                </div>
                <Form.Item
                    name="department_id"
                >
                    <Select
                        size="large"
                        placeholder="请选择部门"
                        className="select-sz-m amp-mr-10"
                        getPopupContainer={triggerNode => triggerNode.parentElement}
                        showSearch
                        optionFilterProp="children"
                    >
                        <Option key={0} value={0}>所有部门</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="group_id"
                >
                    <Select
                        size="large"
                        placeholder="请选择小组"
                        className="select-sz-m amp-mr-10"
                        getPopupContainer={triggerNode => triggerNode.parentElement}
                        showSearch
                        loading={groupLoading}
                        optionFilterProp="children"
                    >
                        <Option key={0} value={0}>所有小组</Option>
                        {
                            previewOption(groupList)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="user_id"
                >
                    <Select
                        size="large"
                        placeholder="请输入人员名称"
                        className="select-sz-m amp-mr-10"
                        getPopupContainer={triggerNode => triggerNode.parentElement}
                        allowClear
                        showSearch
                        loading={usersLoading}
                        optionFilterProp="children"
                    >
                        {
                            previewOption(usersList)
                        }
                    </Select>
                </Form.Item>
                {
                    type === 'task' ? <Form.Item
                        name="task_status"
                    >
                        <Select
                            size="large"
                            placeholder="任务状态"
                            className="select-sz-m amp-mr-10"
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                        >
                            {
                                previewOption(taskStatus)
                            }
                        </Select>
                    </Form.Item> : null
                }

            </Form>
        </div>
    )
}

export default memo(SearchFrom)
