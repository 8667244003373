import { Progress } from 'antd';
import { useState, useEffect } from 'react';
import PreviewImage from '@components/image/preview_image'
import { LazyLoadingImage, } from '@components'
import { sessionAPI } from '@api'
import moment from "moment";

// summaryWeekColumn
export const summaryWeekColumn = [
    {
        title: '组别',
        width: "10%",
        dataIndex: 'name'
    },
    {
        title: '贴图(分)',
        width: "9%",
        dataIndex: 'texture_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.texture_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.texture_score}</span>
                </>
            )
        }
    },
    {
        title: '3D模型(分)',
        width: "9%",
        dataIndex: 'material_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.material_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.material_score}</span>
                </>
            )
        }
    },
    {
        title: '笔刷(分)',
        width: "9%",
        dataIndex: 'brush_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.brush_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.brush_score}</span>
                </>
            )
        },
    },
    {
        title: '透贴(分)',
        width: "9%",
        dataIndex: 'films_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.films_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.films_score}</span>
                </>
            )
        },
    },
    {
        title: '组合(分)',
        width: "9%",
        dataIndex: 'comp_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.comp_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.comp_score}</span>
                </>
            )
        },
    },
    {
        title: '制作总量',
        width: "8%",
        dataIndex: 'produce_count',
    },
    {
        title: '制作总分',
        width: "8%",
        dataIndex: 'produce_score',
        render: (produce_score) => <span className='amp-table-score'>{produce_score}</span>
    },
    {
        title: '目标分数',
        width: "8%",
        dataIndex: 'goal',
        render: (goal) => <span className='amp-table-score'>{goal}</span>
    },
    {
        title: '当前进度',
        width: "9%",
        dataIndex: 'progress_rate',
        render: (progress_rate) => <Progress
            percent={progress_rate}
            format={() => `${progress_rate}%`}
            strokeColor="#29C1B9"
            size="small"
        />
    }
];

// summaryMonthColumn
export const summaryMonthColumn = [
    {
        title: '组别',
        width: "10%",
        dataIndex: 'name'
    },
    {
        title: '贴图(分)',
        width: "9%",
        dataIndex: 'texture_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.texture_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.texture_score}</span>
                </>
            )
        }
    },
    {
        title: '3D模型(分)',
        width: "9%",
        dataIndex: 'material_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.material_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.material_score}</span>
                </>
            )
        }
    },
    {
        title: '笔刷(分)',
        width: "9%",
        dataIndex: 'brush_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.brush_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.brush_score}</span>
                </>
            )
        },
    },
    {
        title: '透贴(分)',
        width: "9%",
        dataIndex: 'films_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.films_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.films_score}</span>
                </>
            )
        },
    },
    {
        title: '组合(分)',
        width: "9%",
        dataIndex: 'comp_count',
        render: (_, record) => {
            return (
                <>
                    <span>{record.comp_count}</span>
                    <span className='amp-table-score mg-l-8'>{record.comp_score}</span>
                </>
            )
        },
    },
    {
        title: '制作总量',
        width: "8%",
        dataIndex: 'produce_count',
    },
    {
        title: '制作总分',
        width: "8%",
        dataIndex: 'produce_score',
        render: (produce_score) => <span className='amp-table-score'>{produce_score}</span>
    },
    {
        title: '补分',
        width: "8%",
        dataIndex: 'supplement_score',
        render: (supplement_score) => <span className='amp-table-score'>{supplement_score}</span>
    },
    {
        title: '总分',
        width: "8%",
        dataIndex: 'total_score',
        render: (total_score) => <span className='amp-table-score'>{total_score}</span>
    },
    {
        title: '月目标分数',
        width: "8%",
        dataIndex: 'goal',
        render: (goal) => <span className='amp-table-score'>{goal}</span>
    },
    {
        title: '当前进度',
        width: "9%",
        dataIndex: 'progress_rate',
        render: (progress_rate) => <Progress
            percent={progress_rate}
            format={() => `${progress_rate}%`}
            strokeColor="#29C1B9"
            size="small"
        />
    }
];

export const emptyMonthColumns = [
    {
        title: '操作',
        width: "10%",
        dataIndex: '',
        render: (record) => <>
            <div className='amp-table-cell-hidden'>
                <span
                    className="amp-blue-btn mg-r-22 cursor-pointer"
                    onClick={() => handleUpdateScore(record)}
                >
                    补分
                </span>
                <span
                    className="amp-blue-btn cursor-pointer"
                    onClick={() => handleDetail(record)}
                >
                    查看
                </span>
            </div>
        </>
    }
]

//emptyColumns
export const emptyWeekColumns = [
    {
        title: '操作',
        width: "10%",
        render: (record) => <>
            <div className='amp-table-cell-hidden'>
                <span
                    className="amp-blue-btn cursor-pointer"
                    onClick={() => handleDetail(record)}
                >
                    查看
                </span>
            </div>
        </>
    }
]

// detailColumn
export const detailColumn = [
    {
        title: '缩略图',
        dataIndex: 'preview_image',
        render(text, record) {
            const src = record.preview_image
            const onPreview = function (src) {
                PreviewImage.open({
                    images: [src],
                    request: (url) => sessionAPI.fetchOriginalImage(url)
                        .then(res => res.data?.origin_urls?.[0] || '')
                })
            }
            return <LazyLoadingImage
                preview={{
                    visible: false
                }}
                onClick={(event) => {
                    event.stopPropagation()
                    onPreview(src)
                }}
                width='60px'
                height='60px'
                src={src} />
        }
    },
    {
        title: '资产ID',
        dataIndex: 'asset_no'
    },
    {
        title: '资产名称',
        dataIndex: 'asset_name'
    },
    {
        title: '制作人',
        dataIndex: 'producer_name'
    },
    {
        title: '资产类型',
        dataIndex: 'asset_kind',
    },
    {
        title: '评分',
        dataIndex: 'score',
    },
    {
        title: '初次提交工程审核时间',
        dataIndex: 'review_time',
    },
    {
        title: '当前状态',
        dataIndex: 'task_status'
    }
];

// 导出数据表头
export const csvHeader = [
    { label: '组员', value: 'name' },
];

/**
 * 开始结束时间转时间戳
 * @param {*} time 时间
 * @param {*} type 1:开始时间，2:结束时间
 * @returns {Number} 时间戳
 */
export const timeStamp = (time, type) => {
    if (type === 1) {
        return moment(time.format('YYYY-MM-DD 00:00:00')).unix()
    } else {
        return moment(time.format('YYYY-MM-DD 23:59:59')).unix()
    }
}

// 默认时间
export const defaultTime = {
    defaultStartWeek: timeStamp(moment().week(moment().week()).day(1).subtract(2, 'day'), 1),
    defaultEndWeek: timeStamp(moment().week(moment().week()).day(8).subtract(2, 'day'), 2),
    defaultStartMonth: timeStamp(moment().startOf('month'), 1),
    defaultEndMonth: timeStamp(moment().endOf('month'), 2),
}

/**
 * 获取视图窗口宽高
 * @returns {Number} windowSize
 */
export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

    const windowChange = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowSize({ width, height });
    };

    useEffect(() => {
        windowChange();
        window.addEventListener('resize', windowChange);

        return () => {
            window.removeEventListener('resize', windowChange);
        };
    }, []);

    return windowSize;
};
