import { Iconfont } from "@/components";

export const DrawerCloseButton = (props) => {
  const { visible, onClick, width } = props;
  let right = visible ? `${(0.985 * width) / 19.2}vw` : "0";
  const className = visible
    ? "drawer-close-icon-active"
    : "drawer-close-icon-inactive";
  return (
    <div className={className} onClick={onClick} style={{ right }}>
      <Iconfont type="icon-right-arrow" />
    </div>
  );
};
