import { combineReducers } from "redux";
import {
  RECEIVE_PROJECTS,
  REQUEST_PROJECTS,
} from "@actions/action_consts";

const pages = (pages = {}, action = {}) => {
  Object.freeze(pages)
  switch (action.type) {
    case REQUEST_PROJECTS:
      return {
        ...pages,
        [action.params.page]: {
          items: [],
          fetching: true,
        },
      };
    case RECEIVE_PROJECTS:
      let list = action.projects.list;
      return {
        ...pages,
        [action.params.page]: {
          items: list?.map((item) => item.id),
          fetching: false,
        },
      };

    default:
      return pages;
  }
};

const filter = (filter = {}, action = {}) => 
  action.type === REQUEST_PROJECTS ? (action.params.filter || {}) : filter;
const currentPage = (currentPage = 1, action = {}) =>
  action.type === REQUEST_PROJECTS ? (action.params.page || 1) : currentPage;
const total = (total = 0, action = {}) =>
  action.type === RECEIVE_PROJECTS ? action.projects.total : total;


export const projectsPagination = combineReducers({
  pages,
  currentPage,
  total,
  filter
});
