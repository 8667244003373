import { priorityIcon } from "@vars/views/tasks_vars";
import { Divider } from "antd";
import { Iconfont } from "@/components";
import { observer, inject } from "mobx-react";
import TaskImages from "./task_image"


const TaskSpecs = (props) => {
    const { processing_time, priority, task_status, collect_images_count } = props;
    const backgroundColor = task_status.color || "gray";
    const isShowCollectImagesCount = [1, 22, 3, 4, 5, 6, 7].includes(task_status.code)
    return (
        <div className="task-card-head">
            <div>
                <Iconfont type="icon-clock" />
                <span className="mg-l-4 mg-r-4">{processing_time}</span>
                {
                    isShowCollectImagesCount && <span className="mg-l-4 mg-r-4 amp-task-card-bd">{collect_images_count}张</span>
                }
            </div>
            <div className="task-card-status">
                <div className="task-status-display" style={{ backgroundColor }}>{task_status?.label}</div>
                {priorityIcon[priority] ? (
                    <Iconfont
                        type={priorityIcon[priority]}
                        className="task-priority-icon mg-l-4"
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

const TaskTitle = (props) => {
    const { title, asset_uid, type, asset_name } = props;
    return (
        <div className="task-card-title">
            <span className="mg-r-12">{`#${asset_uid}`}</span>
            <span>{type === 0 ? asset_name : title}</span>
        </div>
    );
};

// const TaskImages = (props) => {
//     const { display_images } = props;
//     const handleClick = (e, url) => {
//         console.log("555555555555555555555555555", url);
//         e.stopPropagation();
//     };
//     if (!display_images) return "";
//     const images = display_images.slice(0, 3)?.map((url, idx) => (
//         <span key={idx} className="mg-r-4">
//             {/* <BasicImage onClick={handleClick} height={68} width={68} src={url} /> */}
//             <Image
//                 onClick={(e) => {handleClick(e, url)}}
//                 width={88}
//                 height={88}
//                 src={url}
//                 preview={{
//                     //
//                 }}
//             />
//         </span>
//     ));
//     return (
//         <div className="task-card-img" onClick={handleClick}>
//             <Image.PreviewGroup>{images}</Image.PreviewGroup>
//         </div>
//     );
// };
const TaskComment = (props) => {
    const { asset_comment } = props;
    return <div className="task-card-comment">{asset_comment}</div>;
};

// const TaskPeople = (props) => {
//   const seq = ["supervisors", "photographers", "analyzers", "producers"];
//   const display = seq?.map((key) =>
//     props[key] !== undefined && props[key].length > 0 ? (
//       <div key={key} className="task-card-people-section">
//         <span className="mg-r-5">{taskDIC[key]}</span>
//         <ProfileGroup people={props[key]} />
//       </div>
//     ) : (
//       ""
//     )
//   );
//   return <div className="task-card-people">{display}</div>;
// };

const GroupName = (props) => {
    const { task_group_name } = props
    if (!task_group_name) return ""
    return (
        <>
            <div className="justify-center" style={{ marginTop: "-7px" }}>
                {task_group_name}
            </div>
            <Divider style={{ margin: "8px 0" }} />
        </>
    );
}

const TaskCard = (props) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
        // props.taskStore.fetchAssetsInfo(props.id);
        props.taskProcessStore.hide();
    };
    return (
        <div className="task-card" onClick={handleClick}>
            <GroupName {...props} />
            <div className="task-card-content">
                <TaskSpecs {...props} />
                <TaskTitle {...props} />
                <TaskImages {...props} />
                <TaskComment {...props} />
            </div>
            {/* <TaskPeople {...props} /> */}
        </div>
    );
};

export default inject("taskStore", "taskProcessStore")(observer(TaskCard))
