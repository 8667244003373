import { Button } from 'antd';
import './index.less';
import { clsx } from '@gUtils';

/**
 * 按钮组件
 * @param {object} props props参数
 * @param {boolean} props.loading 是在加载
 * @param {boolean} props.disabled 是否禁用
 * @param {default|primary|link|text}[props.type=default] props.type
 * @returns {JSX.Element}
 * @constructor
 */
const Index = function(props) {
    const {
        className,
        disabled,
        loading,
        type = 'default',
        ...other
    } = props;
    return (
        <Button
            className={clsx('amps-button', `amps-button-${type}`, {
                'amps-button-disabled': disabled || loading

            }, className)}
            disabled={disabled}
            loading={loading}
            {...other}
        />
    );
};
export default Index;
