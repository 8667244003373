import {
  RECEIVE_CURRENT_USER,
  RECEIVE_USERS,
  RECEIVE_USER,
  RECEIVE_GROUPS,
  DELETE_USER,
} from "@actions/action_consts";

const usersReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return {
        ...state,
        [action.currentUser.id]: action.currentUser,
      };

    case RECEIVE_USER:
      return {
        ...state,
        [action.data.id]: action.data,
      };

    case RECEIVE_USERS:
      let list = action.users.list || [],
        res = {};
      list.forEach((ele) => {
        res[ele.id] = ele;
      });
      if (!action.params.filter) return res;
      return { ...state, ...res };

    case RECEIVE_GROUPS:
      let groupOption = action.data.list || [];
      return {
        ...state,
        groupOption,
      };

    case DELETE_USER:
      const { id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    default:
      return state;
  }
};

export default usersReducer;
