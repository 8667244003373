import axios from 'axios'
import { Server } from '@gUtils';

const collectionStatsAPI = {
    /**
     * 采集统计 周月总览
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionSummary(params) {
        return axios.post("/api/task-performance/summary", params);
    },

    /**
     * 解析统计详情
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionDetails(params) {
        return axios.post("/api/task-performance/list", params);
    },

    /**
     * 采集统计 解析小组
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionUserGroupList(params) {
        const { group_type } = params
        return axios.get("/api/user-group/list", {
            params:{
                group_type:group_type
            }
        });
    },

    /**
     * 采集统计 解析小组成员
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionUserGroupMember(params) {
        const { group_type, group_id } = params
        return axios.get("/api/user-group/member", {
            params:{
                group_type:group_type,
                group_id:group_id
            }
        });
    },

    /**
     * 采集统计 详情表格导出
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionDetailExport(params) {
        return Server.post("/api/task-performance-detail/export", params);
    },

    /**
     * 采集统计 周月总览表格导出
     * @param {*} params
     * @returns {Object} data
     */
    getCollectionSummaryExport(params) {
        return Server.post("/api/task-performance-summary/export", params);
    },
};

export default collectionStatsAPI
