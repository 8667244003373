import React, { useState, memo, useCallback } from 'react';
import { Modal, Button } from 'antd';
import SearchFrom from './search_form';
import TopicList from './topic_list';
import SceneModal from '@/views/topic_selection_management/topic_details/components/topic_scene/components/scene_modal';
import './index.less';

interface ITopicModalProps {
    visible: boolean;
    onClose: () => void;
}
/**
 * 创建场景-关联选题弹窗
 * @param {ITopicModalProps} props props
 * @returns {React.ReactNode}
 */
const TopicModal: React.FC<ITopicModalProps> = (props) => {
    const { visible, onClose } = props;
    const [searchData, setSearchData] = useState<any>(null);
    const [selectTopic, setSelectTopic] = useState<any>(null);
    const [modalType, setModalType] = useState<string>('');

    const onSearch = useCallback((values) => {
        setSearchData(values);
    }, []);

    const footer = (
        <>
            <Button
                type="primary"
                disabled={!selectTopic?.id}
                onClick={() => {
                    setModalType('add');
                    onClose();
                }}
            >
                确认
            </Button>
            <Button onClick={onClose}>
                取消
            </Button>
        </>
    );

    return (
        <>
            <Modal
                title="添加场景：step1 关联选题"
                visible={visible}
                onCancel={onClose}
                destroyOnClose
                width={1048}
                afterClose={() => setSearchData(null)}
                centered
                footer={footer}
                className="amp-scene-related-topic-modal"
            >
                <div className="amp-related-topic-modal-body">
                    <h5 className="amp-related-topic-modal-tips">添加场景需要关联选题，请选择一个选题并确认</h5>
                    <SearchFrom onSearch={onSearch} />
                    <TopicList searchData={searchData} setSelectTopic={setSelectTopic} />
                </div>
            </Modal>

            <SceneModal
                onClose={(type: string) => setModalType(type)}
                modalType={modalType}
                topicId={selectTopic?.id}
            />
        </>
    );
};

export default memo(TopicModal);
