import * as React from "react";
import { observer, inject } from "mobx-react";
import { titleBaseStyle, columnStyle } from "./vars";
import { Iconfont } from "@components";

const typeDIC = {
  group: "组织",
  role: "角色",
};

@inject("addRelationStore")
@observer
class TypePart extends React.Component {
  get store() {
    return this.props.addRelationStore;
  }
  get addType() {
    return this.store.addType;
  }

  componentDidMount() {
    this.store.fetchGroupTree();
    this.store.fetchRoles();
  }

  handleNodeSelect = (id) => {
    const { role_id, group_id } = this.store;
    if (this.addType === "role") {
      if (id === role_id) this.store.updateRoleId("");
      else this.store.updateRoleId(id);
    } else {
      if (id === group_id) this.store.updateGroupId("");
      else this.store.updateGroupId(id);
    }
  };

  nodeRender = (node) => {
    const isRoleSelection = this.addType === "role";
    const { role_id, group_id } = this.store;
    const { id, name } = node;
    const isSelected = isRoleSelection
      ? role_id === id
      : group_id === id || (group_id && node?.path.includes(group_id));
    const className = isSelected ? "cascader-selected" : "cascader-default";
    return (
      <div
        className={className}
        key={id}
        onClick={() => this.handleNodeSelect(id)}
      >
        {name}
        <Iconfont className="icon-20" type={isSelected ? "icon-arrow" : " "} />
      </div>
    );
  };

  groupRender = () => {
    const { groups, group_id } = this.store;
    const currentGroup = groups.filter((item) => item.id === group_id);
    return (
      <>
        <div style={{ ...columnStyle, width: "50%" }}>
          {groups?.map((node) => this.nodeRender(node))}
        </div>
        <div style={{ width: "50%" }}>
          {currentGroup?.children?.length > 0 && currentGroup.id !== 1
            ? currentGroup.children?.map((node) => this.nodeRender(node))
            : ""}
        </div>
      </>
    );
  };

  roleRender = () => {
    const { roles } = this.store;
    return (
      <div style={{ width: "100%" }}>
        {roles?.map((node) => this.nodeRender(node))}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div style={{ ...titleBaseStyle }}>{typeDIC[this.addType]}</div>
        <div style={{ ...columnStyle, width: "100%", display: "flex" }}>
          {this.addType === "group"
            ? this.groupRender()
            : this.addType === "role" && this.roleRender()}
        </div>
      </div>
    );
  }
}
export default TypePart;
