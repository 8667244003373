import { Select } from 'antd';

const OnlySelect = function(props) {
    const { onChange, options, value: _value , mode,...other } = props;
    const values = Array.isArray(_value)?_value:_value?[_value]:[]
    return (
        <Select
            {...other}
            value={_value}
            mode={mode}
            onChange={(value, option) => {
                if(mode && value?.length>1){
                    const onlyOptions = options.filter(option => option.only)

                    // 已经选择了的唯一项目
                    if(onlyOptions.some(option => option.code === values[0]) ){
                        onChange(value.slice(1))
                    } else {
                        const v = value.find(v => onlyOptions.some(option => option.code === v))
                        if(v){
                            onChange([v])
                        } else {
                            onChange(value)
                        }
                    }
                } else {
                    onChange(value)
                }
            }}
        >
            {options.map((item, idx) => (
                <Select.Option disabled={item.disabled} value={item.code} key={item.code}>
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    );
};
export default OnlySelect;
