/**
 * @name: socAuth.js
 * @user: cfj
 * @date: 2022/4/2
 * @description: 腾讯云oss 文件上传
 */
import axios from 'axios';
/**
 * 获取上传密钥接口
 * @param {object} params 资产信息
 * @returns {object}
 */
export const socAuth = function(params = {}) {
    return axios.post('/api/dac-token/apply', params);
};

export default {};
