import ProjectIndex from "./project_index";
import { connect } from "react-redux";
import { fetchUsers } from "@actions/1_entities/users_actions";
import { createProject } from "@actions/1_entities/project_actions";
import { fetchTaskConfig } from "@actions/2_configs/configs_actions";
import { fetchProjects } from "@actions/1_entities/projects_actions";
import { fetchUserConfig} from '@actions/2_configs/configs_actions';

const mapStateToProps = (state) => ({
  users: state.entities.users,
  projects:state.entities.projects
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (params = { page: 1 }, cb) => dispatch(fetchUsers(params, cb)),
  createProject: (params, cb) => dispatch(createProject(params, cb)),
  fetchTaskConfig: (cb) => dispatch(fetchTaskConfig(cb)),
  fetchProjects: (params, cb) => dispatch(fetchProjects(params, cb)),
  fetchUserConfig:(params,cb) => dispatch(fetchUserConfig(params,cb))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndex);
