import Image from '@components/image';
import MackIcon from '@components/image/mack_icon';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';
import taskPlanAPI from '../../../api/2_projects/task_planning_api';
import { useSend } from '@hooks';
import { $error, $success, Drawer } from '@components';
import Dialog from '@components/dialog';
import CommentForm from './ActionForms/CommentForm';
import { Col, Row } from 'antd';
import { SetTaskForm } from '@views/taskPackage/components/ActionForms';
import JsonToFormData from '@gUtils/jsonToFormData';
import React from 'react';
import { useTaskContext } from '@views/taskPackage';

/**
 * @name: AuditTaskItemCol.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 任务审核的item
 */
const AuditTaskItemCol = function(props) {
    const {
        type, // view 详情的才查看，auto为审核
        cols,
        refresh
    } = props;
    const {
        session, // 当前登录用户信息
        getContainer,
        isPlanUser,
        refreshTaskList, // 刷新任务包列表
        refreshTaskDetails, // 刷新任务包详情
        refreshTaskInfoList, // 刷新任务包详情下的任务信息
        refreshTaskHistoryData // 刷新任务包详情下的任务操作历史
    } = useTaskContext();

    const [nodes, open] = Dialog.useDialog();
    const [drawerNodes, DrawerOpen] = Drawer.useDrawer({
        placement: 'bottom',
        maskClosable: false,
        destroyOnClose: true,
        style: { position: 'absolute' },
        getContainer: getContainer()
    });

    function createPromise(promise) {
        return promise.then(res => {
            if (res.code === 1000) {
                return res;
            } else {
                throw new Error(res.msg || '操作失败');
            }
        });
    }

    function onSuccess() {
        refreshTaskList()
        refreshTaskDetails()
        refreshTaskInfoList()
        $success('操作成功');
        refresh?.();
    }

    function onError(error) {
        $error(error.message);
    }

    // 通过
    const [loading1, send1] = useSend((id) => createPromise(taskPlanAPI.approvePlanTask({ id })), {
        onSuccess,
        onError
    });
    // 驳回
    const [loading2, send2] = useSend((id) => createPromise(taskPlanAPI.disapprovePlanTask({ id })), {
        onSuccess,
        onError
    });
    // 作废
    const [loading3, send3] = useSend((id) => createPromise(taskPlanAPI.discardPlanTask({ id })), {
        onSuccess,
        onError
    });

    // 预览功能
    const onPreview = (item) => {
        PreviewImage.open({
            images: item.images,
            renderDesc: (current) => <div style={{
                color: '#fff',
                padding: '100px 50px'
            }}>
                <p>名称 &nbsp;&nbsp; {item.title}</p>
                <p>上传人 {item.creator.name}</p>
                <p>自评分 {item.score}</p>
            </div>,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };

    function onComment(item) {
        open({
            title: '填写评论',
            content: <CommentForm initialValues={{ comment: item.comment }} />,
            async onOk(values) {
                const res = await taskPlanAPI.commentPlanTask({
                    id: item.id,
                    comment: values.comment
                });
                if (res.code === 1000) {
                    $success('提价成功');
                    refresh();
                } else {
                    throw new Error(res.msg || '操作失败');
                }
            }
        });
    }

    function exit(item) {
        DrawerOpen({
            title: '编辑任务',
            height: 550,
            content: <SetTaskForm initialValues={{
                id: item.id,
                package_id: item.package_id,
                userName: item.creator.name,
                title: item.title,
                score: item.score,
                material_type: item.material_type,
                image_urls: item.images
            }} />,
            onError(error) {
                $error(error.message);
            },
            async onOk(values) {
                const res = await taskPlanAPI.updatePlanTask(JsonToFormData(values));
                if (res.code === 1000) {
                    $success('编辑成功');
                    refreshTaskList()
                    refreshTaskDetails()
                    refreshTaskInfoList()
                    refresh();
                } else {
                    throw new Error(res.msg || '操作失败');
                }
            }
        });
    }

    function getMasks(item) {
        const isCreateTaskUser = item.creator.id === session.id

        const {
            status,
            id
        } = item;
        const masks = [<MackIcon title={'查看'} onClick={() => onPreview(item)} type={'icon-a-16-chakan'} />];
        if (type === 'auto' && [1, 3, 4].includes(status)) {
            // 通过功能
            masks.push(<MackIcon title={'通过'} onClick={() => send1(id)} type={'icon-a-14-tongguo'} />);
        }
        if ([3].includes(status)) {
            // 评论功能
            masks.push(<MackIcon title={'评论'} onClick={() => onComment(item)} type={'icon-a-14-pinglun'} />);
        }
        if (type === 'auto' && [1, 2, 4].includes(status)) {
            // 驳回功能
            masks.push(<MackIcon title={'驳回'} onClick={() => send2(id)} type={'icon-a-14-bohui'} />);
        }
        if ([1, 3].includes(status) || (type === 'auto' && status===2)) {
            // 作废功能
            masks.push(<MackIcon title={'作废'} onClick={() => send3(id)} type={'icon-a-14-zuofei'} />);
        }
        if ((isPlanUser || isCreateTaskUser) && type === 'view' && [1, 3, 4].includes(status)) {
            // 编辑功能
            masks.push(<MackIcon title={'编辑'} onClick={() => exit(item)} type={'icon-a-14-xiugai'} />);
        }
        return masks;
    }

    return <>
        <Row gutter={12} style={{ margin: '6px 0' }} wrap={false}>
            {cols.map((item) => <Col key={item.id}>
                <Image
                    disabled={loading1 || loading2 || loading3}
                    width={160}
                    height={160}
                    src={item.images?.[0]}
                    mask={getMasks(item)}
                    status={item.status}
                    title={item.title}
                    score={item.score}
                />
            </Col>)}
        </Row>
        {nodes}
        {drawerNodes}
    </>;
};
export default AuditTaskItemCol;
