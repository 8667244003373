import { Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { Iconfont } from '@components';
import { useEffect, useState } from 'react';

const { Option } = Select;

export const AssetName = (props) => {
    const message = "资产名称";
    const { assetCharacter, formType, checkedTask, currentTask } = props;

    useEffect(() => {
        if (checkedTask?.title) {
            props.formRef?.current?.setFieldsValue({
                "asset_name": checkedTask?.title
            })
        }
    }, [checkedTask?.title])
    return (
        <div className="task-form-col-2">
            <div className="bold required mg-b-16">资产名称</div>
            <Form.Item
                name="asset_name"
                rules={[{ required: true, message }]}
            >
                <Input
                    className="input-sz-ml"
                    placeholder={message}
                    disabled={true}
                // disabled={assetCharacter === 2}
                />
            </Form.Item>
        </div>
    );
};

export const AssetImageCount = (props) => {
    const message = "请输入0到10000的正整数";
    return (
        <div className="task-form-col-2 asset-count">
            <div className="bold required mg-b-16">原序列图片数量</div>
            <Form.Item
                name="collect_images_count"
                rules={[{
                    required: true,
                    message: "请输入原序列图片数量"
                }]}
                initialValue={props.currentTask?.collect_images_count || null}
            >
                <InputNumber
                    className="input-sz-ml"
                    placeholder={message}
                    min={1}
                    max={10000}
                />
            </Form.Item>
        </div>
    );
};

export const AssetID = (props) => {
    const content = (
        <div style={{ color: "#6D6D6D" }}>
            <p>内部ID规则： </p>
            <p>• 共计12位，系统根据创建时所填参数自动生成。 </p>
            <p>• 1-2号位表示该资产的类型。 </p>
            <p>• 3-4号位表示该资产的采集地区。 </p>
            <p>• 5-10号位自动根据创建顺序随机生成。 </p>
            <p>• 10-12号位根据当前系统版号生成。</p>
        </div>
    );
    return (
        <div className="task-form-col-2">
            <div className="bold align-center mg-b-16">
                <div className="mg-r-9">内部ID</div>
                <Tooltip placement="bottomLeft" title={content}>
                    <Iconfont
                        type="icon-list_prompt"
                        className="icon-12 cursor-pointer"
                    />
                </Tooltip>
            </div>
            <Input
                className="input-sz-ml"
                placeholder="创建完成后自动生成"
                defaultValue={props.currentTask?.asset_uid || null}
                disabled
            />
        </div>
    );
};

const RenderSelect = ({ name, label, data }, currentTask) => {
    const message = "请选择" + label;
    const isPhotographers = name === "photographers";
    data = Array.isArray(data) ? data : Object.values(data);
    const photographers = isPhotographers && (currentTask[name] || [])?.map(item => item.id)
    let [selected, setSelected] = useState([]);
    const tagRender = (props) => {
        let { label, value } = props;
        const idx = selected.indexOf(value);
        const style = idx === 0 ? { marginLeft: "7px" } : {};
        label = selected.length > 1 && idx > 0 ? `/${label}` : label;
        return (
            <span key={label} style={style}>
                {label}
            </span>
        );
    };
    const validator = (_, value) => {
        if (!value) return Promise.reject(message)
        if (isPhotographers && value.length > 8) return Promise.reject("拍摄人员数量不能超过8个");
        return Promise.resolve()
    }

    const onChange = (val) => setSelected(val)

    return (
        <div className="task-form-col-2" key={name}>
            <div className="bold required mg-b-16">{label}</div>
            <Form.Item
                name={name}
                rules={[{ required: true, validator: validator }]}
                initialValue={
                    photographers || currentTask[name]?.code || currentTask[name]
                }
            >
                <Select
                    className="input-sz-ml"
                    placeholder={message}
                    size="large"
                    mode={isPhotographers ? "multiple" : null}
                    tagRender={tagRender}
                    maxTagCount={4}
                    onChange={isPhotographers ? onChange : () => { }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    showArrow
                >
                    {(data || [])?.map((item, idx) => (
                        <Option
                            value={isPhotographers ? item.user_id : item.code}
                            key={idx}
                        >
                            {isPhotographers ? item.name : item.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export const AssetComment = (props) => {
    return (
        <div className="process-form-col-1">
            <div className="mg-b-16 bold">备注</div>
            <Form.Item
                name="asset_comment"
                initialValue={props.currentTask.asset_comment}
            >
                <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="请输入备注"
                    style={{ width: "44vw" }}
                    maxLength={500}
                />
            </Form.Item>
        </div>
    );
};
