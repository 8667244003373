/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/22
 * @description: 作废统计明细
 */
import { Table } from '@components/table'
import { getDiscardLog, sessionAPI } from '@api'
import { Button, LazyLoadingImage, TaskDrawer } from '@components'
import PreviewImage from '@components/image/preview_image'
import { FilterForm } from '@views/4_stats/destroy/filterForm'
import moment from 'moment'
import { useHistory } from 'react-router'

export function momentArrDataToTimes(datas, key, unitOfTime = 'day') {
    if (datas && Array.isArray(datas)) {
        const times = {}
        const [start, end] = datas
        times[`${key}_start`] = moment(start)
            .startOf(unitOfTime)
            .format('YYYY-MM-DD HH:mm:ss')
        times[`${key}_end`] = moment(end)
            .endOf(unitOfTime)
            .format('YYYY-MM-DD HH:mm:ss')
        return times
    }
    return undefined
}

function request(page, pageSize, params) {
    const {
        create_time,
        discard_time,
        ...other
    } = params || {}
    return getDiscardLog({
        page,
        page_size: pageSize,
        ...momentArrDataToTimes(create_time, 'create_time'),
        ...momentArrDataToTimes(discard_time, 'discard_time'),
        ...other
    })
}

const DestroyStatisticsDetails = function () {
    const history = useHistory()
    const columns = [
        {
            title: '预览图',
            dataIndex: 'review_image',
            render(text, record) {
                const src = record.review_image
                const onPreview = function (src) {
                    PreviewImage.open({
                        images: [src],
                        request: (url) => sessionAPI.fetchOriginalImage(url)
                            .then(res => res.data?.origin_urls?.[0] || '')
                    })
                }
                return <LazyLoadingImage
                    preview={{
                        visible: false
                    }}
                    onClick={(event) => {
                        event.stopPropagation()
                        onPreview(src)
                    }}
                    width='60px'
                    height='60px'
                    src={src} />
            }
        },
        {
            title: '资产ID',
            dataIndex: 'asset_uid'
        },
        {
            title: '资产名称',
            dataIndex: 'asset_name'

        },
        {
            title: '创建时间',
            dataIndex: 'create_time'
        },
        {
            title: '作废人',
            dataIndex: 'operator_name'
        },
        {
            title: '作废类型',
            dataIndex: 'discard_type'
        },
        {
            title: '作废时间',
            dataIndex: 'discard_time'
        },
        {
            title: '项目来源',
            ellipsis: {
                showTitle: true
            },
            dataIndex: 'project_name'
        },
        {
            title: '备注',
            dataIndex: 'comment',
            ellipsis: true
        },
        {
            title: '操作',
            render(text, record) {
                return <Button type={'link'} onClick={() => {
                    history.push(`/stats/destroy/details?task_flow?taskId=${record.task_id}`)
                }}>
                    查看
                </Button>
            }
        }
    ]
    return <div className={'page-container'} style={{
        position: 'relative',
        height: 'calc(100VH - 66px)',
        overflow: 'hidden',
    }}>
        <div style={{ height: '100%', overflow: 'auto' }}>
            <Table
                renderFilterForm={(props) => <FilterForm {...props} />}
                url={'/api/task-statistic/discard-log'}
                request={request}
                columns={columns}
            />
        </div>
        <TaskDrawer />
    </div>

}
export default DestroyStatisticsDetails
