/**
 * @name: USerSelectFormField.jsx
 * @user: cfj
 * @date: 2022/4/13
 * @description: 选择分配人
 */
import { Select } from '@components';
import { getAssigneeList } from '@api';
import { forwardRef } from 'react';

function feature() {
    return getAssigneeList()
        .then((res) => {
            return res.statics?.map(item => ({
                ...item,
                value: item.user_simple_info.id,
                label: item.user_simple_info.name
            })) || [];
        });
}

export const USerSelectFormField = forwardRef(function(props, ref) {
    const {
        value,
        onChange
    } = props;
    return <Select
        ref={ref}
        showSearch
        showArrow
        value={value?.value}
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value, option) => onChange(option)}
        placeholder={'请选择分配制作人'}
        setKey={() => '/api/produce/users'}
        feature={feature}
    />;
});
