import React, { Component } from "react";
import { Form, Select } from 'antd'
const { Option } = Select
class FormSelect extends Component {
    render() {
        const { name, label, options } = this.props;
        const placeholder = `请选择${label}`;
        return (
            <Form.Item>
                <div className="align-center">
                    <span className="bold mg-r-10">{label}</span>
                    <Form.Item name={name} key={name}>
                        <Select
                            className="filter-input"
                            size="large"
                            placeholder={placeholder}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            showArrow
                        >
                            {options?.map((item) => (
                                <Option key={item.code} value={item.code}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </Form.Item>
        );
    }
}
export default FormSelect;
