// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.common.AssetCloseStatus', null, global);
goog.exportSymbol('proto.common.AssetExtendSize', null, global);
goog.exportSymbol('proto.common.AssetIsCloseOrNot', null, global);
goog.exportSymbol('proto.common.CommonFileItem', null, global);
goog.exportSymbol('proto.common.CoreBusinessType', null, global);
goog.exportSymbol('proto.common.DigitalAssetReviewItem', null, global);
goog.exportSymbol('proto.common.EnabledStatus', null, global);
goog.exportSymbol('proto.common.FileUseType', null, global);
goog.exportSymbol('proto.common.FinishPrerequisite', null, global);
goog.exportSymbol('proto.common.GodownEntryExpandFileType', null, global);
goog.exportSymbol('proto.common.GodownEntryStatus', null, global);
goog.exportSymbol('proto.common.GodownFileType', null, global);
goog.exportSymbol('proto.common.GroupUserType', null, global);
goog.exportSymbol('proto.common.HitCaseKeyType', null, global);
goog.exportSymbol('proto.common.HitCaseValue', null, global);
goog.exportSymbol('proto.common.MaterialType', null, global);
goog.exportSymbol('proto.common.NotifyMessageTemplate', null, global);
goog.exportSymbol('proto.common.NotifyUserChannel', null, global);
goog.exportSymbol('proto.common.Pagination', null, global);
goog.exportSymbol('proto.common.PreRequisiteType', null, global);
goog.exportSymbol('proto.common.ProjectConfidentialType', null, global);
goog.exportSymbol('proto.common.ProjectStatus', null, global);
goog.exportSymbol('proto.common.SchedulerBusinessType', null, global);
goog.exportSymbol('proto.common.SchedulerButtonColorType', null, global);
goog.exportSymbol('proto.common.SchedulerHitCase', null, global);
goog.exportSymbol('proto.common.SchedulerNotifyUserType', null, global);
goog.exportSymbol('proto.common.SchedulerOperationNextStepType', null, global);
goog.exportSymbol('proto.common.SchedulerOperationPrevStepType', null, global);
goog.exportSymbol('proto.common.SchedulerOperationType', null, global);
goog.exportSymbol('proto.common.ShootingPlanStatus', null, global);
goog.exportSymbol('proto.common.SysRolePermissionType', null, global);
goog.exportSymbol('proto.common.SysSettingType', null, global);
goog.exportSymbol('proto.common.TaskAssignActive', null, global);
goog.exportSymbol('proto.common.TaskConfidentialType', null, global);
goog.exportSymbol('proto.common.TaskSplitStatus', null, global);
goog.exportSymbol('proto.common.TopicCategory', null, global);
goog.exportSymbol('proto.common.UserPasswordSecurityStatus', null, global);
goog.exportSymbol('proto.common.UserStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Pagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Pagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Pagination.displayName = 'proto.common.Pagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CommonFileItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CommonFileItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CommonFileItem.displayName = 'proto.common.CommonFileItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.NotifyMessageTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.NotifyMessageTemplate.repeatedFields_, null);
};
goog.inherits(proto.common.NotifyMessageTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.NotifyMessageTemplate.displayName = 'proto.common.NotifyMessageTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SchedulerHitCase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SchedulerHitCase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SchedulerHitCase.displayName = 'proto.common.SchedulerHitCase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.DigitalAssetReviewItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.DigitalAssetReviewItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.DigitalAssetReviewItem.displayName = 'proto.common.DigitalAssetReviewItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AssetExtendSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AssetExtendSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AssetExtendSize.displayName = 'proto.common.AssetExtendSize';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Pagination.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Pagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Pagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Pagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isTotal: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Pagination}
 */
proto.common.Pagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Pagination;
  return proto.common.Pagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Pagination}
 */
proto.common.Pagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Pagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Pagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIsTotal();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.common.Pagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Pagination} returns this
 */
proto.common.Pagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.common.Pagination.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Pagination} returns this
 */
proto.common.Pagination.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_total = 3;
 * @return {boolean}
 */
proto.common.Pagination.prototype.getIsTotal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Pagination} returns this
 */
proto.common.Pagination.prototype.setIsTotal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CommonFileItem.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CommonFileItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CommonFileItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommonFileItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileUid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    useType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CommonFileItem}
 */
proto.common.CommonFileItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CommonFileItem;
  return proto.common.CommonFileItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CommonFileItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CommonFileItem}
 */
proto.common.CommonFileItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUid(value);
      break;
    case 1:
      var value = /** @type {!proto.common.FileUseType} */ (reader.readEnum());
      msg.setUseType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CommonFileItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CommonFileItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CommonFileItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CommonFileItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional string file_path = 2;
 * @return {string}
 */
proto.common.CommonFileItem.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CommonFileItem} returns this
 */
proto.common.CommonFileItem.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_url = 3;
 * @return {string}
 */
proto.common.CommonFileItem.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CommonFileItem} returns this
 */
proto.common.CommonFileItem.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_uid = 4;
 * @return {string}
 */
proto.common.CommonFileItem.prototype.getFileUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CommonFileItem} returns this
 */
proto.common.CommonFileItem.prototype.setFileUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional FileUseType use_type = 1;
 * @return {!proto.common.FileUseType}
 */
proto.common.CommonFileItem.prototype.getUseType = function() {
  return /** @type {!proto.common.FileUseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.FileUseType} value
 * @return {!proto.common.CommonFileItem} returns this
 */
proto.common.CommonFileItem.prototype.setUseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.NotifyMessageTemplate.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.NotifyMessageTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.common.NotifyMessageTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.NotifyMessageTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.NotifyMessageTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    notifyUserType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    notifyTemplate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    channelsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.NotifyMessageTemplate}
 */
proto.common.NotifyMessageTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.NotifyMessageTemplate;
  return proto.common.NotifyMessageTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.NotifyMessageTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.NotifyMessageTemplate}
 */
proto.common.NotifyMessageTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.SchedulerNotifyUserType} */ (reader.readEnum());
      msg.setNotifyUserType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotifyTemplate(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.common.NotifyUserChannel>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.NotifyMessageTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.NotifyMessageTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.NotifyMessageTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.NotifyMessageTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotifyUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNotifyTemplate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
};


/**
 * optional SchedulerNotifyUserType notify_user_type = 1;
 * @return {!proto.common.SchedulerNotifyUserType}
 */
proto.common.NotifyMessageTemplate.prototype.getNotifyUserType = function() {
  return /** @type {!proto.common.SchedulerNotifyUserType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.SchedulerNotifyUserType} value
 * @return {!proto.common.NotifyMessageTemplate} returns this
 */
proto.common.NotifyMessageTemplate.prototype.setNotifyUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string notify_template = 2;
 * @return {string}
 */
proto.common.NotifyMessageTemplate.prototype.getNotifyTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.NotifyMessageTemplate} returns this
 */
proto.common.NotifyMessageTemplate.prototype.setNotifyTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated NotifyUserChannel channels = 9;
 * @return {!Array<!proto.common.NotifyUserChannel>}
 */
proto.common.NotifyMessageTemplate.prototype.getChannelsList = function() {
  return /** @type {!Array<!proto.common.NotifyUserChannel>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.common.NotifyUserChannel>} value
 * @return {!proto.common.NotifyMessageTemplate} returns this
 */
proto.common.NotifyMessageTemplate.prototype.setChannelsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.common.NotifyUserChannel} value
 * @param {number=} opt_index
 * @return {!proto.common.NotifyMessageTemplate} returns this
 */
proto.common.NotifyMessageTemplate.prototype.addChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.NotifyMessageTemplate} returns this
 */
proto.common.NotifyMessageTemplate.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SchedulerHitCase.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SchedulerHitCase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SchedulerHitCase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SchedulerHitCase.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stepCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SchedulerHitCase}
 */
proto.common.SchedulerHitCase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SchedulerHitCase;
  return proto.common.SchedulerHitCase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SchedulerHitCase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SchedulerHitCase}
 */
proto.common.SchedulerHitCase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SchedulerHitCase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SchedulerHitCase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SchedulerHitCase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SchedulerHitCase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStepCode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.common.SchedulerHitCase.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SchedulerHitCase} returns this
 */
proto.common.SchedulerHitCase.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.SchedulerHitCase.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SchedulerHitCase} returns this
 */
proto.common.SchedulerHitCase.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 step_code = 3;
 * @return {number}
 */
proto.common.SchedulerHitCase.prototype.getStepCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.SchedulerHitCase} returns this
 */
proto.common.SchedulerHitCase.prototype.setStepCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.DigitalAssetReviewItem.prototype.toObject = function(opt_includeInstance) {
  return proto.common.DigitalAssetReviewItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.DigitalAssetReviewItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DigitalAssetReviewItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    digitalAssetId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reviewImageType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.DigitalAssetReviewItem}
 */
proto.common.DigitalAssetReviewItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.DigitalAssetReviewItem;
  return proto.common.DigitalAssetReviewItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.DigitalAssetReviewItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.DigitalAssetReviewItem}
 */
proto.common.DigitalAssetReviewItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigitalAssetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewImageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.DigitalAssetReviewItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.DigitalAssetReviewItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.DigitalAssetReviewItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.DigitalAssetReviewItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDigitalAssetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReviewImageType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string file_url = 1;
 * @return {string}
 */
proto.common.DigitalAssetReviewItem.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DigitalAssetReviewItem} returns this
 */
proto.common.DigitalAssetReviewItem.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_path = 2;
 * @return {string}
 */
proto.common.DigitalAssetReviewItem.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DigitalAssetReviewItem} returns this
 */
proto.common.DigitalAssetReviewItem.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.common.DigitalAssetReviewItem.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DigitalAssetReviewItem} returns this
 */
proto.common.DigitalAssetReviewItem.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string digital_asset_id = 4;
 * @return {string}
 */
proto.common.DigitalAssetReviewItem.prototype.getDigitalAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DigitalAssetReviewItem} returns this
 */
proto.common.DigitalAssetReviewItem.prototype.setDigitalAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string review_image_type = 5;
 * @return {string}
 */
proto.common.DigitalAssetReviewItem.prototype.getReviewImageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.DigitalAssetReviewItem} returns this
 */
proto.common.DigitalAssetReviewItem.prototype.setReviewImageType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AssetExtendSize.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AssetExtendSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AssetExtendSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AssetExtendSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AssetExtendSize}
 */
proto.common.AssetExtendSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AssetExtendSize;
  return proto.common.AssetExtendSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AssetExtendSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AssetExtendSize}
 */
proto.common.AssetExtendSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AssetExtendSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AssetExtendSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AssetExtendSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AssetExtendSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double length = 1;
 * @return {number}
 */
proto.common.AssetExtendSize.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AssetExtendSize} returns this
 */
proto.common.AssetExtendSize.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double width = 2;
 * @return {number}
 */
proto.common.AssetExtendSize.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AssetExtendSize} returns this
 */
proto.common.AssetExtendSize.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double height = 3;
 * @return {number}
 */
proto.common.AssetExtendSize.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.AssetExtendSize} returns this
 */
proto.common.AssetExtendSize.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.common.ProjectConfidentialType = {
  PROJECT_CONFIDENTIAL_TYPE_UNKNOWN: 0,
  PROJECT_CONFIDENTIAL_TYPE_PRIVATE: 1
};

/**
 * @enum {number}
 */
proto.common.ProjectStatus = {
  PROJECT_STATUS_UNKNOWN: 0,
  PROJECT_STATUS_IN_PLAN: 1,
  PROJECT_STATUS_IN_PROGRESS: 2,
  PROJECT_STATUS_COMPLETE: 3
};

/**
 * @enum {number}
 */
proto.common.ShootingPlanStatus = {
  SHOOTING_PLAN_STATUS_UNKNOWN: 0,
  SHOOTING_PLAN_STATUS_IN_PLAN: 1,
  SHOOTING_PLAN_STATUS_TO_EXAMINE: 2,
  SHOOTING_PLAN_STATUS_REJECT: 3,
  SHOOTING_PLAN_STATUS_AGREE: 4
};

/**
 * @enum {number}
 */
proto.common.SysSettingType = {
  SYS_SETTING_TYPE_UNKNOWN: 0,
  SYS_SETTING_TYPE_TRIP: 1,
  SYS_SETTING_TYPE_TRAFFIC: 2,
  SYS_SETTING_TYPE_FUND: 3,
  SYS_SETTING_TYPE_ASSET_TYPE: 4,
  SYS_SETTING_TYPE_ASSET_CATEGORY: 5
};

/**
 * @enum {number}
 */
proto.common.TaskConfidentialType = {
  TASK_CONFIDENTIAL_TYPE_UNKNOWN: 0,
  TASK_CONFIDENTIAL_TYPE_PUBLIC: 1
};

/**
 * @enum {number}
 */
proto.common.TaskSplitStatus = {
  TASK_SPLIT_STATUS_UNKNOWN: 0,
  TASK_SPLIT_STATUS_UN_SPLIT: 1,
  TASK_SPLIT_STATUS_HAD_SPLIT: 2
};

/**
 * @enum {number}
 */
proto.common.EnabledStatus = {
  ENABLED_STATUS_UNKNOWN: 0,
  ENABLED_STATUS_ENABLED: 1,
  ENABLED_STATUS_DISABLED: 2
};

/**
 * @enum {number}
 */
proto.common.NotifyUserChannel = {
  NOTIFY_USER_CHANNEL_UNKNOWN: 0,
  NOTIFY_USER_CHANNEL_RABBITMQ: 1,
  NOTIFY_USER_CHANNEL_EMAIL: 2,
  NOTIFY_USER_CHANNEL_WECHAT: 3
};

/**
 * @enum {number}
 */
proto.common.FinishPrerequisite = {
  FINISH_PREREQUISITE_UNKNOWN: 0,
  FINISH_PREREQUISITE_ONCE: 1,
  FINISH_PREREQUISITE_TOTAL: 2
};

/**
 * @enum {number}
 */
proto.common.TaskAssignActive = {
  TASK_ASSIGN_ACTIVE_UNKNOWN: 0,
  TASK_ASSIGN_ACTIVE_INVALID: 1,
  TASK_ASSIGN_ACTIVE_DOWN: 2,
  TASK_ASSIGN_ACTIVE_TODO: 3
};

/**
 * @enum {number}
 */
proto.common.GodownFileType = {
  GODOWN_FILE_TYPE_UNKNOWN: 0,
  GODOWN_FILE_TYPE_TEXTURE: 1,
  GODOWN_FILE_TYPE_OTHER: 2
};

/**
 * @enum {number}
 */
proto.common.AssetIsCloseOrNot = {
  ASSET_IS_CLOSE_OR_NOT_UNKNOWN: 0,
  ASSET_IS_CLOSE_OR_NOT_CLOSED: 1,
  ASSET_IS_CLOSE_OR_NOT_OPENING: 2
};

/**
 * @enum {number}
 */
proto.common.SchedulerNotifyUserType = {
  SCHEDULER_NOTIFY_USER_TYPE_UNKNOWN: 0,
  SCHEDULER_NOTIFY_USER_TYPE_ASSIGN: 1,
  SCHEDULER_NOTIFY_USER_TYPE_SUBMITTER: 2
};

/**
 * @enum {number}
 */
proto.common.SchedulerBusinessType = {
  SCHEDULER_BUSINESS_TYPE_UNKNOWN: 0,
  SCHEDULER_BUSINESS_TYPE_TASK: 1,
  SCHEDULER_BUSINESS_TYPE_TOPIC: 2,
  SCHEDULER_BUSINESS_TYPE_TOPIC_SCENE: 3
};

/**
 * @enum {number}
 */
proto.common.CoreBusinessType = {
  CORE_BUSINESS_TYPE_UNKNOWN: 0,
  CORE_BUSINESS_TYPE_TASK: 1,
  CORE_BUSINESS_TYPE_TOPIC: 2,
  CORE_BUSINESS_TYPE_TOPIC_SCENE: 3,
  CORE_BUSINESS_TYPE_TOPIC_SCENE_TASK_PACKAGE: 4,
  CORE_BUSINESS_TYPE_TOPIC_SCENE_PROJECT: 5,
  CORE_BUSINESS_TYPE_TOPIC_SCENE_ANIMATOR_PACKAGE: 6
};

/**
 * @enum {number}
 */
proto.common.UserStatus = {
  USER_STATUS_UNKNOWN: 0,
  USER_STATUS_ENABLED: 1,
  USER_STATUS_DISABLED: 2
};

/**
 * @enum {number}
 */
proto.common.UserPasswordSecurityStatus = {
  USER_PASSWORD_SECURITY_STATUS_UNKNOWN: 0,
  USER_PASSWORD_SECURITY_STATUS_NORMAL: 1,
  USER_PASSWORD_SECURITY_STATUS_NEED_INITIALIZE: 2
};

/**
 * @enum {number}
 */
proto.common.GodownEntryExpandFileType = {
  GODOWN_ENTRY_EXPAND_FILE_TYPE_UNKNOWN: 0,
  GODOWN_ENTRY_EXPAND_FILE_TYPE_SCATTER: 1,
  GODOWN_ENTRY_EXPAND_FILE_TYPE_OPACITY: 2,
  GODOWN_ENTRY_EXPAND_FILE_TYPE_FUZZ: 3
};

/**
 * @enum {number}
 */
proto.common.GodownEntryStatus = {
  GODOWN_ENTRY_STATUS_UNKNOWN: 0,
  GODOWN_ENTRY_STATUS_WAIT_ANALYSIS: 1,
  GODOWN_ENTRY_STATUS_ANALYSING: 2,
  GODOWN_ENTRY_STATUS_SUCCESS: 3,
  GODOWN_ENTRY_STATUS_FAILED: 4
};

/**
 * @enum {number}
 */
proto.common.MaterialType = {
  MATERIAL_TYPE_UNKNOWN: 0,
  MATERIAL_TYPE_PROJECT: 1,
  MATERIAL_TYPE_COMMON: 2
};

/**
 * @enum {number}
 */
proto.common.AssetCloseStatus = {
  ASSET_CLOSE_STATUS_UNSPECIFIED: 0,
  ASSET_CLOSE_STATUS_CLOSED: 1,
  ASSET_CLOSE_STATUS_OPENING: 2
};

/**
 * @enum {number}
 */
proto.common.PreRequisiteType = {
  PRE_REQUISITE_TYPE_UNKNOWN: 0,
  PRE_REQUISITE_TYPE_ONCE: 1,
  PRE_REQUISITE_TYPE_TOTAL: 2
};

/**
 * @enum {number}
 */
proto.common.SchedulerOperationPrevStepType = {
  SCHEDULER_OPERATION_PREV_STEP_TYPE_UNKNOWN: 0,
  SCHEDULER_OPERATION_PREV_STEP_TYPE_NORMAL: 1,
  SCHEDULER_OPERATION_PREV_STEP_TYPE_NO_EXISTS: 2
};

/**
 * @enum {number}
 */
proto.common.SchedulerOperationNextStepType = {
  SCHEDULER_OPERATION_NEXT_STEP_TYPE_UNKNOWN: 0,
  SCHEDULER_OPERATION_NEXT_STEP_TYPE_NORMAL: 1,
  SCHEDULER_OPERATION_NEXT_STEP_TYPE_APPOINT: 2,
  SCHEDULER_OPERATION_NEXT_STEP_TYPE_INVOLVE: 3,
  SCHEDULER_OPERATION_NEXT_STEP_TYPE_CASE: 4
};

/**
 * @enum {number}
 */
proto.common.SchedulerOperationType = {
  SCHEDULER_OPERATION_TYPE_UNKNOWN: 0,
  SCHEDULER_OPERATION_TYPE_NORMAL: 1,
  SCHEDULER_OPERATION_TYPE_PERMISSION: 2
};

/**
 * @enum {number}
 */
proto.common.TopicCategory = {
  TOPIC_CATEGORY_UNKNOWN: 0,
  TOPIC_CATEGORY_NORMAL: 1,
  TOPIC_CATEGORY_SCENE: 2,
  TOPIC_CATEGORY_TASK_PACKAGE: 3,
  TOPIC_CATEGORY_PROJECT: 4
};

/**
 * @enum {number}
 */
proto.common.HitCaseKeyType = {
  HIT_CASE_KEY_TYPE_UNSPECIFIED: 0,
  HIT_CASE_KEY_TYPE_TASK_TYPE: 1
};

/**
 * @enum {number}
 */
proto.common.HitCaseValue = {
  HIT_CASE_VALUE_UNSPECIFIED: 0,
  HIT_CASE_VALUE_PLAN: 1,
  HIT_CASE_VALUE_SELF_CREATED: 2
};

/**
 * @enum {number}
 */
proto.common.FileUseType = {
  FILE_USE_TYPE_UNSPECIFIED: 0,
  FILE_USE_TYPE_MAIN: 1,
  FILE_USE_TYPE_ASSIST: 2
};

/**
 * @enum {number}
 */
proto.common.SysRolePermissionType = {
  SYS_ROLE_PERMISSION_TYPE_UNSPECIFIED: 0,
  SYS_ROLE_PERMISSION_TYPE_FULL_PERMISSION: 1,
  SYS_ROLE_PERMISSION_TYPE_NORMAL: 2
};

/**
 * @enum {number}
 */
proto.common.SchedulerButtonColorType = {
  SCHEDULER_BUTTON_COLOR_TYPE_UNSPECIFIED: 0,
  SCHEDULER_BUTTON_COLOR_TYPE_MAIN: 1,
  SCHEDULER_BUTTON_COLOR_TYPE_HELPER: 2
};

/**
 * @enum {number}
 */
proto.common.GroupUserType = {
  GROUP_USER_TYPE_UNSPECIFIED: 0,
  GROUP_USER_TYPE_LEADER: 1,
  GROUP_USER_TYPE_MEMBER: 2
};

goog.object.extend(exports, proto.common);
