import React, { Component } from 'react'

class DrawerFooter extends Component {
    render() {
        const {children} = this.props
        return !!children ? (
            <div className="drawer-footer">{children}</div>
        ) : "";
    }
}

export default DrawerFooter
