import React, { Component } from "react";
import { BaseDrawer, AuthButton, $error, AuthTabs, AuthTabPane } from "@components";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { TitleSection, DragDropArea } from ".";

@withRouter
@inject("taskGroupsStore")
@observer
class TaskGroupsDrawer extends Component {
    state = {
        activeType: "1"
    }
    get store() {
        return this.props.taskGroupsStore;
    }
    get projectId() {
        return parseInt(this.props.match.params.id);
    }

    componentDidMount() {
        this.setUpDrawer();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location !== this.props.location) {
            this.setUpDrawer();
        }
    }

    setUpDrawer = () => {
        const { location, match } = this.props;
        const id = parseInt(match.params.id);
        this.store.updateProjectId(id);
        if (location.search.includes("?task-groups")) {
            this.show();
            this.store.fetchTaskGroups(this.state.activeType);
            this.store.fetchUngroupedAssets(this.state.activeType);
        } else {
            this.hide();
        }
    };

    show = () => {
        this.props.taskGroupsStore.show();
    };

    hide = () => {
        this.props.taskGroupsStore.hide();
    };

    onClose = () => {
        const projectId = JSON.parse(this.props.match.params.id);
        this.props.history.replace(`/projects/${projectId}`);
    };

    handleCancel = () => {
        this.onClose();
        this.store.clear();
    };

    handleSave = () => {
        const emptyName = this.store.groups.some((item) => !item.group_name);
        if (this.store.groupedIds.length < 0) {
            return $error("请先添加数据");
        } else if (emptyName) {
            return $error("请填写所有组合名称");
        }
        this.store.updateGroupsRequest(this.state.activeType);
        this.updateAllLists();
    };

    updateAllLists = () => {
        Object.values(this.props.listTypes || {}).forEach(({code}) => {
            let filter = this.props.filter || {};
            const params = {
                list_type: JSON.parse(code),
                page: 1,
                page_size: 5,
                project_id: this.projectId,
                filter,
            };
            this.props.fetchTasks(params);
        })
    };
    /**
     * 切换tabs
     * @param {*} key tabs value
     */
    handleChange = (key) => {
        this.setState({
            activeType: key,
        }, () => {
            this.setUpDrawer()
        })
    };

    render() {
        const { actionState } = this.store;
        return (
            <BaseDrawer
                visible={this.store.visible}
                onClose={this.onClose}
                width={1052}
                className="group-drawer"
            >
                <div>
                    <div className="flex">
                        <AuthTabs
                            onChange={this.handleChange}
                            tabBarGutter={100}
                            activeKey={this.state.activeType}
                            permission=""
                        >
                            <AuthTabPane key="1" tab="组合资产" />
                            <AuthTabPane key="2" tab="组合任务" />
                        </AuthTabs>
                    </div>
                    <TitleSection {...this.state} />
                    <DragDropArea {...this.state} />
                    <div className="drawer-footer">
                        <AuthButton
                            type="primary"
                            className="mg-r-12"
                            onClick={this.handleSave}
                            loading={actionState === "pending"}
                        >
                            保存
                        </AuthButton>
                        <AuthButton onClick={this.handleCancel}>取消</AuthButton>
                    </div>
                </div>
            </BaseDrawer>
        );
    }
}
export default TaskGroupsDrawer;
