import React, { Component } from 'react';
import { formatTime } from '@/utils/global/functional_util';
import { DateRangesFilter, SelectFilter, InputFilter, SplitSelect } from './renders';
import { AuthButton } from '@/components';
import { observer, inject } from 'mobx-react';
import { debounce } from '@gUtils';

const initialState = {
    uid_name: '',
    produced_at: {},
    asset_type: null,
    asset_category: null,
    project_name: '',
    is_split: null,
};

@inject('filterStore')
@observer
class AssetFilter extends Component {
    state = initialState;

    get store() {
        return this.props.filterStore;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.store.clearList();
            this.store.setPage();
            this.store.setFlag();
            this.store.clearWaterFallList();
            const params = this.getParams();
            this.debounceSetFilter(params);
        }
        if (this.store.clearFlag) {
            this.store.clearFlag = false;
            this.clear();
        }
    }

    // 刷新请求防抖函数
    debounceSetFilter = debounce((params) => {
        // showWaterFall 为 true 表格数据，false 瀑布流数据
        if(this.store.showWaterFall){
            this.props.fetchAssets(params)
        }else{
            // 非表格展示，同时更新表格的筛选数据
            this.props.requestAssets(params)
        }
        // 更新瀑布流筛选数据，filter改变会触发瀑布流接口请求
        this.store.setFilter(params?.filter)
    }, 300);

    // functions
    clear = () => {
        this.setState(initialState);
    };

    setStateFunc = (key, val) => {
        this.setState({ [key]: val });
    };

    getParams = () => {
        let { project_name, uid_name, asset_type, asset_category,is_split } = this.state;
        let params = {
            page: 1,
            filter: {
                uid_name,
                produced_at: formatTime(this.state.produced_at),
                asset_type,
                asset_category,
                project_name,
                is_split
            },
            page_size: 6
        };
        return params;
    };

    render() {
        const { showWaterFall } = this.store;
        return (
            <div className="project-filters">
                <div className="display-flex">
                    <InputFilter state={this.state} setStateFunc={this.setStateFunc} />
                    <SelectFilter state={this.state} setStateFunc={this.setStateFunc} configs={this.props.configs} />
                    <DateRangesFilter state={this.state} setStateFunc={this.setStateFunc} />
                    <SplitSelect state={this.state} setStateFunc={this.setStateFunc} />
                </div>
                <div className="reset-filter" onClick={this.clear}>
                    重置
                </div>
                <div className="waterfall-button">
                    <AuthButton
                        type="primary"
                        permission="asset_waterfall"
                        onClick={() => {
                            this.store.setPage();
                            this.store.clearList();
                            this.store.toggleShowWaterFall();
                            this.store.clearWaterFallList();
                            const params = this.getParams();
                            this.store.setFlag();
                            this.store.setParamsObj(params);
                        }}>
                        {showWaterFall ? ' 切换瀑布流' : '切换列表'}
                    </AuthButton>
                </div>
            </div>
        );
    }
}

export default AssetFilter;
