import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { Iconfont } from "@/components";
import { Table } from "antd";
import TripList from "./trip_list"
import TripEdit from "./trip_edit"
import "./index.less"

@inject("taskPackageStore")
@observer
class TripContent extends Component {
    state = {
        list: this.props.list || [],
        activeIndex: -1,
    }

    constructor(props) {
        super(props);
        props.taskPackageStore.getTripCategory();
        props.taskPackageStore.getTrafficMode();
    }
    updateList() {
        this.props.onChange(this.state.list);
    }
    addItem = (item) => {
        this.props.form.setFieldsValue({
            trips: item
        })
        this.setState(() => {
            return {
                ...this.state,
                list: [...this.state.list, item]
            }
        }, () => {
            this.updateList();
        });
    }
    handleUpdate = (item) => {
        this.state.list.splice(this.state.activeIndex, 1, item)
        this.setState(() => {
            return {
                ...this.state,
                list: this.state.list.slice(0),
            }
        }, () => {
            this.updateList();
        });
        this.resetActiveIndex();
    }
    handleChange = (id) => {
        this.setState(() => {
            return {
                ...this.state,
                activeIndex: id,
            }
        })
    }
    resetActiveIndex = () => {
        this.setState(() => {
            return {
                ...this.state,
                activeIndex: -1,
            }
        })
    }

    handleDelete = (idx) => {
        this.state.list.splice(idx, 1)
        this.setState(() => {
            return {
                ...this.state,
                list: this.state.list.slice(0),
            }
        }, () => {
            this.updateList();
        })
    }

    render() {
        const { trafficMode, tripCategory } = this.props.taskPackageStore;
        let typeMap ={};
        tripCategory.forEach(item => {
            typeMap[item.id] = item;
        });
        let tripMap ={};
        trafficMode.forEach(item => {
            tripMap[item.id] = item;
        });
        const columnsList = [
            {
                title: "日期",
                width: "26.86%",
                dataIndex: 'time',
                render: (item, row) => {
                    // return item[0].format('YYYY/MM/DD') +'-' + item[1].format('YYYY/MM/DD');
                    return row.start_time +'-' + row.end_time;
                },
                className: "bg-white",
            },{
                title: "行程类型",
                width: "11.91%",
                dataIndex: 'trip_type',
                render(item) {
                    return (typeMap || {})[item]?.value || '';
                },
                className: "bg-white",
            },{
                title: "目的地",
                width: "17.14%",
                dataIndex: 'destination',
                className: "bg-white",
            },{
                title: "交通方式",
                width: "11.91%",
                dataIndex: 'transportation',
                render(item) {
                    return (tripMap || {})[item]?.value || '';
                },
                className: "bg-white",
            },{
                title: "行程说明",
                dataIndex: 'comment',
                className: "bg-white",
            }, {
                width: "90px",
                dataIndex: 'operator',
                render: (text, row, idx) => {
                    return (
                        <div>
                            <span onClick={() => {
                                if (this.state.activeIndex < 0){
                                    let _idx = this.state.list.findIndex(item => {
                                        if (item === row) {
                                            return true
                                        }
                                        return false;
                                    })
                                    row.isEdit = true;
                                    this.handleChange(_idx);
                                }
                            }}>
                                <Iconfont type="icon-bianji" className="icon-30 amp-edit-icon"></Iconfont>
                            </span>
                            <span onClick={() => {
                                if (this.state.activeIndex < 0){
                                    let _idx = this.state.list.findIndex(item => {
                                        if (item === row) {
                                            return true
                                        }
                                        return false;
                                    })
                                    this.handleDelete(_idx);
                                }
                            }}>
                                <Iconfont type="icon-a-30-jian" className="icon-30 amp-decrease-icon"></Iconfont>
                            </span>
                        </div>
                    )
                },
                className: "bg-white",
            }
        ]

        return (
            <div className='amps-trip-content'>
                <Table
                    className='amp-content-table amp-list-table'
                    footer={null}
                    dataSource={[]}
                    columns={columnsList}
                    pagination={false}
                    locale={{
                        emptyText() {
                            return '';
                        }
                    }}
                ></Table>
                <TripEdit
                    form={this.props.form}
                    typeList={tripCategory}
                    tripType={trafficMode}
                    onSubmit={this.addItem}
                />
                <TripList
                    activeIndex={this.state.activeIndex}
                    columnsList={columnsList}
                    list={this.state.list}
                    typeList={tripCategory}
                    tripType={trafficMode}
                    onSuccess={() => {
                        this.resetActiveIndex()
                    }}
                    onConfirm={this.handleUpdate}
                />
            </div>
        )
    }
}

export default TripContent;
