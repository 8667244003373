import * as React from 'react'
import ReviewFilter from './filter'
import ReviewTable from './table'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'
import moment from 'moment'

@withRouter
@inject("reviewStore")
@observer
class Approve extends React.Component {
  get store() {
    return this.props.reviewStore;
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  get paramFilter() {
    const { search } = this.props.location;
    const filter =
      search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
    return filter || {};
  }
  componentDidMount() {
    this.setupDate();
    this.store.fetchArtsReviewUsers({filter: {type: 10}});
    this.store.fetchResReviewUsers({filter: {type: 11}});
  }

  setupDate = () => {
    const { date } = this.store;
    if (date) {
      const res = date?.map((item) => moment(item));
      this.store.updateDate(res);
    } else {
      this.setDefaultdate();
    }
  };
  setDefaultdate = () => {
    let filter, date;
    date = [moment().subtract(60, "months"), moment()];
    const res = date?.map((item) => moment(item));
    this.store.updateDate(res);
    filter = { date };
  };
  render() {
    return (
      <div className={'page-container'}>
        <ReviewFilter setDefaultdate={this.setDefaultdate} setupDate={this.setupDate}/>
        <ReviewTable {...this.props} />
      </div>
    );
  }
}
export default Approve;
