import axios from 'axios'

const productionStatsAPI = {
    getProductionReport(date, dateType) {
        return axios.get(
            `/produce-stats/statistics?date=${date}&date_type=${dateType}`
        );
    },
    fetchProductionSummary(params) {
        return axios.post("/produce-stats/summary", params);
    },
    fetchProductionUserSummary(params) {
        return axios.post("/produce-stats/user-summary", params);
    },
    fetchProductionDetails(params) {
        return axios.post("/produce-stats/detail", params);
    },
    fetchProductionReviews(params) {
        return axios.post("/produce-stats/review-detail", params);
    },
    /**
       * @method 工作实时统计-总览
       * @param {*} params
       */
    getOverviewRealTime(params) {
        return axios.get("/api/real-time/overview", { params });
    },
    /**
       * @method 工作实时统计-项目情况
       * @param {*} params
       */
    getProjectInfosRealTime(params) {
        return axios.post("/api/real-time/project-infos", params);
    },
    /**
       * @method 工作实时统计-任务情况
       * @param {*} params
       */
    getTaskInfosRealTime(params) {
        return axios.post("/api/real-time/task-infos", params);
    },
};

export default productionStatsAPI
