import React from "react";
import { Collapse, } from "@components"
import { Timeline } from "antd";

const TaskProcess = (props) => {
    const { PackageHistoryData } = props
    return (
        <Collapse header="任务包流程" fontWeight="fw-500" opened={true}>
            <div className="amp-task-package-region">
                {
                    PackageHistoryData && (
                        PackageHistoryData.map((item, index) => {
                            const flag = (item.action === "create_package" || item.action === "update_package")
                            const title = item.action === "create_package" ? "创建任务包" : (item.action === "update_package" ? "更新任务包" : item.history_data.operation)
                            return (

                                <Collapse header={title} opened={true} fontWeight="fw-400" key={item.id}>
                                    <Timeline.Item>
                                        <div>{item.updated_at}</div>
                                        <div className="amp-task-package-process">
                                            <span className="amp-task-package-process-title">操作人：
                                                {flag ? item.history_data.creator.name : item.history_data.operator} </span>
                                            <span className="amp-task-package-process-title">操作类型：
                                                {title}</span>
                                        </div>
                                    </Timeline.Item>
                                </Collapse>

                            )

                        })
                    )
                }
            </div>
        </Collapse>
    )
}


export default TaskProcess
