import React, { memo, useState, useEffect } from 'react';
import { AuthTabs, AuthTabPane } from "@/components";
import TargetConfig from "./target_config"
import './index.less'

const tabs = [
    { key: '1', title: "目标录入", permission: "performance_goal_query" },
    // { key: '2', title: "附加系数" }
];

const Target = (props) => {
    const [tab, setTab] = useState('1')

    return (
        <div className="amp-settings-target">
            <AuthTabs
                defaultActiveKey="1"
                onChange={(key) => setTab(key)}
                tabBarGutter={60}
                permission="performance_goal_manage"
            >
                {
                    tabs?.map((tab) => (
                        <AuthTabPane
                            key={tab.key}
                            tab={tab.title}
                            permission={tab.permission} />
                    ))
                }
            </AuthTabs>
            <TargetConfig tab={tab} />
        </div>
    )
};

export default memo(Target);
