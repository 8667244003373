import { combineReducers } from "redux";
import { projectsPagination } from "./projects/projects_page_reducer";
import { tasksPagination } from "./tasks/tasks_page_reducer";
import { userPagination } from "./users/user_page_reducer";
import { rolePagination } from "./roles/role_page_reducer";
import { announcementsPagination } from "./announcements/announcements_page_reducer";
import { assetsPagination } from "./assets/assets_page_reducer";

const paginationReducer = combineReducers({
  projects: projectsPagination,
  tasks: tasksPagination,
  users: userPagination,
  roles: rolePagination,
  announcements: announcementsPagination,
  assets: assetsPagination
});

export default paginationReducer;
