import React, { Component } from "react";
import { Table } from "antd";
import { AccessControl, Iconfont } from "@components";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
    <Iconfont
        type="icon-sort"
        style={{ cursor: "grab" }}
        className="icon-20"
    />
));

const basicColumn = [
    {
        title: "排序",
        dataIndex: "sort",
        width: "12%",
        className: "drag-visible",
        render: () => <DragHandle />,
    },
];
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class SortableTable extends Component {
    DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.props.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { dataSource } = this.props;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource?.findIndex(
            (x) => x.id === restProps["data-row-key"]
        );
        return <SortableItem index={index} {...restProps} />;
    };

    render() {
        let { columns, permission, cell, height, unSortable, ...rest } = this.props;
        // unSortable参数兼容不需要排序功能的table
        columns = unSortable ? [...columns] : [...basicColumn, ...columns];
        const components = {
            body: {
                wrapper: this.DraggableContainer,
                row: this.DraggableBodyRow,
                cell
            },
        };
        return (
            <div className="sortable-table">
                <AccessControl permission={permission}>
                    <Table
                        pagination={false}
                        rowKey={(record) => record.id}
                        components={components}
                        columns={columns}
                        scroll={{ y: height || "74vh" }}
                        {...rest}
                    />
                </AccessControl>
            </div>
        );
    }
}

export default SortableTable;
