import React, { memo, useMemo } from 'react';
import { Tooltip } from 'antd';

interface ITopicItemProps {
    data: any;
    checkKeys: number | string;
    checkKeysChange: (item: any) => void;
}
/**
 * 选题列表单行item
 * @param {ITopicItemProps} props props
 * @returns {React.ReactNode}
 */
const TopicItem: React.FC<ITopicItemProps> = (props) => {
    const { data, checkKeys, checkKeysChange } = props;
    const checkClassName = useMemo(() => {
        if (checkKeys === data.id) {
            return 'amp-topic-left-scroll-item-current';
        }
        return '';
    }, [checkKeys, data]);

    const tooltipTitle = data.name && data.name.length > 16 ? data.name : null;

    return (
        <div className={`amp-topic-left-scroll-item ${checkClassName}`} onClick={() => checkKeysChange(data)}>
            <Tooltip title={tooltipTitle}>
                <div className="amp-topic-line-text">{data.name}</div>
            </Tooltip>
            <div className="amp-topic-line-text">{data.number}</div>
        </div>
    );
};

export default memo(TopicItem);
