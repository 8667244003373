function fileToImgUrl (file) {
    return new Promise((resolve) => {
        const fr = new FileReader();
        fr.onloadend = function(e) {
            resolve(e.target.result);
        };
        fr.readAsDataURL(file);
    });
}
export default fileToImgUrl
