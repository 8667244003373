import { cloneElement, Component } from 'react';
import { Button, Typography } from '@components';
import { mutate } from '@hooks/swr';

/**
 * 创建ErrorBoundaries组件
 * @returns {React.ComponentType} Component.Props.renderFallback: ({error}) => ReactNode
 */
function createErrorBoundaries() {
    return class ErrorBoundaries extends Component {
        _key = null;

        constructor(props) {
            super(props);
            this.state = {
                error: null,
                hasError: false
            };
        }

        componentDidCatch(error) {
            this._key = error._key;
            this.setState({
                error,
                hasError: true
            });
        }

        reLoad = () => {
            if (this._key) {
                mutate(this._key, undefined, true).then(() => {
                    this.setState({ hasError: false, error: null });
                });
            }
        };

        render() {
            const {
                children,
                errorFallback
            } = this.props;
            const {
                hasError,
                error
            } = this.state;
            if (hasError) {
                if (errorFallback) {
                    return cloneElement(errorFallback, { error, reLoad: this.reLoad });
                }
                return (
                    <Typography>
                        {error.message || '网络错误请稍后'}
                        <Button className="d-inline" type="link" onClick={this.reLoad}>重试</Button>
                    </Typography>

                );
            }
            return children;
        }
    };
}

export default createErrorBoundaries;
