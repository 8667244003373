import { noop, UNDEFINED } from './helper';
import { internalMutate } from './mutate';
import { SWRGlobalState } from './global-state';

export const initCache = (provider,) => {
  if (!SWRGlobalState.has(provider)) {
    const EVENT_REVALIDATORS = {}
    const mutate = internalMutate.bind(
      UNDEFINED,
      provider
    )
    let unmount = noop
    SWRGlobalState.set(provider, [EVENT_REVALIDATORS, {}, {}, {}, mutate])

      unmount = () => {
          SWRGlobalState.delete(provider)
      }

    return [provider, mutate, unmount]
  }

  return [provider, (SWRGlobalState.get(provider)[4])]
}
