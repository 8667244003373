import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Input, Select, Form } from "antd";
import { priorityTypes } from "@vars/views/tasks_vars";
import { toJS, autorun } from "mobx";
import { debounce } from '@gUtils';
const { Option } = Select;

@inject("todosStore", "configsStore")
@observer
class TodosFilter extends Component {
  get store() {
    return this.props.todosStore;
  }
  get configsStore() {
    return this.props.configsStore;
  }

  formRef = React.createRef();

  componentDidMount() {
    // 获取任务状态
    this.configsStore.fetchTaskConfig();
    // 获取发起人员
    this.configsStore.fetchAllUsers();
    // 监听filterType字段反复改变刷新 表单中的数据
    autorun(() => {
        if(this.store.filterType){
            this.formRef.current?.resetFields()
        }
    })
  }

  handleValuesChange = (_, allValues) => {
    let filter = {};
    for (var key in allValues) {
      if (allValues[key]) {
        filter[key] = allValues[key];
      }
    }
    this.getTaskTableList({ filter });
  };

  handleTodoRequest = (params) => {
    const { currentTab } = this.store;
    if (currentTab === "task") return this.store.fetchTaskTodos(params);
    else return this.store.fetchProjectTodos(params);
  };

  getTaskTableList = debounce(this.handleTodoRequest, 300);

  renderTaskFilter = () => {
    const task_status = Object.values(
      toJS(this.configsStore.taskConfig.task_status) || {}
    );
    let initiator = Object.values(
      toJS(this.configsStore.allUsers) || {}
    )
    return (
      <>
        <Form.Item name="name_uid" initialValue="">
          <Input
            placeholder="检索项目/内部ID/资产名称"
            className="filter-input"
            allowClear
            maxLength={50}
          />
        </Form.Item>
        {this.renderSelect("initiator", initiator, "发起人员")}
        {this.renderSelect("task_status", task_status, "任务状态")}
        {this.renderSelect("priority", priorityTypes, "优先级")}
      </>
    );
  };

  renderSelect = (type, data, placeholder) => {
    return (
      <Form.Item name={type} initialValue={null}>
        <Select
          className="filter-input"
          size="large"
          placeholder={placeholder}
          allowClear
          showSearch optionFilterProp="children" showArrow
        >
          {
            data?.map((item, idx) => (
              type === "initiator" ?
              (<Option key={item.id} value={item.id}>
                {item.name}
              </Option>) : (<Option key={idx} value={item.code}>
                {item.label}
              </Option>)
            ))
          }
        </Select>
      </Form.Item>
    );
  };

  renderProjectFilter = () => {
    return (
      <Form.Item name="project_name" initialValue="">
        <Input placeholder="检索项目名称" className="filter-input" />
      </Form.Item>
    );
  };

  render() {
    const { currentTab } = this.store;
    return (
      <Form
        onValuesChange={this.handleValuesChange}
        className="mg-b-20"
        layout="inline"
        ref={this.formRef}
      >
        {currentTab === "task"
          ? this.renderTaskFilter()
          : this.renderProjectFilter()}
      </Form>
    );
  }
}
export default TodosFilter;
