import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import './index.less';
const { Step } = Steps;

/**
 * TopicProgress
 * @param {*} props props
 * 选题详情节点展示
 */
const TopicProgress = (props) => {
    const [progressLine, setProgressLine] = useState([]);
    useEffect(() => {
        const temp = [];
        props.progressList?.map((item) => {
            temp.push(<Step title={item.label} key={item.id} />);
        });
        setProgressLine(temp);
    }, props.progressList);
    return (
        <div className="amp-topic-progress">
            <Steps labelPlacement="vertical" progressDot current={props?.groupStatus} initial={1}>
                {progressLine}
            </Steps>
        </div>
    );
};

export default TopicProgress;
