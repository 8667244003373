import React, { Component } from "react";
import { Table, Pagination } from "antd";
import { connect } from "react-redux";
import { AccessControl } from "../..";
import { checkAuth } from "@gUtils";
import { observer, inject } from "mobx-react";
//props:(调用时传输)
//data: columnsConfig
//methods: rowClick(列表行点击事件)及其他自定义事件

//connector: (写成container形式)
//mapStateToProps: pages, currentPage, entity, fetching, total
//mapDispatchToProps: fetchItems
//写法参考ProjectsTableContainer
const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});
@inject("filterStore")
@observer
class BaseTable extends Component {
  componentDidMount() {
    let { defaultPageSize, filter } = this.props;
    this.backendRequest({ page: 1, page_size: defaultPageSize || 12, filter });
  }
  get rowClickAuth() {
    const { rowClickPermission, userPermissions } = this.props;
    if (!rowClickPermission) {
      return true;
    }
    return checkAuth(userPermissions, rowClickPermission);
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.props.filterStore.setSortObj({
      order_by: sorter.field,
      sort: sorter.order ? sorter.order.replace("end", "") : "",
    })
    let { defaultPageSize } = this.props;
    let params = {
      order_by: sorter.field,
      sort: sorter.order ? sorter.order.replace("end", "") : "",
      page: 1,
      filter: this.props.filter,
      page_size: defaultPageSize || 12,
    };
    this.backendRequest(params);
  };

  handlePageChange = (page, page_size) => {
    let filter = this.props.filter;
    this.backendRequest({ page, page_size, filter });
  };

  backendRequest = (params) => {
    this.props.fetchItems(params);
  };

  onRow = (record, index) => {
    const onClick =
      this.rowClickAuth && this.props.rowClick
        ? (e) => {
            e.stopPropagation();
            this.props.rowClick(record, index);
          }
        : () => {};
    return { onClick };
  };

  render() {
    const {
      pages,
      currentPage,
      entity,
      columnsConfig,
      permission,
      defaultPageSize,
      rowClassName,
    } = this.props;
    const ids = pages[currentPage] ? pages[currentPage].items : [];
    const data = ids?.map((id) => entity[id]);
    const fetching = pages[currentPage] ? pages[currentPage].fetching : true;
    const cursorClass = this.rowClickAuth ? "" : "table-cursor-default";

    return (
      <div className="table-wrap">
        <AccessControl permission={permission}>
          <Table
            className={`base-table`}
            columns={columnsConfig}
            rowKey={(record) => (record ? record.id : "")}
            onChange={this.handleTableChange}
            rowClassName={`table-row ${cursorClass} ${rowClassName}`}
            dataSource={data}
            pagination={false}
            loading={fetching}
            onRow={this.onRow}
            {...this.props}
          />

          <Pagination
            defaultPageSize={defaultPageSize || 12}
            showQuickJumper
            onChange={this.handlePageChange}
            current={currentPage}
            showTotal={(total) => `共 ${total} 条`}
            showSizeChanger={false}
            {...this.props}
          />
        </AccessControl>
      </div>
    );
  }
}
export default connect(mapStateToProps)(BaseTable);
