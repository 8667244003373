import {
    RECEIVE_TASK,
    RECEIVE_TASK_ERROR
} from "@actions/action_consts";
import {
    $success,
    $error
} from "@/components";

import {tasksAPI} from "@api";
//action creator
export const receiveTask = (task) => ({
    type: RECEIVE_TASK,
    task,
});

export const receiveTaskErr = (error) => ({
    type: RECEIVE_TASK_ERROR,
    error,
});

// back end request
// 使用小括号进行implicit return
export const fetchTaskDetail = (params, callback = () => {}) => dispatch => (
    tasksAPI.fetchTaskDetail(params).then(
        res => {
            if (res.code === 1000) {
                callback()
                return dispatch(receiveTask(res.data))
            } else {
                callback()
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

export const createTask = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.createTask(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)
export const updateTask = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.updateTask(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

export const fetchTaskInfo = (id, callback = () => {}) => dispatch => (
    tasksAPI.fetchTaskInfo(id).then(
        res => {
            if (res.code === 1000) {
                callback()
                return dispatch(receiveTask(res.data))
            } else {
                callback()
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

export const fetchTaskHistory = (id, callback = () => {}) => dispatch => (
    tasksAPI.fetchTaskHistory(id).then(
        res => {
            if (res.code === 1000) {
                callback()
                return res.data;
            } else {
                callback()
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

// 拍摄分配
export const shootingAssignment = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.shootingAssignment(params).then(
        (res) => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

// 作废
export const taskDiscard = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.taskDiscard(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

// 提交制作
export const submitMake = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.submitMake(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

//制作分配
export const makingAssignment = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.makingAssignment(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

// 制作 回退解析
export const backAnalyse = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.backAnalyse(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

//制作开始
export const taskMaking = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.taskMaking(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)
//  制作中回退监制 
export const backToReviewAnalysis = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => dispatch => (
    tasksAPI.backToReviewAnalysis(params).then(
        res => {
            if (res.code === 1000) {
                success()
                $success(res.msg)
                return dispatch(receiveTask(res.data))
            } else {
                error()
                if (res.code !== 1002) $error(res.data.msg)
                return dispatch(receiveTaskErr(res.msg))
            }
        },
    )
)

// analysis 
export const startAnalysis = (
    params, {
        success,
        error
    } = {
        success: () => {},
        error: () => {}
    }
) => (dispatch) => {
    tasksAPI.analysisTask(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg);
            return dispatch(receiveTask(res.data));
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg));
        }
    });
}

export const allocationTask = (
    params, {
        success,
        error
    } = {
        success: () => {},
        error: () => {}
    }
) => (dispatch) => {
    tasksAPI.allocationTask(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    });
};

export const backToReviewShoot = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.backToReviewShoot(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const submitAnalyze = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.submitAnalyze(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const analyticalDistribution = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.analyticalDistribution(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const fallbackShoot = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.fallbackShoot(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const fallbackParsing = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.fallbackParsing(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const productionDistribution = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.productionDistribution(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const handleComplete = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.handleComplete(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

// 拍摄
export const submitShoot = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.submitShoot(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const downloadUrl = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.downloadUrl(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const startShooting = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.startShooting(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

// 监制  完成
export const reviewAgree = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.reviewAgree(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

// 停止 拍摄/解析/制作
export const taskStop = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.taskStop(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

// 作废 完成 重制
export const remake = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.remake(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

// 驳回
export const reject = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.reject(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}

export const rollback = (params, {
    success,
    error
} = {
    success: () => {},
    error: () => {}
}) => (dispatch) => {
    tasksAPI.rollback(params).then((res) => {
        if (res.code === 1000) {
            success()
            $success(res.msg)
            return dispatch(receiveTask(res.data))
        } else {
            error();
            if (res.code !== 1002) $error(res.data.msg)
            return dispatch(receiveTaskErr(res.msg))
        }
    })
}