import React, { Component } from 'react';
import { Form, Table, DatePicker, Select, Input } from "antd"
import TripEdit from "../trip_edit"
import "./index.less"

export default class TripList extends Component {
    render() {
        return (
            this.props.list.length > 0 && <div className='amps-trip-list'>
                {this.props.activeIndex >=0 ? (<>
                    {(this.props.list.length > 1 && this.props.activeIndex > 0) ? (<Table
                        className='amp-list-table'
                        showHeader={false}
                        pagination={false}
                        columns={this.props.columnsList}
                        dataSource={this.props.list.slice(0, this.props.activeIndex)}
                        locale={{
                            emptyText() {
                                return '';
                            }
                        }}>
                    </Table>) : null}
                    <TripEdit
                        isEdit={true}
                        itemData={this.props.list[this.props.activeIndex]}
                        typeList={this.props.typeList}
                        tripType={this.props.tripType}
                        onCancel={this.props.onSuccess}
                        onConfirm={this.props.onConfirm}></TripEdit>
                    {this.props.list.length > this.props.activeIndex + 1 ? (<Table
                        className='amp-list-table'
                        showHeader={false}
                        pagination={false}
                        columns={this.props.columnsList}
                        dataSource={this.props.list.slice(this.props.activeIndex + 1)}
                        locale={{
                            emptyText() {
                                return '';
                            }
                        }}>
                    </Table>) : null}
                </>) : <Table
                    className='amp-list-table'
                    showHeader={false}
                    pagination={false}
                    columns={this.props.columnsList}
                    dataSource={this.props.list}
                    locale={{
                        emptyText() {
                            return '';
                        }
                    }}>
                </Table>}
            </div>
        )
    }
}
