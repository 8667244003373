import { combineReducers } from "redux";
import { RECEIVE_USERS, REQUEST_USERS } from "@actions/action_consts";

const pages = (pages = {}, action = {}) => {
    Object.freeze(pages)
    switch(action.type) {
        case REQUEST_USERS:
            return {
              ...pages,
              [action.params.page]: {
                items: [],
                fetching: true,
              },
            };
        case RECEIVE_USERS: 
        let list  = action.users.list || [];
        return {
          ...pages,
          [action.params.page]: {
            items: list?.map((item) => item.id) || [],
            fetching: false,
          },
        };
        default:
        return pages;
    }
};
const filter = (filter = {}, action = {}) => 
    action.type === REQUEST_USERS ? (action.params.filter || {}): filter;
const currentPage = (currentPage = 1, action = {}) =>
    action.type === REQUEST_USERS ? (action.params.page || 1) : currentPage;
const total = (total = 0, action = {}) =>
    action.type === RECEIVE_USERS ? action.users.total : total;

export const userPagination = combineReducers({
    pages,
    currentPage,
    total,
    filter
}); 