import { Form, Input } from "antd";
// import React, { Component } from 'react'

export const RangeInputPositiveInteger = ({ name, label }) => {
  const placeholder = "请输入";
  const style = { width: `${97/19.2}vw` };
  const getInt = (e) => {
    const res = parseInt(e.target.value)
    if (res !== 0 && !res) {
      return null
    } else {
      return res
    }
  };
  const validator = (_, value)=> {
    if (value) return Promise.resolve()
  }
  return (
    <div className="align-center mg-b-20">
      <span className="bold mg-r-10">{label}</span>
      <div className="site-input-group-wrapper">
        <Input.Group compact>
          <Form.Item
            name={[name, "min"]}
            getValueFromEvent={getInt}
            rules={[{ validator }]}
          >
            <Input
              size="large"
              className=" site-input-left"
              style={style}
              placeholder={placeholder}
              maxLength={6}
            />
          </Form.Item>
          <Input
            size="large"
            className="site-input-split"
            placeholder={"至"}
            disabled
            style={{
              width: `${36 / 19.2}vw`,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: "none",
            }}
          />
          <Form.Item
            name={[name, "max"]}
            getValueFromEvent={getInt}
            dependencies={[name, "min"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue(name).min) {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              size="large"
              className="site-input-right"
              style={style}
              placeholder={placeholder}
              maxLength={6}
            />
          </Form.Item>
        </Input.Group>
      </div>
    </div>
  );
};
