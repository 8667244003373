import { Component } from "react";
import { Menu } from "antd";
import { withRouter } from "react-router-dom";
import { Iconfont, AuthMenuItem, AuthSubMenu } from "@/components";
import { settingsMenu } from "@vars/configs/menu_vars";

class SideBar extends Component {
  get currentActiveKey() {
    const { pathname } = this.props.location;
    const firstLevel = pathname.split("/").slice(2, 3);
    const activeKey = pathname.split("/").slice(-1);
    return pathname.includes("/projects") ? activeKey : firstLevel;
  }

  handleSelect = ({ keyPath }) => {
    const resPath = keyPath.reverse().join("/");
    const arr = ["user-relation-settings","shooting-team-settings", "shooting-plan-settings", "asset-category-settings", "asset-type-settings"];
    let flag = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === resPath) {
        flag = true;
      }
    }
    flag ? (this.props.history.push(`/settings/projects/${resPath}`)) : (this.props.history.push(`/settings/${resPath}`));
  };
  renderMenuItem = (menu) => (
    <AuthMenuItem
      permission={menu.permission}
      key={menu.key}
      icon={menu.icon ? <Iconfont type={menu.icon} /> : ""}
    >
      {menu.title}
    </AuthMenuItem>
  );

  renderSideMenu = () => {
    return settingsMenu?.map((menu) => {
      const { children } = menu;
      return !children ? (
        this.renderMenuItem(menu)
      ) : (
        <AuthSubMenu
          permission={menu.permission}
          key={menu.key}
          title={menu.title}
          icon={<Iconfont type={menu.icon} />}
        >
          {children?.map((item) => this.renderMenuItem(item))}
        </AuthSubMenu>
      );
    });
  };
  render() {
    return (
      <Menu
        onSelect={this.handleSelect}
        style={{ width: 256 }}
        mode="inline"
        selectedKeys={this.currentActiveKey}
        defaultOpenKeys={["projects"]}
        multiple={false}
      >
        {this.renderSideMenu()}
      </Menu>
    );
  }
}

export default withRouter(SideBar);
