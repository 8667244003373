import { Component } from "react";
import { Modal, Form, Input, Button } from "antd";
import { connectUserSession, passEncrypt } from "@/utils/global/";
import { rules } from "@vars/global_vars";
import {accountAPI} from "@api";
import { $success, $error, AuthButton } from "@/components";

class ResetPassword extends Component {
  state = {
    visible: false,
  };

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleSubmit = (values) => {
    const password = passEncrypt(values.password);
    this.resetRequest({ password });
  };

  resetRequest = (params) => {
    accountAPI.resetDefaultPassword(params).then((res) => {
      if (res.code === 1000) {
        this.success();
        $success(res.msg);
      } else {
        if (res.code !== 1002) $error(res.msg);
      }
    });
  };

  success = () => {
    this.hide();
    this.props.finishInitialization();
  };

  render() {
    return (
      <Modal
        centered
        visible={this.state.visible}
        onCancel={this.hide}
        className="medium-form-dialog"
        footer={null}
        width="438px"
        destroyOnClose
      >
        <div className="dialog-heading">
          <h2>{`嗨，${this.props.session && this.props.session.name}`}</h2>
          <h2>欢迎访问 AMP ~</h2>
          <h3 className="mg-t-12">为了账户安全，请重新设置您的密码</h3>
        </div>

        <Form
          onFinish={this.handleSubmit}
          layout="vertical"
          validateTrigger="onBlur"
          preserve={false}
        >
          <div>
            <Form.Item
              className="form-item"
              name="password"
              rules={rules.password}
            >
              <Input.Password
                placeholder="请设置您的新密码"
                className="password-input"
                size="large"
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="confirmPassword"
              dependencies={["password"]}
              rules={rules.confirmPassword}
            >
              <Input.Password
                placeholder="请再次输入新密码"
                className="password-input"
                size="large"
              />
            </Form.Item>
          </div>
          <div className="dialog-footer">
            <Form.Item className="form-item">
              <AuthButton className="span-btn" type="primary" htmlType="submit">
                保存
              </AuthButton>
            </Form.Item>
            <div className="skip-text mg-t-12" onClick={this.hide}>
              稍后再说
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default ResetPassword;
