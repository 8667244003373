import React from "react";
import { Collapse } from "@components"
import { PlanTaskImg } from "./../components"
import "./index.less"
import { toJS } from "mobx";

const Status = {
    1: "未审核",
    2: "通过",
    3: "驳回",
    4: "作废",
}

const TaskInfoRegion = (props) => {
    const { item_statics } = props
    const list = toJS(item_statics)?.sort((a, b) => {
        return a.status - b.status
    })
    return (
        <div className="amp-origin-border mg-b-24">
            <Collapse header="任务信息" fontWeight="fw-500" opened={true}>
                <div className="amp-task-package">
                    <div className="amp-task-info-title">
                        {
                            list?.map((item, index) => (
                                <span className="amp-task-info-status" key={index}>{Status[item.status]}: {item.count}</span>
                            ))
                        }
                    </div>
                    <PlanTaskImg {...props} />
                </div>
            </Collapse>
        </div>

    )
}


export default TaskInfoRegion
