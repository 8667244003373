/**
 * @name: TaskRoute.jsx
 * @user: cfj
 * @date: 2022/3/22 3:47 PM
 * @description: 任务包路由入口
 */

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TaskPackage from './index';
import SetTaskForm from './SetTaskForm';
import TaskDetails from './TaskDetails';
// 任务包详情路由
const TaskDetailsRoute = function () {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/add`}>
                <SetTaskForm parentUrl={url} />
            </Route>
            <Route path={`${path}/edit/:taskId`}>
                <SetTaskForm parentUrl={url} />
            </Route>
            <Route path={`${path}/detail/:taskId`}>
                <TaskDetails parentUrl={url} />
            </Route>
            <Route path={`*`}>
                <Redirect to={`${url}/add`}/>
            </Route>
        </Switch>
    );
};
// 任务包路由
 const TaskRoute = function () {
    const {path, url} = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/create_task`}>
                <TaskPackage parentUrl={url} >
                    <TaskDetailsRoute />
                </TaskPackage>
            </Route>
        </Switch>
    );
};
export default TaskRoute
