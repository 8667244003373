import './index.less';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Iconfont } from '@components';
import { BaseInfo, TaskInfo, TaskProcess } from './';

/**
 * 任务包详情展示
 * @returns {JSX.Element}
 */

@inject('taskPackageStore')
@observer
class Details extends Component {

    get taskPackageStore() {
        return this.props.taskPackageStore;
    }

    render() {
        const {
            currentTasks: {
                status,
                no,
                title,
                item_count,
                score
            }
        } =
            this.taskPackageStore;
        return (
            <div className='task-planning' ref={(node) => this.scrollNode = node}>
                <div className='task-planning-content'>
                    <div className='drawer-title'>
                        <div>
                            <h3 style={{ display: 'inline-block' }}>{`任务包：${no} ${title}`}</h3>
                        </div>
                        <div className='align-center mg-t-20 mg-b-24'>
                            <div className='task-status-display mg-r-16' style={{ background: `${status?.color}` }}>{status?.label}</div>
                            <div className='align-center mg-r-16'>
                                <Iconfont type='icon-a-16-geshu' className='icon-18 mg-r-6' /> <span> {item_count} 个</span>
                            </div>

                            <div className='align-center'>
                                <Iconfont type='icon-a-20-zongpingfen' className='icon-18 mg-r-6' /> <span> {score} 分</span>
                            </div>
                        </div>
                    </div>
                    {/* 任务包基本信息 */}
                    <BaseInfo {...this.taskPackageStore.currentTasks} />
                    {/* 任务包信息 */}
                    <TaskInfo {...this.taskPackageStore.currentTasks} {...this.taskPackageStore}
                              tasksId={this.taskPackageStore.tasksId} />
                    {/* 任务包流程展示区域 */}
                    <TaskProcess {...this.taskPackageStore} />
                </div>
            </div>
        );
    }

};

export default Details;
