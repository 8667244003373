import React, { useState, useRef, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Row, Button } from 'antd'
import { AuthButton } from "@/components"
import moment from 'moment'
import { useParentRoute } from '@components/router/routes';
import { collectionStatsAPI } from '@api'

const { Option } = Select;
const { RangePicker } = DatePicker
const FormItem = Form.Item

/**
 * form 采集/解析 统计表单
 * @param {*} props
 * @return {JSX.Element} Form
 */
const AnalyticalFrom = (props) => {
    const {
        formList,
        weekOrMonth = false,
        className = null,
        csvExport,
        submitForm,
        resetForm,
        GroupType,
        initialValues
    } = props
    const route = useParentRoute(); // 获取当前路由节点的配置项
    const [groupMemberList, setGroupMemberList] = useState([]); // 小组组员列表
    const [groupList, setGroupList] = useState([]); // 小组列表
    const [permission, setPermission] = useState(); // 导出权限
    const [loading, setLoading] = useState(true); // 小组成员loading
    const [form] = Form.useForm()
    useEffect(() => {
        form.resetFields();
    }, [route.key]);

    /**
    * 小组用户信息
    */
    useEffect(() => {
        if (route.name.includes("Summary")) {
            getUserGroupMemberList()
            getUserGroupList()
        } else {
            getUserGroupMemberList()
        }
    }, []);

    /**
    * 导出权限
    */
    useEffect(() => {
        if (route.name.includes("collectionStatisticsSummary")) {
            setPermission("collect_score_summary_export")
        }
        if (route.name.includes("collectionStatisticsDetails")) {
            setPermission("collect_score_detail_export")
        }
        if (route.name.includes("analyticalStatisticsSummary")) {
            setPermission("analysis_score_summary_export")
        }
        if (route.name.includes("analyticalStatisticsDetails")) {
            setPermission("analysis_score_detail_export")
        }
    }, []);

    const onValuesChange = (changedValues) => {
        if (changedValues.user_group_id >= 0) {
            form.setFieldsValue({ user_id: undefined })
            getUserGroupMemberList(changedValues.user_group_id)
        }
    }

    /**
     * 小组成员列表
     */
    const getUserGroupMemberList = async (user_group_id) => {
        setLoading(true)
        let params = {
            group_type: GroupType,
            group_id: user_group_id || 0
        }
        try {
            const res = await collectionStatsAPI.getCollectionUserGroupMember(params)
            if (res.code === 1000) {
                setLoading(false)
                setGroupMemberList(res.data.list)
            }
        } catch (error) { }
    }

    /**
     * 小组列表
     */
    const getUserGroupList = async () => {
        let params = {
            group_type: GroupType,
        }
        try {
            const res = await collectionStatsAPI.getCollectionUserGroupList(params)
            if (res.code === 1000) {
                setLoading(false)
                res.data.list.unshift({
                    id: 0, group_type: GroupType, name: "全部小组"
                })
                setGroupList(res.data.list)
            }
        } catch (error) { }
    }

    /**
     * 小组Select
     */
    const userOptions = groupList?.map((user) => (
        <Option key={user.id} value={user.id} label={user.name}>
            {user.name}
        </Option>
    ));

    /**
     * 小组组员Select
     */
    const groupMemberOption = groupMemberList?.map((user) => (
        <Option key={user.user_id} value={user.user_id} label={user.name}>
            {user.name}
        </Option>
    ));

    /**
     * 禁用时间
     * @param {*} current
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    /**
     * 表单
     * @param {*} value
     */
    const handleSubmit = (value) => {
        let startDate = ""
        let endDate = ""
        if (weekOrMonth) {
            if (route.key === "week" && value.created_at) {
                startDate = moment(value.created_at).day(1).format("YYYY-MM-DD")
                endDate = moment(value.created_at).day(7).format("YYYY-MM-DD")
                value.created_at = { from: startDate, to: endDate }
            } else if (route.key === "month" && value.created_at) {
                startDate = moment().month(moment(value.created_at).month()).startOf('month').format('YYYY-MM-DD')
                endDate = moment().month(moment(value.created_at).month()).endOf('month').format('YYYY-MM-DD')
                value.created_at = { from: startDate, to: endDate }
            }
        } else if (value.created_at) {
            startDate = moment(value.created_at[0]).format("YYYY-MM-DD")
            endDate = moment(value.created_at[1]).format("YYYY-MM-DD")
            value.created_at = { from: startDate, to: endDate }
        }
        submitForm(value)
    }

    /**
    * 重置表单
    */
    const submitFormReset = () => {
        form.resetFields();
        resetForm(form.getFieldsValue())
        getUserGroupMemberList()
    }

    /**
     * 导出数据
     */
    const submitFormExport = () => {
        csvExport();
    }

    return (
        <div className='amp-statistical-from'>
            <Form
                layout="horizontal"
                className={`amp-analytical-data-form ${className}`}
                onFinish={handleSubmit}
                onValuesChange={onValuesChange}
                form={form}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    {
                        weekOrMonth && (
                            <FormItem
                                label="统计周期"
                                name="created_at"
                            >
                                {
                                    <DatePicker
                                        picker={route.key}
                                        disabledDate={disabledDate}
                                        className='amp-input-sz-l'
                                    />
                                }
                            </FormItem>
                        )
                    }
                    {
                        formList.map((item, index) => {
                            return (
                                <div key={index}>
                                    {
                                        item.type === "input" && (
                                            <FormItem
                                                label={item.label}
                                                name={item.name}
                                            >
                                                <Input
                                                    maxLength={item.maxLength}
                                                    placeholder={item.placeholder}
                                                    className="amp-input-sz-l"
                                                />
                                            </FormItem>
                                        )
                                    }
                                    {
                                        item.type === "select" && (
                                            <FormItem
                                                label={item.label}
                                                name={item.name}
                                            >
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    loading={item.name === "user_id" ? loading : false}
                                                    placeholder={item.placeholder}
                                                    size="large"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    className="amp-input-sz-l"
                                                >
                                                    {item.name === "user_id" && groupMemberOption}
                                                    {item.name === "user_group_id" && userOptions}
                                                </Select>
                                            </FormItem>
                                        )
                                    }
                                    {
                                        item.type === "ranger_picker" && (
                                            <FormItem
                                                label={item.label}
                                                name={item.name}
                                            >
                                                <RangePicker
                                                    size='large'
                                                    disabledDate={disabledDate}
                                                    placeholder={item.placeholder}
                                                    className="amp-ranger-picker-l"
                                                />
                                            </FormItem>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                    <FormItem>
                        <Button
                            className='amp-btn-sz-s amp-blue-border'
                            onClick={submitFormReset}
                        >
                            重置
                        </Button>
                        <Button
                            className='amp-btn-sz-s'
                            type="primary"
                            htmlType='submit'
                        >
                            查询
                        </Button>
                    </FormItem>
                </Row>
            </Form>
            <div className="amp-business-data-button">
                <AuthButton
                    permission={permission}
                    className='amp-btn-sz-s'
                    onClick={submitFormExport}
                >
                    导出
                </AuthButton>
            </div>
        </div>
    )
}

export default AnalyticalFrom
