import { Collapse, HistoryHeader } from "@/components";

const CommentedPanel = (props) => {
  const { history_detail } = props;
  return (
    <Collapse header={<HistoryHeader {...props} />}>
      {history_detail.solve_comment
        ? `处理意见：${history_detail.solve_comment}`
        : ""}
    </Collapse>
  );
};

export default CommentedPanel;
