/**
 * @name: AbandonTaskForm.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 放弃任务包
 */
import { forwardRef, useImperativeHandle } from 'react';
import { Col, Form, Row } from 'antd';
import { Input } from '@components';

const AbandonTaskForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    const { initialValues } = props;
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }))
    return <Form form={form} initialValues={initialValues} layout={'vertical'}>
        <Row>
            <Col span={12}>
                <Form.Item label={'下一步'} name={'statusLabel'} required={true}>
                    <Input disabled={true} />
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Form.Item label={'备注'} name={'comment'}>
                    <Input.TextArea
                        autoSize={{
                            minRows: 5,
                            maxRows: 5
                        }}
                        placeholder='请输入备注信息'
                        maxLength={200}
                        showCount={({
                            count,
                            maxLength
                        }) => `${count}/${maxLength}`}
                    />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
});
export default AbandonTaskForm
