import React, { Component } from 'react';
import { Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import { Iconfont } from '@/components';
import TaskImg from '../../taks_details/components/task_img';
import './index.less';
@inject('taskPlanStore', 'taskPackageStore')
@observer
class TaskPackageReview extends Component {
    state = {
        options: [
            {
                label: '未审核',
                value: 1
            },
            {
                label: '通过',
                value: 2
            },
            {
                label: '驳回',
                value: 3
            },
            {
                label: '作废',
                value: 4
            }
        ]
    };

    filterConRef = React.createRef();

    // 获取taskPackageStore
    get taskPackageStore() {
        return this.props.taskPackageStore;
    }

    componentDidMount() {
        const dom = this.filterConRef.current;
        dom.addEventListener('scroll', () => {
            this.scrollHandle(dom);
        });
    }

    // 滚动事件
    scrollHandle = (dom) => {
        const { scrollHeight, scrollTop, clientHeight } = dom;
        const { planningListTotal, planningPage } = this.taskPackageStore;
        const maxPage = Math.ceil(planningListTotal / 30);
        const flag = Math.floor(scrollHeight) <= Math.floor(scrollTop + clientHeight + 50);
        if (flag && maxPage > planningPage) {
            const changePage = planningPage + 1;
            this.taskPackageStore.updatePlanningPage(changePage);
            this.taskPackageStore.getPlanTaskList();
        }
    };

    // 渲染选择options
    renderOptions = () => {
        let res = [];
        const { options } = this.state;
        const { filtrateOptions } = this.taskPackageStore;
        options.map((item, idx) => {
            const IsChecked = filtrateOptions.indexOf(item.value) > -1 ? true : false;
            res.push(
                <span
                    className="mg-r-34 cursor-pointer"
                    key={idx}
                    onClick={() => {
                        this.taskPackageStore.updateFiltrateOptions(item.value);
                    }}>
                    <Iconfont type={IsChecked ? 'icon-a-24-xuanzhong' : 'icon-a-24-weixuanzhong'} className="mg-r-8" />
                    {item.label}
                </span>
            );
        });
        return res;
    };

    render() {
        const filterOptions = this.renderOptions();
        const { Isloading, planningList } = this.taskPackageStore;
        return (
            <div className="amps-filter">
                <div className="fliter-header">{filterOptions}</div>
                <div className="filter-content" ref={this.filterConRef}>
                    {!Isloading ? (
                        <div className="filter-content-loading">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <TaskImg planTaskOutListRefresh={planningList} packageStatus={true} />
                    )}
                </div>
            </div>
        );
    }
}

export default TaskPackageReview;
