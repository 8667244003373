import React from 'react'
import { Column as AColumn } from '@ant-design/plots'
import { withEmpty } from '@/HOC'

const ColumnSlider = (props) => {
    const { data, type = '月', xField, yField, loading } = props

    const config = {
        color: '#29C1B9',
        loading: loading,
        data: data,
        // 取值字段名
        xField: xField,
        yField: yField,
        // 内边距
        appendPadding: [30, 0, 0, 0],
        // 柱形文字位置
        label: {
            position: 'top'
        },
        // 添加Y坐标额外文字
        yAxis: {
            title: {
                text: '(个)',
                autoRotate: false,
                position: 'end'
            },
        },
        // 添加X坐标额外文字
        xAxis: {
            title: {
                text: `(${type})`,
                autoRotate: false,
                position: 'end',
                offset: 26
            },
        },
        // 替换yField字段显示文字
        meta: {
            [yField]: {
                alias: `作废总量`
            }
        },
        // 柱形最大宽度
        maxColumnWidth: 10,
        // 滑块
        slider: {
            start: 0,
            end: 1,
            // 前景配置，参考绘图属性
            foregroundStyle: {
                fill: '#29C1B9',
                fillOpacity: 0.5,
            }
        },
        // 柱形圆角
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
    }

    return <AColumn {...config} />
}

export default withEmpty(ColumnSlider)
