import { Button } from "antd";
import { AuthButton } from "@/components";

/**
 * 承接任务包相关按钮
 * @returns
 */
export const UndertakeButton = (props) => {
    const { handleCancel } = props;
    return (
        <div className="amps-create-tasks-button">
            <div className="bottom-button  ">
                <AuthButton
                    type="primary"
                    className="tasks-create tasks-btn mg-r-20"
                    onClick={() => {
                        props.taskPackageStore.getCurrentTaskPackageTotal();
                        props.taskPackageStore.getPackageScore();
                        props.taskPlanStore.setTitle(8);
                    }}
                >
                    承接任务包
                </AuthButton>

                <Button className="tasks-cancel tasks-btn" onClick={handleCancel}>
                    取消
                </Button>
            </div>
        </div>
    );
};
