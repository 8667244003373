import ScrollContainer from '../../../components/scroll_container';
import { taskPlanAPI } from '@api';
import AuditTaskItemCol from '@views/taskPackage/components/AuditTaskItemCol';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import useDebounceState from '../../../hooks/useDebounceState';
import { MissionInfoForm } from './ActionForms';

/**
 * @name: MissionInfo.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 任务信息
 */
const MissionInfo = forwardRef(function(props, ref) {
    const scrollRef = useRef()
    const {taskId} = props
    const [values, debounceParams, setValues] = useDebounceState({
        taskId
    });

    useImperativeHandle(ref, () => ({
        refresh: () => scrollRef.current?.refresh()
    }))
    return <ScrollContainer
        col={5}
        ref={scrollRef}
        className={'mission-info'}
        pageSize={15}
        url={'/package-item/list'}
        renderHeader={({data}) => <MissionInfoForm statics={data?.[0]?.statics} value={values} onChange={setValues} />}
        params={debounceParams}
        request={(page, pageSize, params) => taskPlanAPI.getPlanTaskList({
            page,
            page_size: pageSize,
            package_id: params.taskId,
            filter: {
                ...params,
                status: [1,2,3,4]
            }
        })
            .then((res) => {
                return {
                    list: res.data.list,
                    total: res.data.total,
                    statics: res.data.statics
                };
            })}
        renderItem={(cols, key) => <AuditTaskItemCol
            type={'view'}
            refresh={() => {
                scrollRef.current?.refresh()
            }}
            key={key} cols={cols} />}
    />
})
export default MissionInfo
