import React from 'react';
import CycleImage from '../../../../../topic_details/components/topic_description/render/cycleImage';
import RenderItem from './render_item'
import GdMap from '@/components/gaode_map';
import './index.less'

/**
 * 场景详情左侧描述组件
 * @param {*} props props
 * @returns {*}
 */
const SceneDetailsDescription = (props) => {
    const {
        imagesList,
        comment,
        createdAt,
        planUserList,
        addressListList,
    } = props;
    return (
        <div className='amp-scene-description'>
            <div className='amp-scene-description-slideImage'>
                <CycleImage image_list={imagesList} />
            </div>
            <div className='amp-scene-description-details'>
                <RenderItem
                    comment={comment}
                    createdAt={createdAt}
                    planUserList={planUserList}
                    addressListList={addressListList}
                />
            </div>
            <div className='amp-scene-description-map'>
                <GdMap marker={addressListList} />
            </div>
        </div>
    )
}

export default SceneDetailsDescription;
