
import { connect } from 'react-redux';
import { Table } from "@/components";
import {getUser} from '@actions/1_entities/user_actions';
import { fetchUsers } from "@actions/1_entities/users_actions";
import { tableColumns } from "@vars/views/user_vars";
import { initState } from "@vars/views/user_vars";

const UserTable = (props) => {
    const rowClick = (record, index) => {
      props.getUser(record.id, (info) => {
        props.onRowClick("edit", info);
      });
    };
    return (
      <Table
        rowClickPermission="user_update"
        rowClick={rowClick}
        columnsConfig={tableColumns}
        permission="user_list"
        {...props}
      />
    );
};

const mapStateToProps = (state) => ({
    entity: state.entities.users,
    currentPage: state.pagination.users.currentPage,
    total: state.pagination.users.total,
    pages: state.pagination.users.pages,
    filter: state.pagination.users.filter, // 此数据存储与redux中，与state中数据耦合，且不便于清理，后续做优化时解耦处理
    // filter: initState, // 此数据仅在组件重新加载时使用，与筛选条件更新触发的渲染无关
});

const mapDispatchToProps = (dispatch) => ({
    fetchItems: (params) => dispatch(fetchUsers(params)),
    getUser: (params, cb) => dispatch(getUser(params, cb))
});
export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
