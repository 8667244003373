import { Component } from "react";
import { projectsAPI } from "@api";
import { shootingPlanAPI } from "@api";
import { timelineTypes } from "@vars/views/shooting_plan_vars";
import { Timeline, Spin } from "antd";
import { ReviewDrawer } from "../bottom_drawer";
import { requestMessage } from "@gUtils/functional_util";
import { withRouter } from "react-router";
import {$error} from '@components'

@withRouter
class PlanHistory extends Component {
  state = {
    historyRecords: [],
    visible: false,
    loading: false,
    review_comment: "",
    drawerType: "",
  };

  get shootingPlanId() {
    const { search } = this.props.location;
    return parseInt(search.replace("?shootingPlanId=", ""));
  }

  componentDidMount() {
    this.props.onRef(this);
    this.requestProjectHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.requestProjectHistory();
    }
  }

  hideDrawer = () => this.setState({ visible: false });
  showDrawer = (type) =>
    this.setState({ visible: true, drawerType: type, review_comment: "" });
  startLoad = () => {
    this.setState({ loading: true });
  };
  finishLoad = () => {
    this.setState({ loading: false });
  };
  setComment = (e) => {
    this.setState({ review_comment: e.target.value });
  };

  getUpdated = (id) => {
    this.hideDrawer();
    this.requestProjectHistory();
    this.props.getUpdate(id);
  };
  confirmApprove = () => {
    this.reviewRequest({ approve: 1 });
  };
  confirmDisapprove = () => {
    this.reviewRequest({ approve: 0 });
  };

  reviewRequest = ({ approve }) => {
    const { review_comment } = this.state;
    const id = this.shootingPlanId;
    const params = { approve, id, review_comment };
    shootingPlanAPI
      .reviewPlan(params)
      .then((res) => requestMessage(res, this.getUpdated(id)));
  };

  requestProjectHistory = () => {
    this.startLoad();
    projectsAPI.fetchProjectHistory(this.props.projectId).then((res) => {
      if (res.code === 1000) {
        this.setState({ historyRecords: res.data });
      } else if (res.code === 1001) {
        $error(res.msg)
        this.props.onClose()
      }
      this.finishLoad();
    });
  };

  timelineItemRender = (record) => {
    const { history_action } = record;
    let type = timelineTypes(history_action);
    return (
      <Timeline.Item key={record?.history_id} color={type?.color}>
        {type?.component(record)}
      </Timeline.Item>
    );
  };

  recordsRender = () => {
    const { historyRecords } = this.state;
    return historyRecords?.map((record) => this.timelineItemRender(record));
  };

 render() {
    const { loading } = this.state;
    return (
      <div className="history-content">
        {loading ? (
          <div
            style={{ height: "500px" }}
            className="display-flex justify-center align-center"
          >
            <Spin size="large" />
          </div>
        ) : (
          <Timeline>{this.recordsRender()}</Timeline>
        )}
        <ReviewDrawer {...this} />
      </div>
    );
  };
}

export default PlanHistory;
