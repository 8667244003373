import React from "react";


export const ShrinkImage = (props) => {
    return (
        <div className="mg-b-30">
            <span className="asset-update-span">缩略图</span>
            <div className="asset-update-img">
                <img src={props.previews[0]} alt=""/>
            </div>
        </div>
    )
}

export const AssetIdAndName = (props) => {
    return (
        <>
            <div className="mg-b-30">
                <span className="asset-update-span">内部ID</span>
                <div className="asset-update-id-name mg-l-30">
                    {props.uid}
                </div>
            </div>
            <div className="mg-b-30">
                <span className="asset-update-span">资产名称</span>
                <div className="asset-update-id-name mg-l-16">
                    {props.name}
                </div>
            </div>
        </>
    )
}