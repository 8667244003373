import React, { Component } from "react";
import { AuthTabs, AuthTabPane } from "@/components";
import { ConfigList } from "./";
const tabs = [
  { key: 1, title: "行程类型"},
  { key: 2, title: "交通方式" },
  { key: 3, title: "经费类型" },
];

class ShootingPlanConfigs extends Component {
  state = {
    activeKey: 1,
  };
  onChange = (key) => {
    const activeKey = JSON.parse(key);
    this.setState({ activeKey });
  };

  tabPaneRender = () =>
    tabs?.map((tab) => <AuthTabPane key={tab.key} tab={tab.title} />);

  render() {
    const { activeKey } = this.state;
    const tab = tabs.find((item) => item.key === activeKey);
    return (
      <div>
        <AuthTabs
          defaultActiveKey={1}
          onChange={this.onChange}
          tabBarGutter={60}
          permission="plan_config_settings" 
        >
          {this.tabPaneRender()}
        </AuthTabs>
        <ConfigList tab={tab} />
      </div>
    );
  }
}

export default ShootingPlanConfigs;
