/**
 * @name: filterForm.jsx
 * @user: zy
 * @date: 2022/5/11
 * @description: 表格筛选
 */
import { Form, Button, DatePicker, Input, Select } from 'antd';
import moment from "moment";
import { useSwr } from '@hooks';
import { AuthButton } from "@/components"
import { collectionStatsAPI, configsAPI } from '@api';
import { handoverStatus } from './conofig'

const { Option } = Select
const { RangePicker } = DatePicker;
const shootingUser = 1
const analytical = 3
export const FilterForm = function (props) {
    const {
        submitForm,
        resetForm,
        csvExport
    } = props
    const [form] = Form.useForm()

    /**
     * 资产类型
     * @returns {Object} data
     */
    const getAssetType = () => {
        return configsAPI.fetchTaskConfig().then((res) => {
            if (res.code === 1000) {
                return Object.values(res.data.asset_type)?.map(item => ({
                    id: item.code,
                    label: item.label
                }))
            }
            throw new Error(res.msg || '');
        })
    }
    const { data: assetTypeList, isValidating: assetTypeLoading } = useSwr(['/task/config'], getAssetType)

    /**
     * 拍摄租成员
     * @returns {Object} data
     */
    const getShootingUserList = () => {
        const params = {
            group_type: 1,
            group_id: 0
        }
        return collectionStatsAPI.getCollectionUserGroupMember(params).then((res) => {
            if (res.code === 1000) {
                return res.data.list?.map(item => ({
                    id: item.user_id,
                    label: item.name
                }))
            }
            throw new Error(res.msg || '');
        })
    }
    const { data: shootingUserList, isValidating: shootingUserLoading } = useSwr(['/api/user-group/member', shootingUser], getShootingUserList)

    /**
     * 拍摄租成员
     * @returns {Object} data
     */
    const getAnalyticalUserList = () => {
        const params = {
            group_type: 3,
            group_id: 0
        }
        return collectionStatsAPI.getCollectionUserGroupMember(params).then((res) => {
            if (res.code === 1000) {
                return res.data.list?.map(item => ({
                    id: item.user_id,
                    label: item.name
                }))
            }
            throw new Error(res.msg || '');
        })
    }
    const { data: AnalyticalUserList, isValidating: AnalyticalUserLoading } = useSwr(['/api/user-group/member', analytical], getAnalyticalUserList)

    /**
    * 表单
    * @param {*} value
    */
    const handleSubmit = (value) => {
        if (value.analysis_finish) {
            value.analysis_finish_start_time = moment(value?.analysis_finish[0]).format('YYYY-MM-DD')
            value.analysis_finish_end_time = moment(value?.analysis_finish[1]).format('YYYY-MM-DD')
        }
        submitForm(value)
    }

    /**
    * 重置表单
    */
    const submitFormReset = () => {
        form.resetFields();
        resetForm(form.getFieldsValue())
    }

    /**
     * option选项
     * @param {*} options
     * @returns {JSX.Element}
     */
    const previewOption = (options = []) => {
        return options.map(item => (
            <Option
                key={item.id}
                value={item.id}
            >
                {item.label}
            </Option>
        ))
    }

    /**
     * 导出数据
     */
    const submitFormExport = () => {
        csvExport();
    }

    /**
     * 禁用时间
     * @param {*} current
     * @returns {boolean}
     */
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <div className='amp-business-data-from-page'>
            <Form
                className='amp-business-data-form'
                form={form}
                onFinish={handleSubmit}
                initialValues={{ handover_status: 0 }}
            >
                <Form.Item
                    name={'asset_query'}
                    label={'搜索资产'}
                >
                    <Input
                        className='amp-input-sz-l'
                        maxLength={50}
                        placeholder={'请输入资产ID或者名称'} />
                </Form.Item>
                <Form.Item
                    name={'shoot_user_id'}
                    label={'拍摄人'}>
                    <Select
                        size="large"
                        maxLength={10}
                        className="amp-input-sz-l"
                        placeholder={'请输入组员姓名'}
                        allowClear
                        loading={shootingUserLoading}
                    >
                        {
                            previewOption(shootingUserList)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={'analysis_user_id'}
                    label={'解析人'}
                >
                    <Select
                        size="large"
                        maxLength={10}
                        className="amp-input-sz-l"
                        placeholder={'请输入组员姓名'}
                        allowClear
                        loading={AnalyticalUserLoading}
                    >
                        {
                            previewOption(AnalyticalUserList)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={'asset_type'}
                    label={'资产类型'}>
                    <Select
                        size="large"
                        placeholder='请选择资产类型'
                        className="amp-input-sz-l"
                        allowClear
                        loading={assetTypeLoading}
                    >
                        {
                            previewOption(assetTypeList)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={'handover_status'}
                    label={'是否提交'}
                >
                    <Select
                        size="large"
                        className='amp-input-sz-l'
                        allowClear
                    >
                        {
                            previewOption(handoverStatus)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={'analysis_finish'}
                    label={'拍摄完成时间'}
                >
                    <RangePicker
                        disabledDate={disabledDate}
                    />
                </Form.Item>
                <Form.Item
                    className='amp-mg-l-30'
                >
                    <Button
                        className='amp-btn-sz-s amp-blue-border'
                        onClick={submitFormReset}
                    >
                        重置
                    </Button>
                    <Button
                        className='amp-btn-sz-s'
                        type="primary"
                        htmlType='submit'
                        onClick={form.submit}
                    >
                        查询
                    </Button>
                </Form.Item>
            </Form>
            <div className="amp-business-data-button">
                <AuthButton
                    permission="project_handover_log_export"
                    className='amp-btn-sz-s'
                    onClick={submitFormExport}
                >
                    导出
                </AuthButton>
            </div>
        </div>
    );
};
