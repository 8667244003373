import React from 'react';
import RenderItem from './render/renderItem';
import GdMap from '@/components/gaode_map';
import CycleImage from './render/cycleImage'
import './index.less';

/**
 * TopicDescription
 * @param {*} props props
 * @returns {*}
 */
const TopicDescription = (props) => {
    const {
        imagesList,
        detail,
        created_time,
        plan_user_list,
        outline_list,
        scene_list,
        address_list,
    } = props;
    return (
        <div className='amp-topic-description'>
            <div className='amp-topic-description-slideImage'>
                <CycleImage image_list={imagesList} />
            </div>
            <div className='amp-topic-description-details'>
                <RenderItem
                    detail={detail}
                    created_time={created_time}
                    plan_user_list={plan_user_list}
                    outline_list={outline_list}
                    scene_list={scene_list}
                    address_list={address_list}
                />
            </div>
            <div className='amp-topic-description-map'>
                <GdMap marker={address_list} />
            </div>
        </div>
    )
}

export default TopicDescription
