import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PermissionRoute } from "@gUtils";
import {
    UserSettings,
    RoleSettings,
    TargetSettings,
    AnnouncementSettings,
    SideBar,
    AddEditAnnouncement,
    ProjectSettings
} from "./";
import { BlankPage } from "@/components";

export default class Settings extends Component {
    render() {
        return (
            <div className="full-content display-flex">
                <SideBar />
                <div className="side-content mg-t-12">
                    <Switch>
                        <PermissionRoute
                            path="/settings/projects"
                            component={ProjectSettings}
                            permission="project_settings"
                        />
                        <Redirect exact path="/settings" to="/settings/projects" />
                        <PermissionRoute
                            exact
                            path="/settings/users"
                            component={UserSettings}
                            permission="user_settings"
                        />
                        <PermissionRoute
                            exact
                            path="/settings/roles"
                            component={RoleSettings}
                            permission="role_settings"
                        />
                        <PermissionRoute
                            exact
                            path="/settings/target"
                            component={TargetSettings}
                            permission="performance_goal_manage"
                        />
                        <PermissionRoute
                            exact
                            path="/settings/announcements"
                            component={AnnouncementSettings}
                            permission="announcement_settings"
                        />
                        <PermissionRoute
                            exact
                            path="/settings/announcements/:id"
                            component={AddEditAnnouncement}
                            permission="announcement_settings"
                        />
                        <Route component={BlankPage} />
                    </Switch>
                </div>
            </div>
        )
    }
}
