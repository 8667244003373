import React, { useRef } from 'react';
import { $success, AuthButton, Upload, $error } from "@/components";
import { Input, Form, Modal } from 'antd';
import { useSelector } from 'react-redux';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import ImageUploadCheck from '@components/2_data_input/upload/imageUploadCheck'
import './index.less';
const { TextArea } = Input;
const { Item } = Form;

/**
 * 填加批注组件
 * @param {*} props props
 * @returns {*}
 */
const AddAnnotation = (props) => {
    const { modalShow, setModalShow, width } = props;
    const session = useSelector((state) => state.session);
    const formRef = useRef();
    const uploadRef = useRef();
    /**
     * 表单验证通过函数
     * @param {*} values values
     */
    const onFinishHandle = (values) => {
        const request = {};
        const temp = [];
        const { type, businessId } = props;
        values?.imagesList?.map(({file_path, file_url, uid}) => {
            temp.push({
                filePath: file_path,
                fileUrl: file_url,
                fileUid: uid,
            })
        })
        request.type = type;
        request.businessId = businessId;
        request.imagesList = temp;
        request.comment = values?.comment;
        SelectedTopicApi().createTopicAnnotation({
            ...request,
        }).then(() => {
            props.getAnnotationList(); // 重新获取批注
            closeHandle();
            return $success("添加成功");
        }).catch((err) => {
            return $error(err?.message || "添加失败");
        })
    }

    /**
     * 弹框关闭
     */
    const closeHandle = () => {
        formRef.current.setFieldsValue({
            comment: "",
            imagesList: [],
        });
        setModalShow(false);
    }

    return (
        <Modal
            visible={modalShow}
            width={width || 200}
            title={'添加批注'}
            centered
            className='amps-add-annotation'
            onCancel={() => { closeHandle() }}
            footer={null}
        >
            <Form
                name='add-annotation'
                initialValues={{
                    annotationPerson: session?.name,
                }}
                ref={formRef}
                onFinish={onFinishHandle}
            >
                <div className='amp-annotation-item'>
                    <span className='amp-annotation-item-title amp-mg-b-10'>批注人</span>
                    <Item name={'annotationPerson'}><Input disabled className='amp-w-50' /></Item>
                </div>
                <div className='amp-annotation-item'>
                    <span className='amp-annotation-item-title required amp-mg-b-10'>批注信息</span>
                    <Item
                        name={'comment'}
                        rules={[
                            {
                                required: true,
                                message: '请填写批注信息',
                            },
                        ]}
                    >
                        <TextArea
                            size='large'
                            maxLength={500}
                            placeholder={'请输入批注内容'}
                            className='amp-h-86'
                        />
                    </Item>
                </div>
                <div className='amp-annotation-item'>
                    <span className='amp-annotation-item-title'>图片</span>
                    <Item name={'imagesList'}>
                        <ImageUploadCheck
                            maxCount={3}
                            fileSize={20}
                            hidenMain
                            purpose={"topic"}
                        />
                    </Item>
                </div>
                <div className='amp-annotation-item amp-annotation-operator'>
                    <Item>
                        <AuthButton
                            type='primary'
                            htmlType="submit"
                            className='btn-sz-s amp-mg-r-12'
                        >提交</AuthButton>
                        <AuthButton
                            onClick={() => { closeHandle() }}
                            className='btn-sz-s'
                        >取消</AuthButton>
                    </Item>
                </div>
            </Form>
        </Modal>
    );
}

export default AddAnnotation;

