import { useCallback, useEffect, useRef } from 'react'
import { serialize } from './serialize'
import { isUndefined, rAF } from './helper'
import { useStateWithDeps } from './state'
import { SWRGlobalState } from './global-state'
import { compare } from './compare'
import { withArgs } from './resolve-args'
import { subscribeCallback } from './subscribe-key'
import { MUTATE_EVENT } from './revalidate-events'
import { internalMutate } from './mutate'
import { broadcastState } from './broadcast-state'
import { getTimestamp } from './timestamp'

const WITH_DEDUPE = { dedupe: true };
/**
 * 参考的swr工具, config配置项目的功能没有完全实现
 * @param {null|D|D[]|(() => D|D[])} _key  请求唯一标识，string / function / array / null
 * @param {(param: D|D[]) => Promise<T>} fetcher  获取数据方法，形参为_key或者_key数组的结构，或_key函数执行。。。
 * @param {object} config swr的配置项目
 * @param {T} config.fallbackData 默认值
 * @param {boolean} config.revalidateOnMount [config.revalidateOnMount=true] 组件挂载重新请求
 * @param {boolean} config.revalidateIfStale [config.revalidateIfStale=true]  已经存在请求成功是否重新请求
 * @param {boolean} config.shouldRetryOnError [config.shouldRetryOnError=true] 禁用请求错误自动重试
 * @param {number} config.dedupingInterval [config.dedupingInterval=2000] 请求错误重试时间
 * @param {(error:Error, key:D, config, revalidate, { retryCount }) => undefined} config.onErrorRetry 请求重试方法
 * @param {(data: D) => void} config.onSuccess 请求成功
 * @param {(error: Error) => void} config.onError 请求失败
 * @param {boolean} config.suspense react.Suspense组件 功能 需要在withSuspense HOC 的作用域下使用
 * @returns {{mutate: (data?:T, shouldRevalidate?:boolean) => Promise<T>, readonly data: T, readonly error: undefined|Error, readonly isValidating: boolean}}
 * mutate:重新请求; data: 请求成功返回的数据; error: 请求失败返回的数据; isValidating: 是否正在请求（注意：data存在也有可能也会在请求）
 */
const useSwr = function(_key, fetcher, config) {
    const {
        cache,
        fallbackData,
        revalidateOnMount,
        revalidateIfStale,
        suspense
    } = config;
    const [EVENT_REVALIDATORS, STATE_UPDATERS, MUTATION, FETCH] =
        SWRGlobalState.get(cache);

    const [key, fnArgs, keyInfo] = serialize(_key);
    const initialMountedRef = useRef(false);
    const unmountedRef = useRef(false);
    const keyRef = useRef(key);
    const fetcherRef = useRef(fetcher);
    const configRef = useRef(config);
    const getConfig = () => configRef.current;

    const patchFetchInfo = (info) => cache.set(keyInfo,
        { ...cache.get(keyInfo), ...info });

    const cached = cache.get(key);

    const data = isUndefined(cached) ? fallbackData : cached;
    const info = cache.get(keyInfo) || {};
    const error = info.error;
    const isInitialMount = !initialMountedRef.current;

    // 是否应该重新验证
    const shouldRevalidate = () => {
        if (isInitialMount && !isUndefined(revalidateOnMount)) {
            return revalidateOnMount;
        }

        if (suspense) {
            return isUndefined(data) ? false : revalidateIfStale;
        }

        return isUndefined(data) || revalidateIfStale;
    };
    // 正在验证的状态
    const resolveValidating = () => {
        if (!key || !fetcher) { return false; }
        return !!info.isValidating;
    };
    const isValidating = resolveValidating();

    const [stateRef, stateDependencies, setState] = useStateWithDeps(
        {
            data,
            error,
            isValidating
        },
        unmountedRef
    );
    const revalidate = useCallback(
        async (revalidateOpts) => {
            const currentFetcher = fetcherRef.current;
            if (
                !key
                || !currentFetcher
                || unmountedRef.current
            ) {
                return false;
            }
            let newData;
            let startAt;
            const opts = revalidateOpts || {};
            const shouldStartNewRequest = !FETCH[key] || !opts.dedupe;

            const isCurrentKeyMounted = () => !unmountedRef.current
                && key === keyRef.current
                && initialMountedRef.current;

            const cleanupState = () => {
                const requestInfo = FETCH[key];
                if (requestInfo && requestInfo[1] === startAt) {
                    delete FETCH[key];
                }
            };

            const newState = { isValidating: false };

            const finishRequestAndUpdateState = () => {
                patchFetchInfo({ isValidating: false });
                if (isCurrentKeyMounted()) {
                    setState(newState);
                }
            };

            patchFetchInfo({ isValidating: true });
            setState({ isValidating: true });

            try {
                if (shouldStartNewRequest) {
                    broadcastState(
                        cache,
                        key,
                        stateRef.current.data,
                        stateRef.current.error,
                        true
                    );
                    FETCH[key] = [currentFetcher(...fnArgs), getTimestamp()];

                }
                [newData, startAt] = FETCH[key];
                newData = await newData;

                if (shouldStartNewRequest) {
                    setTimeout(cleanupState, config.dedupingInterval);
                }
                // 解决时序问题 永远只更新最新的
                if (!FETCH[key] || FETCH[key][1] !== startAt) {
                    return false;
                }

                patchFetchInfo({
                    error: undefined
                });

                newState.error = undefined;

                const mutationInfo = MUTATION[key];

                if (
                    !isUndefined(mutationInfo) &&
                    (startAt <= mutationInfo[0] ||
                        startAt <= mutationInfo[1] ||
                        mutationInfo[1] === 0)
                ) {
                    finishRequestAndUpdateState();
                    return false;
                }

                if (!compare(stateRef.current.data, newData)) {
                    newState.data = newData;
                } else {
                    newState.data = stateRef.current.data;
                }
                if (!compare(cache.get(key), newData)) {
                    cache.set(key, newData);
                }
                if (shouldStartNewRequest) {
                    if (isCurrentKeyMounted()) {
                        getConfig()
                            .onSuccess
                            ?.(newData, key, config);
                    }
                }
            } catch (error) {
                cleanupState();
                error._key = _key;
                patchFetchInfo({ error });
                newState.error = error;

                if (shouldStartNewRequest && isCurrentKeyMounted()) {
                    getConfig()
                        .onError(error, key, config);
                    // 错误重新验证
                    if ((typeof config.shouldRetryOnError === 'boolean' &&
                            config.shouldRetryOnError) ||
                        (typeof config.shouldRetryOnError === 'function' &&
                            config.shouldRetryOnError(error))) {
                        getConfig()
                            .onErrorRetry(error, key, config, revalidate, {
                                retryCount: (opts.retryCount || 0) + 1,
                                dedupe: true
                            });
                    }
                }
            }
            finishRequestAndUpdateState();
            // 发布本条key 的更新
            if (isCurrentKeyMounted() && shouldStartNewRequest) {
                broadcastState(cache, key, newState.data, newState.error, false);
            }
            return true;
        },
        [key]
    );
    const boundMutate = useCallback(
        internalMutate.bind(undefined, cache, () => keyRef.current),
        []
    );

    useEffect(() => {
        fetcherRef.current = fetcher;
        configRef.current = config;
    });

    useEffect(() => {
        if (!key) { return () => {}; }

        const keyChanged = key !== keyRef.current;
        const softRevalidate = revalidate.bind(undefined, WITH_DEDUPE);

        // 更新状态
        function onStateUpdate(data, error, isValidating) {
            setState(
                Object.assign({},
                    {
                        error,
                        isValidating
                    },
                    compare(stateRef.current.data, data)
                        ? undefined
                        : {
                            data
                        }
                )
            );
        }

        // 重新验证
        const onRevalidate = (type) => {
            if (type === MUTATE_EVENT) {
                return revalidate();
            }
        };

        const unsubUpdate = subscribeCallback(key, STATE_UPDATERS, onStateUpdate);
        const unsubEvents = subscribeCallback(key, EVENT_REVALIDATORS, onRevalidate);

        unmountedRef.current = false;
        keyRef.current = key;
        initialMountedRef.current = true;

        if (keyChanged) {
            setState({
                data,
                error,
                isValidating
            });
        }

        if (shouldRevalidate()) {
            if (isUndefined(data)) {
                softRevalidate();
            } else {
                rAF(softRevalidate);
            }
        }

        return () => {
            unsubUpdate();
            unsubEvents();
            unmountedRef.current = true;
        };
    }, [key, revalidate]);

    if (suspense && isUndefined(data) && key) {
        fetcherRef.current = fetcher;
        configRef.current = config;
        unmountedRef.current = false;
        throw isUndefined(error) ? revalidate(WITH_DEDUPE) : error;
    }
    return {
        mutate: boundMutate,
        get data() {
            stateDependencies.data = true;
            return data;
        },
        get error() {
            stateDependencies.error = true;
            return error;
        },
        get isValidating() {
            stateDependencies.isValidating = true;
            return isValidating;
        }
    };
};

export const unstable_serialize =(key) => serialize(key)[0]

export default withArgs(useSwr);
