import { Image } from "antd";
import { BasicImage } from '@components';

export const HistoryHeader = (props) => {
  const { history_created_at, history_name } = props;
  return history_created_at ? (
    <div>
      <span className="mg-r-10">{history_created_at.replace(/-/g, "/")}</span>
      <span>{history_name}</span>
    </div>
  ) : (
    ""
  );
};

export const HistoryImages = (props) => {
  const { image_urls } = props;
  const displayImages = image_urls
    ? image_urls?.map((url, idx) => (
        <BasicImage width={110} height={110} src={url} key={idx} />
      ))
    : "";
  return (
    <div>
      <div style={{ width: 56 }}>
        {props.title}
      </div>
        <div className="history-images">{displayImages}</div>
    </div>
  );
};
