
import { Form, Button } from 'antd';
import { passEncrypt } from "@/utils/global/session_util";
import { AuthButton, $error} from '@/components'

const AddEditUserFooter = (props) => {
  const { type, data, form } = props;

  const handleSave = () => {
    form.current.validateFields().then((values) => {
      formInput();
    });
  }

  const formInput = () => {
    let val = props.form.current.getFieldsValue();
    let data = {
      id: props.data.id,
      username: val.username,
      name: val.name,
      password: val.password ? passEncrypt(val.password) : null,
      status: 1,
      email: val.email,
      group_id: val.group_id,
      role_id: val.role_id
    }
    var reg = new RegExp("[\u4E00-\u9FFF]+","g");
    if (reg.test(val.password)) {
      return  $error('密码须为6-20位的数字字母组合');;
    }
    props.handleSubmit(data, type);
  }

  const handleDel = () => {
    props.handleShowDel(data);
  }

  const cancel = () => {
    props.form.current.resetFields();
    props.handleCancel();

  }

  const handleEditPsd = () => {
    props.handleEditPsd();
  }

  return (
    <div className="user-dialog-footer">
      <Form.Item>
        <AuthButton permission={type === 'edit' ? "user_update" : "user_create"} className="" type="primary" htmlType="submit" onClick={handleSave}>
          完成
        </AuthButton>
        <AuthButton className=" mg-l-20" htmlType="button" onClick={cancel}> 取消</AuthButton>
        {
          type === 'edit' ? (
            <AuthButton permission="user_update" className=" mg-l-20" htmlType="button" onClick={handleEditPsd}>
              修改密码
          </AuthButton>
          ) : null
        }
        {
          type === 'edit' ?
            (
              <AuthButton permission="user_delete" className=" mg-l-20" onClick={handleDel}>
                删除用户
              </AuthButton>
            )
            :
            null
        }

      </Form.Item>
    </div>
  );
}
export default AddEditUserFooter;