/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/3/23
 * @description: 时间选择器
 */
import { DatePicker as AnDatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = AnDatePicker;
const DatePicker = function(props) {
    const {value, onChange, ...other} = props
    let initialValue = value?moment(value):null

    return <AnDatePicker value={initialValue} onChange={(value) => {
        if(value){
            onChange(value.toDate())
        } else {
            onChange(undefined)
        }
    }
    }  {...other} />
}
DatePicker.RangePicker = function(props) {
    const {value, onChange, ...other} = props
    let initialValue = undefined
    if( Array.isArray(value)&&value.length){
        initialValue = value.map(i => moment(i))
    } else {
        initialValue = undefined
    }
    return <RangePicker value={initialValue} onChange={(value) => {
        if(value){
            if(Array.isArray(value) && value.length){
                onChange(value.map(i => i.toDate()))
            } else if(value){
                onChange(value.toDate())
            } else {
                onChange(undefined)
            }
        } else {
            onChange(undefined)
        }
    }
    }  {...other} />
}
export default DatePicker
