import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'
import SummaryFilter from './filter'
import SummaryTable from './table'
import moment from 'moment'
import { RenderTabs } from '@views/4_stats/2_summary/RenderTabs'

@withRouter
@inject("summaryStore")
@observer
class Summary extends React.Component {
  get store() {
    return this.props.summaryStore;
  }
  get paramFilter() {
    const { search } = this.props.location;
    const filter =
      search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
    return filter || {};
  }
  get currentTab() {
    if(location.pathname.includes('/all')){
      return 'all'
    } else if(location.pathname.includes('/user')){
      return 'user'
    }
    return null
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  componentDidMount() {
    this.setupTab();
    this.setupDate();
    this.setupStatType();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setupDate();
      this.setupStatType();
    }
  }

  setDefaultdate = () => {
    let filter, date;
    const from = moment().subtract(24, "week").startOf("week");
    const to =
      this.statType === "art"
        ? moment().subtract(1, "months").endOf("month")
        : moment().subtract(1, "week").startOf("week");
    date = [from, to];
    filter = { date };
    this.props.history.push({ search: `?filter=${JSON.stringify(filter)}` });
  };
  setupTab = () => {
    this.store.updateTab(this.currentTab);
  };
  setupStatType = () => this.store.updateStatType(this.statType);
  setupDate = () => {
    const { date, dateType } = this.paramFilter;
    if (dateType) {
      this.store.updateDateType(parseInt(dateType));
    }
    if (date) {
      const res = date?.map((item) => moment(item));
      this.store.updateDate(res);
    } else {
      this.setDefaultdate();
    }
  };

  handleChange = (key) => {
    const { statType } = this.store;
    this.store.updatePage(1);
    this.store.updateDateType(2);
    this.store.updateTab(key);
    this.props.history.push(`/stats/${statType}/${statType}-summary/${key}`);
  };

  render() {
    return (
      <div className={'page-container'}>
        <RenderTabs
          activeKey={this.currentTab}
          onChange={this.handleChange} />
        <SummaryFilter setDefaultdate={this.setDefaultdate} />
        <SummaryTable />
      </div>
    );
  }
}
export default Summary;
