import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Drawer, Form, Input, InputNumber, Tooltip } from 'antd';
import { $error, AuthButton, Iconfont } from '@components';
import {
    historyFlowSteps,
    historyFlowStepsWithoutCollectScore,
    previousHistoryFlowSteps,
    processDrawerKeys,
    taskActionLabels,
    operationFlowButtonText
} from '@vars/views/task_flow_vars';
import { autorun } from 'mobx';
import TaskProcessContent from './task_process_content';
import { bulkSetFormData, formatImageFiles, isEmpty } from '@gUtils';
import { taskFlowAPI, accountAPI, configsAPI } from '@api';


@inject('taskProcessStore', 'taskStore')
@observer
class TaskProcessDrawer extends Component {
    uploadRef = React.createRef();
    formRef = React.createRef();
    contentRef = React.createRef();
    drawerRef = React.createRef();

    get store() {
        return this.props.taskProcessStore;
    }

    get action() {
        return this.props.taskProcessStore.action;
    }

    get currentTask() {
        return this.props.taskStore.currentTask;
    }

    async componentDidMount() {
        this.listenToActionChanges();
        // 获取当前资产待入库历史数据，编辑与回显数据，首次入库也需要请求
        // 素材类型，泛素材，项目素材
        const { materialType } = this.store.actionDispose
        if (this.action && this.action === 'file_to_stock') {
            const res = await taskFlowAPI.getGodownEntry({
                task_id: this.currentTask.id,
                material_type: materialType,
            })
            if (res.code === 1000) {
                let data = {}
                Object.keys(res.data).forEach(item => {
                    if(res.data[item]){
                        data[item] = res.data[item]
                    }
                })
                const {
                    assets_package_uri,
                    review_images = [],
                    ...dataJson
                } = data
                let upload_response = undefined
                if (assets_package_uri) {
                    upload_response = {
                        Key: assets_package_uri,
                        Location: assets_package_uri
                    }
                }
                const reviewImages = review_images?.map(item => {
                    return {
                        ...item,
                        uid: item.digital_asset_id || item.file_name,
                        status: 'done'
                    }
                })
                this.formRef.current.setFieldsValue({
                    ...dataJson,
                    'upload_response': upload_response,
                    'review_images': reviewImages,
                    'material_type': materialType
                })
            }else{
                this.formRef.current.setFieldsValue({
                    'material_type': materialType
                })
            }
        }
        // 工程文件待审通过时approve_file待处理人默认为当前用户
        // 判断可选用户数组是否存在当前用户
        if (this.action && this.action === "approve_file") {
            // 当前用户id
            const userInfo = await accountAPI.fetchProfile()
            const relatedUsers = await configsAPI.fetchRelatedUsers({ filter: { action: this.action } })
            if (userInfo.code === 1000 && relatedUsers.code === 1000) {
                let topUserId;
                if (relatedUsers?.data?.list?.some(item => item.user_id == userInfo?.data?.id)) {
                    topUserId = userInfo?.data?.id
                }
                this.formRef.current.setFieldsValue({
                    'assignee_ids': topUserId,
                })
            }
        }
    }

    componentWillUnmount = () => {
        this.store.hide();
    };

    listenToActionChanges = () => {
        autorun(() => {
            if (
                this.props.taskProcessStore.action &&
                this.props.taskProcessStore.action === 'discard_task'
            ) {
                this.props.taskProcessStore.fetchDiscardReasons();
            }
        });
    };

    onClose = () => {
        this.store.hide();
    };

    handleConfirm = () => {

        // console.log('请求数据', this.formRef.current.getFieldsValue())
        const { action } = this.store;
        const required = action.includes("submit");
        // const flag = (isEmpty(this.formRef.current.getFieldValue('data'))); // 拆分和新增任务字段数据为空判断
        this.formRef.current.validateFields().then((res) => {
            if (required && this.uploadRef.current?.state?.fileList?.length === 0) {
                $error('请上传参考图')
                throw new Error('请上传参考图')
            } else {
                return res
            }
        })
            .then((res) => {
                let params = {};
                for (const key in res) {
                    if (res[key]) {
                        if (key === 'assignee_ids') {
                            params[key] = [res[key]];
                        } else {
                            params[key] = res[key];
                        }
                    }
                }
                if (action === "score_produce") {
                    let arr = []
                    if (res.data) {
                        for (const key in res.data) {
                            arr.push(res.data[key])
                        }

                    } else {
                        let obj = {
                            title: this.currentTask.asset_name,
                            score: res.score,
                            kind: this.currentTask?.asset_type?.code,
                        }
                        arr.push(obj)
                    }
                    params["data"] = arr
                }
                // if (!flag) return; // 拆分任务字段为空时return
                this.handleRequest(params)
                    .then(() => {
                        this.props.updateTaskRelated();
                        this.props.updateTask();
                    })
                    .then(() => {
                        this.props.taskStore.updateRefreshed(true);
                    });
            });
    };

    handleRequest = async (params) => {
        let actionKey = this.action.split('_')[0];
        if (this.action === "update_kind_task") {
            // 修改资产ID
            actionKey = "update_kind_task";
        } else if (
            this.action === "approve_primitive" ||
            this.action === "approve_analysis" ||
            this.action === "approve_production_result" ||
            this.action === "reassign_production"
        ) {
            // 通过原序列待审、解析待审、制作终审待审
            actionKey = "special_review";
        } else if (this.action === "duplicate_task") {
            // 新增任务
            actionKey = "duplicate_task";
        } else if (this.action === "update_produce_score") {
            // 修改评分
            actionKey = "update_produce_score";
        } else if(this.action === "remake_asset_godown") {
            // 入库重置
            actionKey = "remake_asset_godown"
        }
        switch (actionKey) {
            case "update_kind_task":
                // 修改资产ID
                return this.store.updateKindTask(params);
            case "special_review":
                // 原序列待审、解析待审通过、制作终审待审、待制作转派任务时单独请求
                return this.store.specialReview(params);
            case "update_produce_score":
                // 制作终审待审修改评分
                return this.store.updateProduceScore(params);
            case "duplicate_task":
                // 新增任务
                return this.store.splitInProduceFinal(params)
            case 'score':
                return this.store.productionScore(params)
            case 'approve':
            case 'disapprove':
                return this.handleReviewRequest(params);
            case 'initiate':
                return this.store.startTask(params);
            case 'reassign':
            case 'assign':
                return this.store.manipulateTask(params);
                //回退功能接口
            case 'rollback':
                return this.rollbackAction(params)
            case 'stop':
                return this.store.stopTask(params);
            case 'submit':
                return this.handleSubmitRequest(params);
            case 'remake':
                return this.store.remakeTask(params);
            case 'discard':
                return this.store.discardTask(params);
            case 'update':
                return this.updateCollectCount(params);
            case 'file':
                //入库请求处理
                return this.createGodownEntry(params)
            case 'remake_asset_godown':
                return this.store.godownEntryReset(params)
            default:
                return;
        }
    };

    //回退接口拼接数据，判断制定回退/上一步
    rollbackAction = (data) => {
        const params = { comment: data.comment }
        if (data.is_appoint_status === 1) {
            //上一步
            params.task_status = this.store?.next_status
        } else {
            params.task_status = data.appoint_status
        }
        return this.store.taskRollBack(params);
    }

    handleReviewRequest = (params) => {
        const hasImage = this.action.includes('disapprove');
        let formData = new FormData();
        if (hasImage) {
            formData = this.handleFormImage(formData);
        }
        formData = bulkSetFormData(params, formData);
        return this.store.reviewTask(formData);
    };

    handleFormImage = (formData) => {
        const fileList = this.contentRef?.current?.getFileList();
        if (fileList && fileList.length < 1 && this.action.includes('submit')) {
            return $error('请上传参考图');
        }
        formData = formatImageFiles(fileList, formData);
        return formData;
    };

    handleSubmitRequest = (params) => {
        const hasImage = ['submit_for_analysis', 'submit_production_art', 'submit_file'].includes(
            this.action
        );
        const hasTwoAssignees = this.action === 'submit_production_art';
        let formData = new FormData();
        if (hasImage) {
            formData = this.handleFormImage(formData);
        }
        if (hasTwoAssignees) {
            const {
                assignee_A,
                assignee_B,
                ...rest
            } = params;
            formData.append('assignee_ids', assignee_A);
            formData.append('assignee_ids', assignee_B);
            params = { ...rest };
        } else {
            const {
                assignee_ids,
                ...others
            } = params;
            formData.append('assignee_ids', assignee_ids[0]);
            params = { ...others };
        }
        formData = bulkSetFormData(params, formData);
        return this.store.submitTask(formData);
    };

    updateCollectCount = (params) => {
        let formData = new FormData();
        const fileList = this.contentRef?.current?.getFileList();
        if (fileList && fileList.length > 0) {
            formData = formatImageFiles(fileList, formData);
        }
        formData = bulkSetFormData(params, formData);
        return this.store.updateCollectCount(formData);
    }

    createGodownEntry = (params) => {
        params.assets_package_uri = params?.upload_response?.Key || ''
        params.upload_response = JSON.stringify(params?.upload_response)
        params.review_images = params.review_images.map(item => {
            return {
                file_url: item.file_url,
                digital_asset_id: item.digital_asset_id,
                file_path: item.file_path,
                file_name: item.file_name,
                review_image_type: item.review_image_type
            }
        })
        return this.store.createGodownEntry(params)
    }

    renderNext = () => {
        const next_status = this.store?.next_status;
        const type = this.props.taskStore.currentTask?.task_package_item?.type;
        let label = '';
        if (type === 1) {
            historyFlowStepsWithoutCollectScore.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        } else if (type === 2) {
            historyFlowSteps.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        } else {
            previousHistoryFlowSteps.map((item) => {
                if (item.id === next_status) {
                    label = item.label;
                }
            });
        }
        let nextDisplay =
            next_status === 18 ? '入库' : next_status === 19 ? '作废' : label;
        if (this.action === 'duplicate_task') {
            nextDisplay = "待制作" // 新增任务的action
        }
        return (
            <div
                className={
                    this.action === 'approve_analysis'
                        ? 'process-form-col-1'
                        : 'process-form-col-2'
                }
            >
                <div className='mg-b-8 bold'>下一步</div>
                <Input disabled value={nextDisplay} className='input-sz-m mg-b-16' />
            </div>
        );
    };

    renderDifficulty = () => {
        const message = '请输入难度系数';
        const isFileAssign = this.action === 'assign_producer';
        const isReviewArt = this.action.includes('approve_production_art');
        const initialValue = isReviewArt ? this.currentTask.difficulty : null;
        const validator = (_, value) => {
            if (!value && value !== 0) {
                return Promise.reject(message);
            }
            if (value < initialValue) {
                return Promise.reject('不能低于原有难度系数，请重新填写');
            }
            return Promise.resolve();
        };
        if (isFileAssign || isReviewArt) {
            return (
                <div className='process-form-col-2'>
                    <div className='align-center mg-b-8'>
                        <div className='bold required mg-r-5'>难度系数</div>
                        <Tooltip placement='bottomLeft' title='数值区域0～14'>
                            <Iconfont
                                type='icon-list_prompt'
                                className='icon-12 cursor-pointer'
                            />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name='score'
                        rules={[
                            {
                                required: true,
                                validator: validator
                            }]}
                        initialValue={initialValue}
                    >
                        <InputNumber
                            step={0.5}
                            style={{ width: '210px' }}
                            size='large'
                            placeholder={message}
                            max={14}
                            min={0}
                            precision={2}
                        />
                    </Form.Item>
                </div>
            );
        } else {
            return '';
        }
    };

    renderComment = () => {
        // 回退原因和备注共用，备注非必填，回退原因仅“待制作”“入库重置”回退为必填。
        const required = ['rollback_production', 'remake_asset_godown']
        const { action } = this.store;
        const flag = required.includes(action)
        const temp = action.indexOf("rollback") > -1
        const title = temp ? "回退原因" : "备注";
        const placeholderText = temp ? "请输入回退原因" : "请输入备注";
        const IsVisible = action !== "score_produce"  // 制作待评分不显示
                        && action !== "duplicate_task" // 新增任务不显示
                        && action !== "update_produce_score" // 修改评分不显示
        return (
            IsVisible && (
                <div className="process-form-col-1">
                    <div className={flag ? "mg-b-8 bold required" : "mg-b-8 bold"}>{title}</div>
                    <Form.Item
                        name="comment"
                        rules={[{
                            required: flag,
                            message: `请认真填写${title}`,
                        }]}
                    >
                        <Input.TextArea
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            placeholder={placeholderText}
                            maxLength={500}
                        />
                    </Form.Item>
                </div>
            )
        );
    };

    // Form 表单字段变化回调函数
    handleValuesChange = (changeVal) => {
        const arr = Object.keys(changeVal);
        const flag = arr.indexOf("split_task") > -1 || arr.indexOf("new_split_task") > -1; // 判断当前改变的字段是否包含拆分任务和新增任务字段
        if (flag) {
            const currentAction = this.action === "score_produce"; // 判断当前的action是否为拆分任务
            const num = currentAction ? changeVal["split_task"] : changeVal["new_split_task"]; // 控制当前拆分数量
            const arr = Array(num || 1).fill({
                "kind": this.currentTask?.asset_type?.code // 给类型传入默认值
            });
            this.formRef.current.setFieldsValue({
                "data": arr, // 更新拆分任务数量
            });
        }
    }

    renderForm = (flag) => {
        let next = true
        if (this.action.split('_')[0] === 'rollback') next = false
        if (this.action === 'update_collect_image') next = false
        if (this.action === 'file_to_stock') next = false
        if (this.action === "update_kind_task") next = false
        if (this.action === "update_produce_score") next = false
        if (this.action === "remake_asset_godown") next = false
        return (
            <Form
                ref={this.formRef}
                onValuesChange={(changeVal) => {
                    // 监听Form表单的字段变化
                    this.handleValuesChange(changeVal);
                }}
                scrollToFirstError
                size="large"
            >
                <div className='process-form'>
                    {next && this.renderNext()}
                    {/* {this.renderDifficulty()} */}
                    <TaskProcessContent ref={this.contentRef} formRef={this.formRef} uploadRef={this.uploadRef} />
                    {!flag && this.renderComment()}
                </div>
            </Form>
        );
    };

    getFileList = async () => {
        let res = this.uploadRef.current.state.fileList;
        let imgs = [];
        res.forEach((item) => {
            imgs.push(new Promise(resolve => {
                const img = new Image();
                item.task_image = URL.createObjectURL(item.originFileObj);

                img.src = item.task_image;
                img.onload = (e) => {
                    let _img = e.target;
                    item.aspect_ratio = ((_img.width / _img.height) || 0).toFixed(2);
                    resolve(item);
                };
                img.onerror = (err) => {
                    item.aspect_ratio = '0';
                    resolve(item);
                };
            }));
        });
        let data = await Promise.all(imgs);
        this.props.taskStore.updateReloadImgSrc(data);
    };

    renderContent = () => {
        const {
            actionState,
            action
        } = this.store;
        const flag = action === 'score_produce' || action === 'approve_photo_collection_score' || action === "update_kind_task";
        const reFlag = action === 'submit_production_art';

        //根据action类型获取确认confirmBtn，取消cancelBtn按钮文字
        const [confirmBtn, cancelBtn] = operationFlowButtonText(action)
        return (
            <div className='amp-in-drawer'>
                <div className="bold mg-b-16">{taskActionLabels(action)}</div>
                {this.renderForm(flag)}
                <div className='amp-in-drawer-button'>
                    <AuthButton
                        type='primary'
                        className='mg-r-12'
                        onClick={this.handleConfirm}
                        loading={actionState === 'pending'}
                    >
                        {confirmBtn}
                    </AuthButton>
                    <AuthButton onClick={() => this.store.hide()}>
                        {cancelBtn}
                    </AuthButton>
                    {
                        reFlag && (
                            <AuthButton
                                type='primary'
                                className='mg-l-12'
                                onClick={async () => {
                                    await this.getFileList();
                                    this.props.showWaterfall();
                                }}
                            >
                                瀑布流预览
                            </AuthButton>
                        )
                    }
                </div>
            </div>
        );
    };

    render() {
        const { visible } = this.store;
        let height = '100%'
        if (this.drawerRef.current) {
            height = this.drawerRef.current.clientHeight + 70
        }
        return (
            <Drawer
                placement='bottom'
                closable={false}
                onClose={this.onClose}
                visible={visible}
                getContainer={false}
                mask={false}
                height={height}
                style={{ maxHeight: '70vh' }}
                destroyOnClose
            >
                <div ref={this.drawerRef}>
                    {this.renderContent()}
                </div>
            </Drawer>
        );
    }
}

export default TaskProcessDrawer;
