import React from 'react';
import { Modal, Spin } from 'antd';
import ReactDOM from 'react-dom';
import {
    CloseOutlined,
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from '@ant-design/icons';
import { clsx } from '@gUtils';
import '@/stylesheets/components/preview_image.less';

const prefixCls = 'amps-preview-image';
export const icons = {
    rotateLeft: <RotateLeftOutlined />,
    rotateRight: <RotateRightOutlined />,
    zoomIn: <ZoomInOutlined />,
    zoomOut: <ZoomOutOutlined />,
    close: <CloseOutlined />,
    left: <LeftOutlined />,
    right: <RightOutlined />
};
const closeFns = [];

class PreviewImage extends React.Component {
    static open(props) {
        const container = document.createDocumentFragment();
        let dialogRef = null;

        function close() {
            dialogRef?.onCancel();
        }

        function afterClose() {
            ReactDOM.unmountComponentAtNode(container);
            const index = closeFns.indexOf(close);
            if (index > -1) {
                closeFns.splice(index, 1);
            }
        }

        setTimeout(() => {
            ReactDOM.render(<PreviewImage
                ref={(ref) => { dialogRef = ref; }}
                afterClose={() => afterClose()}
                {...props}
            />, container);
            closeFns.push(close);
        });
        return close;
    }

    static closeAll() {
        closeFns.forEach((i) => i?.());
    }

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            scale: 1,
            current: props.current || 0,
            imgMaps: new Map()
        };
        this.wheelHandler = this.onWheelMove.bind(this);
    }

    componentDidMount() {
        const { current } = this.state;
        this.requestAsyncImg(current);
        window.addEventListener('wheel', this.wheelHandler, {
            passive: false
        });
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.wheelHandler, {
            passive: false
        });
    }

    onCancel() {
        this.setState({
            visible: false
        });
    }

    onSwitchLeft() {
        const { current } = this.state;
        if (current > 0) {
            this.setState({
                current: current - 1
            });
            this.requestAsyncImg(current - 1);
        }
    }

    onSwitchRight() {
        const { current } = this.state;
        const { images } = this.props;
        if (current < images.length - 1) {
            this.setState({
                current: current + 1
            });
            this.requestAsyncImg(current + 1);
        }
    }

    onZoomOut() {
        const { scale } = this.state;
        if (scale > 1) {
            this.setState({ scale: scale - 1 });
        }
    }

    onZoomIn() {
        const { scale } = this.state;
        this.setState({ scale: scale + 1 });
    }

    onWheelMove(event) {
        event.preventDefault();
        const wheelDirection = event.deltaY;
        if (wheelDirection > 0) {
            this.onZoomOut();
        } else if (wheelDirection < 0) {
            this.onZoomIn();
        }
    }

    requestAsyncImg(current) {
        const {
            request,
            images
        } = this.props;
        const { imgMaps } = this.state;
        if (request && !imgMaps.has(current)) {
            if (images[current]?.indexOf('data:image/') > -1) {
                const newMap = new Map(imgMaps).set(current, {
                    loading: false,
                    url: images[current]
                });
                this.setState({ imgMaps: newMap });
            } else {
                const newMap = new Map(imgMaps).set(current, {
                    loading: true
                });
                this.setState({ imgMaps: newMap });
                request(images[current])
                    .then((res) => {
                        const newMap = new Map(imgMaps).set(current, {
                            url: res,
                            loading: false
                        });
                        this.setState({ imgMaps: newMap });
                    })
                    .catch((error) => {
                        const newMap = new Map(imgMaps).set(current, {
                            error,
                            loading: false
                        });
                        this.setState({ imgMaps: newMap });
                    });
            }
        }
    }

    modalRender() {
        const {
            current,
            imgMaps,
            scale
        } = this.state;
        const {
            images,
            renderDesc
        } = this.props;
        const currentImgMap = imgMaps.get(current) || {};
        const {
            loading,
            error,
            url
        } = currentImgMap;
        const showLeftOrRightSwitches = images?.length > 1;
        const tools = [
            {
                icon: icons.close,
                onClick: () => this.onCancel(),
                type: 'close'
            },
            {
                icon: icons.zoomIn,
                onClick: () => this.onZoomIn(),
                type: 'zoomIn'
            },
            {
                icon: icons.zoomOut,
                onClick: () => this.onZoomOut(),
                type: 'zoomOut',
                disabled: scale === 1
            }
        ];
        return (
            <div className={prefixCls}>
                <ul className={`${prefixCls}-operations`}>
                    {tools.map(({
                        icon,
                        onClick,
                        type,
                        disabled
                    }) => (
                        <li
                            className={clsx(`${prefixCls}-operations-operation`, {
                                [`${prefixCls}-operations-operation-disabled`]: !!disabled
                            })}
                            key={type}
                        >
                            <div onClick={onClick}>
                                {React.cloneElement(icon, { className: `${prefixCls}-operations-icon` })}

                            </div>
                        </li>
                    ))}
                </ul>
                <div className={`${prefixCls}-img-wrapper`}>
                    {url && (
                        <img
                            className={`${prefixCls}-img`}
                            style={{
                                transform: `scale3d(${scale}, ${scale}, 1)`
                            }}
                            src={url}
                            alt="原图"
                        />
                    )}
                    {loading && <div className={`${prefixCls}-loading`}><Spin size="large" /></div>}
                    {error && <p className={`${prefixCls}-error`}>图片加载错误</p>}
                </div>
                <div>
                    {showLeftOrRightSwitches && (
                        <div
                            className={clsx(`${prefixCls}-switch-left`, {
                                [`${prefixCls}-switch-left-disabled`]: current === 0
                            })}
                            onClick={() => this.onSwitchLeft()}
                        >
                            <LeftOutlined />
                        </div>
                    )}
                    {showLeftOrRightSwitches && (
                        <div
                            className={clsx(`${prefixCls}-switch-right`, {
                                [`${prefixCls}-switch-right-disabled`]: current === images.length - 1
                            })}
                            onClick={() => this.onSwitchRight()}
                        >
                            <RightOutlined />
                        </div>
                    )}
                </div>
                {renderDesc && <div className={`${prefixCls}-desc`}>{renderDesc?.(current)}</div>}
            </div>
        );
    }

    render() {
        const { visible } = this.state;
        const { afterClose } = this.props;
        const bodyStyle = { width: '100%', height: '100%', top: 0 };
        return (
            <Modal
                transitionName="zoom"
                maskTransitionName="fade"
                closable={false}
                keyboard
                footer={false}
                visible={visible}
                width="100%"
                bodyStyle={bodyStyle}
                afterClose={afterClose}
                onCancel={() => this.onCancel()}
                className="amps-preview-image-modal"
                modalRender={() => this.modalRender()}
            />
        );
    }
}

export default PreviewImage;
