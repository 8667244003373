import React, { Component } from 'react';
import { Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import { AssociatedForm } from './index';
import AssociatedContentV2 from './associated_content_v2';

@inject("todosStore", "taskStore")
@observer
class AssociatedModal extends Component {
    state = {
        package_id: undefined,
    }
    get store() {
        return this.props.taskStore;
    }


    render() {
        const { isPlanningTask, selectedItem } = this.store;
        const { package_id } = this.state
        return (
            <>
                <AssociatedForm {...this.props} onChange={(value) => {
                    this.setState({ package_id: value })
                }} />

                {
                    !isPlanningTask && !package_id && <div className=" task-form-col-1 associated-content-no-data">
                        <img className='associated-content-no-data-img' src={`${process.env.PUBLIC_URL}/images/other/no_data.png`} alt='请选择任务包' />
                        <span className='associated-content-no-data-span'>请选择任务包</span>
                    </div>
                }
                {
                    !!(!isPlanningTask && package_id) && (<AssociatedContentV2
                        package_id={package_id}
                        selectItem={selectedItem}
                        onSelect={(id, item) => {
                            this.store.selectedItem = item
                            this.props.updateTaskCheck(item);
                            this.store.associatedTaskID = item.id
                            this.store.associatedTaskImage = item.images[0]
                        }} />)
                }
            </>
        );
    }
}

export default AssociatedModal;
