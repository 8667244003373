import { useState } from "react";
import { Modal } from "antd";
import "./index.less";
/**
/**
 * 任务包弹框壳子
 * @param {*} props  参数
 * @returns
 */
export const TaskPackageModal = (props) => {
    const { isVisible, handleCancel, afterClose } = props;

    return (
        <div className="amps-task-package-modal">
            <Modal
                centered
                width={1268}
                title={"任务包"}
                visible={isVisible}
                onCancel={handleCancel}
                className={props.className}
                footer={null}
                afterClose={afterClose}
                destroyOnClose={true}
            >
                {props.children}
            </Modal>
        </div>
    );
};
