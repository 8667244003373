import axios from "axios";

const shootingPlanAPI = {
  createPlan(params) {
    return axios.post("shooting-plan/add", params);
  },
  updatePlan(params) {
    return axios.post("/shooting-plan/update", params);
  },
  reviewPlan(params) {
    return axios.post("/shooting-plan/review", params);
  },
  fetchPlanDetails(id) {
    return axios.get(`/shooting-plan/info?id=${id}`);
  },
};

export default shootingPlanAPI;
 