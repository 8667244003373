import React from 'react';
import { Collapse } from '@components';
import { inject, observer } from 'mobx-react';
import TaskInfoV2ImgList from './task_info_img_list';
import CommentModel from './CommentModal';

const Status = {
    1: "未审核",
    2: "通过",
    3: "驳回",
    4: "作废",
}

const TaskInfo = ({tasksId}) => {
    return (
        <div className="mg-t-16 amp-origin-border mg-b-30">
            <Collapse header="任务信息" fontWeight="fw-500" opened={true}>
                <TaskInfoV2ImgList package_id={tasksId} />
                <CommentModel />
            </Collapse>
        </div>
    )
}


export default inject("taskPackageStore")(observer(TaskInfo))

