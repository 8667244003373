import moment from 'moment';
/**
 * 获取时间对象函数
 * @param {*} time 时间戳格式，AMP固定时间戳按秒为单位
 * @returns {*} 返回一个时间对象包含年月日时分秒
 */
export const getTime = (time) => {
    return {
        y: moment( Number(time * 1000) ).year(),
        m: moment( Number(time * 1000) ).month() + 1 < 10 ? `0${moment( Number(time * 1000) ).month() + 1}`: moment( Number(time * 1000) ).month() + 1,
        d: moment( Number(time * 1000) ).date() < 10 ? `0${moment( Number(time * 1000) ).date()}` : moment( Number(time * 1000) ).date(),
        h: moment( Number(time * 1000) ).hour() < 10 ? `0${moment( Number(time * 1000) ).hour()}` : moment( Number(time * 1000) ).hour(),
        f: moment( Number(time * 1000) ).minute() < 10 ? `0${moment( Number(time * 1000) ).minute()}` : moment( Number(time * 1000) ).minute(),
        s: moment( Number(time * 1000) ).second() < 10 ? `0${moment( Number(time * 1000) ).second()}` : moment( Number(time * 1000) ).second(),
    }
}
