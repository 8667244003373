import { action, flow, makeAutoObservable, observable } from 'mobx';
import { configsAPI, tasksNewAPI } from '@api';

// import {projectStore} from '@stores'
class TasksStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable task_list_type = {};
  @observable tasks = {};
  @observable lists = {};
  @observable filter = {};
  @observable status_total = {};
  @observable total = {};
  @observable flag = true;

  // @computed get currentProject() {
  //   return projectStore.currentProject
  // }
  @action toggleFlag(value) {
    this.flag = Boolean(value);
  }
  @action updateCurrentPage({ list_type, page }) {
    this.lists[list_type].currentPage = page;
  }
  @action setFilter(obj) {
    this.filter = obj
  }
  @action receiveTasks({ list_type, page }, list) {
    let currentList = this.lists[list_type];
    let items = page !== 1 ? currentList?.items : [];
    this.lists[list_type].items = [...items, ...list]
  }
  @action updateStatus({list_type}, fetching) {
    this.lists[list_type].fetching = fetching
  }

  @action clearFilter() {
    this.filter = {}
  }
  @action updateFilter(filter) {
    this.filter = filter
  }

  fetchTaskConfigs = flow(function* () {
    try {
      const res = yield configsAPI.fetchTaskConfig();
      if (res.code === 1000) {
        this.task_list_type = res.data.task_list_type;
      }
    } catch (error) {}
  });

  fetchTasks = flow(function* (params) {
    this.updateStatus(params, true)
    if (params.filter) {
      this.updateFilter(params.filter)
    }
    try {
      const res = yield tasksNewAPI.fetchTasks(params);
      if (res.code === 1000) {
        this.receiveTasks(params, res.list);
        this.updateStatus(params,false)
        this.updateCurrentPage(params)
        localStorage.setItem(`project_${params.project_id}`, res.time);
      }
    } catch (error) {}
  });
}

export default new TasksStore();
