import { BasicImage } from '@components';

export const tabs = [
    { key: 'week', label: '周汇总' },
    { key: 'month', label: '月汇总' }
];

export const collectionSummaryFormList = [
    {
        label: '查看小组',
        placeholder: '请选择小组',
        name: 'user_group_id',
        type: 'select'
    },
    {
        label: '搜索组员',
        placeholder: '请输入组员姓名',
        maxLength: 50,
        name: 'user_id',
        type: 'select'
    }
];
export const collectionDetailFormList = [
    {
        label: '搜索资产',
        placeholder: '请输入资产ID或者名称',
        maxLength: 50,
        name: 'task_key',
        type: 'input'
    },
    {
        label: '拍摄人',
        placeholder: '请输入组员姓名',
        maxLength: '10',
        name: 'user_id',
        type: 'select'
    },
    {
        label: '拍摄完成时间',
        // placeholder: "请选择需要查询的日期",
        name: 'created_at',
        type: 'ranger_picker'
    }
];

export const weekColumns = [
    {
        title: '组别',
        children: [
            {
                title: '',
                width: '12%',
                dataIndex: 'user_group_name'
            }
        ]
    },
    {
        title: '任务数量',
        align: 'left',
        children: [
            {
                title: '项目素材',
                width: '12%',
                dataIndex: 'project_material_task'
            },
            {
                title: '泛素材',
                width: '12%',
                dataIndex: 'common_material_task'
            },
            {
                title: '合计',
                width: '12%',
                dataIndex: 'total_material_task'
            }
        ]
    },
    {
        title: '任务得分',
        align: 'left',
        children: [
            {
                title: '项目素材',
                width: '12%',
                dataIndex: 'project_material_score'
            },
            {
                title: '泛素材',
                width: '12%',
                dataIndex: 'common_material_score'
            },
            {
                title: '合计',
                width: '12%',
                dataIndex: 'total_material_score'
            }
        ]
    }
];
export const collectionDetailColumns = [
    {
        title: '资产ID',
        dataIndex: 'task_no'
    },
    {
        title: '资产名称',
        dataIndex: 'task_title',
        width: "12%",
        ellipsis: true
    },
    {
        title: '缩略图',
        dataIndex: 'preview',
        render: (preview) => <BasicImage height={68} width={68} src={preview} />
    },
    {
        title: '拍摄人',
        dataIndex: 'user_name'
    },
    {
        title: '所在小组',
        dataIndex: 'user_group_name',
        render: (user_group_name) => (user_group_name ? user_group_name : "-"),
    },
    {
        title: '素材类型',
        dataIndex: 'task_material_type',
        render: (_, record) => (
            <span>
                {record.task_material_type === 1 ? '项目素材' : '泛素材'}
            </span>
        )
    },
    {
        title: '得分',
        dataIndex: 'score'
    },
    {
        title: '结果',
        dataIndex: 'score_type',
        render: (_, record) => (
            <span>
                {record.score_type === 3 ? '通过' : '驳回'}
            </span>
        )
    },
    {
        title: '记录时间',
        dataIndex: 'created_at',
        width: "12%",
    }
];
export const emptyColumns = {
    title: '',
    width: "12%",
}
