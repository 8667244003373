/**
 * @name: accessMatch.js
 * @user: cfj
 * @date: 2022/4/19
 * @description: 权限匹配
 */

/**
 * 权限匹配
 * @param {string[]} auths
 * @param {string|access[]} access
 * @param {AND|OR} whereType
 * @returns {*}
 */
export const accessMatch = (auths, access, whereType = 'AND') => {
  if(typeof access === 'string'){
    return auths.includes(access)
  }
  if(Array.isArray(access)){
    if(whereType === 'AND'){
      return access.every(i => auths.includes(i))
    } else if(whereType === 'OR'){
      return access.some(i => auths.includes(i))
    } else {
      return access.every(i => auths.includes(i))
    }
  }
}
