import React from "react";
import { ProfileAvatar, AuthButton } from "@/components";
import { UserPart, AccountPart, SignOut, SendCode, Validation, ChangePassword } from ".";

class Profile extends React.Component {
  logOutRef = React.createRef();
  sendCodeRef = React.createRef();
  validationRef = React.createRef();
  changePassRef = React.createRef()

  showLogOut = () => {
    this.logOutRef.current.show();
  };
  showSendCode = () => {
    this.sendCodeRef.current.show();
  };
  showValidation = () => {
    this.validationRef.current.show()
  };
  showChangePassword = () => {
    this.changePassRef.current.show()
  };

  renderSignOutButton = () => (
    <AuthButton
      type="primary"
      className=" mg-t-42"
      onClick={this.showLogOut}
    >
      退出登录
    </AuthButton>
  );

  render() {
    const { props } = this;
    const { currentUser } = props;
    if (!currentUser.id) return ""
    return (
      <div className="account-profile">
        <SignOut {...props} ref={this.logOutRef} />
        <SendCode
          {...props}
          ref={this.sendCodeRef}
          showValidation={this.showValidation}
        />
        <Validation {...props} ref={this.validationRef} showChangePassword= {this.showChangePassword} />
        <ChangePassword {...props} ref={this.changePassRef} />
        <ProfileAvatar {...currentUser} size={80} />
        <h2 className="mg-t-42">账户信息</h2>
        <AccountPart {...props} showSendCode={this.showSendCode} />
        <h2 className="mg-t-42">用户信息</h2>
        <div className="profile-info-section">
          <UserPart {...props} />
        </div>
        {this.renderSignOutButton()}
      </div>
    );
  }
}

export default Profile;
