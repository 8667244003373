import { $success, $error } from "@components";

export const formatTime = (timesArr) => {
  let from = "",
    to = "";
  if (Array.isArray(timesArr)) {
    from = timesArr[0] ? timesArr[0].format("YYYY-MM-DD") + " 00:00:00" : "";
    to = timesArr[1] ? timesArr[1].format("YYYY-MM-DD") + " 23:59:59" : "";
  }
  return { from, to };
};

export const tableTimeFormat = (timeStr) =>
  timeStr ? timeStr.split(" ")[0].replace(/-/g, ".") : "";

export const requestMessage = (res, cb = () => {}) => {
  if (res.code === 1000) {
    $success(res.msg);
    cb();
  } else {
    if (res.code !== 1002) $error(res.msg);
    cb();
  }
};

export const formatImageFiles = (fileList, formData) => {
  formData.delete("images");
  formData.delete("image_urls");
  fileList?.forEach((file) => {
    if (file.originFileObj) {
      formData.append("images", file.originFileObj);
    } else if (file.url) {
      formData.append("image_urls", file.url);
    }
  });
  return formData;
};

export const formatExcelFiles = (fileList, formData) => {
    formData.delete("excel");
    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append("excel", file.originFileObj);
      }
    });
    return formData;
};

export const formatZipFiles = (fileList, formData) => {
    formData.delete("zip");
    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append("zip", file.originFileObj);
      }
    });
    return formData;
};

const checkSpec = (list, type) => (list || []).some((item) => item === type);

export const checkAuth = (list, permission) => {
  if (typeof permission === "string") {
    return checkSpec(list, permission);
  } else if (Array.isArray(permission)) {
    return permission.some((type) => checkSpec(list, type));
  }
};

export const getActivityUrl = (item, base='') => {
  const typeDIC = ["", "project", "shootingPlan", "task"];
  const { project_id, object_id, type } = item;
  const issueObject = typeDIC[type];
  const baseUrl =  base || `/projects/${project_id}`;
  const searchUrl = `?${
    type === 3 ? `task_flow?task` : issueObject
  }Id=${object_id}`;
  if (project_id === 0) return $error("该项目已删除");
  let finalUrl;
  finalUrl = `${baseUrl}${searchUrl}`;
  return finalUrl;
};

export const bulkSetFormData = (params, formData) => {
  for (const key in params) {
    const val = params[key];
    if (Array.isArray(val)) {
      formData.delete(key);
      val.forEach((item) => {
        const res = typeof item === "object" ? JSON.stringify(item) : item;
        formData.append(key, res);
      });
    } else if (val) {
      formData.set(key, val);
    }
  }
  return formData;
};

export const formatRecordWithCode = (record) => {
  let newRecord = {};
  const keys = Object.keys(record);
  keys.forEach((key) => {
    const code = record[key]?.code;
    newRecord[key] = code ? code : record[key];
  });
  return newRecord;
};

export const parseTaskID = (location) => {
  const { search } = location;
  return (
    search.includes("taskId=") && search.split("taskId=").slice(-1)[0]
  )
}

export const isEmpty = (data) => {
    if(data === null){
        $error('请完整填写任务描述及美术评分');
        return false;
    }
    const flag = data?.every(item => item.title && item.score && item.kind );
    if (!flag) {
        $error('请完整填写任务描述及美术评分');
        return false;
    }
    return true;
    // return的值用于点击“保存”按钮时是否阻塞用
}
