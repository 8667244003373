import { useCallback, useEffect, useRef, useState } from 'react';
import useThrottle from './useThrottle';

function useInfiniteScroll({
    params,
    request,
    onSuccess,
    onError,
    scrollThreshold,
    anchorEl
}) {
    const isUnmounted = useRef(false)
    const requestRef = useRef(request);
    const requestRes = useRef()
    const anchorElRef = useRef(anchorEl);
    const pageRef = useRef(0);
    const errorCountRef = useRef(0);
    const rerender = useState({})[1];
    const requestPromiseRef = useRef();
    const dataSourceRef = useRef({
        list: [],
        total: 0,
        loading: false,
        error: null
    });
    function nextRequest(reRequest) {
        if (anchorElRef.current && anchorElRef.current.scrollHeight && anchorElRef.current.clientHeight) {
            if (anchorElRef.current.scrollHeight <= anchorElRef.current.clientHeight) {
                if (dataSourceRef.current.list.length < dataSourceRef.current.total) {
                    reRequest();
                }
            }
        }
    }

    const requestData = useCallback(async (page, params) => {
        if (dataSourceRef.current.loading) {
            return requestPromiseRef.current;
        }
        try {
            dataSourceRef.current.loading = true;
            dataSourceRef.current.error = null;
            if (page === 0) {
                dataSourceRef.current.list = [];
                dataSourceRef.current.total = 0;
            }
            const requestPromise = requestRef.current(page + 1, params);
            requestPromiseRef.current = requestPromise;
            rerender({});
            const res = await requestPromise;
            dataSourceRef.current.loading = false;
            errorCountRef.current = 0;
            pageRef.current = page + 1;
            if (res.list?.length > 0) {
                dataSourceRef.current.list = [].concat(dataSourceRef.current.list, res.list);
            }
            if (res?.total) {
                dataSourceRef.current.total = res.total;
            }
            requestRes.current = res
            if(!isUnmounted.current){
                rerender({});
                nextRequest(() => requestData(pageRef.current, params));
                onSuccess?.(res);
            }
            return res;
        } catch (error) {
            errorCountRef.current += 1;
            dataSourceRef.current.error = error;
            dataSourceRef.current.loading = false;
            requestRes.current = undefined
            if(!isUnmounted.current){
                rerender({});
                onError?.(error, () => requestData(page, params), pageRef.current, errorCountRef.current);
            }
            return Promise.reject(error);
        }
    }, [JSON.stringify(params)]);

    function onScroll(even) {
        const dom = even.target;
        const l = dom.scrollHeight - dom.offsetHeight - dom.scrollTop;
        const {
            list,
            total,
            loading
        } = dataSourceRef.current;
        if (l <= dom.offsetHeight * (scrollThreshold || 0.4)) {
            if (list.length < total && !loading) {
                requestData(pageRef.current, params)
                    .catch(() => {});
            }
        }
    }

    const throttleOnScroll = useThrottle(onScroll, 120, [anchorEl, JSON.stringify(params)]);

    useEffect(() => {
        requestRef.current = request;
        anchorElRef.current = anchorEl;
    });
    useEffect(() => {
        anchorElRef.current?.addEventListener('scroll', throttleOnScroll, false);
        return () => anchorElRef.current?.removeEventListener('scroll', throttleOnScroll, false);
    }, [anchorEl, JSON.stringify(params)]);

    useEffect(() => {
        requestData(0, params)
            .catch(() => {});
    }, [JSON.stringify(params)]);
    useEffect(() => {
        return () => {
            isUnmounted.current = true
        }
    }, [])
    return {
        page: pageRef.current,
        res: requestRes.current,
        list: dataSourceRef.current.list,
        loading: dataSourceRef.current.loading,
        error: dataSourceRef.current.error,
        total: dataSourceRef.current.total,
        refresh: () => requestData(0, params)
    };
}

export default useInfiniteScroll;
