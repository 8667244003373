import React, { Component } from "react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import SunEditor from "suneditor-react";
import { connectUserSession } from "@gUtils";

import env from "env";

const urlBase = env.serveUrls;

const editorOptions = {
  height: 670,
  buttonList: [
    ["print"],
    ["undo", "redo", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike"],
    ["subscript", "superscript", "removeFormat"],
    ["fontColor", "hiliteColor", "lineHeight", "align"],
    ["outdent", "indent", "list", "table"],
    ["horizontalRule", "codeView", "showBlocks"],
    ["link", "image", "video"],
    ["fullScreen", "preview"],
  ],
  imageUploadUrl: `${urlBase}image/upload`,
  imageAccept: ".jpg, .png",
  imageUploadSizeLimit: "5000000",
  videoFileInput: true,
  videoUploadUrl: `${urlBase}/image/upload`,
};

class Editor extends Component {
  render() {
    const { props } = this;
    const {token} = this.props.session;
    const finalOptions = {
      ...editorOptions,
      imageUploadHeader: {
        "x-token": token,
      },
      videoUploadHeader: {
        "x-token": token,
      },
    };
    return (
      <div>
        <SunEditor
          lang="zh_cn"
          setOptions={finalOptions}
          onChange={this.props.handleContentChange}
          {...props}
        />
      </div>
    );
  }
}
export default connectUserSession(Editor);
