export const asset_name = "asset_name";
export const asset_uid = "asset_uid";
export const asset_comment = "asset_comment";
export const asset_type = "asset_type";
export const creator = "creator";
export const location = "location";
export const display_images = "display_images";
export const image_urls = "image_urls";
export const title = "title";
export const asset_category = "asset_category";
export const shoot_score = "shoot_score";
export const difficulty = "difficulty";
export const material_type = "material_type";
export const shoot_images = "shoot_images";
export const produce_images = "produce_images";
export const analyse_images = "analyse_images";
export const photographers = "photographers";
export const description = "description";
export const collect_images_count = "collect_images_count";
export const program_images = "program_images";
export const projectDetailTitle = {
    [asset_name]: {
        title: "资产名称",
    },
    [asset_uid]: {
        title: "资产ID编号",
    },
    [asset_comment]: {
        title: "备注",
    },
    [asset_type]: {
        title: "资产类型", //label
    },
    [collect_images_count]: {
        title: "原序列图片数量",
    },
    [program_images]: {
        title: "工程文件",
    },
    [location]: {
        title: "采集地区",
    },
    [display_images]: {
        title: "拍摄图",
    },
    [image_urls]: {
        title: "解析图",
    },
    [creator]: {
        title: "创建人",
    },
    [asset_category]: {
        title: "资产种类", // label
    },
    [shoot_score]: {
        title: "拍摄评分",
    },
    [difficulty]: {
        title: "制作评分",
    },
    [material_type]: {
        title: "素材属性",
    },
    [shoot_images]: {
        title: "拍摄图",
    },
    [produce_images]: {
        title: "制作图",
    },
    [analyse_images]: {
        title: "解析图",
    },
    [photographers]: {
        title: "拍摄人员",
    },
    [description]: {
        title: "任务包描述"
    }
};
export const materialTypeEnum = {
    '1': '项目素材',
    '2': '泛素材',
}
export const projectTitle = [
    "asset_uid",
    "asset_type",
    "asset_category",
    "location",
    "photographers",
    "collect_images_count",
    "asset_comment",
    "material_type"
]

export const imageTitle = [
    "program_images",
    "shoot_images",
    "analyse_images",
    "produce_images"
]

export const scoreTitle = [
    "shoot_score",
    "difficulty"
]
