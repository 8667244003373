import { dashboardAPI, getUserAuth, sessionAPI } from '@/api';
import {
    FINISH_INITIALIZATION,
    INITIATE_SESSION,
    LOGOUT_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    RECEIVE_SESSION_ERROR,
    RECEIVE_TODOS,
    RECEIVE_USER_PERMISSIONS
} from '@actions/action_consts';

// action creators
export const initiateSession = () => ({
    type: INITIATE_SESSION
});
export const receiveCurrentUser = (currentUser) => ({
    type: RECEIVE_CURRENT_USER,
    currentUser
});

export const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER
});

export const receiveSessionError = (error) => ({
    type: RECEIVE_SESSION_ERROR,
    error
});

export const receiveUserPermissions = (permissions) => ({
    type: RECEIVE_USER_PERMISSIONS,
    permissions
});

export const finishInitialization = () => ({
    type: FINISH_INITIALIZATION
});

const receiveTodos = (todos) => ({
    type: RECEIVE_TODOS,
    todos
});

// 异步数据请求（使用thunk中的dispatch方法返回dispatch函数）
export const login = (user) => (dispatch) =>
    sessionAPI.login(user).then(
        (res) => {
            if (res.code === 1000) {
                let userInfo = res.data;
                localStorage.setItem('token', userInfo.token);
                dispatch(initiateSession());
                dispatch(receiveUserPermissions(userInfo.permissions));
                return dispatch(receiveCurrentUser(userInfo));
            } else {
                return dispatch(receiveSessionError(res.msg));
            }
        },
        (err) => dispatch(receiveSessionError(err.msg))
    );

export const fetchUserPermissions = () => (dispatch) =>
    getUserAuth().then((res) => {
        return dispatch(receiveUserPermissions(res.list));
    });

export const fetchTodos = () => (dispatch) =>
    dashboardAPI.fetchAssignments().then((res) => {
        if (res.code === 1000) {
            return dispatch(receiveTodos(res.data));
        }
    });

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');

    return dispatch(logoutCurrentUser());
};
