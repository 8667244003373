import React, { memo, useState } from 'react'
import { Input, Form, Radio, Checkbox, InputNumber, Tooltip } from 'antd';
import { Iconfont } from '@components';
import { tastSize } from '@vars/views/task_flow_vars';

import LargeFileUpload from '@components/LargeFileUpload'
import ImageUploadCheck from '@components/2_data_input/upload/imageUploadCheck'
import RecommendedColor from './../template/recommended_color/index'

const chartletOptions = [
    { label: 'scatter', value: 'scatter' },
    { label: 'opacity', value: 'opacity' },
    { label: 'fuzz', value: 'fuzz' },
];
const typeInitialValue = (asset_type) => {
    const { code = '' } = asset_type || {}
    if (code === 'SF' || code === 'DC') {
        return 2
    }
    return 1
}

/**
 * 入库提交表单
 * @param {*} props
 * @returns
 */
const TaskToBeWarehoused = (props) => {
    const { currentTask, taskProcessStore } = props
    const { asset_uid, asset_type } = currentTask || {}
    const [extra, setExtra] = useState('')
    const fileType = typeInitialValue(asset_type)

    const onUploadStatus = (file) => {
        if (file?.status === 'uploading') {
            setExtra('*请等待文件上传完毕…')
        } else {
            setExtra('')
        }
    }

    return (
        <>
            <div className="process-form-col-2">
                <div className="mg-b-8 bold">资产ID</div>
                <Form.Item>
                    <Input disabled value={asset_uid} className='input-sz-m mg-b-16' />
                </Form.Item>
            </div>
            <div className="process-form-col-2">
                <div className="mg-b-8 bold">资产类型</div>
                <Form.Item>
                    <Input disabled value={asset_type?.label ?? ''} className='input-sz-m mg-b-16' />
                </Form.Item>
            </div>
            <div className="process-form-col-2">
                <div className="mg-b-8 required bold">资产名称</div>
                <Form.Item
                    name="asset_name"
                    rules={[{ required: true, message: '请输入资产名称' }]}
                >
                    <Input
                        className='input-sz-m mg-b-16'
                        maxLength={50}
                        placeholder="请输入资产名称"
                    />
                </Form.Item>
            </div>
            <div className="process-form-col-2">
                <div className="align-center mg-b-8">
                    <div className="required bold mg-r-5">英文名称</div>
                    <Tooltip placement='topLeft' title='名称不会直接显示在PBRMAX'>
                        <Iconfont
                            type='icon-list_prompt'
                            className='icon-12 cursor-pointer'
                        />
                    </Tooltip>
                </div>
                <Form.Item
                    name="asset_eng_name"
                    rules={[{ required: true, message: '请输入英文名称' }]}
                >
                    <Input
                        className='input-sz-m mg-b-16'
                        maxLength={50}
                        placeholder="请输入英文名称"
                    />
                </Form.Item>
            </div>

            <div className="process-form-col-1">
                <div className="mg-b-8 required bold">封闭状态</div>
                <Form.Item
                    name="is_close"
                    rules={[{ required: true, message: '请选择封闭状态' }]}
                    initialValue={1}
                >
                    <Radio.Group>
                        <Radio value={1}>封闭</Radio>
                        <Radio value={2}>不封闭</Radio>
                    </Radio.Group>
                </Form.Item>
            </div>

            <div className="process-form-col-1">
                <div className="mg-b-8 bold">资产尺寸</div>
                <div className="display-flex">
                    <div className="amp-process-asset-size">
                        {
                            tastSize.map(item => (
                                <div className='display-flex' key={item.sizeName}>
                                    <span className="amp-process-asset-label mg-r-20">{item.label}</span>
                                    <Form.Item
                                        name={item.sizeName}
                                        className="mg-r-20"
                                    >
                                        <InputNumber
                                            className='input-sz-m'
                                            maxLength={6}
                                            placeholder={`请输入${item.label}度`}
                                            size="large"
                                            precision={2}
                                            min={0}
                                        />
                                    </Form.Item>
                                    <span className="amp-process-asset-label">米</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="process-form-col-2">
                <div className="mg-b-8 bold">建议价格</div>
                <Form.Item
                    name="integrate"
                >
                    <InputNumber
                        className='input-sz-m mg-b-16'
                        placeholder="请输入数字"
                        maxLength={6}
                        precision={0}
                    />
                </Form.Item>
            </div>

            <div className="process-form-col-2">
                <div className="mg-b-8 bold">建议颜色</div>
                <Form.Item
                    name="color_code"
                >
                    <RecommendedColor />
                </Form.Item>
            </div>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.material_type !== currentValues.material_type}
            >
                {({ getFieldValue }) => {
                    return (<div className="process-form-col-2">
                        {/* <div className="mg-b-8 required bold">上传文件</div> */}
                        <div className="mg-b-8 bold">上传文件</div>
                        <Form.Item
                            name="upload_response"
                            extra={extra}
                            // rules={[{ required: true, message: '请选择上传文件' }]}
                        >
                            <LargeFileUpload
                                onUploadStatus={onUploadStatus}
                                accept=".zip"
                                params={{
                                    material_type: getFieldValue('material_type'),
                                    asset_uid: asset_uid
                                }}
                            />
                        </Form.Item>
                    </div>)
                }
                }
            </Form.Item>

            <div className="process-form-col-2" style={{ width: "60%" }}>
                <div className="mg-b-8 required bold">预览图</div>
                <Form.Item
                    name="review_images"
                    rules={[{ required: true, message: '请选择上传图片' }]}
                >
                    <ImageUploadCheck maxCount={5} />
                </Form.Item>
            </div>

            <Form.Item
                name="material_type"
                hidden
            >
                <Radio.Group
                    disabled
                >
                    <Radio value={2}>入库泛素材</Radio>
                    <Radio value={1}>入库项目素材</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.material_type !== currentValues.material_type}
            >
                {({ getFieldValue }) =>
                    getFieldValue('material_type') === 2 ? (
                        <>
                            <div className="process-form-col-2">
                                <div className="mg-b-8 bold">文件类型</div>
                                <Form.Item
                                    name="file_type"
                                    rules={[{ required: true, message: '请选择文件类型' }]}
                                    initialValue={fileType}
                                >
                                    <Radio.Group>
                                        <Radio value={2}>非贴图型</Radio>
                                        <Radio value={1}>贴图型</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="process-form-col-2">
                                <div className="mg-b-8 bold">特殊类型</div>
                                <Form.Item
                                    name="expand_file_type"
                                >
                                    <Checkbox.Group
                                        options={chartletOptions}
                                    />
                                </Form.Item>
                            </div>
                        </>
                    ) : <>
                        <Form.Item
                            hidden
                            name="file_type"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            hidden
                            name="expand_file_type"
                        >
                            <Input />
                        </Form.Item>
                    </>
                }

            </Form.Item>

            <Form.Item
                hidden
                name="id"
            >
                <Input />
            </Form.Item>

        </>
    )
}

export default memo(TaskToBeWarehoused)
