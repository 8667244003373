import axios from "axios";

const announcementAPI = {
  fetchAnnouncements(params) {
    return axios.post("/announcement/list", params);
  },
  fetchAnnouncementDetails(id) {
    return axios.get(`/announcement/info?id=${id}`);
  },
  createAnnouncement(params) {
    return axios.post("/announcement/create", params);
  },
  updateAnnouncement(params) {
    return axios.put("/announcement/update", params);
  },
  removeAnnouncement(id) {
    return axios.delete("/announcement/delete", { data: { id } });
  },
  publishAnnouncement(id) {
    return axios.put("/announcement/publish", {id});
  },
};

export default announcementAPI;
