import { Form, Upload } from 'antd';
import { $error, Iconfont } from '@components';
import { useEffect, useRef, useState } from 'react';
import { fileToImgUrl } from '@gUtils';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';

/**
 * AmpUpload 上传组件
 * @param {*} props props
 * @returns {*}
 */
const Index = function(props) {
    const {
        form,
        value,
        onChange,
        noRestriction,
        beforeUpload,
        multiple,
        maxCount,
        children,
        accept = 'image/*',
        ...other
    } = props;
    const isMount = useRef(false);
    const [fileList, setFileList] = useState([]);
    const urlsList = form.getFieldValue('image_urls')
        ?.map((i) => ({
            uid: i,
            url: i,
            source: 'URL',
            status: 'done'
        })) || [];
    useEffect(() => {
        isMount.current = true;
        if (Array.isArray(value)) {
            if (value.length) {
                Promise.all(value.map((file) => fileToImgUrl(file)))
                    .then((res) => {
                        if (isMount.current) {
                            setFileList(res.map((url, i) => ({
                                url,
                                source: 'FILE',
                                originFileObj: value[i],
                                uid: i,
                                status: 'done'
                            })));
                        }
                    });
            } else if (urlsList?.length) {
                setFileList([null]);
            } else {
                setFileList([]);
            }
        } else {
            setFileList([]);
        }
        return () => {
            isMount.current = false;
        };
    }, [value, urlsList?.length]);

    /**
     * 上传校验
     * @param {*} file 文件
     * @returns {boolean}
     */
    function defaultBeforeUpload(file) {
        const lessThan20M = file.size / 1024 / 1024 < 20;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (noRestriction) { return false; }
        if (!isJpgOrPng) {
            $error('仅支持jpg或png格式图片，请重新选择～');
            file.status = 'error';
            return Promise.reject();
        }
        if (!lessThan20M) {
            $error('图片大小不能超过20M, 请重新选择～');
            file.status = 'error';
            return Promise.reject();
        }
        return false;
    }

    /**
     * 预览功能
     * @param {*} file file
     */
    const onPreview = function (file) {
        PreviewImage.open({
            images: [file.url || file.preview],
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then((res) => res.data?.origin_urls?.[0] || '')
        });
    };

    const _fileList = [].concat(urlsList, fileList.filter((i) => i));
    return (
        <Upload
            {...other}
            accept={accept}
            fileList={_fileList}
            multiple={multiple}
            maxCount={maxCount}
            showUploadList={{
                showPreviewIcon: /image/ig.test(accept)
            }}
            onPreview={(file) => onPreview(file)}
            listType="picture-card"
            beforeUpload={beforeUpload || defaultBeforeUpload}
            // eslint-disable-next-line no-shadow
            onChange={({ fileList }) => {
                const files = fileList.filter((file) => file.originFileObj || file.source === 'FILE')
                    .map((i) => i.originFileObj);
                const urls = fileList.filter((file) => file.source === 'URL')
                    .map((i) => i.url);
                onChange(files);
                form.setFieldsValue({ image_urls: urls });
            }}
        >
            {maxCount === 1 && _fileList.length ? null : (children || (
                <div>
                    <Iconfont type="icon-a-14-zengjia" />
                    <div style={{
                        marginTop: 8,
                        color: '#BFC5D1'
                    }}
                    >
                        上传文件
                    </div>
                </div>
            ))}
        </Upload>

    );
};

/**
 * 上传From
 * @param {*} props props
 * @returns {*}
 */
const UploadForm = function(props) {
    return (
        <>
            <Form.Item noStyle dependencies={['image_urls']}>
                {(form) => <Index form={form} {...props} />}
            </Form.Item>
            <Form.Item name="image_urls" hidden>
                <p>image_urls</p>
            </Form.Item>
        </>
    );
};

export default UploadForm;
