import React, { Component } from "react";
import { Modal } from "antd";
import AddEditUserForm from "./add_edit_user_form";

export default class AddEditUser extends Component {
  state = {
    type: "add",
    visible: false,
    visibleDel: false,
    loading: false,
    data: {},
    editPsd: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    this.getOptions();
  }
  showEdit = (type, data) => {
    this.setState({ type, data });
    this.setState({ visible: true, editPsd: false });
  };

  hideEdit = () => {
    this.setState({ visible: false });
  };

  handleEditPsd = () => {
    this.setState({ editPsd: !this.state.editPsd });
  };

  handleSubmit = (val, type) => {
    if (type === "add") {
      this.props.createUser(val, (data) => {
        this.handleCallback(data);
      });
    } else {
      this.props
        .updateUser(val, (data) => {
          this.handleCallback(data);
        })
        .then(this.updateCurrentUser);
    }
  };
  handleCallback = (data) => {
    if (!data || (data && data !== 1001)) {
      this.hideEdit();
    }
  };

  updateCurrentUser = (res) => {
    const { permissions, is_locked, phone, status, ...userInfo } = res;
    if (userInfo.id === this.props.session.id) {
      this.props.receiveCurrentUser(userInfo);
    }
  };

  getOptions = () => {
    this.props.fetchUserConfig();
  };

 render() {
    const { type, data, groupOption, roleOption } = this.state;
    const props = { ...this.props };
    return (
      <Modal
        className="large-form-dialog editUser"
        width="704px"
        destroyOnClose
        visible={this.state.visible}
        confirmLoading={this.state.loading}
        footer={null}
        onCancel={this.hideEdit}
      >
        <div className="dialog-title">
          {this.state.type === "add" ? "新增用户" : "编辑用户"}
        </div>
        <div className="dialog-content">
          <AddEditUserForm
            type={type}
            data={data}
            {...this.state}
            groupOption={groupOption}
            roleOption={roleOption}
            handleSubmit={this.handleSubmit}
            handleCancel={this.hideEdit}
            handleShowDel={props.handleShowDel}
            handleSolve={props.handleSolve}
            handleEditPsd={this.handleEditPsd}
            {...this.props}
          />
        </div>
      </Modal>
    );
  };
}
