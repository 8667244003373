import { SWRGlobalState } from './global-state';
import { MUTATE_EVENT } from './revalidate-events';

export const broadcastState = (
    cache,
    key,
    data,
    error,
    isValidating,
    revalidate,
    broadcast = true
) => {
    const [EVENT_REVALIDATORS, STATE_UPDATERS, , FETCH] = SWRGlobalState.get(cache)
    const revalidators = EVENT_REVALIDATORS[key]
    const updaters = STATE_UPDATERS[key]

    if (broadcast && updaters) {
        for (let i = 0; i < updaters.length; ++i) {
            updaters[i](data, error, isValidating)
        }
    }
    if (revalidate) {
        delete FETCH[key]

        if (revalidators && revalidators[0]) {
            return revalidators[0](MUTATE_EVENT).then(() =>
                cache.get(key)
            )
        }
    }

    return cache.get(key)
}
