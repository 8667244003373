import { observable, action, computed, flow, makeAutoObservable } from "mobx";
import { usersAPI, projectSettingsAPI, groupAPI, rolesAPI, shootingTeamAPI } from "@api";
import { $success, $error } from "@components";
import { userRelationsStore } from "@stores";

class AddRelationStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable visible = false;
  @observable loading = false;
  @observable groups = [];
  @observable roles = [];
  @observable allUsers = [];
  @observable name_email = "";
  @observable group_id = "";
  @observable role_id = "";
  @observable addType = "group";
  @observable selectedIds = [];

  @computed get relationTypes() {
    return userRelationsStore.relationTypes;
  }
  @computed get shootingTeamList() {
      return userRelationsStore.shootingTeamList
  }
  @computed get activeTypeCode() {
    return userRelationsStore.activeTypeCode;
  }
  @computed get shootingTeamType() {
    return userRelationsStore.shootingTeamType;
  }
  @computed get relatedUserIds() {
    return userRelationsStore.relatedUsers?.map((item) => item.user_id);
  }
  @computed get shootingMemberIds() {
    return userRelationsStore.shootingMember?.map((item) => item.user_id);
  }

  @computed get formattedUsers() {
    return this.allUsers?.map((item) => {
      const { id, name, email, role, group } = item;
      const name_email = `${name} ${email}`;
      const group_ids = group?.path
        .split("/")
        .filter((item) => item)
        ?.map((item) => JSON.parse(item));
      const disabled = this.relatedUserIds.includes(id);
      return {
        name_email,
        group_ids,
        role_id: role.id,
        id,
        name,
        disabled,
      };
    });
  }


  @computed get usersDisplay() {
    const res = this.formattedUsers.filter((item) => {
      let flag = true;
      if (this.addType === "role" && this.role_id) {
        flag = item.role_id === this.role_id;
      } else if (this.addType === "group" && this.group_id) {
        flag = item.group_ids.includes(this.group_id);
      }
      return item.name_email.includes(this.name_email) && flag;
    });
    return res;
  }

   // 拍摄
   @computed get formattedPaiSheUsers() {
    return this.allUsers?.map((item) => {
      const { id, name, email, role, group } = item;

      const name_email = `${name} ${email}`;
      const group_ids = group?.path
        .split("/")
        .filter((item) => item)
        ?.map((item) => JSON.parse(item));
      const disabled = this.shootingMemberIds.includes(id);
      return {
        name_email,
        group_ids,
        role_id: role.id,
        id,
        name,
        disabled,
      };
    });
  }

  @computed get usersPaiSheDisplay() {
    const res = this.formattedPaiSheUsers.filter((item) => {
      let flag = true;
      if (this.addType === "role" && this.role_id) {
        flag = item.role_id === this.role_id;
      } else if (this.addType === "group" && this.group_id) {
        flag = item.group_ids.includes(this.group_id);
      }
      return item.name_email.includes(this.name_email) && flag;
    });
    return res;
  }

  @action show() {
    this.visible = true;
  }
  @action hide() {
    this.visible = false;
  }
  @action clear() {
    this.name_email = "";
    this.selectedIds = [];
    this.group_id = ''
    this.role_id = ''
  }
  @action changeActive(code) {
    userRelationsStore.changeActive(code)
  }
  @action changeShootingTeamTypeActive(code) {
      userRelationsStore.changeShootingTeamTypeActive(code)
  }
  @action updateRoleId(val) {
    this.role_id = val;
  }
  @action updateGroupId(val) {
    this.group_id = val;
  }
  @action updateNameEmail(val) {
    this.name_email = val;
  }
  @action updateAddType(type) {
    this.addType = type;
  }
  @action updateSelectedIds(val) {
    this.selectedIds = val;
  }
  fetchGroupTree = flow(function* () {
    try {
      const res = yield groupAPI.fetchGroupTree();
      if (res.code === 1000) {
        this.groups = res.data.children;
      }
    } catch (error) {}
  });
  fetchAllUsers = flow(function* () {
    try {
      const res = yield usersAPI.fetchUsers();
      if (res.code === 1000) {
        this.allUsers = res.data.list;
      }
    } catch (error) {}
  });
  fetchRoles = flow(function* () {
    try {
      const res = yield rolesAPI.fetchRoles();
      if (res.code === 1000) {
        this.roles = res.data.list;

      }
    } catch (error) {}
  });
  createRequest = flow(function* () {
    const user_ids = this.selectedIds.filter(
      (id) => !this.relatedUserIds.includes(id)
    );
    const params = { type: this.activeTypeCode, user_ids };
    try {
      const res = yield projectSettingsAPI.createRelation(params);
      if (res.code === 1000) {
        userRelationsStore.fetchRelatedUsers();
        this.hide();
        $success(res.msg);
      } else {
        if (res.code !== 1002) $error(res.msg);
      }
    } catch (error) {}
  });

  // 拍摄小组关联请求
  createShootingTeamRelationRequest = flow(function* () {
    const user_ids = this.selectedIds.filter(
        (id) => !this.shootingMemberIds.includes(id)
      );
    const params = { group_id: this.shootingTeamType, user_ids: user_ids };
    try {
      const res = yield shootingTeamAPI.shootingTeamAddMember(params);
      if (res.code === 1000) {
        userRelationsStore.getShootingTeamMember();
        this.hide();
        $success(res.msg);
      } else {
        if (res.code !== 1002) $error(res.msg);
      }
    } catch (error) {}
  });
}

const addRelationStore = new AddRelationStore();
export default addRelationStore;
