export const previousHistoryFlowSteps = [
    { id: 0, label_code: 1, label: '创建资产' },
    { id: 1, label_code: 2, label: '拍摄中' },
    { id: 2, label_code: 3, label: '拍摄美术待审' },
    { id: 3, label_code: 4, label: '原序列待审' },
    { id: 4, label_code: 5, label: '解析待分配' },
    { id: 5, label_code: 6, label: '待解析' },
    { id: 6, label_code: 7, label: '解析中' },
    { id: 7, label_code: 8, label: '解析待审' },
    { id: 21, label_code: 9, label: '制作待评分' },
    { id: 8, label_code: 10, label: '制作待选' },
    { id: 9, label_code: 11, label: '待制作' },
    { id: 10, label_code: 12, label: '制作中' },
    { id: 11, label_code: 13, label: '制作初审待审' },
    { id: 12, label_code: 14, label: '制作终审待审' },
    { id: 13, label_code: 15, label: '工程文件待分配' },
    { id: 14, label_code: 16, label: '工程文件待制作' },
    { id: 15, label_code: 17, label: '工程文件制作中' },
    { id: 30, label_code: 18, label: '工程文件初审' },
    { id: 16, label_code: 19, label: '工程文件终审' },
    { id: 17, label_code: 20, label: '文件规范待审' },
    { id: 23, label_code: 21, label: '待入库' },
    { id: 24, label_code: 22, label: '入库中' },
    { id: 18, label_code: 23, label: '入库/作废' }
];

export const historyFlowSteps = [
    { id: 0, label_code: 1, label: '创建资产' },
    { id: 22, label_code: 2, label: '采集待评分' },
    { id: 1, label_code: 3, label: '拍摄中' },
    { id: 3, label_code: 4, label: '原序列待审' },
    { id: 4, label_code: 5, label: '解析待分配' },
    { id: 5, label_code: 6, label: '待解析' },
    { id: 6, label_code: 7, label: '解析中' },
    { id: 7, label_code: 8, label: '解析待审' },
    { id: 21, label_code: 9, label: '制作待评分' },
    { id: 8, label_code: 10, label: '制作待选' },
    { id: 9, label_code: 11, label: '待制作' },
    { id: 10, label_code: 12, label: '制作中' },
    { id: 11, label_code: 13, label: '制作初审待审' },
    { id: 12, label_code: 14, label: '制作终审待审' },
    { id: 13, label_code: 15, label: '工程文件待分配' },
    { id: 14, label_code: 16, label: '工程文件待制作' },
    { id: 15, label_code: 17, label: '工程文件制作中' },
    { id: 30, label_code: 18, label: '工程文件初审' },
    { id: 16, label_code: 19, label: '工程文件终审' },
    { id: 23, label_code: 20, label: '待入库' },
    { id: 24, label_code: 21, label: '入库中' },
    { id: 18, label_code: 22, label: '入库/作废' }
];

export const historyFlowStepsWithoutCollectScore = [
    { id: 0, label_code: 1, label: '创建资产' },
    { id: 1, label_code: 2, label: '拍摄中' },
    { id: 3, label_code: 3, label: '原序列待审' },
    { id: 4, label_code: 4, label: '解析待分配' },
    { id: 5, label_code: 5, label: '待解析' },
    { id: 6, label_code: 6, label: '解析中' },
    { id: 7, label_code: 7, label: '解析待审' },
    { id: 21, label_code: 8, label: '制作待评分' },
    { id: 8, label_code: 9, label: '制作待选' },
    { id: 9, label_code: 10, label: '待制作' },
    { id: 10, label_code: 11, label: '制作中' },
    { id: 11, label_code: 12, label: '制作初审待审' },
    { id: 12, label_code: 13, label: '制作终审待审' },
    { id: 13, label_code: 14, label: '工程文件待分配' },
    { id: 14, label_code: 15, label: '工程文件待制作' },
    { id: 15, label_code: 16, label: '工程文件制作中' },
    { id: 30, label_code: 17, label: '工程文件初审' },
    { id: 16, label_code: 18, label: '工程文件终审' },
    { id: 23, label_code: 19, label: '待入库' },
    { id: 24, label_code: 20, label: '入库中' },
    { id: 18, label_code: 21, label: '入库/作废' }
];

export const historyEntryLabels = {
    creator: '创建人',
    asset_name: '资产名称',
    asset_uid: '内部ID',
    asset_type: '资产类型',
    asset_category: '资产种类',
    location: '采集地区',
    photographers: '拍摄人员',
    operator: '任务处理人',
    score: '制作评分',
    collScore: '拍摄评分',
    priority: '优先级',
    comment: '备注',
    operation: '操作类型',
    reason: '作废原因',
    discard_type: '作废类型',
    difficulty: '难度系数',
    collect_images_count: '原序列图片数量',
    action_result: '执行结果',
    action_error_message: '失败原因',
    godown_file_type: '文件类型',
    next_step: '下一步',
    old_asset_type: "原资产类型",
    old_asset_id: "原资产ID",
    new_asset_type: "新资产类型",
    new_asset_id: "新资产ID",
    rollback_result: "回退结果",
    rollback_reason: "回退原因",
    origin_score: "原评分",
    asset_eng_name: "英文名称",
    is_close: "封闭状态",
    asset_size: "资产尺寸",
    integrate: "建议价格",
    color_code: "建议颜色",
    reset_status: "重置后状态",
    old_operator: "原处理人",
    assign_user: "待处理人",
    reset_reason: "重置原因",
};

let splitTaskArr = [];
for (let i = 2; i < 21; i++) {
    let obj = {};
    obj['key'] = i;
    obj['label'] = `${i}`;
    splitTaskArr.push(obj);
}

export const splitTask = [{ key: 1, label: '不拆分' }, ...splitTaskArr];

const taskDetails = ['asset_name', 'asset_uid', 'asset_type', 'location', 'photographers', 'collect_images_count', 'comment', 'image_urls'];

// 历史记录
const operatorOperation = ['operator', 'operation'];
const operationRelated = [...operatorOperation, 'assignees'];
const easyManipulation = [...operatorOperation, 'comment'];
const normalReview = [...operationRelated, 'comment', 'image_urls'];
const normalManipulation = [...operationRelated, 'comment'];
const photoArtApprove = [...operationRelated, 'score', 'comment'];
const manipulationWithPriority = [...operationRelated, 'priority', 'comment'];
const priorityDifficulty = [
    ...operationRelated,
    // "priority",
    'difficulty',
    'comment'
];
const manipulationWithImage = [...operationRelated, 'comment', 'image_urls'];
const discardTaskAssign = [...operatorOperation, 'discard_type', 'comment'];
const approveAnalysis = [...operationRelated, 'priority', 'asset_category', 'comment'];
const collectScore = [...operationRelated, 'collScore'];
const scoreProduce = [...operationRelated, 'score', 'split_task'];
const scoreSplitProduce = ['split_task', 'comment'];
const completeScoreProduce = ['operation'];
const productionArtReview = [...operationRelated, 'difficulty', 'comment', 'image_urls'];
const updateKindTask = [...operatorOperation, "old_asset_type", "old_asset_id", "new_asset_type", "new_asset_id"]; // 修改资产ID详情
const updateProduceScore = [...operatorOperation, "origin_score", "score"];
const checkSpecials = (action, status, record) => {
    let res;
    switch (action) {
        case 'approve_production_art':
        case 'disapprove_production_art':
        case 'disapprove_production_result':
            res = productionArtReview;
            break;
        case "update_produce_score":
            res = updateProduceScore;
            break;
        case 'assign_producer':
            res = priorityDifficulty;
            break;
        case "duplicate_task":
            res = scoreSplitProduce;
            break;
        case "update_kind_task":
            res = updateKindTask;
            break;
        case 'create_task':
            res = ['creator', ...taskDetails];
            break;
        case 'submit_file':
            res = [...operatorOperation, 'assignees', "comment", 'image_urls'];
            break;
        case 'approve_photo_collection_score':
            res = collectScore;
            break;
        case 'update_task':
            res = [...operatorOperation, ...taskDetails];
            break;
        case 'approve_file_formats':
            res = easyManipulation;
            break;
        case 'approve_analysis':
            res = approveAnalysis;
            break;
        case 'score_produce':
            if (record.history_data.children) {
                res = scoreSplitProduce;
            } else {
                res = scoreProduce;
            }

            break;
        case 'complete_score_produce':
            res = completeScoreProduce;
            break;
        case 'submit_for_analysis':
        case 'submit_production_art':
            res = manipulationWithImage;
            break;
        case 'approve_photo_art':
            res = photoArtApprove;
            break;
        case 'discard_task':
            if (status === 18 || status === 19) {
                res = operatorOperation;
            }
            res = discardTaskAssign;
            break;
        case 'file_to_stock':
            res = ['operator', 'operation', 'action_result', 'action_error_message', 'next_step', 'godown_file_type', 'asset_name', 'asset_eng_name', 'is_close', 'asset_size', 'integrate', 'color_code', 'comment'];
            break;
        case 'rollback_analysis':
        case 'rollback_task':
            res = ['operator', 'operation', 'rollback_result', 'rollback_reason']
            break;
        case 'remake_asset_godown':
            res = ['operator', 'operation', 'reset_reason','reset_status', 'old_operator', 'assign_user', 'comment']
            break;
        default:
            res = [];
            break;
    }
    return res;
};

const checkIncludes = (action) => {
    const actionKey = action.split('_')[0];
    switch (actionKey) {
        case 'assign':
            return manipulationWithPriority;
        case 'initiate':
            return easyManipulation;
        case 'disapprove':
            return normalReview;

        default:
            return normalManipulation;
    }
};

export const getScenarioKeys = (action, status, record) => {
    let keywords = checkSpecials(action, parseInt(status), record);
    if (keywords.length < 1) {
        keywords = checkIncludes(action);
    }
    return keywords;
};
/**
 * @method 获取资产操作一级底部按钮文字
 * @param {*} action 操作类型
 */
export const taskActionLabels = (action) => {
    if (action === 'approve_photo_collection_score') return '采集评分';
    if (action === 'approve_file_formats') return '入库';
    if (action === 'file_to_stock') return '入库';
    if (action === "update_kind_task") return "修改类型";
    if (action === "update_produce_score") return "修改评分";
    if (action === "duplicate_task") return "新增制作任务";
    if (action === "remake_asset_godown") return "入库重置"
    return taskActionText[action.split('_')[0]] ?? '';
};
export const taskActionText = {
    submit: '提交',
    discard: '作废',
    approve: '通过',
    disapprove: '驳回',
    assign: '分配',
    initiate: '开始',
    reassign: '转派',
    rollback: '回退',
    stop: '停止',
    remake: '重制',
    score: '制作评分',
    approve_photo_collection_score: '采集评分',
    update: '修改数量'
};
/**
 * @method 获取资产操作二级抽屉底部取消/确认按钮文字
 * @param {*} action 当前操作的类型
 * @returns
 */
export const operationFlowButtonText = (action) => {
    return drawerFootText[action] ?? ['确认处理', '取消处理'];
};
/**
 * TaskProcessDrawer 底部按钮文字
 */
export const drawerFootText = {
    score_produce: ['保存', '取消'],
    approve_photo_collection_score: ['保存', '取消'],
    update_collect_image: ['确认修改', '取消修改']
};

// 处理表单
const normalForm = ['assignee'];
const formWithImage = ['assignee', 'images'];
const submitFile = ['assignee', 'image'];
const formWithTwoAssigneesImage = ['twoAssignees', 'images'];
const basicForm = [];
const discardForm = ['discard_type'];
const remakeForm = ['prev_assignee'];
const disapproveProductionForm = ['assignee', 'images'];
const approvePhotoCollectionScoreForm = ['score_produce_assignee', 'collectionGrade', 'collectionProgress'];
const disapproveForm = ['prev_assignee', 'make_submitter', 'images'];
const disapproveShootingForm = ['person_user', 'images'];
const assignForm = ['priority', 'assignee'];
const artForm = ['score', 'assignee'];
const analysisForm = ['priority', 'category'];
const scoreProduceForm = ['score_produce_assignee', 'split_task', 'grade', 'opinion']; // progress 评分进度隐藏
const rollbackProductionForm = ['specify_fallback'];
const producerForm = ['assignee', 'approve_production_result'];
const updateCollectFrom = ['old_amount', 'new_amount', 'upload_voucher'];
const updateKindTaskFrom = ['old_asset_type', 'old_asset_ID', "new_asset_type", "update_reason"]; // 修改资产ID表单
const splitInProduceFinalFrom = ["prev_assignee", "new_split_task", "grade", "opinion"]; // 新增任务
const updateScoreFrom = ["old_score", "grade"]; // 制作终审待审修改评分
const fileFirstCheckDisapproveFrom = ["prev_assignee", 'images']; // 工程文件初审驳回
export const processDrawerKeys = (action) => {
    const actionKey = action.split('_')[0];
    const res =
        action === 'approve_photo_art'
            ? artForm
            : action === 'remake_asset_godown'
            ? ['remake_asset_godown']
            : action === "update_kind_task"
            ? updateKindTaskFrom
            : action === "update_produce_score"
            ? updateScoreFrom
            : action === "disapprove_program_first_check"
            ? fileFirstCheckDisapproveFrom
            : action === "duplicate_task"
            ? splitInProduceFinalFrom
            : action === 'approve_photo_collection_score'
            ? approvePhotoCollectionScoreForm
            : action === 'approve_file'
            ? ['assignee']
            : action === 'disapprove_production_art' || action === 'disapprove_production_result'
            ? disapproveProductionForm
            : action === 'approve_analysis'
            ? analysisForm
            : action === 'submit_for_analysis'
            ? formWithImage
            : actionKey === 'rollback'
            ? rollbackProductionForm
            : action === 'submit_production_art'
            ? formWithTwoAssigneesImage
            : actionKey === 'discard'
            ? discardForm
            : action === 'disapprove_primitive' // 第二步
            ? disapproveShootingForm
            : action === 'disapprove_file' // 第18步
            ? disapproveForm
            : ['stop', 'remake'].includes(actionKey)
            ? remakeForm
            : action === 'approve_file_formats' || actionKey === 'initiate'
            ? basicForm
            : actionKey !== 'reassign' && action.includes('assign')
            ? assignForm
            : action === 'score_produce'
            ? scoreProduceForm
            : action === 'approve_production_result'
            ? producerForm
            : action === 'submit_file'
            ? submitFile
            : action === 'update_collect_image'
            ? updateCollectFrom
            : action === 'file_to_stock'
            ? ['file_to_stock']
            : normalForm;
    return res;
};

export const priorityTypes = ['', '顶', '高', '低'];

// 资产尺寸
export const tastSize = [
    {
        label: '长',
        sizeName: 'length',
        unit: 'lengthUnit'
    },
    {
        label: '宽',
        sizeName: 'width',
        unit: 'widthUnit'
    },
    {
        label: '高',
        sizeName: 'height',
        unit: 'heightUnit'
    }
]
