import axios from 'axios'
import { forceLogout } from '@components'
import env from 'env'

const configureInceptors = (store) => {
    axios.defaults.timeout = 100000;
    axios.defaults.baseURL = env.serveUrls;
    axios.defaults.headers.post["Content-Type"] =
        "application/json;charset=UTF-8";
    axios.defaults.headers.get["Content-Type"] = "application/json;charset=UTF-8";

    // request 拦截器
    axios.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("token");
            token && (config.headers["x-token"] = token);
            return config;
        },
        (error) => Promise.reject(error)
    );

    // response 拦截器
    axios.interceptors.response.use(
        (response) => response.data,
        (error) => {
            let response = error.response;
            if (response) {
                let errorStatus = response.status; // 错误码
                let errorData = response.data;
                if(Object.prototype.toString.call(response.data) === '[object String]'){
                    const filterMsg = filterFieldRpc(response.data)
                    errorData = {
                        code: errorStatus,
                        data: {},
                        msg: filterMsg
                    }
                }
                if (errorStatus === 401) {
                    store.dispatch({ type: "LOGOUT_CURRENT_USER" });
                    localStorage.removeItem("token");

                    forceLogout()
                    return errorData;
                }
                return errorData
            }
            return Promise.reject(error);
        }
    );
};

/**
 * 截取请求报错msg，rpc 返回 desc = 后的字节
 * @param {string} msg
 * @returns {string} 截取后的报错信息
 */
const filterFieldRpc = (msg) => {
    if(typeof msg !== 'string') return ''
    const code = 'desc = '
    const index = msg.lastIndexOf(code)
    return index > -1 ? msg.slice(index + code.length) : msg
}

export default configureInceptors;
