import * as React from "react";
import { observer, inject } from "mobx-react";
import { Form, Select } from "antd";
import { RangePicker } from "@components";
import { RangeInput, RangeInputPositiveNumber, RangeInputPositiveInteger, RangeInputAnyNumber,  FormSelect } from "../0_components";
import { withRouter } from "react-router";
import { artConfigs, productionConfigs } from "@vars/views/stats_vars";
import moment from "moment";
import { autorun } from "mobx";

const { Option } = Select;

@withRouter
@inject("summaryStore")
@observer
class SummaryFilter extends React.Component {
  formRef = React.createRef();
  get store() {
    return this.props.summaryStore;
  }
  get paramFilter() {
    const { search } = this.props.location;
    const filter =
      search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
    return filter || {};
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  get configs() {
    return this.statType === "art"
      ? artConfigs
      : this.statType === "production" && productionConfigs;
  }
  componentDidMount() {
    this.listenToTabChange();
    this.store.updateDateType(2)
  }
  componentWillUnmount() {
    this.store.clear()
  }

  listenToTabChange = () => {
    autorun(() => {
      if (this.store.tab) {
        this.clearForm();
        this.store.clear();
      }
    });
  };

  handleFormChange = (_, allValues) => this.store.updateFilter(allValues);
  clearForm = () => this.formRef?.current?.resetFields();
  clear = () => {
    this.clearForm();
    this.props.setDefaultdate();
  };

  changePickerType = (val) => {
    this.store.updateDateType(val);
    const from = moment().subtract(6, "months").startOf("month");
    const to = moment().subtract(1, "months").endOf("month");
    const date = [from, to];
    const filter = { date };
    this.props.history.push({
      search: `?filter=${JSON.stringify(filter)}`,
    });
  };

  renderFilters = () => {
    const { pathname } = this.props.location;
    const isUser = pathname.includes("user");
    const filterRender = isUser
      ? this.configs.userFilters
      : this.configs.allFilters;
    return (
      <>
        {this.renderBasics()}
        {isUser ? this.renderUserSelect() : ""}
        {filterRender?.map(({name, label}) => {
            if (this.statType === "art") {
              if (!isUser) {
                if (name === "score_total" || name === "assignee_avg_score" || name === "assignee_task_avg_score") {
                  return <RangeInputAnyNumber name={name} label={label} key={name} />
                } else if (name === "sort" || name === "task_count") {
                  return <RangeInputPositiveInteger name={name} label={label} key={name} />
                } else if (name === "assignee_avg_task") {
                  return <RangeInputPositiveNumber name={name} label={label} key={name} />
                }
              } else {
                if (name === "score_total" || name === "task_avg_score") {
                  return <RangeInputAnyNumber name={name} label={label} key={name} />
                } else if (name === "task_count" || name === "sort") {
                  return <RangeInputPositiveInteger name={name} label={label} key={name} />
                }
              }
            } else {
              if (!isUser) {
                if (name === "score_total" || name === "assignee_avg_task" || name === "assignee_avg_score" ) {
                  return <RangeInputAnyNumber name={name} label={label} key={name} />
                } else if (name === "task_count") {
                  return <RangeInputPositiveInteger name={name} label={label} key={name} />
                }
              } else {
                if (name === "score_total") {
                  return <RangeInputAnyNumber name={name} label={label} key={name} />
                } else if (name === "task_count" || name === "sort") {
                  return <RangeInputPositiveInteger name={name} label={label} key={name} />
                }
              }
            }
          })
        }
      </>
    );
  };
  renderUserSelect = () => {
    const options = this.store.relatedUsers?.map((person) => ({
      code: person.user_id,
      label: person.name,
    }));
    const label = this.statType === "production" ? "制作人员" : "拍摄人员";
    return <FormSelect name="user_id" label={label} options={options} />;
  };

  renderPickerType = () => {
    return (
      <div>
        <span className="bold mg-r-10">统计周期</span>
        <Select
          size="large"
          className="filter-input mg-r-16"
          value={this.store.dateType}
          onChange={this.changePickerType}
        >
          <Option value={2}>周</Option>
          <Option value={1}>月</Option>
        </Select>
      </div>
    );
  };

  renderBasics = () => {
    const { date } = this.paramFilter;
    const { dateType } = this.store;
    const isProduction = this.statType === "production";
    const isWeek = dateType === 2;
    const weekFormat = isProduction && isWeek;
    return (
      <>
        <div
          className="align-center"
          style={{ marginTop: "-20px", marginRight: "16px" }}
        >
          {isProduction ? this.renderPickerType() : ""}
          <span className="bold mg-r-10">汇总时间</span>
          <RangePicker
            placeholder={["选择时间"]}
            picker={weekFormat ? "week" : "month"}
            value={date?.map((day) => moment(day))}
            onChange={(date) =>
              this.props.history.push({
                search: `?filter=${JSON.stringify({ date })}`,
              })
            }
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="mg-t-4">
        <Form
          ref={this.formRef}
          onValuesChange={this.handleFormChange}
          layout="inline"
        >
          {this.renderFilters()}
          <div className="reset-filter" onClick={this.clear}>
            <span>重置</span>
          </div>
        </Form>
      </div>
    );
  }
}
export default SummaryFilter;
