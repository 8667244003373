import {
    HttpClient
} from 'ecoplants-lib';
import * as CommonPbManageMessage from '@/api/protos_api/common/amp/common_pb';
import * as DispatchManageMessage from '@/api/protos_api/manager_api_pb';

import {
    ManagerApiServicePromiseClient
} from '@/api/protos_api/manager_api_grpc_web_pb';

import {
    ListCoreSchedulerStepGroupRequest,
    ListSchedulerStepRequest,
    ListSysUserSchedulerRoleRequest
} from '@/api/protos_api/manager_api_pb';

/**
 * 选题模块-api
 */
class DispatchApi extends HttpClient<any, any> {
    /**
     * 构造函数
     */
    constructor() {
        super({
            GrpcClient: ManagerApiServicePromiseClient,
            services: { ...CommonPbManageMessage, ...DispatchManageMessage }
        });
    }

    /**
     * 获取节点
     * @param {object} data data
     * @returns {Promise}
     */
    listCoreSchedulerStepGroup(data: ListCoreSchedulerStepGroupRequest.AsObject) {
        return this.fetchData('listCoreSchedulerStepGroup', {
            data
        }, {
            rpcRequestName: 'ListCoreSchedulerStepGroup'
        });
    }

    /**
     * 调度步骤列表
     * @param {object} data data
     * @returns {Promise}
     */
    listSchedulerStep(data: ListSchedulerStepRequest.AsObject) {
        return this.fetchData('listSchedulerStep', {
            data
        }, {
            rpcRequestName: 'ListSchedulerStep'
        });
    }

    /**
     * 人员类型
     * @param {object} data data
     * @returns {Promise}
     */
    listSysUserSchedulerRole(data: ListSysUserSchedulerRoleRequest.AsObject) {
        const _data = {
            page: 1,
            pageSize: 9999
        };
        return this.fetchData('listSysUserSchedulerRole', {
            data: { ..._data, ...data }
        }, {
            rpcRequestName: 'ListSysUserSchedulerRole'
        });
    }
}
/**
 * ProjectManageApi
 * @returns {function} 请求api单例
 */
export default function (): any {
    return DispatchApi.singleton();
}
