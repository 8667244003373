/**
 * @fileoverview gRPC-Web generated client stub for core_api
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_amp_common_pb = require('./common/amp/common_pb.js')
const proto = {};
proto.core_api = require('./core_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.core_api.CoreApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.core_api.CoreApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.GetTaskAssignmentRequest,
 *   !proto.core_api.GetTaskAssignmentResponse>}
 */
const methodDescriptor_CoreApiService_GetAccountAssignment = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetAccountAssignment',
  grpc.web.MethodType.UNARY,
  proto.core_api.GetTaskAssignmentRequest,
  proto.core_api.GetTaskAssignmentResponse,
  /**
   * @param {!proto.core_api.GetTaskAssignmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.GetTaskAssignmentResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.GetTaskAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.GetTaskAssignmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.GetTaskAssignmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getAccountAssignment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetAccountAssignment',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetAccountAssignment,
      callback);
};


/**
 * @param {!proto.core_api.GetTaskAssignmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.GetTaskAssignmentResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getAccountAssignment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetAccountAssignment',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetAccountAssignment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicInfoRequest,
 *   !proto.core_api.TopicInfoResponse>}
 */
const methodDescriptor_CoreApiService_GetTopicInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetTopicInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicInfoRequest,
  proto.core_api.TopicInfoResponse,
  /**
   * @param {!proto.core_api.TopicInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getTopicInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicInfo,
      callback);
};


/**
 * @param {!proto.core_api.TopicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getTopicInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.SceneAndOutlineRequest,
 *   !proto.core_api.SceneAndOutlineResponse>}
 */
const methodDescriptor_CoreApiService_GetTopicSceneAndOutline = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetTopicSceneAndOutline',
  grpc.web.MethodType.UNARY,
  proto.core_api.SceneAndOutlineRequest,
  proto.core_api.SceneAndOutlineResponse,
  /**
   * @param {!proto.core_api.SceneAndOutlineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.SceneAndOutlineResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.SceneAndOutlineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.SceneAndOutlineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.SceneAndOutlineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getTopicSceneAndOutline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicSceneAndOutline',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicSceneAndOutline,
      callback);
};


/**
 * @param {!proto.core_api.SceneAndOutlineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.SceneAndOutlineResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getTopicSceneAndOutline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicSceneAndOutline',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicSceneAndOutline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicInfoCommonRequest,
 *   !proto.core_api.TopicResponse>}
 */
const methodDescriptor_CoreApiService_UpdateTopic = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/UpdateTopic',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicInfoCommonRequest,
  proto.core_api.TopicResponse,
  /**
   * @param {!proto.core_api.TopicInfoCommonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicInfoCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.updateTopic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopic',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopic,
      callback);
};


/**
 * @param {!proto.core_api.TopicInfoCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.updateTopic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopic',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicInfoCommonRequest,
 *   !proto.core_api.TopicResponse>}
 */
const methodDescriptor_CoreApiService_CreateTopic = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/CreateTopic',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicInfoCommonRequest,
  proto.core_api.TopicResponse,
  /**
   * @param {!proto.core_api.TopicInfoCommonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicInfoCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.createTopic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopic',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopic,
      callback);
};


/**
 * @param {!proto.core_api.TopicInfoCommonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.createTopic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopic',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicListRequest,
 *   !proto.core_api.TopicListResponse>}
 */
const methodDescriptor_CoreApiService_GetTopicList = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetTopicList',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicListRequest,
  proto.core_api.TopicListResponse,
  /**
   * @param {!proto.core_api.TopicListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicListResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getTopicList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicList',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicList,
      callback);
};


/**
 * @param {!proto.core_api.TopicListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicListResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getTopicList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicList',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicSceneAddressRequest,
 *   !proto.core_api.TopicSceneAddressResponse>}
 */
const methodDescriptor_CoreApiService_CreateTopicSceneAddress = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/CreateTopicSceneAddress',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicSceneAddressRequest,
  proto.core_api.TopicSceneAddressResponse,
  /**
   * @param {!proto.core_api.TopicSceneAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicSceneAddressResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicSceneAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicSceneAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.createTopicSceneAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopicSceneAddress,
      callback);
};


/**
 * @param {!proto.core_api.TopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicSceneAddressResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.createTopicSceneAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopicSceneAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicSceneAddressRequest,
 *   !proto.core_api.TopicSceneAddressResponse>}
 */
const methodDescriptor_CoreApiService_UpdateTopicSceneAddress = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/UpdateTopicSceneAddress',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicSceneAddressRequest,
  proto.core_api.TopicSceneAddressResponse,
  /**
   * @param {!proto.core_api.TopicSceneAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicSceneAddressResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicSceneAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicSceneAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.updateTopicSceneAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopicSceneAddress,
      callback);
};


/**
 * @param {!proto.core_api.TopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicSceneAddressResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.updateTopicSceneAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopicSceneAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicSceneUnbindRequest,
 *   !proto.core_api.TopicSceneAddressResponse>}
 */
const methodDescriptor_CoreApiService_DeleteTopicSceneAddress = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/DeleteTopicSceneAddress',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicSceneUnbindRequest,
  proto.core_api.TopicSceneAddressResponse,
  /**
   * @param {!proto.core_api.TopicSceneUnbindRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicSceneAddressResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicSceneUnbindRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicSceneAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicSceneAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.deleteTopicSceneAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteTopicSceneAddress,
      callback);
};


/**
 * @param {!proto.core_api.TopicSceneUnbindRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicSceneAddressResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.deleteTopicSceneAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteTopicSceneAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicSceneInfoRequest,
 *   !proto.core_api.TopicSceneInfoResponse>}
 */
const methodDescriptor_CoreApiService_GetTopicSceneInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetTopicSceneInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicSceneInfoRequest,
  proto.core_api.TopicSceneInfoResponse,
  /**
   * @param {!proto.core_api.TopicSceneInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicSceneInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicSceneInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicSceneInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicSceneInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getTopicSceneInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicSceneInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicSceneInfo,
      callback);
};


/**
 * @param {!proto.core_api.TopicSceneInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicSceneInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getTopicSceneInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetTopicSceneInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetTopicSceneInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.ListTopicSceneAddressRequest,
 *   !proto.core_api.ListTopicSceneAddressResponse>}
 */
const methodDescriptor_CoreApiService_ListTopicSceneAddress = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/ListTopicSceneAddress',
  grpc.web.MethodType.UNARY,
  proto.core_api.ListTopicSceneAddressRequest,
  proto.core_api.ListTopicSceneAddressResponse,
  /**
   * @param {!proto.core_api.ListTopicSceneAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.ListTopicSceneAddressResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.ListTopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.ListTopicSceneAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.ListTopicSceneAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.listTopicSceneAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/ListTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListTopicSceneAddress,
      callback);
};


/**
 * @param {!proto.core_api.ListTopicSceneAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.ListTopicSceneAddressResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.listTopicSceneAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/ListTopicSceneAddress',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListTopicSceneAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicAnnotationRequest,
 *   !proto.core_api.TopicAnnotationResponse>}
 */
const methodDescriptor_CoreApiService_CreateTopicAnnotation = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/CreateTopicAnnotation',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicAnnotationRequest,
  proto.core_api.TopicAnnotationResponse,
  /**
   * @param {!proto.core_api.TopicAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.createTopicAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopicAnnotation,
      callback);
};


/**
 * @param {!proto.core_api.TopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.createTopicAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/CreateTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateTopicAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicAnnotationRequest,
 *   !proto.core_api.TopicAnnotationResponse>}
 */
const methodDescriptor_CoreApiService_UpdateTopicAnnotation = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/UpdateTopicAnnotation',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicAnnotationRequest,
  proto.core_api.TopicAnnotationResponse,
  /**
   * @param {!proto.core_api.TopicAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.updateTopicAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopicAnnotation,
      callback);
};


/**
 * @param {!proto.core_api.TopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.updateTopicAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateTopicAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.ListTopicAnnotationRequest,
 *   !proto.core_api.ListTopicAnnotationResponse>}
 */
const methodDescriptor_CoreApiService_ListTopicAnnotation = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/ListTopicAnnotation',
  grpc.web.MethodType.UNARY,
  proto.core_api.ListTopicAnnotationRequest,
  proto.core_api.ListTopicAnnotationResponse,
  /**
   * @param {!proto.core_api.ListTopicAnnotationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.ListTopicAnnotationResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.ListTopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.ListTopicAnnotationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.ListTopicAnnotationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.listTopicAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/ListTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListTopicAnnotation,
      callback);
};


/**
 * @param {!proto.core_api.ListTopicAnnotationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.ListTopicAnnotationResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.listTopicAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/ListTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListTopicAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.IdRequest,
 *   !proto.core_api.EmptyResponse>}
 */
const methodDescriptor_CoreApiService_DeleteTopicAnnotation = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/DeleteTopicAnnotation',
  grpc.web.MethodType.UNARY,
  proto.core_api.IdRequest,
  proto.core_api.EmptyResponse,
  /**
   * @param {!proto.core_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.deleteTopicAnnotation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteTopicAnnotation,
      callback);
};


/**
 * @param {!proto.core_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.deleteTopicAnnotation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteTopicAnnotation',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteTopicAnnotation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest,
 *   !proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse>}
 */
const methodDescriptor_CoreApiService_ListUserSimpleInfoByIntegrationCondition = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/ListUserSimpleInfoByIntegrationCondition',
  grpc.web.MethodType.UNARY,
  proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest,
  proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse,
  /**
   * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.listUserSimpleInfoByIntegrationCondition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/ListUserSimpleInfoByIntegrationCondition',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListUserSimpleInfoByIntegrationCondition,
      callback);
};


/**
 * @param {!proto.core_api.ListUserSimpleInfoByIntegrationConditionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.ListUserSimpleInfoByIntegrationConditionResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.listUserSimpleInfoByIntegrationCondition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/ListUserSimpleInfoByIntegrationCondition',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListUserSimpleInfoByIntegrationCondition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.TopicSceneSchedulerHandlerRequest,
 *   !proto.core_api.TopicSceneSchedulerHandlerResponse>}
 */
const methodDescriptor_CoreApiService_TopicSceneSchedulerHandler = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/TopicSceneSchedulerHandler',
  grpc.web.MethodType.UNARY,
  proto.core_api.TopicSceneSchedulerHandlerRequest,
  proto.core_api.TopicSceneSchedulerHandlerResponse,
  /**
   * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.TopicSceneSchedulerHandlerResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.TopicSceneSchedulerHandlerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.TopicSceneSchedulerHandlerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.topicSceneSchedulerHandler =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/TopicSceneSchedulerHandler',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_TopicSceneSchedulerHandler,
      callback);
};


/**
 * @param {!proto.core_api.TopicSceneSchedulerHandlerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.TopicSceneSchedulerHandlerResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.topicSceneSchedulerHandler =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/TopicSceneSchedulerHandler',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_TopicSceneSchedulerHandler);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.AssetExtendInfoRequest,
 *   !proto.core_api.AssetExtendInfoResponse>}
 */
const methodDescriptor_CoreApiService_CreateAssetExtendInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/CreateAssetExtendInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.AssetExtendInfoRequest,
  proto.core_api.AssetExtendInfoResponse,
  /**
   * @param {!proto.core_api.AssetExtendInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.AssetExtendInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.AssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.AssetExtendInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.AssetExtendInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.createAssetExtendInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/CreateAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateAssetExtendInfo,
      callback);
};


/**
 * @param {!proto.core_api.AssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.AssetExtendInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.createAssetExtendInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/CreateAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_CreateAssetExtendInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.AssetExtendInfoRequest,
 *   !proto.core_api.AssetExtendInfoResponse>}
 */
const methodDescriptor_CoreApiService_UpdateAssetExtendInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/UpdateAssetExtendInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.AssetExtendInfoRequest,
  proto.core_api.AssetExtendInfoResponse,
  /**
   * @param {!proto.core_api.AssetExtendInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.AssetExtendInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.AssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.AssetExtendInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.AssetExtendInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.updateAssetExtendInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateAssetExtendInfo,
      callback);
};


/**
 * @param {!proto.core_api.AssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.AssetExtendInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.updateAssetExtendInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/UpdateAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_UpdateAssetExtendInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.IdRequest,
 *   !proto.core_api.EmptyResponse>}
 */
const methodDescriptor_CoreApiService_DeleteAssetExtendInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/DeleteAssetExtendInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.IdRequest,
  proto.core_api.EmptyResponse,
  /**
   * @param {!proto.core_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.deleteAssetExtendInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteAssetExtendInfo,
      callback);
};


/**
 * @param {!proto.core_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.deleteAssetExtendInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/DeleteAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_DeleteAssetExtendInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.GetAssetExtendInfoRequest,
 *   !proto.core_api.AssetExtendInfoResponse>}
 */
const methodDescriptor_CoreApiService_GetAssetExtendInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/GetAssetExtendInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.GetAssetExtendInfoRequest,
  proto.core_api.AssetExtendInfoResponse,
  /**
   * @param {!proto.core_api.GetAssetExtendInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.AssetExtendInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.GetAssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.AssetExtendInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.AssetExtendInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.getAssetExtendInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/GetAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetAssetExtendInfo,
      callback);
};


/**
 * @param {!proto.core_api.GetAssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.AssetExtendInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.getAssetExtendInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/GetAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_GetAssetExtendInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.core_api.ListAssetExtendInfoRequest,
 *   !proto.core_api.ListAssetExtendInfoResponse>}
 */
const methodDescriptor_CoreApiService_ListAssetExtendInfo = new grpc.web.MethodDescriptor(
  '/core_api.CoreApiService/ListAssetExtendInfo',
  grpc.web.MethodType.UNARY,
  proto.core_api.ListAssetExtendInfoRequest,
  proto.core_api.ListAssetExtendInfoResponse,
  /**
   * @param {!proto.core_api.ListAssetExtendInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.core_api.ListAssetExtendInfoResponse.deserializeBinary
);


/**
 * @param {!proto.core_api.ListAssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.core_api.ListAssetExtendInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.core_api.ListAssetExtendInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.core_api.CoreApiServiceClient.prototype.listAssetExtendInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/core_api.CoreApiService/ListAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListAssetExtendInfo,
      callback);
};


/**
 * @param {!proto.core_api.ListAssetExtendInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.core_api.ListAssetExtendInfoResponse>}
 *     Promise that resolves to the response
 */
proto.core_api.CoreApiServicePromiseClient.prototype.listAssetExtendInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/core_api.CoreApiService/ListAssetExtendInfo',
      request,
      metadata || {},
      methodDescriptor_CoreApiService_ListAssetExtendInfo);
};


module.exports = proto.core_api;

