import { fetchNotifications } from "@actions/1_entities/account_actions";
import { connect } from "react-redux";
import Notifications from "./notifications";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: (params, success, error) =>
    dispatch(fetchNotifications(params, success, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
