const name = 'name'
const userCount = 'userCount'

const initState = {
  name: '',
}
// 表格

const roleColumns = [
  {
    title: "角色ID",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "角色名称",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "绑定人数",
    key: "user_count",
    dataIndex: "user_count",
  },
];

// 弹框

const roleAdd = {
  name: '',
  permissions: [],
  roleCount:[]
}

const roleDIC = [{
    title: '角色名称',
    dataIndex: 'name',
    placeholder: '请输入角色名称',
    rules: {
      name: [{
          required: true,
          message: '请输入角色名称',
          type: 'string'
        },
        {
          message: '角色名称不能超过10个字符',
          min: 1,
          max: 10,
          type: 'string'
        }
      ]
    }
  },
  {
    title: '绑定人员',
    placeholder: '未关联'
  },
  {
    title: '角色权限'
  }
]


export {
  roleColumns,
  initState,
  roleDIC,
  name,
  userCount,
  roleAdd
}