import "./index.less";
import { useRef } from "react";
import { observer, inject } from "mobx-react";
import { Details, TaskPlanning } from "./task_planning";
import TaskDrawer from "./task_drawer"
import TaskPackageOperate from "./task_package_operate";

/**
 * 任务包详情页面
 * @returns
 */
const TaskDetails = (props) => {
    const { visible, title } = props.taskPlanStore
    let flag = "";
    switch (title) {
        // 提交审核、创建、批量创建、修改策划任务
        case 0:
        case 1:
        case 2:
        case 3:
            flag = "A";
            break;
        // 任务包审核、公开、驳回、作废、承接、放弃
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            flag = "B";
            break;
    }
    return (
        <>
            <div className="amps-task-details">
                <Details />
                <TaskDrawer
                    containerRef={props.taskPlanRef.current}
                    visible={visible}
                >
                    {
                        flag && flag === "A" ? (
                            <TaskPlanning {...props} />
                        ) : (
                            <TaskPackageOperate {...props} />
                        )
                    }
                </TaskDrawer>
            </div>
        </>
    )
};

export default inject("taskPlanStore")(observer(TaskDetails));
