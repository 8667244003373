export const wechatCards = [
  // {
  //   name: "daily_notification",
  //   label: "每日提醒",
  //   title: "未处理待办事项统计",
  // },
  {
    name: "task_notification",
    label: "新待办提醒",
    title: "新待办事项通知",
  },
  {
    name: "task_count_notification",
    label: "超额通知",
    title: "工作量预警",
  },
];
