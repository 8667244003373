import React, { Component } from "react";
import { Input, Select } from "antd";
import { formatTime } from "@/utils/global/functional_util";
import { RangePicker } from "@/components";
import { debounce } from '@gUtils';

const { Option } = Select;
const initialState = {
    title_author: "",
    published_at: "",
    status: null,
};
class AnnouncementFilter extends Component {
    state = initialState;

    componentDidMount() {
        this.searchRequest();
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.searchRequest();
        }
    }

    componentWillUnmount() {
        // this.props.fetchAssets({});
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        // 清除redux表格记录的筛选数据
        this.props.requestAnnouncements({})
    }

    // functions
    clear = () => {
        this.setState(initialState);
    };

    setStateFunc = (key, val) => {
        this.setState({ [key]: val });
    };
    setRangeFunc = (date, key) => {
        this.setStateFunc(key, date);
    };

    handleStatusChange = (value) => {
        this.setStateFunc("status", value);
    };

    searchRequest = () => {
        let { title_author, status } = this.state;
        let params = {
            page: 1,
            filter: {
                title_author,
                published_at: formatTime(this.state.published_at),
                status,
            },
            page_size: 12,
        };
        this.debounceSearchAnnouncements(params);
    };

    debounceSearchAnnouncements = debounce(this.props.searchAnnouncements, 300);


    // components render functions
    dateRangesRender = () => (
        <div className="mg-r-20">
            <RangePicker
                placeholder={["发布时间"]}
                onChange={(date) => this.setRangeFunc(date, "published_at")}
                value={this.state.published_at}
            />
        </div>
    );

    selectOptionsRender = () => {
        const announcementStatus = [
            { code: 0, label: "草稿" },
            { code: 1, label: "已发布" },
        ];

        return announcementStatus?.map((ele) => (
            <Option key={ele.code} label={ele.label} value={ele.code}>
                {ele.label}
            </Option>
        ));
    };

    render() {
        return (
            <div className="project-filters">
                <div className="display-flex">
                    <Input
                        allowClear
                        onChange={(e) => this.setStateFunc("title_author", e.target.value)}
                        placeholder="检索公告名称\作者"
                        value={this.state.title_author}
                        size="large"
                        className="filter-input mg-r-20"
                    />
                    {this.dateRangesRender()}
                    <Select
                        placeholder="全部状态"
                        className="filter-input"
                        onChange={this.handleStatusChange}
                        value={this.state.status}
                        allowClear
                        showArrow
                        showSearch optionFilterProp="children"
                        size="large"
                    >
                        {this.selectOptionsRender()}
                    </Select>
                </div>
                <div className="reset-filter" onClick={this.clear}>
                    <span>重置</span>
                </div>
            </div>
        )
    }
}

export default AnnouncementFilter;
