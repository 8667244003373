import { serialize } from './serialize';
import { isFunction, isUndefined, UNDEFINED } from './helper';
import { broadcastState } from './broadcast-state';
import { getTimestamp } from './timestamp';
import { SWRGlobalState } from './global-state';

export const internalMutate = async (...args) => {
  const [cache, _key, _data, _opts] = args
  const options = typeof _opts === 'boolean' ? { revalidate: _opts } : _opts ||
    {}
  let populateCache = isUndefined(options.populateCache)
    ? true
    : options.populateCache
  const revalidate = options.revalidate !== false
  const [key, , keyInfo] = serialize(_key)

  if (!key) {
    return
  }
  if (args.length < 3) {
    // 重新验证自己时候,直接调用mutate()时。 或者重新验证别的key，mutate(key)
    return broadcastState(
      cache,
      key,
      cache.get(key),
      UNDEFINED,
      UNDEFINED,
      revalidate,
      true
    )
  } else {
    // 当更新自己直接调用mutate(newData) 或者设置别的key时
    const [, , MUTATION] = SWRGlobalState.get(cache)

    let data = _data
    let error

    const beforeMutationTs = getTimestamp()
    MUTATION[key] = [beforeMutationTs, 0]
    if (isFunction(data)) {
      try {
        data = data(cache.get(key))
      } catch (err) {
        error = err
      }
    }
    if (data && isFunction((data).then)) {
      data = await data.catch(err => {
        error = err
      })
      if (beforeMutationTs !== MUTATION[key][0]) {
        if (error) throw error
        return data
      } else if (error) {
        data = undefined
      }
    }
    cache.set(key, data)
    cache.set(keyInfo, Object.assign({}, cache.get(keyInfo), { error }))
    MUTATION[key][1] = getTimestamp()
    const res = await broadcastState(
      cache,
      key,
      data,
      error,
      UNDEFINED,
      revalidate,
      !!populateCache
    )
    if (error) throw error
    return populateCache ? res : data
  }

}
