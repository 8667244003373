import React from 'react';
import { clsx } from '@gUtils';
import './index.less';

/**
 * 文字排版
 * @param {object} props 参数
 * @param {inherit|left|center|right} [props.align=inherit] props.align 参数
 * @param {boolean} props.noWrap 文字禁止换行
 * @param {boolean} props.ellipsis 溢出...
 * @param {h1|h2|h3|body1|body2|caption} [props.variant=body1] props.variant 文字大小
 * @param {string} props.className className
 * @param {default} [props.weight=default] props.weight 字体文字粗细
 * @param {primary｜secondary｜primaryText｜secondaryText｜secondaryText2｜disabled} [props.color=primaryText] 字体颜色
 * @returns {React.DetailedReactHTMLElement<{className: *}, HTMLElement>}
 */
const Index = function(props) {
    const {
        align = 'inherit',
        noWrap,
        ellipsis,
        variant = 'body1',
        component = 'p',
        className,
        weight = 'default',
        color = 'primaryText',
        ...output
    } = props;
    const classColorName = clsx({
        'amps-typography-primary': color === 'primary',
        'amps-typography-secondary': color === 'secondary',
        'amps-typography-primary-text': color === 'primaryText',
        'amps-typography-secondary-text': color === 'secondaryText',
        'amps-typography-secondary-text2': color === 'secondaryText2',
        'amps-typography-disabled-text': color === 'disabled',
        'amps-typography-black': color === 'black'
    });
    return React.createElement(component, {
        className: clsx(
            'amps-typography',
            `amps-typography-weight-${weight}`,
            `amps-typography-${align}`,
            `amps-typography-${variant}`,
            classColorName,
            noWrap && 'amps-typography-noWrap',
            ellipsis && 'amps-typography-ellipsis',
            className
        ),
        ...output
    });
};
export default Index;
