import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { BlankPage, HeaderBar } from '@components'

const checkAuth = (list, permission) => {
  return (list || []).some((item) => item === permission);
};

const LoginAuth = ({
  component: Component,
  path,
  loggedIn,
  exact,
  location,
}) => {
  if (loggedIn) {
    let  prevLocation  = location?.prevLocation
    return <Redirect to={prevLocation || "/"} />;
  } else {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => <Component {...props} />}
      />
    );
  }
};
const Protected = (props) => {
  const { loggedIn, location } = props;
  return loggedIn ? (
    <div className="main-wrapper">
      <div className="header-wrapper">
        <HeaderBar />
      </div>
      <div id="router-content">
        <Permission {...props} />
      </div>
    </div>
  ) : (
    <Redirect to={{ pathname: "/login", prevLocation: location.pathname }} />
  );
};

const Permission = (props) => {
  const {
    component: Component,
    path,
    exact,
    permission,
    userPermissions,
  } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  const renderContent =
    !permission || hasAuth
      ? (props) => <Component {...props} />
      : () => <BlankPage text="您还没有该功能的权限!" />;
  return <Route path={path} exact={exact} render={renderContent} />;
};

const mapStateToProps = (state) => ({
  loggedIn: Boolean(state.session.token),
  userPermissions: state.session.permissions,
});

export const AuthRoute = withRouter(connect(mapStateToProps)(LoginAuth));
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));
export const PermissionRoute = withRouter(connect(mapStateToProps)(Permission));
