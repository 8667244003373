import { Input } from "antd";

const LabeledInput = (props) => {
  const {label, required} = props
    return (
      <div className={"labeled-input"}>
        <span className={required ? "required" : ""}>{label}</span>
        <Input className="input-sz-m" {...props} />
      </div>
    );
}

export {
    LabeledInput
}