import React from 'react';
// import TopicHistory from './render/topic_history';
import { TopicScene } from "../";
import TopicAnnotation from '../../../components/topic_annotation';
import './index.less'

/**
 * TopicInfo
 * @param {*} props props
 * @returns {*}
 */
const TopicInfo = (props) => {
    const { history_list, topicId } = props;
    return (
        <div className='amps-topic-info'>
            {/* 关联场景 */}
            <TopicScene topicId={topicId} />
            {/* 批注信息 */}
            <TopicAnnotation
                type={2}
                id={topicId}
            />
            {/* 历史信息 */}
            {/* <TopicHistory history_list={history_list} /> */}
            {/* <AddAnnotation /> */}
        </div>
    )
}


export default TopicInfo
