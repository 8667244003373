import React, { useState } from 'react';
import './index.less';

/**
 * 关联信息渲染组件
 * @param {*} list 数据源
 * @param {*} type 项目/任务包/选题
 * @return {*}
 */
const renderTopic = (list, type) => {
    const title = (
        type === 'topic' ? '选题' :
        type === 'project' ? '项目' :
        type === 'taskPackage' ? '任务包' : "占位符"
    )
    return (
        list?.length ? (
            <div className='amps-relate-info-item'>
                <div className='amp-relate-info-item-title'>{title}</div>
                {
                    list?.map((item, idx) => {
                        return (
                            <div
                                key={idx}
                                className='amp-relate-info-item-list'
                                onClick={() => { clickHandle(item?.id, type) }}
                            >
                                <span className='amp-relate-info-item-id'>{item?.number}</span>
                                <span className='amp-relate-info-item-name'>{item?.name}</span>
                            </div>
                        )
                    })
                }
            </div>
        ) : null
    )
}

/**
 * 点击事件
 * @param {*} id 选题/项目/任务包的ID
 * @param {*} type 点击的类型：选题/项目/任务包
 */
const clickHandle = (id, type) => {
    let url = null;
    if (type === 'topic') {
        url = `/select-topic-management/select-topic-pool/topic_details?topicId=${id}`
    } else if (type === 'project') {

    } else if (type === 'taskPackage') {

    }
    url && window.open(url);
}

/**
 * 场景详情关联信息
 * @param {*} props props
 * @returns {*}
 */
const RelateInfo = (props) => {
    const { topicList, projectList, taskPackageList } = props?.relatedInfo;
    const [ topicRender, ] = useState(renderTopic(topicList, 'topic'));
    const [ projectRender, ] = useState(renderTopic(projectList, 'project'));
    const [ taskPackageRender, ] = useState(renderTopic(taskPackageList, 'taskPackage'));
    return (
        <>
            {topicRender}
            {projectRender}
            {taskPackageRender}
        </>
    )
}

export default RelateInfo;
