import React, { Component } from "react";
import { Input, Select } from "antd";
import { initState, optionStatus } from "@vars/views/user_vars";
import { debounce } from '@gUtils';

const { Option } = Select;

class UserFilter extends Component {
  //初始化 筛选项
  state = initState;

  // 渲染完成后，初始化获取数据，实例化
  componentDidMount() {
    this.getOptions();
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  // 数据更新后立即调用，但首次渲染不会执行
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.searchRequest();
    }
  }

  componentWillUnmount() {
    // this.props.fetchAssets({});
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  componentCleanup = () => {
    // 清除redux表格记录的筛选数据
    this.props.requestUsers({})
  }

  // 赋值
  handleStateFun = (key, val) => this.setState({ [key]: val });

  handleNameEmail = (val) => {
    this.handleStateFun("name_email", val);
  };

  handleRoleChange = (val) => {
    this.handleStateFun("role_ids", val);

  };

  handleOrgChange = (val) => {
    this.handleStateFun("group_ids", val);
  };

  handleStatusChange = (val) => {
    this.handleStateFun("status", val);
  };

  selectOptionsRender = (val) => {
    let optionArr =
      val === "role"
        ? this.props.roleOption
        : val === "group"
          ? this.props.groupOption
          : val === "status"
            ? optionStatus
            : [];
    if (optionArr && optionArr.length !== 0) {
      return optionArr?.map((ele) => (
        <Option label={ele.name} value={ele.id} key={ele.id}>
          {ele.name}
        </Option>
      ));
    }
  };

  clear = () => {
    this.setState({
        ...initState
    });
  };

  searchRequest = () => {
    let { name_email, role_ids, group_ids } = this.state;
    let params = {
      page: 1,
      page_size: 12,
      filter: {
        name_email,
        role_ids,
        group_ids,
        status: this.state.status && this.state.status.length === 1 ? this.state.status[0]: null
      },
    };
    this.debounceSearchUsers(params);
  };

  debounceSearchUsers = debounce(this.props.searchUsers, 300);

  getOptions = () => {
    this.props.fetchUserConfig();
  };

  selectedTagsRenderRole = (props) => {
    let { label, value } = props;
    const selectedStatus = this.state.role_ids;
    const idx = selectedStatus.indexOf(value);
    const style = idx === 0 ? { marginLeft: "7px" } : {};
    label = selectedStatus.length > 1 && idx > 0 ? `/${label}` : label;
    return <span style={style}>{label}</span>;
  };

  selectedTagsRenderGroup = (props) => {
    let { label, value } = props;
    const selectedStatus = this.state.group_ids;
    const idx = selectedStatus.indexOf(value);
    const style = idx === 0 ? { marginLeft: "7px" } : {};
    label = selectedStatus.length > 1 && idx > 0 ? `/${label}` : label;
    return <span style={style}>{label}</span>;
  };

  selectedTagsRenderStatus = (props) => {
    let { label, value } = props;
    const selectedStatus = this.state.status;
    const idx = selectedStatus.indexOf(value);
    const style = idx === 0 ? { marginLeft: "7px" } : {};
    label = selectedStatus.length > 1 && idx > 0 ? `/${label}` : label;
    return <span style={style}>{label}</span>;
  };




  render() {
    return (
      <div className="user-filter">
        <Input
          className="select-sz-m mg-r-20"
          placeholder="检索账户名\邮箱\用户名"
          allowClear
          size="large"
          onChange={(val) => this.handleNameEmail(val.target.value)}
          value={this.state.name_email}
        />
        <Select
          className="select-sz-m mg-r-20"
          placeholder="全部角色"
          mode="multiple"
          allowClear
          size="large"
          onChange={this.handleRoleChange}
          tagRender={this.selectedTagsRenderRole}
          value={this.state.role_ids}
          showArrow
          maxTagCount={1}
          showSearch optionFilterProp="children"
        >
          {this.selectOptionsRender("role")}
        </Select>
        <Select
          className="select-sz-m mg-r-20"
          mode="multiple"
          placeholder="全部组织"
          allowClear
          size="large"
          onChange={this.handleOrgChange}
          tagRender={this.selectedTagsRenderGroup}
          value={this.state.group_ids}
          showArrow
          maxTagCount={3}
          showSearch optionFilterProp="children"
        >
          {this.selectOptionsRender("group")}
        </Select>
        <Select
          className="select-sz-m"
          placeholder="全部状态"
          mode="multiple"
          allowClear
          size="large"
          onChange={this.handleStatusChange}
          tagRender={this.selectedTagsRenderStatus}
          value={this.state.status}
          showArrow
          showSearch optionFilterProp="children"
        >
          {this.selectOptionsRender("status")}
        </Select>
        <div className="reset-filter" onClick={this.clear}>
          重置
        </div>
      </div>
    );
  }
}

export default UserFilter;
