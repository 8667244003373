import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Skeleton } from 'antd';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import DetailsTitle from './details_title';
import DetailsContent from './details_content';
import DetailsFooter from './details_footer';
import './index.less';
import { $error, $success } from '@components';

class TopicDetails extends Component {
    state = {
        id: 0,
        details: {},
    }
    componentDidMount = async () => {
        const { history } = this?.props;
        const { id } = this.state;
        const params = await new URLSearchParams(history?.location?.search);
        const requestFlag = id !== Number(params.get('topicId'));
        requestFlag && SelectedTopicApi().getTopicInfo({id: Number(params.get('topicId'))}).then((res) => {
            this.setState({
                ...this.state,
                details: res,
                id: Number(params.get('topicId')),
            })
        }).catch((err) => {
            throw new Error(err);
        });
    }

    /**
     * 关闭弹框
     */
    cancelHandle = () => {
        this.setState(() => {
            return {
                id: 0,
                details: {},
            }
        }, () => {
            const { history } = this?.props;
            history?.push("/select-topic-management");
        })
    }
    render() {
        const { details, id, annotationList, loadingAnnotation } = this.state;
        const flag = JSON.stringify(details) === "{}";
        return (
            <Modal
                visible
                width="auto"
                title={<DetailsTitle name={details?.name} number={details?.number} />}
                className="amps-topic-details-modal"
                footer={<DetailsFooter id={id} />} // 后端容器调度器接入后，按钮由后端控制，前端只需渲染 + 特殊按钮的处理(如编辑)
                onCancel={() => {this.cancelHandle()}}
                maskClosable={false}
            >
                {
                    flag ? <Skeleton active /> : (
                        <DetailsContent details={details} />
                    )
                }
            </Modal>
        );
    }
}

export default withRouter(TopicDetails);
