import React, { Component } from 'react';
import ImageSwiper from '@/components/image_swiper';
import { Empty } from 'antd';
import './index.less'

class CycleImage extends Component {
    state = {
        imageList: this?.props?.image_list,
    }
    render() {
        const { imageList } = this.state;
        return (
            <div className='amps-topic-cycle-image'>
                {
                    imageList?.length ? (
                        <ImageSwiper imageList={imageList} />
                    ) : (
                        <Empty image={`${process.env.PUBLIC_URL}/images/other/image2.png`} description="暂无数据" />
                    )
                }

            </div>
        )
    }
}

export default CycleImage
