import { announcementAPI } from "@api";
import { REQUEST_ANNOUNCEMENTS, RECEIVE_ANNOUNCEMENTS } from "../action_consts";

export const requestAnnouncements = (params) => ({
  type: REQUEST_ANNOUNCEMENTS,
  params,
});

export const receiveAnnouncements = (params, announcements) => ({
  type: RECEIVE_ANNOUNCEMENTS,
  params,
  announcements,
});

export const fetchAnnouncements = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) => {
  dispatch(requestAnnouncements(params));
  announcementAPI.fetchAnnouncements(params).then((res) => {
    if (res.code === 1000) {
      success();
      dispatch(receiveAnnouncements(params, res.data));
    } else {
      error();
    }
  });
};
