import React from 'react';
import { TopicProgress, TopicInfo, TopicDescription } from "../components";
import './index.less';

/**
 * DetailsContent
 * @param {*} props props
 * @returns {*}
 */
const DetailsContent = (props) => {
    const {
        imagesList,
        detail,
        createdTime,
        planUserList,
        outlineList,
        sceneListList,
        addressListList,
        historyListList,
        id,
    } = props?.details;
    return (
        <div className='amps-details-content'>
            {/* <TopicProgress /> */}
            <div className='amp-details-content-main'>
                <TopicDescription
                    imagesList={imagesList} // 参考图
                    detail={detail} // 描述
                    created_time={createdTime} // 创建时间
                    plan_user_list={planUserList} // 策划人员
                    outline_list={outlineList} // 大纲
                    scene_list={sceneListList} // 主题
                    address_list={addressListList} // 地址
                />
                <TopicInfo
                    history_list={historyListList}
                    topicId={id}
                />
            </div>
        </div>
    )
}

export default DetailsContent;
