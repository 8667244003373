import { projectsAPI } from "@api";

import {
  REQUEST_PROJECTS,
  RECEIVE_PROJECTS,
  RECEIVE_PROJECTS_ERROR,
} from "@actions/action_consts";

// action creators
export const requestProjects = (params) => ({
  type: REQUEST_PROJECTS,
  params,
});

export const receiveProjects = (params, projects) => ({
  type: RECEIVE_PROJECTS,
  params,
  projects,
});

export const receiveProjectsErr = (error) => ({
  type: RECEIVE_PROJECTS_ERROR,
  error,
});

// back end requests
export const fetchProjects = (params, success = () => {}, error = () =>{}) => (dispatch) => {
  dispatch(requestProjects(params));
  projectsAPI.fetchProjects(params).then((res) => {
    if (res.code === 1000) {
      success();
      return dispatch(receiveProjects(params, res.data));
    } else {
      error();
      return dispatch(receiveProjectsErr(res.msg));
    }
  });
};
