import React, { memo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { QueryFilter, AuthButton } from '@components';
import { useSwr } from '@hooks';
import TopicModal from './topic_modal';
import DispatchApi from '@/api/grpcApi/dispatch/dispatch_api';
// import { useHistory } from 'react-router-dom';

interface ISearchFromProps {
    onSearch: (values: any) => void;
}
/**
 * 场景管理列表-搜索表单
 * @param {ISearchFromProps} props props
 * @constructor
 */
const SearchFrom: React.FC<ISearchFromProps> = (props) => {
    const { onSearch } = props;
    const [visible, setVisible] = useState<boolean>(false);
    // const history = useHistory();

    /**
    * 获取场景状态
    */
    const { data: statusList = [] } = useSwr(
        'listSchedulerStep',
        () => DispatchApi().listSchedulerStep({ belongBusiness: 3 }).then((res: any) => res?.listList?.map((item: any) => ({
            value: item.code,
            label: item.name
        })))
    );

    return (
        <>
            <div className="amp-scene-wrap-search-form">
                <QueryFilter
                    size="large"
                    className="amp-scene-wrap-search-filter"
                    onFinish={onSearch}
                    isOffset
                    minCloSpan={6}
                // initialValues={{ type: 0, status: 0 }}
                >
                    <Form.Item
                        colon={false}
                        label="场景"
                        name="sceneIdName"
                    >
                        <Input
                            allowClear
                            maxLength={50}
                            placeholder="请输入名称或者ID"
                        />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label="状态"
                        name="status"
                    >
                        <Select
                            allowClear
                            placeholder="全部状态"
                            options={statusList}
                        />
                    </Form.Item>
                </QueryFilter>
                <AuthButton
                    permission="topic_scene_address_create"
                    type="primary"
                    className="amp-button-primary"
                    onClick={() => setVisible(true)}
                >
                    创建场景
                </AuthButton>
            </div>
            <TopicModal
                visible={visible}
                onClose={() => setVisible(false)}
            />
        </>
    );
};

export default memo(SearchFrom);
