import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { priorityTypes } from '@vars/views/tasks_vars'
import { processDrawerKeys, splitTask } from '@vars/views/task_flow_vars'
import { Divider, Form, Input, InputNumber, Select, Tooltip } from 'antd'
import { Iconfont, OnlySelect, Upload, UserSelect } from '@components'
import TaskScoreEdit from './taskScoreEdit'
import TaskOpinionEdit from './taskOpinionEdit'
import TaskSpecifyFallback from './taskSpecifyFallback'
import TaskToBeWarehoused from './taskToBeWarehoused'
import TaskStockInReset from './taskStockInReset'
import { getVoidStatusOptions } from '@gUtils/getVoidStatusOptions'
import { configsAPI } from '@api';

const { Option } = Select;

@inject("taskProcessStore", "taskStore", "projectStore", "userRelationsStore", "configsStore")
@observer
class TaskProcessContent extends Component {
    state = {
        isSplit: false,
        splitTasks: []
    }
    // uploadRef = React.createRef();
    componentDidMount() {
        this.props.taskStore.fetchTaskConfigs();
        this.props.projectStore.fetchProfile();
        const { id } = this.props.taskStore.currentTask;
        id && this.store.getProduceScore(id);
        id && this.store.getCollectScore(id);
    }
    get store() {
        return this.props.taskProcessStore;
    }
    get action() {
        return this.props.taskProcessStore.action;
    }
    get currentTask() {
        return this.props.taskStore.currentTask;
    }
    getFileList = () => {
        return this.props.uploadRef?.current?.state?.fileList;
    };

    /**
     * 制作数量 拆分任务
     * @param {*} value select value
     */
    handleSplit = (value) => {
        if (value !== 1) {
            this.setState({
                isSplit: true
            })
        } else {
            this.setState({ isSplit: false })
        }
    }

    /**
     * 新增任务数量 默认不拆分
     */
    handleNewSplit = () => {
        this.setState({
            isSplit: true
        })
    }

    renderByType(type, idx) {
        switch (type) {
            case "assignee":
                return this.renderAssignee(idx);
            case "old_score":
                return this.renderOldScore(idx);
            case "twoAssignees":
                return this.renderTwoAssignees(idx);
            case "prev_assignee":
                return this.renderPrevAssignee(idx);
            case "discard_type":
                return this.renderReason(idx);
            case "priority":
                return this.renderPriority(idx);
            case "score":
                return this.renderScore(idx);
            case "images":
                return this.renderUpload(idx);
            case "image":
                return this.renderUpload(idx, 1);
            case "category":
                return this.renderCategory(idx);
            case "grade":
                return this.state.isSplit ?
                    this.renderSplitTask(idx) :
                    this.action !== "duplicate_task" && this.renderGrade(idx);
            // 修改评分、新增任务和拆分任务共用，仅拆分任务和修改评分需要评分
            case "split_task":
                return this.renderIsSplit(idx)
            case "new_split_task":
                return this.renderNewSplitTask(idx); // 新增任务数量
            case "opinion":
                return this.renderOpinion(idx);
            case "collectionGrade":
                return this.renderCollectionGrade(idx);
            case "progress":
                return this.renderProgress(idx);
            case "collectionProgress":
                return this.renderCollectionProgress(idx);
            case "score_produce_assignee":
                return this.renderScoreProduceAssignee(idx);
            case "approve_production_result":
                return this.renderScoreProduceProducer(idx);
            case 'person_user':
                return this.renderPersonUser(idx);
            case 'make_submitter':
                return this.renderMakeSubmitter(idx)
            case 'old_amount':
                return this.renderOldAmount(idx)
            case 'new_amount':
                return this.renderCollectionGrade(idx, 'collect_images_count', '新序列图片数量', '请输入新数量')
            case 'upload_voucher':
                return this.renderUpload(idx, 3, '上传凭证')
            case "old_asset_type":
                return this.renderOldType(idx)
            case "old_asset_ID":
                return this.renderOldID(idx)
            case "new_asset_type":
                return this.renderNewType(idx)
            case "update_reason":
                return this.renderUpdateReason(idx)
            case 'specify_fallback':
                return this.renderSpecifyFallback(idx)
            case 'file_to_stock':
                return <TaskToBeWarehoused
                    key={idx}
                    currentTask={this.currentTask}
                    taskProcessStore={this.store}
                />
            // 入库重置
            case 'remake_asset_godown':
                return <TaskStockInReset
                    key={idx}
                    currentTask={this.currentTask}
                    formRef={this.props.formRef}
                />
            default:
                return "";
        }
    }

    // 原资产类型
    renderOldType = (idx) => {
        const { asset_type = {} } = this.currentTask;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">原类型</div>
                <Input
                    disabled
                    value={asset_type.label}
                    className="input-sz-m"
                />
            </div>
        );
    }

    // 原资产ID
    renderOldID = (idx) => {
        const { asset_uid = "" } = this.currentTask;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">原资产ID</div>
                <Input
                    disabled
                    value={asset_uid}
                    className="input-sz-m"
                />
            </div>
        );
    }

    // 目标类型(新类型)
    renderNewType = (idx) => {
        const message = "请选择目标类型";
        const { asset_type } = this.props.taskStore;
        const items = Object.values(asset_type);
        return (
            <div className="process-form-col-1" key={idx} style={{
                height: "100px",
            }}>
                <div className="mg-b-8 bold required">目标类型</div>
                <div style={{
                    display: "inline-block"
                }}>
                    {this.renderSelect({ message, name: "kind", items })}
                </div>
                <span className="amp-refresh-tip">注：变更类型资产ID将会重新生成</span>
            </div>
        );
    }

    // 变更资产类型的原因
    renderUpdateReason = () => {
        const placeholderText = "请填写变更原因";
        return (
            <div className="process-form-col-1">
                <div className="mg-b-8 bold required">变更原因</div>
                <Form.Item
                    name="reason"
                    rules={[{
                        required: true,
                        message: placeholderText,
                    }]}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder={placeholderText}
                        maxLength={500}
                    />
                </Form.Item>
            </div>
        );
    };

    // 旧评分
    renderOldScore = (idx) => {
        const { difficulty = 0 } = this.currentTask;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">原评分</div>
                <Input
                    disabled
                    value={difficulty}
                    className="input-sz-m"
                />
            </div>
        );
    }

    renderSelect = ({ name, message, items, select, mode, maxTagCount }) => {
        const { action } = this.store;
        const {
            currentTask: { prev_assignees, producer },
        } = this.props.taskStore;

        const selected =
            this.action === "assign_file_producer" && name === "assignee_ids"
                ? this.currentTask.producer.id
                : undefined;

        let topUserId = "";
        //判断是否是制作初审待审的驳回
        if (action === "disapprove_production_art") {
            // 获取上个制作人的用户id
            topUserId = prev_assignees ? prev_assignees[0]?.id : null;
        }
        //判断是否是制作终审待审的通过
        if (action === "approve_production_result") {
            // 获取上个制作人的用户id
            topUserId = producer?.id ? producer?.id : null;
        }
        // 判断是否为制作终审待审的驳回
        if (action === "disapprove_production_result") {
            // 获取上个制作人的用户id
            topUserId = prev_assignees ? prev_assignees[0]?.id : null;
        }

        return (
            <Form.Item
                name={name}
                className="input-sz-m"
                rules={[{ required: true, message }]}
                initialValue={topUserId || selected}
                shouldUpdate={(prevValues, nextValues) => prevValues[name] !== nextValues[name]}
            >
                {select || (
                    <OnlySelect size="large"
                        options={items || []}
                        placeholder={message}
                        showSearch
                        optionFilterProp="children"
                        showArrow
                        mode={mode ? mode : undefined}
                        maxTagCount={maxTagCount ? maxTagCount : undefined} />)}
            </Form.Item>
        );
    };

    renderUpload = (idx, maxCount, label = '参考图') => {
        const required = this.action.includes("submit");
        const uploadTitle = (
            <div className="align-center">
                <div
                    className={`bold mg-r-5 ${required ? "required" : ""}`}
                    style={{ marginBottom: "-5px" }}
                >
                    {label}
                </div>
                <Tooltip placement="bottomLeft" title="尺寸不限，格式为：JPG\PNG">
                    <Iconfont
                        type="icon-list_prompt"
                        className="icon-12 cursor-pointer"
                    />
                </Tooltip>
            </div>
        );
        return (
            <div className="process-form-col-1 mg-b-12" key={idx}>
                <Upload
                    maxCount={maxCount}
                    ref={this.props.uploadRef}
                    extra={uploadTitle}
                    showIndicator={false}
                    length={maxCount}
                    noRestriction={this.action === "submit_production_art"}
                />
            </div>
        );
    };

    renderScore = (idx) => {
        const message = "请填写美术评分";
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="align-center mg-b-8">
                    <div className="bold required mg-r-5">美术评分</div>
                    <Tooltip placement="bottomLeft" title="评分范围0～50">
                        <Iconfont
                            type="icon-list_prompt"
                            className="icon-12 cursor-pointer"
                        />
                    </Tooltip>
                </div>
                <Form.Item name="score" rules={[{ required: true, message }]}>
                    <InputNumber
                        size="large"
                        className="input-sz-m"
                        placeholder={message}
                        min={0}
                        max={50}
                        precision={2}
                    />
                </Form.Item>
            </div>
        );
    };

    renderPriority = (idx) => {
        const message = "请选择优先级";
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">优先级</div>
                {this.renderSelect({ name: "priority", message, items: priorityTypes })}
            </div>
        );
    };

    renderReason = (idx) => {
        const message = "请选择作废类型";
        const mode = "multiple"
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">作废类型</div>
                {this.renderSelect({
                    name: "discard_type",
                    message,
                    items: getVoidStatusOptions(this.currentTask?.task_status?.code),
                    maxTagCount: 1
                })}
            </div>
        );
    };

    renderGrade = (idx) => {
        const message = "请填写美术评分";
        const title = this.action === "update_produce_score" ? "修改后评分" : "评分";
        return (
            <div className="process-form-col-1 made-scroll mg-t-12" key={idx}>
                <div className="mg-b-8 required bold">{title}</div>
                <Form.Item name="score" rules={[{ required: true, message }]}>
                    <TaskScoreEdit
                        placeholder={message}
                        min={1}
                        max={30}
                    />
                </Form.Item>
            </div>
        );
    };

    renderSplitTask = (idx) => {
        const { splitTasks } = this.state;
        const { asset_type, } = this.props.taskStore;
        const items = Object.values(asset_type);
        const message = "请填写美术评分";
        return (
            <div className='mg-t-12 mg-b-8' key={idx}>
                <div className="mg-b-8 bold required">拆分任务</div>
                <div className='amp-split-tasks amp-split-task-w-100' key={idx}>
                    <Form.List
                        name="data"
                    >
                        {fields =>
                            fields.map((item, index) => {
                                return (
                                    <div className='amp-split-task' key={`task_${item.key}`}>
                                        <div className='process-form'>
                                            <div className='amp-split-task-item'>
                                                <Form.Item name={[item.name, 'title']} label={`制作任务${item.key + 1}`} rules={[{ required: true, message: "请输入任务名称" }]}>
                                                    <Input maxLength={20} className="input-sz-m" placeholder='请输入任务名称' ></Input>
                                                </Form.Item>
                                            </div>
                                            <div className='amp-split-task-item'>
                                                <Form.Item name={[item.name, 'kind']} label={"任务类型"} rules={[{ required: true, message: '请选择任务类型' }]}>
                                                    {this.renderSelect({ name: [item.name, 'kind'], message, items })}
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='amp-process-score'>
                                            <Form.Item name={[item.name, 'score']} label="美术评分&nbsp;" rules={[{ required: true, message }]}>
                                                <TaskScoreEdit
                                                    placeholder={message}
                                                    min={1}
                                                    max={30}
                                                />
                                            </Form.Item>
                                        </div>
                                        {
                                            fields?.length > index + 1 && <Divider dashed={true}></Divider>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Form.List>
                </div>
            </div>
        )
    }

    renderIsSplit = (idx) => {
        return (
            <div className='process-form-col-2' key={idx}>
                <Form.Item
                    className="input-sz-m"
                    name="split_task"
                    initialValue={1}
                    label="制作数量"
                >
                    <Select
                        onChange={this.handleSplit}
                    >
                        {
                            splitTask.map(item => {
                                return (
                                    <Option
                                        size="large"
                                        value={item.key}
                                        key={item.key}
                                    >
                                        {item.label}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </div>
        )
    }

    renderNewSplitTask = (idx) => {
        const message = "请选择新增任务数量"
        const newSplitTask = Array.from({ length: 20 }, (v, k) => ({ label: k + 1, code: k + 1, }));
        return (
            <div className='process-form-col-2' key={idx}>
                <Form.Item
                    className="input-sz-m"
                    name="new_split_task"
                    label="新增任务数量"
                    rules={[{ required: true, message }]}
                >
                    <OnlySelect
                        size="large"
                        options={newSplitTask || []}
                        placeholder={message}
                        showSearch
                        onChange={this.handleNewSplit}
                        optionFilterProp="children"
                        showArrow />
                </Form.Item>
            </div>
        )
    }

    renderOpinion = (idx) => {
        return (
            <div className="process-form-col-1 made-scroll" key={idx}>
                <div className="mg-b-8 bold">处理意见</div>
                <Form.Item name="comment" >
                    <TaskOpinionEdit
                        placeholder="请输入意见"
                    />
                </Form.Item>
            </div>
        )
    }

    renderCollectionGrade = (idx, name = 'score', label = '评分', msgError) => {
        const message = msgError ?? `请输入${name === "collect_images_count" ? 10000 : 1000}以内的正整数`;
        const getInt = (e) => {
            const res = parseInt(e);
            if (res !== 0 && !res) {
                return null;
            } else {
                return res;
            }
        };
        return (
            <div className="process-form-col-2 made-scroll" key={idx}>
                <div className="mg-b-8 required bold">{label}</div>
                <Form.Item name={name} getValueFromEvent={getInt} rules={[{ required: true, message }]}>
                    <InputNumber
                        className="input-sz-m"
                        placeholder={message}
                        min={0}
                        max={name === "collect_images_count" ? 10000 : 999}
                    />
                </Form.Item>
            </div>
        );
    };

    renderProgress = (idx) => {
        const { score_history, score_total } = this.store.produceScore;
        const res = [];
        const len = score_history?.length;
        for (let i = 0; i < len; i++) {
            res.push(<span key={`history${i}`} className="produce-score produce-score-color"></span>);
        }
        const temp = score_total - len;
        if (temp > 0) {
            for (let i = 0; i < temp; i++) {
                res.push(<span key={`total${i}`} className="produce-score"></span>);
            }
        }
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">评分进度</div>
                <div>{res}</div>
            </div>
        );
    };

    renderCollectionProgress = (idx) => {
        const { score_history, score_total } = this.store.collectScore;
        const res = [];
        const len = score_history?.length;
        for (let i = 0; i < len; i++) {
            res.push(<span className="produce-score produce-score-color"></span>);
        }
        const temp = score_total - len;
        if (temp > 0) {
            for (let i = 0; i < temp; i++) {
                res.push(<span className="produce-score"></span>);
            }
        }
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">评分进度</div>
                <div>{res}</div>
            </div>
        );
    };

    renderPrevAssignee = (idx) => {
        let { prev_assignees, producer } = this.currentTask;
        if (this.action === "duplicate_task") {
            // 新增任务的action
            prev_assignees = [{
                name: producer?.name
            }]
        }
        return prev_assignees?.length > 0 ? (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">待处理人</div>
                <Input
                    disabled
                    value={prev_assignees[0]?.name}
                    className="input-sz-m"
                />
            </div>
        ) : (
            ""
        );
    };

    renderScoreProduceAssignee = (idx) => {
        const { action } = this.store;
        const { name } = this.props.projectStore.loginUser;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">
                    {action === "score_produce" ? "评分人" : "处理人"}
                </div>
                <Input disabled value={name} className="input-sz-m" />
            </div>
        );
    };

    renderScoreProduceProducer = (idx) => {
        const { history } = this.props.taskStore
        const item = history.find((item, index) => {
            if (item.action === "initiate_production") {
                return item
            }
        })

        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold">制作人</div>
                <Input disabled value={item.history_data.operator} className="input-sz-m" />
            </div>
        );
    };

    renderTwoAssignees = (idx) => (
        <div className="process-form-col-2" key={idx}>
            {this.renderAssignee(idx + 1, "")}
        </div>
    );

    renderAssignee = (idx, extra = "") => {
        const message = "请选择待处理人";
        const { action } = this.store;
        const name = extra ? `assignee_${extra}` : "assignee_ids";
        let isDisableTemp = false;
        //判断是否是制作初审待审的驳回
        if (action === "disapprove_production_art") {
            isDisableTemp = true;
        }
        const select = (
            <UserSelect
                isDisable={isDisableTemp}
                params={{ action }}
                placeholder={message}
                sign="exist"
            />
        );
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">待处理人</div>
                {this.renderSelect({ name, message, select })}
            </div>
        );
    };

    renderCategory = (idx) => {
        const message = "请选择资产种类";
        const { asset_category } = this.props.taskStore;
        const items = Object.values(asset_category);
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">种类</div>
                {this.renderSelect({ message, name: "asset_category", items })}
            </div>
        );
    };
    // 待处理人
    renderPersonUser = (idx) => {
        const message = "请选择待处理人";
        const name = "assignee_ids";
        const select = (
            <UserSelect
                params={{ "type": 10 }}
                placeholder={message}
                sign="exist"
            />
        );
        return <div className="process-form-col-2" key={idx}>
            <div className="mg-b-8 bold required">待处理人</div>
            {this.renderSelect({ name, message, select })}
        </div>
    };

    renderMakeSubmitter = (idx) => {
        const { producer } = this.currentTask;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">制作提交人</div>
                <Input
                    disabled
                    value={producer?.name}
                    className="input-sz-m"
                />
            </div>
        );
    };

    renderSpecifyFallback = (idx) => {
        return (
            <TaskSpecifyFallback
                key={idx}
                store={this.store}
                taskStore={this.props.taskStore}
            />
        )
    };

    renderOldAmount = (idx) => {
        const { collect_images_count = '' } = this.currentTask;
        return (
            <div className="process-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">原序列图片数量</div>
                <Input
                    disabled
                    value={collect_images_count}
                    className="input-sz-m"
                />
            </div>
        );
    }

    render() {
        const seq = processDrawerKeys(this.action);
        return seq?.map((item, idx) =>
            this.renderByType(item, idx)
        )
    }
}
export default TaskProcessContent;
