import { RECEIVE_PROJECT, RECEIVE_PROJECTS, RECEIVE_PROJECTS_ERROR, RECEIVE_PROJECT_ERROR  } from "@actions/action_consts";


const projectsErrorsReducer = (state= null, action = {}) => {
    switch (action.type) {
        case RECEIVE_PROJECTS_ERROR:
            return action.error || null
        case RECEIVE_PROJECTS:
            return null
        case RECEIVE_PROJECT_ERROR:
            return action.error || null
        case RECEIVE_PROJECT:
            return null
        default:
            return state
    }
}

export default projectsErrorsReducer