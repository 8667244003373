/**
 * @name: Pie.jsx
 * @user: cfj
 * @date: 2022/4/21
 * @description: 类型占比饼状图
 */
import { Pie as APie } from '@ant-design/plots'
import { generate } from '@ant-design/colors'
import { withEmpty } from '@/HOC'

const Pie = function (props) {
    const { data } = props
    const config = {
        color: generate('#29C1B9'),
        data,
        angleField: 'ratio',
        colorField: 'asset_type',
        radius: 0.65,
        tooltip: false,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    return <APie {...config} />
}
export default withEmpty(Pie)
