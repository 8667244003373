import { Form, Switch } from "antd";
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
@inject("messagesStore")
@observer
class OptionCard extends Component {
  get store() {
    return this.props.messagesStore;
  }
  get wechatInfo() {
    return this.props.messagesStore.wechatInfo
  }
  handleCheck = (checked)=> {
    return checked ? 1 : 0
  }

  render() {
    const { name, label, title } = this.props;
    const {isBund} = this.store
    return (
      <div className={`wechat-option${isBund ? "" : "-disabled"}`}>
        <div className="wechat-option-card-top">
          {title}
          <div className="option-line"></div>
        </div>
        <div className="wechat-option-card-bottom">
          <span className="bold fz-18 wechat-option-card-label">{label}</span>
          <Form.Item
            name={name}
            getValueFromEvent={this.handleCheck}
            valuePropName="checked"
          >
            <Switch disabled={!isBund} />
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default OptionCard;
