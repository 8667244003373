import React from 'react';
import { useHistory } from 'react-router';
import { AuthButton } from "@/components";
import './index.less';

/**
 * DetailsFooter
 * @param {*} props props
 * @returns {*}
 */
const DetailsFooter = (props) => {
    const historyObj = useHistory();
    const editorHandle = () => {
        historyObj.push(`/select-topic-management/select-topic-pool/create_topic/edit/${props?.id}`)
    }
    return (
        <div className='amps-details-footer'>
            <AuthButton
                type="primary"
                onClick={() => {editorHandle()}}
                permission="topic_update"
            >编辑</AuthButton>
        </div>
    )
}

export default DetailsFooter;
