import { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Button } from 'antd';
import { useSwr } from '@hooks'
import { businessDataAPI } from '@api'
import { $error, $success } from '@components';

/**
 * ModifyScoreModal
 * @param {*} props
 * @returns {JSX.Element}
 */
const ModifyScoreModal = (props) => {
    const {
        visible,
        closeModal,
        submitForm,
        supplyScoreRecord
    } = props

    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        businessDataAPI.getSupplyScore(supplyScoreRecord).then((res) => {
            form.setFieldsValue({ score: res.data?.score || '' })
        })
    }, []);

    /**
     * 提交表单
     * @param {*} value 表单values
     * @returns {Object}
     */
    const handleSubmit = (value) => {
        businessDataAPI.upDataSupplyScore({ ...value, ...supplyScoreRecord }).then((res) => {
            if (res.code === 1000) {
                $success('操作成功')
            } else {
                $error('操作失败')
            }
        })
        submitForm()
    }

    /**
     * 按钮置灰
     * @param {*} changedValues changedValues
     * @param {*} allValues allValues
     */
    const onValuesChange = (changedValues, allValues) => {
        if (allValues.score && allValues.comment) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    /**
     * 关闭弹框
     */
    const handleClose = () => {
        closeModal()
    }

    return (
        <div>
            <Modal
                visible={visible}
                title='补分'
                footer={false}
                centered
                onCancel={handleClose}
            >
                <div className='amp-modify-score-modal'>
                    <p className='fw-300'>*添加补分后将会影响总分数</p>
                    <Form
                        onFinish={handleSubmit}
                        form={form}
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            label='分值'
                            name='score'
                            className='amp-form-input fw-400'
                            required={true}
                        >
                            <InputNumber
                                controls={false}
                                className='amp-input-number fw-400'
                                min={1}
                                max={1000}
                                placeholder='请输入正整数'
                            />
                        </Form.Item>
                        <Form.Item
                            label='备注'
                            name='comment'
                            required={true}
                        >
                            <Input.TextArea
                                placeholder='请输入补分原因'
                                maxLength={500}
                                autoSize={{ minRows: 5, maxRows: 6 }}
                            />
                        </Form.Item>
                        <Form.Item
                            className='amp-form-btn'
                        >
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='mg-r-12'
                                disabled={disabled}
                            >
                                确认提交
                            </Button>
                            <Button
                                onClick={handleClose}
                            >
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default ModifyScoreModal
