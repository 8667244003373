import React, { Component } from "react";
import { Form, Table, Button } from "antd";
import { MinusSquareOutlined } from "@ant-design/icons";
import TripTable from "./renders/trip_table";
import { TripDate, TripDestination } from "./renders/trip_form_renders";
import { FormDescription, FormAdd, FormSelect } from "./renders/common_renders";
import { tripFormColumns } from "@vars/views/shooting_plan_vars";

class TripForm extends Component {
  formRef = React.createRef();

  state = {
    trips: this.props.planDetails.trips,
    errorMsg: "",
  };

  get timeFrame() {
    const { trips } = this.state;
    if (!trips) return "";
    let startArr = trips?.map((item) => item.trip_time[0]).sort();
    let endArr = trips?.map((item) => item.trip_time[1]).sort();
    let startDate = startArr[0],
      endDate = endArr[endArr.length - 1];
    const notEmpty = startDate && endDate;
    return notEmpty ? `${startDate}-${endDate}` : "";
  }

  get formattedTrips() {
    const { trips } = this.state;
    return trips ? trips?.map((item) => this.formatTrip(item)) : "";
  }

  componentDidMount() {
    this.props.updatePlanDetails({ time_frame: this.timeFrame });
  }

  validateForm = () => {
    if (this.state.trips.length < 1) {
      this.formRef.current.validateFields();
      this.setState({ errorMsg: "请添加行程" });
      return Promise.reject();
    }
    return Promise.resolve();
  };

  onFinish = (val) => {
    const { trips } = this.state;
    let { trip_time } = val;
    trip_time = trip_time
      ? trip_time?.map((item) => item.format("YYYY/MM/DD"))
      : "";
    this.setState({ trips: [...trips, { ...val, trip_time }], errorMsg: "" });
    this.props.updatePlanDetails({ time_frame: this.timeFrame });
    this.resetForm();
  };

  resetForm = () => {
    this.formRef.current.resetFields();
  };

  formRender = () => (
    <Form
      layout="inline"
      onFinish={this.onFinish}
      validateMessages={{ required: "该项为必填项" }}
      ref={this.formRef}
      className="plan-table-form"
      validateTrigger="onBlur"
    >
      <TripDate />
      <FormSelect {...this.props} type={"trip_type"} />
      <TripDestination />
      <FormSelect {...this.props} type={"trip_transportation"} />
      <FormDescription type={"trip_description"} />
      <FormAdd />
    </Form>
  );

  formatTrip = (item) => {
    const { tripType, tripTransportation } = this.props;
    const { trip_type, trip_transportation } = item;
    let res = { ...item };
    res.trip_type =
      typeof trip_type !== "object" ? tripType[trip_type] : trip_type;
    res.trip_transportation =
      typeof trip_transportation !== "object"
        ? tripTransportation[trip_transportation]
        : trip_transportation;
    return res;
  };

  removeTripItem = (index) => {
    const { trips } = this.state;
    trips.splice(index, 1);
    this.setState({ trips });
    this.props.updatePlanDetails({ time_frame: this.timeFrame });
  };

  saveItem = (index, item) => {
    const { trips } = this.state;
    trips[index] = item;
    this.setState({ trips });
    this.props.updatePlanDetails({ time_frame: this.timeFrame });
  };

  render() {
    const tableProps = {
      ...this.props,
      tripData: this.formattedTrips,
      deleteItem: this.removeTripItem,
      saveItem: this.saveItem,
    };

    return (
      <div className="drawer-form-content">
        <h3 className="required">行程</h3>
        <div className="plan-table">
          <Table columns={tripFormColumns} />
          {this.formRender()}
          <TripTable {...tableProps} />
        </div>
        <div className="error-color">{this.state.errorMsg}</div>
      </div>
    );
  }
}

export default TripForm;
