import '@styles/components/image.less';
import { clsx } from '@gUtils';
import { Iconfont, LazyLoadingImage } from '@components';

const Index = function(props) {
    const {
        score,
        title,
        status,
        checked,
        onChecked,
        disabled,
        mask,
        style,
        ...other
    } = props;
    return (
        <div style={{width: props.width, height: props.height, ...style}} className={clsx('amps-image', status !== undefined && `amp-status-${status}`)}>
            <LazyLoadingImage {...other} preview={false} />
            <div className="amps-image-mask">
                {
                    mask?.map((mask, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="amps-image-mask-item" key={index}>
                            {
                                mask
                            }
                        </div>
                    ))
                }
            </div>
            {!disabled && onChecked && (
                <div
                    className="amps-image-checked"
                    onClick={() => {
                        onChecked(!checked);
                    }}
                >
                    {checked ? <Iconfont type="icon-a-24-yixuan" className="icon-23 mg-r-3" /> : (
                        <Iconfont
                            type="icon-a-24-weixuan"
                            className="icon-23 mg-r-3"
                        />
                    )}
                </div>
            )}
            {(score !== undefined || title) && (
                <div className="amps-image-footer">
                    {score !== undefined && <p className="amps-image-footer-score">{score}</p>}
                    {title && (
                        <p className="amps-image-footer-title">
                            {title}
                        </p>
                    )}
                </div>
            )}

            {
                disabled && <div className="amps-image-disabled" />
            }

        </div>
    );
};
export default Index;
