import * as React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Table, Pagination } from "antd";
import { detailColumns } from "@vars/views/stats_vars";
import { centerStyle } from "@vars/views/dashboard_vars";
import { TaskDrawer } from "@components";

import { toJS } from "mobx";
@withRouter
@inject("reviewStore", "filterStore")
@observer
class ReviewTable extends React.Component {
  get store() {
    return this.props.reviewStore;
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  componentDidMount() {
    this.handlePageChange(1)
  }
  handlePageChange = (page) => {
    this.store.updatePage(page);
  };
  getUrl = ({ project_id, task_id, plan_id, typeOperate = "task" }, base) => {
    let url;
    let baseUrl = base || `/stats/production/production-reviews`;
    switch (typeOperate) {
      case "project":
        url = `/projects/${project_id}`;
        break;
      case "plan":
        url = `${baseUrl}?shootingPlanId=${plan_id}`;
        break;
      case "task":
        url = `${baseUrl}?task_flow?taskId=${task_id}`;
        break;
      default:
        url = "";
        break;
    }
    return url;
  }
  renderAction = (record) => {
    const url = this.getUrl(record, "");
    const onClick = (e) => {
      this.props.filterStore.footerShow = false
      e.stopPropagation();
      let search = null
      const reviewType = record.review_type
      if (reviewType === 4) {
        search = 12
      } else if (reviewType === 5) {
        search = 13
      }
      this.props.history.push(url + `?reviewType=${search}`);
    };
    return (
      <>
        {
          <div className="primary-color table-row" onClick={onClick} style={centerStyle}>
            查看
          </div>
        }
      </>
    );
  }

  render() {
    const { table, page, total, loading } = this.store;
    const action = {
      title: "操作",
      key: "operation",
      render: this.renderAction,
    };
    const finalColumns = [...detailColumns["review"], action];
    return (
      <div>
        <Table
          style={{ minHeight: "20vh" }}
          dataSource={table}
          columns={finalColumns}
          pagination={false}
          loading={loading}
          className="reviewsTable"
        />
        <TaskDrawer/>
        <Pagination
          defaultPageSize={9}
          total={total}
          showQuickJumper
          onChange={this.handlePageChange}
          current={page}
          showTotal={(total) => `共 ${total} 条`}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
export default ReviewTable;
