// 连接React组件与 react store
// mapStateToProps 将store中的数据作为props绑定到组件上。
// mapDispatchToProps
import { connect } from "react-redux";

import AddEditUser from './add_edit_user';
import {receiveCurrentUser} from '@actions/0_session/session_actions'
import { updateUser, createUser, unlockUser} from '@actions/1_entities/user_actions';
import { fetchUserConfig} from '@actions/2_configs/configs_actions';

const mapStateToProps = (state) => ({
    groupOption: state.configs.groups,
    roleOption: state.configs.roles,
    session: state.session
})

const mapDispatchToProps = (dispatch) => ({
    updateUser: (params, cb) => dispatch(updateUser(params,cb)),
    createUser: (params, cb) => dispatch(createUser(params,cb)),
    fetchUserConfig: (cb) => dispatch(fetchUserConfig(cb)),
    unlockUser: ( params, cb) => dispatch(unlockUser(params, cb)),
    receiveCurrentUser:(params) => dispatch(receiveCurrentUser(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser)