/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, createElement, forwardRef, useEffect } from 'react';
import { Loading, Typography } from '@components';
import { getJSONP } from '@gUtils';
import env from 'env';

/**
 * ContainerMap
 * @param {*} component component
 * @returns {any}
 */
const ContainerMap = (component: any) => forwardRef((props:any, ref) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        // @ts-ignore
        window._AMapSecurityConfig = {
            securityJsCode: env.gaodeCipher
        };
        // @ts-ignore
        if (window.AMap) {
            setLoading(false);
        } else {
            getJSONP(`//webapi.amap.com/maps?v=2.0&key=${env.gaodeKey}&callback=`).then(() => {
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                setError(err);
            });
        }
    }, []);

    if (loading) {
        return (
            // @ts-ignore
            <div className="display-flex align-center"><Loading /></div>
        );
    }

    if (error) {
        return (
            // @ts-ignore
            <Typography>
                {error || ''}
                {' '}
            </Typography>
        );
    }

    return createElement(component, {
        ...props,
        ref
    });
});

export default ContainerMap;
