import { React, useEffect, useRef, useState } from 'react'
import { topicEventBus } from '@/event_bus'
import ScrollContainer from '@/components/scroll_container'
import SelectedTopicApi from "@/api/grpcApi/seleceed_topic/selected_topic_api"
import FilterForm from './filter_form'
import moment from "moment";
import ListItem from './components/list_item'
import { useOutlet } from '@components/router/routes'
import './index.less'

/**
 * 选题池页面
 */
const TopicPool = () => {
    const outlet = useOutlet()
    const ref = useRef(null)
    const [params, setParams] = useState();

    /**
     * 提交表单
     * @param {*} value value
     */
    const handleFilterForm = (value) => {
        if (value?.sort) {
            value.sort = value.sort.includes(1) ? 1 : 0
        }
        if (value.create_time) {
            value.start_time = moment(moment(value?.create_time[0]).format('YYYY-MM-DD 00:00:00')).unix()
            value.end_time = moment(moment(value?.create_time[1]).format('YYYY-MM-DD 23:59:59')).unix()
        }
        delete value.create_time
        delete value._test
        setParams(value)
    }


    useEffect(() => {

        /**
         * 刷新列表
         */
        const reFresh = () => {
            ref.current?.refresh()
        }

        const off = topicEventBus.on(reFresh);
        return off
    }, [topicEventBus]);

    return (
        <div className='amps-select-topic-page page-container'>
            {outlet}
            <ScrollContainer
                ref={ref}
                pageSize={20}
                col={5}
                className={'amp-select-topic-content'}
                params={params}
                renderHeader={(data) => <FilterForm count={data?.data?.[0].total} onChange={handleFilterForm} />}
                renderItem={(item, key) => <ListItem data={item} key={key} />}
                request={(page, pageSize, params) => SelectedTopicApi().getTopicList({
                    page: page,
                    page_size: pageSize,
                    ...params
                }).then(res => ({
                    list: res.listList,
                    total: res.count
                }))}
            />
        </div>
    )
}

export default TopicPool
