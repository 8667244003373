import { Input } from 'antd';
import { clsx } from '@gUtils';
import './index.less';

const Index = function(props) {
    const { className, ...other } = props;
    return (
        <Input
            size="large"
            className={clsx('amp-fixed-input', className)}
            {...other}
        />
    );
};
Index.TextArea = function(props) {
    return (
        <Input.TextArea
            autoSize={{
                minRows: 5,
                maxRows: 5
            }}
            {...props}
        />
    );
};
export default Index;
