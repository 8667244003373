import axios from "axios";

const dashboardAPI = {
  fetchStatistics() {
    return axios.get("/account/statistics");
  },
  fetchAssignments() {
    return axios.get("/account/assignments");
  },
  fetchTaskTodos(params) {
    return axios.post("/task/assignments", params);
  },
  fetchProjectTodos(params) {
    return axios.post("/project/assignments", params);
  },
  getTodosCount() {
    return axios.get("/account/assignment-count");
  },
};

export default dashboardAPI;
