import axios from "axios";
const projectSettingsAPI = {
  fetchUserRelations(params) {
    return axios.post("/user-relation/list", params);
  },
  createRelation(params) {
    return axios.post("/user-relation/create", params);
  },
  deleteRelation(id) {
    return axios.delete("/user-relation/delete", { data: { id } });
  },
  sortRelations(params) {
    return axios.post("/user-relation/sort", params);
  },
  fetchPlanConfigs(params) {
    return axios.post("/settings/shooting-plan-config/list", params);
  },
  createPlanConfig(params) {
    return axios.post("/settings/shooting-plan-config/create", params);
  },
  updatePlanConfig(params) {
    return axios.put("/settings/shooting-plan-config/update", params);
  },
  deletePlanConfig(id) {
    return axios.delete("/settings/shooting-plan-config/delete", {
      data: { id },
    });
  },
  sortPlanConfigs(params) {
    return axios.put("/settings/shooting-plan-config/sort", params);
  },
  fetchAssetCategories() {
    return axios.get("/settings/asset-categories");
  },
  createAssetCategory(params) {
    return axios.post("/settings/asset-category", params);
  },
  updateAssetCategory(params) {
    return axios.put("/settings/asset-category", params);
  },
  sortAssetCategories(params) {
    return axios.put("/settings/asset-category-sort", params);
  },
  deleteAssetCategory(id) {
    return axios.delete("/settings/asset-category", { data: { id } });
  },
  fetchAssetTypes() {
    return axios.get("/settings/asset-types");
  },
  createAssetType(params) {
    return axios.post("/settings/asset-type", params);
  },
  updateAssetType(params) {
    return axios.put("/settings/asset-type", params);
  },
  sortAssetTypes(params) {
    return axios.put("/settings/asset-type-sort", params);
  },
  deleteAssetType(id) {
    return axios.delete("/settings/asset-type", { data: { id } });
  },
};
export default projectSettingsAPI;
