import { observable, action, computed, flow, makeAutoObservable, reaction, toJS } from "mobx";
import { configsAPI, projectSettingsAPI, shootingTeamAPI } from "@api";
import { $success, $error } from "@components";
import { addRelationStore } from "@stores";
import { debounce } from "lodash";
import DispatchApi from '@/api/grpcApi/dispatch/dispatch_api';

class UserRelationsStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable activeTypeCode = 50;
    @observable shootingTeamType = 1
    @observable relationTypes = [];
    @observable total;
    @observable isMoved = false;
    @observable loading = false;
    @observable relatedUsers = [];

    // 拍摄小组相关
    @observable shootingTeamList = [];
    @observable shootingMember = []

    @computed get currentType() {
        return this.relationTypes.find((item) => item.type === this.activeTypeCode);
    }

    @computed get currentShootingTeamType() {
        return this.shootingTeamList.find((item) => item.id === this.shootingTeamType);
    }

    @action changeActive(code) {
        this.activeTypeCode = code;
        addRelationStore.clear();
    }

    @action changeShootingTeamTypeActive(code) {
        this.shootingTeamType = code;
        addRelationStore.clear();
    }
    @action receiveRelatedUsers(relatedUsers) {
        this.relatedUsers = relatedUsers;
        this.loading = false;
    }

    @action receiveRelatedMember(shootingMember) {
        this.shootingMember = shootingMember;
        this.loading = false;
    }
    @action endSort() {
        this.isMoved = true;
    }
    @action startSort() {
        this.isMoved = false;
    }

    fetchRelationTypes = flow(function* () {
        try {
            const res = yield DispatchApi().listSysUserSchedulerRole({})
            this.relationTypes = res.listList.map(item => {
                return {
                    ...item,
                    type: item.code,
                    title: item.name
                }
            })
            // const res = yield configsAPI.fetchUserRelationTypes();

            // if (res.code === 1000) {
            //     this.relationTypes = res.data;
            // }
        } catch (error) { }
    });

    fetchRelatedUsers = flow(function* (type) {
        this.loading = true;
        const filter = { type: type ? type : this.activeTypeCode };
        try {
            const res = yield projectSettingsAPI.fetchUserRelations({ filter });
            if (res.code === 1000) {
                this.receiveRelatedUsers(res.data.list);
                this.total = res.data.total;
            }
        } catch (error) { }
    });

    sortRequest = flow(function* (sort) {
        this.loading = true;
        const params = { sort, type: this.activeTypeCode };
        try {
            const res = yield projectSettingsAPI.sortRelations(params);
            if (res.code === 1000) {
                this.isMoved = false;
                $success(res.msg);
            } else {
                if (res.code !== 1002) $error(res.msg);
            }
            this.loading = false;
        } catch (error) { }
    });


    // 获取拍摄小组列表
    getUserGroupList = flow(function* (group_id) {
        this.loading = true;
        const filter = { group_id: group_id ? group_id : this.shootingTeamType };
        try {
            const res = yield shootingTeamAPI.shootingTeamList();
            if (res.code === 1000) {
                this.shootingTeamList = res.data.list;
                this.loading = false;
            } else {
            }
        } catch (error) { }
    });

    // 获取拍摄小组成员
    getShootingTeamMember = flow(function* (group_id) {
        this.loading = true;
        const filter = { group_id: group_id ? group_id : this.shootingTeamType };
        try {
            const res = yield shootingTeamAPI.shootingTeamMember({ filter });
            if (res.code === 1000) {
                this.receiveRelatedMember(res.data.list);
                this.total = res.data.total;
            }
        } catch (error) { }
    });

    // 拍摄小组排序
    shootingTeamSort = flow(function* (sort) {
        this.loading = true;
        const params = { sort, type: this.shootingTeamType };
        try {
            const res = yield shootingTeamAPI.shootingTeamSort(params);
            if (res.code === 1000) {
                this.isMoved = false;
                $success(res.msg);
            } else {
                if (res.code !== 1002) $error(res.msg);
            }
            this.loading = false;
        } catch (error) { }
    });
}

const relationStore = new UserRelationsStore()
const debouncedRequest = debounce(() => relationStore.fetchRelatedUsers(), 500);
const debouncedShootingTeamListRequest = debounce(() => relationStore.getShootingTeamMember(), 500);

reaction(
    () => relationStore.activeTypeCode,
    (val, prevVal) => {
        if (val !== prevVal) debouncedRequest();
    }
);
reaction(
    () => relationStore.shootingTeamType,
    (val, prevVal) => {
        if (val !== prevVal) debouncedShootingTeamListRequest();
    }
);

export default relationStore;
