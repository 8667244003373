// 注册监听事件
export const subscribeCallback = (
  key,
  callbacks,
  callback
) => {
  const keyedRevalidators = callbacks[key] || (callbacks[key] = [])
  keyedRevalidators.push(callback)

  return () => {
    const index = keyedRevalidators.indexOf(callback)

    if (index >= 0) {
      keyedRevalidators[index] = keyedRevalidators[keyedRevalidators.length - 1]
      keyedRevalidators.pop()
    }
  }
}
