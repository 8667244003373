import { observable, action, flow, makeAutoObservable, computed } from "mobx";
import { messagesAPI } from "@api";
import { $success, $error } from "@components";
import { debounce } from "lodash";

const emptyInfo = {
  user_id: null,
  task_notification: 0,
  daily_notification: 0,
  nickname: "",
  head_image: "",
};

class MessagesStore {
  constructor() {
    makeAutoObservable(this);
  }
  @observable codeVisible = false;
  @observable wechatInfo = emptyInfo;
  @observable unbindVisible = false;

  @computed get isBund() {
    return JSON.stringify(this.wechatInfo) !== JSON.stringify(emptyInfo);
  }
  @action showCode() {
    this.codeVisible = true;
  }
  @action hideCode() {
    this.codeVisible = false;
  }
  @action showUnbind() {
    this.unbindVisible = true;
  }
  @action hideUnbind() {
    this.unbindVisible = false;
  }
  @action updateWechatInfo(info) {
    this.wechatInfo = info;
  }
  @action clearWechat() {
    this.updateWechatInfo(emptyInfo);
  }

  fetchWechatDetails = flow(function* () {
    try {
      const res = yield messagesAPI.fetchWechatDetails();
      if (res.code === 1000) {
        this.updateWechatInfo(res.data);
      } else {
        this.clearWechat();
      }
    } catch (e) {}
  });

  refreshWechat = flow(function* () {
    try {
      const res = yield messagesAPI.refreshWechat();
      if (res.code === 1000) {
        this.updateWechatInfo(res.data);
      } else {
        this.clearWechat();
      }
    } catch (e) {}
  });

  updateWechatOptions = flow(function* (params) {
    try {
      const res = yield messagesAPI.updateWechatOptions(params);
      if (res.code === 1000) {
        this.updateWechatInfo(res.data);
        $success(res.msg);
      } else {
        $error(res.msg);
      }
    } catch (e) {}
  });
  unbindWechat = flow(function* () {
    try {
      const res = yield messagesAPI.unbindWechat();
      if (res.code === 1000) {
        this.clearWechat();
        $success(res.msg);
      } else {
        $error(res.msg);
      }
    } catch (e) {}
  });
}

const messagesStore = new MessagesStore();
export default messagesStore;
