import * as React from 'react'
import DetailFilter from './filter'
import DetailTable from './table'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'
import moment from 'moment'
import { collectionStatsAPI } from '@api'

@withRouter
@inject("statsStore")
@observer
class ArtDetail extends React.Component {
    state = {
        producePerson: [],
    }
    get store() {
        return this.props.statsStore;
    }
    get statType() {
        const { pathname } = this.props.location;
        return pathname.split("/")[2];
    }
    get paramFilter() {
        const { search } = this.props.location;
        const filter =
            search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
        return filter || {};
    }
    componentDidMount() {
        this.setupStatType();
        this.setupDate();
        this.fetchProducePerson();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setupDate();
            this.setupStatType();
        }
    }

    // 获取制作人员
    fetchProducePerson = () => {
        collectionStatsAPI.getCollectionUserGroupMember({
            group_type: 2,
            group_id: 0
        }).then((res) => {
            if (res.code === 1000) {
                this.setState({
                    producePerson: res.data.list,
                })
            }
        })
    }

    setupStatType = () => this.store.updateStatType(this.statType);
    setupDate = () => {
        const { date } = this.paramFilter;
        if (date) {
            const res = date?.map((item) => moment(item));
            this.store.updateDate(res);
        } else {
            this.setDefaultdate();
        }
    };
    setDefaultdate = () => {
        let filter, date;
        date = [moment().subtract(1, "months"), moment()];
        filter = { date };
        this.props.history.push({ search: `?filter=${JSON.stringify(filter)}` });
    };
    render() {
        const { producePerson } = this.state;
        return (
            <div className={'page-container'}>
                <DetailFilter setDefaultdate={this.setDefaultdate} producePerson={producePerson} />
                <DetailTable />
            </div>
        );
    }
}
export default ArtDetail;
