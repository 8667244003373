import { combineReducers } from "redux";
import {
  usersReducer,
  projectsReducer,
  tasksReducer,
  // configsReducer,
  rolesReducer,
  permissionsReducer,
  announcementsReducer,
  assetsReducer,
} from ".";

const entitiesReducer = combineReducers({
  users: usersReducer,
  projects: projectsReducer,
  tasks: tasksReducer,
  // configs: configsReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  announcements: announcementsReducer,
  assets: assetsReducer,
});

export default entitiesReducer;
