import { Col, Form, Row } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';
import { Input } from '@components';

/**
 * @name: TaskPackageRejectedForm.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 任务包驳回
 */

const TaskPackageRejectedForm = forwardRef(function(props, ref){
    const [form] = Form.useForm();
    const { initialValues } = props;
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }))
    return <Form form={form} initialValues={initialValues} layout={'vertical'}>
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item label={'下一步'} name={'statusLabel'} required={'true'}>
                    <Input disabled={true} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item label={'处理人'} name={'userName'} required={true}>
                    <Input disabled={true} />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
})
export default TaskPackageRejectedForm
