import {
    HttpClient
} from 'ecoplants-lib';
import * as CoreApiPbManageMessage from '@/api/protos_api/core_api_pb';
import * as CommonPbManageMessage from '@/api/protos_api/common/amp/common_pb';
import * as DispatchManageMessage from '@/api/protos_api/manager_api_pb';

import {
    CoreApiServicePromiseClient
} from '@/api/protos_api/core_api_grpc_web_pb';

import {
    TopicListRequest,
    TopicInfoCommonRequest,
    TopicInfoRequest,
    TopicSceneSchedulerHandlerRequest,
    TopicSceneInfoRequest,
    ListTopicSceneAddressRequest,
    ListUserSimpleInfoByIntegrationConditionRequest,
    ModifyTopicAnnotation,
    Address,
    ListTopicAnnotationRequest,
    IdRequest
} from '@/api/protos_api/core_api_pb';
import {
    CommonFileItem
} from '@/api/protos_api/common/amp/common_pb';

/**
 * 选题模块-api
 */
class SelectedTopicApi extends HttpClient<any, any> {
    /**
     * 构造函数
     */
    constructor() {
        super({
            GrpcClient: CoreApiServicePromiseClient,
            services: { ...CommonPbManageMessage, ...CoreApiPbManageMessage, ...DispatchManageMessage }
        });
    }

    /**
     * 获取选题池列表
     * @param {*} data 请求参数
     * @returns {Promise}
     */
    getTopicList(data: TopicListRequest.AsObject) {
        return this.fetchData(
            'getTopicList',
            {
                data
            },
            {
                rpcRequestName: 'TopicList'
            }
        );
    }

    /**
     * 配置信息 选题-大纲和场景
     * @returns {Promise}
     */
    getTopicSceneAndOutline() {
        return this.fetchData('getTopicSceneAndOutline', {
            data: {}
        }, {
            rpcRequestName: 'SceneAndOutline'
        });
    }

    /**
     * 创建选题
     * @param {Object} data data
     * @returns {Promise}
     */
    createTopic(data: TopicInfoCommonRequest.AsObject) {
        const _data = {
            ...data
        };
        _data.scenesList = data.scenesList.map((item) => this.beCommon('SceneInfoRequest', item));
        _data.imagesList = data.imagesList.map((item) => this.beCommon('CommonFileItem', item));
        _data.addressList = data.addressList.map((item) => this.beCommon('Address', item));
        return this.fetchData('createTopic', {
            data: _data
        }, {
            rpcRequestName: 'TopicInfoCommon'
        });
    }

    /**
     * 获取选题详情
     * @param {object} data data
     * @returns {Promise}
     */
    getTopicInfo(data: TopicInfoRequest.AsObject) {
        return this.fetchData('getTopicInfo', {
            data
        }, {
            rpcRequestName: 'TopicInfo'
        });
    }

    /**
     * 编辑选题
     * @param {Object} data data
     * @returns {Promise}
     */
    updateTopic(data: TopicInfoCommonRequest.AsObject) {
        const _data = {
            ...data
        };
        _data.scenesList = data.scenesList.map((item) => this.beCommon('SceneInfoRequest', item));
        _data.imagesList = data.imagesList.map((item) => this.beCommon('CommonFileItem', item));
        _data.addressList = data.addressList.map((item) => this.beCommon('Address', item));
        return this.fetchData('updateTopic', {
            data: _data
        }, {
            rpcRequestName: 'TopicInfoCommon'
        });
    }

    /**
     * 选题场景调度器，聚合创建、编辑、提交审核、快速审核等
     * @param {Object} data data
     * @returns {Promise}
     */
    topicSceneSchedulerHandler(data: TopicSceneSchedulerHandlerRequest.AsObject) {
        const _data: TopicSceneSchedulerHandlerRequest.AsObject = {};
        if (data.commonParam) {
            _data.commonParam = this.beCommon('TopicSceneSchedulerCommonParam', data.commonParam);
        }
        if (data.addSceneParam) {
            const addSceneParam = {
                ...data.addSceneParam,
                imagesList: data.addSceneParam?.imagesList?.map((item: CommonFileItem.AsObject) => this.beCommon('CommonFileItem', item)),
                addressList: data.addSceneParam?.addressList?.map((item: Address.AsObject) => this.beCommon('Address', item))
            };
            _data.addSceneParam = this.beCommon('TopicSceneSchedulerModifySceneParam', addSceneParam);
        }
        if (data.updateSceneParam) {
            const updateSceneParam = {
                ...data.updateSceneParam,
                imagesList: data.updateSceneParam?.imagesList?.map(
                    (item: CommonFileItem.AsObject) => this.beCommon('CommonFileItem', item)
                ),
                addressList: data.updateSceneParam?.addressList?.map((item: Address.AsObject) => this.beCommon('Address', item))
            };
            _data.updateSceneParam = this.beCommon('TopicSceneSchedulerModifySceneParam', updateSceneParam);
        }
        return this.fetchData('topicSceneSchedulerHandler', {
            data: _data
        }, {
            rpcRequestName: 'TopicSceneSchedulerHandler'
        });
    }

    /**
     * 获取场景具体详情
     * @param {object} data data
     * @returns {Promise}
     */
    getTopicSceneInfo(data: TopicSceneInfoRequest.AsObject) {
        return this.fetchData('getTopicSceneInfo', {
            data
        }, {
            rpcRequestName: 'TopicSceneInfo'
        });
    }

    /**
     * 根据选题ID获取场景集合
     * @param {object} data data
     * @returns {Promise}
     */
    listTopicSceneAddress(data: ListTopicSceneAddressRequest.AsObject) {
        return this.fetchData('listTopicSceneAddress', {
            data
        }, {
            rpcRequestName: 'ListTopicSceneAddress'
        });
    }

    /**
     * 用户集成查询接口
     * @param {object} data data
     * @returns {Promise}
     */
    listUserSimpleInfoByIntegrationCondition(data: ListUserSimpleInfoByIntegrationConditionRequest.AsObject) {
        return this.fetchData('listUserSimpleInfoByIntegrationCondition', {
            data
        }, {
            rpcRequestName: 'ListUserSimpleInfoByIntegrationCondition'
        });
    }

    /**
     * 创建批注
     * @param {object} data data
     * @returns {Promise}
     */
    createTopicAnnotation(data: ModifyTopicAnnotation.AsObject) {
        const _data: ModifyTopicAnnotation.AsObject = {
            ...data,
            imagesList: data?.imagesList?.map((item: CommonFileItem.AsObject) => this.beCommon('CommonFileItem', item))
        };
        return this.fetchData('createTopicAnnotation', {
            data: { detail: this.beCommon('ModifyTopicAnnotation', _data) }
        }, {
            rpcRequestName: 'TopicAnnotation'
        });
    }

    /**
     * 更新批注
     * @param {object} data data
     * @returns {Promise}
     */
    updateTopicAnnotation(data: ModifyTopicAnnotation.AsObject) {
        const _data: ModifyTopicAnnotation.AsObject = {
            ...data,
            imagesList: data?.imagesList?.map((item: CommonFileItem.AsObject) => this.beCommon('CommonFileItem', item))
        };
        return this.fetchData('updateTopicAnnotation', {
            data: { detail: this.beCommon('ModifyTopicAnnotation', _data) }
        }, {
            rpcRequestName: 'TopicAnnotation'
        });
    }

    /**
     * 批注列表
     * @param {object} data data
     * @returns {Promise}
     */
    listTopicAnnotation(data: ListTopicAnnotationRequest.AsObject) {
        return this.fetchData('listTopicAnnotation', {
            data
        }, {
            rpcRequestName: 'ListTopicAnnotation'
        });
    }

    /**
     * 删除批注
     * @param {object} data data
     * @returns {Promise}
     */
    deleteAnnotation(data: IdRequest.AsObject) {
        return this.fetchData('deleteTopicAnnotation', {
            data
        }, {
            rpcRequestName: 'Id'
        });
    }

    /**
     * 获取
     * @param {object} data data
     * @returns {Promise}
     */
    fetchProcessData(data: IdRequest.AsObject) {
        return this.fetchData('listCoreSchedulerStepGroup', {
            data
        }, {
            rpcRequestName: 'ListCoreSchedulerStepGroup'
        });
    }
}
/**
 * ProjectManageApi
 * @returns {function} 请求api单例
 */
export default function (): any {
    return SelectedTopicApi.singleton();
}
