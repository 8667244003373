 
import React, { Component } from "react";
import { Form } from 'antd';
import { 
  UserNameInput,
  EmailInput,
  NameInput,
  PasswordInput,
  RoleSelect,
  GroupSelect,
  StatusInput
} from './add_edit_user_input';
import AddEditUserFooter from './add_edit_user_footer';

export default class AddEditUserForm extends Component {
   editUserRef = React.createRef();
   
   render() {
      const props = {...this.props};
      return (
        <div>
          <Form
            layout="vertical"
            onFinish={props.handleSubmit}
            ref={this.editUserRef}
            validateTrigger="onBlur"
            preserve={false}
          >
            <div className="two-col-form">
              <UserNameInput {...props} />
              <EmailInput {...props} />
            </div>
            <div className="two-col-form">
              <NameInput {...props} />
              <PasswordInput {...props} />
            </div>
            <div className="two-col-form">
              <RoleSelect {...props} />
              <GroupSelect {...props} />
            </div>
            <StatusInput {...props} handleSolve={props.handleSolve} />
          </Form>
          <AddEditUserFooter {...props} form={this.editUserRef} />
        </div>
      );  
    };
}
