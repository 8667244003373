import { observable, action, flow, makeAutoObservable, computed } from "mobx";
import { dashboardAPI } from "@api";
import {projectStore} from '@stores'

class TodosStore {
  constructor() {
    makeAutoObservable(this);
  }
  //observables
  @observable currentTab = "task";
  @observable projectTodos = [];
  @observable taskTodos = [];
  @observable todosCount = 0;
  @observable IsLoading = false;
  // 反复改变，用来清空工作中心搜索表单
  @observable filterType = false;
  // 判断入库状态更新 true 会刷新header的入库列表接口
  @observable storagesType = true;
  //computed values
  @computed get projectId() {
    return projectStore.projectId
  }
  @computed get currentTodos() {
    if (this.currentTab === "task") {
      return this.taskTodos;
    } else {
      return this.projectTodos;
    }
  }
  @computed get taskTotal() {
    return this.taskTodos.length;
  }
  @computed get projectTotal() {
    return this.projectTodos.length;
  }
  //actions
  @action updateProjectId(id) {
    projectStore.updateProjectId(id)
  }
  @action updateTab(tab) {
    this.currentTab = tab;
  }
  @action updateIsLoading(bool) {
    this.IsLoading = bool;
  }
  @action receiveTodos(todos) {
    this.todos = todos;
  }
  @action finishTaskTodo(id) {
    this.taskTodos = this.taskTodos?.filter((item) => item?.task_id !== id);
  }

  @action finishProjectTodo(id, projectId) {
    this.projectTodos = this.projectTodos?.filter((item) => item?.plan_id !== id &&
      projectId
        ? item?.project_id !== projectId && item?.plan_id !== id
        : item?.plan_id !== id
    );
  }

  @action setUpdateStorages(type) {
    this.storagesType = type
  }

  @action againFilterType() {
    this.filterType = !this.filterType
  }

  //flow requests
  getTodosCount = flow(function* () {
    try {
      const res = yield dashboardAPI.getTodosCount();
      if (res.code === 1000) {
        this.todosCount = res.data.count;
      }
    } catch (error) {}
  });
  fetchProjectTodos = flow(function* (params = {}) {
    try {
      const res = yield dashboardAPI.fetchProjectTodos(params);
      if (res.code === 1000) {
        this.projectTodos = res.data;
      }
    } catch (error) {}
  });
  fetchTaskTodos = flow(function* (params = {}) {
      this.updateIsLoading(true);
    try {
      const res = yield dashboardAPI.fetchTaskTodos(params);
      if (res.code === 1000) {
          this.updateIsLoading(false);
        this.taskTodos = res.data;
      }
    } catch (error) {}
  });
}

export default new TodosStore();
