import React, { Component } from "react";
import { Table } from "antd";

const columnsConfig = [
  { title: "页面", dataIndex: "page", width:250 },
  { title: "功能", dataIndex: "name", width:350 },
  { title: "权限描述", dataIndex: "description" },
];

class Permissions extends Component {
    state = {permissions:[]}
    
    componentDidMount(){
        this.requestPermissions()
    }

    requestPermissions = async () => {
        let permissions = await this.props.fetchPermissionDetails();
        this.setState({permissions})
    }
    
    render() {
        const {permissions} = this.state
        return (
          <div className="permissions">
            <h3 className="mg-tb-24">权限信息</h3>
              <Table
                columns={columnsConfig}
                dataSource={permissions}
                pagination={false}
                rowKey={(record)=> record.code}
                className="mg-b-42"
              />
          </div>
        );
    }
}

export default Permissions