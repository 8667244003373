/**
 * @name: getVoidStatusOptions.js
 * @user: cfj
 * @date: 2022/4/15
 * @description: 获取作废的Options
 */

/**
 * 1: {code: 22, label: "采集待评分", list_type_id: 0}
 * 2: {code: 1, label: "拍摄中", list_type_id: 0}
 * 3: {code: 3, label: "原序列待审", list_type_id: 0}
 * 4: {code: 4, label: "解析待分配", list_type_id: 0}
 * 5: {code: 5, label: "待解析", list_type_id: 0}
 * 6: {code: 6, label: "解析中", list_type_id: 0}
 * 7: {code: 7, label: "解析待审", list_type_id: 0}
 * 8: {code: 21, label: "制作待评分", list_type_id: 0}
 * 9: {code: 8, label: "制作待选", list_type_id: 0}
 * 10: {code: 9, label: "待制作", list_type_id: 0}
 * 11: {code: 10, label: "制作中", list_type_id: 0}
 * 12: {code: 11, label: "制作初审待审", list_type_id: 0}
 * 13: {code: 12, label: "制作终审待审", list_type_id: 0}
 * 14: {code: 13, label: "工程文件待分配", list_type_id: 0}
 * 15: {code: 14, label: "工程文件待制作", list_type_id: 0}
 * 16: {code: 15, label: "工程文件制作中", list_type_id: 0}
 * 17: {code: 16, label: "工程文件待审", list_type_id: 0}
 * 18: {code: 23, label: "待入库", list_type_id: 0}
 * 19: {code: 24, label: "入库中", list_type_id: 0}
 * 20: {code: 18, label: "已入库", list_type_id: 0}
 * 21: {code: 19, label: "已作废", list_type_id: 0}
 * 21, 8, 11, 12, 13, 14, 15, 16, 23,24,18
 * @param status
 * @returns {[{code: number, disabled: boolean, label: string},{code: number, disabled: boolean, label: string},{code: number, disabled: boolean, label: string},{code: number, disabled: boolean, label: string},{code: number, only: boolean, label: string}]}
 */
export const getVoidStatusOptions = function (status) {
    // 1 => 拍摄中、采集待评分、原序列待审、
    // 5 => 拍摄中、采集待评分、原序列待审、
    // 2 => 拍摄中、采集待评分、原序列待审、
    // 3 => 制作待评分、制作待选、待制作、制作中、制作初审、制作终审、工程文件待分配、工程文件待制作、工程文件制作中、工程文件待审、待入库、入库中、已入库

    return [
        {
            label: '前期拍摄',
            code: 1,
            disabled: status !== undefined ? ![1, 22, 3].includes(status) : false
        },
        {
            label: '选材错误',
            code: 5,
            disabled: status !== undefined ? ![1, 22, 3,].includes(status) : false
        },
        {
            label: '创建错误',
            code: 2,
            disabled: status !== undefined ? ![1, 22, 3].includes(status) : false
        },
        {
            label: '后期制作',
            code: 3,
            disabled: status !== undefined ? ![21, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 18].includes(status) : undefined
        },
        {
            label: '无责任作废',
            code: 4,
            only: true
        }
    ].filter((i) => !i.disabled);
};
