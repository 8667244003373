import { connect } from 'react-redux'
import DelRole from './del_role_index'
import { deleteRole } from '@actions/1_entities/role_actions'


const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  deleteRole: (params, cb) => dispatch(deleteRole(params, cb)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DelRole)