import axios from 'axios';

const targetApi = {
    /**
     * @method 获取用户目标
     * @param {*} params
     * @param {string} id 用户ID
     */
    getGoalUser(params) {
        return axios.get(`/api/performance-goal/get?id=${params.id}`);
    },
    /**
     * @method 获取目标列表
     * @param {*} params
     * @param {string} year 目标年
     */
    getGoalList(params) {
        return axios.get(`/api/performance-goal/list?year=${params.year}`);
    },
    /**
     * @method 添加用户目标
     * @param {string} id 数据id
     * @param {string} user_id 用户id
     * @param {string} goal 目标值
     * @param {string} goal_date_year 目标年
     * @param {string} goal_date_month 目标月
     */
    addGoal(params) {
        return axios.post('/api/performance-goal/add', params);
    },
    /**
     * @method 删除用户目标
     * @param {string} id 数据id
     */
    deleteGoal(params) {
        return axios.post('/api/performance-goal/delete', params);
    },
    /**
     * @method 更新用户目标
     * @param {string} id 数据id
     * @param {string} user_id 用户id
     * @param {string} goal 目标值
     * @param {string} goal_date_year 目标年
     * @param {string} goal_date_month 目标月
     */
    updateGoal(params) {
        return axios.post('/api/performance-goal/update', { detail: params });
    }
};

export default targetApi;
