import { connect } from "react-redux";
import Dashboard from "./dashboard";
import { fetchStatistics } from "@actions/1_entities/dashboard_actions";
import { fetchNotifications, fetchProjectActivities } from "@actions/1_entities/account_actions";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    fetchStatistics: (success, error) => dispatch(fetchStatistics(success, error)),
    fetchNotifications: (params, success, error) => dispatch(fetchNotifications(params, success, error)),
    fetchProjectActivities: (params) => dispatch(fetchProjectActivities(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

