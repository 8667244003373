import { Image } from "antd";
import { BasicImage } from '@components';

export const dataType = {
  title: "工作统计",
  jobStatistics: [
    {
      info: [
        {
          name: "待处理事项",
          icon: "icon-list_todo",
          data: "task_todo",
          code: 1,
        },
        {
          name: "参与中的资产",
          icon: "icon-list_ongoing-asset",
          data: "task_in_process",
          code: 2,
        },
        {
          name: "参与中的项目",
          icon: "icon-list_ongoing-project",
          data: "project_in_process",
          code: 3,
        },
      ],
    },
    {
      info: [
        {
          name: "平均处理效率（天）",
          icon: "icon-list_efficiency",
          data: "average_time_tasks_stayed",
          code: 4,
        },
        {
          name: "累计参与资产",
          icon: "icon-list_total-asset",
          data: "task_participated",
          code: 5,
        },
        {
          name: "累计参与项目",
          icon: "icon-list_total-project",
          data: "project_participated",
          code: 6,
        },
      ],
    },
  ],
};

export const centerStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  float: "left",
};
const render = (text) => <div style={centerStyle}>{text}</div>;
const renderLabel = ({ label }) => <div style={centerStyle}>{label}</div>;
const renderInitiator = ({ name }) => <div style={centerStyle}>{name}</div>;
const waitTimeColumn = {
  title: "滞留时间",
  dataIndex: "waiting_time",
  render: (time) => {
    const className = time / 24 >= 30 ? "error-color" : "";
    const display =
      time <= 1
        ? "1小时内"
        : time < 24
        ? `${time}小时`
        : `${Math.floor(time / 24)}天`;
    return (
      <div style={centerStyle}>
        <span className={className}>{display}</span>
      </div>
    );
  },
};
export const taskDashColumns = [
  {
    title: "缩略图",
    dataIndex: "task_preview",
    render: (preview) => <BasicImage height={68} width={68} src={preview} />,
  },
  {
    title: "任务状态",
    dataIndex: "task_status",
    render: renderLabel,
  },
  {
    title: "发起人员",
    dataIndex: "initiator",
    render: renderInitiator,
  },
  {
    title: "所属项目",
    dataIndex: "project_name",
    render,
  },
  {
    title: "内部ID",
    dataIndex: "asset_uid",
    render,
  },
  {
    title: "资产名称",
    dataIndex: "asset_name",
    render,
  },
  {
    title: "优先级",
    dataIndex: "priority",
    render: (priority) => render(priorityDisplay[priority]),
  },
  waitTimeColumn,
];

const priorityDisplay = {
  1: "顶",
  2: "高",
  3: "低",
  99: "-",
};

export const projectDashColumns = [
  {
    title: "事项",
    dataIndex: "name",
  },
  {
    title: "所属项目",
    dataIndex: "project_name",
  },
  waitTimeColumn,
];
