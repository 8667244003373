import { DatePicker } from "antd";
import { useState } from "react";
const { RangePicker } = DatePicker;

const BaseRangePicker = (props) => {
  const { value } = props;
  let length = value ? value.length : 0;
  const [focused, setFocused] = useState(false);
  const active = focused || length > 0;
  const activeClass = active ? "filter-input-focused " : "filter-input ";
  const klass = activeClass + props.klass
  return (
    <RangePicker
      className={klass}
      {...props}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

export default BaseRangePicker;
