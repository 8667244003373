import { Component } from "react";
import { ApprovalButtons, HistoryButtons, FormButtons } from "./footer_buttons";
import { AccessControl } from "@/components";

const approvalPermissions = [
  "shooting_plan_disapprove",
  "shooting_plan_approve",
];

class PlanFooter extends Component {
 render() {
    const props = { ...this.props };
    const { drawerContent, planStatus, onEditPlan } = props;
    let footer, permission;
    if (planStatus === 2) {
      permission = approvalPermissions;
      footer = <ApprovalButtons {...props} />;
    } else {
      permission = "shooting_plan_update";
      if (drawerContent === "history") {
        footer = <HistoryButtons planStatus={planStatus} onEditPlan={onEditPlan} />;
      } else if (drawerContent === "form") {
        footer = <FormButtons {...props} planStatus={planStatus} />;
      }
    }
    return (
      <AccessControl permission={permission}>
        <div className="drawer-footer">{footer}</div>
      </AccessControl>
    );
  };
}

export default PlanFooter;
