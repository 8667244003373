import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Image } from 'antd';
import { Iconfont } from '@/components';
import env from 'env';

@inject("taskStore")
@observer

class AssociatedContent extends Component {

    /**
     * 获取taskStore
     */
    get store() {
        return this.props.taskStore;
    }
    /**
     * 选择的任务包
     * @param  selectItem
     */
    selectItem = (item) => {
        this.store.selectedItem = item
    }


    getBorderColor = (params, text) => {
        switch (params) {
            //  未审核
            case 1:
                return `amp-${text}-no-see amps-plan-task-img-no-select`;
            //   通过
            case 2:
                return `amp-${text}-pass amps-plan-task-img`;
            //   驳回
            case 3:
                return `amp-${text}-reject amps-plan-task-img-no-select`;
            //   作废
            case 4:
                return `amp-${text}-invalid amps-plan-task-img-no-select`;
            default:
                return ``;
        }
    };
    renderHover = (item) => {

        switch (item.status) {
            //   未审核
            case 2:
                return (
                    <Iconfont
                        type="icon-a-24-weixuan"
                        className="icon-23 mg-r-3"
                        onClick={() => this.selectItem(item)}
                    />
                );
            default:
                return null
        }
    };

    /**
     * 暂无数据
     * @returns {JSX.Element}
     */
    NoData = () => {
        return (
            <div className="associated-content-no-data">
                <img className='associated-content-no-data-img' src={`${process.env.PUBLIC_URL}/images/other/no_data.png`} alt='请选择任务包' />
                <span className='associated-content-no-data-span'>请选择任务包</span>
            </div>
        )
    }

    /**
     * 有数据
     * @returns {JSX.Element}
     */
    packageItems = (value) => {
        const { items } = value
        return (
            <div className='amp-associated-content-list'>
                {
                    items.map((item, index) => (
                        <div className={`amp-associated-img ${this.getBorderColor(item.status, 'border')}`} key={item.id}>
                            <div className="task-img-body">
                                <Image className='assets-img' fallback={env.placeImage} src={item.images[0]} preview={{ visible: false, mask: this.renderHover(item) }} />
                            </div>
                            {this.store.selectedItem === item ? (<div className='amp-associated-selected'>
                                <Iconfont type="icon-a-24-yixuan" className="icon-23 mg-r-3" />
                            </div>) : null}
                            <div className={`task-text`}>{item.score}</div>
                        </div >
                    ))
                }
            </div>
        )
    }

    render() {
        const { packageItem } = this.store
        return (
            <div className="associated-content">
                <span>选择任务</span>
                {(packageItem.length < 1 || packageItem.items === null) ? this.NoData() : this.packageItems(packageItem)}
            </div>
        )
    }
}

export default AssociatedContent
