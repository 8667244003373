import React, { useState, useEffect, useRef, useMemo, memo } from 'react'
import { Input } from 'antd'

const TableItem = (props) => {
    const { text, record, index, dataIndex, revise, editable = false } = props
    // 是否在编辑状态
    const [editing, setEditing] = useState(false)
    // 当前编辑的值
    const [value, setValue] = useState(text)
    // 是不是组目标，组目标不可编辑，文案需特殊处理
    const [groupTarget, setGroupTarget] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
        if (editing && inputRef.current) {
            setValue(text ? text : '')
            inputRef.current.focus()
        }
    }, [editing, text])

    useEffect(() => {
        setGroupTarget(record.user_id ? false : true)
    }, [record])
    /**
     * @method input输入对数值结果做过滤处理
     * @param {*} event input节点返回
     */
    const inputChange = (event) => {
        let val = event.target.value.replace(/\D/g, '')
        if (val) {
            val = parseInt(val) <= 99999999 ? parseInt(val) : 99999999
        }
        setValue(val)
    }
    /**
     * @method input输入失去焦点回调事件
     */
    const inputBlur = () => {
        revise(value, record, dataIndex, index)
        setEditing(false)
    }

    const showText = useMemo(() => {
        if (text) return text
        return (<span className="amp-target-table-place" >
                {groupTarget ? "自动计算":"请输入目标值"}
            </span>)
    }, [text, groupTarget])

    const mergeClassName = `amp-target-table-text ${editable && !groupTarget ? '' : 'amp-target-table-disabled'}`

    return (
        <>
            {/* 处于编辑状态，有编辑权限，不是组目标 才显示input */}
            {
                editing && editable && !groupTarget ?
                    <Input
                        className="amp-target-table-input"
                        ref={inputRef}
                        value={value}
                        onBlur={inputBlur}
                        onPressEnter={inputBlur}
                        onChange={inputChange}
                        placeholder="0-99999999"
                    /> :
                    <div
                        className={mergeClassName}
                        onClick={() => setEditing(true)}
                    >
                        {showText}
                    </div>
            }
        </>
    )
}

export default memo(TableItem)
