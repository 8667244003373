/**
 * @name: line.jsx
 * @user: cfj
 * @date: 2022/4/21
 * @description: 增长率
 */
import { Line as ALine } from '@ant-design/plots'
import { withEmpty } from '@/HOC'

const Line = function (props) {
    const { data, type='月' } = props
    const config = {
        data: data.map(i => ({
            date: i.date,
            speed: parseInt(i.speed * 100)
        })),
        color: '#29C1B9',
        padding: 'auto',
        xField: 'date',
        yField: 'speed',
        tooltip: false,
        appendPadding: [20, 0, 0 , 0],
        label: {
            formatter (data) {
                return data.speed + '%'
            }
        },
        point: {
            size: 5,
            style: {
                fill: 'white',
                stroke: '#29C1B9',
                lineWidth: 2
            }
        },
        yAxis: {
            title: {
                text: '(%)',
                autoRotate: false,
                position: 'end'
            },
        },
        xAxis: {
            title: {
                text: `(${type})`,
                autoRotate: false,
                position: 'end'
            },
        },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red'
                }
            }
        }

    }
    return <ALine {...config} />
}
export default withEmpty(Line)
