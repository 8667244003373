import {
    RECEIVE_CURRENT_USER,
    RECEIVE_USERS,
    RECEIVE_USERS_ERR,
    DELETE_USER
} from '@actions/action_consts';

const usersErrorsReducer = (state=null, action={}) => {
    Object.freeze(state)
    switch (action.type) {
        case RECEIVE_CURRENT_USER:
        return state
        case RECEIVE_USERS:
            return action.error || null
        case RECEIVE_USERS_ERR:
          return action.error || null
        case DELETE_USER:
          return action.error || null
        default:
            return state
    }
}
export default usersErrorsReducer