import { useCallback, useEffect, useRef, useState } from 'react';

export const useStateWithDeps = (
    state,
    unmountedRef
) => {
    const rerender = useState({})[1];
    const stateRef = useRef(state);

    const stateDependenciesRef = useRef({
        data: false,
        error: false,
        isValidating: false
    });

    const setState = useCallback(
        (payload) => {
            let shouldRerender = false;

            const currentState = stateRef.current;
            // eslint-disable-next-line guard-for-in
            for (const _ in payload) {
                const k = _;

                if (currentState[k] !== payload[k]) {
                    currentState[k] = payload[k];
                    if (stateDependenciesRef.current[k]) {
                        shouldRerender = true;
                    }
                }
            }

            if (shouldRerender && !unmountedRef.current) {
                rerender({});
            }
        },
        []
    );

    // Always update the state reference.
    useEffect(() => {
        stateRef.current = state;
    });

    return [stateRef, stateDependenciesRef.current, setState];
};
