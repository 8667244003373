/**
 * @name: bar.jsx
 * @user: cfj
 * @date: 2022/4/21
 * @description: 数据总览图表
 */
import { Bar as ABar } from '@ant-design/plots'
import { withEmpty } from '@/HOC'

const typeEnum = [
  {dataIndex: 'task_count',  color: '#D4B8FF'}, //制作总数
  {dataIndex: 'score_total', color: '#98D8FF'}, //难度总数
]

const Bar = function (props){
  const {data, labels} = props
  const dataSource = data.map(item => {
    return typeEnum.map((typeItem, index) => ({
      type: labels[index],
      label: item.user_name,
      value: item[typeItem.dataIndex]
    }))
  }).sort((a,b) => b.score_total - a.score_total)
  const config = {
    data: [].concat(...dataSource),
    isGroup: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    color: typeEnum.map(i => i.color),
    autoFit: true,
    appendPadding: [0, 0, 0, 20],
    legend: {
      layout: 'horizontal',
      position: 'top-left',
      offsetX: 20,
    },
    label: {
      position: 'right',
    },
  }
  return <ABar  {...config} />

}
export default withEmpty(Bar, {style: {height: 282}})
