import { action, computed, flow, makeAutoObservable, observable } from 'mobx';
import { configsAPI, projectsAPI, tasksNewAPI } from '@api';
import { $error } from '@components';
import autoSave from '@stores/autosave';
import { filterStore } from '@stores';

class TaskStore {
    constructor() {
        makeAutoObservable(this);
        autoSave(this, 'taskStore');
    }

    @observable currentTask = { location: 'CN' };
    @observable operateCurrentTask = {};
    @observable visible = false;
    @observable history = [];
    @observable loading = false;
    @observable photographers = [];
    @observable asset_type = {};
    @observable asset_category = {};
    @observable actionState = '';
    @observable refresh = false;
    @observable refreshed = false;
    @observable waterfallVisible = false;
    @observable waterfallSource = [];
    @observable assetCharacter = 0;
    @observable releatedAssets = [];
    @observable reloadImgSrc = [];
    @observable assetTitle = '';
    @observable assetsId = null;

    // 关联任务
    @observable showAssociatedModal = false;
    @observable isAssociated = false;
    @observable isPlanningTask = false;
    @observable taskPackageList = [];
    @observable packageItem = [];
    @observable selectedItem = undefined;
    @observable associatedTaskID = '';
    @observable associatedTaskImage = '';

    // 资产详情区域
    @observable ProjectDetail = [];
    @observable TaskPackageInfo = [];

    @observable isShowTaskInfoRegion = true;

    @computed get shouldRefresh() {
        return this.refresh && !this.refreshed;
    }
    @computed get taskStatus() {
        return this.currentTask?.task_status?.code;
    }
    @computed get taskID() {
        return this.currentTask.id;
    }
    @computed get actions() {
        return this.currentTask.actions;
    }
    @computed get previewImage() {
        return (this.currentTask?.display_images && this.currentTask?.display_images[2]) || '';
    }
    @action setAssetId(val) {
        this.assetsId = val;
    }
    @action setAssetCharacter(val) {
        this.assetCharacter = val;
    }
    @action setAssetTitle(str) {
        this.assetTitle = str;
    }
    @action show() {
        this.visible = true;
    }
    @action hide() {
        this.visible = false;
        this.currentTask = { location: 'CN' };
        this.actionState = '';
        this.isPlanningTask = false;
    }
    @action updateContent(content) {
        this.drawerContent = content;
    }
    @action receiveTask(task) {
        this.currentTask = task;
        //判断任务的类型，策划任务还是自建任务
        // if(){}
    }
    @action showWaterfall() {
        this.waterfallVisible = true;
    }
    @action hideWaterfall() {
        this.waterfallVisible = false;
        this.updateReloadImgSrc([]);
    }

    @action updateActionState(res) {
        if (res.code === 1000) {
            this.actionState = 'done';
        } else {
            this.actionState = 'error';
        }
    }

    @action updateRefresh(val) {
        this.refresh = val;
    }

    @action updateRefreshed(val) {
        this.refreshed = val;
    }

    @action updateReloadImgSrc(list) {
        this.reloadImgSrc = list;
    }

    @action fetchWaterFallSource(data) {
        const arr = [];
        let sum = 0;
        const len = data.length;
        for (let i = 0; i < len; i++) {
            const singleData = data[i];
            const tempRadio = this.getRadio(singleData.aspect_ratio);
            sum += tempRadio;
            if (sum <= 6) {
                const temp = this.reduceArr(singleData);
                const arrLen = arr.length;
                if (arrLen === 0) {
                    arr[arrLen] = [].concat(temp);
                } else {
                    const res = this.getArrLastRadio(arr[arrLen - 1], tempRadio);
                    if (res) {
                        arr[arrLen - 1] = arr[arrLen - 1].concat(temp);
                    } else {
                        sum = 0;
                        arr[arrLen] = [].concat(temp);
                    }
                }
            } else if (sum > 6) {
                sum = 0;
                const temp = this.reduceArr(singleData);
                const len = arr.length;
                arr[len] = [].concat(temp);
            }
        }
        return arr;
    }

    @action getArrLastRadio(item, tempRadio) {
        let total = 0;
        for (let i = 0; i < item.length; i++) {
            total += this.getRadio(item[i].aspect_ratio);
        }
        const n = total + tempRadio;
        const flag = n > 6 ? false : true;
        return flag;
    }

    @action reduceArr(data) {
        const newArr = [];
        newArr.push(data);
        return newArr;
    }

    @action getRadio(radio) {
        if (radio > 0 && radio < 1.5) {
            return 1;
        } else if (radio >= 1.5 && radio < 2.5) {
            return 2;
        } else if (radio >= 2.5 && radio < 3.5) {
            return 3;
        } else if (radio >= 3.5) {
            return 4;
        }
    }

    @action updateOperateCurrentTask(obj) {
        this.operateCurrentTask = obj;
    }

    fetchHistory = flow(function* (id) {
        this.loading = true;
        try {
            const res = yield tasksNewAPI.fetchHistory(id);
            if (res.code === 1000) {
                this.loading = false;
                this.history = res.data;
            }
        } catch (error) {}
    });

    fetchTask = flow(function* (id) {
        this.loading = true;
        try {
            const res = yield tasksNewAPI.fetchTask(id);
            if (res.code === 1000) {
                this.loading = false;
                this.receiveTask(res.data);
                this.updateOperateCurrentTask(res.data);
                this.show();
            }
        } catch (error) {}
    });

    fetchTaskConfigs = flow(function* () {
        try {
            const res = yield configsAPI.fetchTaskConfig();
            if (res.code === 1000) {
                this.asset_type = res.data.asset_type;
                this.asset_category = res.data.asset_category;
            }
        } catch (error) {}
    });

    fetchPhotographers = flow(function* () {
        const filter = { type: 2 };
        try {
            const res = yield configsAPI.fetchRelatedUsers({ filter });
            if (res.code === 1000) {
                this.photographers = res.data.list || [];
            }
        } catch (error) {}
    });

    createTask = flow(function* (params) {
        this.actionState = 'pending';
        try {
            const res = yield tasksNewAPI.createTask(params);
            this.updateActionState(res);
            if (res.code === 1000) {
                this.receiveTask(res.data);
            }
            return res;
        } catch (error) {}
    });

    updateTask = flow(function* (params) {
        this.actionState = 'pending';
        try {
            const res = yield tasksNewAPI.updateTask(params);
            this.updateActionState(res);
            return res;
        } catch (error) {}
    });

    checkRefresh = flow(function* (params) {
        this.updateRefreshed(false);
        try {
            const res = yield tasksNewAPI.checkRefresh(params);
            if (res.code === 1000) {
                this.refresh = res.data.refresh;
                localStorage.setItem(`project_${params.project_id}`, res.data.time);
            }
        } catch (error) {}
    });

    getWaterfallSource = flow(function* (id) {
        this.waterfallSource = [];
        try {
            const res = yield tasksNewAPI.getWaterfallSource(id);
            const list = this.reloadImgSrc[0] ? this.reloadImgSrc[0] : [];
            if (res.code === 1000) {
                const flag = res.data;
                if (flag) {
                    this.waterfallSource = filterStore.setPlaceholderImage(this.fetchWaterFallSource([...list, ...res.data]));
                } else {
                    this.waterfallSource = filterStore.setPlaceholderImage(this.fetchWaterFallSource([...list]));
                }
            } else {
                this.waterfallSource = filterStore.setPlaceholderImage(this.fetchWaterFallSource([...list]));
            }
        } catch (error) {}
    });

    getReleatedAsset = flow(function* (id) {
        this.releatedAssets = [];
        try {
            const res = yield tasksNewAPI.getReleatedAsset(id);
            if (res.code === 1000) {
                this.releatedAssets = res.data.list;
            }
        } catch (error) {}
    });

    fetchAssetsInfo = flow(function* (id) {
        try {
            const res = yield tasksNewAPI.fetchTask(id);
            if (res.code === 1000) {
                // this.assetCharacter = res.data.type ? res.data.type : 0;
                this.setAssetCharacter(res.data.type);
            }
        } catch (error) {}
    });

    /**
     *获取资产详情
     * @memberof TaskStore
     */
    getProjectDetail = flow(function* (id) {
        try {
            const res = yield projectsAPI.getProjectDetail(id);
            if (res.code === 1000) {
                this.ProjectDetail = res.data;
                if (res.data.task_package_id === 0) {
                    this.isShowTaskInfoRegion = false;
                } else {
                    this.isShowTaskInfoRegion = true;
                    this.getTaskPackageInfo(res.data.task_package_id);
                }
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });

    /**
     *任务包信息
     * @memberof TaskStore
     */
    getTaskPackageInfo = flow(function* (id) {
        try {
            const res = yield projectsAPI.getTaskPackageInfo(id);
            if (res.code === 1000) {
                this.TaskPackageInfo = res.data;
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });

    planAssociatedTask = flow(function* (params) {
        try {
            const res = yield projectsAPI.planAssociatedTask(params);
            if (res.code === 1000) {
                this.taskPackageList = res.data;
            }
        } catch (error) {}
    });

    /**
     * 创建资产关联任务
     *
     * @memberof TaskStore
     */
    associatedTask = flow(function* (params) {
        try {
            const res = yield projectsAPI.associatedTask(params);
            if (res.code === 1000) {
                this.associatedTaskID = res.data.id;
                this.associatedTaskImage = res.data.images[0];
                this.isAssociated = true;
                this.showAssociatedModal = false;
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
}

export default new TaskStore();
