import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Input } from "antd";
import { AuthButton } from "@components";

const activeList = {
    '1': '资产',
    '2': '任务'
}
@inject("taskGroupsStore")
@observer
class TitleSection extends Component {
    get store() {
        return this.props.taskGroupsStore;
    }
    addNewGroup = () => {
        this.store.addNewGroup();
    };

    render() {
        const { activeType = '1' } = this.props
        const activeName = activeList[activeType] ?? ''
        return (
            <div className="space-between">
                <div className="grouped-assets">
                    <div className="fz-18 mg-b-20">已组合{activeName} </div>
                    <AuthButton
                        block
                        size="large"
                        onClick={this.addNewGroup}
                        className="blue-btn"
                    >
                        新建组合
                    </AuthButton>
                </div>
                <div className="ungrouped-filter">
                    <div className=" mg-b-20 align-center space-between">
                        <div className="fz-18"> 未组合{activeName}</div>
                        <div
                            className="bold cursor-pointer"
                            onClick={() => this.store.updateSearchWord("")}
                        >
                            重置
                        </div>
                    </div>
                    <div className="mg-b-20">
                        <Input
                            size="large"
                            placeholder={"检索" + `${activeName}` + "名称/ID"}
                            value={this.store.searchWord}
                            onChange={(e) => this.store.updateSearchWord(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default TitleSection;
