import React, { Component } from "react";
import { Form } from "antd";
import { TreeSelect, NameInput, ConnectedAccounts, FormFooter } from ".";

export default class RoleForm extends Component {
  formRef = React.createRef();
  treeRef = React.createRef();

  confirmFinish = ({ name }) => {
    const { roleDetails } = this.props;
    const { id } = roleDetails;
    const { checkedKeys } = this.treeRef.current.state;
    const params = { id, name, permissions: checkedKeys };
    this.props.handleSubmit(params);
  };

 render() {
    const props = { ...this.props };
    return (
      <div>
        <Form layout="vertical" ref={this.formRef}>
          <div className="two-col-form">
            <NameInput {...props}></NameInput>
            <ConnectedAccounts {...props}></ConnectedAccounts>
          </div>
          <div>
            <TreeSelect {...props} ref={this.treeRef}></TreeSelect>
          </div>
        </Form>
        <FormFooter
          {...props}
          form={this.formRef}
          confirmFinish={this.confirmFinish}
        ></FormFooter>
      </div>
    );
  };
}
