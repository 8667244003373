import { Typography } from '@components';

/**
 * @name: TitleFormItem.jsx
 * @user: cfj
 * @date: 2022/4/13
 * @description: 分配和领取的title
 */

export const TitleFormField = function(props) {
    const {
        value,
        tmp
    } = props;
    if (tmp === 1) {
        if (value) {
            return <Typography className="title-form-field">
                当前小组常规任务数量上限为{value?.can_take_count || 0}个，
                该成员已领取{value.produce_take_count || 0}个
                （待制作{value?.take_to_produce_count || 0}个、制作中{value?.take_producing_count || 0}个）；
                特殊任务无上限，该成员已分配{value?.produce_allot_count || 0}个
                （待制作{value?.alloted_to_produce_count || 0}个，
                制作中{value?.alloted_producing_count || 0}个）
            </Typography>;
        }
        return <Typography className="title-form-field">请先选择分配人员，再选择需要分配的任务</Typography>;
    } else if (tmp === 2) {
        return <Typography className="title-form-field">
            当前小组成员可自行领取的任务数量上限为{value?.can_take_count || 0}个，
            你已领取{value?.produce_take_count || 0}个
            （待制作{value?.take_to_produce_count || 0}个、制作中{value?.take_producing_count || 0}个）
        </Typography>;
    } else {
        return null;
    }

};
