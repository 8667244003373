import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import ScrollContainer from '@/components/scroll_container';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import TopicListItem from './topic_list_item';


/**
 * 场景管理列表-选题
 * @param {*} props props
 * @constructor
 */
const TopicList = (props) => {
    const { searchData, setSelectTopic } = props;
    const topicRef = useRef(null);
    const [checkKeys, setCheckKeys] = useState(null);
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        if (Array.isArray(allData) && allData.length > 0) {
            const currentKey = allData.find(item => item.id === checkKeys)
            if (currentKey) {
                setSelectTopic(currentKey)
            } else {
                setSelectTopic(null)
                setCheckKeys(null)
            }
        } else {
            setSelectTopic(null)
            setCheckKeys(null)
        }
    }, [allData])

    const checkKeysChange = useCallback((item) => {
        if (checkKeys === item.id) {
            setCheckKeys(null)
            setSelectTopic(null)
        } else {
            setCheckKeys(item.id)
            setSelectTopic(item)
        }
    }, [checkKeys])

    /**
     * 获取所有选题数据
     * @param {Array} val val
     * @constructor
     */
    const renderAllData = useCallback(
        (val) => {
            setAllData(val)
        }, [])

    return (
        <ScrollContainer
            ref={topicRef}
            pageSize={20}
            col={6}
            height="40vh"
            className="amp-related-topic-scroll"
            params={searchData ? { ...searchData } : {}}
            renderItem={(item, key) => (
                <TopicListItem
                    data={item}
                    key={key}
                    checkKeys={checkKeys}
                    checkKeysChange={checkKeysChange}
                />
            )}
            renderAllData={renderAllData}
            request={(page, pageSize, params) => SelectedTopicApi().getTopicList({
                page,
                page_size: pageSize,
                ...params
            }).then((res) => {
                return {
                    list: res.listList,
                    total: res.count
                }
            })}
        />
    );
};

export default memo(TopicList);
