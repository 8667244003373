import { React, useEffect, useRef, useState, useCallback } from 'react'
import ScrollContainer from '@/components/scroll_container'
import { sceneEventBus } from '@/event_bus'
import SelectedTopicApi from "@/api/grpcApi/seleceed_topic/selected_topic_api"
import FilterForm from './filter_form'
import ListItem from './list_item'

/**
 * 下拉滚动加载
 * @param {*} props props
 * @returns {JSX.Element}
 */
const RenderItem = (props) => {
    const { params, setSelectTopic = () => { }, status } = props
    const ref = useRef(null)
    const [allData, setAllData] = useState([]);
    const [checkKeys, setCheckKeys] = useState(null);
    const [random, setRanDom] = useState(Math.random());

    useEffect(() => {
        if (Array.isArray(allData) && allData.length > 0) {
            setSelectTopic(allData[0])
            setCheckKeys(allData[0].id)
        } else {
            setSelectTopic(null)
            setCheckKeys(null)
        }
    }, [allData])

    /**
     * checkKeysChange
     */
    const checkKeysChange = useCallback((item) => {
        setSelectTopic(item)
    }, [])

    /**
     * 获取所有选题数据
     * @param {Array} val val
     * @constructor
     */
    const renderAllData = useCallback(
        (val) => {
            setAllData(val)
        }, [])


    useEffect(() => {
        /**
         * 刷新列表
         */
        const reFresh = () => {
            ref.current?.refresh()
        }

        const off = sceneEventBus.on(reFresh);
        return off
    }, [sceneEventBus]);



    return (
        <ScrollContainer
            ref={ref}
            pageSize={20}
            col={2}
            className={'amp-scene-scroll-container'}
            params={params ? { ...params, status, _test: random } : { status, _test: random }}
            renderItem={(item, key) => (
                <ListItem
                    data={item}
                    key={key}
                    checkKeys={checkKeys}
                    checkKeysChange={checkKeysChange}
                />
            )}
            renderAllData={renderAllData}
            request={(page, pageSize, params) => SelectedTopicApi().listTopicSceneAddress({
                page: page,
                page_size: pageSize,
                ...params,
            }).then(res => ({
                list: res.listList,
                total: res.total
            }))}
        />
    )
}
export default RenderItem
