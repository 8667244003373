import { Table } from "antd";
import {
  HistoryHeader,
  HistoryImages,
  Collapse,
  ProfileAvatar,
} from "@/components";

import {
  planDIC,
  tripColumns,
  fundColumns,
} from "@vars/views/shooting_plan_vars";

const PlanSummary = (props) => {
  return ["area", "time_frame", "fund_total"]?.map((key) => (
    <div className="history-item" key={key}>
      <div className="history-item-title"> {planDIC[key].title}</div>
      <div className="history-item-content">{props[key]}</div>
    </div>
  ));
};
const PlanExecutors = (props) => {
  const { executors } = props;
  return (
    <div className="history-item align-center">
      <div className="history-item-title">{planDIC.executors.title}</div>
      <div className="history-item-content people-display">
        {executors
          ? executors?.map((executor) => (
              <div className="mg-r-12" key={executor.id}>
                <ProfileAvatar
                  {...executor}
                  key={executor.id}
                  className="mg-r-12"
                />
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};

const TripsTable = (props) => {
  const { trips } = props;
  return (
    <Table
      columns={tripColumns}
      dataSource={trips}
      width="840px"
      pagination={false}
      rowKey={(record) => record.id}
      bordered
      className="shooting-plan-detail-table"
    />
  );
};

const FundsTable = (props) => {
  const { funds } = props;
  return (
    <Table
      columns={fundColumns}
      dataSource={funds}
      width="840px"
      pagination={false}
      rowKey={(record) => record.id}
      bordered
      className="shooting-plan-detail-table"
    />
  );
};

const Comment = (props) => {
  const { comment } = props;
  return (
    <div>
      <div>{comment}</div>
    </div>
  );
};

const ShootingPlanDetails = (props) => {
  const { history_detail } = props;
  const components = history_detail ? (
    <div>
      <h3 className="mg-b-16">概览</h3>
      <div className="three-col-history">
        <PlanSummary {...history_detail} />
      </div>
      <div className="one-col-history mg-tb-16">
        <PlanExecutors {...history_detail} />
      </div>
      <h3 className="history-headline">行程</h3>

      <div className="one-col-history">
        <TripsTable {...history_detail} />
      </div>
      <h3 className="history-headline">经费</h3>

      <div className="one-col-history">
        <FundsTable {...history_detail} />
      </div>
      {history_detail.comment ? <h3 className="history-headline">备注</h3> : ""}

      <div className="one-col-history">
        <Comment {...history_detail} />
      </div>

      {history_detail.image_urls?.length > 0 ? (
        <h3 className="history-headline">参考图</h3>
      ) : (
        ""
      )}

      <div className="one-col-history">
        <HistoryImages {...history_detail} />
      </div>
    </div>
  ) : (
    ""
  );
  return (
    <Collapse header={<HistoryHeader {...props} />}>
      {components}
    </Collapse>
  );
};

export default ShootingPlanDetails;
