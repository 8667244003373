import { useState, useEffect } from 'react';
import { Calendar, Button, Input, Spin } from 'antd'
import moment from 'moment'
import {
    CaretLeftOutlined,
    CaretRightOutlined
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { checkAuth } from '@gUtils'
import { businessDataAPI } from "@api"

const CalendarModal = (props) => {
    // 日历编辑权限
    const permissions = 'workday_manage_edit_prv'
    const userPermissions = useSelector(state => state.session.permissions)
    const calendarDisabled = checkAuth(userPermissions, permissions);

    const date = new Date();
    const yearMonth = moment(date).format('YYYY-MM');

    const [loading, setLoading] = useState(false);
    const [statistic, setStatistic] = useState();
    const [calendarData, setCalendarData] = useState([]);
    const [disabledNext, setDisabledNext] = useState(false);
    const [calendarGray, setCalendarGray] = useState(false);
    const [currentYearMonth, setCurrentYearMonth] = useState(moment().endOf('day'));

    useEffect(() => {
        getListWorkDay()
    }, []);

    useEffect(() => {
        const changeDay = moment(currentYearMonth).format('YYYY-MM')
        const newDay = moment(new Date()).format('YYYY-MM')
        if (changeDay === newDay) {
            if (moment(new Date()).format('D') < 15) {
                setDisabledNext(true)
            } else {
                setDisabledNext(false)
            }
        } else if (changeDay < newDay) {
            setDisabledNext(false)
        } else {
            setDisabledNext(true)
        }
    }, [currentYearMonth])


    /**
     * 获取日历列表
     * @param {*} selectMonth
     */
    const getListWorkDay = async (selectMonth) => {
        const data = {
            work_year_month: selectMonth ? selectMonth : yearMonth
        }
        try {
            let res = await businessDataAPI.getListWorkDay(data)
            if (res.code === 1000) {
                setCalendarData(res.data.list)
                setStatistic(res.data.statistic)
            }
            setLoading(false)
        } catch (error) {
        }
        setLoading(false)
    }

    /**
    * 点击日期修改工作日
    * @param {*} date 时间
    */
    const handleOnPanelChange = async (date) => {
        setLoading(true)
        const currentDay = moment(date).format('YYYY-MM-DD')
        const changeData = calendarData?.filter((item, index) => {
            if (item.workday === currentDay) {
                if (item.workday_type === 1) {
                    item.workday_type = 2
                } else {
                    item.workday_type = 1
                }
                return item
            }
        })
        let data = {
            workdays: changeData,
            work_year_month: moment(date).format('YYYY-MM'),
        }
        try {
            let res = await businessDataAPI.saveWorkDay(data)
            if (res.code === 1000) {
                setCalendarData(res.data.list)
                setStatistic(res.data.statistic)
            }
            setLoading(false)
        } catch (error) { }
    }

    /**
     * 数据处理
     * @param {*} value
     */
    const getData = (value) => {
        const currentDay = moment(value).format('YYYY-MM-DD')

        const currentDate = calendarData.find((item) =>
            item.workday === currentDay
        ) || {}

        let singleData = {}

        switch (currentDate.workday_type) {
            case 1:
                singleData = { className: "primary", day: currentDate.workday.split('-')[2], content: '工作日' }
                break;
            case 2:
                singleData = { className: "gray", day: currentDate.workday.split('-')[2], content: '休息日' }
                break;
            default:
                singleData = { className: "", day: currentDay.split('-')[2], content: '' }
        }
        return singleData;
    }

    /**
     * 工作日/休息日
     * @param {*} value
     * @returns {JSX.Element}
     */

    const dateFullCellRender = (value) => {
        const data = getData(value);
        return (
            <div className={`amp-single-${calendarGray ? "gray" : "day"} amp-single-day-${data.className} `}>
                <span>{data.day}</span>
                {
                    !calendarGray ? (
                        <ul className="amp-events amp-events-hidden">
                            <li>
                                <span className={`amp-events-circle amp-events-circle-${data.className}`} />
                                <span style={{ height: "22px" }}>{data?.content}</span>
                            </li>
                        </ul>
                    ) : null
                }
            </div>
        )
    }

    /**
     * 切换上月
     * @param {*} onChange
     */
    const preMonth = (onChange) => {
        let preMonth = moment(currentYearMonth).subtract(1, "month")
        let isBefore = moment(moment(preMonth).format('YYYY-MM')).isBefore(yearMonth)
        setCalendarGray(isBefore)
        getListWorkDay(moment(preMonth).format('YYYY-MM'))
        setCurrentYearMonth(preMonth)
        onChange(preMonth);
    }

    /**
     * 切换下月 / 当未生成下个月的日历时无法查看
     * @param {*} onChange
     */
    const nextMonth = (onChange) => {
        let nextMonth = moment(currentYearMonth).add(1, "month")
        let isBefore = moment(moment(nextMonth).format('YYYY-MM')).isBefore(yearMonth)
        setCalendarGray(isBefore)
        getListWorkDay(moment(nextMonth).format('YYYY-MM'))
        setCurrentYearMonth(nextMonth)
        onChange(nextMonth);

    }

    return (
        <div className='amp-calendar-modal-content'>
            <div className='amp-calendar-modal-title'>每个月15日自动生成下个月的工作日历,如需要调整请点击对应的日期进行变更</div>
            <Spin spinning={loading}>
                <Calendar
                    dateFullCellRender={dateFullCellRender}
                    onSelect={calendarDisabled ? (calendarGray ? null : handleOnPanelChange) : null}
                    validRange={[moment(currentYearMonth).startOf('month'), moment(currentYearMonth).endOf('month')]}
                    headerRender={
                        ({ value, onChange }) => {
                            return (
                                <div className="amp-calendar-modal-select">
                                    <div className='amp-calendar-modal-select-date'>
                                        <Button
                                            className="amp-score-button"
                                            icon={<CaretLeftOutlined />}
                                            onClick={() => preMonth(onChange)}
                                        />
                                        <Input
                                            className="amp-calendar-modal-date"
                                            disabled={true}
                                            value={moment(currentYearMonth).format('YYYY年MM月')}
                                        />
                                        <Button
                                            disabled={disabledNext}
                                            className="amp-score-button"
                                            icon={<CaretRightOutlined />}
                                            onClick={() => nextMonth(onChange)}
                                        />
                                    </div>
                                    <div className='fz-16'>
                                        本月共<span className='amp-calendar-modal-tip'>
                                            &nbsp;{statistic?.total_work_num}&nbsp;
                                        </span>个工作日，当前为第<span className='amp-calendar-modal-tip-primary'>
                                            {statistic?.had_work_num}
                                        </span>个工作日，理想进度<span className='amp-calendar-modal-tip-primary'>
                                            {statistic?.work_percent}%
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    }
                />
            </Spin>
        </div>
    )
}

export default CalendarModal
