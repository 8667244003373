/**
 * @name: FilterForm.jsx
 * @user: cfj
 * @date: 2022/4/13
 * @description: 分配和领取的筛选模块
 */
import { Col, Form, Row } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { TitleFormField } from './TitleFormItem';
import { useSwr } from '@hooks';
import { getProduceInfo } from '@api';
import { Button, Input, Select } from '@components';
import { clsx } from '@gUtils';
import { USerSelectFormField } from '@views/pool/components/USerSelectFormField';
import { EditLimit } from '@views/pool/components/EditLimit';

export const FilterForm = forwardRef(function(props, ref) {
    const userSelectRef = useRef(null);
    const {
        value,
        onChange,
        tmp,
        className
    } = props;
    const [form] = Form.useForm();
    const initialValues = useMemo(() => value, []);
    const {
        data,
        mutate
    } = useSwr(tmp === 2 ? '/api/produce/info' : null, getProduceInfo);

    function reset() {
        form.setFieldsValue({
            parent_id: undefined,
            uid_name: initialValues?.uid_name,
            is_split: initialValues?.is_split
        });
        const values = form.getFieldsValue();
        onChange(values);
    }

    useEffect(() => {
        form.setFieldsValue({ produceInfo: data });
        const values = form.getFieldsValue();
        onChange(values);
    }, [data]);

    useImperativeHandle(ref, () => ({
        refresh() {
            if (tmp === 2) {
                mutate();
            } else {
                userSelectRef.current?.refresh();
            }
        },
        setParentId(id) {
            const parent_id = form.getFieldValue('parent_id');
            form.setFieldsValue({ parent_id: id === parent_id ? undefined : id });
            const values = form.getFieldsValue();
            onChange(values);
        }
    }));
    return <Form
        form={form}
        layout={'inline'}
        className={className}
        initialValues={initialValues}
        onValuesChange={onChange}>
        <Form.Item name={'parent_id'} hidden><span /></Form.Item>
        <Row>
            <Col span={24}>
                <Form.Item dependencies={['produceInfo']}>
                    {({ getFieldValue }) => <TitleFormField value={getFieldValue('produceInfo')} tmp={tmp} />}
                </Form.Item>
            </Col>
        </Row>
        {tmp === 1 && <EditLimit className={'mt-2'} onRefresh={() => userSelectRef.current?.refresh()} />}
        <Row gutter={40} className={'pt-2'}>
            <Col span={7}>
                <Form.Item label={'制作任务'} name={'uid_name'}>
                    <Input placeholder={'搜索任务ID、名称'} />
                </Form.Item>
            </Col>
            <Col span={7}>
                <Form.Item label={'任务类型'} name={'is_split'}>
                    <Select options={[
                        {
                            label: '全部任务',
                            value: 0
                        },
                        {
                            label: '拆分任务',
                            value: 2
                        },
                        {
                            label: '非拆分任务',
                            value: 1
                        }]} />
                </Form.Item>
            </Col>
            <Col span={2} className={'d-flex align-items-center'}>
                <Form.Item noStyle dependencies={['parent_id']}>
                    {({ getFieldValue }) => <Button
                        type={'text'}
                        className={clsx('reset-button', getFieldValue('parent_id') !== undefined && 'reset-button-select')}
                        onClick={reset}>重置</Button>}
                </Form.Item>
            </Col>
            <Col span={8} className={tmp === 1 ? 'produce-info-box' : ''}>
                <Form.Item name={'produceInfo'} required label={'分配制作人'} hidden={tmp === 2}>
                    {tmp === 1 ? <USerSelectFormField ref={userSelectRef} /> : <span />}
                </Form.Item>
            </Col>
        </Row>


    </Form>;
});
