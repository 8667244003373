import { Drawer, Input, Select } from "antd";
import { AuthButton } from "@/components";

const { Option } = Select;

const SubmitContent = (props) => {
  let { setAssignee, state } = props;
  const { list } = state;
  const options = list
    ? list?.map((user) => (
        <Option key={user.user_id} value={user.user_id} label={user.name}>
          {user.name}
        </Option>
      ))
    : [];
  return (
    <div className="display-flex">
      <div className="mg-r-140">
        <div className="bold mg-b-8">下一步</div>
        <Input value="拍摄计划审批" className="input-sz-sm" disabled />
      </div>
      <div>
        <div className="bold mg-b-8">待处理人</div>
        <Select
          placeholder="选择处理人"
          onChange={setAssignee}
          size="large"
          style={{ width: 203 }}
          showSearch optionFilterProp="children" showArrow

        >
          {options}
        </Select>
      </div>
    </div>
  );
};

const SubmitButtons = (props) => {
  const { confirmSubmit, onClose } = props;
  return (
    <div style={{ marginLeft: "-8px" }} className="mg-t-30">
      <AuthButton type="primary" className=" mg-lr-8" onClick={confirmSubmit}>
        确认提交
      </AuthButton>
      <AuthButton className=" mg-lr-8" onClick={onClose}>
        取消
      </AuthButton>
    </div>
  );
};

const SubmitDrawer = (props) => {
  let { onClose, confirmSubmit, state } = props;
  const { visible } = state;
  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      mask={false}
      height={190}
      destroyOnClose
    >
      <SubmitContent {...props} />
      <SubmitButtons onClose={onClose} confirmSubmit={confirmSubmit} />
    </Drawer>
  );
};
export default SubmitDrawer
