import React from 'react'
import TimeChart from './time_chart'
import ProjectChart from './project_chart'
import './index.less'

const DestroyStatisticsChart = (props) => {
    return (
        <div className="amp-destroy-chart page-container px-5 pb-2">
            {/* 时间维度可视化 */}
            <div className="amp-destroy-chart-title">
                <div className="amp-destroy-chart-title-icon"></div>
                <span>时间维度</span>
            </div>
            <TimeChart />

            {/* 项目维度可视化 */}
            <div className="amp-destroy-chart-title">
                <div className="amp-destroy-chart-title-icon"></div>
                <span>项目维度</span>
            </div>
            <ProjectChart />
        </div>
    )
}

export default DestroyStatisticsChart
