import { Image } from "antd";
import { BasicImage } from '@components';
const artAllfilters = [
  { name: "score_total", label: "总评分" },
  { name: "task_count", label: "拍摄任务总数" },
  { name: "assignee_avg_task", label: "人均拍摄数量" },
  { name: "assignee_avg_score", label: "人均得分" },
  { name: "assignee_task_avg_score", label: "任务平均得分" },
];

const productionAllfilters = [
  { name: "score_total", label: "难度总数" },
  { name: "task_count", label: "制作总数" },
  { name: "assignee_avg_score", label: "人均难度" },
  { name: "assignee_avg_task", label: "人均制作数量" },
];

const productionUserFilters = [
  { name: "score_total", label: "难度总数" },
  { name: "task_count", label: "制作总数" },
  { name: "sort", label: "总体排名" },
];

const artUserFilters = [
  { name: "score_total", label: "总评分" },
  { name: "task_count", label: "拍摄任务数量" },
  { name: "task_avg_score", label: "任务平均得分" },
  { name: "sort", label: "总体排名" },
];

const artAllColumns = [
  { title: "汇总时间", dataIndex: "date" },
  { title: "总评分", dataIndex: "score_total" },
  { title: "拍摄任务总数", dataIndex: "task_count" },
  { title: "参与拍摄人数", dataIndex: "assignee_count" },
  { title: "人均得分", dataIndex: "assignee_avg_score" },
  { title: "人均拍摄任务数量", dataIndex: "assignee_avg_task" },
  { title: "任务平均得分", dataIndex: "assignee_task_avg_score" },
];

const artUserColumns = [
  { title: "汇总时间", dataIndex: "date" },
  { title: "拍摄人员", dataIndex: "user_name" },
  { title: "总评分", dataIndex: "score_total" },
  { title: "拍摄任务数量", dataIndex: "task_count" },
  { title: "任务平均得分", dataIndex: "task_avg_score" },
  { title: "总体排名", dataIndex: "sort" },
];

const productionAllColumns = [
  { title: "汇总时间", dataIndex: "date" },
  { title: "难度总数", dataIndex: "score_total" },
  { title: "制作总数", dataIndex: "task_count" },
  { title: "参与制作人数", dataIndex: "assignee_count" },
  { title: "人均难度", dataIndex: "assignee_avg_score" },
  { title: "人均制作数量", dataIndex: "assignee_avg_task" },
];

const productionUserColumns = [
  { title: "汇总时间", dataIndex: "date" },
  { title: "制作人员", dataIndex: "user_name" },
  { title: "难度总数", dataIndex: "score_total" },
  { title: "制作总数", dataIndex: "task_count" },
  { title: "总体排名", dataIndex: "sort" },
];

export const artConfigs = {
  allFilters: artAllfilters,
  userFilters: artUserFilters,
  allColumns: artAllColumns,
  userColumns: artUserColumns,
};

export const productionConfigs = {
  allFilters: productionAllfilters,
  userFilters: productionUserFilters,
  allColumns: productionAllColumns,
  userColumns: productionUserColumns,
};

export const summaryItems = [
  "score_total",
  "task_count",
  "assignee_count",
  "assignee_avg_score",
  "assignee_avg_task",
];

export const statsLabels = {
  art: {
    user_name: "拍摄人员",
    score_total: "总评分",
    task_count: "拍摄任务总数",
    assignee_count: "参与拍摄总人数",
    assignee_avg_score: "人均得分",
    assignee_avg_task: "人均拍摄任务数量",
    assignee_task_avg_score: "任务平均得分",
  },
  production: {
    user_name: "制作人员",
    score_total: "难度总数",
    task_count: "制作总数",
    assignee_count: "参与制作人数",
    assignee_avg_score: "人均难度",
    assignee_avg_task: "人均制作数量",
  },
};

const productionDetailFilters = [
  { name: "project_name", label: "所属项目", type: "input" },
  { name: "asset_uid", label: "内部ID", type: "input" },
  { name: "asset_name", label: "资产名称", type: "input" },
  { name: "user_id", label: "制作人员", type: "select" },
  { name: "score", label: "难度系数", type: "range" },
  { name: "type", label: "类型", type: "select" },
];

const artDetailFilters = [
  { name: "project_name", label: "所属项目", type: "input" },
  { name: "asset_uid", label: "内部ID", type: "input" },
  { name: "asset_name", label: "资产名称", type: "input" },
  { name: "user_id", label: "拍摄人员", type: "select" },
  { name: "type", label: "类型", type: "select" },
  { name: "score", label: "美术评分", type: "range" },
];

const productionReviewFilters = [
  { name: "review_type", label: "任务状态", type: "select" },
  { name: "project_name", label: "所属项目", type: "input" },
  { name: "asset_uid", label: "内部ID", type: "input" },
  { name: "operator_id", label: "处理人员", type: "select" },
  { name: "review_res", label: "类型", type: "select" },
];

export const detailFilters = {
  art: artDetailFilters,
  production: productionDetailFilters,
  review: productionReviewFilters,
};

export const detailActionTypes = [
  { code: 1, label: "通过" },
  { code: 11, label: "驳回" },
  { code: 12, label: "作废" },
  { code: 13, label: "重置" },
  { code: 2, label: "入库" },
  { code: 21, label: "取消入库" },
  { code: 22, label: "通过" },
  { code: 23, label: "作废" },
  { code: 24, label: "重置" },
];

export const detailProductionTypes = [
  { code: 22, label: "通过" },
  { code: 23, label: "作废" },
  { code: 24, label: "重置" },
];

export const reviewTypes = [
  // { code: 1, label: "拍摄美术待审" },
  // { code: 2, label: "拍摄原序列待审" },
  // { code: 3, label: "解析待审" },
  // { code: 4, label: "制作美术待审" },
  // { code: 5, label: "制作结果待审" },
  // { code: 6, label: "工程文件待审" },
  // { code: 7, label: "文件规范待审" },
  { code: 4, label: "制作美术待审" },
  { code: 5, label: "制作结果待审" },
];

export const reviewRes = [
  { code: 1, label: "通过" },
  { code: 2, label: "驳回" },
  { code: 3, label: "作废" },
];

const artDetailColumns = [
  {
    title: "缩略图",
    width: "14%",
    dataIndex: "image",
    render: (image) => <BasicImage height={68} width={68} src={image} />,
  },
  { title: "时间", width: "14%", dataIndex: "created_at" },
  {
    title: "所属项目",
    width: "15%",
    ellipsis: true,
    dataIndex: "project_name",
  },
  { title: "内部ID", width: "14%", dataIndex: "asset_uid" },
  { title: "资产名称", width: "15%", ellipsis: true, dataIndex: "asset_name" },
  { title: "拍摄人员", width: "10%", dataIndex: "user_name" },
  { title: "美术评分", width: "11%", dataIndex: "score" },
  {
    title: "类型",
    dataIndex: "type",
    width: "5%",
    render: (type) =>
      detailActionTypes.find(({ code }) => code === type)?.label,
  },
];

const productionDetailColumns = [
  {
    title: "缩略图",
    width: "14%",
    dataIndex: "image",
    render: (image) => <BasicImage height={68} width={68} src={image} />,
  },
  { title: "时间", width: "14%", dataIndex: "created_at" },
  {
    title: "所属项目",
    width: "15%",
    ellipsis: true,
    dataIndex: "project_name",
  },
  { title: "内部ID", width: "14%", dataIndex: "asset_uid" },
  { title: "资产名称", width: "15%", ellipsis: true, dataIndex: "asset_name" },
  { title: "制作人员", width: "10%", dataIndex: "user_name" },
  { title: "难度系数", width: "11%", dataIndex: "score" },
  {
    title: "类型",
    dataIndex: "type",
    width: "5%",
    render: (type) =>
      detailActionTypes.find(({ code }) => code === type)?.label,
  },
];

const productionReviewColumns = [
  {
    title: "制作图",
    width: "6%",
    dataIndex: "review_images",
    render: (image) => <BasicImage height={60} width={60} src={image[0]} />,
  },
  { title: "时间", width: "11%", dataIndex: "created_at" },
  {
    title: "审核状态",
    width: "9%",
    dataIndex: "review_type",
    render: (type) => reviewTypes.find(({ code }) => code === type)?.label,
  },
  { title: "所属项目", width: "9%", ellipsis: true, dataIndex: "project_name" },
  {
    title: "内部ID",
    width: "12%",
    dataIndex: "asset_uid",
  },
  {
    title: "制作人员",
    width: "8%",
    dataIndex: `producer`,
    render: (obj) => <>{obj.name}</>,
  },
  {
    title: "处理人员",
    width: "8%",
    dataIndex: "operator",
    render: (obj) => <>{obj.name}</>,
  },
  {
    title: "类型",
    dataIndex: "review_res",
    render: (type) => reviewRes.find(({ code }) => code === type)?.label,
  },
  {
    title: "参考图",
    dataIndex: "upload_images",
    render: (image) =>
      image ? <BasicImage height={60} width={60} src={image[0]} /> : <span>-</span>,
  },
  {
    title: "备注",
    width: "18%",
    ellipsis: true,
    dataIndex: "comment",
    render: (comment) => (comment ? <span>{comment}</span> : <span>-</span>),
  },
];

export const detailColumns = {
  art: artDetailColumns,
  production: productionDetailColumns,
  review: productionReviewColumns,
};
