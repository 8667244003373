import { Input, Select, Form } from "antd";
// import moment from "moment";
import { planDIC } from "@vars/views/shooting_plan_vars";
import React, { Component } from "react";
import { ProfileAvatar, Iconfont, UserSelect } from "@/components";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

@inject("configsStore")
@observer
class SummaryForm extends Component {
  formRef = React.createRef();
  state = {
    executors: this.props.planDetails.executors?.map((item) => item.id),
    area: this.props.planDetails.area,
  };

  get planDetails() {
    return this.props.planDetails;
  }

  validateForm = () => {
    this.formRef.current.validateFields().then((res) => {
      const { executors, area } = res;
      this.setState({ executors, area });
    });
  };

  handleFormChange = ({ executors, area }) => {
    if (executors) {
      this.setState({ executors });
    }
    if (area) {
      this.setState({ area });
    }
  };

  areaRender = () => {
    return (
      <Form.Item
        initialValue={this.state.area}
        name="area"
        rules={[{ required: true, message: "请填写拍摄地区" }]}
      >
        <Input
          className="normal-input form-item"
          placeholder={planDIC.area.placeholder}
          disabled={planDIC.area.disabled}
          suffix={<Iconfont className="icon-20" type={planDIC.area.icon} />}
        />
      </Form.Item>
    );
  };

  basicsRender = () => {
    return (
      <div className="three-col-form mg-t-16">
        {this.areaRender()}
        {["time_frame", "fund_total"]?.map((key) => (
          <Input
            className="normal-input form-item"
            placeholder={planDIC[key].placeholder}
            disabled={planDIC[key].disabled}
            value={this.props.planDetails[key]}
            key={key}
            suffix={<Iconfont className="icon-20" type={planDIC[key].icon} />}
          />
        ))}
      </div>
    );
  };

  tagRender = (props) => {
    let { label } = props;
    const style = { marginLeft: "7px" };
    return <span style={style}>{label}</span>;
  };

  validateExecutors = (_, value) => {
    if (!value) return Promise.reject("请选择拍摄人员");
    if (value.length > 8) return Promise.reject("拍摄人员数量不能超过8个");
    return Promise.resolve();
  };

  executorsRender = () => {
    const { allUsers } = this.props.configsStore;
    const { executors } = this.state;
    const displayUsers = toJS(allUsers);
    return (
      <div className="select-people">
        <Form.Item
          initialValue={executors}
          name="executors"
          rules={[{ required: true, validator: this.validateExecutors }]}
        >
          <UserSelect
            mode="multiple"
            maxTagCount={1}
            tagRender={this.tagRender}
            style={{ width: "100%" }}
            placeholder="添加人员"
          />
        </Form.Item>
        <div className="people-display">
          {executors
            ? executors?.map((executorId, idx) => {
                const user = displayUsers?.find(({ id }) => id === executorId);
                return (
                  <div className="mg-r-12" key={idx}>
                    <ProfileAvatar {...user} />
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    );
  };

 render() {
    return (
      <div className="drawer-form-content">
        <h3 className="required">概览</h3>
        <Form
          ref={this.formRef}
          validateTrigger="onBlur"
          onValuesChange={this.handleFormChange}
        >
          {this.basicsRender()}
          {this.executorsRender()}
        </Form>
      </div>
    );
  };
}
export default SummaryForm;
