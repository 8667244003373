import React, { Component } from "react";
import { Form, Modal } from "antd";
import { AuthButton } from "@/components";

export default class DelRole extends Component {
  state = {
    name: "",
    id: null,
    loading: false,
    visibleDel: this.props.visibleDel,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.props.onRef(this);
  }
  delShow = (record) => {
    this.setState({
      visibleDel: true,
      name: record.name,
      id: record.id,
    });
  };
  delHide = () => {
    this.setState({ visibleDel: false });
  };
  submitDelete = () => {
    const {id} = this.state
    this.requestDel({id});
  };

  requestDel = (params) => {
    this.props.deleteRole(params, () => {
      this.setState({ visibleDel: false });
    });
  };
 render() {
    const roleName = this.state.name;
    return (
      <Modal
        className="medium-form-dialog"
        width="550px"
        destroyOnClose
        centered
        visible={this.state.visibleDel}
        confirmLoading={this.state.loading}
        footer={null}
        onCancel={this.delHide}
      >
        <div className="dialog-prompt-title mg-b-68">
          是否确认对角色 <span className="mg-r-10">{roleName}</span>进行删除
        </div>
        <Form.Item>
          <AuthButton
            type="primary"
            htmlType="submit"
            
            onClick={this.submitDelete}
          >
            确认
          </AuthButton>
          <AuthButton
            className=" mg-l-20"
            htmlType="button"
            onClick={this.delHide}
          >
            取消
          </AuthButton>
        </Form.Item>
      </Modal>
    );
  };
}
