import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as MobxProvider } from 'mobx-react';
import '@/stylesheets/fonts/fonts.css';
import '@/stylesheets/setup.less';
import '@/stylesheets/resets.css';
import '@/stylesheets/iconfont.less';
import '@/stylesheets/index.less';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import { HttpClient } from 'ecoplants-lib';
import moment from 'moment';
import * as stores from '@stores';
import { configure } from 'mobx';
import env from 'env';
import App from './App.jsx';
import { configureInceptors, updateTitle } from '@/utils/config';
import { configurePersistor, configureStore } from '@/redux/store.js';

/**
 * GrpcPromise基础类,构建GrpcClient和请求方法
*/
HttpClient.setConfig({
    ApiURL: env.grpcUrls,
    devtoolOption: env.devtoolOption,
    requestConfig: {
        headers: {
            'Content-Type': 'application/grpc-web-text+proto',
            AppID: '999999'
        }
    },
    /**
     * 发送请求之前的请求
     * @param {object} options 基本配置
     * @param {object} requestConfig 请求数据
     * @returns {Promise}
     */
    sendBefore({ options, requestConfig }) {
        requestConfig = requestConfig || {};
        return new Promise((resolve) => {
            requestConfig.headers = {
                ...(requestConfig.headers || {}),
                // token: localStorage.getItem('token'),
                Authentication: localStorage.getItem('token'),
                'x-token': localStorage.getItem('token') // AMP后端需要传“x-token”字段
            };
            resolve({
                ...options,
                requestConfig
            });
        });
    }
});
moment.locale('zh-cn');

const store = configureStore(),
    persistor = configurePersistor(store);
configureInceptors(store);
updateTitle();

configure({
    enforceActions: 'never'
});
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MobxProvider store={stores} {...stores}>
                <BrowserRouter>
                    <ConfigProvider locale={zhCN}>
                        <App />
                    </ConfigProvider>
                </BrowserRouter>
            </MobxProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('amp')
);
