import React, { Component } from 'react'
import { Form, Table, DatePicker, Select, Input } from "antd"
import { Iconfont } from "@/components";
import moment from "moment";
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker;
import "./index.less"


export default class TripEdit extends Component {
    tripEditRef = React.createRef();

    renderOption = (list) => {
        return list.map((item, idx) => {
            return (<Option key={item.id} value={item.id}>
                {item.value}
            </Option>)
        })
    }

    handleClick = async () => {
        try {
            let res = await this.tripEditRef.current.validateFields();
            res = {
                ...res,
                start_time: res.time[0].format('YYYY-MM-DD'),
                end_time: res.time[1].format('YYYY-MM-DD')
            }
            this.props.onSubmit && this.props.onSubmit(res);
            this.tripEditRef.current.resetFields();

        } catch (error) {
            throw error;
        }

    }
    handleConfirm = async () => {
        try {
            let res = await this.tripEditRef.current.validateFields();
            res = {
                ...res,
                start_time: res.time[0].format('YYYY-MM-DD'),
                end_time: res.time[1].format('YYYY-MM-DD')
            }
            this.props.onConfirm && this.props.onConfirm(res);

        } catch (error) {
            throw error;
        }

    }

    render() {
        let data = null;
        if (this.props.itemData) {
            data = {
                ...this.props.itemData,
                time: [moment(this.props.itemData.start_time),moment(this.props.itemData.end_time)],

            }
        }
        return (
            <Form
                ref={this.tripEditRef}
                initialValues={data}
            >
                <table className='amps-trip-edit'>
                    <td className='amp-edit-td amp-edit-col1'>
                        <Item

                            name="time"
                            rules={[{ required: true, message: "请选择日期" }]}
                        >
                            <RangePicker />
                        </Item>
                    </td>
                    <td className='amp-edit-td amp-edit-col2'>
                        <Item

                            name="trip_type"
                            rules={[{ required: true, message: "请选择行程类型" }]}
                        >
                            <Select
                                placeholder="请选择"
                            >
                                {this.renderOption(this.props.typeList)}
                            </Select>
                        </Item>
                    </td>
                    <td className='amp-edit-td amp-edit-col3'>
                        <Item

                            name="destination"
                            rules={[{ required: true, message: "请输入目的地" }]}
                        ><Input placeholder="请输入目的地" /></Item>
                    </td>
                    <td className='amp-edit-td amp-edit-col4'>
                        <Item

                            name="transportation"
                            rules={[{ required: true, message: "请选择交通方式" }]}
                        >
                            <Select
                                placeholder="请选择"
                            >
                                {this.renderOption(this.props.tripType)}
                            </Select>
                        </Item>
                    </td>
                    <td className='amp-edit-td amp-edit-col5'>
                        <Item

                            name="comment"
                        ><Input placeholder="选填" /></Item>
                    </td>
                    <td className='amp-edit-td amp-edit-col6'>
                        {this.props.isEdit ? (
                            <>
                                <span className='primary-color mg-r-6' onClick={this.handleConfirm}>确认</span>
                                <span className='primary-color' onClick={this.props.onCancel}>取消</span>
                            </>
                        ) : (<span onClick={this.handleClick}>
                            <Iconfont type="icon-a-30-zengjia" className="amp-add-icon icon-30"></Iconfont>
                        </span>)}
                    </td>
                </table>
            </Form>
        )
    }
}
