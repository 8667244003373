import { useEffect, useRef, useState } from 'react';

/**
 * 提交请求
 * @param fetcher 触发的请求(data) => Promise
 * @param options
 * @param options.onSuccess  请求成功时的会调用
 * @param options.onError  请求失败时的会调用
 * @returns {[boolean,((function(*): Promise<void>)|*)]} [请求状态, 触发请求方法]
 */
const useSend = function(fetcher, options) {
    const {
        onSuccess,
        onError
    } = options;
    const requestRef = useRef(fetcher);
    const isUnmounted = useRef(false)
    const [loading, setLoading] = useState(false);

    async function send(params) {
        try {
            setLoading(true);
            const res = await requestRef.current(params);
            if(!isUnmounted.current){
                setLoading(false);
                onSuccess?.(res);
            }
        } catch (error) {
            if(!isUnmounted.current){
                setLoading(false);
                onError?.(error);
            }


        }
    }
    useEffect(() => {
        requestRef.current = fetcher;
    });
    useEffect(() => {
        return () => {
            isUnmounted.current = true
        }
    }, [])
    return [loading, send];
};
export default useSend;
