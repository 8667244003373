import { projectDIC } from "@vars/views/projects_vars";
import {
  ProfileAvatar,
  HistoryHeader,
  HistoryImages,
  Collapse,
} from "@/components";

const ProjectBase = (props) =>
  ["title", "code", "start_at", "estimate_finish_at"]?.map((key) => (
    <div className="history-item" key={key}>
      <div className="history-item-title"> {projectDIC[key].title}</div>
      <div className="history-item-content">
        {props[key]
          ? !key.includes("at")
            ? props[key]
            : props[key].replace(/-/g, "/")
          : "未填写"}
      </div>
    </div>
  ));

const ProjectPeople = (props) =>
  ["creator", "photographer"]?.map((key) => (
    <div className="history-item align-center" key={key}>
      <div className="history-item-title mg-t-6"> {projectDIC[key].title}</div>
      <div className="history-item-content">
        <ProfileAvatar {...props[key]} size={32} />
      </div>
    </div>
  ));

const ProjectDescriptions = (props) => {
  const { description } = props;
  return description ? (
    <div className="one-col-history">
      <div className="history-item">
        <div className="history-item-title">
          {projectDIC.description.title}
        </div>
        <div className="history-item-content"> {description} </div>
      </div>
    </div>
  ) : (
    ""
  );
};

const ProjectDetails = (props) => {
  const { history_detail, isOpen } = props;
  const components = history_detail ? (
    <div className="mg-t-6">
      <div className="two-col-history">
        <ProjectBase {...history_detail} />
      </div>
      <div className="two-col-history">
        <ProjectPeople {...history_detail} />
      </div>
        <ProjectDescriptions {...history_detail} />
      <div className="mg-t-6">
        {history_detail.image_urls?.length > 0 ? (
          <HistoryImages {...history_detail} title={"参考图"} />
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
  return (

    <Collapse header={<HistoryHeader {...props} isOpen={isOpen} />}>
      {components}
    </Collapse>
  );
};

export default ProjectDetails;
