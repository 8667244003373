import axios from "axios";

const rolesAPI = {
  fetchRoles(params) {
    return axios.post("/role/list", params);
  },
  createRole(params) {
    return axios.post("/role/create", params);
  },
  allPermissions() {
    return axios.get("/api/role/permissions");
  },
  deleteRole(params) {
    return axios.delete("/role/delete", { data: params });
  },
  editRole(params) {
    return axios.put("/role/update", params);
  },
  roleInfo(id) {
    return axios.get("/role/info?id=" + id);
  },
};

export default rolesAPI;
