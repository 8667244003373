import {
  observable,
  action,
  computed,
  flow,
  makeAutoObservable,
  reaction,
} from "mobx";
import { artStatsAPI, productionStatsAPI } from "@api";
import { statsStore } from "@stores";
import { debounce } from "lodash";
import moment from "moment";

class ReportStore {
  constructor() {
    makeAutoObservable(this);
  }
  @observable report = {};
  @observable date;
  @observable loading = false;
  @action startLoad() {
    this.loading = true;
  }
  @action finishLoad() {
    this.loading = false;
  }
  @computed get statType() {
    return statsStore.statType;
  }
  @computed get dateType() {
    return statsStore.dateType;
  }
  @action clearDate() {
    if (this.statType === "art") {
      this.date = moment().subtract(1, "months");
    } else {
      this.date = moment().subtract(1, "weeks");
    }
  }
  @action updateDate(val) {
    this.date = val;
  }
  @action changeWeek(val) {
    this.week = val;
  }
  @action updateDateType(type) {
    statsStore.updateDateType(type);
  }
  @action updateStatType(type) {
    statsStore.updateStatType(type);
  }
  @action clear() {
    this.report = {};
  }

  getArtReport = flow(function* () {
    this.startLoad()
    try {
      const res = yield artStatsAPI.getArtReport(this.date.format("YYYY-MM"));
      if (res.code === 1000) {
        this.report = res.data;
        this.finishLoad()
      } else {
        this.report = {};
      }
    } catch (e) {}
  });
  getProductionReport = flow(function* () {
    this.startLoad()
    const date =
      this.dateType === 1
        ? this.date.format("YYYY-MM")
        : this.dateType === 2 && this.date.format("YYYY-WW");
    try {
      const res = yield productionStatsAPI.getProductionReport(
        date,
        this.dateType
      );
      if (res.code === 1000) {
        this.report = res.data;
        this.finishLoad()
      } else {
        this.report = {};
      }
    } catch (e) {}
  });
}

const reportStore = new ReportStore();
const handleDifferentRequest = () => {
  if (reportStore.statType === "art") {
    reportStore.getArtReport();
  } else {
    reportStore.getProductionReport();
  }
};
const debouncedRequest = debounce(handleDifferentRequest, 500);
reaction(
  () => reportStore.date,
  (val, prevVal) => {
    if (val !== prevVal) debouncedRequest();
  }
);



export default reportStore;
