import { Component } from "react";
import { Modal } from "antd";
import {AuthButton} from '@/components'
export default class SignOut extends Component {
  state = {
    visible: false,
  };
  show = () => {
    this.setState({ visible: true });
  };
  hide = () => {
    this.setState({ visible: false });
  };

  handleConfirm = () => {
    this.hide();
    this.props.logout();
  };

  render() {
      const {currentUser} = this.props
    return (
      <Modal
        visible={this.state.visible}
        onOk={this.handleConfirm}
        onCancel={this.hide}
        footer={null}
        className="medium-form-dialog"
        centered
      >
        <h2>登出确认</h2>
        <div className="mg-t-30 fz-18">
          是否确认对当前账户{currentUser.username}进行退出登录？
        </div>
        <AuthButton
          type="primary"
          className=" mg-t-30 mg-r-12"
          onClick={this.handleConfirm}
        >
          确认
        </AuthButton>
        <AuthButton className=" mg-t-30" onClick={this.hide}>
          取消
        </AuthButton>
      </Modal>
    );
  }
}
