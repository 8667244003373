import { AuthTabPane, AuthTabs } from '@components'
import * as React from 'react'
import { useParentRoute } from '@components/router/routes'

/**
 * @name: RenderTabs.jsx
 * @user: cfj
 * @date: 2022/4/20
 * @description: 渲染Tabs
 */
export const RenderTabs = (porps) => {
  const route = useParentRoute();
  if(route) return null
  return (
    <AuthTabs
      {...porps}
      tabBarGutter={60}
      permission=""
    >
      <AuthTabPane key="all" tab="整体统计" />
      <AuthTabPane key="user" tab="个人统计" />
    </AuthTabs>
  );
};
