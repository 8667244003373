import { Button, Input, Spin } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import useDebounceState from '@/hooks/useDebounceState';
import { sessionAPI, taskPlanAPI } from '@api';
import { $error } from '@components';
import PreviewImage from '@components/image/preview_image';
import Image from '@components/image';
import MackIcon from '@components/image/mack_icon';

const AssociatedContentV2 = function({
    package_id,
    selectItem,
    onSelect
}) {
    const scrollDomRef = useRef();
    const [anchorEl, setAnchorEl] = useState();
    const [params, debounceParams, setParams] = useDebounceState({
        package_id: package_id,
        title: ''
    });
    const {
        list,
        loading,
        total,
        error,
        refresh
    } = useInfiniteScroll({
        anchorEl,
        params: debounceParams,
        onError(error, onReload, currentPage, errorCount){
            if(errorCount<3){
                onReload()
            } else {
                $error(error.message || '服务器错误')
            }
        },
        request: (page, params) => taskPlanAPI.getPlanTaskList({
            page,
            page_size: 32,
            package_id: params.package_id,
            filter: {
                //过滤已关联的任务
                has_related: 2,
                title: params.title,
                status: [1,2,3,4]
            }
        })
            .then((res) => {
                return {
                    list: res.data.list,
                    total: res.data.total,
                    statics: res.data.statics
                };
            })

    });
    const onPreview = (item) => {
        PreviewImage.open({
            images: item.images,
            renderDesc: (current) => <div style={{
                color: '#fff',
                padding: '100px 50px'
            }}>
                <p>名称 &nbsp;&nbsp; {item.title}</p>
                <p>上传人 {item.creator.name}</p>
                <p>自评分 {item.score}</p>
            </div>,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };
    useEffect(() => {
        if(package_id){
            setParams({
                package_id: package_id,
                title: ''
            })
        }
    }, [package_id]);
    useEffect(() => {
        setAnchorEl(scrollDomRef.current);
    }, []);
    return <div className='associated-content'>
        <div className={'associated-content-form'}>
            <div className={'associated-content-form-item'}>
                <label className={'associated-content-form-item-label'}>选择任务</label>
                <Input className={'associate-input-sz-ml'} size={'large'} value={params.title} onChange={(event) => {
                    setParams((state) => ({
                        package_id: state.package_id,
                        title: event.target.value
                    }));
                }} />
            </div>
        </div>

        <p className={'associated-content-title'}>任务图片</p>

        <div ref={scrollDomRef} className={'associated-content-scroll'}>
            {
                !error  && list?.map(item => {
                    return (
                        <Image
                            key={item.id}
                            width={160}
                            height={160}
                            style={{margin: 6}}
                            src={item.images?.[0]}
                            mask={[<MackIcon onClick={() => onPreview(item)} type={'icon-a-16-chakan'} />]}
                            checked={item.id === selectItem?.id}
                            disabled={item.status !==2}
                            onChecked={() => {
                                onSelect(item.id, item)
                            }}
                            status={item.status}
                            title={item.title}
                            score={item.score}
                        />
                    )
                })
            }
            {
                !!(!error && loading && !list.length) && <div  className={'associated-content-scroll-loading'}>
                    <Spin />
                </div>
            }
            {
                !!(!error &&loading && list.length) && <div className={'associated-content-scroll-footer-loading'}><Spin /></div>
            }

            {
                (!error && !loading && !list.length && !total) && <div className="associated-content-scroll-nodata">
                    <img className='associated-content-scroll-nodata-img' src={`${process.env.PUBLIC_URL}/images/other/no_data.png`} alt='请选择任务包' />
                    <span className='associated-content-scroll-nodata-text'>暂无任务</span>
                </div>
            }
            {
                error && <Button onClick={() => refresh()}>{error.message||'服务错误'}，点击重新加载</Button>
            }
        </div>
    </div>;
};

export default memo(AssociatedContentV2);
