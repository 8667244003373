const assetForm = [{
    name: '资产名称',
    placeholder: '请输入名称',
    rules: {
        name: [{
            required: true,
            message: "请输入名称",
            type: "string"
        }, {
            message: "资产名称不能超过50个字符",
            max: 50,
            require: true,
            type: 'string'
        },]
    }

},
{
    name: '内部ID',
    placeholder: '创建完成后自动生成',
    rules: {}
},
{
    name: '资产类型',
    placeholder: '请选择',
    rules: {
        name: [{
            required: true,
            message: "请选择资产类型",
            type: "string"
        },]
    }
},
{
    name: '资产种类',
    placeholder: '请选择',
    rules: {
        name: [{
            required: true,
            message: "请选择资产种类",
            type: "number"
        },]
    }
},
{
    name: '采集地区',
    placeholder: '请选择',
    rules: {
        name: [{
            required: true,
            message: "请选择采集地区",
            type: "string"
        },]
    },
},
{
    name: '拍摄人员',
    placeholder: '请选择',
    rules: {
        name: [{
            required: true,
            message: "请选择拍摄人员",
            type: "number"
        },]
    }
},
{
    name: '预览图',
    placeholder: '上传图片',
    rules: {}
},
{
    name: '备注',
    placeholder: '请输入备注信息',
    rules: {
        name: [{
            required: true,
            message: "请输入备注信息",
            type: "string"
        },]
    }
}

];
const toVoidReason = [{
    label: '项目作废',
    rules: {
        name: [{
            required: true,
            message: "请选择作废原因",
            type: "number"
        },]
    }
},
{
    label: '处理人',
    rules: {
        name: [{
            required: true,
            message: "请选择处理人",
            type: "number"
        },]
    }
},
{
    label: '优先级',
    rules: {
        name: [{
            required: true,
            message: "请选择优先级",
            type: "number"
        },]
    }
}
]

const priorityTypes = [{
    label: '顶',
    code: 1
},
{
    label: '高',
    code: 2
},
{
    label: '低',
    code: 3
},
]

const discardType = [{
    label: '前期拍摄',
    code: 1
},
{
    label: '解析处理',
    code: 2
},
{
    label: '后期制作',
    code: 3
},
{
    label: '无责任作废',
    code: 4,
    only: true,
}
]

const scoreTypes = [{
    label: '2',
    code: 2
},
{
    label: '3',
    code: 3
},
{
    label: '4',
    code: 4
},
{
    label: '5',
    code: 5
},
]

const statusArr = [{
    label: '拍摄处理',
    code: 1
},
{
    label: '解析处理',
    code: 2
},
{
    label: '制作处理',
    code: 3
}

]




const priorityIcon = {
    1: "icon-top",
    2: "icon-list-high",
    3: "icon-low",
};

const taskDIC = {
    supervisors: "监制",
    photographers: "拍摄",
    analyzers: "解析",
    producers: "制作"
}

const buttons = {
    // 监制  待审核状态
    1: {
        name: '通过',
        type: 'primary',
        className: ' mg-l-20',
        code: 1,
        supervisors: 0,
        label: '待分配',
        permission: 'task_agree'
    },
    2: {
        name: '驳回',
        type: 'primary',
        className: ' mg-l-20',
        code: 2,
        supervisors: 0,
        label: '待拍摄',
        permission: 'task_reject'
    },
    3: {
        name: '驳回',
        type: 'primary',
        className: ' mg-l-20',
        code: 3,
        supervisors: 0,
        label: '待解析',
        permission: 'task_reject'
    },
    4: {
        name: '驳回',
        type: 'primary',
        className: ' mg-l-20',
        code: 4,
        supervisors: 0,
        label: '待制作',
        permission: 'task_reject'
    },
    // 仅当制作待审状态显示
    5: {
        name: '完工',
        type: 'primary',
        className: ' mg-l-20',
        code: 5,
        supervisors: 0,
        label: '已完工',
        permission: 'task_complete'
    },
    // ###########
    // 6: {
    //     name: '',
    //     type: '',
    //     className: ' mg-l-20',
    //     code: 6,
    //     supervisors: 0,
    //     label: ''
    // },
    // 监制  待分配状态
    7: {
        name: '拍摄分配',
        type: 'primary',
        className: ' mg-l-20',
        code: 7,
        supervisors: 0,
        label: '待拍摄',
        permission: 'task_assign_shooting'
    },
    8: {
        name: '解析分配',
        type: 'primary',
        className: ' mg-l-20',
        code: 8,
        supervisors: 0,
        label: '待解析',
        permission: 'task_assign_analysis'
    },
    9: {
        name: '制作分配',
        type: 'primary',
        className: ' mg-l-20',
        code: 9,
        supervisors: 0,
        label: '待制作',
        permission: 'task_assign_production'
    },
    // 拍摄  待拍摄状态
    10: {
        name: '开始拍摄',
        type: 'primary',
        className: ' mg-l-20',
        code: 10,
        supervisors: 0,
        label: '拍摄中',
        permission: 'task_start_shooting'
    },
    11: {
        name: '拍摄转派',
        type: 'primary',
        className: ' mg-l-20',
        code: 11,
        supervisors: 0,
        label: '待拍摄',
        permission: 'task_transfer_shooting'
    },
    12: {
        name: '拍摄回退',
        type: 'primary',
        className: ' mg-l-20',
        code: 12,
        supervisors: 0,
        label: '待分配',
        permission: 'task_rollback_shooting'
    },
    // 拍摄  拍摄中状态
    13: {
        name: '提交审核',
        type: 'primary',
        className: ' mg-l-20',
        code: 13,
        supervisors: 0,
        label: '拍摄待审核',
        permission: 'task_submit_shooting'
    },
    14: {
        name: '停止拍摄',
        type: 'primary',
        className: ' mg-l-20',
        code: 14,
        supervisors: 0,
        label: '待拍摄',
        permission: 'task_stop_shooting'
    },
    // 解析 待解析状态
    15: {
        name: '解析开始',
        type: 'primary',
        className: ' mg-l-20',
        code: 15,
        supervisors: 0,
        label: '解析中',
        permission: 'task_start_analysis'
    },
    16: {
        name: '解析转派',
        type: 'primary',
        className: ' mg-l-20',
        code: 16,
        supervisors: 0,
        label: '待解析',
        permission: 'task_transfer_analysis'
    },
    17: {
        name: '解析回退',
        type: 'primary',
        className: ' mg-l-20',
        code: 17,
        supervisors: 0,
        label: '待分配',
        permission: 'task_rollback_analysis'
    },
    // 解析中状态
    18: {
        name: '提交解析',
        type: 'primary',
        className: ' mg-l-20',
        code: 18,
        supervisors: 0,
        label: '解析待审核',
        permission: 'task_submit_analysis'
    },
    19: {
        name: '停止解析',
        type: 'primary',
        className: ' mg-l-20',
        code: 19,
        supervisors: 0,
        label: '待解析',
        permission: 'task_stop_analysis'
    },
    // 制作 待制作状态
    20: {
        name: '开始制作',
        type: 'primary',
        className: ' mg-l-20',
        code: 20,
        supervisors: 0,
        label: '制作中',
        permission: 'task_start_production'
    },
    21: {
        name: '制作转派',
        type: 'primary',
        className: ' mg-l-20',
        code: 21,
        supervisors: 0,
        label: '待制作',
        permission: 'task_transfer_production'
    },
    22: {
        name: '制作回退',
        type: 'primary',
        className: ' mg-l-20',
        code: 22,
        supervisors: 0,
        label: '待分配',
        permission: 'task_rollback_production'
    },
    // 制作中状态
    23: {
        name: '提交制作',
        type: 'primary',
        className: ' mg-l-20',
        code: 23,
        supervisors: 0,
        label: '制作待审核',
        permission: 'task_submit_production'
    },
    24: {
        name: '停止制作',
        type: 'primary',
        className: ' mg-l-20',
        code: 24,
        supervisors: 0,
        label: '待制作',
        permission: 'task_stop_production'
    },

    // 关闭  已完工状态
    25: {
        name: '重制',
        type: 'primary',
        className: ' mg-l-20',
        code: 25,
        supervisors: 0,
        label: '待分配',
        permission: 'task_remake'
    },
    // 关闭 已作废状态
    26: {
        name: '重制',
        type: 'primary',
        className: ' mg-l-20',
        code: 26,
        supervisors: 0,
        label: '待分配',
        permission: 'task_remake'
    },
}

const allTaskPermissions = [
    "task_stop_analysis",
    "task_create",
    "task_submit_shooting",
    "task_transfer_shooting",
    "task_discard",
    "task_remake",
    "task_transfer_production",
    "task_stop_production",
    "task_submit_production",
    "task_rollback_shooting",
    "task_stop_shooting",
    "task_download",
    "task_reject",
    "task_rollback_analysis",
    "task_update",
    "task_submit_analysis",
    "task_transfer_analysis",
    "task_assign_production",
    "task_agree",
    "task_assign_analysis",
    "task_complete",
    "task_rollback_production",
    "task_start_production",
    "task_assign_shooting",
    "task_start_analysis",
    "task_start_shooting",
];

const taskObj = {
    asset_name: "",
    asset_uid: "",
    priority: null,
    processing_time: "",
    task_status: { code: null, label: "", list_type_id: null },
    id: null,
    asset_category: {
        code: null,
        label: ""
    },
    asset_type: {
        code: "",
        label: ""
    },
    status_color: "",
    status_icon: "",
    assignee: {
        id: null,
        username: "",
        name: ""
    },
};

export {
    assetForm,
    priorityTypes,
    discardType,
    scoreTypes,
    priorityIcon,
    taskDIC,
    toVoidReason,
    statusArr,
    buttons,
    allTaskPermissions,
    taskObj
};
