/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-use-before-define */
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import ContainerMap from './container_map';

interface IProps {
    height: string | number,
    width: string | number,
    marker: any[],
}
/**
 * 高德地图组件
 * @param {Number | String} props.height 地图容器高度默认400px
 * @param {Number | String} props.width 地图容器宽度默认100%
 * @param {Array} props.marker 标记点
 * @returns
 */
const GdMap = forwardRef((props: IProps, ref) => {
    const {
        height = '400px',
        width = '100%',
        marker
    } = props;

    // @ts-ignore
    const AMap: any = window.AMap || null;

    const containRef = useRef<HTMLDivElement>(null);

    const [map, setMap] = useState<any>(null);

    // 初始化加载地图依赖
    useEffect(() => {
        const creatMap = new AMap.Map(containRef.current, {
            viewMode: '3D',
            zoom: 12,
            zooms: [2, 22],
            resizeEnable: true
        });
        setMap(creatMap);
        return () => {
            setMap(null);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        // 搜索关键字
        search(keywords: any) {
            return new Promise((resolve, reject) => {
                AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
                    const autoComplete = new AMap.Autocomplete({});
                    autoComplete.search(keywords, (status: any, result: any) => {
                        if (status === 'complete') {
                            resolve(result?.tips || []);
                        } else {
                            resolve([]);
                        }
                    });
                });
            });
        }
    }));

    // 动态添加标记
    useEffect(() => {
        let pointMarker: any[] = [];
        if (map) {
            pointMarker = (marker || []).map((item) => (
                new AMap.Marker({
                    map,
                    anchor: 'bottom-center',
                    title: item.name,
                    position: new AMap.LngLat(item.lng, item.lat),
                    clickable: true,
                    topWhenClick: true
                })
            ));
            map.setFitView();
        }
        return () => {
            map?.remove?.(pointMarker);
        };
    }, [marker, map]);

    return (
        <div
            ref={containRef}
            style={{
                height,
                width
            }}
        />
    );
});

export default ContainerMap(GdMap);
