import "./index.less";
import { Image } from "antd";
import { useState } from "react";
import {
    SeeIcon,
} from "./plan_task_img/components/icon_utils";
import { AntdPreviewImg } from "./plan_task_img/components/antd-preview-img";
import "./index.less"
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { BasicImage } from '@components';
/**
 * 策划任务的图片组件
 * @param {*} props
 * @returns
 */

const getBorderColor = (params, text) => {
    switch (params || params.code) {
        //  未审核
        case 1:
            return `amp-${text}-no-see`
        //   通过
        case 2:
            return `amp-${text}-pass`;
        //   驳回
        case 3:
            return `amp-${text}-reject`;
        //   作废
        case 4:
            return `amp-${text}-invalid`;
        default:
            return "";
    }
};
const TaskImg = (props) => {
    const { items } = props

    const renderHover = () => {

        return (
            <SeeIcon />
        )
    };
    return items ? (
        <div className="tmp-task-info-images">
            {
                items.map((item, index) => (
                    <div className={`amps-plan-task-img ${getBorderColor(item.status, "border")}`} key={index}>
                        <div className="task-img-body">
                            <BasicImage
                                alt="img"
                                src={item?.images?.[0] || ''}
                            // preview={{ mask: renderHover() }}
                            />
                        </div>
                        <div className={`task-text`}>
                            {item.score}
                        </div>
                    </div>
                ))
            }
        </div>
    ) : null
}

export default inject("taskPlanStore")(observer(TaskImg));
