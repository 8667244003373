import { observable, action, flow, makeAutoObservable, computed } from "mobx";
import { shootingTeamAPI } from "@api";

class ShootingTeamStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable shootingTeamList = [];

    // 获取拍摄小组列表
    getUserGroupList = flow(function* () {
        try {
            const res = yield shootingTeamAPI.shootingTeamList();
            if (res.code === 1000) {
                this.shootingTeamList = res.data;
            } else {
            }
        } catch (error) { }
    });

    fetchShootingPlanConfig = flow(function* () {
        try {
            const res = yield configsAPI.fetchShootingPlanConfig();
            if (res.code === 1000) {
                this.planConfig = res.data;
            } else {
            }
        } catch (error) { }
    })
}

export default new ShootingTeamStore();
