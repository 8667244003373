import React, { useState, useEffect, useMemo, memo } from 'react'
import { Select } from 'antd';
import TargetTable from '../target_table'
import { $error } from "@components";
import { targetApi } from "@api";
import { monthEnum } from '@vars/views/settings_vars'
import { useSelector } from "react-redux";
import { checkAuth } from '@gUtils'

const { Option } = Select;
const years = [
    {
        title: '2022',
        value: '2022'
    },
    {
        title: '2023',
        value: '2023'
    }
]
const TargetConfig = (props) => {
    const [year, setYear] = useState('2022')
    const [tableData, setTableData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    // 判断用户是否有权限编辑
    const userPermissions = useSelector(state => state.session.permissions)
    const editable = checkAuth(userPermissions, 'performance_goal_update');
    /**
     * @method input输入失去焦点回调事件，更新目标值
     * @param {string} val 修改的值
     * @param {object} record 表格行数据
     * @param {string} dataIndex 表格行字段名
     */
    const itemRevise = async (val, record, dataIndex) => {
        setTableLoading(true)
        const params = {
            user_id: record.user_id,
            group_id: record.group_id,
            goal: val ? val : 0,
            goal_date_year: record.year,
            goal_date_month: monthEnum[dataIndex]
        }
        const res = await targetApi.updateGoal(params)
        if (res.code === 1000) {
            // 当前编辑小组目标
            const enitGroupTargetTotal = res.data?.group_detail?.goal || 0
            let newData = tableData.map(item => {
                if (item.key === record.key) {
                    item[dataIndex] = val
                }
                if(item.group_id === record.group_id && !item.user_id){
                    item[dataIndex] = enitGroupTargetTotal
                }
                return item
            })
            setTableData(newData)
        } else {
            $error(res.msg)
        }
        setTableLoading(false)
    }

    // 获取表格数据
    const getTableData = async () => {
        setTableLoading(true)

        try {
            const res = await targetApi.getGoalList({ year: year })
            if (res.code === 1000) {
                const data = res?.data?.list?.map((item,index) => {
                    return {
                        ...item,
                        key: index
                    }
                })
                setTableData(data || [])
            } else {
                $error(res.msg)
            }
            setTableLoading(false)
        } catch (error) {
        }

    }

    useEffect(() => {
        getTableData()
    }, [year])

    // 格式化表格展示数据
    const dataSource = useMemo(() =>{
        const group = []
        const groupChildren = {}
        tableData.forEach((item, index) => {
            // user_id不存在或为0 为组目标
            if(!item.user_id){
                group.push(item)
            }else{
                if(groupChildren[item.group_id]){
                    groupChildren[item.group_id].push(item)
                }else{
                    groupChildren[item.group_id] = [item]
                }
            }
        })
        const showData = group.map(item => {
            const children = groupChildren[item.group_id]
            return {
                ...item,
                children
            }
        })
        return showData
    }, [tableData])

    return (
        <>
            <div className="space-between mg-b-20">
                <div>
                    <span className="mg-r-10">目标年份</span>
                    <Select
                        className="select-sz-m"
                        size="large"
                        value={year}
                        onChange={(value) => setYear(value)}
                        showArrow
                    >
                        {
                            years.map(item => (
                                <Option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.title}
                                </Option>
                            ))
                        }
                    </Select>
                </div>
            </div>
            {/* <div className="amp-target-tips mg-b-20">
                <span className="mg-r-20">换算区域</span>
                <span>目标*天数*系数 = 实际目标</span>
            </div> */}
            <TargetTable
                year={year}
                itemRevise={itemRevise}
                tableLoading={tableLoading}
                dataSource={dataSource}
                editable={editable}
            />
        </>
    )
}

export default memo(TargetConfig)
