import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { AuthButton, $success, $error } from '@/components';
import SceneModal from '@/views/topic_selection_management/topic_details/components/topic_scene/components/scene_modal';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import DrawerChildren from './render';
import './index.less';

const renderList = (props, setModalType) => {
    const { list } = props;
    const temp = [];
    list?.map((item) => {
        const type = item?.buttonColorType === 1 ? 'primary' : 'default';
        temp.push(
            <AuthButton
                key={item?.operation}
                type={type}
                onClick={() => {
                    actionHandle(item, props, setModalType);
                }}>
                {item?.buttonName}
            </AuthButton>
        );
    });
    return temp;
};

const actionHandle = (item, props, setModalType) => {
    const temp = item?.operation === 'topic_scene_update'; // 编辑场景
    if (temp) {
        setModalType('edit');
    } else {
        props?.open({
            title: item?.name,
            content: <DrawerChildren item={item} />,
            okText: '确认',
            onOk(values) {
                SelectedTopicApi()
                    .topicSceneSchedulerHandler({
                        commonParam: {
                            ...values,
                            operation: item?.operation,
                            sceneId: props?.id
                        }
                    })
                    .then(() => {
                        $success('操作成功');
                        props?.fetchSceneDetails();
                    })
                    .catch((err) => {
                        $error(err?.message);
                    });
            },
            onError(error) {
                $error(error.message);
            }
        });
    }
};

/**
 * 场景详情底部按钮组件
 * @param {*} props props
 * @returns
 */
const SceneDetailsFooter = (props) => {
    const { list, id, open, fetchSceneDetails, topicId } = props;
    const [modalType, setModalType] = useState('default');
    const historyObj = useHistory();
    const [operationList, setOperationList] = useState([]);
    useEffect(() => {
        setOperationList(renderList(props, setModalType));
    }, [list, open]);
    // useEffect(() => {
    //     fetchSceneDetails();
    // }, [modalType === '']);
    return (
        <div className="amps-scene-details-footer">
            <SceneModal onClose={(type) => setModalType(type)} modalType={modalType} sceneId={id} topicId={topicId} fetchSceneDetails={props?.fetchSceneDetails} />
            {operationList}
        </div>
    );
};

export default SceneDetailsFooter;
