import { Component } from "react";
import { Menu } from "antd";
import { withRouter } from "react-router-dom";
import { Iconfont } from "@/components";

const menus = [
  {
    key: "profile",
    title: "个人信息",
  },
  {
    key: "permissions",
    title: "权限信息",
  },
  {
    key: "notifications",
    title: "通知公告",
  },
  {
    key: "activities",
    title: "项目变动",
  },
  {
    key: "messages",
    title: "消息管理",
  },
];

class SideBar extends Component {
  get currentActiveKey() {
    const { pathname } = this.props.location;
    const activeKey = pathname.split("/").slice(-1);
    return activeKey[0] === "account" ? ["profile"] : activeKey;
  }

  handleClick = ({ key }) => {
    this.props.history.push(`/account/${key}`);
  };
  renderMenu = () =>
    menus?.map((menu) => (
      <Menu.Item
        key={menu.key}
        icon={ <Iconfont type={`icon-${menu.key}`} /> }
      >
        {menu.title}
      </Menu.Item>
    ));
  render() {
    return (
      <Menu
        onClick={this.handleClick}
        style={{ width: 256 }}
        mode="inline"
        selectedKeys={this.currentActiveKey}
      >
        {this.renderMenu()}
      </Menu>
    );
  }
}

export default withRouter(SideBar);
