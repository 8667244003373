import {announcementAPI} from "@api";
import { RECEIVE_ANNOUNCEMENT, DELETE_ANNOUNCEMENT } from "../action_consts";
import { fetchAnnouncements } from "./announcements_actions";
import { $success, $error } from "@/components";
const receiveAnnouncement = (announcement) => ({
  type: RECEIVE_ANNOUNCEMENT,
  announcement,
});

const deleteAnnouncement = (id) => ({
  type: DELETE_ANNOUNCEMENT,
  id,
});

export const fetchAnnouncement = (id, success = () => {}, error = () => {}) => (
  dispatch
) =>
  announcementAPI.fetchAnnouncementDetails(id).then((res) => {
    if (res.code === 1000) {
      success();
      return dispatch(receiveAnnouncement(res.data));
    } else {
      error();
    }
  });

export const createAnnouncement = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  announcementAPI.createAnnouncement(params).then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
      //  dispatch(fetchAnnouncements({ page: 1 }));
      return;
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
    }
  });

export const updateAnnouncement = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  announcementAPI.updateAnnouncement(params).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
      // return dispatch(receiveAnnouncement(res.data));
      return;
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
    }
  });

export const removeAnnouncement = (
  id,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  announcementAPI.removeAnnouncement(id).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
      dispatch(fetchAnnouncements({ page: 1, page_size: 14 }));
      return dispatch(deleteAnnouncement(id));
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
    }
  });

export const publishAnnouncement = (
  params,
  success = () => {},
  error = () => {}
) => (dispatch) =>
  announcementAPI.publishAnnouncement(params).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
      return dispatch(receiveAnnouncement(res.data));
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
    }
  });
