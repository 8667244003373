import axios from 'axios';

const tasksAPI = {
    fetchTaskDetail(id) {
        return axios.get('/task/info?id=' + id)
    },

    fetchTasks(params) {
        return axios.post("/task/list", params);
    },
    fetchTaskInfo(id) {
        return axios.get('/task/info?id=' + id)
    },
    fetchTaskHistory(id) {
        return axios.get('/api/task/history?id=' + id)
    },
    createTask(params) {
        return axios.post('/task/add', params)
    },
    updateTask(params) {
        return axios.post('/task/update', params)
    },
    removeTask(id) {
        return axios.get('/task/remove?id=' + id)
    },


    shootingAssignment(params) { //拍摄分配
        return axios.post('/task/to-shoot', params)
    },

    taskDiscard(params) { // 作废
        return axios.post('/task/discard', params)
    },
    // 制作
    submitMake(params) { // 提交制作
        return axios.post('/task/submit-make', params)
    },
    makingAssignment(params) { //制作分配
        return axios.post('/task/to-make', params)
    },
    // backShoot(params) { //  回退拍摄
    //     return axios.post('/task/back-to-shoot', params)
    // },
    backAnalyse(params) { //回退解析
        return axios.post('/task/back-to-analyse', params)
    },
    backMake(params) { // 回退制作
        return axios.post('/task/back-to-make', params)
    },
    taskMaking(params) { //制作开始
        return axios.post('/task/making', params)
    },

    // 解析
    // taskComplete(params) { // 制作完成
    //     return axios.post('/task/complete', params)
    // },
    analysisTask(params) {
        return axios.post('/task/analyzing', params)
    },
    allocationTask(params) {
        return axios.post('/task/to-analyze', params)
    },
    backToReviewShoot(params) { // 回退监制（待解析、解析中回退至拍摄待审）
        return axios.post('/task/back-to-review-shoot', params)
    },
    submitAnalyze(params) {
        return axios.post('/task/submit-analyze', params)
    },
    analyticalDistribution(params) {
        return axios.post('/task/to-analyze', params)
    },
    // 监制
    fallbackShoot(params) {
        return axios.post('/task/back-to-shoot', params)
    },
    fallbackParsing(params) {
        return axios.post('/task/back-to-analyze', params)
    },
    productionDistribution(params) {
        return axios.post('/task/back-to-make', params)
    },
    handleComplete(params) {
        return axios.post('/task/complete', params)
    },
    // 拍摄
    submitShoot(params) { //拍摄提交审核
        return axios.post('/task/submit-shoot', params)
    },
    downloadUrl(id) { // 下载文件
        return axios.get('/task/info?id=' + id)
    },
    startShooting(params) { //开始拍摄
        return axios.post('/task/shooting', params)
    },
    backToReviewAnalysis(params) { //回退监制（待制作，制作中回退至待解析）
        return axios.post('/task/back-to-review-analyze', params)
    },
    // 监制  通过
    reviewAgree(params) {
        return axios.post('/task/agree',params)
    },
    // 停止 拍摄/解析/制作
    taskStop(params) {
        return axios.post('/task/stop',params)
    },
    //  作废 完成  重制
    remake(params){
        return axios.post('/task/re-back',params)
    },
    //  驳回
    reject(params){
        return axios.post('/task/reject',params)
    },
    // 回退
    rollback(params){
        return axios.post('/task/rollback',params)
    },
    getWaterfalls(data){
        return axios.post('/project/waterfall', data)
    }
}

export default tasksAPI
