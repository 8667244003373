import React from 'react';
import { Col, Row } from 'antd'
import { BasicImage, Collapse } from '@components';
import { imageTitle, materialTypeEnum, projectDetailTitle, projectTitle, scoreTitle } from './../config';
import './index.less';

const handleOpenDetail = (item) => {
    const { project_id, id } = item
    window.open(`/projects/${project_id}?task_flow?taskId=${id}`);
}

const Photographers = (props) =>
    props["photographers"].map((item, index) => {
        return `${item.name}${index === props["photographers"].length - 1 ? `` : `/`}`
    })


const ProjectName = (props) => {
    return (
        props?.asset_name ? (
            <div className="amp-assets-info-item mg-tb-8 amp-w-50">
                <span className="amp-assets-info-item-title">{projectDetailTitle["asset_name"].title}</span>
                {
                    props?.sibling?.length > 0 ? (
                        <Collapse header={props["asset_name"]} fontWeight="fw-500" opened={true}>
                            {
                                props?.sibling?.map((item, index) => {
                                    return (
                                        <Row justify='space-between' gutter={{ xs: 8 }} className='amp-assets-info-split-task mg-b-10' key={index}>
                                            <Col span={12} className='amp-text-over-ellipsis'>
                                                <span className="cursor-pointer" onClick={() => handleOpenDetail(item)}>{item.asset_uid} &nbsp; {item.asset_name}</span>
                                            </Col>
                                            <Col span={6}>
                                                <span className="amp-assets-info-split-task-status" style={{ background: `${item.task_status.color}` }}>{item.task_status.label}</span>
                                            </Col>
                                            <Col span={6}>
                                                <span>
                                                    {item.assignees?.map((item, index) => {
                                                        return <i key={index}>{item.name}&nbsp;</i>
                                                    })}
                                                </span>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Collapse>
                    ) : (
                        <span className={`amp-assets-info-label`}>{props["asset_name"]}</span>
                    )
                }
            </div>
        ) : null
    )
}


const ProjectTitleBase = (props) =>
    projectTitle.map((item, index) => {
        let defaultValue = props[item]
        if (item === 'material_type') {
            defaultValue = materialTypeEnum[props[item]]
        }
        return (
            defaultValue ? (
                <div className={`amp-assets-info-item mg-tb-8 ${item === "asset_comment" ? "amp-w-100" : "amp-w-50"}`} key={index}>
                    <span className="amp-assets-info-item-title">{projectDetailTitle[item].title}</span>
                    <span className={`amp-assets-info-label`}>
                        {item === "photographers" ? <Photographers {...props} /> : ((item === "asset_type" || item === "asset_category") ? props[item].label : defaultValue)}
                    </span>
                </div>
            ) : null
        )
    });
const ImageBase = (props) =>
    imageTitle.map((item, index) => (
        props[item]?.length > 0 ? (
            <div className={`amp-assets-info-item mg-tb-8 amp-w-100`} key={index}>
                <span className="amp-assets-info-item-title">{projectDetailTitle[item].title}</span>
                <div className={`amp-assets-info-label amp-assets-info-item-photo`}>
                    {
                        props[item].map((val, index) => {
                            return (
                                <div className="history-images" key={index}>
                                    <BasicImage width={110} height={110} src={val} key={index} />
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        ) : null
    ));
const ScoreBase = (props) =>
    scoreTitle.map((item, index) => (
        props[item] ? (
            <div className={`amp-assets-info-item mg-tb-8 amp-w-100`} key={index}>
                <span className="amp-assets-info-item-title">{projectDetailTitle[item].title}</span>
                <span className={`amp-assets-info-label`}>
                    {props[item]}
                </span>
            </div>
        ) : null
    ));

const AssetsInfoRegion = (props) => {
    return (
        <div style={{ paddingTop: "96px" }}>
            <Collapse header="资产信息" fontWeight="fw-500" opened={true}>
                <div className="amp-assets-info">
                    <ProjectName {...props} />
                    <ProjectTitleBase {...props} />
                    <ImageBase {...props} />
                    <ScoreBase {...props} />
                </div>
            </Collapse>
        </div>

    )
}

export default AssetsInfoRegion
