import React, { Component } from "react";
import {
    RoleFilterContainer,
    RoleTableContainer,
    AddRole,
    AddEditRoleContainer,
    DelRoleContainer,
} from "./index";
import { roleAdd } from "@vars/views/settings_role_vars";
import { AuthButton, AccessControl } from "@/components";

export default class RoleIndex extends Component {
    editOnRef = (ref) => {
        this.child = ref;
    };
    addUser = () => {
        this.child.show("add", roleAdd);
    };
    editShow = (data) => {
        this.child.show("edit", data);
    };
    delORef = (ref) => {
        this.child.DelRole = ref;
    };
    delShow = (data) => {
        this.child.DelRole.delShow(data);
    };
    handleShowDel = (data) => {
        this.delShow(data);
    };

    handleSaveDelete = (data) => {
        this.child.hide();
        this.delShow(data);
    };
    render() {
        return (
            <div>
                <div className="settings-role-head">
                    <AccessControl permission="role_list">
                        <RoleFilterContainer />
                    </AccessControl>
                    <AuthButton
                        permission="role_create"
                        type="primary"

                        onClick={this.addUser}
                    >
                        新增
                    </AuthButton>
                </div>
                <div className="table-list-content">
                    <RoleTableContainer
                        {...this.props}
                        delShow={this.delShow}
                        editShow={this.editShow}
                    />
                </div>
                {/* <AddRole onRef={this.editOnRef}></AddRole> */}
                <AddEditRoleContainer
                    {...this.props}
                    onRef={this.editOnRef}
                    handleShowDel={this.handleSaveDelete}
                ></AddEditRoleContainer>
                <DelRoleContainer
                    onRef={this.delORef}
                    {...this.props}
                    handleShowDel={this.handleShowDel}
                ></DelRoleContainer>
            </div>
        )
    }
}
