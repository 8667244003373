import { HttpClient } from 'ecoplants-lib';
import * as projectManageMessage from '@/api/protos_api/data_depend_api_pb';
import { DataDependApiServicePromiseClient } from '@/api/protos_api/data_depend_api_grpc_web_pb';

/**
 * 系统设置-资产类型api
 */
class ProjectManageApi extends HttpClient {
    /**
     * 构造函数
     */
    constructor () {
        super({
            GrpcClient: DataDependApiServicePromiseClient,
            services: projectManageMessage
        });
    }

    /**
     * 资产类型列表
     * @returns {Promise}
     */
    fetchAssetTypeList() {
        return this.fetchData(
            'listAssetType',
            {
                data: {}
            },
            {
                rpcRequestName: 'Empty'
            }
        );
    }
}
/**
 * ProjectManageApi
 * @returns {function} 请求api单例
 */
export default function() {
    return ProjectManageApi.singleton();
}
