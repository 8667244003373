import { useState, useEffect } from 'react'
import { useParentRoute } from '@components/router/routes.js'
import { useHistory } from 'react-router'
import { Table } from 'antd'
import { useSwr } from '@hooks';
import moment from 'moment'
import { $error, $success } from "@/components"
import { boldExportFile } from '@gUtils/downFile'
import { businessDataAPI } from '@api'
import FilterForm from './filterForm'
import { detailColumn, timeStamp } from '../config'
import './../../index.less'

const default_start_time = timeStamp(moment().subtract(1, 'year'), 1)
const default_end_time = timeStamp(moment(), 2)

/**
 * 制作周月报详情页面
 * @returns {JSX.Element} ProduceDetail
 */
const ProduceDetail = () => {
    const history = useHistory()
    const [btnLoading, setBtnLoading] = useState(false); // 导出按钮loading
    const route = useParentRoute() // 获取当前路由节点
    const [exportParams, setExportParams] = useState(); // 导出数据
    const [columns, setColumns] = useState([]); // 表格columns
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
    })


    useEffect(() => {
        if (route.key === 'week-detail') {
            const weekSingleColumns = {
                title: '初次提交制作初审时间',
                dataIndex: 'review_time',
            }
            // 周columns
            const weekColumns = detailColumn.map(item => item.dataIndex === 'review_time' ? weekSingleColumns : item)
            setColumns(weekColumns)
        } else {
            setColumns(detailColumn)
        }
    }, [route.key]);

    const getKpiDataListRequest = (url, search) => {
        const { user_id, group_id, start_time, end_time, asset_id_name, task_status } = search
        let userId = 0
        let groupId = 0
        if (history.location?.user_id) {
            userId = history.location.user_id
        } else if (history.location?.group_id) {
            groupId = history.location.group_id
        }
        let params = {
            ...search,
            page: search.current,
            page_size: search.pageSize,
            user_id: user_id || userId,
            group_id: group_id || groupId,
            start_time: history.location?.data?.start_time || Number(start_time || default_start_time),
            end_time: history.location?.data?.end_time || Number(end_time || default_end_time),
            asset_id_name: asset_id_name || '',
            task_status: task_status || 0,
            is_week: route.key === 'week-detail' ? true : false,
            is_month: route.key === 'month-detail' ? true : false
        }
        delete params.current
        delete params.pageSize
        setExportParams(params);
        return businessDataAPI.getStatisticsDetail(params).then((res) => {
            if (res.code === 1000) {
                return res.data
            }
        })
    }

    const { data: kpiData, loading } = useSwr(['/api/kpi-asset-info/list', pagination, route.key], getKpiDataListRequest)

    /**
     * 分页
     * @param {*} page
     */
    const onChangeGetList = (page) => {
        setPagination({ ...pagination, pageSize: page.pageSize, current: page.current })
    }

    /**
     * 模糊查询
     * @param {*} value 模糊查询value
     */
    const submitForm = (value) => {
        delete history.location?.user_id
        delete history.location?.group_id
        delete history.location?.data
        setPagination({ ...pagination, ...value, current: 1 })
    }

    /**
     * 重置表单
     * @param {*} value
     */
    const resetForm = (value) => {
        delete history.location?.user_id
        delete history.location?.group_id
        delete history.location?.data
        setPagination({ ...pagination, current: 1, ...value, start_time: default_start_time, end_time: default_end_time })
    }

    /**
     * 导出全部数据
     * @param {*} handleCsvRef
     */
    const csvExport = () => {
        setBtnLoading(true)
        businessDataAPI.exportWeekOrMonthDetail(exportParams).then((blob) => {
            setBtnLoading(false)
            boldExportFile(blob);
            $success('导出成功');
        }).catch((err) => {
            $error(err.message);
        });
    };

    return (
        <div className='amps-business-data amps-collection-data'>
            <FilterForm
                className="amp-mg-t-20"
                resetForm={resetForm}
                submitForm={submitForm}
                csvExport={csvExport}
                btnLoading={btnLoading}
            />
            <Table
                rowKey={(record) => record.task_id + Math.random()}
                dataSource={kpiData?.infos || []}
                columns={columns}
                loading={loading}
                pagination={{
                    position: ['bottomCenter'],
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: kpiData?.total,
                    showTotal: (total) => `共 ${total} 条`,
                    ...pagination,
                }}
                onChange={onChangeGetList}
            />
        </div>
    )
}

export default ProduceDetail
