import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Table } from 'antd'
import { useSwr } from '@hooks';
import { TaskDrawer, $error, $success } from '@components'
import { analyticalStatsAPI } from '@api'
import { boldExportFile } from '@gUtils/downFile'
import { AnalyticalFrom } from '../../0_components'
import { analyticalDetailColumns, analyticalDetailFormList } from './../config'

const type = 3

/**
 * 解析统计详情页面
 * @returns {JSX.Element} AnalyticalDetail
 */
const AnalyticalDetail = () => {
    const history = useHistory()
    const [exportParams, setExportParams] = useState({}); // 导出
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
    })

    /**
     * 请求列表数据
     * @param {*} url
     * @param {*} searchPage
     * @returns {Object} res data
     */
    const getTableRequest = (url, searchPage) => {
        const { filter } = searchPage
        let params = {
            ...searchPage,
            page: searchPage.current,
            page_size: searchPage.pageSize,
            type: type, // 绩效类型
            filter: {
                task_key: filter?.task_key || "", // 关键字查询默认为空
                user_id: filter?.user_id || history.location?.user_id || 0, // 筛选组员 || 跳转组员 || 默认0
                user_group_id: filter?.user_group_id || history.location?.user_group_id || 0, // 筛选小组 || 跳转小组 || 默认0
                created_at: filter?.created_at || history.location?.exportParams?.created_at || { // 筛选时间 || 跳转时间 || 默认时间
                    from: "", // 开始时间
                    to: "" // 结束时间
                }
            }
        }
        delete params.current
        delete params.pageSize
        setExportParams(params)
        return analyticalStatsAPI.getAnalyticalDetails(params).then(res => {
            if (res.code === 1000) {
                return res.data
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: dataSource, isValidating } = useSwr(['/api/task-performance/list', pagination, type], getTableRequest)

    /**
     * 分页
     * @param {*} page
     */
    const onChangeGetList = (page) => {
        setPagination({ ...pagination, pageSize: page.pageSize, current: page.current })
    }
    /**
     * 模糊查询
     * @param {*} value 模糊查询value
     */
    const handleSearch = (value) => {
        delete history.location?.user_id
        delete history.location?.user_group_id
        delete history.location?.exportParams
        setPagination({ ...pagination, filter: value, current: 1 })
    }

    /**
     * 重置表单
     * @param {*} value
     */
    const resetForm = (value) => {
        delete history.location?.user_id
        delete history.location?.user_group_id
        delete history.location?.exportParams
        setPagination({ ...pagination, current: 1, filter: value })
    }

    /**
     * 导出全部数据
     * @param {*} handleCsvRef
     */
    const csvExport = () => {
        analyticalStatsAPI.getAnalyticalDetailExport(exportParams).then(res => {
            boldExportFile(res)
            $success("导出成功")
        }).catch(err => {
            $error(err.message)
        })
    }

    /**
     * Table action
     */
    const actionColumns = {
        title: '操作',
        render: (record) => (
            <span
                className='amp-blue-btn cursor-pointer'
                onClick={() => handleDetail(record)}
            >
                查看
            </span>
        ),
    }

    /**
    * 打开侧弹框
    * @param {*} record
    */
    const handleDetail = (record) => {
        const { task_id } = record
        history.push(`?task_flow?taskId=${task_id}`)
    }

    return (
        <div className='amps-collection-data'>
            <AnalyticalFrom
                className="amp-mg-t-20"
                csvExport={csvExport}
                resetForm={resetForm}
                submitForm={handleSearch}
                formList={analyticalDetailFormList}
                GroupType={3}
            />
            <Table
                rowKey="id"
                dataSource={dataSource?.list || []}
                loading={isValidating}
                columns={[...analyticalDetailColumns, ...actionColumns]}
                pagination={{
                    position: ['bottomCenter'],
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: dataSource?.total,
                    showTotal: (total) => `共 ${total} 条`,
                    ...pagination
                }}
                onChange={onChangeGetList}
            />
            <TaskDrawer />
        </div>
    )
}

export default AnalyticalDetail
