import "./index.less";
import { Button } from "antd";
import { AuthButton } from "@/components";
import { toJS } from "mobx";

/**
 * 创建***任务****相关按钮
 * @returns
 */
export const TaskButton = (props) => {
  const { handleCancel, isHaveTask, taskPackageStore, loginUser } = props;
  const creator = taskPackageStore.currentTasks?.creator;
  const { users } = taskPackageStore.currentTasks;
  const limits = [creator, ...users];
  let allowPerson = false;
  limits.map((item) => {
      if (item.id === loginUser.id) {
        allowPerson = true;
      }
  })
  const { id } = taskPackageStore.currentTasks.creator
  const flag = id === loginUser.id;
  return (
    <div className="amps-create-tasks-button">
      <div className="bottom-button  ">
        {isHaveTask && (
          <Button
            type="primary"
            className="tasks-create tasks-btn mg-r-20"
            onClick={() => {
              props.taskPlanStore.setTitle(0);
            }}
            style={{
                display: flag && allowPerson ? "inline-block" : "none"
            }}
          >
            提交审核
          </Button>
        )}
        <Button
          type="primary"
          className={`tasks-create tasks-btn ${isHaveTask && "mg-r-20"}`}
          onClick={() => {
            props.taskPlanStore.setTitle(1);
          }}
          style={{
            display: allowPerson ? "inline-block" : "none"
          }}
        >
          创建任务
        </Button>
        {isHaveTask && (
          <AuthButton
            type="primary"
            className="mg-r-20"
            onClick={() => {
              props.taskPackageStore.setShowEditCreate(true);
            }}
            style={{
                display: allowPerson ? "inline-block" : "none"
            }}
          >
            编辑任务包
          </AuthButton>
        )}

        <AuthButton
          className="mg-r-20"
          onClick={() => {
            props.taskPlanStore.setTitle(2);
          }}
          style={{
            display: allowPerson ? "inline-block" : "none"
          }}
        >
          批量创建
        </AuthButton>
        <Button className="tasks-cancel tasks-btn" onClick={handleCancel}>
          取消
        </Button>
      </div>
    </div>
  );
};
