import { Col, Divider, Row } from 'antd'
import { Empty, Iconfont } from '@components'
import { useHistory } from 'react-router'
import { statsLabels, summaryItems } from '@vars/views/stats_vars'

const SingleRankItem = (props) => {
    const { user_name, sort, score_total, task_count, id } = props;
    const isTop = sort <= 3;
    return (
        <div className="single-rank-item" key={id}>
            <div className="rank-sort">
                <div className={`fz-18 ${isTop ? "rank-top" : "rank-normal"}`}>
                    {sort}
                </div>
            </div>
            <div className="rank-name">{user_name}</div>
            <div className="rank-score">{score_total}</div>
            <div className="rank-task">{task_count}</div>
        </div>
    );
};

const SquareDisplay = (props) => {
    const { label, value, change } = props;
    const isIncrease = change > 0;
    const percentage = Math.round(change * 100 * (isIncrease ? 1 : -1));
    const displayChange = `${percentage}%`;
    return (
        <div className="dashed-square-display">
            <div className="fz-18 justify-center" style={{ padding: "9px 0" }}>
                {label}
            </div>
            <Divider style={{ margin: "0" }} />
            <div
                className={"square-display-data"}
                style={{ color: isIncrease ? "#8B3EFF" : "#4FBDFF" }}
            >
                {value}
            </div>
            <div className="justify-center align-center">
                <span className="mg-r-10">{`据上期：${displayChange}`}</span>
                <span>
                    {change ? (
                        <Iconfont
                            type={`icon-${isIncrease ? "increase" : "decrease"}`}
                            className="icon-24"
                        />
                    ) : (
                        ""
                    )}
                </span>
            </div>
        </div>
    );
};

const ShowDetailButton = (props) => {
    const { url } = props;
    const history = useHistory();
    return (
        <div className="show-detail" onClick={() => history.push(url)}>
            查看详情
        </div>
    );
};

const StatsSummary = (props) => {
    const { type, stats, url } = props;
    const labels = statsLabels[type];
    const displayItems =
        type === "art"
            ? [...summaryItems, "assignee_task_avg_score"]
            : summaryItems;
    return (
        <div>
            <div className="bold fz-18 mg-b-20 display-flex">
                <span className="mg-r-10">整体概况</span>
                <ShowDetailButton url={url} />
            </div>
            <div className="summary-table">
                <Row justify="flex-start" gutter={[16, 32]}>
                    {displayItems?.map((item,index) => {
                        return (
                            <Col xl={4} key={index}>
                                <SquareDisplay
                                    key={item}
                                    label={labels[item]}
                                    value={stats && stats[item]}
                                    change={stats && stats[item + "_change"]}
                                />
                            </Col>
                        );
                    })}
                </Row>

            </div>
        </div>
    );
};

const RankTable = (props) => {
    const { stats, type } = props;
    const labels = statsLabels[type];
    return (
        <div>
            <div className="bold fz-18 mg-b-20">
                <span className="mg-r-10">总排行</span>
            </div>
            <div className="rank-table">
                <div className="rank-table-labels">
                    <div className="rank-sort">排名</div>
                    <div className="rank-name">{labels?.user_name}</div>
                    <div className="rank-score">{labels?.score_total}</div>
                    <div className="rank-task">{labels?.task_count}</div>
                </div>
                <div className="rank-table-scroll">
                  {
                    stats?.length?stats
                      ?.map((item) => (
                        <SingleRankItem {...item} key={item.id} />
                      )):<Empty />
                  }
                </div>
            </div>
        </div>
    );
};

export { StatsSummary, RankTable, ShowDetailButton };
