/**
 * @name: isDataEmpty.js
 * @user: cfj
 * @date: 2022/4/21
 * @description:
 */
export const isDataEmpty = function (data){
  if(typeof data === 'object'){
    if(Array.isArray(data)&& data.length){
      return  true
    }
    return !!Object.keys(data)?.length;
  } else if(typeof data === 'number') {
    return true
  } else {
    return !!data
  }
}
