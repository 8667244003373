import React, { Component } from "react";
import { withRouter } from "react-router";
import { Table, AuthIcon, Iconfont, ConfirmDialog } from "@/components";
import { announcementColumns } from "@vars/views/settings_vars";
import { Row, Col } from "antd";

class AnnouncementTable extends Component {
  deleteRef = React.createRef();
  publishRef = React.createRef()
  rowClick = (record, index) => {
    this.goToRecord(record);
  };

  goToRecord = (record) => {
    if (record.status === 0) {
      this.props.history.push(`/settings/announcements/${record.id}`);
    } else {
      window.open(`/announcements/${record.id}`, "_blank");
    }
  };

  publishAnnouncement = (e, record) => {
    e.stopPropagation();
    this.publishRef.current.show(record, "公告", record.title, "发布")
  };

  fetchAnnouncements=()=> {
    this.props.fetchItems({page:1, page_size: 12})
  }

  removeAnnouncement = (e, record) => {
    e.stopPropagation();
    this.showDelete(record)
  };

  showDelete = (record) => {
    this.deleteRef.current.show(record, "公告", record.title)
  }

  render() {
    const action = {
      title: "操作",
      render: (text, record) => (
        <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
          <Col className="gutter-row" span={5}>
            <AuthIcon
              type={record.status === 0 ? "icon-publish-item" : " "}
              className="icon-20"
              onClick={(e) => this.publishAnnouncement(e, record)}
              permission="announcement_publish"
            />
          </Col>
          <Col className="gutter-row" span={5}>
            <AuthIcon
              type={record.status === 0 ? "icon-edit-item" : " "}
              className="icon-20"
              onClick={() => this.goToRecord(record)}
              permission="announcement_update"
            />
          </Col>
          <Col className="gutter-row" span={5}>
            <AuthIcon
              type="icon-trash"
              className="icon-20"
              onClick={(e) => this.removeAnnouncement(e, record)}
              permission="announcement_delete"
            />
          </Col>
        </Row>
      ),
    };
    const columnsConfig = [...announcementColumns, action];

    return (
      <>
        <ConfirmDialog
          ref={this.deleteRef}
          update={this.fetchAnnouncements}
          storeRequest={this.props.removeAnnouncement}
        />
        <ConfirmDialog
          ref={this.publishRef}
          update={this.fetchAnnouncements}
          storeRequest={this.props.publishAnnouncement}
        />
        <Table
          rowClick={this.rowClick}
          columnsConfig={columnsConfig}
          // permission="announcement_list"
          {...this.props}
        />
      </>
    );
  }
}

export default withRouter(AnnouncementTable)
