import { RECEIVE_PROJECT, RECEIVE_PROJECTS, DELETE_PROJECT } from "@actions/action_consts";

const projectsReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_PROJECT:
      return { ...state, [action.project.id]: action.project };
    case RECEIVE_PROJECTS:
      let projects = action.projects.list;
      let res = {};
      projects.forEach((project) => {
        res[project.id] = project;
      });
      if (!action.params.filter) return res
      return { ...state, ...res };
    case DELETE_PROJECT:
      const {id} = action
      const newState = {...state}
      delete newState[id]
      return newState
    default:
      return state;
  }
};

export default projectsReducer;
