import React, { useEffect, useState } from 'react'
import { Cascader } from 'antd';


const CascaderTheme = (props) => {
    const {
        value,
        onChange,
        options,
        ...other
    } = props

    const [selectKeys, setSelectKeys] = useState([])

    const findPatentValue = (array, targetId, valueKey, childrenKey) => {
        if (!targetId || !Array.isArray(array)) return []
        const result = []
        let valid = false
        const seek = (_array, _targetId) => {
            let parentValue = ''
            const up = (_array_, _targetId_, lastValue) => {
                _array_.forEach(v => {
                    const val = v[valueKey]
                    const child = v[childrenKey]
                    if (val === _targetId_) {
                        valid = true
                        parentValue = lastValue
                        return
                    }
                    child?.length && up(child, _targetId_, val)
                })
            }
            up(_array, _targetId)
            if (parentValue) {
                result.unshift(parentValue)
                seek(_array, parentValue)
            }
        }
        seek(array, targetId)
        return valid ? [...result, targetId] : []
    }

    useEffect(() => {
        if(Array.isArray(value) && value.length === 1){
            const newArray = findPatentValue(options, value[0].id, 'id', 'childrenList')
            setSelectKeys(newArray,'newArray')
        }else{
            setSelectKeys([])
        }
    }, [value, options])

    const displayRender = (label, selectedOptions) => {
        const data = selectedOptions.slice(-1)
        if(data.length === 1){
            return data[0].title
        }else{
            return null
        }
    }

    const cascaderOnChange = (value, selectedOptions) => {
        const data = selectedOptions.slice(-1).map(item => {
            return {
                id: item.id,
                name: item.title
            }
        })
        onChange?.(data)
    }

    return (
        <Cascader
            value={selectKeys}
            options={options}
            displayRender={displayRender}
            onChange={cascaderOnChange}
            {...other}
        />
    )
}

export default CascaderTheme
