import React, { Component } from "react";
import { Form, Modal } from "antd";
import { AuthButton } from "@/components";

export default class DelUser extends Component {
  state = {
    visible: this.props.visibleDel,
    loading: false,
    obj: this.props.data,
    name: "",
    id: null,
  };

  formRef = React.createRef();
  componentDidMount() {
    this.props.onRef(this);
  }

  delShow = (data) => {
    this.setState({
      visible: true,
      name: data.username || data.name,
      id: data.id,
      obj: data,
    });
  };
  backToEdit = () => {
    this.hide();
    this.props.handleShowEdit("edit", this.state.obj);
  };
  hide = () => {
    this.setState({ visible: false });
  };

  success = () => {
    this.hide();
  };
  confirmDelete = () => {
    const { id } = this.state;
    this.requestDelete({ id });
  };
  requestDelete = (params) => {
    this.props.deleteUser(params, this.success);
  };
 render() {
    const username = this.state.name;
    return (
      <Modal
        className="medium-form-dialog"
        width="550px"
        destroyOnClose
        centered
        visible={this.state.visible}
        confirmLoading={this.state.loading}
        footer={null}
        onCancel={this.backToEdit}
      >
        <div className="dialog-prompt-title mg-b-68">
          是否确认删除用户<span>{username}</span>
        </div>
        <div className="dialog-content">
          <div>
            <Form.Item>
              <AuthButton
                onClick={this.confirmDelete}
                type="primary"
                htmlType="submit"
                className=""
              >
                确认
              </AuthButton>
              <AuthButton
                className=" mg-l-20"
                htmlType="button"
                onClick={this.backToEdit}
              >
                取消
              </AuthButton>
            </Form.Item>
          </div>
        </div>
      </Modal>
    );
  };
}
