import { Component } from 'react';
import { Iconfont } from "@/components";
import { dataType } from "@vars/views/dashboard_vars";
import { Spin } from "antd";

class Stats extends Component {
    title = () => {
        return <div className="board-title mg-b-20">{dataType.title}</div>
    }
    RenderStatisticalData = () => {
        return dataType.jobStatistics?.map((item, index) => (
            <div key={index} className={`between ${index === 0 ? 'mg-b-22' : ''}`}>
                {this.eachDataInfo(item)}
            </div>
        ))
    };

    eachDataInfo = (data) => {
        return (
            data.info?.map((item, index) => (
                <div className={`amp-flex-row-start w-176`} key={index}>
                    <Iconfont type={item.icon} className="fz-32" />
                    <div className="in-block mg-l-9 ">
                        <div className="board-number">{this.getNumber(item)}</div>
                        <div className="col-48 fz-14">{item.name}</div>
                    </div>
                </div>
            ))
        )
    }
    getNumber = (item) => {
        const { statisticalData } = this.props;
        switch (item.code) {
            case 1:
                return statisticalData?.task_todo;
            case 2:
                return statisticalData?.task_in_process;
            case 3:
                return statisticalData?.project_in_process;
            case 4:
                return statisticalData?.average_time_tasks_stayed;
            case 5:
                return statisticalData?.task_participated;
            case 6:
                return statisticalData?.project_participated;
            default:
                return
        }

    }
    render() {
        const { spinning } = this.props;
        return (
            <div className="board-border">
                {this.title()}
                <div className="mg-b-28">
                    {
                        spinning ? (
                            <Spin spinning={spinning} size="large" />
                        ) : (
                            this.RenderStatisticalData()
                        )
                    }
                </div>
            </div>
        );
    }

}
export default Stats;
