import input from "./input_check_util";

export const checkIsNumber = (rule, value) => {
  const res = input.checkIsNumber(value);
  if (res !== -1) {
    return Promise.reject(input.checkFormErrorMsg(res));
  }
  return Promise.resolve();
};

export const checkEmail = (rule, value) => {
  const resEmail = input.checkEmail(value);
  if (resEmail !== -1) {
    return Promise.reject(input.checkFormErrorMsg(resEmail));
  }
  return Promise.resolve();
};

export const checkCompanyEmail = (rule, value) => {
  const resEmail = input.checkCompanyEmail(value);
  if (resEmail !== -1) {
    return Promise.reject(input.checkFormErrorMsg(resEmail));
  }
  return Promise.resolve();
};

export const checkPassword = (rule, value) => {
  const resPassword = input.checkPassword(value);
  if (resPassword !== -1) {
    return Promise.reject(input.checkFormErrorMsg(resPassword));
  }
  return Promise.resolve();
};

export const checkIncludeEmoji = (rule, value) => {
  const res = input.checkIncludeEmoji(value);
  const resSpace = input.checkIsSpace(value);
  if (res !== -1 || resSpace !== -1) {
    let error = res !== -1 ? res : resSpace;
    return Promise.reject(input.checkFormErrorMsg(error));
  }
  return Promise.resolve();
};
