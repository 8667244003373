import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal } from "antd";
import {Iconfont} from '@components'
import { SingleWaterFall } from "./waterfall_preview_render"
@inject("taskStore")
@observer
class WaterfallPreview extends React.Component {
  get store() {
    return this.props.taskStore;
  }

  componentWillUnmount() {
    this.store.hideWaterfall();
  }

  render() {
    const { waterfallVisible, waterfallSource } = this.store;
    const waterFallList = waterfallSource.length !== 0 &&  (
      waterfallSource.map((item, index) => {
        return (
          <div
            key={index}
            className="waterfall-preview-box"
          >
            {item.map(it => {
              return (it.aspect_ratio !== "0" ?
                (<SingleWaterFall {...it} key={it.task_id} />)
                : null
              )
            })}
          </div>
        )
      })
    )
    return (
      <Modal
        destroyOnClose
        footer={null}
        visible={waterfallVisible}
        onCancel={() => this.store.hideWaterfall()}
        wrapClassName="waterfall-dialog"
        width="100vw"
        style={{
            top: "0",
            overflow:"hidden",
            position: "absolute",
            height: "100%",
          }}
        closeIcon={<Iconfont type="icon-close-white" className="icon-20"
          style={{
            width: "1.134vw",
            height: "1.134vw",
            lineHeight: "1.134vw",
            right: "1.83vw",
            top: "5.579vw",
            position: "absolute",
          }}
        />}
      >
        <div className="waterfall-preview-image">
          {waterFallList}
        </div>
      </Modal>
    );
  }
}
export default WaterfallPreview;
