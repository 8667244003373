import { assetsAPI } from "@/api";
import { REQUEST_ASSETS, RECEIVE_ASSETS } from "../action_consts";

export const requestAssets = (params) => ({
  type: REQUEST_ASSETS,
  params,
});

export const receiveAssets = (params, assets) => ({
  type: RECEIVE_ASSETS,
  params,
  assets,
});

export const fetchAssets = (params, success = () => {}, error = () => {}) => (
  dispatch
) => {
  dispatch(requestAssets(params));
  assetsAPI.fetchAssets(params).then((res) => {
    if (res.code === 1000) {
      success();
      return dispatch(receiveAssets(params, res.data));
    } else {
      error();
    }
  });
};
