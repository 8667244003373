import { AuthButton } from "@/components";

/**
 * 任务包放弃相关按钮
 * @returns
 */
export const AbandonButton = (props) => {
    const { handleCancel } = props;

    return (
        <div className="amps-create-tasks-button">
            <div className="bottom-button  ">
                <AuthButton
                    onClick={() => {
                        props.taskPlanStore.setTitle(9);
                    }}
                    className="mg-r-20"
                >
                    放弃任务包
                </AuthButton>
                <AuthButton
                    onClick={() => {
                        handleCancel()
                    }}
                >
                    取消
                </AuthButton>
            </div>
        </div>
    );
};
