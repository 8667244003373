import React, { Component } from "react";
import QRCode from "qrcode.react";
import env from "env";

class WechatCode extends Component {
  get finalUrl() {
    const appid = env.wechatID;
    const redirect = this.urlEncode(
      env.serveUrls + "wechat/binding"
    );
    const token = localStorage.getItem("token");
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_userinfo&state=${token}#wechat_redirect`;
  }

  urlEncode = (str) =>{
    str = (str + "").toString();
    return encodeURIComponent(str)
      .replace(/!/g, "%21")
      .replace(/'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\*/g, "%2A")
      .replace(/%20/g, "+");
  }
  render() {
    return (
      <div>
        <QRCode
          value={this.finalUrl}
          size={200}
          imageSettings={{
            src: process.env.PUBLIC_URL + "/images/logo/qr-logo.png",
            width: 50,
            height: 50,
          }}
        />
      </div>
    );
  }
}

export default WechatCode;
