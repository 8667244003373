import React, { useEffect, useState, useRef } from 'react'
import { Button, Input, Form } from 'antd';
import {
    CaretLeftOutlined,
    CaretRightOutlined
} from '@ant-design/icons';
const labelValues = [
    3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30
]
const TaskScoreEdit = (props) => {
    const { value, onChange, placeholder, min = 1, max, step = 1 } = props
    const firstLoad = useRef(true)
    const [count, setCount] = useState(0)
    const [validate, setValidate] = useState({
        validateStatus: 'validating',
        help: ''
    })

    useEffect(() => {
        setCount(parseInt(value) || 0)
        if (firstLoad.current) {
            firstLoad.current = false
            return
        } else {
            setValidate({
                validateStatus: value ? 'success' : 'error',
                help: value ? '' : placeholder
            })
        }
    }, [value])


    /**
     * 输入改变，校验输入格式
     */
    const inputChange = (event) => {
        let val = event.target.value.replace(/\D/g, '')
        if (val) {
            val = parseInt(val) >= min ? parseInt(val) : min
            val = parseInt(val) <= max ? parseInt(val) : max
        }
        onChange?.(val)
    }
    /**
     * 按钮点击增加减少分数
     * @param {string} type off 减少 on增加
     */
    const btnClick = (type) => {
        if (!value) {
            onChange?.(min)
            return
        }
        const count = parseInt(value) || 0
        if (type === 'off') {
            //减减
            let result = count - step
            if (result < min) {
                result = max
            }
            onChange?.(result)
        } else if (type === 'on') {
            //加加
            let result = count + step
            if (result > max) {
                result = max
            }
            onChange?.(result)
        }
    }

    return (
        <div className="amp-drawer-score">
            <div className="amp-drawer-score-stepper">
                <Button
                    className="amp-score-button"
                    icon={<CaretLeftOutlined />}
                    disabled={count <= min}
                    onClick={() => btnClick('off')}
                />
                <Input
                    className="amp-score-input"
                    placeholder={`${min}～${max}`}
                    value={value}
                    onChange={inputChange}
                />
                <Button
                    className="amp-score-button"
                    icon={<CaretRightOutlined />}
                    disabled={count >= max}
                    onClick={() => btnClick('on')}
                />
            </div>

            <div className="amp-drawer-score-label">
                {
                    labelValues.map(item => (
                        <div
                            key={`label${item}`}
                            className={`amp-drawer-score-label-item
                            ${count === item ? 'current' : ''}
                            `}
                            onClick={() => onChange?.(item)}
                        >
                            {item}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TaskScoreEdit
