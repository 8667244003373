import React, { Component } from "react";
import arrayMove from "array-move";
import {
    SortableTable,
    AuthIcon,
    $success,
    $error,
    AddEditConfigDialog,
} from "@/components";
import { projectSettingsAPI } from "@/api";
import SysNotificationFn from "@/api/grpcApi/system_settings/project_manage.js"
const tab = { key: 4, title: "资产类型" };

class AssetType extends Component {
    deleteRef = React.createRef();
    addEditRef = React.createRef();

    state = {
        list: [],
        total: null,
        isMoved: false,
        loading: false,
        editingKey: null,
    };

    columns = [
        {
            title: "资产类型",
            dataIndex: "value",
            width: "30%",
        },
        {
            title: "简称",
            dataIndex: "code",
        },
    ];

    componentDidMount() {
        this.fetchCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        const { tab } = this.props;
        const { isMoved } = this.state;
        if (prevProps.tab !== tab) {
            this.fetchCategories();
        }
        if (isMoved && !prevState.isMoved) {
            this.sortRequest();
        }
    }

    fetchCategories = () => {
        this.setState({ loading: true });
        SysNotificationFn().fetchAssetTypeList().then((res) => {
            const { listList } = res;
            this.setState({ list: listList, total: listList?.length, loading: false });
        }).catch((err) => {
            throw new Error(err.message);
        });
    };

    sortRequest = () => {
        const { list } = this.state;
        const { key } = tab;
        const sort = list?.map((item) => item.id);
        const params = { type: key, sort };
        this.setState({ loading: true });
        projectSettingsAPI.sortAssetTypes(params).then((res) => {
            if (res.code === 1000) {
                this.setState({ isMoved: false });
                $success(res.msg);
            } else {
                if (res.code !== 1002) $error(res.msg);
            }
            this.setState({ loading: false });
        });
    };

    //   拖动排序(3.4.0起停用，数据层面直接通过后端从DAC获取，AMP不再单独维护)
    onSortEnd = ({ oldIndex, newIndex }) => {
        const { list } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([...list], oldIndex, newIndex).filter(
                (el) => !!el
            );
            this.setState({ list: newData, isMoved: true });
        }
    };

    showDelete = (record) => {
        this.deleteRef.current.show(record, tab.title, record.value);
    };
    showAdd = () => {
        this.addEditRef.current.show(tab);
    };

    showEdit = (record) => {
        this.addEditRef.current.show(tab, record);
    };


    renderOperations = (record) => (
        <>
            <AuthIcon
                type="icon-edit-item"
                className="icon-20 mg-r-32"
                onClick={() => this.showEdit(record)}
                permission="asset_type_update"
            />
            <AuthIcon
                type="icon-trash"
                className="icon-20"
                onClick={() => this.showDelete(record)}
                permission="asset_type_delete"
            />
        </>
    );

    renderTable = () => {
        const { list, total, loading } = this.state;

        return total > 0 || loading ? (
            <SortableTable
                columns={this.columns}
                dataSource={list}
                // onSortEnd={this.onSortEnd} // 拖动排序
                loading={loading}
                height="80vh"
                permission="asset_type_list"
                unSortable={true}
            />
        ) : (
            <div
                style={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                暂时没有{tab.title}
            </div>
        );
    };

    render() {
        return (
            <>
                <AddEditConfigDialog
                    ref={this.addEditRef}
                    updateList={this.fetchCategories}
                    createRequest={projectSettingsAPI.createAssetType}
                />
                <div>
                    {this.renderTable()}
                </div>
            </>
        );
    }
}

export default AssetType;
