import React, { Component } from "react";
import { Table } from "antd";
import { TaskDrawer, ShootingPlanDrawer } from "@components";
import {
    taskDashColumns,
    projectDashColumns,
    centerStyle
} from "@vars/views/dashboard_vars";
import { connectUserSession } from "@gUtils";
import { observer, inject } from "mobx-react";
import { TodosTab, TodosFilter } from "./";
import { toJS } from 'mobx'
import { Spin } from "antd";
@inject("todosStore")
@observer
class Todos extends Component {
    get store() {
        return this.props.todosStore;
    }

    componentDidMount() {
        // 工作中心，我的任务，我的项目 初始化获取表格数据 存储在todosStore
        // 修改todosStore数据，更新页面渲染
        this.store.fetchTaskTodos();
        this.store.fetchProjectTodos();
    }
    getUrl = ({ project_id, task_id, plan_id, type = "task" }, base) => {
        let url;
        let baseUrl = base || `/projects/${project_id}`;
        switch (type) {
            case "project":
                url = `/projects/${project_id}`;
                break;
            case "plan":
                url = `${baseUrl}?shootingPlanId=${plan_id}`;
                break;
            case "task":
                url = `${baseUrl}?task_flow?taskId=${task_id}`;
                break;
            default:
                url = "";
                break;
        }
        return url;
    };

    renderAction = (record, index) => {
        const url = this.getUrl(record, "/dashboard");
        const onClick = (e) => {
            e.stopPropagation();
            this.store.updateProjectId(record.project_id);
            this.props.history.push(url);
        };
        const finalUrl = this.getUrl(record);
        const rowClick = (e) => {
            e.stopPropagation();
            this.props.history.push(finalUrl);
        }
        return (
            <>
                <div className="primary-color" onClick={onClick} style={centerStyle}>
                    处理
                </div>
                <div className="primary-color mg-l-50" onClick={rowClick} style={centerStyle}>
                    前往
                </div>
            </>
        );
    };

    render() {
        const { currentTodos, currentTab, IsLoading } = this.store;
        const action = {
            title: "操作",
            key: "operation",
            render: this.renderAction,
        };
        const columnConfigs = currentTab === 'task' ? taskDashColumns : projectDashColumns
        const finalColumns = [...columnConfigs, action];
        return (
            <>
                <div>
                    <TodosTab />
                    <TodosFilter />
                    <Table
                        className="matter-onlyHeader"
                        pagination={false}
                        rowKey={(record) => `${record.project_id} ${record.task_id || record.plan_id}`}
                        columns={finalColumns}
                        scroll={{ y: "52vh" }}
                    />
                    {
                        IsLoading ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: "30px",
                                }}
                            >
                                <Spin size="large" />
                            </div>
                        ) : (
                            <Table
                                className="matter"
                                pagination={false}
                                rowKey={(record) => `${record.project_id} ${record.task_id || record.plan_id} ${Math.random()}`}
                                columns={finalColumns}
                                dataSource={toJS(currentTodos)}
                                scroll={{ y: "52vh" }}
                                showHeader={false}
                            />
                        )
                    }
                </div>
                <TaskDrawer />
                <ShootingPlanDrawer />
            </>
        );
    }
}

export default connectUserSession(Todos);
