import * as React from "react";
import { observer, inject } from "mobx-react";
import { Form, Input } from "antd";
import { RangePicker } from "@components";
import { RangeInput, ReviewFormSelect } from "../0_components";
import { withRouter } from "react-router";
import { reviewTypes, reviewRes, detailFilters } from "@vars/views/stats_vars";
import { artConfigs } from "@vars/views/stats_vars";
import moment from "moment";
@withRouter
@inject("reviewStore", "statsStore")
@observer
class ReviewFilter extends React.Component {
  formRef = React.createRef();
  get store() {
    return this.props.reviewStore;
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  get paramFilter() {
    const { search } = this.props.location;
    const filter = 
      search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
    return filter || {};
  }
  get configs() {
    return this.statType === "review" && artConfigs
  }

  componentDidMount() {
    this.props.setDefaultdate();
  }

  componentWillUnmount() {
    this.store.clear();
  }

  handleFormChange = (_, allValues) => {
    this.store.updateFilter(allValues);
  };

  clear = () => {
    this.store.updateDate(null)
    this.formRef.current.resetFields();
    this.store.clear();
    const dom = document.getElementById("approveFilter");
    const list = dom.getElementsByTagName("input");
    list[0].value = "";
    list[1].value = "";
    this.props.setupDate();
    this.props.history.go(0);
  };

  renderFilters = () => {
    const { tab } = this.store;
    const filterRender =
      tab === "user" ? this.configs.userFilters : this.configs.allFilters;
    return (
      <>
        {this.renderBasics()}
        {filterRender?.map(({ name, label }) => (
          <RangeInput name={name} label={label} key={name} />
        ))}
      </>
    );
  };

  renderInput = ({ name, label }) => {
    return (
      <div className="align-center mg-b-20 mg-r-14" key={name}>
        <Form.Item name={name} key={name}>
          <Input
            className="filter-input"
            placeholder={`检索${label}`}
            allowClear
            maxLength={ name === "project_name" ? 20 : 12}
          />
        </Form.Item>
      </div>
    );
  };

  renderSelect = ({ name, label }) => {
    let options;
    const isUser = name === "user_id";
    if (name === "review_type") {
      options = reviewTypes;
    } else if (name === "operator_id") {
      options = this.store.uniqueFunc([...this.store.artsReviewUsers, ...this.store.resReviewUsers]).map((person) => ({
        code: person.user_id,
        label: person.name,
      }));
    } else if (name === "review_res") {
      options = reviewRes
    } else if (isUser) {
      options = this.store.relatedUsers?.map((person) => ({
        code: person.user_id,
        label: person.name,
      }));
    }
    return (
      <ReviewFormSelect key={name} name={name} label={label} options={options} />
    );
  };

  renderOthers = () => {
    return detailFilters.review?.map((item) => {
      let output;
      switch (item.type) {
        case "range":
          output = <RangeInput {...item} key={item.name} />;
          break;
        case "input":
          output = this.renderInput(item);
          break;
        case "select":
          output = this.renderSelect(item);
          break;
        default:
          output = "";
          break;
      }
      return output;
    });
  };
  randerDate = () => {
    return (
      <div
        className="align-center"
        id="approveFilter"
        style={{ marginTop: "-20px", marginRight: "31px" }}
      >
        <RangePicker
          placeholder={["选择时间"]}
          picker="date"
          format="YYYY-MM-DD"
          allowClear
          onChange={(date) => {
            if (date) {
              this.store.updateDate(date);
            } else {
              this.props.setDefaultdate();
            }
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="details mg-t-12 mg-b-10">
        <Form
          ref={this.formRef}
          onValuesChange={this.handleFormChange}
          layout="inline"
          style={{ width: "90%" }}
        >
          {this.randerDate()}
          {this.renderOthers()}
          <div className="reset-filter" onClick={this.clear}>
            <span>重置</span>
          </div>
        </Form>
      </div>
    );
  }
}
export default ReviewFilter;
