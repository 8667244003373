import React, { memo, useState, useEffect, useCallback, useMemo } from 'react'
import PreviewImage from '@components/image/preview_image';
import { Spin, Upload } from 'antd';
import { $error, Iconfont } from '@/components';
import { sessionAPI, configsAPI } from '@api';
import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'

import './index.less'

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const ImageUploadCheck = (props) => {
    const {
        value,
        onChange,
        beforeUpload,
        maxCount = 10,
        fileSize = 32,
        iconType,
        iconTitle,
        accept = ".png,.jpeg,.jpg",
        width,
        height = width,
        disabled,
        purpose = "godown",
        hidenMain,
    } = props
    const [fileList, setFileList] = useState(value || []);
    useEffect(() => {
        if (Array.isArray(value)) {
            const newValue = value.map(item => {
                return {
                    preview: item.file_url,
                    status: item.status ? item.status : 'done',
                    ...item,
                }
            })
            setFileList(newValue);
        } else {
            setFileList([]);
        }
    }, [value]);

    const upLoadStyle = {
        height: height ?? '',
        width: width ?? ''
    }

    const defaultBeforeUpload = (file) => {
        const isLt20M = file.size / 1024 / 1024 < fileSize
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isLt20M) {
            $error(`图片大小不能超过${fileSize}M, 请重新选择～`)
            file.status = "error"
        }
        if (!isJpgOrPng) {
            $error("仅支持jpg或png格式图片，请重新选择～")
            file.status = "error"
        }
        return (isLt20M && isJpgOrPng)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        PreviewImage.open({
            images: [file.url || file.preview],
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };

    const handleChange = async ({ file, fileList }) => {
        const newFileList = fileList.filter((item) => item.status !== "error")
        for (const item of newFileList) {
            if (!item.response && !item.file_url && item.originFileObj) {
                item.file_url = await getBase64(item.originFileObj);
            }
        }
        setFileList(newFileList)
        if (file.status === 'done') {
            const filtered = fileList.filter((item) => item.status === "done")
            let hasMain = false
            const fileData = filtered.map(item => {
                if (item.review_image_type === 'main') {
                    hasMain = true
                }
                return { ...item, ...item.response }
            })
            if (!hasMain && fileData.length > 0 && !hidenMain) {
                fileData[0].review_image_type = 'main'
            }
            const sortFiles = fileListSort(fileData)
            onChange?.(sortFiles)
        }
    };

    const onRemove = (file) => {
        if (disabled) return
        const index = fileList.indexOf(file)
        if (index < 0) return
        const newFileList = [...fileList]
        newFileList.splice(index, 1)
        handleChange({ file, fileList: newFileList })
    };

    const setMainImage = (file) => {
        if (disabled) return
        if (file.status !== "done") return
        const newFileList = fileList.map(item => {
            if (item.uid === file.uid) {
                item.review_image_type = 'main'
            } else {
                item.review_image_type = ''
            }
            return { ...item }
        })
        const sortFiles = fileListSort(newFileList)
        onChange?.(sortFiles)
    }

    // 排序图片，主图在第一张
    const fileListSort = (files) => {
        if (!Array.isArray(files)) return []
        if (files.length === 0) return []
        if (hidenMain) return files
        let splitIndex = 0
        let newList = files.map((item, index) => {
            if (item.review_image_type === 'main') {
                splitIndex = index
            }
            return item
        })
        if (splitIndex !== 0) {
            const splitData = newList[splitIndex]
            newList.splice(splitIndex, 1)
            newList.unshift(splitData)
        }
        return newList
    }

    const handleCustomRequest = async ({ file, onSuccess, onError }) => {
        let formdata = new FormData()
        formdata.append('images', file)
        formdata.append('purpose', purpose)
        const res = await configsAPI.uploadImages(formdata)
        if (res.code === 1000) {
            onSuccess(res.data?.files?.[0], file)
        } else {
            onError(res)
            $error(res?.msg || '上传失败！')
        }
    }

    const uploadButton = (
        <div className="amp-upload-custom-upload-button" style={upLoadStyle}>
            <Iconfont type={iconType ? iconType : "icon-a-14-zengjia"} />
            <div style={{ marginTop: 8, color: "#BFC5D1" }}>
                {
                    iconTitle ? iconTitle : "上传图片"
                }
            </div>
        </div>
    );
    const handleClickPreBox = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const getMainType = (item) => {
        const error = item.status === 'error'
        const uploading = item.status === 'uploading'
        const main = item.review_image_type === 'main'
        if (error) {
            return <div className="amp-upload-preview-error"> 图片上传失败 </div>
        }
        if (uploading) {
            return <div className="amp-upload-preview-uploading">
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
            </div>
        }
        if (disabled && !main) {
            return null
        }

        if (hidenMain) return null

        return (
            <div
                className={`amp-upload-preview-main ${main ? 'current' : ''}`}
                onClick={() => setMainImage(item)}
            >
                <Iconfont
                    className="mg-r-3"
                    type={main ? "icon-zhutuxuanzhong" : "icon-zhutuweixuanzhong"}
                />
                主图
            </div>
        )
    }

    const fileListPreview = useMemo(
        () => {
            if (!fileList) return null
            return fileList?.map((item) => {
                const previewImage = item.file_url
                const error = item.status === 'error'
                const hoverPreview = item.status === "uploading" ? "" : "amp-upload-hover-preview"
                return <div
                    key={item.uid}
                    className={`amp-upload-custom-upload-item
                    ${error ? 'error' : ''}`}
                    onClick={handleClickPreBox}
                    style={upLoadStyle}
                >
                    <div className={`amp-upload-preview ${hoverPreview}`}>

                        <div className="amp-upload-preview-img" style={{ backgroundImage: `url(${previewImage})` }}></div>
                        <div className="amp-upload-preview-mask">
                            <a
                                className="amp-upload-preview-mask-btn"
                                onClick={() => handlePreview(item)}>
                                <EyeOutlined />
                            </a>
                            {
                                !disabled ? <a
                                    className="amp-upload-preview-mask-btn"
                                    onClick={() => onRemove(item)}
                                >
                                    <DeleteOutlined />
                                </a> : null
                            }

                        </div>
                        {
                            getMainType(item)
                        }
                    </div>
                </div>
            })
        }, [fileList])


    return (
        <div>
            <Upload
                className="amp-upload-custom-sign"
                accept={accept}
                fileList={fileList}
                // listType="picture-card"
                onChange={handleChange}
                beforeUpload={beforeUpload ? beforeUpload : defaultBeforeUpload}
                customRequest={handleCustomRequest}
                showUploadList={false}
                maxCount={maxCount}
                disabled={disabled}
            >
                <div className="amp-upload-custom-preview">
                    {
                        fileListPreview
                    }
                    {fileList?.length >= maxCount ? null : uploadButton}
                </div>
            </Upload>
        </div>
    )
}

export default memo(ImageUploadCheck)
