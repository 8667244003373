import { LabeledInput, AuthButton } from "@/components";
import { useState } from "react";
const accountInfo = [
  { label: "账户名称", key: "username", onChange: "onNameChange" },
  { label: "账户密码", key: "password", onChange: "" },
  { label: "角色名称", key: "role", onChange: "" },
];
const userInfo = [
  { label: "用户名称", key: "name" },
  { label: "用户组织", key: "group" },
  { label: "用户邮箱", key: "email" },
];

const AccountName = (props) => {
  const { accountUpdate, currentUser } = props;
  const [editing, setEditing] = useState(false);
  const [username, setUsername] = useState(currentUser.username);
  const beginEdit = () => setEditing(true);
  const stopEdit = () => setEditing(false);
  const onChange = ({ target }) => setUsername(target.value);

  const handleSave = () => {
    accountUpdate({ username }, stopEdit);
  };

  const handleCancel = () => {
    setUsername(currentUser.username);
    stopEdit();
  };

  const normalButton = (
    <AuthButton type="link" className="mg-t-17" onClick={beginEdit}>
      更改名称
    </AuthButton>
  );

  const editingButton = (
    <div className="display-flex mg-t-17">
      <AuthButton type="link" className="mg-r-17" onClick={handleSave}>
        保存
      </AuthButton>
      <AuthButton type="link" onClick={handleCancel}>
        取消
      </AuthButton>
    </div>
  );

  return (
    <div className="display-flex algin-center">
      <LabeledInput
        label={accountInfo[0].label}
        value={username}
        disabled={!editing}
        onChange={onChange}
        onPressEnter={handleSave}
      />
      {!editing ? normalButton : editingButton}
    </div>
  );
};

const AccountPassword = (props) => {
  const { showSendCode } = props;
  return (
    <div className="display-flex algin-center">
      <LabeledInput label={accountInfo[1].label} value="********" disabled />
      <AuthButton type="link" className="mg-t-17" onClick={showSendCode}>
        更改密码
      </AuthButton>
    </div>
  );
};
const AccountRole = ({ currentUser }) => {
  const { role } = currentUser;
  return (
    <LabeledInput label={accountInfo[2].label} value={role && role.name ? role.name : ""} disabled />
  );
};

const AccountPart = (props) => (
  <div className="profile-info-section">
    <AccountName {...props} />
    <AccountRole {...props} />
    <AccountPassword {...props} />
  </div>
);

const UserPart = ({ currentUser }) =>
  userInfo?.map((item, idx) => {
    const value =
      item.key === "group" && currentUser.group ? currentUser[item.key].name : currentUser[item.key];
    return <LabeledInput key={idx} label={item.label} value={value} disabled />;
  });

export { UserPart, AccountPart };
