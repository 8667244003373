// 选题性质
export const topicType = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 1,
        label: '内部'
    },
    {
        value: 2,
        label: '商务'
    }
];

// 状态
export const topicStatus = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 1,
        label: '创建/编辑选题'
    }
];
