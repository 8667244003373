import { Typography } from '@components';
import React from 'react';
import { useAccess } from '@hooks';
import Icon from '@components/icon';

/**
 * @name: PoolTitle.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 制作池title
 */

export const PoolTitle = function(props){
    const {tmp, setTmp} = props
    const isProduceAllot = useAccess('produce_allot'); // 分配权限
    const isProduceTake = useAccess('produce_take'); // 领取权限
    const isProduceSetting = useAccess('produce_setting'); // 编辑权限
    const isAllotOrTake = useAccess(['produce_allot', 'produce_take'], {whereType: 'OR'}); // 分配或领取权限
    return <div className={'flex-align-center amp-pool-title'}>
        <Typography className ={"pl-1 pool-title"} variant={'h3'}>
            { tmp === 3 ? "管理制作任务" : "制作池待选" }
        </Typography>
        {
            tmp === 3 ? (
                <>
                    {isAllotOrTake && <Icon size={18} className={'ml-1'} onClick={() => setTmp(isProduceAllot?1:isProduceTake?2:0)} type='icon-a-20-shezhi-01' />}
                </>
            ) : (
                <>
                    {isProduceSetting && <Icon size={18} className={'ml-1'} onClick={() => setTmp(3)} type='icon-a-20-shezhi-01' />}
                </>
            )
        }
    </div>
}
