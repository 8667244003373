import { connect } from "react-redux";
import { fetchProjects } from "@actions/1_entities/projects_actions";
import { projectColumns } from "@vars/views/projects_vars";
import { useHistory } from "react-router";
import { Table } from "@/components";


const ProjectsTable = (props) => {
  const history = useHistory()
  const rowClick = (record, index) => {
    history.push(`/projects/${record.id}`);
  };
    return (
        <Table
          rowClick={rowClick}
          columnsConfig={projectColumns}
          permission="project_list"
          {...props}
          className="project-table"
        />
    );
};

const mapStateToProps = (state) => ({
  entity: state.entities.projects,
  currentPage: state.pagination.projects.currentPage,
  pages: state.pagination.projects.pages,
  total: state.pagination.projects.total,
  filter: state.pagination.projects.filter
});
const mapDispatchToProps = (dispatch) => ({
  fetchItems: (params, cb) => dispatch(fetchProjects(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable);
