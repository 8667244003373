import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Input, Form } from "antd";
import { Iconfont } from "@components";
import { AssetImage } from "..";

@inject("taskGroupsStore")
@observer
class SingleGroupItem extends Component {
    formRef = React.createRef();
    state = {
        isEditing: false,
    };

    get store() {
        return this.props.taskGroupsStore;
    }
    get index() {
        return this.props.index;
    }
    get groupItem() {
        return this.store.groups[this.index];
    }

    placeholderSrc = () => {
        if (this.props.activeType === '2') return process.env.PUBLIC_URL + "/images/other/drag_task_placeholder.png"
        return process.env.PUBLIC_URL + "/images/other/drag_placeholder.png"
    }

    startEdit = () => {
        this.setState({ isEditing: true });
    };
    finishEdit = () => {
        this.setState({ isEditing: false });
    };

    handleSaveName = () => {
        this.formRef.current
            .validateFields()
            .then((res) => {
                this.store.updateGroupName(this.index, res.group_name);
            })
            .then(() => this.finishEdit());
    };

    handleCancel = () => {
        this.finishEdit();
    };

    handleDropChange = (tasks) => {
        this.store.updateGroupTasks(this.index, tasks);
    };

    handleRemove = (tag) => {
        let tasks = this.groupItem.tasks.filter((item) => item.id !== tag.id);
        this.store.updateGroupTasks(this.index, tasks);
    };

    validateName = (_, value) => {
        const { groups } = this.store;
        const sameName = groups.some(
            (item, idx) => idx !== this.index && item.group_name === value
        );
        if (!value) return Promise.reject("组合名称不能为空");
        else if (sameName) return Promise.reject("组合名称已存在，请重新填写");
        return Promise.resolve();
    };

    renderDeleteBtn = () => (
        <div onClick={() => this.store.removeGroup(this.index)}>
            <Iconfont type="icon-trash" className="icon-20 cursor-pointer mg-t-8" />
        </div>
    );

    renderInput = () => {
        const { group_name } = this.groupItem;
        return (
            <Form ref={this.formRef} layout="inline">
                <Form.Item
                    name="group_name"
                    rules={[{ validator: this.validateName }]}
                    initialValue={group_name}
                >
                    <Input
                        className="input-sz-m"
                        placeholder="请输入组合名称"
                        maxLength={20}
                    />
                </Form.Item>
                <span
                    className="cursor-pointer mg-r-20 mg-t-8"
                    onClick={this.handleSaveName}
                >
                    保存
                </span>
                <span className="cursor-pointer mg-t-8" onClick={this.handleCancel}>
                    取消
                </span>
            </Form>
        );
    };

    renderName = () => {
        const { group_name } = this.groupItem;
        return (
            <div className="align-center">
                {group_name}
                <div className="mg-l-10" onClick={this.startEdit}>
                    <Iconfont type="icon-pencil" className="icon-16 cursor-pointer" />
                </div>
            </div>
        );
    };

    renderNameInput = () => {
        const { group_name } = this.groupItem;
        return group_name === "" || this.state.isEditing
            ? this.renderInput()
            : this.renderName();
    };
    render() {
        const { tasks } = this.groupItem;
        const GroupedArea = this.props.draggableGroup.addArea(
            `grouped-${this.index}`
        );

        return (
            <>
                <div className="space-between mg-t-20 single-group-name">
                    {this.renderNameInput()}
                    {this.renderDeleteBtn()}
                </div>
                <div className="single-drop-area">
                    <div
                        className={
                            tasks.length > 1 ? "drag-placeholder-none" : "drag-placeholder"
                        }
                    >
                        <img src={this.placeholderSrc()} alt="" />
                    </div>
                    <GroupedArea
                        tags={tasks}
                        render={({ tag }) =>
                            tag.id !== 0 ? (
                                <AssetImage
                                    tag={tag}
                                    allowRemove
                                    onRemove={() => this.handleRemove(tag)}
                                />
                            ) : (
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "0px",
                                        height: "120px",
                                    }}
                                ></div>
                            )
                        }
                        onChange={this.handleDropChange}
                    />
                </div>
            </>
        );
    }
}
export default SingleGroupItem;
