import React, { useState, useEffect, useMemo, memo } from 'react'
import { Select } from 'antd'
import ThemeMenu from './template/theme_menu'
import { clsx } from '@gUtils';

import './index.less'

const SelectTree = (props) => {
    const {
        value,
        onChange,
        className,
        options,
        ...other
    } = props

    const optionsList = useMemo(() => {
        return [
            {
                title: '0-0',
                key: '0-0',
                children: [
                    {
                        title: '0-0-0',
                        key: '0-0-0',
                        children: [
                            { title: '0-0-0-0', key: '0-0-0-0' },
                            { title: '0-0-0-1', key: '0-0-0-1' },
                            { title: '0-0-0-2', key: '0-0-0-2' },
                        ],
                    },
                    {
                        title: '0-0-1',
                        key: '0-0-1',
                        children: [
                            { title: '0-0-1-0', key: '0-0-1-0' },
                            { title: '0-0-1-1', key: '0-0-1-1' },
                            { title: '0-0-1-2', key: '0-0-1-2' },
                        ],
                    },
                    {
                        title: '0-0-2',
                        key: '0-0-2',
                    },
                ],
            },
            {
                title: '0-1',
                key: '0-1',
                children: [
                    { title: '0-1-0-0', key: '0-1-0-0' },
                    { title: '0-1-0-1', key: '0-1-0-1' },
                    { title: '0-1-0-2', key: '0-1-0-2' },
                ],
            },
            {
                title: '0-2',
                key: '0-2',
            },
        ]
    },[])

    const menuChange = (data) => {
        onChange?.(data)
    }

    return (
        <>
            <Select
                className={clsx(className, 'amp-select-theme')}
                {...other}
                mode="multiple"
                value={value}
                dropdownRender={() => (
                    <ThemeMenu
                        data={optionsList}
                        value={value}
                        onChange={menuChange}
                    />
                )}
                dropdownStyle={{
                }}
            >
            </Select>
        </>
    )
}

export default memo(SelectTree)
