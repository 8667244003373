import env from 'env';
/**
 * 根据环境设置AMP title
 */
const envTitle = {
    autopush: 'AMP.Development',
    local: 'AMP.Local',
    preprod: 'AMP.Pre',
    prod: 'AMP',
    staging: 'AMP.Test'
};
const updateTitle = () => {
    const title = envTitle[env.envName] ?? 'AMP';
    document.title = title;
};

export default updateTitle;
