import React, { Component } from 'react'
import { TaskCard } from '.'
import { Spin, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'

const initialState = {
    participants: [],
    uid_name: null,
    assignee_id: null,
    discard_type: null,
    asset_group: [],
    task_status: [],
    asset_type: []
}

@inject('tasksStore', 'configsStore')
@observer
class TaskList extends Component {
    get listCode () {
        return this.props.listCode
    }

    get listTypes () {
        return this.props.listTypes
    }

    get listInfo () {
        const { pages } = this.props
        return pages[this.listCode] ? pages[this.listCode] : {}
    }

    get listIds () {
        return this.listInfo ? this.listInfo.items : []
    }

    get listTotal () {
        const { total } = this.props
        return total[this.listCode]
    }

    get currentPage () {
        const { currentPages } = this.props
        return currentPages[this.listCode]
    }

    initRequest(type){
        this.requestTasks(type, (res) => {
            const {scrollHeight, clientHeight} = this.scrollRef
            if(scrollHeight<=clientHeight && this.listIds.length < this.listTotal){
                this.initRequest('scroll')
            }
        })
    }

    componentDidMount () {
        this.initRequest()
    }

    componentWillUnmount () {
        this.props.tasksStore.filter = initialState
    }

    scrollRef = null

    requestTasks (type = '', callback) {
        const { projectId } = this.props
        const {
            uid_name,
            participants,
            asset_group,
            asset_type,
            assignee_id,
            task_status,
            discard_type
        } = this.props.configsStore.paramsObj
        let page
        const { filter } = this.props.tasksStore
        page = type === 'scroll' ? this.currentPage + 1 : 1
        console.log('this.currentPage', this.currentPage)
        const params = {
            list_type: JSON.parse(this.listCode),
            page,
            page_size: 5,
            project_id: projectId,
            filter: {
                ...filter,
                uid_name,
                participants,
                asset_group,
                asset_type,
                assignee_id,
                discard_type,
                task_status
            }
        }
        this.props.fetchTasks(params, callback)
    }

    handleScroll = (e) => {
        let { target } = e
        const {
            scrollHeight,
            scrollTop,
            clientHeight
        } = target
        const atBottom = scrollHeight - scrollTop - 50 <= clientHeight
        if (atBottom && this.listIds.length < this.listTotal) {
            this.requestTasks('scroll')
        }
    }

    tasksRender = () => {
        const { tasks } = this.props
        return this.listIds
            ? this.listIds?.map((id) => (
                <TaskCard
                    {...tasks[id]}
                    key={id}
                    onClick={() => this.props.TaskItemClick(id)}
                />
            ))
            : ''
    }

    titleRender = () => {
        const { status_total } = this.props
        const currentStatuses = status_total[this.listCode]
            ? status_total[this.listCode]
            : []
        const label = Object.values(this.listTypes)
            .find((item) => {return item.code === this.listCode})?.label
        const statusDisplay = currentStatuses?.map((item, idx) => (
            <Tooltip
                placement='top'
                title={item.status_group}
                key={idx}
                overlayInnerStyle={{
                    backgroundColor: `${item.status_color}`
                }}
            >
                <div
                    className='display-flex align-center'
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <div
                        className='status-dot mg-r-4 mg-l-16'
                        style={{ background: item.status_color }}
                    ></div>
                    <div className='fw-400'>{item.total}</div>
                </div>
            </Tooltip>
        ))
        return (
            <div className='task-list-title'>
                <div>
                    <span className='mg-r-10'>{label}</span>
                    <span>{this.listTotal}</span>
                </div>
                <div className='display-flex'>{statusDisplay}</div>
            </div>
        )
    }

    render () {
        const { pages } = this.props
        const currentList = pages[this.listCode] ? pages[this.listCode] : {}
        const fetching = currentList ? currentList.fetching : false

        return (
            <div className='task-list'>
                {this.titleRender()}
                <div className='task-list-content' ref={(ref) => this.scrollRef = ref} onScroll={this.handleScroll}>
                    {this.tasksRender()}
                    <div className='spin-height'>
                        <Spin spinning={fetching} />
                    </div>
                </div>
            </div>
        )
    };
}

export default TaskList
