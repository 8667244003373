import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { assetsAPI } from '../../../api';
import FallItem from '@components/fall_item';
import * as React from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { $error } from '@components';

export function twoDime(originalArr, startIndex = 0, endIndex = 0, erWie = []) {
    const sum = originalArr.slice(startIndex, endIndex)
        .reduce((acc, i) => acc + i.ratio, 0);
    if (originalArr.length <= 1) {
        erWie.push(originalArr);
        return erWie;
    }
    const nextSum = sum + originalArr[endIndex].ratio;
    if (nextSum < 6) {
        if (endIndex + 1 < originalArr.length) {
            return twoDime(originalArr, startIndex, endIndex + 1, erWie);
        } else {
            erWie.push(originalArr.slice(startIndex, originalArr.length));
            return erWie;
        }
    } else if (nextSum === 6) {
        erWie.push(originalArr.slice(startIndex, endIndex + 1));
        return twoDime(originalArr.slice(endIndex + 1, originalArr.length),
            0, 0, erWie);
    } else {
        if (nextSum <= 8) {
            erWie.push(originalArr.slice(startIndex, endIndex + 1));
            return twoDime(
                originalArr.slice(endIndex + 1), 0, 0,
                erWie);
        } else {
            erWie.push(originalArr.slice(startIndex, endIndex));
            return twoDime(
                originalArr.slice(endIndex), 0, 0,
                erWie);
        }

    }
}

function Asset_water_fall_v2({ filter }) {
    const domRef = useRef();
    const [dom, setDom] = useState(null);
    useEffect(() => {
        setDom(domRef.current);
    }, []);
    const {
        list,
        loading,
        total
    } = useInfiniteScroll({
        anchorEl: dom,
        params: filter,
        scrollThreshold: 0.4,
        onError(error, onReload, currentPage, errorCount) {
            if (errorCount < 3) {
                onReload()
            } else {
                $error(error.message || '服务器错误')
            }
        },
        request: (page, params) => assetsAPI.fetchWaterfall({
            page,
            filter: params,
            page_size: 30
        })
            .then(res => ({
                list: res.data.list,
                total: res.data.total
            }))
    });

    const copeList = list.map(i => {
        let ratio = 1;
        if (i.aspect_ratio > 0 && i.aspect_ratio < 1.5) {
            ratio = 1;
        } else if (i.aspect_ratio >= 1.5 && i.aspect_ratio < 2.5) {
            ratio = 2;
        } else if (i.aspect_ratio >= 2.5 && i.aspect_ratio < 3.5) {
            ratio = 3;
        } else if (i.aspect_ratio >= 3.5 && i.aspect_ratio < 4.5) {
            ratio = 4;
        } else {
            ratio = 6;
        }
        return {
            id: i.id,
            uid: i.uid,
            url: i.previews?.[0],
            name: i.name,
            task_id: i.task_id,
            project_id: i.project_id,
            is_split:i.is_split,
            ratio
        };
    });
    const renderList = [].concat(...twoDime(copeList)
        .map((images, index, dimArr) => {
            if (!images.length) {
                return [];
            }
            const sum = images.reduce((acc, i) => acc + i.ratio, 0);
            return images.map(image => ({
                ...image,
                isDimEnd: dimArr.length - 1 === index,
                sum
            }));
        }));
    /**
     * 瀑布流单个详情重开页
     * @param {*} data
     */
    const handleItem = (data) => {
        const { task_id } = data
        window.open(`/assets/?task_flow?taskId=${task_id}`);
    }

    return <div
        ref={domRef}
        className={'amps_asset_water_fall_v2'}
    >
        {
            renderList.map((i) => <FallItem key={i.id} {...i} onClick={() => handleItem(i)} />)
        }
        {
            loading && renderList.length === 0 && <div className='amps_asset_water_fall_v2_init_loading'>
                <Spin size='large' />
            </div>
        }
        {
            !loading && renderList.length === 0 && <div className='amps_asset_water_fall_v2_init_loading'>
                暂无加载数据
            </div>
        }
        {
            loading && renderList.length && <div className='amps_asset_water_fall_v2_footer'><Spin size='large' /></div>
        }
        {
            renderList.length && total === renderList.length &&
            <div className={'amps_asset_water_fall_v2_footer'}><p className={'amps_asset_water_fall_v2_footer_text'}>加载到底了！</p></div>
        }
    </div>;
}

export default memo(Asset_water_fall_v2);
