import {usersAPI} from "@api";
import { $success, $error } from "@/components";
import { fetchUsers } from "./users_actions";
import { RECEIVE_USER, RECEIVE_USERS_ERR, DELETE_USER } from "@actions/action_consts";

const receiveUser = (data) => ({
  type: RECEIVE_USER,
  data,
});

const receiveUserErr = (err) => ({
  type: RECEIVE_USERS_ERR,
  err,
});

const removeUser = (id) => ({
  type: DELETE_USER,
  id
})

const getUser = (id, callback = () => {}) => (dispatch) => {
  usersAPI.getUser(id).then((res) => {
    if (res.code === 1000) {
      callback(res.data);
      return dispatch(receiveUser(res.data));
    } else {
      callback();
      return dispatch(receiveUserErr(res.msg));
    }
  });
};

const createUser = (params, callback = () => {}) => (dispatch) => {
  usersAPI.createUser(params).then((res) => {
    if (res.code === 1000) {
      callback();
      $success('新增成功');
      return dispatch(fetchUsers({ page: 1, page_size: 14 }));
    } else {
      callback(res.code);
      if (res.code !== 1002) $error(res.msg);
      return res.code;
    }
  });
};

const updateUser = (params, callback = () => {}) => (dispatch) => 
  usersAPI.updateUser(params).then((res) => {
    if (res.code === 1000) {
      callback();
      $success('修改成功');
      dispatch(receiveUser(res.data));
      return res.data
    } else {
      callback(res.code);
      if (res.code !== 1002) $error(res.msg);
      return res.code;
    }
  });

const deleteUser = (params, callback = () => {}) => (dispatch) => {
  usersAPI.delUser(params).then((res) => {
    if (res.code === 1000) {
      callback();
      $success(res.msg);
      dispatch(fetchUsers({ page: 1, page_size: 14 }));
      return dispatch(removeUser(params.id)) ;
    } else {
      callback();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUserErr(res.msg));
    }
  });
};

const unlockUser = (params, callback = () => {}) => (dispatch) => {
  usersAPI.unlockUser(params).then((res) => {
    if (res.code === 1000) {
      callback();
      $success(res.msg);
      return dispatch(fetchUsers({ page: 1, page_size: 14 }));
    } else {
      callback();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveUserErr(res.msg));
    }
  });
};

export { getUser, createUser, updateUser, deleteUser, unlockUser };
