import { action, flow, makeAutoObservable, observable } from 'mobx'
import moment from 'moment'
import { configsAPI } from '@api'
import autoSave from '../autosave'

class StatsStore {
  constructor() {
    makeAutoObservable(this);
    autoSave(this, "statsStore");
  }
  @observable statType = "art";
  @observable date = null;
  @observable dateType = 2;
  @observable relatedUsers=[]

  @action clearDate() {
    if (this.statType === "art") {
      this.date = moment().subtract(1, "months");
    } else {
      this.date = moment().subtract(1, "weeks");
    }
  }
  @action updateDate(val) {
    this.date = val;
  }
  @action changeWeek(val) {
    this.week = val;
  }
  @action updateDateType(val) {
    this.dateType = val;
  }
  @action updateStatType(type) {
    this.statType = type;
  }
  @action clear() {
    this.report = {};
  }

  fetchRelatedUsers = flow(function* (params) {
    try {
      const res = yield configsAPI.fetchRelatedUsers(params);
      if (res.code === 1000) {
        this.relatedUsers = res.data.list;
      }
    } catch (error) {}
  });
}

const statsStore = new StatsStore();
export default statsStore;
