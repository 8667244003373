import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Tooltip } from 'antd';
import { $error, AuthButton, DrawerFooter, Iconfont, Upload } from '@components';
import { bulkSetFormData, formatImageFiles } from '@gUtils';
import { withRouter } from 'react-router';
import { AssetComment, AssetID, AssetImageCount, AssetName } from './form_renders';
import MultipleComponents from './MultipleComponents.jsx';
import AssociatedTask from './associated_task/index';
import { projectsAPI } from '@api';

@withRouter
@inject('taskStore')
@observer
class TaskFormContent extends Component {
    formRef = React.createRef();
    uploadRef = React.createRef();
    state = {
        associatedPhotographers: 0,
        checkedTask: {}
    };

    updatePhotographers = (id) => {
        this.setState(() => {
            return {
                associatedPhotographers: id
            };
        });
    };

    updateTaskCheck = (checkedTask) => {
        this.setState({
            checkedTask
        });
    };

    get store() {
        return this.props.taskStore;
    }

    get formType() {
        const { search } = this.props.location;
        return search.split('?')[1];
    }

    get currentTask() {
        return this.props.taskStore.currentTask;
    }

    get projectId() {
        return parseInt(this.props.match.params.id);
    }

    get taskId() {
        return this.props.taskStore.currentTask.id;
    }

    componentDidMount() {
        this.store.fetchTaskConfigs();
        this.store.fetchPhotographers();
        this.taskId && this.store.fetchAssetsInfo(this.taskId);
        this.store.getReleatedAsset(this.projectId);
        this.store.isAssociated = false;
        this.store.isPlanningTask = false;

        if (this.formType === 'task_edit' && this.currentTask?.task_package_id) {
            this.formRef?.current?.setFieldsValue({
                'package_id': this.currentTask?.task_package_id
            });
        }
        if (this.formType === 'task_edit' && this.currentTask?.task_package_item) {
            this.store.selectedItem = this.currentTask?.task_package_item;
            this.store.associatedTaskID = this.currentTask?.task_package_item?.id;
        }
        if (this.formType === 'task_edit' && this.currentTask?.asset_name) {
            this.formRef?.current?.setFieldsValue({
                'asset_name': this.currentTask?.asset_name
            });
        }
    }

    componentWillUnmount() {
        this.store.setAssetCharacter(0);
        this.store.setAssetTitle('');
    }

    handleConfirm = async () => {
        const { pathname } = this.props.location;
        try {
            const formResult = await this.formRef.current.validateFields();
            if (formResult.package_type === 1) {
                // 策划任务
                if (!this.store.selectedItem?.id) {
                    throw new Error('请选择任务包下的任务');
                }
            } else {
                // 自建任务
                if (formResult.up_images.length < 1) {
                    throw new Error('请上传图片文件');
                }
                let formData = new FormData();
                formData.append('package_id', formResult.package_id);
                formData.append('material_type', formResult.material_type);
                formData.append('title', formResult.title);
                formData = formatImageFiles(formResult.up_images, formData);
                formData.append('type', 2);
                const creatTask = await projectsAPI.associatedTask(formData);
                if (creatTask.code !== 1000) {
                    throw new Error(creatTask.msg || '创建任务失败');
                }
                this.store.associatedTaskID = creatTask.data.id;
            }
            delete formResult.up_images;
            const taskResult = await this.handleSave(formResult);
            if (taskResult.code === 1000) {
                const updateType = formResult.package_type === 1 ? 'plan' : 'producer';
                this.props.updatePlanRelated(updateType);
                if (!pathname.includes('dashboard')) {
                    this.props.history.push(
                        `/projects/${this.projectId}?task_flow?taskId=${this.taskId}`
                    );
                }
            } else {
                throw new Error(taskResult.msg || '操作失败');
            }
        } catch (error) {
            $error(error.message);
        }

    };

    handleSave = (params) => {
        let formData = new FormData();
        if (this.taskId) {
            formData.append('task_id', this.taskId);
        } else {
            formData.append('project_id', this.projectId);
        }
        formData.append('type', 0);
        if (this.store.assetsId) {
            formData.append('asset_id', this.store.assetsId);
        }
        if (this.store.associatedTaskID) {
            formData.append('package_item_id', this.store.associatedTaskID);
        }
        formData = formData = bulkSetFormData(params, formData);
        if (this.formType === 'task_edit') {
            return this.store.updateTask(formData);
        } else if (this.formType === 'task_add') {
            return this.store.createTask(formData);
        }
    };

    // 表单字段变化时的name
    onValuesChange = (changedValues) => {
        const keyName = Object.keys(changedValues)[0];
        if (keyName === 'package_id' || keyName === 'package_type') {
            this.formRef?.current?.setFieldsValue({
                'asset_name': '',
                'photographers': [],
                'title': ''
            });
            this.updateTaskCheck({});
        }
        if (keyName === 'title') {
            this.formRef?.current?.setFieldsValue({
                'asset_name': changedValues[keyName]
            });
        }
    };

    onCancel = () => {
        this.props.history.goBack();
    };

    renderUpload = (idx) => {
        const uploadTitle = (
            <div className='align-center'>
                <div className='bold required mg-r-5'>预览图</div>
                <Tooltip placement='bottomLeft' title='尺寸不限，格式为：JPG\PNG'>
                    <Iconfont
                        type='icon-list_prompt'
                        className='icon-12 cursor-pointer'
                    />
                </Tooltip>
            </div>
        );
        return (
            <div className='process-form-col-1 mg-b-16' key={idx}>
                <Upload
                    ref={this.uploadRef}
                    extra={uploadTitle}
                    showIndicator={false}
                    entity={this.store.currentTask}

                />
            </div>
        );
    };

    renderForm = () => {
        const {
            asset_type,
            asset_category,
            photographers,
            currentTask,
            assetCharacter
        } =
            this.store;
        const {
            associatedPhotographers,
            checkedTask
        } = this.state;
        if (checkedTask?.creator?.id) {
            const ids = checkedTask?.creator?.id;
            const photographers = Array.isArray(ids) ? ids : [ids];
            this.formRef?.current?.setFieldsValue({
                'photographers': photographers
            });
        }

        const props = {
            asset_type,
            asset_category,
            photographers,
            currentTask,
            assetCharacter,
            checkedTask,
            associatedPhotographers
        };
        return (
            <Form ref={this.formRef} onValuesChange={this.onValuesChange}>
                <div className='task-form'>
                    <AssociatedTask formType={this.formType} currentTask={this.store.currentTask}
                                    updatePhotographers={this.updatePhotographers} updateTaskCheck={this.updateTaskCheck}
                                    formRef={this.formRef}
                    />
                    <AssetName {...props} formType={this.formType} formRef={this.formRef} />
                    <AssetID currentTask={this.store.currentTask} />
                    <MultipleComponents  {...props} formType={this.formType} formRef={this.formRef} />
                    <AssetImageCount {...props} />
                    <AssetComment currentTask={this.store.currentTask} />
                </div>
            </Form>
        );
    };

    renderButtons = () => {
        const { actionState } = this.store;
        return (
            <DrawerFooter>
                <div style={{
                    position: 'fixed',
                    bottom: '20px'
                }}>
                    <AuthButton
                        type='primary'
                        className='mg-r-12'
                        onClick={this.handleConfirm}
                        loading={actionState === 'pending'}
                    >
                        确认
                    </AuthButton>
                    <AuthButton onClick={this.onCancel}>取消</AuthButton>
                </div>
            </DrawerFooter>
        );
    };

    render() {
        return (
            <div>
                <h2 className='mg-b-42'>
                    {this.formType === 'task_edit' ? '编辑' : '创建'}资产
                </h2>
                {this.renderForm()}
                {this.renderButtons()}
            </div>
        );
    }
}

export default TaskFormContent;
