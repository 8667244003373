// 连接React组件与 react store
// mapStateToProps 将store中的数据作为props绑定到组件上。
// mapDispatchToProps
import { connect } from "react-redux";

import UserFilter from './user_filter';

import { fetchUsers, requestUsers } from '@actions/1_entities/users_actions';
import { fetchUserConfig } from '@actions/2_configs/configs_actions';

const mapStateToProps = (state) => ({
  groupOption: state.configs.groups,
  roleOption: state.configs.roles
})

const mapDispatchToProps = (dispatch) => ({
  searchUsers: (params, cb) => dispatch(fetchUsers(params, cb)),
  requestUsers: (params) => dispatch(requestUsers(params)),
  fetchUserConfig: (cb) => dispatch(fetchUserConfig(cb)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter)
