import { Spin,Empty } from 'antd'
import React from 'react'
import { Typography } from '@components'

/**
 * @name: Tsusukes.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 列的辅助ui
 */
// 加载
const classPrefix = 'maps-scroll-container';

export function defaultRenderLoading(options) {
    const { size } = options;
    return <div className={`${classPrefix}-loading`}>
        <Spin size={'large'} />
    </div>;
}

export function defaultRenderNoData(options) {
    const { list } = options;

    if (list.length === 0) {
        return (
            <div className={`${classPrefix}-no-data`}>
                <Empty image={`${process.env.PUBLIC_URL}/images/other/image2.png`} description="暂无数据" />
            </div>
        )
    } else {
        return <Typography align={'center'} className={`${classPrefix}-end`}>已经到底了</Typography>
    }

}

export function defaultRenderError(options) {
    const {
        list,
        mutate,
        error
    } = options;
    if (list.length === 0 ) {
        return <Typography className={`${classPrefix}-nodata-error`} align={'center'}>{error.message||"网络错误"}，点击<a onClick={() => mutate(undefined, true)}>重试</a></Typography>;
    } else {
        return <Typography className={`${classPrefix}-error`} align={'center'}>{error.message||'未知错误'}，点击<a onClick={mutate}>重试</a></Typography>;
    }

}
