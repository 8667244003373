import {projectsAPI} from "@api";
import { $success, $error } from "@/components";
import { fetchProjects } from "@actions/1_entities/projects_actions";
import {
  RECEIVE_PROJECT,
  RECEIVE_PROJECT_ERROR,
  DELETE_PROJECT
} from "@actions/action_consts";

 const receiveProject = (project) => ({
  type: RECEIVE_PROJECT,
  project,
});

 const receiveProjectErr = (error) => ({
  type: RECEIVE_PROJECT_ERROR,
  error,
});

const deleteProject = (id) => ({
  type: DELETE_PROJECT,
  id
})

export const fetchProject = (id, callback = () => {}) => (dispatch) => 
  projectsAPI.fetchProjectInfo(id).then((res) => {
    if (res.code === 1000) {
      callback();
       dispatch(receiveProject(res.data));
       return res.data
    } else {
      callback();
      dispatch(receiveProjectErr(res.msg));
      return {}
    }
  });


export const createProject = (
  params,
  { success, error } = {
    success: () => {},
    error: () => {},
  }
) => (dispatch) =>
  projectsAPI.createProject(params).then((res) => {
    if (res.code === 1000) {
      success();
      $success(res.msg);
      return dispatch(fetchProjects({ page: 1, page_size: 14 }));
    } else {
      error();
      if (res.code !== 1002) $error(res.msg);
      return dispatch(receiveProjectErr(res.msg));
    }
  });

export const updateProject = (
  params,
  { success, error } = {
    success: () => {},
    error: () => {},
  }
) => (dispatch) =>
  projectsAPI.updateProject(params).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
      return dispatch(receiveProject(res.data));
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
      return dispatch(receiveProjectErr(res.msg));
    }
  });

export const removeProject = (
  params,
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) =>
  projectsAPI.removeProject(params).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
       dispatch(fetchProjects({ page: 1, page_size: 14 }));
      return dispatch(deleteProject(params.id));
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
      return dispatch(receiveProjectErr(res.msg));
    }
  });

export const completeProject = (
  params,
  { success, error } = { success: () => {}, error: () => {} }
) => (dispatch) =>
  projectsAPI.completeProject(params).then((res) => {
    if (res.code === 1000) {
      $success(res.msg);
      success();
      return dispatch(receiveProject(res.data));
    } else {
      if (res.code !== 1002) $error(res.msg);
      error();
      return dispatch(receiveProjectErr(res.msg));
    }
  });
