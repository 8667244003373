import { UserRelations, ShootingTeam, ShootingPlanConfigs, AssetCategory, AssetType } from '@/views/5_settings';
export const settingsMenu = [
    {
        key: 'projects',
        title: '项目管理',
        icon: 'icon-project-settings',
        permission: 'project_settings',
        children: [
            {
                key: 'user-relation-settings',
                title: '人员关联',
                permission: 'user_relation_settings',
                component: () => <UserRelations />
            },
            {
                key: 'shooting-team-settings',
                title: '拍摄小组',
                // permission: "shooting_team_settings",
                component: () => <ShootingTeam />
            },
            {
                key: 'shooting-plan-settings',
                title: '拍摄计划',
                permission: 'plan_config_settings',
                component: () => <ShootingPlanConfigs />
            },
            {
                key: 'asset-category-settings',
                title: '资产种类',
                permission: 'asset_category_settings',
                component: () => <AssetCategory />
            },
            {
                key: 'asset-type-settings',
                title: '资产类型',
                permission: 'asset_type_settings',
                component: () => <AssetType />
            }
        ]
    },
    {
        key: 'users',
        title: '用户管理',
        icon: 'icon-user-settings',
        permission: 'user_settings'
    },
    {
        key: 'roles',
        title: '角色管理',
        icon: 'icon-role-settings',
        permission: 'role_settings'
    },
    // {
    //   key: "groups",
    //   title: "组织管理",
    //   icon: "icon-org-chart",
    //   permission: "group_settings",
    // },
    {
        key: 'target',
        title: '目标管理',
        icon: 'icon-a-30-mubiaoguanli',
        permission: 'performance_goal_manage'
    },
    {
        key: 'announcements',
        title: '公告管理',
        icon: 'icon-announcement-settings',
        permission: 'announcement_settings'
    }
];

export const statsMenu = [
    {
        key: 'business-data-overview',
        title: '业务数据总览',
        icon: 'icon-a-30-shujutongjijindubiao',
        permission: 'performance_goal_statistic'
    },
    {
        key: 'art',
        url: 'art',
        title: '拍摄美术评分统计',
        icon: 'icon-camera',
        permission: 'shooting_art_score_report',
        children: [
            {
                key: 'art-report',
                title: '拍摄数据总览',
                permission: 'shooting_art_score_stats',
                component: () => <></>
            },
            {
                key: 'art-summary',
                title: '拍摄数据汇总',
                permission: 'shooting_art_score_summary',
                component: () => <></>
            },
            {
                key: 'art-details',
                title: '拍摄数据明细',
                permission: 'shooting_art_score_detail',
                component: () => <></>
            }
        ]
    },
    {
        key: 'production',
        title: '制作统计',
        icon: 'icon-rulers',
        permission: 'produce_difficulty_score_report',
        children: [
            {
                key: 'production-report',
                title: '制作数据总览',
                permission: 'produce_difficulty_score_stats',
                component: () => <></>
            },
            {
                key: 'production-summary',
                title: '制作数据汇总',
                permission: 'produce_difficulty_score_summary'
            },
            {
                key: 'production-details',
                title: '制作数据明细',
                permission: 'produce_difficulty_score_detail'
            },
            {
                key: 'production-reviews',
                title: '制作审核明细',
                permission: 'produce_review_detail'
            }
        ]
    }
];
