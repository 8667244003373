import React from "react";
import { Collapse, ProfileAvatar } from "@components"
import { AmpTable } from "./../components"
import { tripColumns } from "./../config/colums";
import { projectDetailTitle } from "./../config"


const TaskPackageTitleBase = (props) => {
    return (
        <div className="amp-assets-info-item mg-tb-8 amp-w-50">
            {
                ["creator", "location"]?.map((item, index) => (
                    <div className="amp-assets-info-item amp-w-50" key={index}>
                        {props[item] ? (
                            <>
                                <span className="amp-assets-info-item-title">{item === "location" ? "相关地点" : projectDetailTitle[item]?.title}</span>
                                <span className={`amp-assets-info-label`}>
                                    {item === "creator" ? props[item].name : props[item]}
                                </span>
                            </>
                        ) : null}
                    </div>
                ))
            }
        </div>

    )
}


const PlanExecutors = (props) => {
    const { users } = props
    return (
        <div className="history-item align-center">
            <div className="amp-assets-info-item-title">策划人员</div>
            <div className="history-item-content people-display">
                {users?.map((item) => (
                    <div className="mg-r-12" key={item.id}>
                        <ProfileAvatar
                            {...item}
                            key={item.id}
                            className="mg-r-12"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
const TaskPackageDescription = (props) => (
    <div className="mg-t-16">
        {props["description"] ? (
            <div className={`amp-assets-info-item mg-tb-8 amp-w-100`}>
                <span className="amp-assets-info-item-title">{projectDetailTitle["description"]?.title}</span>
                <span className={`amp-assets-info-label`}>
                    {props["description"]}
                </span>
            </div>
        ) : null}
    </div>
);

const TaskPackageRegion = (props) => {
    const tripProps = { title: "行程", columns: tripColumns, props }
    return (
        <Collapse header="任务包信息" fontWeight="fw-500" opened={true}>
            <div className="amp-task-package">
                <TaskPackageTitleBase {...props} />
                <PlanExecutors {...props} />
                <AmpTable {...tripProps} />
                <TaskPackageDescription {...props} />
            </div>
        </Collapse>
    )
}


export default TaskPackageRegion
