import COS from 'cos-js-sdk-v5';
import { socAuth } from '@api/global/socAuth';
import moment from 'moment';

/**
 * @name: CosFileUpload.js
 * @user: cfj
 * @date: 2022/4/2
 * @description: // 通讯员oss 文件上传
 */

class CosFileUpload {
    bucket = undefined;
    region = undefined;

    constructor() {
        this.cos = this.init();
    }

    async getConfig(params) {
        try {
            const res = await socAuth(params);
            this.bucket = res.data.bucket_name;
            this.region = res.data.region;
            this.key = res.data.object_key;
            this.params = res.data;
        } catch (e) {
            throw new Error('获取密钥失败');
        }
    }

    init() {
        return new COS({
            getAuthorization: (options, callback) => {
                callback({
                    TmpSecretId: this.params.temp_secret_id,
                    TmpSecretKey: this.params.temp_secret_key,
                    SecurityToken: this.params.token,
                    StartTime: moment(this.params.start).unix(),
                    ExpiredTime: moment(this.params.expire).unix(),
                    ScopeLimit: true
                });
            }
        });
    }

    // 文件切片上传
    async sliceUploadFile(option, params) {
        await this.getConfig(params);
        return new Promise((resolve, reject) => {
            this.cos.sliceUploadFile(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Key: this.key,
                    Body: option.file,
                    ChunkSize: 25 * 1024 * 1024,
                    onTaskReady: (taskId) => {
                        resolve(taskId);
                    },
                    onHashProgress: (data) => {
                        data.percent *= 100;
                        option.onProgress?.(data);
                    },
                    onProgress: (data) => {
                        data.percent *= 100;
                        option.onProgress?.(data);
                    }
                },
                (err, data) => {
                    if (data) {
                        option.onSuccess?.({
                            url: data.Key,
                            thumbUrl: data.Key,
                            uid: data.Key,
                            dataSource: data
                        });
                    }
                    if (err) {
                        reject(err);
                        option.onError?.(err);
                    }
                }
            );
        });
    }

    // 终止上传
    multipartAbort(taskId) {
        this.cos.cancelTask(taskId);
    }
}

export default new CosFileUpload();
