import {
    RECEIVE_CURRENT_USER,
    RECEIVE_SESSION_ERROR
} from '@actions/action_consts';

const sessionErrorsReducer = (state= null, action={} ) => {
    switch (action.type) {
        case RECEIVE_SESSION_ERROR:
            return action.error || null
        case RECEIVE_CURRENT_USER:
            return null;
        default:
            return state
    }
}

export default sessionErrorsReducer