import {
    FINISH_INITIALIZATION,
    INITIATE_SESSION,
    LOGOUT_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    RECEIVE_TODOS,
    RECEIVE_USER_PERMISSIONS,
    RECEIVE_VALIDATION_TOKEN
} from '@actions/action_consts';
import { sourceMap } from '@hooks/swr/resolve-args';

const _nullUser = Object.freeze({
    id: null,
    token: null,
    is_first_login: null,
    initial_session: false,
    validationToken: null,
    permissions: [],
    name: null,
    role: null,
    todos: []
});

const sessionReducer = (state = _nullUser, action = {}) => {
    Object.freeze(state);
    switch (action.type) {
        case INITIATE_SESSION:
            return { ...state, initial_session: true };
        case RECEIVE_CURRENT_USER:
            const { currentUser } = action;
            const { initialized } = currentUser;
            const is_first_login = initialized === 0;
            return { ...state, is_first_login, ...currentUser };
        case RECEIVE_USER_PERMISSIONS:
            const { permissions } = action;
            return { ...state, permissions };
        case LOGOUT_CURRENT_USER:
            sourceMap.clear();
            return _nullUser;
        case RECEIVE_VALIDATION_TOKEN:
            const { validationToken } = action;
            return { ...state, validationToken };
        case FINISH_INITIALIZATION:
            return { ...state, is_first_login: false };
        case RECEIVE_TODOS:
            const { todos } = action;
            return { ...state, todos };
        default:
            return state;
    }
};

export default sessionReducer;
