import axios from "axios";

const shootingTeamAPI = {
    // 小组列表
    shootingTeamList(params) {
        return axios.post("/task/user-group/list", params);
    },
    // 小组组员列表
    shootingTeamMember(params) {
        return axios.post("/task/user-group/member", params);
    },
    // 新增小组成员
    shootingTeamAddMember(params) {
        return axios.post("/task/user-group/add",params);
    },
    // 删除小组成员
    shootingTeamDeleteMember(id) {
        return axios.delete("/task/user-group/delete", { data: { id }});
    },
    // 小组成员排序
    shootingTeamSort(params) {
        return axios.post("/task/user-group/sort", params);
    }
};

export default shootingTeamAPI;



