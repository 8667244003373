import React, { Component } from "react";
import arrayMove from "array-move";
import { SortableTable, AuthIcon, ConfirmDialog } from "@/components";
import { shootingTeamAPI } from "@/api";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

const baseColumns = [
    { title: "真实姓名", dataIndex: "name" },
    { title: "角色", dataIndex: "role" },
    {
        title: "组织",
        dataIndex: "group",
        render: (group) => (group ? group : "-"),
    },
];

@inject("userRelationsStore")
@observer
class RelationList extends Component {
    deleteRef = React.createRef();

    state = {
        list: [],
        total: null,
        isMoved: false,
        loading: false,
    };

    componentDidMount() {
        this.store.getShootingTeamMember()
    }


    get store() {
        return this.props.userRelationsStore;
    }

    showDelete = (record) => {
        this.deleteRef.current.show(
            record,
            this.store.currentShootingTeamType.name,
            record.name
        );
    };

    getShootingTeamMember = () => this.props.userRelationsStore.getShootingTeamMember();

    sortRequest = () => {
        this.store.sortRequest();
    };

    //   onSortEnd = ({ oldIndex, newIndex }) => {
    //     let { shootingMember } = this.store;
    //     shootingMember = toJS(shootingMember);
    //     if (oldIndex !== newIndex) {
    //       const newList = arrayMove([...shootingMember], oldIndex, newIndex).filter(
    //         (el) => !!el
    //       );
    //       this.store.receiveRelatedMember(newList);
    //       this.store.shootingTeamSort(newList?.map((item) => item.id));
    //     }
    //   };

    renderTable = () => {
        const { shootingMember, total, loading, currentShootingTeamType } =
            this.props.userRelationsStore;
        const render = (text, record) => (
            <AuthIcon
                type="icon-trash"
                className="icon-20"
                onClick={() => this.showDelete(record)}
                permission="relation_delete"
            />
        );
        const columns = [...baseColumns, { title: "操作", render }];
        return total > 0 || loading ? (
            <SortableTable
                columns={columns}
                dataSource={shootingMember}
                // onSortEnd={this.onSortEnd}
                loading={loading}
                permission="relation_list"
            />
        ) : (
            <div
                style={{
                    height: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {currentShootingTeamType?.name}暂时没有成员
            </div>
        );
    };

    render() {
        return (
            <>
                <ConfirmDialog
                    ref={this.deleteRef}
                    update={this.getShootingTeamMember}
                    confirmRequest={shootingTeamAPI.shootingTeamDeleteMember}
                />
                <div>{this.renderTable()}</div>
            </>
        );
    }
}

export default RelationList;
