import {
  observable,
  action,
  flow,
  computed,
  makeAutoObservable,
  toJS
} from "mobx";
import { assetsAPI } from "@api";
import { taskStore } from "@stores";
import { $success, $error } from "@/components";
import env from "env";

class FilterStore {
  @observable showWaterFall = true;
  @observable waterFallList = [];
  @observable list = [];
  @observable total = 0
  @observable page = 1;
  @observable isLoading = true;
  @observable flag = true;
  @observable temp = [];
  @observable assetCategoryId = null;
  @observable selectedRowKeys = [];
  @observable paramsObj = {};
  @observable clearFlag = false;
  @observable footerShow = true;
  @observable loading = true;
  @observable filter = {
    uid_name: "",
    produced_at: {},
    asset_type: null,
    asset_category: null,
    project_name:""
  };
  @observable sortObj = {};

  constructor() {
    makeAutoObservable(this);
  }
  @action setParamsObj(obj) {
    this.paramsObj = obj;
  }
  @action setFilter(obj) {
    this.filter = obj;
  }
  @action setSortObj(obj) {
    this.sortObj = obj;
  }
  @action changeAssetCategoryId(newVal) {
    this.assetCategoryId = newVal;
  }
  @action setClearFlag() {
    this.clearFlag = true;
  }
  @action toggleShowWaterFall() {
    this.showWaterFall = !this.showWaterFall;
  }

  @action clearWaterFallList() {
    this.waterFallList = []
  }

  @action clearList() {
    this.list = []
  }

  @action setPage() {
    this.page = 1;
  }

  @action setFlag() {
    this.flag = true;
  }

  @action async scroll (obj) {
    const imgs = await obj.getElementsByTagName("img");
    const _that = this;
    obj.onscroll = function () {
      if ( imgs.length) {
        _that.check(imgs);
      }
    };
  }

  @action check (imgs) {
    const len = imgs.length ? imgs.length : 0;
    if (this.getTop(imgs[len - 1])) {
      this.isLoading && this.loadImg()
    }
  }

  @action getTop (el) {
    const bound = el && el.getBoundingClientRect();
    const clientHeight = window.innerHeight;
    return bound.top - clientHeight <= 10;
  }

  @action async loadImg () {
    this.isLoading = false;
    this.getPage(this.page);
    await this.fetchWaterFall(this.filter);
    this.isLoading = true;
  }

  @action getPage(page) {
    if (page + 1 <= this.total) {
      this.page = page + 1;
    } else {
      this.flag = false;
      this.page = this.total;
    }
  }

  @action setPlaceholderImage(list) {
    const newList = list
    const len = newList.length;
    for (let i = 0; i < len; i++) {
      let total = 0;
      for (let j = 0; j < newList[i].length; j++) {
        let temp = newList[i][j].aspect_ratio;
        temp = taskStore.getRadio(temp);
        total += temp;
      }
      let pushItem = 6 - total;
      if (pushItem > 0) {
        for (let k = 0; k < pushItem; k++) {
          newList[i].push({
            aspect_ratio: 1,
            previews: [env.placeImage],
            task_image: [env.placeImage],
            task_image_bak: [env.placeImage],
            status: "placeholder"
          })
        }
      }
    }
    return newList
  }

  fetchWaterFall = flow(function* (filterObj) {
    this.loading = true;
    const obj = {
      filter: {
        ...filterObj
      },
      ...this.sortObj,
      page: this.page,
      page_size: 30,
    }
    if (this.flag) {
      try {
        const res = yield assetsAPI.fetchWaterfall({
          ...obj
        });
        if (res.code === 1000) {
          this.loading = false;
          if (res.data.total !== 0 ) {
            this.total = Math.ceil(res.data.total / 24);
            const temp = res.data.list.filter(it => it.aspect_ratio !== 0);
            this.list = [...this.list, ...temp];
            this.waterFallList = this.setPlaceholderImage(taskStore.fetchWaterFallSource(this.list));
          }
        }
      } catch (error) {}
    }
  })


  updateAssets = flow(function* (obj) {
    try {
      const res = yield assetsAPI.updateAssets({
        ...obj
      });
      if (res.code === 1000) {
        $success(res.msg);
      } else {
        $error(res.msg)
      }
    } catch (error) {}
  })

}

export default new FilterStore();
