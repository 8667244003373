import { connect } from "react-redux";
import { login, receiveSessionError } from "@actions/0_session/session_actions";
import SessionForm from "./session_form";


const mapStateToProps = (state) => ({
    error: state.errors.session
})

const mapDispatchToProps = (dispatch) => ({
  submitForm: (user) => dispatch(login(user)),
  resetErr: () => dispatch(receiveSessionError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionForm)