/**
 * @name: PublicTaskPackageForm.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 公开任务包
 */
import { forwardRef, useImperativeHandle } from 'react';
import { Col, Form, Row } from 'antd';
import { Input, Select } from '@components';

const priorityTypes = [
    {
        label: '顶',
        value: 1
    },
    {
        label: '高',
        value: 2
    },
    {
        label: '低',
        value: 3
    }
];
const PublicTaskPackageForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    const { initialValues } = props;
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }))
    return <Form form={form} initialValues={initialValues} layout={'vertical'}>
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item label={'下一步'} name={'statusLabel'} required={'true'}>
                    <Input disabled={true} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label={'优先级'} name={'priority'} required={true} rules={[{ required: true }]}>
                    <Select placeholder={'请选择优先级'} options={priorityTypes} />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
});
export default PublicTaskPackageForm
