import { DatePicker, Form, Input, Select } from 'antd';
import { QueryFilter } from '@components';
import { projectSettingsAPI } from '@api';
import { useSwr } from '@hooks';

const { RangePicker }: any = DatePicker;
const FormItem = Form.Item;

interface IProps {
    onChange: (values: any) => void;
}
/**
 * FilterForm
 * @param {IProps} props props
 * @returns {React.ReactNode}
 */
const FilterForm: React.FC<IProps> = (props) => {
    const { onChange } = props;

    /**
     * 获取策划人员列表
     */
    const { data: userList = [] } = useSwr(
        '/user-relation/list',
        () => projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } }).then((res: any) => {
            if (res.code === 1000) {
                return res.data.list.map((item: any) => ({
                    value: item.user_id,
                    label: item.name
                }));
            }
            return [];
        })
    );

    return (
        <QueryFilter
            size="large"
            onFinish={onChange}
            isOffset
        >
            <FormItem
                colon={false}
                label="搜索"
                name="scene_id_name"
            >
                <Input
                    allowClear
                    maxLength={50}
                    placeholder="请输场景的名称或ID"
                />
            </FormItem>
            <FormItem
                colon={false}
                label="策划人员"
                name="plan_user_id"
            >
                <Select
                    showSearch
                    allowClear
                    placeholder="请选择策划人员"
                    optionFilterProp="label"
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    options={userList}
                />
            </FormItem>
            <FormItem
                colon={false}
                label="提交时间"
                name="create_time"
            >
                <RangePicker
                    size="large"
                    allowClear
                />
            </FormItem>
        </QueryFilter>
    );
};

export default FilterForm;
