/**
 * @name: JourneyFormItem.jsx
 * @user: cfj
 * @date: 2022/3/23
 * @description: 行程的form item
 */

import { Form, Table } from 'antd';
import { AmpIcon } from '@components';
import { projectSettingsAPI } from '@api';
import { BodyCell, BodyRow, HeaderRow, HeaderTableCell } from './tableComponents';

const JourneyFormItem = function(props) {
    const {
        value,
        onChange
    } = props;

    const columns = [
        {
            title: '日期',
            width: 220,
            dataIndex: 'time',
            valueType: 'rangePicker',
            formItemProps: {
                className: 'journey-form-item',
                rules: [
                    {
                        required: true,
                        message: '必选项'
                    }]
            },
            request() {}
        },
        {
            title: '行程类型',
            width: 80,
            dataIndex: 'trip_type',
            valueType: 'select',
            fieldProps: {
                placeholder: '请选择行程类型'
            },
            formItemProps: {
                className: 'journey-form-item',
                rules: [
                    {
                        required: true,
                        message: '必选项'
                    }]
            },
            setKey: '/settings/shooting-plan-config/list/1',
            request() {
                return projectSettingsAPI.fetchPlanConfigs({
                    "filter": { type: 1 }
                })
                    .then((res) => res.data.list.map((item) => ({
                        value: item.id,
                        label: item.value
                    })));
            }
        },
        {
            title: '目的地',
            width: 100,
            dataIndex: 'destination',
            valueType: 'text',
            fieldProps: {
                placeholder: '请输入目的地'
            },
            formItemProps: {
                className: 'journey-form-item',
                rules: [
                    {
                        required: true,
                        message: '必填项'
                    }]
            }
        },
        {
            title: '交通方式',
            width: 80,
            dataIndex: 'transportation',
            valueType: 'select',
            fieldProps: {
                placeholder: '请选择交通方式'
            },
            formItemProps: {
                className: 'journey-form-item',
                rules: [
                    {
                        required: true,
                        message: '必选项'
                    }]
            },
            setKey: '/settings/shooting-plan-config/list/2',
            request() {
                return projectSettingsAPI.fetchPlanConfigs({
                    'filter': { type: 2 }
                })
                    .then((res) => res.data.list.map((item) => ({
                        value: item.id,
                        label: item.value
                    })));
            }
        }, {
            title: '行程说明',
            dataIndex: 'comment',
            valueType: 'text',
            fieldProps: {
                placeholder: '请输入行程说明'
            },
            formItemProps: {
                className: 'journey-form-item',
            }
        },
        {
            width: 80,
            title: '操作',
            type: 'option',
            valueType: 'option',
            renderHeader() {
                return <Form.Item noStyle shouldUpdate>
                    {({
                        submit
                    }) => <AmpIcon onClick={submit} name={'add'} />}
                </Form.Item>;
            }
        }
    ];
    const mergedColumns = columns.map(column => {
        return {
            ...column,
            children: [
                {
                    title: column.title,
                    dataIndex: column.dataIndex,
                    width: column.width,
                    render: column.render,
                    onHeaderCell() {
                        return column;
                    },
                    onCell(record, index) {
                        return {
                            record,
                            index,
                            ...column
                        };
                    }
                }]
        };
    });

    function edit(data) {
        const newValue = [...value.map(value => ({ ...value }))];
        const index = value.findIndex(d => d.id === data.id);
        if (index > -1) {
            newValue[index] = data;
            onChange(newValue);
        }
    }

    function del(i) {
        const newValue = [...value];
        const index = value.findIndex(d => d.id === i);
        if (index > -1) {
            newValue.splice(i, 1);
            onChange(newValue);
        }
    }

    return <Table
        bordered
        className={'form-item-table'}
        onHeaderRow={(columns, index) => ({
            index,
            onChange,
            value
        })}
        onRow={(record, index) => ({
            onChange,
            edit,
            del,
            initialValues: record,
            index
        })}
        components={{
            header: {
                row: HeaderRow,
                cell: HeaderTableCell
            },
            body: {
                row: BodyRow,
                cell: BodyCell
            }
        }}
        rowKey={'id'}
        footer={null}
        dataSource={value}
        columns={mergedColumns}
        pagination={false}
        locale={{
            emptyText() {
                return '';
            }
        }}
    />;
};
export default JourneyFormItem;
