import axios from 'axios'

const taskGroupsAPI = {
  updateTaskGroups(params) {
    return axios.post("/task-group/update", params);
  },
  fetchTaskGroups(params) {
    return axios.post("/task-group/list", params);
  },
  fetchUngroupedAssets(params) {
    return axios.post("/task-group/ungrouped-list", params);
  },
};

export default taskGroupsAPI