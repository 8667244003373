import { fetchProjectActivities } from '@actions/1_entities/account_actions';
import { connect } from 'react-redux';
import Activities from './activities'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  fetchProjectActivities: (params) => dispatch(fetchProjectActivities(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Activities)