import { rolesAPI } from "@api";

import {
    REQUEST_ROLE_LIST,
    RECEIVE_ROLES,
    RECEIVE_ROLES_ERR,
    RECEIVE_ROLE,
    RECEIVE_PERMISSIONS,
    DELETE_ROLE,
} from "@actions/action_consts";
import { $success, $error } from "@/components";

const requestRoles = (params) => ({
    type: REQUEST_ROLE_LIST,
    params,
});

const receiveRoleList = (params, roles) => ({
    type: RECEIVE_ROLES,
    params,
    roles,
});

const receiveRoleErr = (err) => ({
    type: RECEIVE_ROLES_ERR,
    err,
});

const receiveRole = (data) => ({
    type: RECEIVE_ROLE,
    data,
});

const receivePermissions = (data) => ({
    type: RECEIVE_PERMISSIONS,
    data,
});

const removeRole = (id) => ({
    type: DELETE_ROLE,
    id,
});

const fetchRoles = (params, callback = () => { }) => (dispatch) => {
    dispatch(requestRoles(params));
    rolesAPI.fetchRoles(params).then((res) => {
        if (res.code === 1000) {
            callback();
            return dispatch(receiveRoleList(params, res.data));
        } else {
            callback();
            return dispatch(receiveRoleErr(res.msg));
        }
    });
};

const createRole = (
    params,
    { success, error } = {
        success: () => { },
        error: () => { },
    }
) => (dispatch) =>
        rolesAPI.createRole(params).then((res) => {
            if (res.code === 1000) {
                success();
                $success(res.msg);
                return dispatch(
                    fetchRoles({
                        page: 1,
                        page_size: 14
                    })
                );
            } else {
                error();
                if (res.code !== 1002) $error(res.msg);
                return dispatch(receiveRoleErr(res.msg));
            }
        });

const getPermissions = (callback = () => { }) => (dispatch) => {
    rolesAPI.allPermissions().then((res) => {
        if (res.code === 1000) {
            callback();
            return dispatch(receivePermissions(res.data));
        } else {
            callback();
            return dispatch(receiveRoleErr(res.msg));
        }
    });
};

const deleteRole = (params, callback = () => { }) => (dispatch) => {
    rolesAPI.deleteRole(params).then((res) => {
        if (res.code === 1000) {
            callback();
            $success(res.msg);
            dispatch(fetchRoles({ page: 1, page_size: 12 }));
            return dispatch(removeRole(params.id));
        } else {
            callback();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveRoleErr(res.msg));
        }
    });
};

const editRole = (params, callback = () => { }) => (dispatch) => {
    rolesAPI.editRole(params).then((res) => {
        if (res.code === 1000) {
            callback();
            $success(res.msg);
            return dispatch(receiveRole(res.data));
        } else {
            callback();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveRoleErr(res.msg));
        }
    });
};

const fetchRoleDetails = (params, callback = () => { }) => (dispatch) =>
    rolesAPI.roleInfo(params).then((res) => {
        if (res.code === 1000) {
            callback();
            return res.data;
        } else {
            callback();
            if (res.code !== 1002) $error(res.msg);
            return dispatch(receiveRoleErr(res.msg));
        }
    });

export {
    requestRoles,
    fetchRoles,
    createRole,
    getPermissions,
    deleteRole,
    editRole,
    fetchRoleDetails,
};
