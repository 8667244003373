import React, { memo, useEffect, useMemo, useState, useCallback } from 'react'
import { Modal, Form, Skeleton } from 'antd';
import { $error, $success } from '@components';
import { AddFooter, BasicFooter } from './template/footer'
import TurnForm from './template/turn_form'
import CreateFrom from './template/create_from'
import { sceneEventBus } from '@/event_bus'
import { useSend } from '@hooks';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api'
import configData from './config'
import './index.less'

const SceneModal = (props) => {
    const {
        onClose,
        modalType,
        topicId,
        sceneId
    } = props

    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [forbid, setForbid] = useState(true)

    useEffect(() => {
        if (modalType && Object.hasOwn(configData, modalType)) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [modalType])

    const [loading, submission] = useSend((params) => {
        const {
            values,
            next,
            topicId,
            sceneId,
            operation,
            modalType,
        } = params
        let data = {
            commonParam: {
                operation,
                sceneId: values.checkSceneId || sceneId || '',
                comment: values.describe,
            }
        }
        const sceneParam = {
            topicId,
            ...values,
            imagesList: values?.imagesList?.map(item => {
                return {
                    filePath: item.file_path,
                    fileUrl: item.file_url,
                    fileUid: item.uid,
                    useType: item.review_image_type === 'main' ? 1 : 0
                }
            }),
            addressList: values?.addressList?.map(item => {
                return {
                    ...item,
                    ad_code: item.adcode, //区域编码
                    type_code: item.typecode,// POI类型编码
                }
            })
        }
        if (modalType === 'add') {
            data.addSceneParam = sceneParam
        } else if (modalType === 'edit') {
            data.updateSceneParam = sceneParam
        }
        return SelectedTopicApi().topicSceneSchedulerHandler(data).then(res => ({ ...res, next }))
    }, {
        onSuccess(res) {
            sceneEventBus.emit('sceneEventBus')
            if (res.next) {
                onClose?.('add')
                form.resetFields()
                form.scrollToField('name')
            } else {
                onClose?.('')
            }
            $success('提交成功！')
            props?.fetchSceneDetails && props?.fetchSceneDetails()
        },
        onError(error) {
            $error(error.message);
        }
    })

    const sceneConfirm = useCallback((next) => {
        // 提交表单
        form.validateFields().then(values => {
            submission({
                values: { ...values },
                next,
                topicId,
                sceneId,
                operation: configData[modalType]?.operation,
                modalType,
            })
        }).catch(info => { })
    }, [form, modalType, topicId, sceneId])

    const onCancel = useCallback(() => {
        setForbid(true)
        onClose?.('')
    }, [])

    const footer = useMemo(() => {
        if (!modalType) return null
        if (modalType === 'add') {
            return <AddFooter
                onOk={() => sceneConfirm()}
                onCancel={onCancel}
                onBegin={() => sceneConfirm(true)}
                loading={loading}
            />
        } else {
            const disabled = modalType === 'edit' ? false : forbid
            return <BasicFooter
                onOk={() => sceneConfirm()}
                onCancel={onCancel}
                loading={loading}
                forbid={disabled}
            />
        }
    }, [modalType, loading, forbid])

    const { title, tips, component, width } = useMemo(() => {
        if (!modalType) return {
            title: '-',
            component: <Skeleton />
        }
        let component = <TurnForm topicId={topicId} modalType={modalType} />
        let width = 1048
        if (modalType === 'add') {
            width = 708
            component = <CreateFrom fromRef={form} />
        }
        if (modalType === 'edit') {
            width = 708
            component = <CreateFrom fromRef={form} sceneId={sceneId} />
        }

        return {
            title: configData[modalType]?.title,
            tips: configData[modalType]?.tips,
            component,
            width
        }
    }, [modalType, visible, topicId])

    const onValuesChange = (changedValues, allValues) => {
        const checkSceneId = allValues['checkSceneId']
        if (checkSceneId) {
            setForbid(false)
        } else {
            setForbid(true)
        }
    }

    return (
        <>
            <Modal
                title={title}
                visible={visible}
                footer={footer}
                onCancel={onCancel}
                destroyOnClose
                width={width}
                centered
                maskClosable={false}
                className="amp-topic-scene-modal"
            >
                <div className="amp-topic-scene-modal-body">
                    {
                        tips && <h5 className="amp-topic-scene-modal-tips">{tips}</h5>
                    }
                    <Form
                        form={form}
                        layout='vertical'
                        size="large"
                        preserve={false}
                        onValuesChange={onValuesChange}
                        scrollToFirstError
                    // initialValues={topicData ? { ...topicData } : {}}
                    >
                        {component}
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default memo(SceneModal)
