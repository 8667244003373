import { Tabs } from 'antd'
import { connect } from "react-redux";
import { checkAuth } from "@gUtils";

const {TabPane} = Tabs


const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});


const AuthTabsComponent = (props) => {
  const { userPermissions, permission, children, dispatch, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? (
    <Tabs {...rest}>{children}</Tabs>
  ) : (
    ""
  );
};

const AuthTabPaneComponent = (props) => {
  const { userPermissions, permission, children, dispatch, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? <TabPane {...rest}>{children}</TabPane> : "";
};

const AuthTabs = connect(mapStateToProps)(AuthTabsComponent);
const AuthTabPane = connect(mapStateToProps)(AuthTabPaneComponent);

export { AuthTabs, AuthTabPane}