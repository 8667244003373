import React, { Component } from "react";
import { Timeline, Empty } from "antd";
import { Iconfont } from "@/components";
import { withRouter } from "react-router";
import { getActivityUrl } from "@gUtils";

class Activities extends Component {
  state = {
    page: 1,
    page_size: 18,
    list: [],
    total: 0,
  };
  componentDidMount() {
    this.requestActivities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.requestActivities();
    }
  }

  requestActivities = async () => {
    let { page, page_size, list } = this.state;
    const res = await this.props.fetchProjectActivities({
      page,
      page_size,
    });
    list = [...list, ...res.list];
    let { total } = res;
    this.setState({ total, list });
  };

  handleScroll = (e) => {
    let { list, total, page } = this.state;
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const atBottom = Math.floor(scrollHeight - scrollTop) <= clientHeight;
    if (list.length < total && atBottom) {
      page += 1;
      this.setState({ page });
    }
  };

  recordClick = (record) => {
    const finalUrl = getActivityUrl(record);
    this.props.history.push(finalUrl);
  };

  renderSingleRecord = (record) => {
    const { type, object_name, description, time, history_id } = record;
    return (
      <Timeline.Item color="#DBDFE5" key={history_id}>
        <div
          className="display-flex align-center cursor-pointer"
          style={{ width: "fit-content" }}
          onClick={() => this.recordClick(record)}
        >
          <span className="mg-r-20">{time}</span>
          <Iconfont
            className="activity-icon mg-r-10"
            type={type === 3 ? "icon-asset" : "icon-project"}
          />
          <span className="mg-r-10 primary-color">{object_name}</span>
          <span>{description}</span>
        </div>
      </Timeline.Item>
    );
  };

  renderAllRecords = () => {
    let { list } = this.state;
    let allDates = [];
    list.forEach((item) => allDates.push(item.date));
    allDates = Array.from(new Set(allDates));
    allDates = allDates?.map((date) => {
      const filteredDates = list.filter((item) => item.date === date);
      return { list: filteredDates, date: date };
    });
    return allDates?.map((item) => (
      <div key={item.date}>
        <div className="activities-date">{`${item.date} ${item.list[0].day}`}</div>
        <div className="activities-timeline">
          <Timeline>
            {item.list?.map((record) => this.renderSingleRecord(record))}
          </Timeline>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div className="activities" onScroll={this.handleScroll}>
        <h3 className="mg-tb-24">项目变动</h3>
        {this.state.list.length > 0 ? (
          <div>{this.renderAllRecords()}</div>
        ) : (
        <div
          className="display-flex align-center justify-center"
          style={{ width: "85%", height: "60vh" }}
        >
          <Empty />
        </div>
        )}
      </div>
    );
  }
}

export default withRouter(Activities);
