import React, { useEffect, useState, useRef } from 'react'
import { Button, Input, Form } from 'antd';
import { Iconfont, $error } from '@/components';
import { taskFlowAPI } from '@/api'
import { useSelector } from "react-redux";
import { checkAuth } from '@gUtils'


const TaskOpinionEdit = (props) => {
    const { value = '', onChange, placeholder, scene = 'produce_score', permissions = 'sys_tag_manage' } = props
    // 编辑状态
    const [edit, setEdit] = useState(false)
    // 标签列表
    const [labelValues, setLabelValues] = useState([])
    // 是否有新增标签
    const [addFlag, setAddFlag] = useState(false)
    // 标签信息添加进意见反馈的当前id
    const [pushId, setPushId] = useState('')
    const inputRef = useRef(null)
    // 判断用户是否有权限编辑标签
    const userPermissions = useSelector(state => state.session.permissions)
    const labelDisabled = checkAuth(userPermissions, permissions);

    useEffect(() => {
        getTagList()
    }, [])

    useEffect(() => {
        if (inputRef.current && addFlag) {
            setAddFlag(false)
            inputRef.current?.focus()
        }
    }, [addFlag])

    useEffect(() => {
        let resetId = null
        if (pushId) {
            resetId = setTimeout(() => {
                setPushId('')
            }, 2000)
        }
        return () => {
            clearTimeout(resetId)
        }
    }, [pushId])
    /**
     * @method 获取标签列表
     * @param {string} scene 标签类型
    */
    const getTagList = () => {
        taskFlowAPI.getTagListScene({ scene: scene }).then((res) => {
            if (res.code === 1000) {
                setLabelValues(res.data.list || [])
            } else {
                $error(res?.msg || '获取标签列表失败')
            }
        })
    }

    /**
     *
     * @param {object} item 标签点击添加进编辑器
     */
    const labelClick = (item) => {
        if (edit || !item.id) return
        //短时间多次点击同一个标签无效
        if (pushId === item.id) return
        setPushId(item.id)
        onChange?.(value + item.title)
    }
    /**
     * @method 提交保存编辑后的标签
     */
    const saveEdits = async () => {
        let keepLists = []
        let addLists = []
        labelValues.forEach(item => {
            if (item.id) {
                keepLists.push(item)
                return
            }
            const tag = item.title.trim() || ''
            //标签没有并且值不为空，并且已存在的数据没有相同标签，并且同样新增值不相同
            if (tag && !(labelValues.some(label => (tag === label.title) && label.id))) {
                addLists.push(tag)
            }
        })
        addLists = Array.from(new Set(addLists))
        // 没有新增数据直接返回
        if (addLists.length === 0) {
            setEdit(false)
            setLabelValues(keepLists)
            return
        }
        // 新增数据请求更新数据
        const res = await taskFlowAPI.addTagListScene({
            title: addLists,
            scene: scene
        })
        if (res.code === 1000) {
            setEdit(false)
            getTagList()
        } else {
            $error(res?.msg || '保存失败！')
            return
        }
    }
    /**
     *
     * @param {object} item 需要删除的数据
     * @param {number} index 需要删除的下标
     */
    const deleteLabel = async (item, index) => {
        // 有id 接口清除
        try {
            if (item.id) {
                const res = await taskFlowAPI.deleteTagListScene({
                    id: item.id,
                    scene: scene
                })
                if (res?.code !== 1000) {
                    $error(res?.msg || '删除失败！')
                    return
                }
            }
            const newLists = [...labelValues]
            newLists.splice(index, 1)
            setLabelValues(newLists)
        } catch (err) { }
    }
    /**
     * @method 添加输入框到编辑标签
     */
    const labelListAdd = () => {
        let newLists = [...labelValues]
        newLists.push({
            id: '',
            title: ''
        })
        setLabelValues(newLists)
        setAddFlag(true)
    }
    /**
     * @method 编辑新增标签数据
     * @param {*} e
     * @param {*} index
     */
    const addInputChange = (e, index) => {
        let newLists = [...labelValues]
        newLists[index].title = e.target.value
        setLabelValues(newLists)
    }
    // 标签组成节点
    const makeUpLabel = () => {

        return labelValues.map((item, index) => (
            <div
                className="amp-drawer-opinion-key"
                key={index}
                onClick={() => labelClick(item)}
                style={{
                    width: item.id ? "auto" : "100%"
                }}
            >
                {
                    item.id ?
                        <div className="amp-drawer-opinion-text">
                            {item.title}
                        </div> :
                        <Input
                            className="amp-drawer-opinion-input"
                            value={item.title}
                            ref={inputRef}
                            maxLength={50}
                            size="small"
                            onChange={(e) => addInputChange(e, index)}
                        />
                }
                {
                    deleteBtn(item, index)
                }

            </div>
        ))
    }
    // 标签上的删除按钮
    const deleteBtn = (item, index) => {
        return edit ?
            <div
                className="amp-drawer-opinion-delete"
                onClick={() => deleteLabel(item, index)}
            >
                <Iconfont
                    type="icon-a-16-guanbi"
                    className="icon-close icon-14 mg-l-3"
                    onClick={props.click}
                ></Iconfont>
            </div> : null
    }
    //添加标签按钮
    const addLabel = () => {
        return labelValues.length < 10 && edit ?
            <div
                className="amp-drawer-opinion-add amp-drawer-opinion-key"
                onClick={labelListAdd}
            >
                <Iconfont
                    type="icon-a-14-zengjia"
                    className="icon-16 mg-l-3"
                />
            </div> : null
    }

    return (
        <div className="amp-drawer-opinion">
            <Form.Item>
                <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder={placeholder}
                    maxLength={500}
                    value={value}
                    onChange={(e) => onChange?.(e.target.value)}
                />
            </Form.Item>

            <div className="amp-drawer-opinion-quick">
                <div className="amp-drawer-opinion-label">
                    <div>快捷填入：</div>
                    <div className="amp-drawer-opinion-taglist">
                        {
                            makeUpLabel()
                        }
                        {
                            addLabel()
                        }
                    </div>
                </div>
                {
                    labelDisabled &&
                    <div className="amp-drawer-opinion-button">
                        {
                            edit ?
                                <Button
                                    className=""
                                    type="link"
                                    size="small"
                                    onClick={saveEdits}
                                >
                                    保存
                                </Button>
                                :
                                <Button
                                    className="amp-opinion-button-icon"
                                    icon={
                                        <Iconfont
                                            type="icon-a-20-shezhi-01"
                                            className="icon-16 mg-l-3"
                                            onClick={props.click}
                                        ></Iconfont>
                                    }
                                    disabled={!labelDisabled}
                                    type="link"
                                    size="small"
                                    onClick={() => setEdit(true)}
                                />
                        }
                    </div>
                }

            </div>
        </div>
    )
}

export default TaskOpinionEdit
