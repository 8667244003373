import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AssociatedModal } from './components/index';

import './index.less';

@inject("taskStore", "todosStore")
@observer
class AssociatedTask extends Component {
    get store() {
        return this.props.taskStore;
    }
    get projectId() {
        return (
            parseInt(this.props.match?.params?.id) || this.props.todosStore.projectId
        );
    }
    componentDidMount() {
        this.store.showAssociatedModal = false
        this.store.packageItem = []
        this.props.taskStore.planAssociatedTask(this.projectId)
    }
    /**
     * 打开关联任务弹框
     */
    // openModalHandle = () => {
    //     this.store.showAssociatedModal = true
    //     let project_id = this.projectId;
    //     this.props.taskStore.planAssociatedTask(project_id)

    // }
    render() {
        const img_url = this.store.associatedTaskImage ? this.store.associatedTaskImage : ""
        const { currentTask, formType } = this.props
        return (
            <>
                <div className="process-form-col-1 bold mg-b-16">
                    关联任务
                </div>
                <AssociatedModal {...this.props} />
                <div
                    className="process-form-col-1 mg-b-16"
                    style={{ borderBottom: "1px dashed #ccc" }}
                />
            </>
            // <div className="process-form-col-1 mg-b-16">
            //     <div className="bold mg-b-16">关联任务</div>

            //     {
            //         (formType !== "task_edit" && !this.store.isAssociated) ? (
            //             <div className="primary-color mg-t-16 mg-b-30 cursor-pointer" onClick={this.openModalHandle}>关联任务
            //                 <Iconfont
            //                     type="icon-a-16-lianjie"
            //                     className="icon-16"
            //                 />
            //             </div>
            //         ) : (
            //             // TODO: div展示图片
            //             <div className="mg-t-16 mg-b-30 flex">
            //                 <div key={img_url} className="mg-r-10">
            //                     <BasicImage src={img_url} width={120} height={120} />
            //                 </div>
            //                 <div className="primary-color cursor-pointer amp-reSelect" onClick={this.openModalHandle}>重新选择</div>
            //             </div>

            //         )
            //     }

            // </div>
        )
    }
}

export default AssociatedTask
