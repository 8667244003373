import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'
import { Pagination, Table } from 'antd'
import { Iconfont } from '@components'
import moment from 'moment'
import { artConfigs, productionConfigs } from '@vars/views/stats_vars'

@withRouter
@inject("summaryStore")
@observer
class SummaryTable extends React.Component {
  get store() {
    return this.props.summaryStore;
  }
  get configs() {
    return this.statType === "art"
      ? artConfigs
      : this.statType === "production" && productionConfigs;
  }
  get statType() {
    const { pathname } = this.props.location;
    return pathname.split("/")[2];
  }
  get currentTab() {
    if(location.pathname.includes('/all')){
      return 'all'
    } else if(location.pathname.includes('/user')){
      return 'user'
    }
    return null
  }
  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page) => {
    this.store.updatePage(page);
  };
  renderAction = (record) => {
    const { date, user_id } = record;
    const isWeek = this.statType === "production" && this.store.dateType === 2;
    let res;
    if (isWeek) {
      res = date.split("~").map((item) => moment(item));
    } else {
      if (!date.includes("~")) {
        res = [moment(date).startOf("month"), moment(date).endOf("month")];
      }
    }
    const url = `/stats/${this.store.statType}/${
      this.store.statType
    }-details?filter=${JSON.stringify({ date: res, user_id })}`;
    const onClick = (e) => {
      e.stopPropagation();
      this.props.history.push(url);
    };
    return (
      <div className="cursor-pointer align-center" onClick={onClick}>
        <Iconfont type="icon-eye-black" className="icon-18 mg-r-10" />
        查看详情
      </div>
    );
  };
  render() {
    const { table, page, total, loading } = this.store;
    const action = {
      title: "操作",
      key: "operation",
      render: this.renderAction,
    };
    const allColumns = this.configs.allColumns;
    const userColumns = this.configs.userColumns;
    const finalColumns =
      this.currentTab === "all"
        ? [...allColumns, action]
        : this.currentTab === "user" && [...userColumns, action];
    return (
      <div>
        <Table
          dataSource={table}
          columns={finalColumns}
          pagination={false}
          rowKey={(record) => record.id}
          loading={loading}
        />

        <Pagination
          defaultPageSize={11}
          total={total}
          showQuickJumper
          onChange={this.handlePageChange}
          current={page}
          showTotal={(total) => `共 ${total} 条`}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
export default SummaryTable;
