import { Component } from "react";
import { Modal } from "antd";
import {AuthButton} from '@/components'
export default class SendCode extends Component {
  state = {
    visible: false,
  };
  get disabled() {
    const newDate = new Date()
    const newStamp = newDate.getTime();
    const stamp = localStorage.getItem('timeStamp')
    return (newStamp - stamp) / 1000 < 60
  }

  show = () => {
    this.setState({ visible: true });
  };
  hide = () => {
    this.setState({ visible: false });
  };
  handleConfirm = () => {
    const newDate = new Date()
    localStorage.setItem('timeStamp', newDate.getTime())
    this.props.sendCode({ success: this.success, error: this.hide});
  }

  success = () => {
    this.hide()
    this.props.showValidation()
  }

  render() {
    const {currentUser} = this.props
    return (
      <Modal
        visible={this.state.visible}
        onOk={this.handleConfirm}
        onCancel={this.hide}
        footer={null}
        className="medium-form-dialog"
        centered
      >
        <h2>更改密码</h2>
        <div className="mg-t-30 fz-18">
          <p>是否使用绑定邮箱</p>
          <p> {currentUser.email}进行身份验证？</p>
        </div>
        <AuthButton
          type="primary"
          className=" mg-t-30 mg-r-12"
          onClick={this.handleConfirm}
        >
          确认
        </AuthButton>
        <AuthButton className=" mg-t-30" onClick={this.hide}>
          取消
        </AuthButton>
      </Modal>
    );
  }
}
