import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AuthButton } from '@/components';
import { Form, Input, Select } from 'antd';
import { priorityTypes } from '@vars/views/tasks_vars';
import { ForbidEditInput, Title } from '../task_planning/TaskPlanningRender';
import { drawerTitle } from '../config/index';
import TaskPackageReview from '../../task_planning/task_package_review/index';
import './index.less';

@inject("taskPlanStore", "taskPackageStore", "projectStore")
@observer
class TaskPackageOperate extends Component {
    formRef = React.createRef();
    /**
     *获取taskPlanStore
     */
    get store() {
        return this.props.taskPlanStore;
    }
    /**
     *获取taskPackageStore
     */
    get taskPackageStore() {
        return this.props.taskPackageStore;
    }
    /**
     *获取projectStore
     */
    get projectStore() {
        return this.props.projectStore;
    }
    // 点击事件
    handleClick = (action) => {
        if (action === "public") {
            this.formRef.current.validateFields().then((res) => {
                this.taskPackageStore.publicPackage();
                this.store.toggleVisible(false);
            })
        } else if (action === "disapprove") {
            this.formRef.current.validateFields().then((res) => {
                this.taskPackageStore.setDisapproveComment(res.disapprove_comment);
                this.taskPackageStore.disapprovePackage();
                this.store.toggleVisible(false);
            })
        } else if (action === "discard") {
            this.taskPackageStore.discardPackage();
            this.store.toggleVisible(false);
        } else if (action === "take") {
            this.taskPackageStore.takePackage();
            this.store.toggleVisible(false);
        } else if (action === "abandon") {
            this.formRef.current.validateFields().then((res) => {
                this.taskPackageStore.setDisapproveComment(res.disapprove_comment);
                this.taskPackageStore.abandonPackage();
                this.store.toggleVisible(false);
            })
        }
    }


    // 取消按钮
    handleClose = () => {
        this.store.toggleVisible(false);
        this.taskPackageStore.updatePlanningPage(1);
        this.taskPackageStore.filtrateOptions = [1];
        this.taskPackageStore.planningList = [];
    }
    // 渲染内容区域
    renderContent = (title) => {
        const { name } = this.projectStore.loginUser;
        const { currentTaskTotal, balanceTotal } = this.taskPackageStore;
        let res = [];
        drawerTitle[title]?.formContent?.map((item, idx) => {
            if (item.name === "operator") {
                res.push(<ForbidEditInput key={idx} {...item} value={name} operatorName="处理人" />)
            } else if (item.name === "total") {
                res.push(<ForbidEditInput key={idx} {...item} value={currentTaskTotal} operatorName={item.label} />)
            } else if (item.name === "totals") {
                res.push(<ForbidEditInput key={idx} {...item} value={balanceTotal} currentTaskTotal={currentTaskTotal} operatorName={item.label} />)
            }
            else {
                res.push(<ForbidEditInput key={idx} {...item} />)
            }
        });
        return res;
    }
    // 渲染底部按钮
    renderFooter = (title) => {
        let res = [];
        drawerTitle[title]?.buttons?.map((item, idx) => {
            res.push(
                <AuthButton
                    key={idx}
                    type="primary"
                    className="mg-r-20"
                    onClick={() => {
                        this.handleClick(item.action);
                    }}
                >{item.title}</AuthButton>
            )

        });
        return res;
    }
    // 优先级
    renderPriority = (idx) => {
        const message = "请选择优先级";
        return (
            <div className="task-form-col-2" key={idx}>
                <div className="mg-b-8 bold required">优先级</div>
                <Form.Item
                    name="priority"
                    className="input-sz-m"
                    rules={[{ required: true, message }]}
                >
                    <Select
                        size="large"
                        placeholder={message}
                        showSearch
                        optionFilterProp="children"
                        showArrow
                        onChange={(val) => {
                            this.taskPackageStore.setPublicPriorityTypes(val);
                        }}
                    >
                        {priorityTypes?.map((item, idx) => (
                            <Option value={item.code} key={idx}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    };
    // 备注
    renderComment = (props) => {
        return (
            <div className="task-form-col-1">
                <div className="mg-b-16 bold">备注</div>
                <Form.Item
                    name="disapprove_comment"
                >
                    <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder="请输入备注"
                        style={{
                            width: "43vw",
                            maxWidth: "820px",
                        }}
                        maxLength={500}
                    ></Input.TextArea>
                </Form.Item>
            </div>
        );
    };
    render() {
        const { title } = this.store;
        const res = this.renderContent(title);
        const footer = this.renderFooter(title)
        return (
            <div className="amps-task-package-operate">
                <div className="bold amp-operate-title mg-b-20">
                    <Title title={title} />
                </div>
                <div className="amp-operate-content">
                    <Form ref={this.formRef} className='task-form'>
                        {res}
                        {
                            title === 5 && (this.renderPriority())
                        }
                        {
                            title === 6 || title === 9 && (this.renderComment())
                        }
                    </Form>
                    {
                        title === 4 && (<TaskPackageReview />)
                    }
                </div>
                <div className="amp-operate-footer mg-t-14">
                    {
                        footer
                    }
                    {
                        <AuthButton
                            className="mg-r-20"
                            onClick={() => {
                                this.handleClose();
                            }}
                        >{ title === 4 ? "返回" : "取消" }</AuthButton>
                    }
                </div>
            </div>
        )
    }
}

export default TaskPackageOperate;
