import axios from 'axios';

// 策划任务相关接口
const taskPlanAPI = {
    // 创建策划任务
    createPlanTask(params) {
        return axios.post("/package-item/create", params);
    },
    // 获取策划任务
    getPlanTaskList(params) {
        return axios.post("/package-item/list", params);
    },
    // 更新策划任务
    updatePlanTask(params) {
        return axios.post("/package-item/update", params)
    },
    // 批量创建策划任务
    volumnCreatePlanTask(params) {
        return axios.post("/package-item/upload", params);
    },
    // 通过策划任务
    approvePlanTask(id) {
        return axios.post("/package-item/approve", id);
    },
    // 驳回策划任务
    disapprovePlanTask(id) {
        return axios.post("/package-item/disapprove", id);
    },
    // 作废策划任务
    discardPlanTask(id) {
        return axios.post("/package-item/discard", id);
    },
    // 评论策划任务
    commentPlanTask(params) {
        return axios.post("/package-item/comment", params);
    }
};

export default taskPlanAPI;
