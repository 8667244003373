import React, { useState, useMemo, useEffect } from 'react'
import { Form, Select, Input } from 'antd';
import { useSwr } from '@hooks';
import { taskFlowAPI } from '@api'
import { toJS } from 'mobx';

const { Option } = Select;

/**
 * 入库重置表单
 * @param {*} props
 * @returns
 */
const TaskStockInReset = (props) => {

    const { currentTask, formRef } = props
    // 重置后的状态
    const [status, setStatus] = useState(null)
    // 用户类型
    const [userType, setUserType] = useState(null)
    // 原处理人
    const [processor, setProcessor] = useState(null)
    const { data: configData } = useSwr(currentTask.id || null, (id) => taskFlowAPI.godownEntryResetConfig({ task_id: id }).then((res) => {
        return res.data
    })
    )

    const { data: userList = [] } = useSwr(userType, (type) => taskFlowAPI.getUserList({ user_type: type }).then((res) => {
        return res?.data?.list.map(item => ({
            value: item.id,
            label: item.name
        }))
    })
    )

    const { allow_status, reset_reason_config } = useMemo(() => {
        let allow_status = []
        let reset_reason_config = []

        if (configData?.allow_status) {
            allow_status = configData.allow_status.map(item => ({
                ...item,
                value: item.code,
            }))
        }
        if (configData?.reset_reason_config) {
            reset_reason_config = configData.reset_reason_config.map(item => ({
                ...item,
                value: item.code,
            }))
        }

        return {
            allow_status,
            reset_reason_config
        }

    }, [configData])

    const selectDome = (params) => {
        const {
            title,
            message,
            placeholder,
            formName,
            initialValue,
            options,
            onChange,
        }
            = params
        return (
            <div className="process-form-col-2">
                <div className="mg-b-8 required bold">{title}</div>
                <Form.Item
                    name={formName}
                    rules={[{ required: true, message: message }]}
                    initialValue={initialValue}
                >
                    <Select
                        className='input-sz-m'
                        showSearch
                        placeholder={placeholder}
                        optionFilterProp="label"
                        size="large"
                        options={options}
                        onChange={onChange}
                    />
                </Form.Item>
            </div>
        )
    }

    useEffect(() => {
        const currentStatus = allow_status.find(item => item.value === status) || {}
        setUserType(currentStatus.user_type || null)
        setProcessor(currentStatus.user?.name || null)
        formRef?.current?.setFieldsValue({
            assignee_id: null,
            reset_user_type: currentStatus.user_type || null
        })
    }, [status])

    return (
        <>
            {
                selectDome({
                    title: '重置原因',
                    message: '请选择重置原因',
                    placeholder: '请选择重置原因',
                    formName: 'reset_reason_code',
                    initialValue: 1,
                    options: reset_reason_config,
                })
            }
            {
                selectDome({
                    title: '重置后状态',
                    message: '请选择重置后状态',
                    placeholder: '请选择重置后状态',
                    formName: 'reset_status_code',
                    options: allow_status,
                    onChange: (value) => {
                        setStatus(value)
                    }
                })
            }
            <div className="process-form-col-2">
                <div className="mg-b-8 bold">原处理人</div>
                <Form.Item
                >
                    <Input
                        className='input-sz-m mg-b-16'
                        value={processor}
                        placeholder="选择状态后自动带出"
                        disabled
                    />
                </Form.Item>
            </div>
            {
                selectDome({
                    title: '待处理人',
                    message: '请选择待处理人',
                    placeholder: '请选择待处理人',
                    formName: 'assignee_id',
                    options: userList,
                })
            }
            <Form.Item
                hidden
                label="重置的用户类型"
                name="reset_user_type"
            >
                <Input />
            </Form.Item>
        </>
    )
}

export default TaskStockInReset
