import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Select, Input } from "antd";
import { AuthButton } from "@components";
import { UserPart, TypePart } from "./";
const { Option } = Select;

@inject("addRelationStore")
@observer
class AddRelation extends Component {
  get store() {
    return this.props.addRelationStore;
  }

  componentDidMount() {
    this.store.fetchAllUsers()
  }
  componentWillUnmount() {
    this.store.clear();
  }

  handleSubmit = () => {
    this.store.createShootingTeamRelationRequest();
  };

  hide = () => {
    this.store.hide();
    this.store.clear()
  };

  renderTypeSelect = () => {
    const options = this.store.shootingTeamList?.map((item, idx) =>
      item.id ? (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ) : (
        ""
      )
    );
    return this.store?.shootingTeamList?.length > 0 ? (
      <div className="mg-b-30">
        <div className="bold">所属拍摄小组</div>
        <Select
          className="select-sz-m mg-t-12"
          size="large"
          onChange={(code) => this.store.changeShootingTeamTypeActive(code)}
          placeholder="请选择人员类型"
          value={this.store.shootingTeamType}
          showSearch optionFilterProp="children"
          showArrow
        >
          {options}
        </Select>
      </div>
    ) : (
      ""
    );
  };

  renderFooter = () => {
    return (
      <div className="dialog-footer">
        <AuthButton
          type="primary"
          className="mg-r-12"
          onClick={this.handleSubmit}
        >
          确认
        </AuthButton>
        <AuthButton onClick={this.hide}>取消</AuthButton>
      </div>
    );
  };

  renderFilter = () => {
    const { addType } = this.store;
    return (
      <div className="display-flex">
        <div className="mg-r-20">
          <div className="bold">添加方式</div>
          <Select
            className="select-sz-m mg-t-12"
            size="large"
            value={addType}
            onChange={(val) => this.store.updateAddType(val)}
          >
            <Option value="group">组织添加</Option>
            <Option value="role">角色添加</Option>
          </Select>
        </div>
        <div>
          <div className="bold">检索用户</div>
          <Input
            placeholder="检索用户名称"
            value={this.store.name_email}
            onChange={(e) => this.store.updateNameEmail(e.target.value)}
            className="input-sz-m mg-t-12"
            allowClear
          />
        </div>
      </div>
    );
  };

  renderSelections = () => (
    <div className="display-flex mg-t-20">
      <div style={{ width: "66%" }}>
        <TypePart />
      </div>
      <div style={{ width: "33%" }}>
        <UserPart />
      </div>
    </div>
  );

  render() {
    const { visible } = this.store;
    return (
      <Modal
        centered
        visible={visible}
        onCancel={this.hide}
        destroyOnClose
        footer={null}
        width="704px"
      >
        <div className="dialog-pd">
          <div className="dialog-title">拍摄小组成员关联</div>
          <div className="dialog-content">
            {this.renderTypeSelect()}
            {this.renderFilter()}
            {this.renderSelections()}
          </div>
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}
export default AddRelation;
