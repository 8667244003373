import { autorun, set, toJS } from "mobx";

const autoSave = (_this, name) => {
  const storedJSON = sessionStorage.getItem(name);
  if (storedJSON) {
    set(_this, JSON.parse(storedJSON));
  }
  autorun(() => {
    const value = toJS(_this);
    sessionStorage.setItem(name, JSON.stringify(value));
  });
};

export default autoSave;
