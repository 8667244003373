import { Form, Radio } from "antd";
import { AddEditFooter, AddEditPhotographer, Upload, AddEditConfidentialUsers } from "./renders";
import {
  AddEditDate,
  AddEditTitle,
  AddEditDescription,
} from "./renders/add_edit_small";
import React, { Component } from "react";
import { rules, projectDIC } from "@vars/views/projects_vars";
import { UserSelect } from "@components";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
@inject("configsStore", "projectStore", "userRelationsStore")
@observer
class AddEditForm extends Component {
  state = {
    showSelect: this.currentProject.is_confidential ? true : false,
  }
  get configsStore() {
    return this.props.configsStore;
  }
  get projectStore() {
    return this.props.projectStore;
  }
  get userRelationsStore() {
    return this.props.userRelationsStore;
  }
  componentDidMount() {
    const { type } = this.props
    this.configsStore.fetchAllUsers();
    this.projectStore.fetchProfile();
    this.projectStore.setRelatedUsers();
    const { photographer } = this.currentProject;
      if (photographer) {
        this.projectStore.setCurrentPhotographer(photographer);
      } else {
        this.projectStore.setCurrentPhotographer({});
      }
    if (type === "add") {
      this.projectStore.setFinal();
      const final  = this.projectStore.getFinalId();
      this.formRef.current.setFieldsValue({
        confidential_user_ids: [...final]
      })
    } else if (type === "edit") {
      const { confidential_users } = this.currentProject
      const temp = [];
      confidential_users && confidential_users.map(item => {
        temp.push(item.id);
      })
      this.projectStore.final = confidential_users;
      this.formRef.current.setFieldsValue({
        confidential_user_ids: [...temp]
      })
    }
  }
  componentWillUnmount() {
    this.projectStore.currentPhotographer = {};
    this.projectStore.loginUser = {};
    this.projectStore.confidentList = [];
    this.projectStore.final = [];
    this.projectStore.defaultCheckedOptions = []
    this.projectStore.selectList = [];
    this.projectStore.profileGroupList = [];
  }

  formRef = React.createRef();
  uploadRef = React.createRef();
  get currentProject() {
    const props = { ...this.props };
    const { projectId, projects } = props;
    return projects[projectId] || this.props.currentProject;
  }
  getFiles = () => this.uploadRef.current.state.fileList;
  renderPhotographers = () => {
    const { photographer } = this.currentProject;
    const { allUsers } = this.configsStore;
    const { type } = this.props

    const changehandle = (e) => {
      if (e) {
        let temp = {}
        allUsers.map((item) => {
          if (item.id === e) {
            temp = item;
          }
        });
        const { id, name, username } = temp
        const obj = {
          id,
          name,
          username,
        }
        this.projectStore.setCurrentPhotographer(obj);
        this.projectStore.setFinal();
        if (type === "add") {
          const { loginUser, confidentList } = this.projectStore;
          const temp = [];
          [loginUser, obj, ...confidentList].map(item => {
            temp.push(item.id)
          });
          this.formRef.current.setFieldsValue({
            confidential_user_ids: this.projectStore.unique([...temp])
          });
          this.projectStore.final = [loginUser, obj, ...confidentList];
        } else if (type === "edit") {
          let { confidential_users } = this.currentProject;
          const { loginUser, confidentList } = this.projectStore
          const temp = [];
          if (confidential_users) {
            this.projectStore.uniqueFunc([obj, ...confidential_users]).map(item => {
              temp.push(item.id);
            });
            this.projectStore.final = [obj, ...confidential_users];
          } else {
            this.projectStore.uniqueFunc([loginUser, obj, ...confidentList]).map(item => {
              temp.push(item.id);
            });
            this.projectStore.final = [loginUser, obj, ...confidentList];
          }
          this.formRef.current.setFieldsValue({
            confidential_user_ids: [...temp]
          });
        }
      } else {
        this.projectStore.setCurrentPhotographer({});
      }
    }
    return (
      <Form.Item
        className="form-item"
        name="photographer_id"
        rules={rules.photographer}
        label={projectDIC.photographer.title}
        initialValue={
          photographer ? (photographer.id !== 0 ? photographer.id : null) : null
        }
      >
        <UserSelect
          placeholder={projectDIC.photographer.formPlaceholder}
          className="input-sz-m"
          params={{type: 2}}
          onChange={changehandle}
        />
      </Form.Item>
    );
  };
  closeShowSelect = () => {
    this.setState(() => {
      return {
        showSelect: false,
      }
    })
  }
  judgeObjIsEmpty = (obj) => {
    for(var item in obj){
        return true;
    }
    return false;
}
  renderIsConfidential = () => {
    const { is_confidential } = this.currentProject;
    const changeHandle = e => {
      this.projectStore.setFinal();
      const final  = this.projectStore.getFinalId();
      this.formRef.current.setFieldsValue({
        confidential_user_ids: [...final]
      })
      this.setState({
        showSelect: !this.state.showSelect
      })
    }
    return (
      <Form.Item
        className="form-item"
        name="is_confidential"
        rules={rules.is_confidential}
        label={projectDIC.is_confidential.title}
        style={{
          marginBottom: "16px"
        }}
        initialValue={
          is_confidential ? is_confidential : 0
        }
      >
        <Radio.Group onChange={changeHandle}>
          <Radio value={1} className="mg-r-50">是</Radio>
          <Radio value={0}>否</Radio>
        </Radio.Group>
      </Form.Item>
    );
  };
  renderIsPublic = () => {
    const { is_public } = this.currentProject;
    return (
      <Form.Item
        className="form-item"
        name="is_public"
        rules={rules.is_public}
        label={projectDIC.is_public.title}
        style={{
          marginBottom: "16px"
        }}
        initialValue={
            is_public === 0 ? is_public : 1
        }
      >
        <Radio.Group>
          <Radio value={1} className="mg-r-50">是</Radio>
          <Radio value={0}>否</Radio>
        </Radio.Group>
      </Form.Item>
    );
  };
  render() {
    const props = { ...this.props };

    return (
      <Form
        layout="vertical"
        ref={this.formRef}
        validateTrigger="onBlur"
        preserve={false}
      >
        <div className="dialog-content-middle">
          <AddEditTitle {...props} />
          <div className="two-col-form dialog-content-middle-box">
            <AddEditDate {...props} />
            {this.renderPhotographers()}
          </div>
          <AddEditDescription {...props} />
          <Upload
            ref={this.uploadRef}
            {...props}
            entity={this.currentProject}
            showIndicator
            titleClassName="bold"
          />
          <div className="mg-t-30">
                {/* 制作池素材是否公开 */}
                {this.renderIsPublic()}
          </div>
          <div className="mg-t-30">
            {this.renderIsConfidential()}
            {(this.state.showSelect)  && <AddEditConfidentialUsers refTag={this.formRef} closeShowSelect={this.closeShowSelect} {...props} />}
          </div>
        </div>
        <AddEditFooter
          {...props}
          form={this.formRef}
          getFiles={this.getFiles}
        />
      </Form>
    );
  }
}

export default AddEditForm;
