/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description: 分配
 */
import { useDebounceState, useSend } from '@hooks';
import { AllotTake, taskFlowAPI } from '@api';
import { $error, $success, Button, ScrollContainer } from '@components';
import { Col, Row, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { PoolItem } from '@views/pool/components/PoolItem';
import { useParams } from 'react-router-dom';
import { FilterForm } from '@views/pool/components/FilterForm';

const getProduceAllList = (page, pageSize, params) => {
    return taskFlowAPI.getAllProduce({
        page: page,
        page_size: pageSize,
        filter: params
    })
        .then(res => {
            if (res.code === 1000) {
                return {
                    list: res.data.list,
                    statusTotal: res.data.status_total,
                    total: res.data.total
                };
            } else {
                throw new Error(res.msg);
            }
        });
};
const getProduceList = (page, pageSize, params) => {
    const {
        project_id,
        ...other
    } = params;
    return taskFlowAPI.getProduceList({
        page: page,
        page_size: pageSize,
        project_id,
        filter: other
    })
        .then(res => {
            if (res.code === 1000) {
                return {
                    list: res.data.list,
                    statusTotal: res.data.status_total,
                    total: res.data.total
                };
            } else {
                throw new Error(res.msg);
            }
        });
};
const Distribute = (props) => {
    const {
        onCancel,
        tmp
    } = props;
    const scrollContainerRef = useRef(null);
    const { id: projectId } = useParams();
    const receiveFormRef = useRef(null);
    const [selectIds, setIds] = useState([]);
    const [params, debounceParams, setParams] = useDebounceState({
        is_split: 0,
        parent_id: undefined,
        uid_name: undefined,
        produceInfo: null
    });
    const [loading, send] = useSend((ids) => {
        return AllotTake({
            task_id: ids,
            user_id: params.produceInfo.user_simple_info.id
        });
    }, {
        onError(error) {
            $error(error.message);
        },
        onSuccess() {
            setIds([]);
            scrollContainerRef.current?.refresh();
            receiveFormRef.current?.refresh();
            $success('分配成功');
        }
    });

    function test11() {
        receiveFormRef.current?.refresh();

    }

    const onSelect = (item) => {
        // 拿到拆分任务id数组
        // 后端返回的sibling包含所有状态的兄弟拆分任务，没有区分是否处在制作待选状态，8对应后端定义的制作待选状态
        const temp = []
        item?.sibling.map(({id, task_status}) => {
            if (task_status.code === 8) {
                temp.push(id);
            }
        });
        temp.push(item.id);
        // 获取已选id索引
        const index = selectIds.indexOf(item.id);
        let ids = [...selectIds];
        if (index > -1) {
            // 移除
            ids = ids.filter((item) => {
                return temp.indexOf(item) === -1;
            })
        } else {
            ids.push(...temp);
        }
        setIds(ids);
    };
    const onChange = (values) => {
        setIds([]);
        setParams(state => ({
            ...state,
            ...values
        }));
    };
    const onSplit = (item) => {
        receiveFormRef.current?.setParentId(item.parent_id);
    };

    const parentIdParams = useMemo(() => ({
        is_split: debounceParams.is_split,
        parent_id: debounceParams.parent_id,
        uid_name: debounceParams.uid_name,
        project_id: projectId !== undefined ? Number(projectId) : undefined
    }), [
        projectId,
        debounceParams.is_split,
        debounceParams.parent_id,
        debounceParams.uid_name]);
    return <div className={'receive-content'}>
        <FilterForm
            tmp={tmp}
            className={'receive-header px-4 py-2'}
            ref={receiveFormRef}
            value={params}
            onChange={onChange}
        />
        <div className={'receive-body pl-3'}>
            <ScrollContainer
                col={4}
                pageSize={16}
                ref={scrollContainerRef}
                className={'receive-body-scroll'}
                url={'/produce/all'}
                params={parentIdParams}
                renderItem={(col, key) => <Row key={key} gutter={16} style={{ marginBottom: 16 }}>
                    {col.map(item => <Col key={item.id} span={6}>
                        <PoolItem
                            item={item}
                            tmp={2}
                            split={debounceParams.parent_id === item.parent_id}
                            onSplit={onSplit}
                            disabled={!params.produceInfo}
                            select={selectIds.includes(item.id)} onSelect={onSelect} />
                    </Col>)}
                </Row>}
                request={projectId !== undefined ? getProduceList : getProduceAllList}
            />
        </div>

        <Space size={16} className={'receive-footer px-4 pt-2 pb-3'}>
            <Button
                type={'primary'}
                loading={loading}
                onClick={() => send(selectIds)}
                disabled={!(params.produceInfo && selectIds.length)}
            >分配({selectIds.length})</Button>
            <Button onClick={onCancel}>取消</Button>
        </Space>
    </div>;
};
export default Distribute;
