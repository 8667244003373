import { useParams } from 'react-router-dom';
import { useSwr } from '@hooks';
import taskPackageAPI from '../../api/2_projects/task_package_api';
import { withSuspense } from '@/HOC';
import Collapse from './components/Collapse';
import { AmpIcon, Typography } from '@components';
import { BasicInfo, HistoryData } from './components/DetailComponents';
import MissionInfo from './components/MissionInfo';
import { TaskProvider, useTaskContext } from './index';
import { useRef } from 'react';
import { ActionBtns } from '@views/taskPackage/components/ActionBtns';

const { Panel } = Collapse;
/**
 * @name: TaskDetails.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 任务包详情
 */
const classPrefix = 'detail-body';
const TaskDetails = function({ parentUrl }) {
    const taskContext = useTaskContext()
    const missionInfoRef = useRef()
    const {
        taskId,
    } = useParams();
    const {
        data: infoDataSource,
        mutate: reInfoData
    } = useSwr(taskId ? ['/package/info', taskId] : null, (url, id) => taskPackageAPI.getPackageInfo(id), {
        suspense: true
    });
    const {
        data: historyDataSource,
        mutate: reHistoryData
    } = useSwr(taskId ? ['/package/history', taskId] : null, (url, id) => taskPackageAPI.getPackageHistory(id), {
        suspense: true
    });
    const { data } = infoDataSource;
    return <TaskProvider value={Object.assign(taskContext, {
        parentUrl: parentUrl,
        infoDataSource: data, // 任务包详情
        isPlanUser: !!data.users?.find(user => user.id === taskContext.session.id), // 当前登录用户是否在策划组中
        refreshTaskDetails: reInfoData,
        refreshTaskInfoList: () => {
            missionInfoRef.current?.refresh()
        },
        refreshTaskHistoryData: reHistoryData
    })}>
        <div className={`${classPrefix}-main`}>
            <Collapse className={`${classPrefix}-main-content ${classPrefix}-detail`}>
                <Typography variant={'h3'}>任务包： {data.no} {data.title}</Typography>
                <div className={'mt-14 flex-align-center'}>
                    <div className={'task-tags'}>
                        <Typography  className={'task-tag'} style={{ background: data.status.color }}>
                            {data.status.label}
                        </Typography>
                    </div>
                    <Typography className={'flex-align-center ml-10'}>
                        <AmpIcon name={'analysis'} className={'mr-7'} />
                        {data.item_count || 0}个
                        <AmpIcon name={'score'} className={'mr-7 ml-10'} />
                        {data.score || 0}分
                    </Typography>
                </div>
                <Panel key={11} title={'基础信息'} className={'mt-14'}>
                    <BasicInfo data={data} />
                </Panel>
                <Panel key={22} title={'任务信息'} className={'mt-14'}>
                    <MissionInfo ref={missionInfoRef} taskId={Number(taskId)} />
                </Panel>
                <Panel key={33} title={'任务包流程'} opne={true} className={'mt-14'}>
                    <HistoryData data={historyDataSource.data} />
                </Panel>
            </Collapse>
        </div>
        <ActionBtns infoData={data} />
    </TaskProvider>;

};
export default withSuspense(TaskDetails);
