import React, { } from 'react'
import { Select, Radio, Form, Input, Row, Col, Cascader } from 'antd';
import ImageUploadCheck from '@components/2_data_input/upload/imageUploadCheck'
import SearchArea from '@components/gaode_map/search/index'
import CascaderTheme from './cascader_theme'

const AddTopicFormItem = (props) => {

    const { disabled, outlineOptions, themeOptions, userList } = props

    const label = (text) => {
        return <span className="amp-create-topic-label">{text}</span>
    }

    return (
        <>
            <Row gutter={32} justify="space-between">
                <Col span={12}>
                    <Form.Item
                        label={label('选题名称')}
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: '请输入选题名称'
                            }
                        ]}
                    >
                        <Input
                            maxLength={50}
                            disabled={disabled}
                            placeholder="请输入选题名称"
                        />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        label={label('选题性质')}
                        name='type'
                        initialValue={1}
                    >
                        <Radio.Group
                            disabled={disabled}
                        >
                            <Radio value={1}>内部</Radio>
                            <Radio value={2}>商务</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={label('参考图')}
                        name='imagesList'
                        rules={[
                            {
                                required: true,
                                message: '请上传参考图'
                            }
                        ]}
                    >
                        <ImageUploadCheck
                            maxCount={10}
                            disabled={disabled}
                            fileSize={20}
                            width="120px"
                            purpose="topic"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={label('描述')}
                        name="detail"
                    >
                        <Input.TextArea
                            autoSize={{
                                minRows: 3,
                                maxRows: 5
                            }}
                            placeholder='请对该选题方向进行描述'
                            maxLength={500}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={32} justify="space-between">
                <Col span={12}>
                    <Form.Item
                        label={label('策划人员')}
                        name='planUserId'
                        rules={[
                            {
                                required: true,
                                message: '请选择策划人员'
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择策划人员"
                            optionFilterProp="label"
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                            options={userList}
                            disabled={disabled}
                        />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        label={label('大纲')}
                        name='outlineId'
                        rules={[
                            {
                                required: true,
                                message: '请选择真实环境所在的省份'
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择真实环境所在的省份"
                            optionFilterProp="label"
                            disabled={disabled}
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                            options={outlineOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={label('主题')}
                        name='scenesList'
                        rules={[
                            {
                                required: true,
                                message: '请选择相关的主题'
                            }
                        ]}
                    >
                        <CascaderTheme
                            showSearch
                            placeholder="请选择相关的主题"
                            expandTrigger="hover"
                            fieldNames={{
                                label: 'title', value: 'id', children: 'childrenList'
                            }}
                            disabled={disabled}
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                            options={themeOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={label('地址')}
                        name='addressList'
                        rules={[
                            {
                                required: true,
                                message: '请选择地址'
                            }
                        ]}
                    >
                        <SearchArea disabled={disabled} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default AddTopicFormItem
