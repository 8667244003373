import AnnouncementTable from './announcement_table'
import { fetchAnnouncements } from "@actions/1_entities/announcements_actions";
import {
  publishAnnouncement,
  removeAnnouncement,
} from "@actions/1_entities/announcement_actions";
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  entity: state.entities.announcements,
  currentPage: state.pagination.announcements.currentPage,
  pages: state.pagination.announcements.pages,
  total: state.pagination.announcements.total,
  filter: state.pagination.announcements.filter,
});
const mapDispatchToProps = (dispatch) => ({
  fetchItems: (params, success, error) =>
    dispatch(fetchAnnouncements(params, success, error)),
  publishAnnouncement: (params, success, error) =>
    dispatch(publishAnnouncement(params, success, error)),
  removeAnnouncement: (params, success, error) =>
    dispatch(removeAnnouncement(params, success, error)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementTable);
