import { useState, useEffect } from 'react'
import { useParentRoute } from '@components/router/routes.js'
import { useHistory } from "react-router"
import { boldExportFile } from '@gUtils/downFile'
import { businessDataAPI } from '@api'
import { useSelector } from "react-redux";
import { checkAuth } from '@gUtils'
import { $error, Iconfont, $success } from "@/components";
import { Table, Popover } from 'antd'
import { summaryWeekColumn, summaryMonthColumn, emptyWeekColumns, emptyMonthColumns, useWindowSize, defaultTime } from './../config'
import { useTabsContext } from '@/layouts/barExtraContentLayout';
import BusinessDataKpiForm from './filterForm'
import ModifyScoreModal from './modify_score_modal'
import './../../index.less'

/**
 * 制作组周月统计
 * @returns {JSX.Element} ProductGroupNew
 */
const ProductGroupNew = () => {
    const route = useParentRoute()
    // 月度补分权限
    const permissions = 'update_supplement_score'
    const userPermissions = useSelector(state => state.session.permissions)
    const updateSupplementScore = checkAuth(userPermissions, permissions);
    const [dataList, setDataList] = useState([]); //列表数据
    const [loading, setLoading] = useState(true); // 列表loading
    const [btnLoading, setBtnLoading] = useState(false); // 导出按钮loading
    const [searchForm, setSearchForm] = useState({}); // pagination
    const [expandedRowKeys, setExpandedRowKeys] = useState([]); // 额外行key
    const [exportParams, setExportParams] = useState(); // 导出数据
    const [summaryData, setSummaryData] = useState([]); //合计栏数据
    const [isShowSummary, setIsShowSummary] = useState(true); //是否显示合计栏
    const [idealProcess, setIdealProcess] = useState([]); // 理想进度
    const [visible, setVisible] = useState(false); // 补分弹框
    const [supplyScoreRecord, setSupplyScoreRecord] = useState(); // 补分record
    const scrollHeight = useWindowSize()
    const [clientHeight, setClientHeight] = useState(800);
    const { calendarVisible } = useTabsContext(); // 关闭日历更新数据
    const history = useHistory()

    useEffect(() => {
        let setHeight = document.body.clientHeight > 800 ? document.body.clientHeight : 800
        setClientHeight(setHeight)
    }, [scrollHeight.width, scrollHeight.height]);

    useEffect(() => {
        if (searchForm.user_id) {
            setIsShowSummary(false)
            getUserListRequest()
        } else {
            setIsShowSummary(true)
            getGroupListRequest()
        }
        setExpandedRowKeys([])
    }, [searchForm, calendarVisible]);

    /**
     * 获取制作组 组列表
     * @returns {Object}
     */
    const getGroupListRequest = async () => {
        const { user_id, start_time = '', end_time = '' } = searchForm
        setLoading(true)
        let params = {
            start_time: Number(start_time || (route.key === "week" ? defaultTime.defaultStartWeek : defaultTime.defaultStartMonth)),
            end_time: Number(end_time || (route.key === "week" ? defaultTime.defaultEndWeek : defaultTime.defaultEndMonth)),
            user_id: user_id || 0,
            group_id: 0,
            is_month: route.key === "month" ? true : false,
            is_week: route.key === "week" ? true : false,
        }
        setExportParams(params);
        try {
            let res = await businessDataAPI.getGroupList(params)
            if (res.code === 1000) {
                setLoading(false)
                setIdealProcess(res.data.ideal_process)
                const expendList = res.data.infos.map((item) => {
                    return { ...item, parentLine: true }
                })
                res.data.summary['name'] = '合计'
                setSummaryData([res.data.summary])
                setDataList(expendList)
            } else {
                $error(res.mag);
            }
        } catch (err) { }
    }

    /**
     * 获取制作组 组员列表
     * @returns {Object}
     */
    const getUserListRequest = async (data) => {
        const { user_id, start_time = '', end_time = '' } = searchForm
        setLoading(true)
        const params = {
            start_time: Number(start_time || (route.key === "week" ? defaultTime.defaultStartWeek : defaultTime.defaultStartMonth)),
            end_time: Number(end_time || (route.key === "week" ? defaultTime.defaultEndWeek : defaultTime.defaultEndMonth)),
            user_id: user_id || 0,
            group_id: data?.group_id || 0,
            is_month: route.key === "month" ? true : false,
            is_week: route.key === "week" ? true : false,
        }
        setExportParams(params);
        try {
            let res = await businessDataAPI.getGroupUserList(params)
            if (res.code === 1000) {
                setLoading(false)
                if (user_id) {
                    setDataList(res.data.infos)
                } else {
                    const expendChildren = dataList?.map((item) => {
                        if (item.group_id === data.group_id) {
                            item["children"] = res.data.infos
                        }
                        return item
                    })
                    setDataList(expendChildren)
                }
            } else {
                $error(res.mag);
            }
        } catch (error) { }
    }

    /**
     * 提交表单
     * @param {*} value
     */
    const submitForm = (value) => setSearchForm({ ...searchForm, ...value })

    /**
     * 重置表单
     */
    const resetForm = () => {
        setExpandedRowKeys([])
        setSearchForm({});
    };

    /**
     * 导出全部数据
     * @param {*} handleCsvRef
     */
    const csvExport = () => {
        setBtnLoading(true)
        businessDataAPI.exportWeekOrMonthSummary(exportParams).then((blob) => {
            setBtnLoading(false)
            boldExportFile(blob);
            $success('导出成功');
        }).catch((err) => {
            $error(err.message);
        });
    };

    /**
     * 跳转统计明细
     * @param {*} record
     */
    const handleDetail = (record) => {
        history.push({
            pathname: `/stats/business-data-overview/kpi/${route.key}-detail`,
            user_id: record?.user_id,
            group_id: record?.group_id,
            data: exportParams
        });
    }

    /**
     * 补分弹框
     */
    const handleUpdateScore = (record) => {
        setSupplyScoreRecord({
            user_id: record.user_id,
            time: exportParams?.start_time ? exportParams.start_time : defaultTime.defaultStartMonth
        })
        setVisible(true)
    }

    /**
     * 风险气泡内容
     * @returns {JSX.Element}
     */
    const content = (record) => {
        if (record.progress_rate >= idealProcess) {
            return <div>已达标，当前理想进度{idealProcess}%</div>
        }
        return <div>未达标，当前理想进度{idealProcess}%</div>
    }

    /**
    * 列表风险提示
    */
    const IdealProcess = {
        width: '4%',
        render: (_, record) => (
            <span className='cursor-pointer'>
                <Popover content={() => content(record)}>
                    {
                        record.progress_rate >= idealProcess ? <Iconfont type="icon-jinduzhengchang" /> : <Iconfont type="icon-jinduyichang" />
                    }
                </Popover>
            </span>
        )
    }

    // table columns
    const emptyClos = route.key === 'week' ? [...summaryWeekColumn, IdealProcess, ...emptyWeekColumns] : [...summaryMonthColumn, IdealProcess, ...emptyMonthColumns]
    const Clos = route.key === 'week' ? [...summaryWeekColumn, IdealProcess, {
        title: '操作',
        width: '10%',
        render: (record) => <span className="amp-blue-btn cursor-pointer" onClick={() => handleDetail(record)}>查看 </span>
    }] : [...summaryMonthColumn, IdealProcess, {
        title: '操作',
        width: '10%',
        render: (record) => (
            <>
                {updateSupplementScore && record.user_id > 0 && <span className="amp-blue-btn mg-r-22 cursor-pointer" onClick={() => handleUpdateScore(record)}>补分</span>}
                <span className="amp-blue-btn cursor-pointer" onClick={() => handleDetail(record)}> 查看 </span>
            </>
        )
    }]

    /**
     * antd 额外展开行
     * @param {*} expanded 是否展开
     * @param {*} record 行数据
     */
    const onExpand = (expanded, record) => {
        if (expanded) {
            getUserListRequest(record)
        }
    }
    const handleSetRowKey = (rowKeys) => setExpandedRowKeys(rowKeys)
    const expandedRowRender = (record) => <p key={record.group_id}></p>
    /**
     * 提交补分表单
     */
    const submitModalForm = () => {
        setVisible(false)
        setExpandedRowKeys([])
        getGroupListRequest()
    }
    const closeModal = () => setVisible(false)

    return (
        <div className='amps-business-data page-container'>
            <BusinessDataKpiForm
                submitForm={submitForm}
                resetForm={resetForm}
                csvExport={csvExport}
                btnLoading={btnLoading}
            />
            {
                visible && (
                    <ModifyScoreModal
                        visible={visible}
                        supplyScoreRecord={supplyScoreRecord}
                        closeModal={closeModal}
                        submitForm={submitModalForm}
                    />
                )
            }

            <Table
                scroll={{ y: clientHeight - 400 }}
                columns={Clos}
                dataSource={dataList}
                pagination={false}
                loading={loading}
                rowKey={(record) => record.group_id + record.user_id}
                rowExpandable={(record) => record.parentLine}
                expandedRowKeys={expandedRowKeys}
                onExpand={(expanded, record) => onExpand(expanded, record)}
                expandedRowRender={record => expandedRowRender(record)}
                onExpandedRowsChange={(rowKeys) => handleSetRowKey(rowKeys)}
            />
            {
                isShowSummary && (
                    <div className="amp-table-summary-no-thead">
                        <Table
                            scroll={{ y: clientHeight - 400 }}
                            showHeader={false}
                            columns={emptyClos}
                            pagination={false}
                            rowKey="group_id"
                            dataSource={summaryData}
                            expandedRowRender={record => expandedRowRender(record)}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default ProductGroupNew
