import React from 'react';
import { AuthButton } from "@components";
import { taskActionLabels } from "@vars/views/task_flow_vars";

const ActionButtons = (props) => {
    const { actions, updateActionStatus, isAssignee, permissions, taskStatus } = props;
    const flag = permissions?.indexOf("produce_rollback");
    const isRemake = permissions?.indexOf("task_remake"); // 重置资产权限
    const isGodownRemake = permissions?.indexOf("godown_remake"); // 入库重置资产权限
    return (
        <div className="align-center">
            {actions?.map((item, idx) => {
                const { action, next_status } = item;
                const isDiscard = action === "discard_task";
                const props = {
                    key: idx,
                    onClick: () => updateActionStatus(action, next_status),
                    className: "mg-r-12",
                };
                let res = null
                let btn = (
                    <AuthButton {...props} type="primary" key={props.key}>
                        {taskActionLabels(action)}
                    </AuthButton>
                )
                // 判断file_to_stock 入库流程 增加两个按钮泛素材，项目素材
                // 并且携带额外配置参数
                if (action === 'file_to_stock') {
                    btn = (
                        <React.Fragment key={props.key}>
                            <AuthButton
                                className="mg-r-12"
                                type="primary"
                                onClick={() => updateActionStatus(action, next_status, {
                                    materialType: 2
                                })}
                            >
                                入库泛素材
                            </AuthButton>
                            <AuthButton
                                className="mg-r-12"
                                type="primary"
                                onClick={() => updateActionStatus(action, next_status, {
                                    materialType: 1
                                })}
                            >
                                入库项目素材
                            </AuthButton>
                        </React.Fragment>
                    )
                }

                if (action === "rollback_production") {
                    res = flag > -1 && !isDiscard ? btn : "";
                } else if (action === "remake_task") {
                    // 重置按钮显示，- 作废重置
                    if (isRemake > -1 && taskStatus !== 18) {
                        res = (
                            <AuthButton {...props} type="primary" key={props.key}>
                                {taskStatus === 19 ? "作废重置" : taskActionLabels(action)}
                            </AuthButton>
                        )
                    }
                } else if (action === "remake_asset_godown") {
                    // 重置按钮显示，- 入库重置
                    if (isGodownRemake > -1) {
                        res = btn
                    }
                } else {
                    res = isAssignee && !isDiscard ? btn : "";
                }
                return res;
            })}
        </div>
    );
};
export default ActionButtons
