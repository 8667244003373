import React, { memo, useState } from 'react'
import { Table } from 'antd'
import { useSwr } from '@hooks';
import SearchFrom from './search_from'
import { productionStatsAPI } from '@api'

function ProjectSituation(props) {
    const {
        columns,
        type = 'project',
        theme = '项目',
        pageSize = 10
     } = props
    const [pagination, setPagination] = useState({
        pageSize: pageSize,
        current: 1
    })

    // 发起请求获取项目数据
    const getTableRequst = (url, searchPage, type) => {
        let params = {
            ...searchPage,
            page: searchPage.current,
            page_size: searchPage.pageSize
        }
        delete params.current
        delete params.pageSize
        if (type === 'task') {
            return productionStatsAPI.getTaskInfosRealTime(params).then(res => {
                if (res.code === 1000) {
                    res.data.infos = res.data.infos.map((item, index) => ({ ...item, key: index }))
                    return res.data
                }
                throw new Error(res.msg || '');
            })
        }
        return productionStatsAPI.getProjectInfosRealTime(params).then(res => {
            if (res.code === 1000) {
                res.data.infos = res.data.infos.map((item, index) => ({ ...item, key: index }))
                return res.data
            }
            throw new Error(res.msg || '');
        })
    }

    const { data: dataSource, isValidating } = useSwr(['/api/real-time/project-infos', pagination, type], getTableRequst, { suspense: false })
    // 分页页数改变
    const onChangeGetList = (page) => {
        setPagination({ ...pagination, pageSize: page.pageSize, current: page.current })
    }
    // 搜索数据改变
    const searchChange = (formData) => {
        setPagination({ ...pagination, current: 1, ...formData })
    }

    return (
        <div className="amp-pb-16">

            <div className="amp-work-progress-title">
                <div className="amp-work-progress-title-icon"></div>
                <span>{theme}情况</span>
            </div>

            <SearchFrom searchChange={searchChange} type={type} />

            <div className="amp-flex-row-start-center mg-b-16">
                <span className="amp-mr-10 amp-fs-14 amp-c-e25">进行中的{theme}</span>
                <span className="amp-fs-24">{dataSource?.total || '0'}</span>
            </div>

            <Table
                columns={columns}
                loading={isValidating}
                dataSource={dataSource?.infos || []}
                pagination={{
                    position: ["bottomCenter"],
                    showTotal: (total) => `共 ${total} 条`,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: dataSource?.total,
                    ...pagination
                }}
                onChange={onChangeGetList}
            />
        </div>
    )
}

export default memo(ProjectSituation)
