/**
 * @name: TaskList.jsx
 * @user: cfj
 * @date: 2022/3/22
 * @description: 任务包列表
 */
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import ScrollContainer from '@components/scroll_container';
import taskPackageAPI from '@api/2_projects/task_package_api';
import { RenderListHeader, RenderListLoading, TaskListItem } from './components';

const TaskList = forwardRef(function(props, ref) {
    const params = useParams();
    const {className} = props
    return <div className={className}>
        <ScrollContainer
            ref={ref}
            pageSize={10}
            url={'/package/list'}
            className={'list-content-scroll'}
            params={params.id}
            renderLoading={({size}) => <RenderListLoading size={size} />}
            renderHeader={({
                data
            }) => <RenderListHeader res={data?.[0]} />}
            renderItem={(item) => <TaskListItem key={item.id} {...item} />}
            request={(page, pageSize, id) => taskPackageAPI.getPackageList({
                page: page,
                page_size: pageSize,
                project_id: Number(id)
            }).then(res => ({
                list: res.data.list,
                statusTotal: res.data.status_total,
                total: res.data.total
            }))}

        />
    </div>;
});
export default TaskList;
