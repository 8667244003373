import * as React from "react";
import { observer, inject } from "mobx-react";
import { Form, Input } from "antd";
import { RangePicker } from "@components";
import { RangeInput, RangeInputAnyNumber, FormSelect } from "../0_components";
import { withRouter } from "react-router";
import { detailActionTypes, detailFilters, detailProductionTypes } from "@vars/views/stats_vars";
import { artConfigs, productionConfigs } from "@vars/views/stats_vars";
import moment from "moment";

@withRouter
@inject("detailStore")
@observer
class DetailFilter extends React.Component {
    formRef = React.createRef();
    get store() {
        return this.props.detailStore;
    }
    get statType() {
        const { pathname } = this.props.location;
        return pathname.split("/")[2];
    }
    get paramFilter() {
        const { search } = this.props.location;
        const filter =
            search && JSON.parse(search.replace("?filter=", "").replace(/%22/g, '"'));
        return filter || {};
    }
    get configs() {
        return this.statType === "art"
            ? artConfigs
            : this.statType === "production" && productionConfigs;
    }
    componentDidMount() {
        this.setupUser();
    }
    componentDidUpdate(prevProps) {
        if (
            this.paramFilter.user_id &&
            prevProps.location !== this.props.location
        ) {
            this.setupUser();
        }
    }

    componentWillUnmount() {
        this.store.clear();
    }

    setupUser = () => {
        const { user_id } = this.paramFilter;
        this.formRef.current.setFieldsValue({ user_id });
        this.store.updateFilter({ user_id });
    };

    handleFormChange = (_, allValues) => {
        this.store.updateFilter(allValues);
    };

    clear = () => {
        this.formRef.current.resetFields();
        this.store.clear();
        this.props.setDefaultdate();
    };

    renderFilters = () => {
        const { tab } = this.store;
        const filterRender =
            tab === "user" ? this.configs.userFilters : this.configs.allFilters;
        return (
            <>
                {this.renderBasics()}
                {filterRender?.map(({ name, label }) => (
                    <RangeInput name={name} label={label} key={name} />
                ))}
            </>
        );
    };

    renderInput = ({ name, label }) => {
        return (
            <div className="align-center mg-b-20 mg-r-14" key={name}>
                <span className="bold mg-r-10">{label}</span>{" "}
                <Form.Item name={name} key={name}>
                    <Input
                        className="filter-input"
                        placeholder={`请输入${label}`}
                        allowClear
                        maxLength={name === "project_name" ? 20 : 12}
                    />
                </Form.Item>
            </div>
        );
    };

    renderSelect = ({ name, label }) => {
        let options;
        const isUser = name === "user_id";
        if (name === "type") {
            if (this.statType === "art") {
                options = detailActionTypes.slice(0, 4);
            } else if (this.statType === "production") {
                options = detailProductionTypes
            }

        } else if (isUser) {
            // producePerson为所有的制作人员
            options = this.props?.producePerson?.map((person) => ({
                code: person.user_id,
                label: person.name,
            }));
        }
        return (
            <FormSelect key={name} name={name} label={label} options={options} />
        );
    };

    renderOthers = () => {
        return detailFilters[this.statType]?.map((item) => {
            let output;
            switch (item.type) {
                case "range":
                    if (item.name === "score") {
                        output = <RangeInputAnyNumber {...item} key={item.name} />;
                    } else {
                        output = <RangeInput {...item} key={item.name} />;
                    }
                    break;
                case "input":
                    output = this.renderInput(item);
                    break;
                case "select":
                    output = this.renderSelect(item);
                    break;
                default:
                    output = "";
                    break;
            }
            return output;
        });
    };
    randerDate = () => {
        const { date } = this.paramFilter;
        return (
            <div
                className="align-center"
                style={{ marginTop: "-20px", marginRight: "31px" }}
            >
                <span className="bold mg-r-10">时间</span>
                <RangePicker
                    placeholder={["选择时间"]}
                    picker="date"
                    format="YYYY-MM-DD"
                    value={date?.map((day) => moment(day))}
                    onChange={(date) =>
                        this.props.history.push({
                            search: `?filter=${JSON.stringify({ date })}`,
                        })
                    }
                />
            </div>
        );
    };

    render() {
        return (
            <div className="details mg-t-12">
                <Form
                    ref={this.formRef}
                    onValuesChange={this.handleFormChange}
                    layout="inline"
                    style={{ width: "90%" }}
                >
                    {this.randerDate()}
                    {this.renderOthers()}
                    <div className="reset-filter" onClick={this.clear}>
                        <span>重置</span>
                    </div>
                </Form>
            </div>
        );
    }
}
export default DetailFilter;
