/**
 * @name: EditLimit.jsx
 * @user: cfj
 * @date: 2022/4/13
 * @description: 编辑可领取的最大值
 */
import { Col, Form, Row, Space } from 'antd';
import { useSend, useSwr } from '@hooks';
import { getTaskLimit, updateTaskLimit } from '@api';
import { $error, $success, Button, Select } from '@components';
import { useEffect, useState } from 'react';

const options = new Array(10).fill(null)
    .map((i, k) => ({
        value: k + 1,
        label: k + 1
    }));
export const EditLimit = function(props) {
    const {
        className,
        onRefresh
    } = props;
    const [isEditModel, setEditModel] = useState(false);
    const [form] = Form.useForm();
    const {
        data,
        mutate
    } = useSwr('/api/member-produce-count/get', getTaskLimit);
    const [loading, send] = useSend(updateTaskLimit, {
        onError(error) {
            $error(error.message);
        },
        onSuccess() {
            onRefresh();
            mutate();
            setEditModel(false)
            $success('操作成功');
        }
    });
    useEffect(() => {
        if (data) {
            form.setFieldsValue({ set_count: data.default_num });
        }
    }, [data]);

    function onCancel() {
        form.setFieldsValue({ set_count: data.default_num });
        setEditModel(false);
    }

    return <Form
        form={form}
        layout={'inline'}
        component={false}
        onFinish={send}
    >
        <Row gutter={40} className={className}>
            <Col span={7}>
                <Form.Item label={'单个组员领取上限'} name={'set_count'} tooltip={'组内每个组员领取上限都会按这个数量统一更改。'}>
                    <Select disabled={!isEditModel} options={options} />
                </Form.Item>
            </Col>
            <Col span={7} className={'d-flex align-items-center'}>
                {
                    isEditModel ? <Space>
                        <Button type='text'
                                loading={loading}
                                onClick={form.submit}>保存</Button>
                        <Button
                                type='text'
                                onClick={onCancel}>取消</Button>
                    </Space> : <Button type='text' onClick={() => setEditModel(true)}>编辑</Button>
                }

            </Col>
        </Row>
    </Form>;
};
