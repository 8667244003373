import React, { Component } from "react";
import { Modal } from "antd";
import { AuthButton, $success, $error } from "@/components";

class ConfirmDialog extends Component {
  state = {
    visible: false,
    loading: false,
    record: {},
    type: "",
    displayName: "",
    action: "",
  };
  show = (record, type, displayName, action) => {
    this.setState({ visible: true, record, type, displayName, action });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  startLoad = () => {
    this.setState({ loading: true });
  };

  endLoad = () => {
    this.setState({ loading: false });
  };
  success = () => {
    this.endLoad();
    this.props.update();
    this.hide();
  };

  handleSubmit = () => {
    const { record } = this.state;
    const { confirmRequest, storeRequest } = this.props;
    this.startLoad();
    if (storeRequest) {
      return storeRequest(record.id).then((res) => {
        this.endLoad();
        this.hide();
      });
    } else {
      return confirmRequest(record.id).then((res) => {
        if (res.code === 1000) {
          $success(res.msg);
          this.success();
        } else {
          if (res.code !== 1002) $error(res.msg);
        }
      });
    }
  };

  render() {
    const { visible, loading, type, displayName, action } = this.state;
    return (
      <Modal
        centered
        visible={visible}
        onOk={this.handleSubmit}
        onCancel={this.hide}
        destroyOnClose
        footer={null}
      >
        <div className="dialog-pd">
          <div className="fz-18 mg-b-42">
            <div>
              是否确认对{type} <strong>{displayName}</strong> 进行
              {action || "删除"} ?
            </div>
          </div>

          <div className="dialog-footer">
            <AuthButton
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleSubmit}
            >
              确认
            </AuthButton>
            <AuthButton key="back" onClick={this.hide} className="mg-l-20">
              取消
            </AuthButton>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmDialog;
