import React, { memo } from 'react'
import { Image } from 'antd'
import { Iconfont } from '@components';
import ScrollContainer from '@/components/scroll_container'
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api'
import env from 'env'

const SceneItem = (props) => {
    const { onChange, data, value } = props
    const checked = (id) => {
        if (value === id) {
            return 'amp-scene-item-checked'
        }
        return ''
    }
    const checkedIcon = (id) => {
        if (value === id) {
            return <Iconfont type="icon-a-24-yixuan" className="fz-24 icon-hover-main" />
        }
        return <Iconfont type="icon-a-24-weixuan" className="fz-24 icon-hover-main" />
    }
    const itemCheck = (id) => {
        onChange?.(id)
    }
    return (
        <div className="amp-scene-scroll-modal-line">
            {
                Array.isArray(data) && data.map(item => {
                    return (
                        <div className={`amp-scene-scroll-modal-line-item`}
                            onClick={() => itemCheck(item.id)}
                            key={item.id}
                        >
                            <div className={`amp-scene-modal-item-wrap ${checked(item.id)}`}>
                                <div className="amp-scene-modal-item-wrap-pic">
                                    <div className="amp-scene-item-check">
                                        {
                                            checkedIcon(item.id)
                                        }
                                    </div>
                                    <Image
                                        fallback={env.placeImage}
                                        preview={false}
                                        className='amp-scene-item-image'
                                        src={item?.imagesList?.[0]?.fileUrl || ''}
                                    />
                                </div>
                                <div className="amp-scene-item-content">
                                    <h5 className="amp-scene-item-content-name">{item.name}</h5>
                                    <div className="display-flex">
                                        <span className="amp-scene-item-content-number">{item.number}</span>
                                        <span className="amp-scene-item-content-tag">{item.statusName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const SelectScene = (props) => {
    const { value, onChange, params } = props
    const itemChange = (val) => {
        if(val === value){
            onChange?.(null)
        }else{
            onChange?.(val)
        }
    }
    return (
        <ScrollContainer
            pageSize={20}
            col={6}
            height="40vh"
            className="amp-topic-scene-modal-scroll"
            params={params}
            renderItem={(item, key) => <SceneItem data={item} key={key} value={value} onChange={itemChange} />}
            request={(page, pageSize, params) => SelectedTopicApi().listTopicSceneAddress({
                page: page,
                page_size: pageSize,
                ...params
            }).then(res => ({
                list: res.listList,
                total: res.total
            }))}
        />
    )
}

export default memo(SelectScene)
