/**
 * 发布订阅
 */
export class EventEmitter {
    /**
     * 构造函数
     * @param {string} eventName eventName
     */
    constructor(eventName) {
        this.eventName = eventName;
        // 缓存列表
        this.listener = {};
    }

    /**
     * 订阅
     * @param {Function} fn fn
     * @return {any}
     */
    on(fn) {
        if (!this.listener[this.eventName]) {
            this.listener[this.eventName] = [];
        }
        this.listener[this.eventName].push(fn);
        return this.off.bind(this, fn);
    }

    /**
     * 取消订阅
     * @param {Function} fn fn
     */
    off(fn) {
        const callbacks = this.listener[this.eventName];
        if (callbacks && fn) {
            let cb;
            // 遍历所对应的fn，判断和那个fn相同，相同则删除
            for (let i = 0, cbLen = callbacks.length; i < cbLen; i += 1) {
                cb = callbacks[i];
                if (cb === fn || cb.fn === fn) {
                    callbacks.splice(i, 1);
                    break;
                }
            }
        }
    }

    /**
     * 发布
     * @param {*} data data
     */
    emit(data) {
        const callbacks = this.listener[this.eventName];
        if (callbacks) {
            callbacks.forEach((callback) => {
                callback(data);
            });
        }
    }
}
