import { action, computed, flow, makeAutoObservable, observable, reaction, toJS } from 'mobx';
import { accountAPI, getUserAuth, projectsAPI, taskFlowAPI } from '@api';
import { emptyProject } from '@vars/views/projects_vars';
import { $error, $success } from '@components';
import { debounce } from 'lodash';
import { configsStore, userRelationsStore } from '@stores';
import { Select } from 'antd';

const { Option } = Select;
class ProjectStore {
    constructor() {
        makeAutoObservable(this);
    }
    @observable projectId = 0;
    @observable currentProject = emptyProject;
    @observable projectHistory = {};
    @observable currentPhotographer = {};
    @observable loginUser = {};
    @observable confidentList = [];
    @observable final = [];
    @observable defaultCheckedOptions = [];
    @observable selectList = [];
    @observable profileGroupList = [];
    @observable produceListTotal = 0;
    @observable produceListOpenTotal = 0;
    @observable produceTaskPondShow = false;
    @observable produceTaskPondList = [];
    @observable IsChecked = false;
    @observable getTask = [];
    @observable producer = null;
    @observable produceInfo = {};
    @observable openTask = true;
    @observable privateTask = true;
    @observable assetTypeList = [];
    @observable assetCategoryOption = [];
    @observable produceCategory = [];
    @observable pondOpenTask = [];
    @observable pondPage = 1;
    @observable Isloading = true;
    @observable showTotal = 0;
    @observable renderPage = 0;
    @observable userPermissions = [];
    @observable filterObj = {
        is_split: 0,
        parent_id: 0,
        uid_name: ''
    };

    @observable openStatus = [];

    @computed get relatedUsers() {
        return userRelationsStore.relatedUsers;
    }

    @action setRenderPage = (num) => {
        this.renderPage = num;
    };
    @action setUserPermissions = (arr) => {
        this.userPermissions = arr;
    };
    @action setShowTotal = (num) => {
        this.showTotal = num;
    };
    @action setPondOpenTask = (arr) => {
        this.pondOpenTask = arr;
    };
    @action updatePondOpen = (id, num) => {
        const len = this.openStatus.length;
        if (len > 0) {
            this.openStatus.map((item, idx) => {
                if (item.task_id === id) {
                    this.getTask.splice(idx, 1);
                } else {
                    this.openStatus.push({
                        task_id: id,
                        is_public: num
                    });
                }
            });
        } else {
            this.openStatus.push({
                task_id: id,
                is_public: num
            });
        }
    };
    @action getPondOpen = (id) => {
        this.produceTaskPondList.map((item) => {
            if (item.id === id) {
                return item.is_public;
            }
        });
    };
    @action setProduceCategory = (arr) => {
        this.produceCategory = arr;
    };
    @action setAssetCategoryOption = (id) => {
        this.assetCategoryOption.push(id);
    };
    @action removeAssetCategoryOption = (id) => {
        this.assetCategoryOption = this.assetCategoryOption.filter((it) => {
            return it !== id;
        });
    };
    @action setProduceInfo = (obj) => {
        this.produceInfo = obj;
    };
    @action setAssetTypeList = (arr) => {
        this.assetTypeList = arr;
    };
    @action setOpenTask = () => {
        this.openTask = !this.openTask;
    };
    @action setPrivateTask = () => {
        this.privateTask = !this.privateTask;
    };
    @action init = () => {
        this.openTask = true;
        this.privateTask = true;
        this.pondPage = 1;
        this.producer = null;
        this.produceTaskPondList = [];
        this.Isloading = true;
        this.openStatus = [];
    };
    @action setProduceListTotal = (num) => {
        this.produceListTotal = num;
    };
    @action setProduceListOpenTotal = (list) => {
        this.produceListOpenTotal = list.filter((item) => item.is_public !== 0).length;
    };
    @action setProduceTaskPondList = (list) => {
        this.produceTaskPondList = this.filterArray([...this.produceTaskPondList, ...list]);
        this.setPondOpenTask(this.filterTask(this.produceTaskPondList));
    };
    @action filterProduceTaskPondList = (list) => {
        this.produceTaskPondList = list;
    };
    @action filterArray = (list) => {
        let res = [];
        let obj = {};
        const len = list.length;
        for (let i = 0; i < len; i++) {
            if (!obj[list[i].id]) {
                res.push(list[i]);
                obj[list[i].id] = true;
            }
        }
        return res;
    };
    @action unique = (arr) => {
        var newArr = [];
        newArr.push(arr[0]);
        for (var i = 0; i < arr.length; i++) {
            var num = arr[i];
            for (var j = 0; j < newArr.length; j++) {
                if (newArr.indexOf(num) === -1) {
                    newArr.push(num);
                }
            }
        }
        return newArr;
    };
    @action clear() {
        this.projectId = 0;
        this.currentProject = emptyProject;
        this.projectHistory = {};
    }
    @action setCurrentPhotographer(obj) {
        this.currentPhotographer = obj;
    }
    @action setLoginUser(obj) {
        this.loginUser = obj;
    }
    @action getAllUsersToOptions() {
        let arr = [];
        const { allUsers } = configsStore;
        arr = this.getAllUsersOptions(allUsers);
        return arr;
    }
    @action setSelectList(e) {
        this.selectList = [];
        const { allUsers } = configsStore;
        const eLen = e.length;
        const allUsersLen = allUsers.length;
        if (eLen) {
            for (let i = 0; i < eLen; i++) {
                for (let j = 0; j < allUsersLen; j++) {
                    if (e[i] === allUsers[j].id) {
                        const temp = {
                            id: allUsers[j].id,
                            name: allUsers[j].name,
                            username: allUsers[j].username
                        };
                        this.selectList.push(temp);
                    }
                }
            }
        }
        this.final = this.selectList;
        this.profileGroupList = this.selectList;
    }
    @action setProfileGroupList(e) {
        this.profileGroupList = [];
        const id = e.id;
        const len = this.final.length;
        if (len) {
            this.final.map((item) => {
                if (id !== item.id) {
                    this.profileGroupList.push(item);
                }
            });
        }
        this.final = this.profileGroupList;
    }
    @action getAllUsersOptions(usersList) {
        const list = [];
        const userListLen = usersList.length;
        for (let i = 0; i < userListLen; i++) {
            list.push(
                <Option key={usersList[i].id} value={usersList[i].id}>
                    {usersList[i].name}
                </Option>
            );
        }
        return list;
    }
    @action async setRelatedUsers() {
        await userRelationsStore.fetchRelatedUsers(50);
        this.confidentList = [];
        const { relatedUsers } = userRelationsStore;
        relatedUsers.length > 0 &&
            relatedUsers.map((item) => {
                this.confidentList.push({
                    id: item.user_id,
                    name: item.name,
                    username: item.username
                });
            });
    }
    @action setFinal() {
        let arr = [];
        arr = arr.concat([toJS(this.loginUser)]);
        arr = arr.concat([toJS(this.currentPhotographer)]);
        arr = arr.concat(toJS(this.confidentList));
        arr = arr.concat(toJS(this.selectList));
        arr = arr.concat(toJS(this.profileGroupList));
        arr = this.filterEmptyObj(arr);
        arr = this.uniqueFunc(arr);
        this.final = arr;
        return arr;
    }
    @action getFinalId() {
        let arr = [];
        this.final.map((item) => {
            arr.push(item.id);
        });
        return arr;
    }
    @action filterEmptyObj(arr) {
        const temp = [];
        arr.map((item) => {
            if (item) {
                var res = Object.keys(item);
                res.length > 0 && temp.push(item);
            }
        });
        return temp;
    }
    @action uniqueFunc(list) {
        let obj = {};
        let tempArr = [];
        for (var i = 0; i < list.length; i++) {
            if (!obj[list[i]['id']]) {
                tempArr.push(list[i]);
                obj[list[i]['id']] = true;
            }
        }
        return tempArr;
    }
    @action getDefaultCheckedOptions(arr) {
        let list = [];
        arr = this.uniqueFunc(arr);
        const len = arr.length;
        if (len) {
            arr.map((item) => {
                list.push(item.id);
            });
        }
        return list;
    }
    @action updateProjectId(id) {
        this.projectId = id;
    }
    @action updateProject(newProject) {
        this.currentProject = newProject;
    }

    @action handleResult(res) {
        if (res.code === 1000) {
            $success(res.msg);
        } else {
            if (res.code !== 1002) $error(res.msg);
        }
    }

    @action filterTask(arr) {
        let temp = [];
        arr.map((item) => {
            temp.push({
                task_id: item.id,
                is_public: item.is_public
            });
        });
        return temp;
    }

    @action renderByPermission = (list) => {
        let proSetting = list.indexOf('produce_setting') > -1;
        let proAllot = list.indexOf('produce_allot') > -1;
        let proTake = list.indexOf('produce_take') > -1;
        let res;
        if (proAllot) {
            if (proTake && proSetting) {
                res = 1;
            } else if (proTake) {
                res = 2;
            } else if (proSetting) {
                res = 3;
            } else {
                res = 5;
            }
        } else {
            if (proTake && proSetting) {
                res = 4;
            } else if (proTake) {
                res = 6;
            } else if (proSetting) {
                res = 7;
            }
        }
        this.setRenderPage(res);
        return res;
    };

    @action preFetch = (data) => {
        const temp = this.renderByPermission(data);
        let type = null;
        switch (temp) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
                type = 2;
                break;
            case 6:
                type = 1;
                break;
            default:
                break;
        }
        if (type !== null) {
            this.getAllProduceTotal(type);
        }
    };

    fetchProject = flow(function* () {
        if (this.projectId) {
            try {
                const res = yield projectsAPI.fetchProjectInfo(this.projectId);
                if (res.code === 1000) {
                    this.currentProject = res.data;
                    if (res.data.confidential_users) {
                        configsStore.setSecretList(res.data.confidential_users);
                    } else {
                        configsStore.setSecretList([]);
                    }
                }
            } catch (error) {}
        }
    });

    fetchProfile = flow(function* () {
        try {
            const res = yield accountAPI.fetchProfile();
            if (res.code === 1000) {
                this.setLoginUser({
                    id: res.data.id,
                    name: res.data.name,
                    username: res.data.username
                });
            } else {
                $error(res.msg);
            }
        } catch (error) {}
    });
    createProject = flow(function* (params, success = {}, error = {}) {
        try {
            const res = yield projectsAPI.createProject(params);
            this.handleResult(res);

            if (res.code === 1000) {
                success();
                // projectsStore.fetchProjects({page: 1, page_size: 14})
            } else {
                error();
            }
        } catch (error) {}
    });
    updateProject = flow(function* (params, success = {}, error = {}) {
        try {
            const res = yield projectsAPI.updateProject(params);
            this.handleResult(res);
            if (res.code === 1000) {
                this.currentProject = res.data;
                success();
            } else {
                error();
            }
        } catch (error) {}
    });
    removeProject = flow(function* (params, success = {}, error = {}) {
        try {
            const res = yield projectsAPI.removeProject(params);
            this.handleResult(res);
            if (res.code === 1000) {
                success();
            } else {
                error();
            }
        } catch (error) {}
    });
    completeProject = flow(function* (params, success = {}, error = {}) {
        try {
            const res = yield projectsAPI.completeProject(params);
            this.handleResult(res);
            if (res.code === 1000) {
                success();
            } else {
                error();
            }
        } catch (error) {}
    });
    getProduceListTotal = flow(function* (num) {
        let filter = {
            is_public: num,
            asset_category: [],
            ...this.filterObj
        };
        const params = {
            project_id: Number(this.projectId),
            page: 1,
            page_size: 16,
            filter: {
                ...filter
            }
        };
        try {
            const res = yield taskFlowAPI.getProduceList(params);
            if (res.code === 1000) {
                this.setShowTotal(res.data.total);
            }
        } catch (error) {}
    });
    getProduceList = flow(function* () {
        if (this.Isloading) {
            this.Isloading = false;
            const publicCode = this.openTask && this.privateTask ? 2 : this.openTask ? 1 : this.privateTask ? 0 : -1;
            let filter = {
                is_public: publicCode,
                asset_category: this.assetCategoryOption
            };
            if (publicCode === -1) {
                filter = { asset_category: this.assetCategoryOption };
            }
            const params = {
                project_id: Number(this.projectId),
                page: this.pondPage,
                page_size: 16,
                filter: {
                    ...filter,
                    ...this.filterObj
                }
            };
            try {
                const res = yield taskFlowAPI.getProduceList(params);
                if (res.code === 1000) {
                    this.setProduceListTotal(res.data.total);
                    this.setProduceListOpenTotal(res.data.list);
                    this.setProduceTaskPondList(res.data.list);
                    this.Isloading = true;
                } else if (res.code === 1001) {
                    this.Isloading = true;
                    $error(res.msg);
                }
            } catch (error) {}
        }
    });
    fetchUserPermissions = flow(function* () {
        try {
            const res = yield getUserAuth();
            this.setUserPermissions(res.list);
            this.preFetch(res.list);
        } catch (error) {}
    });
    getAllProduceTotal = flow(function* (num) {
        const params = {
            page: 1,
            page_size: 16,
            filter: {
                is_public: num,
                asset_category: [],
                ...this.filterObj
            }
        };
        try {
            const res = yield taskFlowAPI.getAllProduce(params);
            if (res.code === 1000) {
                this.setShowTotal(res.data.total);
            }
        } catch (error) {}
    });
    getAllProduce = flow(function* (num) {
        if (this.Isloading) {
            this.Isloading = false;
            const publicCode = num ? num : this.openTask && this.privateTask ? 2 : this.openTask ? 1 : this.privateTask ? 0 : -1;
            const params = {
                page: this.pondPage,
                page_size: 16,
                filter: {
                    is_public: publicCode,
                    asset_category: this.assetCategoryOption,
                    ...this.filterObj
                }
            };
            try {
                const res = yield taskFlowAPI.getAllProduce(params);
                if (res.code === 1000) {
                    this.setProduceListTotal(res.data.total);
                    this.setProduceListOpenTotal(res.data.list);
                    this.setProduceTaskPondList(res.data.list);
                    this.Isloading = true;
                } else if (res.code === 1001) {
                    $error(res.msg);
                    this.Isloading = true;
                }
            } catch (error) {}
        }
    });
}

const projectStore = new ProjectStore();
const debouncedRequest = debounce(() => projectStore.fetchProject(), 500);

reaction(
    () => projectStore.projectId,
    (val, prevVal) => {
        if (val && val !== prevVal) {
            debouncedRequest();
        }
    }
);
export default projectStore;
