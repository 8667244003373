import { Component } from "react";
import { Modal, Form, Input } from "antd";
import { rules } from "@vars/global_vars";
import { passEncrypt } from "@/utils/global/session_util";
import { AuthButton} from '@/components'

class ChangePassword extends Component {
  state = {
    visible: false,
  };
 
  show = (type) => {
    this.setState({ type });
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleSubmit = (values) => {
    const password = passEncrypt(values.password);
    const token = this.props.validationToken
    this.resetRequest({ password, token });
  };

  resetRequest = (params) => {
    this.props.changePassword(params, {success: this.hide, error: ()=>{}})
  };

  render() {
    return (
      <Modal
        centered
        visible={this.state.visible}
        onCancel={this.hide}
        className="medium-form-dialog"
        footer={null}
        width="438px"
        destroyOnClose
      >
        <h2>重设密码</h2>

        <Form
          onFinish={this.handleSubmit}
          layout="vertical"
          validateTrigger="onBlur"
          preserve={false}
        >
          <div className="mg-t-30">
            <Form.Item
              className="form-item"
              name="password"
              rules={rules.password}
            >
              <Input.Password
                placeholder="请输入新密码"
                className="password-input"
                size="large"
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="confirmPassword"
              dependencies={["password"]}
              rules={rules.confirmPassword}
            >
              <Input.Password
                placeholder="请再次输入新密码"
                className="password-input"
                size="large"
              />
            </Form.Item>
          </div>
          <div className="dialog-footer">
            <Form.Item className="form-item">
              <AuthButton type="primary" className=" mg-r-12" htmlType="submit">
                确认
              </AuthButton>
              <AuthButton onClick={this.hide}>取消</AuthButton>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default ChangePassword;
