import React, { useState, useRef, useMemo } from 'react'
import { Select } from 'antd';
import GdMap from '../index'

import './index.less'

const SearchArea = (props) => {
    const {
        disabled,
        value,
        onChange,
    } = props
    const gdMapRef = useRef(null)
    const [options, setOptions] = useState([])

    // 搜索文本框变化回调
    const handleSearch = (newValue) => {
        // setSearchWord(newValue)
        gdMapRef.current?.search(newValue).then((res) => {
            const points = res.filter(item => item.location && item.id)
            setOptions(points)
        })
    }

    // 下拉选择
    const handleChange = (newValue) => {
        const option = options.find(item => item.id === newValue)
        if (option) {
            onChange?.([{
                ...option,
                name: option.name,
                lat: option.location.lat,
                lng: option.location.lng,
            }])
        } else {
            onChange?.([])
        }
    }

    const seleceValue = useMemo(() => {
        if(Array.isArray(value) && value.length > 0){
            return value[0].id ? value[0].id : value[0].name
        }
        return null
    }, [value])

    return (
        <div className="amp-map-search">
            <Select
                className="amp-map-search-select"
                showSearch
                placeholder="请选择地址"
                filterOption={false}
                value={seleceValue}
                defaultActiveFirstOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                disabled={disabled}
                optionLabelProp="label"
                // mode={mode}
                allowClear
                getPopupContainer={triggerNode => triggerNode.parentElement}
            >
                {
                    options.map(item => (
                        <Select.Option key={item.id} value={item.id} label={item.name}>
                            <div className="amp-search-select-option">
                                <span className="amp-option-name">{item.name}</span>
                                <span className="amp-option-district">{item.district} {item.address}</span>
                            </div>
                        </Select.Option>
                    ))
                }
            </Select>
            <GdMap
                height="260px"
                marker={value}
                ref={gdMapRef}
            />
        </div>
    )
}

export default SearchArea
