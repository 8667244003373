/**
 * @name: MyUpload
 * @user: cfj
 * @date: 2022/3/31 19:33
 */
import { useEffect, useRef, useState } from 'react';
import { Upload } from 'antd';
import { cosFileUpload } from '@gUtils';
import { $error, Iconfont } from '@/components/index';

const LargeFileUpload = function (props) {
    const taskIdRef = useRef();
    const {
        value,
        onChange,
        onUploadStatus,
        params,
        ...other
    } = props;
    const defaultFileList = value ? [{
        uid: value.Key,
        name: value.Key,
        status: 'done',
        url: value.Key,
        thumbUrl: value.Key,
        dataSource: value
    }] : undefined;
    const [fileList, setFileList] = useState(defaultFileList);
    useEffect(() => {
        if (value) {
            setFileList([{
                uid: value.Key,
                name: value.Key,
                status: 'done',
                url: value.Key,
                thumbUrl: value.Key,
                dataSource: value
            }]);
        } else {
            setFileList(undefined);
        }
    }, [value]);
    useEffect(() => () => {
        if (taskIdRef.current) {
            cosFileUpload.multipartAbort(taskIdRef.current);
        }
    }, []);
    return (
        <Upload
            {...other}
            defaultFileList={defaultFileList}
            fileList={fileList}
            listType="picture-card"
            maxCount={1}
            isImageUrl={() => false}
            showUploadList={{ showPreviewIcon: false }}
            customRequest={async (option) => {
                try {
                    taskIdRef.current = undefined;
                    taskIdRef.current = await cosFileUpload.sliceUploadFile(option, params);
                } catch (error) {
                    $error(error.message);
                    option.onError?.(error);
                }
            }}
            onChange={({
                file,
                fileList
            }) => {
                onUploadStatus?.(file);
                if (file.response?.url) {
                    file.url = file.response.url;
                    file.dataSource = file.response.dataSource;
                }
                //为解决上传成功后出现两个数据，强制保留fileList最后一个数组数据
                let newFilter = []
                if (fileList.length > 0) {
                    newFilter = fileList.slice(-1)
                }
                setFileList(newFilter);
                if (fileList.length) {
                    if (file.dataSource) {
                        onChange?.(file.dataSource);
                    }
                } else {
                    onChange?.(undefined);
                }
            }}
        >
            {fileList?.length ? null : (
                <div>
                    <Iconfont type="icon-a-14-zengjia" />
                    <div style={{
                        marginTop: 8,
                        color: '#BFC5D1'
                    }}
                    >
                        上传文件
                    </div>
                </div>
            )}
        </Upload>
    );
};

export default LargeFileUpload;
