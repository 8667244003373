import Stomp from "stompjs";
import env from "env";

const subscriptions = (channel, cb, id) => {
  let stompClient = Stomp.client(env.webSocketSrc);
  const headers = {
    login: env.rmUser,
    passcode: env.rmPsd,
    durable: "true",
    "auto-delete": "false",
  };
  stompClient.connect(headers, function (frame) {
    const subscription = stompClient.subscribe(channel, cb, {id});
  });
  return stompClient
};

export const todosSubscription = (id, cb) =>
  subscriptions(`/queue/user_mq_${id}`, cb, 'todos');

export const boardSubscription = (id, cb) =>
  subscriptions(`/exchange/project_${id}`, cb, 'board');

export const wechatSubscription = (id, cb) =>
 subscriptions(`/queue/wechat_bind_${id}`, cb, 'wechat');

