import axios from 'axios';

// 任务包接口
const taskPackageAPI = {
    // 创建任务包
    createPackage(params) {
        return axios.post("/package/create", params);
    },
    // 更新任务包
    updatePackage(params) {
        return axios.post("/package/update", params);
    },
    // 任务包列表接口
    getPackageList(params) {
        return axios.post("/package/list", params);
    },
    getPackageInfo(id){
        return axios.get(`/package/info?id=${id}`)
    },
    // 任务包提交审核
    submitPackage(id) {
        return axios.post("/package/submit", id);
    },
    // 任务包公开
    publicPackage(id) {
        return axios.post("/package/public", id);
    },
    // 任务包驳回
    disapprovePackage(id) {
        return axios.post("/package/disapprove", id);
    },
    // 任务包作废
    discardPackage(id) {
        return axios.post("/package/discard", id);
    },
    // 领取任务包
    takePackage(id) {
        return axios.post("/package/take", id);
    },
    // 小组信息
    getPackageScore() {
        return axios.get("/package/take-score");
    },
    // 放弃任务包
    abandonPackage(id) {
        return axios.post("/package/abandon", id);
    },
    // 获取操作记录
    getPackageHistory(id) {
        return axios.get(`/package/history?id=${id}`)
    }
};

export default taskPackageAPI;
