const jsonToFormData = function(data) {
    const formData = new FormData()
    for(const key in data){
        const value = data[key]
        if(value && Array.isArray(value)){
            value.forEach(v => {
                if(v!==undefined && v!==null ){
                    formData.append(key, v)
                }
            })
        } else {
            if(value!==undefined){
                formData.append(key, value)
            }
        }
    }
    return formData
}
export default jsonToFormData
