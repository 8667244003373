import React, { Component } from 'react';
import { Button, Col, Empty, Input, Row, Select, Spin, Tooltip, DatePicker } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { UserSelect } from '@components';
import { toJS } from 'mobx';
import { discardType } from '@vars/views/tasks_vars';
import { clsx } from '@gUtils';
import { taskGroupsAPI } from '@api';
import { debounce } from '@gUtils';

const { Option } = Select;
const { RangePicker } = DatePicker
const initialState = {
    discard_at: {},
    dates: [],
    participants: [],
    uid_name: null,
    assignee_id: null,
    discard_type: null,
    asset_group: [],
    task_status: [],
    asset_type: [],
    groups_lists: []
};

@inject('taskGroupsStore', 'configsStore', 'tasksStore')
@observer
class LeftFilter extends Component {

    rangePickerRef = React.createRef();
    //初始化 筛选项
    state = {
        ...initialState,
        uid_name: this.configsStore.paramsObj.uid_name,
        participants: toJS(this.configsStore.paramsObj.participants),
        asset_group: toJS(this.configsStore.paramsObj.asset_group),
        asset_type: toJS(this.configsStore.paramsObj.asset_type),
        assignee_id: this.configsStore.paramsObj.assignee_id,
        discard_type: this.configsStore.paramsObj.discard_type,
        task_status: toJS(this.configsStore.paramsObj.task_status),
        groups_lists: [],
        discard_at: toJS(this.configsStore.paramsObj.discard_at),
        dates: toJS(this.configsStore.paramsObj.discard_dates),
    };

    get groupStore() {
        return this.props.taskGroupsStore;
    }

    get configsStore() {
        return this.props.configsStore;
    }

    componentDidMount() {
        // 获取组合资产数据 type:1 资产
        taskGroupsAPI.fetchTaskGroups({
            project_id: this.props.projectId,
            type: 1
        })
            .then((res) => {
                if (res.code === 1000) {
                    this.setState({
                        groups_lists: (res.data ?? []).map(item => ({
                            name: item.group_name,
                            id: item.id
                        }))
                    });
                }
            });
        this.configsStore.fetchTaskConfig();
        if (this.state.participants[0] === undefined || this.state.participants[0] === null || this.state.participants[0] === '') {
            this.setState(() => {
                return {
                    ...this.state,
                    participants: []
                };
            }, () => {
                this.props.tasksStore.setFilter(this.state);
            });
        } else {
            this.props.tasksStore.setFilter(this.state);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) {
            // this.debounceSearchRequest();
            this.configsStore.paramsObj = {
                ...this.configsStore.paramsObj,
                ...this.state
            };
        }
    }

    componentWillUnmount() {
        this.clear();
    }

    // 赋值
    handleStateFun = (key, val) => this.setState({ [key]: val },() => {
        this.debounceSearchRequest();
    });
    handleSearchInput = (val) => this.handleStateFun('uid_name', val);

    renderOptions = (type) => {
        let options;
        const {
            taskConfig,
            allUsers
        } = this.configsStore;
        const {
            task_status,
            asset_type
        } = taskConfig;
        const optionsMapper = (item) =>
            Object.values(item || {})
                ?.map((item) => ({
                    name: item.label,
                    id: item.code
                }));
        switch (type) {
            case 'asset_group':
                options = this.state.groups_lists;
                break;
            case 'task_status':
                options = optionsMapper(task_status);
                break;
            case 'asset_type':
                options = optionsMapper(asset_type);
                break;
            case 'discard_type':
                options = optionsMapper(discardType);
                break;
            default:
                break;
        }

        return options
            ? options?.map((item, idx) => (
                <Option key={idx} value={item.id}>
                    {item.name}
                </Option>
            ))
            : [];
    };

    renderSelect = (type, idx) => {
        let props = { onChange: (val) => this.handleStateFun(type, val) };
        const { flag } = this.groupStore;
        let multipleOps = {
            ...props,
            mode: 'multiple',
            maxTagCount: 2,
            tagRender: (props) => this.tagRender(props, type)
        };
        let options = {
            ...props,
            mode: 'multiple',
            maxTagCount: 2
        };
        switch (type) {
            case 'participants':
                props = {
                    ...options,
                    placeholder: '参与人员'
                };
                break;
            case 'asset_group':
                props = {
                    ...multipleOps,
                    placeholder: '组合名称'
                };
                break;
            case 'assignee_id':
                props = {
                    ...props,
                    placeholder: '处理人员'
                };
                break;
            case 'discard_type':
                props = {
                    ...props,
                    placeholder: '作废类型'
                };
                break;
            case 'task_status':
                props = {
                    ...multipleOps,
                    placeholder: '任务状态'
                };
                break;
            case 'asset_type':
                props = {
                    ...multipleOps,
                    placeholder: '资产类型'
                };
                break;
            default:
                break;
        }

        return type === 'participants' || type === 'assignee_id' ? (
            <UserSelect
                {...props}
                key={idx}
                className='amps-filter-section-container-form-item'
                value={this.state[type]}
            />
        ) : (
            <Select
                {...props}
                key={idx}
                value={this.state[type]}
                className='amps-filter-section-container-form-item'
                size='large'
                optionFilterProp='children'
                showSearch
                showArrow
                allowClear
                notFoundContent={
                    flag ? (
                        <Spin size='small' />
                    ) : (
                        <div className='selectEmpty'>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )
                }
            >
                {this.renderOptions(type)}
            </Select>
        );
    };

    tagRender = (props, type) => {
        let {
            label,
            value
        } = props;
        const selectedItems = this.state[type];
        const idx = selectedItems.indexOf(value);
        const style = idx === 0 ? { marginLeft: '4px' } : {};
        if (label.length > 15) {
            label = label.substring(0, 12) + '...';
        }
        if (selectedItems.length > 1) {
            if (idx > 0) {
                label = `/${label}`;
            }
            if (label.length > 6) {
                label = label.substring(0, 5) + '...';
            }
        }

        return <span style={style}>{label}</span>;
    };

    clear = () => {
        this.configsStore.paramsObj = {
            ...initialState
        };
        this.setState(() => {
            return {
                ...initialState
            };
        }, () => {
            this.debounceSearchRequest();
        });
    };

    searchRequest = () => {
        const {
            projectId,
            listTypes
        } = this.props;
        const types = Object.keys(listTypes || {});
        let params = {
            page: 1,
            page_size: 5,
            filter: { ...this.state },
            project_id: projectId
        };
        types.forEach((type) => {
            const list_type = JSON.parse(type);
            params = {
                ...params,
                list_type
            };
            this.props.fetchTasks(params);
        });
    };

    debounceSearchRequest = debounce(this.searchRequest, 300);

    handleClick = (value) => {
        this.props.tasksStore.toggleFlag(value);

    };

    changeFlag(value) {
        this.setState({ flag: Boolean(value) });
    }

    /**
     * 作废时间
     * @param {*} val
     */
    handlesRangePickerValue = (val) => {
        if (val === null) {
            this.setState({
                dates: [],
                discard_at: {}
            }, () => {
                this.configsStore.setDiscardAt({}, []);
                this.debounceSearchRequest();
            })
        } else {
            this.setState({
                dates: val,
                discard_at: {
                    from: val[0].format('YYYY-MM-DD'),
                    to: val[1].format('YYYY-MM-DD')
                }
            }, () => {
                this.configsStore.setDiscardAt(this.state.discard_at, this.state.dates);
                this.debounceSearchRequest();
            })
        }
    }

    /**
     * 不可选日期
     * @param {*} current
     * @returns tooEarly || tooLate
     */
    disabledDate = current => {
        const { dates } = this.state
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'months') > 2;
        const tooEarly = dates[1] && dates[1].diff(current, 'months') > 2;
        return tooEarly || tooLate;
    };

    render() {
        const { flag } = this.props.tasksStore;
        const layout = {
            lg: 4,
            sm: 6,
            xs: 8
        };

        return (
            <div className={'amps-filter-section'} tabIndex={-1}>
                <div className={clsx('amps-filter-section-container', `amps-filter-section-container-${!flag ? 'open' : 'close'}`)}>
                    <Row gutter={16} className={'amps-filter-section-container-form'}>
                        <Col {...layout}>
                            <Input
                                size='large'
                                placeholder='检索内部ID\资产名称'
                                allowClear
                                className={'amps-filter-section-container-form-item'}
                                onChange={(val) => {
                                    this.handleSearchInput(val.target.value);
                                    this.configsStore.uid_name = val.target.value;
                                }}
                                value={this.state.uid_name}
                            />
                        </Col>
                        {[
                            'task_status',
                            'participants',
                            'assignee_id',
                            'asset_type',
                            'asset_group',
                            'discard_type'
                        ].map((item, idx) => <Col key={item} {...layout}>
                            {this.renderSelect(item, idx)}
                        </Col>)}
                        <Col {...layout}>
                            <RangePicker
                                value={this.state.dates}
                                size='large'
                                placeholder={['作废时间']}
                                disabledDate={this.disabledDate}
                                onCalendarChange={(val) => {
                                    this.setState({
                                        dates: val
                                    })
                                }}
                                onChange={val => this.handlesRangePickerValue(val)}
                            />
                        </Col>
                    </Row>
                    <div className={'amps-filter-section-container-buttons'}>
                        <Tooltip
                            placement='bottom'
                            title={!flag ? '展开' : '收起'}
                            trigger='hover'
                            overlayInnerStyle={{
                                color: '#fff',
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                width: '56px',
                                height: '26px',
                                textAlign: 'center',
                                letterSpacing: '2px',
                                position: 'absolute',
                                left: '-18px',
                                top: '6px'
                            }}
                        >
                            <div className={clsx('amps-filter-section-container-buttons-switch', flag
                                ? 'amps-filter-section-container-buttons-default-switch'
                                : 'amps-filter-section-container-buttons-open-switch')} onClick={() => {
                                    this.handleClick(!flag);
                                }}>
                                {flag ? <MenuUnfoldOutlined style={{ color: '#9B9B9B' }} /> : <MenuFoldOutlined
                                    style={{ color: '#4487DC' }} />}
                            </div>
                        </Tooltip>
                        <Button onClick={this.clear} className={'amps-filter-section-container-buttons-btn'} type={'text'}>
                            重置
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default LeftFilter;
