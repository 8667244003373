import { Redirect, Switch } from 'react-router-dom'
import { AuthRoute, ProtectedRoute } from '@gUtils'
import SessionFormContainer from '@/views/0_session/session_form_container'
import { Account, AnnouncementPage, Assets, Dashboard, ProjectIndex, Settings, TaskIndex } from '@/views'
import { BlankPage } from '@/components'
import { StatsRoute } from '@views/4_stats/statsRoute'
import { TopicRoute } from '@views/topic_selection_management/route'

const App = () => (
    <div className='amp'>
        <Switch>
            <AuthRoute path='/login' component={SessionFormContainer} />
            <ProtectedRoute
                exact
                path='/projects'
                component={ProjectIndex}
                permission='projects'
            />

            <ProtectedRoute path='/projects/:id' component={TaskIndex} />

            <ProtectedRoute
                path='/settings'
                component={Settings}
                permission='settings'
            />
            <ProtectedRoute path='/account' component={Account} />
            <ProtectedRoute
                exact
                path='/announcements/:id'
                component={AnnouncementPage}
            />
            <ProtectedRoute
                exact
                path='/dashboard'
                component={Dashboard}
                permission='dashboard'
            />
            <ProtectedRoute path='/select-topic-management' component={TopicRoute} permission='' />
            <ProtectedRoute path='/assets' component={Assets} permission='assets' />
            <ProtectedRoute path='/stats' component={StatsRoute} permission='stats' />
            <Redirect exact path='/' to='/dashboard' />
            <ProtectedRoute component={BlankPage} />
        </Switch>
    </div>
)

export default App
