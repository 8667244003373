import { RECEIVE_CONFIGS } from "@actions/action_consts";
import { configsAPI } from "@api";

export const receiveConfigs = (configs) => ({
    type: RECEIVE_CONFIGS,
    configs,
});

export const fetchProjectConfig = (callback = () => { }) => (dispatch) => {
    configsAPI.fetchProjectConfig().then((res) => {
        if (res.code === 1000) {
            callback();
            return dispatch(receiveConfigs(res.data));
        }
    });
};

export const fetchShootingPlanConfig = (callback = () => { }) => (dispatch) => {
    configsAPI.fetchShootingPlanConfig().then((res) => {
        if (res.code === 1000) {
            callback();
            return dispatch(receiveConfigs(res.data));
        }
    });
}

export const fetchTaskConfig = (callback = () => { }) => (dispatch) => {
    configsAPI.fetchTaskConfig().then((res) => {
        if (res.code === 1000) {
            callback();
            return dispatch(receiveConfigs(res.data));
        }
    });
}

export const fetchUserConfig = (callback = () => { }) => (dispatch) => {
    configsAPI.fetchUserConfig().then(
        res => {
            if (res.code === 1000) {
                callback();
                return dispatch(receiveConfigs(res.data));
            }
        },
    )
}
