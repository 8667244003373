import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import { AuthButton } from '@components';
import TextArea from 'antd/es/input/TextArea';
import { taskPlanStore } from '@stores';
import { useState } from 'react';

const CommentModel = function(props){
    const { commentModal, comment } = props.taskPlanStore;
    const [valueLength, setValueLength] = useState(comment?.length || 0);
    const [value, setValue] = useState("")
    const handleChange = () => {
        taskPlanStore.setPlanTaskComment(value);
    };
    const hide = () => {
        taskPlanStore.setCommentModal(false);
    };
    const handleValue = (e) => {
        setValue(e.target.value)
        setValueLength(e.target.value.length === 0 ? comment.length : e.target.value.length)
    }
    return  <Modal
        visible={commentModal}
        className="amp-task-info-modal"
        width="550px"
        footer={null}
        title="填写评论"
        onCancel={hide}>
        <div className="center">
            <div className="content">
                <TextArea
                    defaultValue={comment}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="请填写评论信息"
                    onChange={(e) => handleValue(e)}
                    bordered={false}
                />
                <div className="details-count">
                    (
                    {(
                        <span className={`${valueLength && "count-text"}`}>
                                            {(valueLength || 0)}
                                        </span>
                    ) || 0}
                    /200)
                </div>
            </div>
        </div>
        <div className="footer">
            <AuthButton type="primary" className="btn-sz-m mg-r-20" onClick={handleChange}>
                保存
            </AuthButton>
            <AuthButton className="btn-sz-m" onClick={hide}>
                取消
            </AuthButton>
        </div>
    </Modal>
}
export default inject('taskPlanStore')(observer(CommentModel))
