/**
 * @name: index.jsx
 * @user: cfj
 * @date: 2022/4/19
 * @description:
 */
import { memo } from 'react'
import { registerRouterConfig, useRoutes } from '@components/router/routes'
import { barExtraContentLayout, EmptyLayout, HeaderNavLayout, SideBarLayout } from '@layouts'
import {
    AnalyticalDetail,
    AnalyticalSummary,
    Approve,
    CollectionDetail,
    CollectionSummary,
    Detail,
    HandoverDetail,
    ProductDetail,
    ProductGroupNew,
    Report,
    Summary
} from '.'
import RealTimeWorkProgress from './data_summary/real_time_work_progress'
import NumberAssets from './numberAssets'
import DestroyStatisticsDetails from './destroy'
import DestroyStatisticsChart from './destroy_chart'
import { AuthRedirect } from '@/wrappers'

// TODO 待解决 /stats 路径权限重定向权限问题

export const routes = [
    {
        index: true,
        name: 'statistics',
        component: SideBarLayout,
        routes: [
            {
                name: 'businessDataOverview',
                title: '业务数据总览',
                path: 'business-data-overview',
                icon: 'icon-a-30-shujutongjijindubiao',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'businessDataOverviewSummary',
                        title: '数据汇总',
                        path: 'summary',
                        component: HeaderNavLayout,
                        routes: [
                            {
                                name: 'businessDataOverviewSummaryNumberAssets',
                                index: true,
                                wrappers: [AuthRedirect],
                                title: '资产数量',
                                access: 'performance_goal_statistic_user_number',
                                component: NumberAssets
                            },
                            {
                                name: 'businessDataOverviewSummarySchedule',
                                path: 'schedule',
                                title: '实时进度',
                                access: 'performance_goal_statistic_user_detail',
                                component: RealTimeWorkProgress
                            }

                        ]
                    },
                    {
                        name: 'handoverDetail',
                        title: '前期交接明细',
                        path: 'handover-detail',
                        access: 'project_handover_log',
                        component: HandoverDetail,
                    },
                    {
                        name: 'businessDataKpi',
                        title: 'KPI',
                        path: 'kpi',
                        access: 'key_performance_indicator',
                        component: barExtraContentLayout,
                        routes: [
                            {
                                name: 'businessDataOverviewKpiWeek',
                                title: '制作周报',
                                key: 'week',
                                path: 'week',
                                wrappers: [AuthRedirect],
                                component: ProductGroupNew
                            },
                            {
                                name: 'businessDataOverviewKpiMonth',
                                path: 'month',
                                key: 'month',
                                title: '制作月报',
                                component: ProductGroupNew
                            },
                            {
                                name: 'businessDataOverviewKpiWeekDetail',
                                path: 'week-detail',
                                key: 'week-detail',
                                title: '周报明细',
                                component: ProductDetail
                            },
                            {
                                name: 'businessDataOverviewKpiMonthDetail',
                                path: 'month-detail',
                                key: 'month-detail',
                                title: '月报明细',
                                component: ProductDetail
                            },
                            {
                                name: 'businessDataOverviewKpiRedirect',
                                index: true,
                                redirect: 'businessDataOverviewKpiWeek',
                                wrappers: [AuthRedirect]
                            }
                        ]
                    },
                    {
                        name: 'businessDataOverviewRedirect',
                        index: true,
                        wrappers: [AuthRedirect],
                        redirect: 'businessDataOverviewSummary'
                    }
                ]
            },
            {
                name: 'collectionStatistics',
                title: '采集统计',
                path: 'collection-statistics',
                icon: 'icon-caijitongji',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'collectionStatisticsSummary',
                        title: '数据汇总',
                        path: 'summary',
                        access: 'collect_score_summary',
                        component: HeaderNavLayout,
                        routes: [
                            {
                                name: 'collectionStatisticsSummaryWeek',
                                title: '周汇总',
                                key: 'week',
                                path: 'week',
                                wrappers: [AuthRedirect],
                                component: CollectionSummary
                            },
                            {
                                name: 'collectionStatisticsSummaryMonth',
                                path: 'month',
                                key: 'month',
                                title: '月汇总',
                                component: CollectionSummary
                            },
                            {
                                name: 'collectionStatisticsSummaryRedirect',
                                index: true,
                                redirect: 'collectionStatisticsSummaryWeek',
                                wrappers: [AuthRedirect]
                            }
                        ]
                    },
                    {
                        name: 'collectionStatisticsDetails',
                        title: '数据明细',
                        access: 'collect_score_detail',
                        path: 'details',
                        component: CollectionDetail
                    }
                ]
            },
            {
                name: 'analyticalStatistics',
                title: '解析统计',
                path: 'analytical-statistics',
                icon: 'icon-jiexitongji',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'analyticalStatisticsSummary',
                        title: '数据汇总',
                        path: 'summary',
                        component: HeaderNavLayout,
                        access: 'analysis_score_summary',
                        routes: [
                            {
                                name: 'analyticalStatisticsSummaryWeek',
                                path: 'week',
                                key: 'week',
                                title: '周汇总',
                                wrappers: [AuthRedirect],
                                component: AnalyticalSummary
                            },
                            {
                                name: 'analyticalStatisticsSummaryMonth',
                                key: 'month',
                                path: 'month',
                                title: '月汇总',
                                component: AnalyticalSummary
                            },
                            {
                                name: 'analyticalStatisticsSummaryRedirect',
                                index: true,
                                redirect: 'analyticalStatisticsSummaryWeek',
                                wrappers: [AuthRedirect]
                            }
                        ]
                    },
                    {
                        name: 'analyticalStatisticsDetails',
                        title: '数据明细',
                        access: 'analysis_score_detail',
                        path: 'details',
                        component: AnalyticalDetail
                    }
                ]
            },
            {
                name: 'art',
                title: '拍摄美术评分统计',
                path: 'art',
                icon: 'icon-camera',
                access: 'shooting_score_report',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'artReport',
                        path: 'report',
                        title: '拍摄数据总览',
                        component: Report
                    },
                    {
                        name: 'artSummary',
                        path: 'art-summary',
                        access: 'shooting_data_summary',
                        title: '拍摄数据汇总',
                        component: HeaderNavLayout,
                        routes: [
                            {
                                name: 'artSummaryAll',
                                path: 'all',
                                title: '整体统计',
                                component: Summary
                            },
                            {
                                name: 'artSummaryUser',
                                path: 'user',
                                title: '个人统计',
                                component: Summary
                            },
                            {
                                name: 'artSummaryRedirect',
                                index: true,
                                wrappers: [AuthRedirect],
                                redirect: 'artSummaryAll'
                            }
                        ]
                    },
                    {
                        name: 'artDetails',
                        path: 'art-details',
                        title: '拍摄数据明细',
                        component: Detail
                    },
                    {
                        name: 'artRedirect',
                        index: true,
                        wrappers: [AuthRedirect],
                        redirect: 'businessDataOverviewSummary'
                    }
                ]
            },
            {
                name: 'production',
                title: '制作统计',
                path: 'production',
                icon: 'icon-rulers',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'productionReport',
                        path: 'report',
                        access: 'produce_difficulty_score_stats',
                        title: '制作数据总览',
                        component: Report

                    },
                    {
                        name: 'summary',
                        path: 'production-summary',
                        access: 'produce_difficulty_score_summary',
                        title: '制作数据汇总',
                        component: HeaderNavLayout,
                        routes: [
                            {
                                name: 'productionSummaryAll',
                                path: 'all',
                                title: '整体统计',
                                component: Summary
                            },
                            {
                                name: 'productionSummaryUser',
                                path: 'user',
                                title: '个人统计',
                                component: Summary
                            },
                            {
                                name: 'productionSummaryRedirect',
                                index: true,
                                wrappers: [AuthRedirect],
                                redirect: 'productionSummaryAll'
                            }
                        ]
                    },
                    {
                        name: 'productionDetails',
                        path: 'production-details',
                        access: 'produce_difficulty_score_detail',
                        title: '制作数据明细',
                        component: Detail
                    },
                    {
                        name: 'productionReviewDetail',
                        path: 'production-reviews',
                        access: 'produce_review_detail',
                        title: '制作审核明细',
                        component: Approve
                    },
                    {
                        name: 'productionRedirect',
                        index: true,
                        wrappers: [AuthRedirect],
                        redirect: 'summary'
                    }
                ]
            },
            {
                name: 'destroyStatistics',
                path: 'destroy',
                title: '作废统计',
                icon: 'icon-zuofeitongji',
                component: EmptyLayout,
                routes: [
                    {
                        name: 'destroyStatisticsChart',
                        title: '数据汇总',
                        path: 'chart',
                        access: 'task_discard_statistic_stats_summary',
                        wrappers: [AuthRedirect],
                        component: DestroyStatisticsChart
                    },
                    {
                        name: 'destroyStatisticsDetails',
                        title: '数据明细',
                        path: 'details',
                        access: 'task_discard_statistic_stats',
                        wrappers: [AuthRedirect],
                        component: DestroyStatisticsDetails
                    }
                ]
            },
            {
                name: 'stateRedirect',
                index: true,
                wrappers: [AuthRedirect],
                redirect: 'businessDataOverview'
            }
        ]
    }
];
// TODO 特别注意每次使用useRoutes 必须使用registerRouterConfig注册
registerRouterConfig(routes, undefined, '/stats');
export const StatsRoute = memo(() => useRoutes(routes, { disabledLinkTitle: true }));
