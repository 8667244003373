import {
  observable,
  action,
  computed,
  flow,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import { statsStore } from "@stores";
import { artStatsAPI, configsAPI, productionStatsAPI } from "@api";
import { debounce } from "lodash";
const page_size = 7;

class DetailStore {
  constructor() {
    makeAutoObservable(this);
  }
  @observable filter = {};
  @observable tab = "all";
  @observable table;
  @observable page = 1;
  @observable loading = true;
  @observable user_id;
  @computed get statType() {
    return statsStore?.statType;
  }
  @computed get date() {
    return statsStore?.date;
  }
  @computed get dateType() {
    return statsStore?.dateType;
  }
  @computed get relatedUsers() {
    return statsStore.relatedUsers;
  }
  @computed get params() {
    let [from, to] = this.date;
    from = from.format("YYYY-MM-DD");
    to = to.format("YYYY-MM-DD");
    const filter = { ...this.filter, created_at: { from, to } };
    const params = {
      filter,
      page_size,
      page: this.page,
    };
    return params;
  }
  @action clearDate() {
    statsStore.clearDate();
  }
  @action updateDate(val) {
    statsStore.updateDate(val);
  }
  @action updateDateType(val) {
    statsStore.updateDateType(val);
  }
  @action updateStatType(type) {
    statsStore.updateStatType(type);
  }

  @action updateUserId(id) {
    this.user_id = id
  }
  @action startLoad() {
    this.loading = true;
  }
  @action finishLoad() {
    this.loading = false;
  }
  @action changeFilter(val) {
    this.filter = val;
  }
  @action updateFilter(val) {
    this.filter = { ...this.filter, ...val };
  }
  @action updatePage(val) {
    this.page = val;
  }
  @action clear() {
    runInAction(() => {
      this.page = 1;
      this.table = [];
      this.filter = {};
    });
  }

  @action handleRequest(res) {
    if (res.code === 1000) {
      this.finishLoad();
      this.table = res.data.list;
      this.total = res.data.total;
    }
  }

  fetchArtDetails = flow(function* () {
    this.startLoad();
    try {
      const res = yield artStatsAPI.fetchArtDetails(this.params);
      this.handleRequest(res)
    } catch (e) {}
  });

  fetchProductionDetails = flow(function* () {
    this.startLoad();
    try {
      const res = yield productionStatsAPI.fetchProductionDetails(this.params);
      this.handleRequest(res);

    } catch (e) {}
  });
}

const detailStore = new DetailStore();
const handleDifferentRequest = () => {
  if (detailStore.statType === "art") detailStore.fetchArtDetails();
  else detailStore.fetchProductionDetails();
};

const debouncedRequest = debounce(handleDifferentRequest, 1000);

reaction(
  () => detailStore.date,
  (val, prevVal) => {
    if (val !== prevVal) {
      detailStore.updatePage(1);
      debouncedRequest();
    }
  }
);
reaction(
  () => detailStore.dateType,
  (val, prevVal) => {
    if (val !== prevVal) {
      detailStore.updatePage(1);
      debouncedRequest();
    }
  }
);

reaction(
  () => detailStore.filter,
  (val, prevVal) => {
    if (val !== prevVal) {
      detailStore.updatePage(1)
      debouncedRequest()
    };
  }
);
reaction(
  () => detailStore.page,
  (val, prevVal) => {
    if (val !== prevVal) debouncedRequest();
  }
);

export default detailStore;
