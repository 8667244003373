import React, { memo } from 'react';
import { Form, Input, Select, Row, Col, Button } from 'antd';
import { useSwr } from '@hooks';
import { projectSettingsAPI } from '@api';

interface ISearchFromProps {
    onSearch: (values: any) => void;
}
/**
 * 场景管理列表-搜索表单
 * @param {ISearchFromProps} props props
 * @constructor
 */
const SearchFrom: React.FC<ISearchFromProps> = (props) => {
    const { onSearch } = props;
    const [form] = Form.useForm();

    /**
     * 获取策划人员
     */
    const { data: userList = [] } = useSwr(
        '/user-relation/list',
        () => projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } }).then((res: any) => {
            if (res.code === 1000) {
                return res.data.list.map((item: any) => ({
                    value: item.user_id,
                    label: item.name
                }));
            }
            return [];
        })
    );

    return (
        <div className="amp-scene-wrap-search-form">
            <Form
                size="large"
                className="amp-scene-wrap-search-filter"
                onFinish={onSearch}
                form={form}
                // initialValues={{ type: 0, status: 0 }}
            >
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item colon={false} label="搜索选题" name="topicIdName">
                            <Input allowClear maxLength={50} placeholder="请输入选题名称或者ID" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item colon={false} label="创建人员" name="planUserId">
                            <Select
                                allowClear
                                showSearch
                                placeholder="请选择创建人员"
                                optionFilterProp="label"
                                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                options={userList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button
                            className="amp-button-default mg-r-10"
                            onClick={() => {
                                form.resetFields();
                                form.submit();
                            }}
                        >
                            重置
                        </Button>
                        <Button type="primary" htmlType="submit" className="amp-button-default">
                            查询
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default memo(SearchFrom);
