/**
 * @fileoverview gRPC-Web generated client stub for manager_api
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_amp_common_pb = require('./common/amp/common_pb.js')
const proto = {};
proto.manager_api = require('./manager_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.manager_api.ManagerApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.manager_api.ManagerApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SavePerformanceGoalsRequest,
 *   !proto.manager_api.PerformanceGoalsResponse>}
 */
const methodDescriptor_ManagerApiService_SavePerformanceGoals = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/SavePerformanceGoals',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SavePerformanceGoalsRequest,
  proto.manager_api.PerformanceGoalsResponse,
  /**
   * @param {!proto.manager_api.SavePerformanceGoalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.PerformanceGoalsResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SavePerformanceGoalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.PerformanceGoalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.PerformanceGoalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.savePerformanceGoals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/SavePerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SavePerformanceGoals,
      callback);
};


/**
 * @param {!proto.manager_api.SavePerformanceGoalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.PerformanceGoalsResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.savePerformanceGoals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/SavePerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SavePerformanceGoals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeletePerformanceGoals = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeletePerformanceGoals',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deletePerformanceGoals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeletePerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeletePerformanceGoals,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deletePerformanceGoals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeletePerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeletePerformanceGoals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.PerformanceGoalsResponse>}
 */
const methodDescriptor_ManagerApiService_GetPerformanceGoals = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetPerformanceGoals',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.PerformanceGoalsResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.PerformanceGoalsResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.PerformanceGoalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.PerformanceGoalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getPerformanceGoals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetPerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetPerformanceGoals,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.PerformanceGoalsResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getPerformanceGoals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetPerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetPerformanceGoals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListPerformanceGoalsRequest,
 *   !proto.manager_api.ListPerformanceGoalsIntegrationResponse>}
 */
const methodDescriptor_ManagerApiService_ListPerformanceGoals = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListPerformanceGoals',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListPerformanceGoalsRequest,
  proto.manager_api.ListPerformanceGoalsIntegrationResponse,
  /**
   * @param {!proto.manager_api.ListPerformanceGoalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListPerformanceGoalsIntegrationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListPerformanceGoalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListPerformanceGoalsIntegrationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListPerformanceGoalsIntegrationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listPerformanceGoals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListPerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListPerformanceGoals,
      callback);
};


/**
 * @param {!proto.manager_api.ListPerformanceGoalsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListPerformanceGoalsIntegrationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listPerformanceGoals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListPerformanceGoals',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListPerformanceGoals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.UpdateSysWorkdayRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_SaveSysWorkday = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/SaveSysWorkday',
  grpc.web.MethodType.UNARY,
  proto.manager_api.UpdateSysWorkdayRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.UpdateSysWorkdayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.UpdateSysWorkdayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.saveSysWorkday =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/SaveSysWorkday',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SaveSysWorkday,
      callback);
};


/**
 * @param {!proto.manager_api.UpdateSysWorkdayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.saveSysWorkday =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/SaveSysWorkday',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SaveSysWorkday);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysWorkdayRequest,
 *   !proto.manager_api.ListSysWorkdayResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysWorkday = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysWorkday',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysWorkdayRequest,
  proto.manager_api.ListSysWorkdayResponse,
  /**
   * @param {!proto.manager_api.ListSysWorkdayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysWorkdayResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysWorkdayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysWorkdayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysWorkdayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysWorkday =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysWorkday',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysWorkday,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysWorkdayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysWorkdayResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysWorkday =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysWorkday',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysWorkday);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetAssetApiRequest,
 *   !proto.manager_api.GetAssetApiResponse>}
 */
const methodDescriptor_ManagerApiService_GetAssetApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetAssetApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetAssetApiRequest,
  proto.manager_api.GetAssetApiResponse,
  /**
   * @param {!proto.manager_api.GetAssetApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.GetAssetApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetAssetApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.GetAssetApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.GetAssetApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getAssetApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetAssetApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetAssetApi,
      callback);
};


/**
 * @param {!proto.manager_api.GetAssetApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.GetAssetApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getAssetApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetAssetApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetAssetApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetAssetApiRequest,
 *   !proto.manager_api.GetAssetProductionApiResponse>}
 */
const methodDescriptor_ManagerApiService_GetAssetProductionApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetAssetProductionApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetAssetApiRequest,
  proto.manager_api.GetAssetProductionApiResponse,
  /**
   * @param {!proto.manager_api.GetAssetApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.GetAssetProductionApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetAssetApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.GetAssetProductionApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.GetAssetProductionApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getAssetProductionApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetAssetProductionApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetAssetProductionApi,
      callback);
};


/**
 * @param {!proto.manager_api.GetAssetApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.GetAssetProductionApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getAssetProductionApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetAssetProductionApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetAssetProductionApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.SysNotificationResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysNotificationInfo = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysNotificationInfo',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.SysNotificationResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysNotificationInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysNotificationInfo',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysNotificationInfo,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysNotificationInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysNotificationInfo',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysNotificationInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysNotificationRequest,
 *   !proto.manager_api.ListSysNotificationResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysNotification = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysNotification',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysNotificationRequest,
  proto.manager_api.ListSysNotificationResponse,
  /**
   * @param {!proto.manager_api.ListSysNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysNotification,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysNotificationRequest,
 *   !proto.manager_api.SysNotificationResponse>}
 */
const methodDescriptor_ManagerApiService_AddSysNotification = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/AddSysNotification',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysNotificationRequest,
  proto.manager_api.SysNotificationResponse,
  /**
   * @param {!proto.manager_api.SysNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.addSysNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/AddSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_AddSysNotification,
      callback);
};


/**
 * @param {!proto.manager_api.SysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.addSysNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/AddSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_AddSysNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysNotificationRequest,
 *   !proto.manager_api.SysNotificationResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysNotification = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysNotification',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysNotificationRequest,
  proto.manager_api.SysNotificationResponse,
  /**
   * @param {!proto.manager_api.SysNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysNotification,
      callback);
};


/**
 * @param {!proto.manager_api.SysNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysNotification = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysNotification',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysNotification,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.SysNotificationResponse>}
 */
const methodDescriptor_ManagerApiService_PublishSysNotification = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/PublishSysNotification',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.SysNotificationResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.publishSysNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/PublishSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_PublishSysNotification,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.publishSysNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/PublishSysNotification',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_PublishSysNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerStepGroupRequest,
 *   !proto.manager_api.CoreSchedulerStepGroupResponse>}
 */
const methodDescriptor_ManagerApiService_CreateCoreSchedulerStepGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateCoreSchedulerStepGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerStepGroupRequest,
  proto.manager_api.CoreSchedulerStepGroupResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createCoreSchedulerStepGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerStepGroup,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createCoreSchedulerStepGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerStepGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerStepGroupRequest,
 *   !proto.manager_api.CoreSchedulerStepGroupResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateCoreSchedulerStepGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerStepGroupRequest,
  proto.manager_api.CoreSchedulerStepGroupResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateCoreSchedulerStepGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepGroup,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateCoreSchedulerStepGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteCoreSchedulerStepGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteCoreSchedulerStepGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepGroup,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteCoreSchedulerStepGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetCoreSchedulerStepGroupRequest,
 *   !proto.manager_api.CoreSchedulerStepGroupResponse>}
 */
const methodDescriptor_ManagerApiService_GetCoreSchedulerStepGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetCoreSchedulerStepGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetCoreSchedulerStepGroupRequest,
  proto.manager_api.CoreSchedulerStepGroupResponse,
  /**
   * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getCoreSchedulerStepGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerStepGroup,
      callback);
};


/**
 * @param {!proto.manager_api.GetCoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getCoreSchedulerStepGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerStepGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListCoreSchedulerStepGroupRequest,
 *   !proto.manager_api.ListCoreSchedulerStepGroupResponse>}
 */
const methodDescriptor_ManagerApiService_ListCoreSchedulerStepGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListCoreSchedulerStepGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListCoreSchedulerStepGroupRequest,
  proto.manager_api.ListCoreSchedulerStepGroupResponse,
  /**
   * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListCoreSchedulerStepGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListCoreSchedulerStepGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListCoreSchedulerStepGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listCoreSchedulerStepGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerStepGroup,
      callback);
};


/**
 * @param {!proto.manager_api.ListCoreSchedulerStepGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListCoreSchedulerStepGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listCoreSchedulerStepGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerStepGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerStepGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerStepMenuRequest,
 *   !proto.manager_api.CoreSchedulerStepMenuResponse>}
 */
const methodDescriptor_ManagerApiService_CreateCoreSchedulerStepMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateCoreSchedulerStepMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerStepMenuRequest,
  proto.manager_api.CoreSchedulerStepMenuResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createCoreSchedulerStepMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerStepMenu,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createCoreSchedulerStepMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerStepMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerStepMenuRequest,
 *   !proto.manager_api.CoreSchedulerStepMenuResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateCoreSchedulerStepMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerStepMenuRequest,
  proto.manager_api.CoreSchedulerStepMenuResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateCoreSchedulerStepMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepMenu,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateCoreSchedulerStepMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerStepMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteCoreSchedulerStepMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteCoreSchedulerStepMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepMenu,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteCoreSchedulerStepMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerStepMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetCoreSchedulerStepMenuRequest,
 *   !proto.manager_api.CoreSchedulerStepMenuResponse>}
 */
const methodDescriptor_ManagerApiService_GetCoreSchedulerStepMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetCoreSchedulerStepMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetCoreSchedulerStepMenuRequest,
  proto.manager_api.CoreSchedulerStepMenuResponse,
  /**
   * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerStepMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerStepMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerStepMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getCoreSchedulerStepMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerStepMenu,
      callback);
};


/**
 * @param {!proto.manager_api.GetCoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerStepMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getCoreSchedulerStepMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerStepMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListCoreSchedulerStepMenuRequest,
 *   !proto.manager_api.ListCoreSchedulerStepMenuResponse>}
 */
const methodDescriptor_ManagerApiService_ListCoreSchedulerStepMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListCoreSchedulerStepMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListCoreSchedulerStepMenuRequest,
  proto.manager_api.ListCoreSchedulerStepMenuResponse,
  /**
   * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListCoreSchedulerStepMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListCoreSchedulerStepMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListCoreSchedulerStepMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listCoreSchedulerStepMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerStepMenu,
      callback);
};


/**
 * @param {!proto.manager_api.ListCoreSchedulerStepMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListCoreSchedulerStepMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listCoreSchedulerStepMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerStepMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerStepMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SchedulerStepRequest,
 *   !proto.manager_api.SchedulerStepResponse>}
 */
const methodDescriptor_ManagerApiService_AddSchedulerStep = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/AddSchedulerStep',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SchedulerStepRequest,
  proto.manager_api.SchedulerStepResponse,
  /**
   * @param {!proto.manager_api.SchedulerStepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SchedulerStepResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SchedulerStepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SchedulerStepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.addSchedulerStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/AddSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_AddSchedulerStep,
      callback);
};


/**
 * @param {!proto.manager_api.SchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SchedulerStepResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.addSchedulerStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/AddSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_AddSchedulerStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SchedulerStepRequest,
 *   !proto.manager_api.SchedulerStepResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSchedulerStep = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSchedulerStep',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SchedulerStepRequest,
  proto.manager_api.SchedulerStepResponse,
  /**
   * @param {!proto.manager_api.SchedulerStepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SchedulerStepResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SchedulerStepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SchedulerStepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSchedulerStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSchedulerStep,
      callback);
};


/**
 * @param {!proto.manager_api.SchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SchedulerStepResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSchedulerStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSchedulerStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSchedulerStepRequest,
 *   !proto.manager_api.ListSchedulerStepResponse>}
 */
const methodDescriptor_ManagerApiService_ListSchedulerStep = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSchedulerStep',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSchedulerStepRequest,
  proto.manager_api.ListSchedulerStepResponse,
  /**
   * @param {!proto.manager_api.ListSchedulerStepRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSchedulerStepResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSchedulerStepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSchedulerStepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSchedulerStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSchedulerStep,
      callback);
};


/**
 * @param {!proto.manager_api.ListSchedulerStepRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSchedulerStepResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSchedulerStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSchedulerStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.SchedulerStepResponse>}
 */
const methodDescriptor_ManagerApiService_GetSchedulerStep = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSchedulerStep',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.SchedulerStepResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SchedulerStepResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SchedulerStepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SchedulerStepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSchedulerStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSchedulerStep,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SchedulerStepResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSchedulerStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSchedulerStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSchedulerStep = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSchedulerStep',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSchedulerStep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSchedulerStep,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSchedulerStep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSchedulerStep',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSchedulerStep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerOperationRequest,
 *   !proto.manager_api.CoreSchedulerOperationResponse>}
 */
const methodDescriptor_ManagerApiService_CreateCoreSchedulerOperation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateCoreSchedulerOperation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerOperationRequest,
  proto.manager_api.CoreSchedulerOperationResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerOperationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerOperationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerOperationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createCoreSchedulerOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerOperation,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerOperationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createCoreSchedulerOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateCoreSchedulerOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.CoreSchedulerOperationRequest,
 *   !proto.manager_api.CoreSchedulerOperationResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateCoreSchedulerOperation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateCoreSchedulerOperation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.CoreSchedulerOperationRequest,
  proto.manager_api.CoreSchedulerOperationResponse,
  /**
   * @param {!proto.manager_api.CoreSchedulerOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerOperationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerOperationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerOperationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateCoreSchedulerOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerOperation,
      callback);
};


/**
 * @param {!proto.manager_api.CoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerOperationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateCoreSchedulerOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateCoreSchedulerOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteCoreSchedulerOperation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteCoreSchedulerOperation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteCoreSchedulerOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerOperation,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteCoreSchedulerOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteCoreSchedulerOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetCoreSchedulerOperationRequest,
 *   !proto.manager_api.CoreSchedulerOperationResponse>}
 */
const methodDescriptor_ManagerApiService_GetCoreSchedulerOperation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetCoreSchedulerOperation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetCoreSchedulerOperationRequest,
  proto.manager_api.CoreSchedulerOperationResponse,
  /**
   * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.CoreSchedulerOperationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.CoreSchedulerOperationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.CoreSchedulerOperationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getCoreSchedulerOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerOperation,
      callback);
};


/**
 * @param {!proto.manager_api.GetCoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.CoreSchedulerOperationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getCoreSchedulerOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetCoreSchedulerOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListCoreSchedulerOperationRequest,
 *   !proto.manager_api.ListCoreSchedulerOperationResponse>}
 */
const methodDescriptor_ManagerApiService_ListCoreSchedulerOperation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListCoreSchedulerOperation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListCoreSchedulerOperationRequest,
  proto.manager_api.ListCoreSchedulerOperationResponse,
  /**
   * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListCoreSchedulerOperationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListCoreSchedulerOperationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListCoreSchedulerOperationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listCoreSchedulerOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerOperation,
      callback);
};


/**
 * @param {!proto.manager_api.ListCoreSchedulerOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListCoreSchedulerOperationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listCoreSchedulerOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListCoreSchedulerOperation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListCoreSchedulerOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SaveSchedulerStepOperationRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_SaveSchedulerStepOperationRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/SaveSchedulerStepOperationRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SaveSchedulerStepOperationRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.saveSchedulerStepOperationRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/SaveSchedulerStepOperationRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SaveSchedulerStepOperationRelation,
      callback);
};


/**
 * @param {!proto.manager_api.SaveSchedulerStepOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.saveSchedulerStepOperationRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/SaveSchedulerStepOperationRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_SaveSchedulerStepOperationRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TreeSchedulerStepOperationRequest,
 *   !proto.manager_api.TreeSchedulerStepOperationResponse>}
 */
const methodDescriptor_ManagerApiService_TreeSchedulerStepOperationRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/TreeSchedulerStepOperationRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TreeSchedulerStepOperationRequest,
  proto.manager_api.TreeSchedulerStepOperationResponse,
  /**
   * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TreeSchedulerStepOperationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TreeSchedulerStepOperationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TreeSchedulerStepOperationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.treeSchedulerStepOperationRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/TreeSchedulerStepOperationRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_TreeSchedulerStepOperationRelation,
      callback);
};


/**
 * @param {!proto.manager_api.TreeSchedulerStepOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TreeSchedulerStepOperationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.treeSchedulerStepOperationRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/TreeSchedulerStepOperationRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_TreeSchedulerStepOperationRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysRoleRequest,
 *   !proto.manager_api.SysRoleResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysRoleRequest,
  proto.manager_api.SysRoleResponse,
  /**
   * @param {!proto.manager_api.SysRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysRole,
      callback);
};


/**
 * @param {!proto.manager_api.SysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysRoleRequest,
 *   !proto.manager_api.SysRoleResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysRoleRequest,
  proto.manager_api.SysRoleResponse,
  /**
   * @param {!proto.manager_api.SysRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysRole,
      callback);
};


/**
 * @param {!proto.manager_api.SysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysRole,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysRoleRequest,
 *   !proto.manager_api.SysRoleResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysRoleRequest,
  proto.manager_api.SysRoleResponse,
  /**
   * @param {!proto.manager_api.GetSysRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysRole,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysRoleRequest,
 *   !proto.manager_api.ListSysRoleResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysRoleRequest,
  proto.manager_api.ListSysRoleResponse,
  /**
   * @param {!proto.manager_api.ListSysRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysRole,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupTypeRequest,
 *   !proto.manager_api.TaskUserGroupTypeResponse>}
 */
const methodDescriptor_ManagerApiService_CreateTaskUserGroupType = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateTaskUserGroupType',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupTypeRequest,
  proto.manager_api.TaskUserGroupTypeResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupTypeResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createTaskUserGroupType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroupType,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupTypeResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createTaskUserGroupType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroupType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupTypeRequest,
 *   !proto.manager_api.TaskUserGroupTypeResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateTaskUserGroupType = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateTaskUserGroupType',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupTypeRequest,
  proto.manager_api.TaskUserGroupTypeResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupTypeResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateTaskUserGroupType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroupType,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupTypeResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateTaskUserGroupType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroupType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteTaskUserGroupType = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteTaskUserGroupType',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteTaskUserGroupType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroupType,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteTaskUserGroupType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroupType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetTaskUserGroupTypeRequest,
 *   !proto.manager_api.TaskUserGroupTypeResponse>}
 */
const methodDescriptor_ManagerApiService_GetTaskUserGroupType = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetTaskUserGroupType',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetTaskUserGroupTypeRequest,
  proto.manager_api.TaskUserGroupTypeResponse,
  /**
   * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupTypeResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getTaskUserGroupType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroupType,
      callback);
};


/**
 * @param {!proto.manager_api.GetTaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupTypeResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getTaskUserGroupType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroupType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListTaskUserGroupTypeRequest,
 *   !proto.manager_api.ListTaskUserGroupTypeResponse>}
 */
const methodDescriptor_ManagerApiService_ListTaskUserGroupType = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListTaskUserGroupType',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListTaskUserGroupTypeRequest,
  proto.manager_api.ListTaskUserGroupTypeResponse,
  /**
   * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListTaskUserGroupTypeResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListTaskUserGroupTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListTaskUserGroupTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listTaskUserGroupType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroupType,
      callback);
};


/**
 * @param {!proto.manager_api.ListTaskUserGroupTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListTaskUserGroupTypeResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listTaskUserGroupType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroupType',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroupType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupRequest,
 *   !proto.manager_api.TaskUserGroupResponse>}
 */
const methodDescriptor_ManagerApiService_CreateTaskUserGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateTaskUserGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupRequest,
  proto.manager_api.TaskUserGroupResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createTaskUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroup,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createTaskUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupRequest,
 *   !proto.manager_api.TaskUserGroupResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateTaskUserGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateTaskUserGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupRequest,
  proto.manager_api.TaskUserGroupResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateTaskUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroup,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateTaskUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteTaskUserGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteTaskUserGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteTaskUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroup,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteTaskUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetTaskUserGroupRequest,
 *   !proto.manager_api.TaskUserGroupResponse>}
 */
const methodDescriptor_ManagerApiService_GetTaskUserGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetTaskUserGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetTaskUserGroupRequest,
  proto.manager_api.TaskUserGroupResponse,
  /**
   * @param {!proto.manager_api.GetTaskUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetTaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getTaskUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroup,
      callback);
};


/**
 * @param {!proto.manager_api.GetTaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getTaskUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListTaskUserGroupRequest,
 *   !proto.manager_api.ListTaskUserGroupResponse>}
 */
const methodDescriptor_ManagerApiService_ListTaskUserGroup = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListTaskUserGroup',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListTaskUserGroupRequest,
  proto.manager_api.ListTaskUserGroupResponse,
  /**
   * @param {!proto.manager_api.ListTaskUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListTaskUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListTaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListTaskUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListTaskUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listTaskUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroup,
      callback);
};


/**
 * @param {!proto.manager_api.ListTaskUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListTaskUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listTaskUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroup',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupMemberRequest,
 *   !proto.manager_api.TaskUserGroupMemberResponse>}
 */
const methodDescriptor_ManagerApiService_CreateTaskUserGroupMember = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateTaskUserGroupMember',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupMemberRequest,
  proto.manager_api.TaskUserGroupMemberResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupMemberResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createTaskUserGroupMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroupMember,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupMemberResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createTaskUserGroupMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateTaskUserGroupMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.TaskUserGroupMemberRequest,
 *   !proto.manager_api.TaskUserGroupMemberResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateTaskUserGroupMember = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateTaskUserGroupMember',
  grpc.web.MethodType.UNARY,
  proto.manager_api.TaskUserGroupMemberRequest,
  proto.manager_api.TaskUserGroupMemberResponse,
  /**
   * @param {!proto.manager_api.TaskUserGroupMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupMemberResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateTaskUserGroupMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroupMember,
      callback);
};


/**
 * @param {!proto.manager_api.TaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupMemberResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateTaskUserGroupMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateTaskUserGroupMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteTaskUserGroupMember = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteTaskUserGroupMember',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteTaskUserGroupMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroupMember,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteTaskUserGroupMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteTaskUserGroupMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetTaskUserGroupMemberRequest,
 *   !proto.manager_api.TaskUserGroupMemberResponse>}
 */
const methodDescriptor_ManagerApiService_GetTaskUserGroupMember = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetTaskUserGroupMember',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetTaskUserGroupMemberRequest,
  proto.manager_api.TaskUserGroupMemberResponse,
  /**
   * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.TaskUserGroupMemberResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.TaskUserGroupMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.TaskUserGroupMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getTaskUserGroupMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroupMember,
      callback);
};


/**
 * @param {!proto.manager_api.GetTaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.TaskUserGroupMemberResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getTaskUserGroupMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetTaskUserGroupMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListTaskUserGroupMemberRequest,
 *   !proto.manager_api.ListTaskUserGroupMemberResponse>}
 */
const methodDescriptor_ManagerApiService_ListTaskUserGroupMember = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListTaskUserGroupMember',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListTaskUserGroupMemberRequest,
  proto.manager_api.ListTaskUserGroupMemberResponse,
  /**
   * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListTaskUserGroupMemberResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListTaskUserGroupMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListTaskUserGroupMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listTaskUserGroupMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroupMember,
      callback);
};


/**
 * @param {!proto.manager_api.ListTaskUserGroupMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListTaskUserGroupMemberResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listTaskUserGroupMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListTaskUserGroupMember',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListTaskUserGroupMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysApiRequest,
 *   !proto.manager_api.SysApiResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysApiRequest,
  proto.manager_api.SysApiResponse,
  /**
   * @param {!proto.manager_api.SysApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysApi,
      callback);
};


/**
 * @param {!proto.manager_api.SysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysApiRequest,
 *   !proto.manager_api.SysApiResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysApiRequest,
  proto.manager_api.SysApiResponse,
  /**
   * @param {!proto.manager_api.SysApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysApi,
      callback);
};


/**
 * @param {!proto.manager_api.SysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysApi,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysApiRequest,
 *   !proto.manager_api.SysApiResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysApiRequest,
  proto.manager_api.SysApiResponse,
  /**
   * @param {!proto.manager_api.GetSysApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysApi,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysApiRequest,
 *   !proto.manager_api.ListSysApiResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysApi = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysApi',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysApiRequest,
  proto.manager_api.ListSysApiResponse,
  /**
   * @param {!proto.manager_api.ListSysApiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysApiResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysApiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysApiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysApi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysApi,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysApiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysApiResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysApi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysApi',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysApi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysMenuRequest,
 *   !proto.manager_api.SysMenuResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysMenuRequest,
  proto.manager_api.SysMenuResponse,
  /**
   * @param {!proto.manager_api.SysMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysMenu,
      callback);
};


/**
 * @param {!proto.manager_api.SysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysMenuRequest,
 *   !proto.manager_api.SysMenuResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysMenuRequest,
  proto.manager_api.SysMenuResponse,
  /**
   * @param {!proto.manager_api.SysMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysMenu,
      callback);
};


/**
 * @param {!proto.manager_api.SysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysMenu,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysMenuRequest,
 *   !proto.manager_api.SysMenuResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysMenuRequest,
  proto.manager_api.SysMenuResponse,
  /**
   * @param {!proto.manager_api.GetSysMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysMenu,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysMenuRequest,
 *   !proto.manager_api.ListSysMenuResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysMenu = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysMenu',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysMenuRequest,
  proto.manager_api.ListSysMenuResponse,
  /**
   * @param {!proto.manager_api.ListSysMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysMenuResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysMenu,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysMenuResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysMenu',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysMenuApiRelationRequest,
 *   !proto.manager_api.SysMenuApiRelationResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysMenuApiRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysMenuApiRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysMenuApiRelationRequest,
  proto.manager_api.SysMenuApiRelationResponse,
  /**
   * @param {!proto.manager_api.SysMenuApiRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuApiRelationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuApiRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuApiRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysMenuApiRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysMenuApiRelation,
      callback);
};


/**
 * @param {!proto.manager_api.SysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuApiRelationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysMenuApiRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysMenuApiRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysMenuApiRelationRequest,
 *   !proto.manager_api.SysMenuApiRelationResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysMenuApiRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysMenuApiRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysMenuApiRelationRequest,
  proto.manager_api.SysMenuApiRelationResponse,
  /**
   * @param {!proto.manager_api.SysMenuApiRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuApiRelationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuApiRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuApiRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysMenuApiRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysMenuApiRelation,
      callback);
};


/**
 * @param {!proto.manager_api.SysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuApiRelationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysMenuApiRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysMenuApiRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysMenuApiRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysMenuApiRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysMenuApiRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysMenuApiRelation,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysMenuApiRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysMenuApiRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysMenuApiRelationRequest,
 *   !proto.manager_api.SysMenuApiRelationResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysMenuApiRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysMenuApiRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysMenuApiRelationRequest,
  proto.manager_api.SysMenuApiRelationResponse,
  /**
   * @param {!proto.manager_api.GetSysMenuApiRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysMenuApiRelationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysMenuApiRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysMenuApiRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysMenuApiRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysMenuApiRelation,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysMenuApiRelationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysMenuApiRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysMenuApiRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysMenuApiRelationRequest,
 *   !proto.manager_api.ListSysMenuApiRelationResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysMenuApiRelation = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysMenuApiRelation',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysMenuApiRelationRequest,
  proto.manager_api.ListSysMenuApiRelationResponse,
  /**
   * @param {!proto.manager_api.ListSysMenuApiRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysMenuApiRelationResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysMenuApiRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysMenuApiRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysMenuApiRelation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysMenuApiRelation,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysMenuApiRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysMenuApiRelationResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysMenuApiRelation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysMenuApiRelation',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysMenuApiRelation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysUserSchedulerRoleRequest,
 *   !proto.manager_api.SysUserSchedulerRoleResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysUserSchedulerRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysUserSchedulerRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysUserSchedulerRoleRequest,
  proto.manager_api.SysUserSchedulerRoleResponse,
  /**
   * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserSchedulerRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserSchedulerRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysUserSchedulerRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysUserSchedulerRole,
      callback);
};


/**
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserSchedulerRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysUserSchedulerRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysUserSchedulerRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysUserSchedulerRoleRequest,
 *   !proto.manager_api.SysUserSchedulerRoleResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysUserSchedulerRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysUserSchedulerRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysUserSchedulerRoleRequest,
  proto.manager_api.SysUserSchedulerRoleResponse,
  /**
   * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserSchedulerRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserSchedulerRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysUserSchedulerRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysUserSchedulerRole,
      callback);
};


/**
 * @param {!proto.manager_api.SysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserSchedulerRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysUserSchedulerRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysUserSchedulerRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysUserSchedulerRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysUserSchedulerRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysUserSchedulerRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysUserSchedulerRole,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysUserSchedulerRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysUserSchedulerRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysUserSchedulerRoleRequest,
 *   !proto.manager_api.SysUserSchedulerRoleResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysUserSchedulerRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysUserSchedulerRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysUserSchedulerRoleRequest,
  proto.manager_api.SysUserSchedulerRoleResponse,
  /**
   * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserSchedulerRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserSchedulerRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserSchedulerRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysUserSchedulerRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysUserSchedulerRole,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserSchedulerRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysUserSchedulerRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysUserSchedulerRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysUserSchedulerRoleRequest,
 *   !proto.manager_api.ListSysUserSchedulerRoleResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysUserSchedulerRole = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysUserSchedulerRole',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysUserSchedulerRoleRequest,
  proto.manager_api.ListSysUserSchedulerRoleResponse,
  /**
   * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysUserSchedulerRoleResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysUserSchedulerRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysUserSchedulerRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysUserSchedulerRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysUserSchedulerRole,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysUserSchedulerRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysUserSchedulerRoleResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysUserSchedulerRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysUserSchedulerRole',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysUserSchedulerRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysTagRequest,
 *   !proto.manager_api.SysTagResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysTag = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysTag',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysTagRequest,
  proto.manager_api.SysTagResponse,
  /**
   * @param {!proto.manager_api.SysTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysTagResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysTag,
      callback);
};


/**
 * @param {!proto.manager_api.SysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysTagResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysTagRequest,
 *   !proto.manager_api.SysTagResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysTag = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysTag',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysTagRequest,
  proto.manager_api.SysTagResponse,
  /**
   * @param {!proto.manager_api.SysTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysTagResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysTag,
      callback);
};


/**
 * @param {!proto.manager_api.SysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysTagResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysTag = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysTag',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysTag,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysTagRequest,
 *   !proto.manager_api.SysTagResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysTag = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysTag',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysTagRequest,
  proto.manager_api.SysTagResponse,
  /**
   * @param {!proto.manager_api.GetSysTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysTagResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysTag,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysTagResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysTagRequest,
 *   !proto.manager_api.ListSysTagResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysTag = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysTag',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysTagRequest,
  proto.manager_api.ListSysTagResponse,
  /**
   * @param {!proto.manager_api.ListSysTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysTagResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysTag,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysTagResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysTag',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysUserRequest,
 *   !proto.manager_api.SysUserResponse>}
 */
const methodDescriptor_ManagerApiService_CreateSysUser = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/CreateSysUser',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysUserRequest,
  proto.manager_api.SysUserResponse,
  /**
   * @param {!proto.manager_api.SysUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.createSysUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysUser,
      callback);
};


/**
 * @param {!proto.manager_api.SysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.createSysUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/CreateSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_CreateSysUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.SysUserRequest,
 *   !proto.manager_api.SysUserResponse>}
 */
const methodDescriptor_ManagerApiService_UpdateSysUser = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/UpdateSysUser',
  grpc.web.MethodType.UNARY,
  proto.manager_api.SysUserRequest,
  proto.manager_api.SysUserResponse,
  /**
   * @param {!proto.manager_api.SysUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.SysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.updateSysUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysUser,
      callback);
};


/**
 * @param {!proto.manager_api.SysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.updateSysUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/UpdateSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_UpdateSysUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.IdRequest,
 *   !proto.manager_api.EmptyResponse>}
 */
const methodDescriptor_ManagerApiService_DeleteSysUser = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/DeleteSysUser',
  grpc.web.MethodType.UNARY,
  proto.manager_api.IdRequest,
  proto.manager_api.EmptyResponse,
  /**
   * @param {!proto.manager_api.IdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.deleteSysUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysUser,
      callback);
};


/**
 * @param {!proto.manager_api.IdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.deleteSysUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/DeleteSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_DeleteSysUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.GetSysUserRequest,
 *   !proto.manager_api.SysUserResponse>}
 */
const methodDescriptor_ManagerApiService_GetSysUser = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/GetSysUser',
  grpc.web.MethodType.UNARY,
  proto.manager_api.GetSysUserRequest,
  proto.manager_api.SysUserResponse,
  /**
   * @param {!proto.manager_api.GetSysUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.SysUserResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.GetSysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.SysUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.SysUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.getSysUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysUser,
      callback);
};


/**
 * @param {!proto.manager_api.GetSysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.SysUserResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.getSysUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/GetSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_GetSysUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.manager_api.ListSysUserRequest,
 *   !proto.manager_api.ListSysUserResponse>}
 */
const methodDescriptor_ManagerApiService_ListSysUser = new grpc.web.MethodDescriptor(
  '/manager_api.ManagerApiService/ListSysUser',
  grpc.web.MethodType.UNARY,
  proto.manager_api.ListSysUserRequest,
  proto.manager_api.ListSysUserResponse,
  /**
   * @param {!proto.manager_api.ListSysUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.manager_api.ListSysUserResponse.deserializeBinary
);


/**
 * @param {!proto.manager_api.ListSysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.manager_api.ListSysUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.manager_api.ListSysUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.manager_api.ManagerApiServiceClient.prototype.listSysUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysUser,
      callback);
};


/**
 * @param {!proto.manager_api.ListSysUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.manager_api.ListSysUserResponse>}
 *     Promise that resolves to the response
 */
proto.manager_api.ManagerApiServicePromiseClient.prototype.listSysUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/manager_api.ManagerApiService/ListSysUser',
      request,
      metadata || {},
      methodDescriptor_ManagerApiService_ListSysUser);
};


module.exports = proto.manager_api;

