import React, { useState, useEffect } from 'react';
import { Empty } from 'antd';
import Image from '@/components/image'
import PreviewImage from '@components/image/preview_image';
import MackIcon from '@components/image/mack_icon';
import { sessionAPI } from '@api';
import './index.less';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

const ImageSwiper = (props) => {
    const { slidesPerView = 2 } = props;
    const [list, setList] = useState([]);
    const onPreview = (idx) => {
        let temp = [];
        props?.imageList?.map(({ fileUrl }) => {
            temp.push(fileUrl);
        })
        PreviewImage.open({
            images: temp,
            current: idx,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };
    const renderImageItem = (list) => {
        let temp = [];
        list?.map((item, idx) => {
            temp.push(
                <SwiperSlide key={item?.imageUrl || item?.fileUrl}>
                    <Image
                        preview={{
                            visible: false
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onPreview(idx)
                        }}  // 点击图片全屏查看图片
                        // mask={[<MackIcon onClick={() => {onPreview(idx)}} type={'icon-a-16-chakan'} />]} // 点击Icon全屏查看图片
                        src={item?.imageUrl || item?.fileUrl}
                    />
                </SwiperSlide>
            )
        });
        return temp;
    }

    useEffect(() => {
        setList(renderImageItem(props?.imageList));
    }, [props?.imageList]);

    return (
        <div className='amps-image-swiper'>
            {
                list.length ? <Swiper
                    modules={[Navigation]}
                    navigation={true}
                    slidesPerView={slidesPerView}
                    spaceBetween={8}
                >
                    {list}
                </Swiper> : <Empty image={`${process.env.PUBLIC_URL}/images/other/image2.png`} description="暂无数据" />
            }
        </div>
    )
}

export default ImageSwiper;
