import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { sceneEventBus } from '@/event_bus';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import { useOutlet } from '@/components/router/routes';
import { assetCommitteeReviewList } from '../config';
import '../plan_examine/index.less';

const { TabPane } = Tabs;

/**
 * 资产委员会审核页面
 * @returns {JSX.Element}
 */
const AssetCommitteeReview: React.FC = () => {
    const history = useHistory();
    const outlet = useOutlet();
    const [total, setTotal] = useState(0);
    const status = 203; // 资产委员会审核

    /**
     * listTopicSceneAddress
     */
    const listTopicSceneAddress = () => {
        SelectedTopicApi().listTopicSceneAddress({ status })
            .then((res: any) => setTotal(res.total))
            .catch((err: any) => {
                throw new Error(err.message);
            });
    };
    // 获取资产委员会审核场景总数
    useEffect(() => {
        listTopicSceneAddress();
    }, []);

    useEffect(() => {
        /**
         * 刷新total
         */
        const reFresh = () => {
            listTopicSceneAddress();
        };

        const off = sceneEventBus.on(reFresh);
        return off;
    }, [sceneEventBus]);

    return (
        <div className="amps-plan-examine-page page-container">
            <Tabs
                tabBarGutter={40}
                animated={false}
                defaultActiveKey="scene"
                onChange={(key) => {
                    history.push(key);
                }}
            >
                {
                    assetCommitteeReviewList.map((item) => (
                        <TabPane tab={`${item.title}(${total})`} key={item.name} />
                    ))
                }
            </Tabs>
            {outlet}
        </div>
    );
};

export default AssetCommitteeReview;
