import { connect } from "react-redux";
import Announcement from "./announcement";
import { fetchAnnouncement } from "@actions/1_entities/announcement_actions";

const mapStateToProps = (state) => ({
  announcements: state.entities.announcements,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAnnouncement: (params, success, error) =>
    dispatch(fetchAnnouncement(params, success, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
