import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Select } from 'antd';
import { Upload } from '@components';

const FormItem = Form.Item
const { Option } = Select
@inject("taskStore", "projectStore")
@observer

class AssociatedForm extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            fileList: {},
            type: 2
        })
    }

    uploadRef = React.createRef();
    /**
     * 获取taskStore
     */
    get store() {
        return this.props.taskStore;
    }
    /**
     * 获取projectStore
     */
    get projectStore() {
        return this.props.projectStore;
    }

    componentDidMount() {
        const { formType, currentTask } = this.props;
        if (formType === 'task_edit' && currentTask?.task_package_id) {
            this.props.onChange?.(currentTask?.task_package_id)
        }
    }

    selectHandle = (v, item, arr) => {
        this.store.packageItem = item?.items
        this.props.onChange?.(v)
        this.store.selectedItem = undefined
    }

    taskType = (value) => {
        this.store.selectedItem = undefined
        if (value === 1) {
            this.setState({ type: 1 })
            this.store.isPlanningTask = false
        } else if (value === 2) {
            this.setState({ type: 2 })
            this.store.isPlanningTask = true
        }
    }

    render() {
        const { isPlanningTask, taskPackageList } = this.store
        return (
            <>
                <div className="task-form-col-2">
                    <div className="bold required mg-b-16">选择任务包</div>
                    <FormItem
                        name="package_id"
                        rules={[{ required: true, message: `请选择任务包` }]}
                    >
                        <Select
                            className="associate-input-sz-ml"
                            placeholder="请选择任务包"
                            size="large"
                            showSearch
                            showArrow
                            onChange={this.selectHandle}
                        >
                            {taskPackageList?.map((item, idx) => (
                                <Option
                                    value={item.id}
                                    items={item}
                                    key={idx}
                                >
                                    {item.title}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>
                <div className="task-form-col-2">
                    <div className="bold required mg-b-16">任务类型</div>
                    <FormItem
                        name="package_type"
                        required={true}
                        initialValue={1}
                    >
                        <Select
                            className="associate-input-sz-ml"
                            placeholder="请选择任务类型"
                            size="large"
                            showSearch
                            showArrow
                            onChange={this.taskType}
                        >
                            <Option value={1}>策划任务</Option>
                            <Option value={2}>自建任务</Option>

                        </Select>
                    </FormItem>
                </div>
                {
                    isPlanningTask && (
                        <>
                            <div className="task-form-col-2">
                                <div className="bold required mg-b-16">名称</div>
                                <FormItem
                                    name="title"
                                    rules={[{ required: true, message: `请填写名称` }]}
                                >
                                    <Input
                                        className="associate-input-sz-ml"
                                        placeholder="请输入名称"
                                        size="large"
                                        maxLength={20}
                                    />
                                </FormItem>
                            </div>
                            <div className="task-form-col-2">
                                <div className="bold required mg-b-16">上传人</div>
                                {
                                    this.projectStore.loginUser?.name &&
                                    <FormItem
                                        name="associate_up"
                                        required={true}
                                        initialValue={this.projectStore.loginUser?.name}
                                    >
                                        <Input
                                            className="associate-input-sz-ml"
                                            placeholder="请输入上传人"
                                            size="large"
                                            disabled
                                        />
                                    </FormItem>
                                }
                            </div>
                            <div className="task-form-col-1">
                                <div className="bold required mg-b-16">素材属性</div>
                                <Form.Item required={true} name={'material_type'} initialValue={2}>
                                    <Select
                                        className="associate-input-sz-ml"
                                        size="large"
                                    >
                                        <Option value={1}>项目素材</Option>
                                        <Option value={2}>泛素材</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="task-form-col-2">
                                <div className="bold required">任务图片</div>
                                <FormItem
                                    required={true}
                                    className='upload-form-item'
                                    name="up_images"
                                >
                                    <Upload
                                        ref={this.uploadRef}
                                        fileList={this.state.fileList}
                                        // entity={this.store.currentTask}
                                        maxCount={5}
                                    />
                                </FormItem>
                            </div>
                        </>
                    )}
            </>
        )
    }
}

export default AssociatedForm
