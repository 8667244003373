import React from 'react';
import { Upload } from '@/components';
import { Form, Input, InputNumber } from 'antd';
import { drawerTitle } from './../config';

export function Title(props) {
    return (
        <>
            {drawerTitle[props.title]?.formTitle}
        </>
    );
}

/**
 * 输入框
 * @param {P} props
 * @returns
 */
export const DisabledInput = (props) => {
    return (
        <>
            {
                drawerTitle[props.title]?.formContent?.map((item, index) => (
                    <div className="amp-drawer-ant-row" key={index}>
                        <Form.Item
                            label={item.label}
                            name={item.name}
                            key={item.name}
                            initialValue={props.value || null}
                        >
                            <Input
                                className="task_plan_input-sz-ml"
                                disabled={!item.disabled}
                                // placeholder={item.placeholder ? item.placeholder : "582171"}
                            />
                        </Form.Item>
                    </div>
                ))
            }
        </>
    )
}

/**
 * 禁输入框
 */
 export const ForbidEditInput = (props) => {
    const { operatorName, name, currentTaskTotal, action_name } = props;
    return (
        <div className={
            `${action_name === "take" ? "task-form-col-1" : "task-form-col-2"}`
        }>
            <div className="mg-b-8 bold">{operatorName ? operatorName : "下一步"}</div>
            <Input
                disabled
                value={props.value}
                className={`input-sz-m mg-b-16
                    ${name === "totals" && props.value < currentTaskTotal  ? "special-color" : null}
                `}
            />
        </div>
    )
}

export const TaskName = (props) => {
    const message = "请输入任务名称";
    return (
        <div className="task-form-col-2">
            <div className="bold required mg-b-16">名称</div>
            <Form.Item
                name="task_name"
                rules={[{ required: true, message }]}
                initialValue={props.updateTask.title || null}
            >
                <Input
                    className="task_plan_input-sz-ml"
                    placeholder={message}
                    maxLength={50}
                />
            </Form.Item>
        </div>
    );
};

export const TaskUploader = (props) => {
    const message = "请输入上传人";
    return (
        <div className="task-form-col-2">
            <div className="bold required mg-b-16">上传人</div>
            <Form.Item
                name="task_uploader"
                rules={[{ required: true, message }]}
                initialValue={props.updateTask.creator?.name || props.loginUser?.name || null}
            >
                <Input
                    className="task_plan_input-sz-ml"
                    placeholder={message}
                    disabled
                />
            </Form.Item>
        </div>
    );
};

export const TaskScore = (props) => {
    const message = "请输入自评分";
    return (
        <div className="task-form-col-2">
            <div className="bold required mg-b-16">自评分</div>
            <Form.Item
                name="task_score"
                rules={[{ required: true, message }]}
                initialValue={props.updateTask.score || null}
            >
                <InputNumber
                    size="large"
                    className="task_plan_input-sz-ml"
                    placeholder={message}
                    min={0}
                    max={100}
                    precision={0}
                />
            </Form.Item>
        </div>
    );
};

export const UploadImage = (props) => {
    const uploadTitle = (
        <div className="align-center">
            <div className="bold required mg-r-5">任务图片</div>
        </div>
    );
    return (
        <div
            className="process-form-col-1"
            key={Math.random()}
        >
            <Upload
                ref={props.uploadRef}
                extra={uploadTitle}
                showIndicator={false}
                beforeUpload={props.beforeUpload}
                length={5}
                entity={props.updateTask.images}
            />
        </div>
    );
}

export const VolumeUploadExcel = (props) => {
    return (
        <div className="mg-r-12 mg-t-20">
            <Upload
                ref={props.uploadExcelRef}
                beforeUpload={props.beforeUpload}
                length={1}
                iconType="icon-a-14shangchuan"
                iconTitle="上传电子表格"
            />
        </div>
    );
}

export const VolumeUploadImage = (props) => {
    return (
        <div className="mg-t-20">
            <Upload
                ref={props.uploadZipRef}
                beforeUpload={props.beforeUpload}
                length={1}
                iconType="icon-a-14shangchuan"
            />
        </div>
    );
}

export const TaskPlanningComment = (props) => {
    return (
        <div className="process-form-col-1 mg-b-6 mg-t-20">
            <div className="mg-b-16 bold">驳回评论</div>
            <Form.Item
                name="task_planning_comment"
                initialValue={props.updateTask.comment || null}
            >
                <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder="请输入驳回评论"
                    style={{ width: "44vw" }}
                    maxLength={200}
                    disabled
                />
            </Form.Item>
        </div>
    );
};
