import React, { Component } from "react";
import {Form, Select} from 'antd'
const {Option} = Select
class ReviewFormSelect extends Component {
  render() {
    const { name, label, options } = this.props;
    const placeholder = `${label}`;
    return (
      <Form.Item>
        <div className="align-center">
          <Form.Item name={name} key={name}>
            <Select
              className="filter-input"
              size="large"
              placeholder={placeholder}
              allowClear
              showSearch
              optionFilterProp="children"
              showArrow
            >
              {options?.map((item) => (
                <Option key={item.code} value={item.code}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form.Item>
    );
  }
}
export default ReviewFormSelect;
