import { inject, observer } from 'mobx-react';
import Image from '@components/image';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';
import MackIcon from '@components/image/mack_icon';

const TaskInfoImgItem = (props) => {
    const {
        item,
        width,
        height,
        taskPlanStore,
        packageStatus,
        refresh
    } = props;

    // 预览功能
    const onPreview = () => {
        PreviewImage.open({
            images: item.images,
            renderDesc: (current) => <div style={{
                color: '#fff',
                padding: '100px 50px'
            }}>
                <p>名称 &nbsp;&nbsp; {item.title}</p>
                <p>上传人 {item.creator.name}</p>
                <p>自评分 {item.score}</p>
            </div>,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };

    // 编辑功能
    const onEdit = () => {
        taskPlanStore.setUpdateTask(item);
        taskPlanStore.setTitle(3);
    };

    // 驳回功能
    const onReject = async () => {
        const { id } = item;
        await taskPlanStore.setOperatorId(id, 'disapprove');
        await refresh?.();
    };

    //通过功能
    const onPass = async () => {
        const { id } = item;
        await taskPlanStore.setOperatorId(id, 'approve');
        await refresh?.();
    };

    //评论功能
    const onComment = () => {
        const {
            id,
            comment
        } = item;
        taskPlanStore.comment = comment;
        taskPlanStore.setOperatorId(id, 'comment');
    };

    // 作废功能
    const onCancel = async () => {
        const { id } = item;
        await taskPlanStore.setOperatorId(id, 'discard');
        await refresh?.();
    };
    const masks = [<MackIcon onClick={onPreview} type={'icon-a-16-chakan'} />]
    const {status} = item

    if(packageStatus && ([1,3,4].includes(status))){
        // 通过功能
        masks.push(<MackIcon onClick={onPass} type={'icon-a-14-tongguo'} />)
    }

    if(packageStatus && ([1,2,4].includes(status))){
        // 驳回功能
        masks.push(<MackIcon onClick={onReject} type={'icon-a-14-bohui'} />)
    }


    if(!packageStatus && ([1,3,4].includes(status)) ){
        // 编辑功能
        masks.push(<MackIcon onClick={onEdit} type={'icon-a-14-xiugai'} />)
    }

    if(status === 3){
        // 评论功能
        masks.push(<MackIcon onClick={onComment} type={'icon-a-14-pinglun'} />)
    }

    if(status === 1 || status === 3 || packageStatus && status === 2 ){
        // 作废功能
        masks.push(<MackIcon onClick={onCancel} type={'icon-a-14-zuofei'} />)
    }

    return (
        <Image
            style={{margin: 6}}
            width={width||160}
            height={height|| 160}
            src={item.images?.[0]}
            mask={masks}
            status={item.status}
            title={item.title}
            score={item.score}
        />
    );
};

export default inject('taskPlanStore', 'taskPackageStore')(observer(TaskInfoImgItem));
