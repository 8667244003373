/**
 * @name: BatchTask.jsx
 * @user: cfj
 * @date: 2022/3/25
 * @description: 批量创建任务
 */

import { Col, Form, Row } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';
import { AmpUpload, Typography } from '@components';
import { downloadFile } from '@gUtils';

const BatchTaskForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    const {
        error,
        initialValues
    } = props;
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }));
    return <Form form={form} layout={'vertical'} initialValues={initialValues}>
        <Row gutter={40}>
            <Col>
                <Form.Item name={'package_id'} hidden={true}><span /></Form.Item>
                <Form.Item
                    name={'excel'}
                    label={'电子表格'}
                    required={true}
                    validateFirst={true}
                    rules={[
                        {
                            required: true,
                            message: '请上传表格'
                        },
                        {
                            validator(_, value) {
                                if (value && value?.[0]) {
                                    const file = value?.[0];
                                    const index = file.name.lastIndexOf('.');
                                    const res = file.name.substring(index + 1);
                                    if (res !== 'xlsx' && res !== 'csv') {
                                        return Promise.reject(new Error('请上传excel或CSV格式的文件'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                } else {
                                    return Promise.reject(new Element('请上传表格'));
                                }
                            }
                        }
                    ]}>
                    <AmpUpload maxCount={1} accept={'*'} multiple={false} beforeUpload={(file) => {
                        const index = file.name.lastIndexOf('.');
                        const res = file.name.substr(index + 1);
                        if (res !== 'xlsx' && res !== 'csv') {
                            file.status = 'error';
                        }
                        return false;
                    }} />
                </Form.Item>
            </Col>
            <Col>
                <Form.Item
                    name={'zip'}
                    validateFirst={true}
                    label={'图片压缩包'}
                    required={true} rules={[
                    {
                        required: true,
                        message: '请上传图片压缩包'
                    },
                    {
                        validator(_, value) {
                            if (value && value?.[0]) {
                                const file = value?.[0];
                                const index = file.name.lastIndexOf('.');
                                const res = file.name.substring(index + 1);
                                if (res !== 'zip') {
                                    return Promise.reject(new Error('请上传zip格式的文件'));
                                } else {
                                    return Promise.resolve();
                                }
                            } else {
                                return Promise.reject(new Error('请上传图片压缩包'));
                            }
                        }
                    }
                ]}>
                    <AmpUpload maxCount={1} accept={'*'} multiple={false} beforeUpload={(file) => {
                        const index = file.name.lastIndexOf('.');
                        const res = file.name.substr(index + 1);
                        if (res !== 'zip') {
                            file.status = 'error';
                        }
                        return false;
                    }
                    } />
                </Form.Item>
            </Col>
        </Row>
        {error?.data?.error_url && <Typography>文件我错误 点击<a onClick={() => {
            downloadFile(error.data.error_url);
        }}>下载</a>错误原因</Typography>}
    </Form>;
});
export default BatchTaskForm;
