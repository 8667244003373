import { connect } from "react-redux";
import TaskBoard from "./task_board";
import { fetchTasks } from "@actions/1_entities/tasks_actions";
import { fetchProjects } from "@actions/1_entities/projects_actions";
import { fetchProject } from "@actions/1_entities/project_actions";

const mapStateToProps = (state) => ({
  tasks: state.entities.tasks,
  currentPages: state.pagination.tasks.currentPage,
  pages: state.pagination.tasks.pages,
  total: state.pagination.tasks.total,
  filter: state.pagination.tasks.filter,
  status_total: state.pagination.tasks.status_total,
  users: state.entities.users,
  userPermissions: state.session.permissions,
  configs: state.configs
});

const mapDispatchToProps = (dispatch) => ({
  fetchTasks: (params, cb) => dispatch(fetchTasks(params, cb)),
  fetchProjects: (params, cb) => dispatch(fetchProjects(params, cb)),
  fetchProject: (params, cb) => dispatch(fetchProject(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskBoard)
