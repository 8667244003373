/**
 * @name: OperateForm.jsx
 * @user: cfj
 * @date: 2022/4/12
 * @description: 操作的表单
 */
import { Col, Form, Row } from 'antd';
import { OpenFormField } from './OpenFormField';
import { CategoryFormField } from './CategoryFormField';
import { forwardRef, useImperativeHandle, useMemo } from 'react';

export const OperateForm = forwardRef(function (props, ref) {
    const {
        value,
        onChange
    } = props;
    const [form] = Form.useForm();
    const initialValues = useMemo(() => value, []);
    useImperativeHandle(ref, () => ({
        setParentId(id) {
            const parent_id = form.getFieldValue('parent_id');
            if (id === parent_id) {
                form.setFieldsValue({ parent_id: undefined });
            } else {
                form.setFieldsValue({ parent_id: id });
            }
            form.submit();
        }
    }));
    return <Form form={form}
        initialValues={initialValues}
        onFinish={(values) => {
            onChange(values);
        }}
        onValuesChange={(values) => {
            onChange(values);
        }}>
        <Form.Item name={'parent_id'} hidden><span /></Form.Item>
        <Row group={40}>
            <Col span={24} className={'py-2'}>
                <Form.Item noStyle name={'is_public'}><OpenFormField /></Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item noStyle name={'asset_category'}><CategoryFormField /></Form.Item>
            </Col>
        </Row>
    </Form>;
}
);
