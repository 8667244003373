import ScrollContainer from '../../../../components/scroll_container';
import { taskPlanAPI } from '@api';
import AuditTaskItemCol from '@views/taskPackage/components/AuditTaskItemCol';
import React, { useRef, useState } from 'react';
import { Iconfont, Typography } from '@components';

/**
 * @name: AuditTaskItem.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 审核任务包
 */

const OPTIONS = [
    {
        label: '未审核',
        value: 1
    },
    {
        label: '通过',
        value: 2
    },
    {
        label: '驳回',
        value: 3
    },
    {
        label: '作废',
        value: 4
    }
];
const AuditTaskPackage = function(props) {
    const { taskId, refreshTaskInfoList } = props;
    const scrollRef = useRef();
    const [params, setParams] = useState({
        taskId: taskId,
        status: [1]
    });
    return <>
        <div className={'flex-align-center'}>
            {OPTIONS.map(({
                value,
                label
            }) => {
                let status = [...params.status];
                const index = status.indexOf(value);
                if (index > -1) {
                    if(status.length>1){
                        status.splice(index, 1);
                    }
                } else {
                    status.push(value);
                }
                return <Typography
                    style={{cursor: "pointer", marginRight: 30 }}
                    key={value}
                    onClick={() => {
                        setParams((state) => Object.assign({},  state, {
                            status
                        }));
                    }}>
                    <Iconfont type={index > -1 ? 'icon-a-24-xuanzhong' : 'icon-a-24-weixuanzhong'} className='mg-r-8' />
                    {label}
                </Typography>;
            })}
        </div>
        <ScrollContainer
            col={5}
            url={'/package-item/list'}
            ref={scrollRef}
            params={params}
            height={385}
            pageSize={15}
            request={(page, pageSize, params) => taskPlanAPI.getPlanTaskList({
                page,
                page_size: pageSize,
                package_id: params.taskId,
                filter: {
                    status: params.status
                }
            })
                .then((res) => {
                    return {
                        list: res.data.list,
                        total: res.data.total,
                        statics: res.data.statics
                    };
                })}
            renderItem={(cols, key) => <AuditTaskItemCol
                type={'auto'}
                refresh={() => {
                    scrollRef.current?.refresh()
                    refreshTaskInfoList?.()
                }}
                key={key} cols={cols} />}
        />
    </>;
};

export default AuditTaskPackage;
