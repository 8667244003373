import { Button, Dropdown, Menu } from 'antd';
import { AccessControl, AuthButton, AuthMenuItem, Iconfont } from '@components';
import { projectMenuPermissions, projectStatusIcons } from '@vars/views/projects_vars';
import { PlusOutlined } from '@ant-design/icons';
import React, { useContext, useRef } from 'react';
import { ViewTypeContext } from '@views/2_projects/task_index/task_board/task_board';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import PoolModel from '@views/pool';

export const LeftPart = (props) => (
    <div className="project-title-left">
        <div className="mg-r-10 mg-t-3">
            <Iconfont
                className="icon-20 "
                type="icon-back"
                onClick={() => props.history.push("/projects")}
            />
        </div>
        <h3 className="mg-r-18">{props.currentProject?.title}</h3>
        <div className="display-flex align-center">
            <div className="mg-r-5 mg-t-4">
                <StatusIcon {...props} />
            </div>
            <span>{props.currentProject?.project_status?.label}</span>
            <div>
                {props.showUpdate ? (
                    <div className="align-center">
                        <Iconfont
                            className="icon-20 mg-l-20 mg-r-5"
                            type="icon-refresh"
                            onClick={props.refreshPage}
                        />
                        <span>看板内容已更新 请刷新页面</span>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    </div>
);

export const OperationButtons = (props) => {
    const history = useHistory()
    const { url } = useRouteMatch()
    return props.currentProject && props.statusCode === 2 ? (
        <div className="project-sub-right" id="projectSubRight">
            <AuthButton
                type="primary"
                className="btn-sz-s mg-r-12"
                onClick={() => history.push(url + '/create_task')}
                permission="task_package"
            >
                <PlusOutlined /> 任务包
            </AuthButton>
            <AuthButton
                type="primary"
                className="btn-sz-s mg-r-12"
                onClick={props.showGroupDrawer}
                permission="task_group"
            >
                组合资产
            </AuthButton>
            <AuthButton
                type="primary"
                className="btn-sz-s"
                onClick={props.showAssetDrawer}
                permission="task_create"
            >
                <PlusOutlined /> 创建资产
            </AuthButton>
        </div>
    ) : (
        <div className="project-sub-right" id="projectSubRight">
            <AuthButton
                type="primary"
                className="btn-sz-s mg-r-12"
                onClick={() => history.push(url + '/create_task')}
                permission="task_package"
            >
                <PlusOutlined /> 任务包
            </AuthButton>
            <AuthButton
                type="primary"
                className="btn-sz-s"
                onClick={props.showGroupDrawer}
                permission="task_group"
            >
                组合资产
            </AuthButton>
        </div>
    )
}
    ;

export const StatusIcon = (props) => {
    let icon = projectStatusIcons[props.statusCode] || "icon-project-planning";
    return <Iconfont type={icon} className="icon-20" />;
};

export const RightPart = (props) => {
    const { viewType, switchValueType } = useContext(ViewTypeContext)
    const poolRef = useRef(null)
    const MenuItems = (
        <Menu onClick={props.handleMenuClick}>
            {props.statusCode !== 3 ? (
                <AuthMenuItem key="edit" permission={projectMenuPermissions[0]}>
                    编辑项目
                </AuthMenuItem>
            ) : (
                ""
            )}
            {props.statusCode === 2 ? (
                <AuthMenuItem key="finish" permission={projectMenuPermissions[1]}>
                    结束项目
                </AuthMenuItem>
            ) : (
                ""
            )}
            <AuthMenuItem key="remove" permission={projectMenuPermissions[2]}>
                删除项目
            </AuthMenuItem>
        </Menu>
    );
    return (
        <div className="project-title-right">
            <AuthButton
                type="primary"
                onClick={switchValueType}
                className="btn-sz-s mg-r-12"
                permission="project_waterfall"
            >
                {viewType === 'card' ? '切换瀑布流' : '切换列表'}
            </AuthButton>
            <OperationButtons {...props} />
            {(props.userPermissions.indexOf("produce_take") > -1 ||
                props.userPermissions.indexOf("produce_allot") > -1 ||
                props.userPermissions.indexOf("produce_setting") > -1) && (
                    <div>
                        <AuthButton
                            className="btn-sz-m blue-btn mg-l-10"
                            onClick={() => {
                                poolRef.current?.open()
                            }}
                        >
                            制作池待选
                            <span className="mg-l-4">{props.showTotal}</span>
                        </AuthButton>
                    </div>


                )}
            <AuthButton
                permission="project_history"
                className="btn-sz-s mg-r-10 blue-btn mg-l-10"
                onClick={props.showShootingDrawer}
            >
                拍摄计划
            </AuthButton>
            <AccessControl permission={projectMenuPermissions}>
                <Dropdown overlay={MenuItems} className="dropdown-btn">
                    <AuthButton className="dropdown-btn ant-btn-icon-only">
                        <Iconfont type="icon-three-dots" className="icon-20" />
                    </AuthButton>
                </Dropdown>
            </AccessControl>
            <PoolModel ref={poolRef} />

        </div>
    );
};
