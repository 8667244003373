import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import {AuthButton} from '@/components'
import './index.less'

/**
 * 删除批注
 * @param {*} props props
 * @returns {*}
 */
const RemoveAnnotation = (props) => {
    const { removeModal, setRemoveModal, deleteAnnotation, id } = props;
    const [ deleteReason, setDeleteReason ] = useState("");
    const handleSubmit = (reason) => {
        deleteAnnotation({
            id: id,
            comment: reason
        });
        setRemoveModal(false);
        setDeleteReason("");
    }
    return (
        <Modal
            visible={removeModal}
            footer={null}
            onCancel={() => {
                setRemoveModal(false);
                setDeleteReason("");
            }}
        >
            <div className='amps-delete-modal'>
                <div className='amp-delete-modal-title'>
                    是否确认删除该批注？删除批注数据将无法复原！
                </div>
                <div>
                    <Input.TextArea
                        placeholder="请填写删除原因"
                        value={deleteReason}
                        autoSize={{ minRows: 4, maxRows: 5 }}
                        onChange={(e) => setDeleteReason(e.target.value)}
                        maxLength={500}
                    />
                </div>
                <div className='amp-delete-modal-footer'>
                    <AuthButton
                        type="primary"
                        onClick={ () => { handleSubmit(deleteReason) } }
                    >
                        确认
                    </AuthButton>
                    <AuthButton onClick={ () => {
                        setRemoveModal(false);
                        setDeleteReason("");
                     } } className=" mg-l-20">
                        取消
                    </AuthButton>
                </div>
            </div>
        </Modal>
    )
}

export default RemoveAnnotation;
