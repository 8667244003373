import axios from 'axios';

const taskFlowAPI = {
    submit(params) {
        return axios.post('/task/submit', params);
    },
    review(params) {
        return axios.post('/task/review', params);
    },
    productionScore(params) {
        return axios.post('/api/produce/score', params);
    },
    manipulate(params) {
        return axios.post('/task/manipulate', params);
    },
    start(params) {
        return axios.post('/task/initiate', params);
    },
    discard(params) {
        return axios.post('/task/discard', params);
    },
    remake(params) {
        return axios.post('/task/remake', params);
    },
    stop(params) {
        return axios.post('/task/stop', params);
    },
    getProduceScore(id) {
        return axios.get(`/produce/scores?id=${id}`);
    },
    getProduceList(params) {
        return axios.post(`/produce/list`, params);
    },
    getProduceUsers() {
        return axios.get(`/api/produce/users`);
    },
    getProduceInfo() {
        return axios.get(`/api/produce/info`);
    },
    takeTask(params) {
        return axios.post(`/api/produce/take`, params);
    },
    allotTask(params) {
        return axios.post(`/api/produce/allot`, params);
    },
    getProduceCategory(id) {
        return axios.get(`/produce/category?project_id=${id}`);
    },
    openToggleTask(params) {
        return axios.post(`/api/produce/toggle`, params);
    },
    produceCategoryToggle(params) {
        return axios.post(`/produce/category-toggle`, params);
    },
    getAllProduce(params) {
        return axios.post(`/produce/all`, params);
    },
    getAllProduceCategory() {
        return axios.get(`/produce/category`);
    },
    getCollectScore(id) {
        return axios.post(`/task/scores?id=${id}`);
    },
    /**
     * @method 获取标签列表
     * @param {*} params
     */
    getTagListScene(params) {
        return axios.get(`/api/tag/list`, {
            params: params
        });
    },
    /**
     * @method 添加标签列表
     * @param {*} params
     */
    addTagListScene(params) {
        return axios.post(`/api/tag/add`, params);
    },
    /**
     * @method 删除标签列表
     * @param {*} params
     */
    deleteTagListScene(params) {
        return axios.post(`/api/tag/delete`, params);
    },
    /**
     * @method 修改原序列图片数量
     * @param {*} params
     */
    updateCollectCount(params) {
        return axios.post(`/task/update-collect-count`, params);
    },
    /**
     * @method 资产入库操作
     * @param {*} params
     */
    createGodownEntry(params) {
        return axios.post(`/api/godown-entry-asset-info/create`, params);
    },
    /**
     * @method 获取资产入库数据用来回显
     * @param {*} params
     */
    getGodownEntry(params) {
        return axios.post(`/api/godown-entry/get`, params);
    },
    /**
     * @method 获取资产入库解析校验入库列表
     * @param {*} params
     */
    getListGodownEntry(params = { page: 1, page_size: 1000 }) {
        return axios.post(`/api/godown-entry/list`, params);
    },
    /**
     * @method 更新制作池领取上限
     * @param {*} params
     */
    updateTakeLimit(params) {
        return axios.post(`/api/member-produce-count/update`, params);
    },
    /**
     * @method 获取制作池领取上限
     */
    getTakeLimit() {
        return axios.get(`/api/member-produce-count/get`);
    },
    /**
     * @method 修改资产ID
     */
    updateKindTask(params) {
        return axios.post(`/api/task/update-task-kind`, params);
    },
    /**
     * @method 原序列待审、解析待审通过、待制作转派任务时单独请求
     */
    specialReview(params) {
        return axios.post(`/api/task/handler`, params);
    },
    /**
     * @method 制作终审待审拆分任务
     */
    splitInProduceFinal(params) {
        return axios.post(`/api/task/duplicate`, params);
    },
    /**
     * @method 制作终审待审修改评分
     */
    updateProduceScore (params) {
        return axios.post(`/api/produce/score-update`, params);
    },
    /**
     * @method 入库相关 - 用户接口
     */
    getUserList (params) {
        return axios.post(`/api/users/list`, params);
    },
    /**
     * @method 入库相关 - 入库重置 config
     */
    godownEntryResetConfig (params) {
        return axios.post(`/api/godown-entry/reset-config`, params);
    },
    /**
     * @method 入库相关 - 入库重置 入库操作
     */
     godownEntryReset (params) {
        return axios.post(`/api/godown-entry/reset`, params);
    },
};

export default taskFlowAPI;
