import { EventEmitter } from '@gUtils';

/**
 * 选题池列表更新
 */
export const topicEventBus = new EventEmitter('build');
/**
 * 场景池列表更新
 */
export const sceneEventBus = new EventEmitter('build');
