import { LazyLoadingImage, Typography } from '@components';
import React from 'react';
import Icon from '@components/icon';
import { Space } from 'antd';
import PreviewImage from '@components/image/preview_image';
import { sessionAPI } from '@api';
import { clsx } from '@gUtils';

/**
 * @name: PoolItem.jsx
 * @user: cfj
 * @date: 2022/4/11
 * @description:
 */

export const PoolItem = function (props) {
    const {
        tmp,
        item,
        select,
        onSelect,
        disabled,
        disableSelect,
        split,
        onSplit,
        open, // -1: 隐藏, 0: 不公开 , 1:公开,
        onOpen,
        destroy, // 作废选中
        onDestroy
    } = props;
    const {
        display_images,
        analyse_images,
        produce_images,
        difficulty,
        asset_uid,
        is_public,
        is_split,
        title
    } = item;
    const images = [display_images, analyse_images, produce_images].filter(i => i?.length);
    const textColor = disabled ? 'disabled' : 'primaryText';
    const onPreview = (index) => {
        const current = ([].concat(...images.slice(0, index))).length;
        PreviewImage.open({
            images: [].concat(...images),
            current: current,
            request: (url) => sessionAPI.fetchOriginalImage(url)
                .then(res => res.data?.origin_urls?.[0] || '')
        });
    };
    const publicClassName = open === -1
        ? is_public === 0 ? 'open-box-default-0' : is_public === 1 ? 'open-box-default-1' : ''
        : open === 0 ? 'open-box-0-select' : open === 1 ? 'open-box-1-select' : '';
    const styleObj = {
        color: disabled && Boolean(is_public) ? "#000" :(
            Boolean(is_public) ? "#000" : "#bfbfbf"
        ),
    }
    return <div
        onClick={() => {
            onDestroy?.(item);
        }}
        className={clsx('pool-item',
            disabled && 'pool-item-disabled',
            disableSelect ? 'disable-select' : publicClassName,
            disabled ? 'select-box-disabled' : (select ? 'select-box-select' : 'select-box'),
            destroy && 'destroy-select'
        )}>
        <div className={'d-flex align-center-center justify-content-between'} style={{ height: 27 }}>
            <div className={'d-flex'}>
                <Icon title={Boolean(is_public) ? '公开' : '非公开'}
                    type={Boolean(is_public) ? 'icon-a-16-jifen' : 'icon-a-16-jifenbugongkai'} />
                <Typography color={textColor} className={'mx-1 item-grade'} style={styleObj}>{difficulty}分</Typography>
                {is_split === 2 && <span className={split ? 'split-box-select' : 'split-box'}>
                    <Icon onClick={() => onSplit?.(item)} className={'split-box-on'} type={'icon-chaifenrenwu-morenr'} />
                    <Icon onClick={() => onSplit?.(item)} className={'split-box-off'} type={'icon-chaifenrenwu-hover'} />
                </span>}
            </div>
            <div className={'d-flex'}>
                {tmp === 1 || tmp === 2 && <span>
                    <Icon
                        size={25}
                        title={'选择'}
                        className={'select-box-on'}
                        type={'icon-a-24-weixuanzhong'}
                        onClick={disabled ? undefined : () => onSelect?.(item)} />
                    <Icon
                        size={25}
                        title={'选择'}
                        className={'select-box-off'}
                        type={'icon-a-24-yixuan'}
                        onClick={disabled ? undefined : () => onSelect?.(item)} />
                </span>
                }

                {tmp === 3 &&
                    <span>
                        <Icon className={'open-box-0'}
                            title={'隐藏'}
                            size={25} type={'icon-a-20-biyanhui'}
                            onClick={(event) => {
                                event.stopPropagation();
                                onOpen?.(item);
                            }} />
                        <Icon className={'open-box-1'}
                            size={25}
                            title={'公开'}
                            type={'icon-a-20-chakan'}
                            onClick={(event) => {
                                event.stopPropagation();
                                onOpen?.(item);
                            }} />
                    </span>
                }

            </div>
        </div>
        <div className={'mt-1 d-flex justify-content-between'}>
            <Typography color={textColor} style={styleObj} className="id-title-font">{asset_uid}</Typography>
            <Typography color={textColor} className={'title-text ml-1 id-title-font'} style={styleObj}>{title}</Typography>
        </div>
        <div className={'mt-1 text-no-select'}>
            <Space className={'images'} size={2}>
                {images.map((i, k) => <LazyLoadingImage
                    className={clsx('image', disabled && 'disabled-bg')}
                    key={k}
                    src={i[0]}
                    width={88}
                    height={88}
                    preview={{
                        visible: false
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        onPreview(i);
                    }}
                />)}
            </Space>
        </div>
    </div>;
};
