import React from 'react'
import { ShowDetailButton } from './report_components'
import Bar from './bar'

const label = {
    show: true,
    position: "right",
    color: "#484848",
    distance: 8,
    fontSize: 12,
};
const scoreColor = "#98D8FF";
const totalColor = "#D4B8FF"; //

const Chart = ({ user_stats, labels, title, url }) => {
    // user_stats = user_stats || [];
  const users = user_stats?.map(({ user_name }) => user_name);
  const score_total = user_stats?.map(({ score_total }) => ({
    value: score_total,
    label,
  }));
  const task_count = user_stats?.map(({ task_count }) => ({
    value: task_count,
    label,
  }));
    return (
        <div className={'bar-box'}>
            <div className="align-center mg-b-20" style={{ width: "90%" }}>
                <div className="bold fz-18 mg-r-10">
                    {title}
                </div>
                <ShowDetailButton url={url} />
            </div>
          <div className={'bar'}>
            <Bar data={user_stats} labels={labels} />
          </div>
        </div>
    );
};
export default Chart;
