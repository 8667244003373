import { useCallback, useEffect, useRef } from 'react';

/**
 * 防抖函数
 * @param fn 防抖触发函数
 * @param delay 间隔时间
 * @param dep 防抖触发函数中的数据依赖项目
 * @returns {(function(...[*]): void)|*} 返回防抖函数
 */
function useDebounce(fn, delay, dep = []) {
    const { current } = useRef({ fn, timer: null });
    useEffect(() => {
        current.fn = fn;
        return () => {
            clearTimeout(current.timer)
        }
    }, [fn]);

    return useCallback(function f(...args) {
        if (current.timer) {
            clearTimeout(current.timer);
        }
        current.timer = setTimeout(() => {
            current.fn.call(this, ...args);
        }, delay);
    }, dep);
}

export default useDebounce;
