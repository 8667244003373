/**
 * @name: useVirtual.js
 * @user: cfj
 * @date: 2022/3/28
 * @description:
 */
import { useEffect } from 'react';
import useThrottle from './useThrottle';
import { useSWRInfinite } from './swr';
import { rAF } from '@hooks/swr/helper';

/**
 * dom 滚动监听
 * @param {{current: Element}} ref 监听的dom的ref
 * @param {object} options 配置项
 * @param {number} options.scrollThreshold [options.scrollThreshold=0.4] 距离底部边距的执行request
 * @param {string} options.url swr 唯一key
 * @param {number} options.pageSize [options.pageSize=20] 每页的pageSize
 * @param {D} options.params  每页的pageSize
 * @param {(url:string, page:number, pageSize:number, params:D) => Promise<{list:T[], total: number}>} options.request swr 的 fetcher
 * @param {object} options.outer swr 的 剩余options配置
 * @returns {{mutate, getKey: ((function(*, *, *, *): (*|null))|*), totalSize: (number|number), data, size, error, isValidating}}
 */
const useVirtual = function(ref, options) {
    const {
        scrollThreshold = 0.4,
        url,
        pageSize = 20,
        params,
        request,
        ...outer
    } = options;

    function getKey(index, previousPageData, pageSize, params) {
        if (index === 0) {
            return [url, index + 1, pageSize, params];
        } else {
            if (previousPageData && previousPageData.total) {
                const totalSize = Math.ceil(previousPageData.total / pageSize);
                if (index + 1 <= totalSize) {
                    return [url, index + 1, pageSize, params];
                }
            }
            return null;
        }
    }

    const {
        data,
        error,
        isValidating,
        mutate,
        size,
        setSize
    } = useSWRInfinite(
        (index, previousPageData) => getKey(index, previousPageData, pageSize, params),
        (url, page, pageSize, params) => request(page, pageSize, params), {
            ...outer,
            onSuccess(data, key, config) {
                outer.onSuccess?.(data, key, config);
                rAF(() => {
                    const totalSize = data.length ? Math.ceil((data[data.length - 1].total) / pageSize) : 0;
                    if (data?.length > 0 && data[data.length - 1]?.list.length === pageSize) {
                        if (ref.current && ref.current.scrollHeight && ref.current.clientHeight) {
                            if ((ref.current.scrollHeight) <= ref.current.clientHeight) {
                                if (size < totalSize) {
                                    setSize(size + 1);

                                }
                            }
                        }
                    }
                });
            },
            onError(...arg) {
                outer.onError?.(...arg);
            }
        });
    const totalSize = data?.length ? Math.ceil((data[data.length - 1].total) / pageSize) : 0;

    function onScrollHandler(even) {
        const dom = even.target
        const l = dom.scrollHeight - dom.clientHeight - dom.scrollTop
        if (l <= dom.clientHeight * scrollThreshold) {
            if (!isValidating  && size<totalSize) {
                setSize(size+1);
            }
        }
    }

    const _onScrollHandler = useThrottle(onScrollHandler, 120,
        [ref, JSON.stringify(params)])



    useEffect(() => {
        ref.current?.addEventListener('scroll', _onScrollHandler, false)
        return () => {
            ref.current?.removeEventListener('scroll', _onScrollHandler, false)
        }
    }, [ref])

    return {
        data,
        error,
        isValidating,
        mutate,
        size,
        getKey,
        totalSize: totalSize
    };
};
export default useVirtual;
