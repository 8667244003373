import axios from 'axios'

const assetsAPI = {
    fetchAssets(params) {
        return axios.post("/asset/list", params);
    },
    updateAssets(params) {
        return axios.put("/asset/update", params)
    },
    fetchWaterfall(params) {
        return axios.post("/asset/waterfall", params)
    }
}

export default assetsAPI
