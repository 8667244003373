import { connect } from "react-redux";
import { fetchProjects, requestProjects } from '@actions/1_entities/projects_actions'
import ProjectsFilter from './projects_filter'
import { fetchProjectConfig } from "@actions/2_configs/configs_actions";

const mapStateToProps = (state) => ({
    projectStatuses: state.configs.project_status,
    configs: state.configs
})
const mapDispatchToProps = (dispatch) => ({
    searchProjects: (params, cb) => dispatch(fetchProjects(params, cb)),
    fetchProjectConfig: (cb) => dispatch(fetchProjectConfig(cb)),
    requestProjects: (params,cb) => dispatch(requestProjects(params,cb)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsFilter)
