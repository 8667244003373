import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider, Form, Modal, Space } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { Button } from '@components';
import './index.less';

export const formItemLayout = {
    labelCol: {
        xs: { span: 4 }
    },
    wrapperCol: {
        xs: { span: 20 }
    }
};

const closeFns = [];
let id = 0;

export default class Dialog extends React.Component {
    static useDialog(defaultOptions) {
        const drawersRef = useRef(new Map());
        const nodesRef = useRef(new Map());
        const render = useState({})[1];

        function close(key) {
            if (drawersRef.current.has(key)) {
                drawersRef.current.get(key)
                    .onClose();
            }
        }

        function destroy(key) {
            const nodes = nodesRef.current;
            const has = nodes.delete(key);
            if (has) {
                render({});
            }
        }

        function open(options) {
            id += 1;
            const key = id;
            const nodes = nodesRef.current;
            nodes.set(key, <Dialog
                {...defaultOptions}
                {...options}
                key={key}
                afterClose={() => destroy(key)}
                ref={(ref) => { drawersRef.current.set(key, ref); }}
            />);
            render({});
            return close.bind(null, key);
        }
        const children = [];
        nodesRef.current.forEach((value) => {
            if (value) {
                children.push(value);
            }
        });
        return [children, open];
    }

    static open(options) {
        let drawerRef = null;

        function close() {
            drawerRef.onClose();
        }

        const container = document.createDocumentFragment();

        function destroy() {
            ReactDOM.unmountComponentAtNode(container);
            const index = closeFns.indexOf(close);
            if (index > -1) {
                closeFns.splice(index, 1);
            }
        }

        setTimeout(() => {
            ReactDOM.render(<Dialog
                {...options}
                afterClose={() => destroy()}
                ref={(ref) => { drawerRef = ref; }}
            />, container);
            closeFns.push(close);
        });
        return close;
    }

    static closeAll() {
        closeFns.forEach((i) => i?.());
    }

    node = null;

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            loading: false,
            iSFinish: false
        };
    }

    onCancel() {
        this.setState({
            visible: false
        });
    }

    render() {
        const {
            onOk,
            title,
            content,
            onCancel,
            afterClose,
            onError,
            footer,
            ...other
        } = this.props;
        const {
            visible,
            loading,
            iSFinish,
            error
        } = this.state;
        const cloneContent = React.cloneElement(content, {
            ref: onOk ? (node) => { this.node = node; } : undefined,
            onClose: () => this.onCancel(),
            error
        });
        const Component = onOk ? Form.Provider : React.Fragment;
        const componentProps = onOk ? {
            onFormFinish: async (name, info) => {
                this.setState({ loading: true });
                try {
                    await onOk?.(info.values);
                    this.setState({
                        loading: false,
                        visible: false
                    });
                } catch (e) {
                    onError?.(e);
                    this.setState({ loading: false, error: e });
                }
            }
        } : undefined;
        return (
            <ConfigProvider locale={zhCN}>
                <Component {...componentProps}>
                    <Modal
                        {...other}
                        className="amps-dialog"
                        afterClose={afterClose}
                        title={title}
                        visible={visible}
                        onCancel={() => {
                            onCancel?.(iSFinish);
                            this.onCancel?.();
                        }}
                        footer={footer === undefined ? (
                            <Space size={40}>
                                {onOk
                                    && (
                                        <Button
                                            size="large"
                                            style={{ width: 120 }}
                                            type="primary"
                                            onClick={() => {
                                                this.node?.submit?.();
                                            }}
                                            loading={loading}
                                        >
                                            确定
                                        </Button>
                                    )}
                                <Button size="large" style={{ width: 120 }} onClick={() => this.onCancel()}>取消</Button>
                            </Space>
                        ) : footer}
                        destroyOnClose
                        confirmLoading={loading}
                    >
                        {cloneContent}
                    </Modal>
                </Component>
            </ConfigProvider>
        );
    }
}
