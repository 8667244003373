import React, { Component } from "react";
import { Redirect, Switch, withRouter } from "react-router";
import { PermissionRoute } from "@gUtils";
import { settingsMenu } from "@vars/configs/menu_vars";
class Project extends Component {
  routesRender = () => {
    const projectsRoutes = settingsMenu[0]?.children;
    return projectsRoutes?.map((item) => (
      <PermissionRoute
        exact
        path={`/settings/projects/${item.key}`}
        component={item.component}
        permission={item.permission}
        key={item.key}
      />
    ));
  };
  render() {
    return (
      <div>
        <Switch>
          <Redirect exact path="/settings/projects" to="/settings/projects/user-relation-settings" />
          {this.routesRender()}
        </Switch>
      </div>
    );
  }
}

export default withRouter(Project);
