import { useEffect, useState } from 'react';

/**
 * 监听dom 是否在可视区 主要用在图片懒加载
 * @param ref 绑定的dom元素
 * @returns {boolean} 是否在可视区
 */
export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        if (!ref.current) { return () => {}; }
        if (!window.IntersectionObserver) {
            setIntersecting(true);
            return () => {};
        }
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}
