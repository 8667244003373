import React, { memo, useEffect } from 'react';
import SelectedTopicApi from '@/api/grpcApi/seleceed_topic/selected_topic_api';
import { Select, Radio, Form, Input, Row, Col, Cascader } from 'antd';
import ImageUploadCheck from '@/components/2_data_input/upload/imageUploadCheck';
import SearchArea from '@components/gaode_map/search/index';
import { projectSettingsAPI } from '@api';
import { useSwr } from '@hooks';

const CreateFrom = (props) => {
    const { fromRef, sceneId } = props;

    const disabled = false;

    const label = (text) => {
        return <span className="amp-create-topic-label">{text}</span>;
    };

    // 获取策划人员
    const { data: userList = [] } = useSwr('/user-relation/list', () =>
        projectSettingsAPI.fetchUserRelations({ filter: { type: 17 } }).then((res) => {
            if (res.code === 1000) {
                return res.data.list.map((item) => {
                    return {
                        value: item.user_id,
                        label: item.name
                    };
                });
            }
        })
    );

    useEffect(() => {
        if (sceneId && fromRef) {
            SelectedTopicApi()
                .getTopicSceneInfo({ sceneId })
                .then((res) => {
                    if (res.detail) {
                        const { name, comment, imagesList, addressListList } = res.detail;
                        const { id: planUserId } = res.planUserList;
                        const fieldsValue = {
                            name,
                            comment,
                            planUserId,
                            imagesList: imagesList?.map((item, index) => {
                                return {
                                    file_path: item.filePath,
                                    file_url: item.fileUrl,
                                    uid: `${item.fileUid}-${index}`,
                                    review_image_type: item.useType === 1 ? 'main' : ''
                                };
                            }),
                            addressList: addressListList?.map((item) => {
                                return {
                                    ...item,
                                    adcode: item.ad_code, //区域编码
                                    typecode: item.type_code // POI类型编码
                                };
                            })
                        };
                        fromRef.setFieldsValue(fieldsValue);
                    }
                })
                .catch((err) => { });
        }
    }, [sceneId, fromRef]);

    return (
        <>
            <Row gutter={32} justify="space-between">
                <Col span={12}>
                    <Form.Item
                        label={label('场景名称')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: '请输入场景的名称'
                            }
                        ]}>
                        <Input maxLength={50} disabled={disabled} placeholder="请输入场景的名称" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={label('图片')}
                        name="imagesList"
                        rules={[
                            {
                                required: true,
                                message: '请上传图片'
                            }
                        ]}>
                        <ImageUploadCheck maxCount={10} disabled={disabled} fileSize={20} width="120px" purpose="topic" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={32} justify="space-between">
                <Col span={12}>
                    <Form.Item
                        label={label('策划人员')}
                        name="planUserId"
                        rules={[
                            {
                                required: true,
                                message: '请选择策划人员'
                            }
                        ]}>
                        <Select
                            showSearch
                            placeholder="请选择策划人员"
                            optionFilterProp="label"
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            options={userList}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={label('地址')}
                        name="addressList"
                        rules={[
                            {
                                required: true,
                                message: '请选择地址'
                            }
                        ]}>
                        <SearchArea disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={label('描述')} name="comment">
                        <Input.TextArea
                            autoSize={{
                                minRows: 3,
                                maxRows: 5
                            }}
                            placeholder="请添加对该场景的描述信息"
                            maxLength={500}
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default memo(CreateFrom);
