import { observable, computed, action, flow, makeAutoObservable } from 'mobx';
class CollapseStore {
  constructor() {
    makeAutoObservable(this);
  }
  @observable openedIndex = -1;
  @observable allCollapsed = false

  @action updateOpened(index) {
    this.openedIndex = index
  }
  @action removeOpened() {
    this.openedIndex = -1
  }
  @action toggleAllOpen() {
    this.allCollapsed = !this.allCollapsed
  }
};

export default new CollapseStore()