/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { examineType } from '../config';
import FilterForm from '../components/filter_form';
import RenderItem from '../components/render_item';
import ItemDetails from '../components/item_detail';

interface IParams {
    create_time?: any,
    start_time?: any,
    end_time?: any,
    topic_id_name?: string,
    plan_user_id?: number,
    status: number,
}

/**
 * 场景
 * @returns {React.ReactNode}
 */
const PlanExamineScene: React.FC = () => {
    const [selectTopic, setSelectTopic] = useState<any>(null);
    const [params, setParams] = useState<IParams>({
        status: examineType.PLAN_EXAMINE
    });

    /**
     * 提交表单
     * @param {IParams} value value
     */
    const handleFilterForm = (value: IParams) => {
        if (value.create_time) {
            value.start_time = moment(moment(value?.create_time[0]).format('YYYY-MM-DD 00:00:00')).unix();
            value.end_time = moment(moment(value?.create_time[1]).format('YYYY-MM-DD 23:59:59')).unix();
        }
        delete value.create_time;
        setParams(value);
    };

    useEffect(() => {
        setSelectTopic(null);
    }, []);

    return (
        <div className="amp-scene-page">
            <div className="amp-scene-item-content">
                <FilterForm onChange={handleFilterForm} />
                <Row>
                    <Col span={4}>
                        <RenderItem
                            params={params}
                            status={examineType.PLAN_EXAMINE}
                            setSelectTopic={(item: any) => setSelectTopic(item)}
                        />
                    </Col>
                    <Col span={20}>
                        <ItemDetails
                            status={examineType.PLAN_EXAMINE}
                            selectTopic={selectTopic}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default PlanExamineScene;
