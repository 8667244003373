import React, {useMemo} from 'react'
import { Form, Row, Col, Input } from 'antd';
import SelectScene from './select_scene'
import configData from '../config'

const TurnForm = (props) => {
    const { topicId, modalType } = props
    const label = (text) => {
        return <span className="amp-create-topic-label">{text}</span>
    }

    const params = useMemo(() => {
        return {
            topicId,
            ...configData?.[modalType]?.params
        }
    },[props])

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="checkSceneId"
                    >
                        <SelectScene params={params} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={label('描述')}
                        name="describe"
                    >
                        <Input.TextArea
                            autoSize={{
                                minRows: 3,
                                maxRows: 5
                            }}
                            placeholder='请添加描述信息'
                            maxLength={500}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default TurnForm
