/**
 * @name: authRedirect.jsx
 * @user: cfj
 * @date: 2022/4/20
 * @description: 重定向待权限判断
 */
import { getRoute, getRouteAuth, useParentRoute } from '@components/router/routes';
import { useAccess } from '@hooks';
import { Error403 } from '@components';
import { Redirect } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export,require-jsdoc
export const AuthRedirect = function (props) {
    const { children } = props;
    const auths = useAccess(null, { suspense: true });
    let route = useParentRoute();
    if (route.redirect) {
        route = getRoute(route.redirect);
    }
    const isAuth = getRouteAuth(route, auths);
    if (isAuth === false) {
        const parentRoute = getRoute(route._parentName);
        if (parentRoute?.routes) {
            const autoRoute = parentRoute?.routes.find((r) => {
                if (r.redirect) {
                    return false;
                }
                return getRouteAuth(r, auths);
            });
            if (autoRoute) {
                return <Redirect to={autoRoute.path} />;
            }
            return <Error403 />;
        }
        return <Error403 />;
    }
    return children;
};
