// 入参初始化
import { noop } from './helper';
import { compare } from './compare';
import { initCache } from './cache';

// 错误重新验证
const onErrorRetry = (_, __, config, revalidate, opts) => {
    setTimeout(revalidate, 1000, opts);
};
export const sourceMap = new Map();
export const [cache, mutate] = initCache(sourceMap);
export const defaultConfig = {
    revalidateIfStale: true,
    revalidateOnMount: true,
    fallbackData: undefined,

    onSuccess: noop,
    onError: noop,
    onErrorRetry,

    dedupingInterval: 2 * 1000,

    compare: compare,
    cache,
    mutate,

    suspense: false
};

export const withArgs = (hook) => {
    return function useSWRArgs(...args) {
        const [key, fn, _config] = args;
        const config = Object.assign({}, defaultConfig, _config);
        return hook(key, fn, config);
    };
};
