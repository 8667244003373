/**
 * @name: AcceptTask.jsx
 * @user: cfj
 * @date: 2022/3/28
 * @description: 承接任务包
 */
import { forwardRef, useImperativeHandle } from 'react';
import { Col, Form, Row } from 'antd';
import { Input } from '@components';
import { withSuspense } from '@/HOC';
import taskPackageAPI from '../../../../api/2_projects/task_package_api';
import { useSwr } from '@hooks';

const AcceptTaskForm = forwardRef(function(props, ref){
    const {data} = useSwr('/package/take-score', taskPackageAPI.getPackageScore, {
        suspense: true
    })
    const {initialValues} = props
    const [form] = Form.useForm()
    useImperativeHandle(ref, () => ({
        submit: form.submit
    }))
    return <Form form={form} layout={'vertical'} initialValues={{...initialValues, currentTotal: data.data.score}}>
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item label={'下一步'} name={'statusLabel'} required={true}>
                    <Input disabled={true}  />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={40}>
            <Col span={12}>
                <Form.Item label={'此任务包总评分'} name={'scoreTotal'} required={true}>
                    <Input disabled={true}  />
                </Form.Item>

            </Col>
            <Col span={12}>
                <Form.Item label={'当前小组剩余可承接分'} name={'currentTotal'} required={true}>
                    <Input disabled={true}  />
                </Form.Item>
            </Col>
        </Row>
    </Form>
})

export default withSuspense(AcceptTaskForm)
