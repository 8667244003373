// =====================================================================

// session
export const INITIATE_SESSION = "INITIATE_SESSION";
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const RECEIVE_SESSION_ERROR = "RECEIVE_SESSION_ERROR";
export const RECEIVE_VALIDATION_TOKEN = "RECEIVE_VALIDATION_TOKEN";
export const RECEIVE_USER_PERMISSIONS = "RECEIVE_USER_PERMISSIONS";
export const FINISH_INITIALIZATION = 'FINISH_INITIALIZATION'
export const RECEIVE_TODOS = 'RECEIVE_TODOS'
// =====================================================================

// projects
export const REQUEST_PROJECTS = "REQUEST_PROJECTS";
export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS";
export const RECEIVE_PROJECTS_ERROR = "RECEIVE_PROJECTS_ERROR";
export const DELETE_PROJECT = "DELETE_PROJECT";

// project
export const RECEIVE_PROJECT = "RECEIVE_PROJECT";
export const RECEIVE_PROJECT_ERROR = "RECEIVE_PROJECT_ERROR";
// =====================================================================

// tasks
export const REQUEST_TASKS = "REQUEST_TASKS";
export const RECEIVE_TASKS = "RECEIVE_TASKS";
export const RECEIVE_TASKS_ERROR = "RECEIVE_TASKS_ERROR";

// task
export const RECEIVE_TASK = "RECEIVE_TASK";
export const RECEIVE_TASK_ERROR = "RECEIVE_TASK_ERROR";

// =====================================================================

// statuses
export const RECEIVE_STATUSES = "RECEIVE_PROJECTS_STATUSES";

// =====================================================================

// settings
export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_ROLES = "RECEIVE_ROLES";
export const RECEIVE_GROUPS = "RECEIVE_GROUPS";
export const RECEIVE_USERS_ERR = "RECEIVE_USERS_ERR";
export const DELETE_USER = "DELETE_USER";

// =====================================================================

// role
export const REQUEST_ROLE_LIST = "REQUEST_ROLE_LIST";
export const RECEIVE_ROLES_ERR = "RECEIVE_ROLES_ERR";
export const RECEIVE_ROLE = "RECEIVE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// =====================================================================

// permissions
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS";

// =====================================================================

// configs
export const RECEIVE_CONFIGS = "RECEIVE_CONFIGS";

// =====================================================================

// ui
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE";

// =====================================================================

// announcement
export const REQUEST_ANNOUNCEMENTS = "REQUEST_ANNOUNCEMENTS"
export const RECEIVE_ANNOUNCEMENTS = "RECEIVE_ANNOUNCEMENTS";
export const RECEIVE_ANNOUNCEMENT = "RECEIVE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'


// asset repository

export const REQUEST_ASSETS = 'REQUEST_ASSETS'
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS'