import React, { Component } from 'react';
import { Table } from 'antd';
class AmpTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, columns, props } = this.props;

        const { trips } = props;
        return (
            <>
                <div className="amp-task-package-title">
                    {title} <span className="amp-color-red">*</span>
                </div>
                <div className="amp-task-package-table">
                    <Table
                        columns={columns}
                        dataSource={trips}
                        width="840px"
                        pagination={false}
                        rowKey={(record) => record.id}
                        bordered
                        className="shooting-plan-detail-table"
                    />
                </div>
            </>
        );
    }
}

export default AmpTable;
