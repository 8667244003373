import { isFunction } from './helper';

export const serialize = function(key) {
    if (isFunction(key)) {
        try {
            key = key();
        } catch (err) {
            key = '';
        }
    }
    const args = [].concat(key);
    key = typeof key == 'string'
        ? key
        : (Array.isArray(key) ? key.length : key)
            ? JSON.stringify(key)
            : '';

    const infoKey = key ? `$swr$${key}` : '';
    return [key, args, infoKey];
};
