import { checkPassword, checkCompanyEmail } from "@/utils/validations/validator_util";

const initState = {
  name_email: "",
  role_ids: [],
  group_ids: [],
  status: [],
};
const username = "username";
const name = "name";
const email = "email";
const phone = "phone";
const status = "status";
const role_id = "role_id";
const group_id = "group_id";
const password = "";

const userEdit = {
  username: "",
  name: "",
  email: null,
  role: [
    {
      name: null,
      id: null,
    },
  ],
  group: {
    name: null,
    id: null,
  },
  password: "",
};

const optionRole = [
  { label: "全部角色", value: "" },
  { label: "产品经理", value: "经理" },
  { label: "监制", value: "监制" },
  { label: "制作", value: "制作" },
  { label: "解析", value: "解析" },
];

const optionOrg = [
  { label: "全部组织", value: "" },
  { label: "组织1", value: "1" },
  { label: "组织2", value: "2" },
  { label: "组织3", value: "3" },
  { label: "组织4", value: "4" },
];

const optionStatus = [
  { name: "正常", id: 1 },
  { name: "锁定", id: -1 },
];

const userStatus = [
  { name: "正常", id: 1 },
  { name: "锁定", id: 2 },
];

const userDIC = {
  username: {
    title: "账户名称",
    dataIndex: username,
    placeholder: "请输入账户名称",
  },
  email: {
    title: "邮箱",
    dataIndex: email,
    placeholder: "请输入公司邮箱",
    email: 'email'
  },
  name: {
    title: "真实姓名",
    dataIndex: name,
    placeholder: "请输入真实姓名",
  },
  password: {
    title: "初始密码",
    dataIndex: password,
    placeholder: "请输入初次登录使用的密码",
  },
  role: {
    title: "角色",
    dataIndex: role_id,
    placeholder: "请选择角色",
  },
  group: {
    title: "组织",
    dataIndex: group_id,
    placeholder: "请选择组织",
  },
  status: {
    title: "状态",
    dataIndex: status,
  },
};
const rules = {
  username: [
    { required: true, message: "请输入账户名称", type: "string" },
    {
      message: "账户名称不能超过20个字符",
      min: 1,
      max: 20,
      type: "string",
    },
  ],

  email: [
    { validator: checkCompanyEmail },
  ],

  name: [
    { required: true, message: "请输入账户名", type: "string" },
    {
      message: "真实姓名不能超过10个字符",
      min: 1,
      max: 10,
      type: "string",
    },
  ],
  password: [
    { validator: checkPassword }
  ],
  role: [
    { required: true, message: "请选择角色", type: "number" },
  ]
};

const tableColumns = [
  {
    title: "账户名称",
    key: "id",
    dataIndex: "username",
  },
  {
    title: "邮箱",
    key: "id",
    dataIndex: "email",
  },
  {
    title: "真实姓名",
    key: "id",
    dataIndex: "name",
  },
  {
    title: "角色",
    key: "id",
    dataIndex: "role",
    render: (role) => (role && role.name ? role.name : "-"),
  },
  {
    title: "组织",
    key: "id",
    dataIndex: "group",
    render: (group) => (group && group.name ? group.name : "-"),
  },
  {
    title: "状态",
    key: "id",
    dataIndex: "is_locked",
    render: (is_locked) => (!is_locked ? "正常" : "锁定"),
  },
];

export {
  initState,
  optionRole,
  optionOrg,
  optionStatus,
  tableColumns,
  userStatus,
  username,
  name,
  email,
  phone,
  status,
  role_id,
  group_id,
  password,
  userEdit,
  rules,
  userDIC,
};
