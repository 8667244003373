import React, { Component } from "react";
import { withRouter } from "react-router";

class Announcement extends Component {
  contentRef = React.createRef();
  get id() {
    const { params } = this.props.match;
    const { id } = params;
    return id;
  }

  get currentPiece() {
    const { announcements } = this.props;
    return announcements[this.id];
  }

  componentDidMount() {
    this.props.fetchAnnouncement(this.id);
  }

  render() {
    const { announcements } = this.props;
    const currentPiece = announcements[this.id];
    if (!currentPiece) return "";

    const { title, content, author, published_at } = currentPiece;
    return (
      <div className="announcement-page">
        <h2 style={{ textAlign: "center", marginBottom:"60px" }}>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div style={{ textAlign: "right", marginTop: "100px" }}>
          <p>{author}</p>
          <p>{published_at}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Announcement);
