import {
  RECEIVE_PERMISSIONS

} from '@actions/action_consts'

const permissionsReducer = (state = [], action = {}) => {
  Object.freeze(state)
  switch (action.type) {
      case RECEIVE_PERMISSIONS:
        let permissionsList = action.data
        return permissionsList
      default:
        return state
  }
}
export default permissionsReducer