import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import tasksAPI from '../../../../api/2_projects/tasks_api';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import FallItem from '@components/fall_item';
import { twoDime } from '@views/3_assets/filter_table/asset_water_fall_v2';
import { Spin } from 'antd';
import './fall_view_style.less';
import { $error } from '@components';

export default function({project_id}){
    const domRef = useRef()
    const [anchorEl, setEditing] = useState()
    const {list, loading, total, error} = useInfiniteScroll({
        params: project_id,
        anchorEl,
        onError(error, onReload, currentPage, errorCount){
            if(errorCount<3){
                onReload()
            } else {
                $error(error.message || '服务器错误')
            }
        },
        request: (page, project_id) => tasksAPI.getWaterfalls({
            page,
            project_id,
            page_size: 40

        }).then(res => ({
            list: res.data?.list || [],
            total: res.data?.total || 0
        }))
    })
    useEffect(() => {
        setEditing(domRef.current)
    }, [])
    const copeList = list.map(i => {
        let ratio = 1;
        if (i.aspect_ratio > 0 && i.aspect_ratio < 1.5) {
            ratio = 1;
        } else if (i.aspect_ratio >= 1.5 && i.aspect_ratio < 2.5) {
            ratio = 2;
        } else if (i.aspect_ratio >= 2.5 && i.aspect_ratio < 3.5) {
            ratio = 3;
        } else if (i.aspect_ratio >= 3.5 && i.aspect_ratio < 4.5) {
            ratio = 4;
        } else {
            ratio = 6;
        }
        return {
            id: i.task_id,
            uid: i.task_no,
            url: i.task_image,
            name: i.task_title,
            ratio
        };
    });
    const renderList = [].concat(...twoDime(copeList)
        .map((images, index,dimArr ) => {
            if (!images.length) {
                return [];
            }
            const sum = images.reduce((acc, i) => acc + i.ratio, 0);
            return images.map(image => ({
                ...image,
                isDimEnd: dimArr.length -1 === index,
                sum
            }));
        }));

    return <div ref={domRef} className={'amps_fall_view_container'}>
        {
            renderList?.map(i => <FallItem key={i.id} {...i} />)
        }
        {
            loading && renderList.length === 0 && <div className='init_loading'>
                <Spin size='large' />
            </div>
        }
        {
            !loading && renderList.length === 0 && <div className='init_loading'>
                暂无加载数据
            </div>
        }
        {
            loading && renderList.length && <div className='fall_footer'><Spin size='large' /></div>
        }
        {
            renderList.length && total === renderList.length &&
            <div className={'fall_footer'}><p className={'fall_footer_text'}>加载到底了！</p></div>
        }
    </div>
}
