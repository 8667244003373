/**
 * @name: index.js
 * @user: cfj
 * @date: 2022/4/12
 * @description: 项目的api
 */
import { Server } from '@gUtils';

/**
 * 获取好的领取信息
 * @returns {Promise}
 */
export const getProduceInfo = function() {
    return Server.get('/api/produce/info');
};

/**
 * 领取任务包
 * @param {*} data data
 * @returns {Promise}
 */
export const receiveTake = function(data) {
    return Server.post('/api/produce/take', data);
};

/**
 * 获取任务下的分类筛选
 * @param {*} id id
 * @returns {Promise}
 */
export const getCategory = function(id) {
    return Server.get('/produce/category', { project_id: id });
};

/**
 * 隐藏或者公开任务
 * @param {*} data data
 * @returns {Promise}
 */
export const produceToggle = function(data) {
    return Server.post('/api/produce/toggle', data);
};

/**
 * 公开或隐藏所有
 * @param {*} data data
 * @returns {Promise}
 */
export const produceCategoryToggle = function(data) {
    return Server.post('/produce/category-toggle', data);
};

/**
 * 作废
 * @param {*} data data
 * @returns {Promise}
 */
export const discardTask = function(data) {
    return Server.post('/task/discard', data);
};

/**
 * 分配任务
 * @param {*} data data
 * @returns {Promise}
 */
export const AllotTake = function(data) {
    return Server.post('/api/produce/allot', data);
};

/**
 * 获取分配人
 * @returns {Promise}
 */
export const getAssigneeList = function() {
    return Server.get('/api/produce/users');
};

/**
 * 获取可领取项目的最大值
 * @returns {Promise}
 */
export const getTaskLimit = function() {
    return Server.get('/api/member-produce-count/get');
};

/**
 * 更新可领取项目的最大值
 * @param {*} data data
 * @returns {Promise}
 */
export const updateTaskLimit = function(data) {
    return Server.post('/api/member-produce-count/update', data);
};
