import React, { Component } from "react";
import { Form, Table, Button } from "antd";
import FundTable from "./renders/fund_table";

import {
  FormDescription,
  FormAdd,
  FormSelect,
  FormAmount,
} from "./renders/common_renders";
import { fundFormColumns } from "@vars/views/shooting_plan_vars";

class FundForm extends Component {
  formRef = React.createRef();
  state = {
    funds: this.props.planDetails.funds || [],
    errorMsg: "",
  };

  get fundTotal() {
    let res = 0;
    const { funds } = this.state;
    if (!funds) return 0;
    funds.forEach((item) => {
      res += item.fund_amount;
    });
    const final = res > 0 ? res : 0;
    return final;
  }

  get formattedFunds() {
    const { funds } = this.state;
    let res = funds ? funds?.map((item) => this.formatFund(item)) : "";
    return res;
  }

  componentDidMount() {
    this.props.updatePlanDetails({ fund_total: this.fundTotal });
  }

  validateForm = () => {
    if (this.state.funds.length < 1) {
      this.formRef.current.validateFields();
      this.setState({ errorMsg: "请添加经费" });

      return Promise.reject();
    }
    return Promise.resolve();
  };

  onFinish = (val) => {
    let { funds } = this.state;
    this.setState({ funds: [...funds, { ...val }], errorMsg: "" });
    this.props.updatePlanDetails({ fund_total: this.fundTotal });
    this.resetForm();
  };
  resetForm = () => {
    this.formRef.current.resetFields();
  };

  formRender = () => (
    <Form
      layout="inline"
      onFinish={this.onFinish}
      validateMessages={{ required: "该项为必填项" }}
      ref={this.formRef}
      className="plan-table-form"
      validateTrigger="onBlur"
    >
      <FormSelect {...this.props} type={"fund_type"} />
      <FormDescription type={"fund_description"} size="long" />
      <FormAmount type={"fund_amount"} />
      <FormAdd />
    </Form>
  );

  formatFund = (item) => {
    const { fundType } = this.props;
    const { fund_type } = item;
    let res = { ...item };
    res.fund_type =
      typeof fund_type !== "object" ? fundType[fund_type] : fund_type;
    return res;
  };

  removeFundItem = (index) => {
    const { funds } = this.state;
    funds.splice(index, 1);
    this.setState({ funds });
    this.props.updatePlanDetails({ fund_total: this.fundTotal });
  };

  saveItem = (index, item) => {
    const { funds } = this.state;
    funds[index] = item;
    this.setState({ funds });
    this.props.updatePlanDetails({ fund_total: this.fundTotal });
  };

 render() {
    const tableProps = {
      ...this.props,
      fundData: this.formattedFunds,
      deleteItem: this.removeFundItem,
      saveItem: this.saveItem,
    };
    return (
      <div className="drawer-form-content">
        <h3 className="required">经费</h3>
        <div className="plan-table">
          <Table columns={fundFormColumns} />
          {this.formRender()}
          <FundTable {...tableProps} />
        </div>
        <div className="error-color">{this.state.errorMsg}</div>
      </div>
    );
  };
}

export default FundForm;
