/**
 * @name: Collapse.jsx
 * @user: cfj
 * @date: 2022/3/24
 * @description: 折叠面板
 */
import React, { useEffect, useRef, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { clsx } from '@gUtils';
import { Typography } from '@components';

const classPrefix = 'amps-collapse';
const Collapse = function(props) {
    const {
        defaultActiveKey,
        className,
        children,
        ...other
    } = props;
    return <div className={clsx(classPrefix, className)} {...other}>
        {
            React.Children.map(children, child => {
                const {
                    type,
                    key,
                    props
                } = child;
                if (type.name === 'Panel') {
                    return React.cloneElement(child, {
                        key: key,
                        className: clsx(props.className, `${classPrefix}-child`),
                        open: props.open || defaultActiveKey?.includes(Number(key))
                    });
                } else {
                    return child;
                }
            })
        }
    </div>;

};

const Panel = function(props) {
    const {
        open = true,
        title,
        className,
        children
    } = props;
    const transitionDomRef = useRef();
    const isFirstRender = useRef(true);
    const isMount = useRef(false);
    const containerDomRef = useRef();
    const currentContainerHeight = useRef();
    const [isActive, setActive] = useState(open);
    const [style, setStyle] = useState(isActive ? undefined : {
        height: 0,
        overflow: 'hidden'
    });

    function updateContainerHeight() {
        const height = containerDomRef.current.offsetHeight;
        if (height > 0) {
            currentContainerHeight.current = height;
        }
        return currentContainerHeight.current;
    }

    function stateTransition(open) {

        let requestAnimationID;
        if (open) {
            containerDomRef.current.style.display = '';
            const currentHeight = updateContainerHeight();
            setStyle({
                height: 0,
                transition: 'height 0.2s',
                overflow: 'hidden'
            });
            requestAnimationID = requestAnimationFrame(() => {
                setStyle({
                    height: currentHeight,
                    transition: 'height 0.2s',
                    overflow: 'hidden'
                });
            });
        } else {
            const currentHeight = updateContainerHeight();
            setStyle({
                height: currentHeight,
                transition: 'height 0.2s',
                overflow: 'hidden'
            });
            requestAnimationID = requestAnimationFrame(() => {
                setStyle({
                    height: 0,
                    transition: 'height 0.2s',
                    overflow: 'hidden'
                });
            });
        }
        return () => window.cancelAnimationFrame(requestAnimationID);
    }

    useEffect(() => {
        setActive(open);
    }, [open]);
    useEffect(() => {
        const unTransition = !isFirstRender.current ? stateTransition(isActive) : null;
        isMount.current = true;
        isFirstRender.current = false;
        return () => {
            unTransition?.();
            isMount.current = false;
        };
    }, [isActive]);
    return <div className={clsx('amps-panel', className)}>
        <Typography variant={'body1'} weight={'bold'} className={'amps-panel-title'} onClick={() => {
            setActive(!isActive);
        }}>{title}
            <CaretRightOutlined
                className={'amps-panel-title-icon'}
                rotate={isActive ? 90 : 0} /></Typography>
        <div ref={transitionDomRef}
             style={style}
             onTransitionEnd={() => {
                 if (isMount.current) {
                     if (isActive) {
                         setStyle(undefined);
                     } else {
                         containerDomRef.current.style.display = 'none';
                     }
                 }
             }}>
            <div ref={containerDomRef}
                 className={clsx('amps-panel-content')}>
                {children}
            </div>
        </div>
    </div>;
};
Panel.displayName = 'Panel';
Collapse.displayName = 'Collapse';
Collapse.Panel = Panel;
export default Collapse;
