import { connect } from "react-redux";
import {checkAuth} from '@gUtils'
const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});


const AccessControl = (props) => {
  const { userPermissions, permission, children } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? <>{children}</> : "";
};

export default connect(mapStateToProps)(AccessControl);
