import "./index.less";
import { Button } from "antd";
import { AuthButton } from "@/components";

/**
 * 任务包审核相关按钮
 * @returns
 */
export const ReviewButton = (props) => {
    const { handleCancel, loginUser, taskApproveList } = props;
    let flag = false;
    taskApproveList.map((item) => {
        if (item.user_id === loginUser.id) {
            flag = true;
        }
    });
    return (
        <div className="amps-create-tasks-button">
            <div className="bottom-button  ">
                <AuthButton
                    onClick={() => {
                        props.taskPackageStore.getPlanTaskList();
                        props.taskPlanStore.setTitle(4);
                    }}
                    type="primary"
                    className="tasks-create tasks-btn mg-r-20"
                    style={{
                        display: flag ? "inline-block" : "none",
                    }}
                >
                    任务包审核
                </AuthButton>
                <AuthButton
                    type="primary"
                    className="mg-r-20"
                    onClick={() => {
                        props.taskPlanStore.setTitle(5);
                    }}
                    style={{
                        display: flag ? "inline-block" : "none",
                    }}
                >
                    公开任务包
                </AuthButton>
                <AuthButton
                    className="mg-r-20"
                    onClick={() => {
                        props.taskPlanStore.setTitle(6);
                    }}
                    style={{
                        display: flag ? "inline-block" : "none",
                    }}
                >
                    任务包驳回
                </AuthButton>
                <AuthButton
                    className="mg-r-20"
                    onClick={() => {
                        props.taskPlanStore.setTitle(7);
                    }}
                    permission="task_package_discard"
                >
                    作废
                </AuthButton>
                <Button className="tasks-cancel tasks-btn" onClick={handleCancel}>
                    取消
                </Button>
            </div>
        </div>
    );
};
