export interface IConfig {
    title: string
    tips: string
    params?: any
    operation: string
}
interface IModalConfigData {
    [key:string]: IConfig
}
const modalConfigData: IModalConfigData = {
    add: {
        title: '添加场景',
        tips: '',
        operation: 'topic_scene_create'
    },
    edit: {
        title: '编辑场景',
        tips: '',
        operation: 'topic_scene_update'
    },
    fastReview: {
        title: '快速审核',
        tips: '请选择需要快速审核的场景，点击确认处理后，将会跳过审核直接进行任务包的制作',
        params: {
            statusArrList: [201, 202, 203]
        },
        operation: 'topic_scene_submit_to_quick_approval'
    },
    review: {
        title: '提交策划审核',
        tips: '请选择需要提交策划审核的场景，点击确认处理后，需要经过策划内部审核及资产委员会审核后方可进行任务包的制作',
        params: {
            statusArrList: [201]
        },
        operation: 'topic_scene_submit_to_plan_approval'
    },
    abandon: {
        title: '作废',
        tips: '请选择需要作废场景，点击确认处理后，将无法找回该场景',
        params: {
            statusArrList: [201, 202, 203, 204]
        },
        operation: 'topic_scene_discard'
    }
};

export default modalConfigData;
