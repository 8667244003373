
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TopicModal from './index';
import AddTopic from './add_topic';
// 创建选题详情路由
const TopicDetailsRoute = function (props) {
    const {parentUrl} = props
    const { path, url} = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/add`}>
                <AddTopic parentUrl={parentUrl} />
            </Route>
            <Route path={`${path}/edit/:topicId`}>
                <AddTopic parentUrl={parentUrl} />
            </Route>
            <Route path={`*`}>
                <Redirect to={`${url}/add`} />
            </Route>
        </Switch>
    );
};
// 创建选题路由
const TopicRoute = function () {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/create_topic`}>
                <TopicModal parentUrl={url} >
                    <TopicDetailsRoute parentUrl={url} />
                </TopicModal>
            </Route>
        </Switch>
    );
};
export default TopicRoute
