import { notification } from "antd";
import { Iconfont } from "@/components";

let messageList = [];
let timeOut = null;
let goHome = () => { };

/**
 * 站内消息提醒
 */
const notificTips = () => {
    if (messageList.length === 0) return
    const key = `open${Date.now()}`;
    const close = () => notification.close(key);
    const icon = <Iconfont type="icon-info" className="icon-small mg-r-12" />;
    let action = () => { }
    const visit = () => {
        action();
        close();
    };
    let msg = ''
    if (messageList.length === 0) return
    if (messageList.length === 1) {
        msg = messageList[0].msg
        action = messageList[0].action
    } else {
        msg = `您当下有${messageList.length}个待办未处理`
        action = goHome
    }
    messageList = []
    const message = (
        <div className="display-flex align-center cursor-default">
            {icon}
            <div className="notification-content">{msg}</div>
            <div>
                <span className="mg-r-20 cursor-pointer primary-color" onClick={visit}>
                    前往
                </span>
                <span
                    className="cursor-pointer"
                    style={{ color: "rgba(0, 0, 0, 0.5)" }}
                    onClick={close}
                >
                    稍后
                </span>
            </div>
        </div>
    );
    notification.open({
        message,
        key,
        duration: 5,
    });
}

/**
 * 站内消息提醒输出控制
 * @param {string} msg 提示消息
 * @param {function} action 前往点击事件
 */
export const $push = (msg, action, goHomePage) => {
    messageList.push({ msg, action })
    goHome = goHomePage
    if (timeOut) return
    timeOut = setTimeout(() => {
        notificTips()
        timeOut = null
    }, 1000);
};
