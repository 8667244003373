import { Button } from "antd";
import { connect } from "react-redux";
import { checkAuth } from "@gUtils";
import { Iconfont } from "@/components";

const mapStateToProps = (state) => ({
  userPermissions: state.session.permissions,
});
const AuthButtonComponent = (props) => {
  const { permission, userPermissions, dispatch, className, type, ...rest } = props;
  const hasAuth = checkAuth(userPermissions, permission);
  const finalClass = type === "link" || props.block ? `${className}` : `btn-sz-m ${className}`;
  return !permission || hasAuth ? (
    <Button className={finalClass} type={type} {...rest}  />
  ) : (
    ""
  );
};

const AuthIconComponent = (props) => {
  const { permission, userPermissions, dispatch, onClick, ...rest } = props;
   const hasAuth = checkAuth(userPermissions, permission);
  return !permission || hasAuth ? (
    <span onClick={onClick} style={{cursor:"pointer"}}>
      <Iconfont {...rest}/>
    </span>
  ) : ""
};

const AuthIcon = connect(mapStateToProps)(AuthIconComponent)
const AuthButton = connect(mapStateToProps)(AuthButtonComponent);

export { AuthButton, AuthIcon };
