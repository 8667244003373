import React, { Component } from "react";
import { Modal, Button, Input } from "antd";
import {AuthButton} from '@/components'
import { emptyProject } from "@vars/views/projects_vars";

class RemoveProject extends Component {
  state = {
    visible: false,
    loading: false,
    delete_reason: "",
  };
  show = () => {
    this.setState({ visible: true });
  };

  handleSubmit = () => {
    const { delete_reason } = this.state,
      id = this.props.currentProject.id,
      params = { delete_reason, id };
    this.props.removeProject(params, {
      success: this.success,
      error: this.hide,
    });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  success = () => {
    this.hide();
    this.props.history.push("/projects");
  };

  render() {
    const { visible, loading } = this.state;
   const currentProject = this.props.currentProject || emptyProject;

    return (
      <Modal
        centered
        visible={visible}
        onOk={this.handleSubmit}
        onCancel={this.hide}
        destroyOnClose
        footer={null}
      >
        <div className="dialog-pd">
          <div className="fz-18 mg-b-42">
            <div>
              是否确认对项目 <strong>《{currentProject.title}》</strong>
              进行删除?
            </div>
            <div>删除后项目数据将无法复原！</div>
            <div className="mg-t-42">
              <Input.TextArea
                placeholder="请填写删除原因"
                value={this.state.delete_reason}
                autoSize={{ minRows: 4, maxRows: 5 }}
                onChange={(e) =>
                  this.setState({ delete_reason: e.target.value })
                }
              />
            </div>
          </div>

          <div className="dialog-footer">
            <AuthButton
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleSubmit}
            >
              确认
            </AuthButton>
            <AuthButton key="back" onClick={this.hide} className=" mg-l-20">
              取消
            </AuthButton>
          </div>
        </div>
      </Modal>
    );
  }
}

export default RemoveProject;
