import { forwardRef, useImperativeHandle, useState, useMemo } from 'react';
import { Col, Form, Input, Row, Radio, Select } from 'antd';
import '@styles/views/VoidForm.less';

const RollbackForm = forwardRef(function(props, ref) {
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        submit() {
            form.submit();
        }
    }));
    const appoints = useMemo(() => {
        const status = props?.item?.appoint_status
        let list = []
        if (status) {
            if (Array.isArray(status)) {
                list = status;
            } else {
                for (const item in status) {
                    list.push(status[item]);
                }
            }
        }
        return list;
    }, [props]);

    const [radioVal, setRadioVal] = useState(1);
    const radioChange = (e) => {
        setRadioVal(e.target.value);
    }
    const item = props.item;
    return <div className={'amps-voidForm'}>
        <Form form={form} layout={'vertical'} initialValues={props.initialValues}>
            <Row gutter={40}>
                <Form.Item hidden name={'id'}><input /></Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={'回退类型'}
                        name="is_appoint_status"
                        initialValue={1}
                    >
                        <Radio.Group onChange={radioChange} >
                            <Radio value={1}>上一步</Radio>
                            {
                                props.isAssignStatus && <Radio value={2}>指定状态</Radio>
                            }
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div className="mg-b-8 required bold">目标状态</div>
                    {
                        radioVal === 1 && <Input disabled value={"制作待评分"} className='input-sz-ml mg-b-16' />
                    }
                    {
                        radioVal === 2 && (
                            <Form.Item
                                name="appoint_status"
                                rules={[{ required: true, message: '请选择目标状态' }]}
                            >
                                <Select
                                    className='input-sz-ml'
                                    showSearch
                                    placeholder="请选择任务状态"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    size="large"
                                >
                                    {
                                        appoints.map(item =>
                                            <Select.Option value={item.code} key={item.code}>{item.label}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={'回退原因'}
                        name={'comment'}
                        rules={[{type: 'string', required: true, message: "请输入回退原因" }]}
                    >
                        <Input.TextArea
                            maxLength={500}
                            autoSize={{
                                minRows: 5,
                                maxRows: 5
                            }}
                            placeholder={'请输入回退原因'} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>;
});
export default RollbackForm;
