/**
 * @name: statistics.js
 * @user: cfj
 * @date: 2022/4/20
 * @description: 数据统计 API
 */
import { Server } from '@gUtils';

/**
 * 前期项目交接明细
 * @param {*} data 请求参数
 * @returns {Function}
 */
export const getHandoverDetail = (data) => Server.post('/api/task-statistic/project-handover', { ...data, is_export: 0 });

/**
 * 前期项目交接明细导出
 * @param {object} data 请求参数
 * @returns {Function}
 */
export const getHandoverDetailExport = (data) => Server.post('/api/task-statistic-export/project-handover', { ...data, is_export: 0 });

/**
 * 获取资产数量
 * @param {object} params 请求参数
 * @returns {Function}
 */
export const getNumberAsset = (params) => Server.get('/api/asset/list-new-asset', params);

/**
 * 获取占比类型
 * @param {*} params 请求参数
 * @returns {Function}
 */
export const getTypeProportion = (params) => Server.get('/api/asset-count-pie/list', params);

/**
 * 作废统计查询
 * @param {object} data 请求参数
 * @returns {Function}
 */
export const getDiscardLog = (data) => Server.post('/api/task-statistic/discard-log', data);

/**
 * 作废数据导出
 * @param {object} data 请求参数
 * @returns {Function}
 */
export const exportDiscardLog = (data) => Server.post('/api/task-statistic-export/discard-log', data);

/**
 * 作废汇总统计 时间统计
 * @param {object} params 请求参数
 * @param {int} discard_type 作废类型
 * @param {time} start_time 开始时间
 * @param {time} end_time 结束时间
 * @param {int} project_id 项目id
 * @param {string} project_name 项目名称
 * @returns {Function}
 */
export const getDiscardDateSummary = (params) => Server.post('/api/task-statistic/discard-date-summary', params);

/**
 * 作废汇总统计 项目统计
 * @param {object} params 请求参数
 * @param {int} discard_type 作废类型
 * @returns {Function}
 */
export const getDiscardProjectSummary = (params) => Server.post('/api/task-statistic/discard-project-summary', params);
