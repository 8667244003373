import { Component } from "react";
import { withRouter } from "react-router";
import { AnnouncementTable, AnnouncementFilter } from ".";
import { AuthButton } from "@/components";
class AnnouncementSettings extends Component {
  handleClick = () => {
    this.props.history.push("/settings/announcements/new");
  };
  render() {
    return (
      <div>
        <div className="display-flex space-between align-center">
          <AnnouncementFilter {...this.props} />
          <AuthButton
            type="primary"
            onClick={this.handleClick}
            permission="announcement_create"
          >
            新增
          </AuthButton>
        </div>
        <div className="table-list-content">
          <AnnouncementTable />
        </div>
      </div>
    );
  }
}

export default withRouter(AnnouncementSettings);
