import { Form, Select } from 'antd';
import { countries } from '@vars/global_vars';
import { useState } from 'react';
import { inject, observer } from 'mobx-react';

const { Option } = Select;

const RenderSelect = ({ name, label, data }, currentTask, props) => {
    const message = "请选择" + label;
    const isPhotographers = name === "photographers";
    data = Array.isArray(data) ? data : Object.values(data);
    let photographers = null;
    if (props.formType === "task_edit") {
        photographers = isPhotographers && (currentTask[name] || [])?.map(item => item.id)
    }
    let [selected, setSelected] = useState([]);
    let res = [];
    const tagRender = (props) => {
        let { label, value } = props;
        res.push(props);
        let result = [];
        let obj = {};
        for(let i =0; i<res.length; i++){
            if(!obj[res[i].value]){
                result.push(res[i]);
                obj[res[i].value] = true;
            }
        }
        let temp = []
        result.map((item) => {
            temp.push(item.value);
        })
        const idx = temp.indexOf(value);
        const style = idx === 0 ? { marginLeft: "7px" } : {};
        label = temp.length > 1 && idx > 0 ? `/${label}` : label;
        return (
            <span key={label} style={style}>
                {label}
            </span>
        );
    };
    const validator = (_, value) => {
        if (!value) return Promise.reject(message)
        if (isPhotographers && value.length > 8) return Promise.reject("拍摄人员数量不能超过8个");
        return Promise.resolve()
    }
    const onChange = (val) => {
        props.formRef?.current?.setFieldsValue({
            "photographers": val,
        })
        setSelected(val);
    }
    return (
        <div className="task-form-col-2" key={name}>
            <div className="bold required mg-b-16">{label}</div>
            <Form.Item
                name={name}
                rules={[{ required: true, validator: validator }]}
                initialValue={
                    photographers || currentTask[name]?.code || currentTask[name]
                }
            >
                <Select
                    className="input-sz-ml"
                    placeholder={message}
                    size="large"
                    mode={isPhotographers ? "multiple" : null}
                    tagRender={tagRender}
                    maxTagCount={4}
                    onChange={isPhotographers ? onChange : () => { }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    showArrow
                    disabled={ props.taskStore.assetCharacter === 2 && (name === "asset_type" || name === "location") }
                >
                    {(data || [])?.map((item, idx) => (
                        <Option
                            value={isPhotographers ? item.user_id : item.code}
                            key={idx}
                        >
                            {isPhotographers ? item.name : item.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

const MultipleComponents = (props) => {
    const { secretList } = props.configsStore;
    const { asset_type, photographers, currentTask } = props;
    let temp = [];
    const len = photographers.length;
    const secretLen = secretList.length;
    if (secretLen > 0) {
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < secretLen; j++) {
                if (photographers[i].user_id === secretList[j].id) {
                    temp.push(photographers[i]);
                }
            }
        }
    } else {
        temp = photographers;
    }

    const renders = [
        { name: "asset_type", label: "资产类型", data: asset_type },
        // { name: "asset_category", label: "资产种类", data: asset_category },
        { name: "location", label: "采集地区", data: countries },
        { name: "photographers", label: "拍摄人员", data: temp },
    ];
    return renders?.map((item) => RenderSelect(item, currentTask, props));
}

export default inject('configsStore', "taskStore")(observer(MultipleComponents));
