import {
  RECEIVE_ROLES,
  DELETE_ROLE,
  RECEIVE_ROLE,
} from "@actions/action_consts";

const rolesReducer = (state = {}, action = {}) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ROLES:
      let list = action.roles.list;
      let res = {};
      list.forEach((ele) => {
        res[ele.id] = ele;
      });
      if (!action.params.filter) return res;
      return { ...state, ...res };
    case RECEIVE_ROLE:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case DELETE_ROLE:
      const { id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    default:
      return state;
  }
};
export default rolesReducer;
