/**
 * @name: withEmpty.js
 * @user: cfj
 * @date: 2022/4/21
 * @description: 空状态ui
 */
import { createElement, forwardRef } from 'react';
import { isDataEmpty } from '@gUtils';
import { Empty } from '@components';

/**
 * 主要使用在图表无数据展示的情况
 * @param {ComponentType} component 包裹组件
 * @param {object} options 配置项目
 * @param {string} options.dataIndex props的字段名 默认 data
 * @returns {ComponentType}
 */
export const withEmpty = function (component, options) {
    const { dataIndex = 'data', ...output } = options || {};
    return forwardRef((props, ref) => {
        if (isDataEmpty(props[dataIndex])) {
            return createElement(component, {
                ...props,
                ref
            });
        }
        return <Empty {...output} />;
    });
};
